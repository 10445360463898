import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate} from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Header from "../Header";
import Footer from "../Footer";
import { BiPlusCircle } from "react-icons/bi";
import RecentItem from "../recentitem/rececentItem";
import Select from 'react-select';
import axios from "axios";
import { API_URL } from '../config';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";

const NewProduct = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate = useNavigate();
  const [productAccount, setProductaccount] = useState([]);
  const [suppliers, setsupplierData] = useState([]);
  const [getPrice, setgetPrice] = useState([]);
  const adminRole=localStorage.getItem('role')
  const [supplierSelect, setSupplierSelect] = useState(null);
  // select dropdown
  const supplierDropdown = suppliers.map(item => ({
    value: item.account_name,
    label: item.account_name
  }));


  const [isFormsubmit, setFormsubmited] = useState(false);
  const handleSupplier = (supplierSelect) => {
    setSupplierSelect(supplierSelect);
  };
  const clearSupplier = () => {
    setSupplierSelect(null);
  };
  const [actives, setActives] = useState(false);
  const handleActive = (e) => {
    setActives(e.target.checked);
  };

  const [fixedCorbon, setFixedCorbon] = useState(false);
  const handleFixed = (e) => {
    setFixedCorbon(e.target.checked);
  };

  const ClearIndicator = (props) => {
    return (
      <div onMouseDown={clearSupplier}>
        <span className="clears"><BiPlusCircle /></span>
      </div>
    );
  };

  const supplierDropdown1 = productAccount.map(item => ({
    value: item.account_name,
    label: item.account_name
  }));

  const [supplierSelect1, setSupplierSelect1] = useState(null);
  const handleSupplier1 = (supplierSelect1) => {
    setSupplierSelect1(supplierSelect1);
  };
  const clearSupplier1 = () => {
    setSupplierSelect1(null);
  };

  const ClearIndicator1 = (props) => {
    return (
      <div onMouseDown={clearSupplier1}>
        <span className="clears"><BiPlusCircle /></span>
      </div>
    );
  };


  // price
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      console.log(data);
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
  // product supplier
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/supplier",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(data)
      setsupplierData(data);
    } catch (error) {
      console.error(error);
    }
  };

  // product account
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"all/accounts",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      // console.log(data)
      setProductaccount(data);
    } catch (error) {
      console.error(error);
    }
  };


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setFormsubmited(true)
    const responseData = {
      product_name :data.product_name,
product_account :supplierSelect1.value,
product_supplier :supplierSelect.value,
product_description :data.product_description,
product_family :data.product_family,
product_shipping_term :data.product_shipping_term,
product_owner :data.product_owner,
product_active:actives,
short_term_available:data.short_term_available,
product_price_basis :data.product_price_basis,
product_price_type :data.product_price_type,
product_port_of_loading :data.product_port_of_loading,
total_moisture_typical:data.total_moisture_typical,
total_moisture_minimum:data.total_moisture_minimum,

MnO_Typical:data.MnO_Typical,
MnO_Minimum:data.MnO_Minimum,
MnO_Maximum:data.MnO_Maximum,
gross_as_received_typical:data.gross_as_received_typical,
net_as_received_typical:data.net_as_received_typical,
total_moisture_maximum:data.total_moisture_maximum,
moisture_maximum:data.moisture_maximum,
ash_typical_arb:data.ash_typical_arb,
ash_minimum_arb:data.ash_minimum_arb,
ash_maximum_arb:data.ash_maximum_arb,
sulphur_typical_arb:data.sulphur_typical_arb,
sulphur_minimum_arb:data.sulphur_minimum_arb,
sulphur_maximum_arb:data.sulphur_maximum_arb,
volatile_matter_typical_arb:data.volatile_matter_typical_arb,
volatile_matter_minimum_arb:data.volatile_matter_minimum_arb,
volatile_matter_maximum_arb:data.volatile_matter_maximum_arb,

moisture_typical:data.moisture_typical,
moisture_minimum:data.moisture_minimum,
ash_typical:data.ash_typical,
ash_minimum:data.ash_minimum,
ash_maximum:data.ash_maximum,

volatile_matter_typical:data.volatile_matter_typical,
volatile_matter_minimum:data.volatile_matter_minimum,
volatile_matter_maximum:data.volatile_matter_maximum,
fixed_carbon_by_difference:fixedCorbon,
fixed_carbon_typical:data.fixed_carbon_typical,
fixed_carbon_minimum:data.fixed_carbon_minimum,
fixed_carbon_maximum:data.fixed_carbon_maximum,

gross_air_dried_typical:data.gross_air_dried_typical,
gross_air_dried_min:data.gross_air_dried_min,
gross_air_dried_max:data.gross_air_dried_max,
gross_as_received_min:data.gross_as_received_min,
gross_as_received_max:data.gross_as_received_max,
net_as_received_min:data.net_as_received_min,
net_as_received_max:data.net_as_received_max,

hgi_typical:data.hgi_typical,
hgi_min:data.hgi_min,
hgi_max:data.hgi_max,

carbon_typical:data.carbon_typical,
carbon_min:data.carbon_min,
carbon_max:data.carbon_max,

hydrogen_typical:data.hydrogen_typical,
hydrogen_min:data.hydrogen_min,
hydrogen_max:data.hydrogen_max,

nitrogen_typical:data.nitrogen_typical,
nitrogen_min:data.nitrogen_min,
nitrogen_max:data.nitrogen_max,

sulphur_typical:data.sulphur_typical,
sulphur_min:data.sulphur_min,
sulphur_max_ultimate_analysis:data.sulphur_max_ultimate_analysis,
oxygen_typical:data.oxygen_typical,
oxygen_min:data.oxygen_min,
oxygen_max:data.oxygen_max,

sulphur_typical_adb:data.sulphur_typical_adb,
sulphur_min_adb:data.sulphur_min_adb,
product_sulphur_max:data.product_sulphur_max,

initial_typical:data.initial_typical,
initial_minimum:data.initial_minimum,
initial_maximum:data.initial_maximum,
spherical_typical:data.spherical_typical,
spherical_minimum:data.spherical_minimum,
spherical_maximum:data.spherical_maximum,
hemispherical_typical:data.hemispherical_typical,
hemispherical_minimum:data.hemispherical_minimum,
hemispherical_maximum:data.hemispherical_maximum,
flow_typical:data.flow_typical,
flow_minimum:data.flow_minimum,
flow_maximum:data.flow_maximum,
oxidising_initial_typical:data.oxidising_initial_typical,
oxidising_initial_minimum:data.oxidising_initial_minimum,
oxidising_initial_maximum:data.oxidising_initial_maximum,
oxidising_spherical_typical:data.oxidising_spherical_typical,
oxidising_spherical_minimum:data.oxidising_spherical_minimum,
oxidising_spherical_maximum:data.oxidising_spherical_maximum,
oxidising_hemispherical_typical:data.oxidising_hemispherical_typical,
oxidising_hemispherical_minimum:data.oxidising_hemispherical_minimum,
oxidising_hemispherical_maximum:data.oxidising_hemispherical_maximum,
oxidising_flow_typical:data.oxidising_flow_typical,
oxidising_flow_minimum:data.oxidising_flow_minimum,
oxidising_flow_maximum:data.oxidising_flow_maximum,
SiO2_Typical:data.SiO2_Typical,
SiO2_Minimum:data.SiO2_Minimum,
SiO2_Maximum:data.SiO2_Maximum,
Al2O3_Typical:data.Al2O3_Typical,
Al2O3_Minimum:data.Al2O3_Minimum,
Al2O3_Maximum:data.Al2O3_Maximum,
Fe2O3_Typical:data.Fe2O3_Typical,
Fe2O3_Minimum:data.Fe2O3_Minimum,
Fe2O3_Maximum:data.Fe2O3_Maximum,
CaO_Typical:data.CaO_Typical,
CaO_Minimum:data.CaO_Minimum,
CaO_Maximum:data.CaO_Maximum,
MgO_Typical:data.MgO_Typical,
MgO_Minimum:data.MgO_Minimum,
MgO_Maximum:data.MgO_Maximum,
MnO_Typical:data.MnO_Typical,
MnO_Minimum:data.MnO_Minimum,
MnO_Maximum:data.MnO_Maximum,
TiO2_Typical:data.TiO2_Typical,
TiO2_Minimum:data.TiO2_Minimum,
TiO2_Maximum:data.TiO2_Maximum,
Na2O_Typical:data.Na2O_Typical,
Na2O_Minimum:data.Na2O_Minimum,
Na2O_Maximum:data.Na2O_Maximum,
K2O_Typical:data.K2O_Typical,
K2O_Minimum:data.K2O_Minimum,
K2O_Maximum:data.K2O_Maximum,
P2O5_Typical:data.P2O5_Typical,
P2O5_Minimum:data.P2O5_Minimum,
P2O5_Maximum:data.P2O5_Maximum,
SO3_Typical:data.SO3_Typical,
SO3_Minimum:data.SO3_Minimum,
SO3_Maximum:data.SO3_Maximum,
te_arsenic_as_typical_in_air_dried_coal :data.te_arsenic_as_typical_in_air_dried_coal,
te_arsenic_as_minimum_in_air_dried_coal :data.te_arsenic_as_minimum_in_air_dried_coal,
te_arsenic_as_maximum_in_air_dried_coal :data.te_arsenic_as_maximum_in_air_dried_coal,
te_antimony_sb_typical_in_air_dried_coal :data.te_antimony_sb_typical_in_air_dried_coal,
te_antimony_sb_minimum_in_air_dried_coal :data.te_antimony_sb_minimum_in_air_dried_coal,
te_antimony_sb_maximum_in_air_dried_coal :data.te_antimony_sb_maximum_in_air_dried_coal,
te_beryllium_be_typical_in_air_dried_coal :data.te_beryllium_be_typical_in_air_dried_coal,
te_beryllium_be_minimum_in_air_dried_coal :data.te_beryllium_be_minimum_in_air_dried_coal,
te_beryllium_be_maximum_in_air_dried_coal :data.te_beryllium_be_maximum_in_air_dried_coal,
te_boron_b_typical_in_air_dried_coal:data.te_boron_b_typical_in_air_dried_coal,
te_boron_b_minimum_in_air_dried_coal:data.te_boron_b_minimum_in_air_dried_coal,
te_boron_b_maximum_in_air_dried_coal:data.te_boron_b_maximum_in_air_dried_coal,
te_cadmium_cd_typical_in_air_dried_coal :data.te_cadmium_cd_typical_in_air_dried_coal,
te_cadmium_cd_minimum_in_air_dried_coal :data.te_cadmium_cd_minimum_in_air_dried_coal,
te_cadmium_cd_maximum_in_air_dried_coal :data.te_cadmium_cd_maximum_in_air_dried_coal,
te_chromium_cr_typical_in_air_dried_coal :data.te_chromium_cr_typical_in_air_dried_coal,
te_chromium_cr_minimum_in_air_dried_coal :data.te_chromium_cr_minimum_in_air_dried_coal,
te_chromium_cr_maximum_in_air_dried_coal :data.te_chromium_cr_maximum_in_air_dried_coal,
te_copper_cu_typical_in_air_dried_coal:data.te_copper_cu_typical_in_air_dried_coal,
te_copper_cu_minimum_in_air_dried_coal:data.te_copper_cu_minimum_in_air_dried_coal,
te_copper_cu_maximum_in_air_dried_coal:data.te_copper_cu_maximum_in_air_dried_coal,
te_chlorine_cl_typical_in_air_dried_coal :data.te_chlorine_cl_typical_in_air_dried_coal,
te_chlorine_cl_minimum_in_air_dried_coal :data.te_chlorine_cl_minimum_in_air_dried_coal,
te_chlorine_cl_maximum_in_air_dried_coal :data.te_chlorine_cl_maximum_in_air_dried_coal,
te_fluorine_f_typical_in_air_dried_coal :data.te_fluorine_f_typical_in_air_dried_coal,
te_fluorine_f_minimum_in_air_dried_coal :data.te_fluorine_f_minimum_in_air_dried_coal,
te_fluorine_f_maximum_in_air_dried_coal :data.te_fluorine_f_maximum_in_air_dried_coal,
te_mercury_hg_typical_in_air_dried_coal :data.te_mercury_hg_typical_in_air_dried_coal,
te_mercury_hg_minimum_in_air_dried_coal :data.te_mercury_hg_minimum_in_air_dried_coal,
te_mercury_hg_maximum_in_air_dried_coal :data.te_mercury_hg_maximum_in_air_dried_coal,
te_molybdenum_mo_typical_in_air_dried_coal :data.te_molybdenum_mo_typical_in_air_dried_coal,
te_molybdenum_mo_minimum_in_air_dried_coal :data.te_molybdenum_mo_minimum_in_air_dried_coal,
te_molybdenum_mo_maximum_in_air_dried_coal :data.te_molybdenum_mo_maximum_in_air_dried_coal,
te_lead_pb_typical_in_air_dried_coal:data.te_lead_pb_typical_in_air_dried_coal,
Al2O3_Typical:data.Al2O3_Typical,
te_lead_pb_minimum_in_air_dried_coal:data.te_lead_pb_minimum_in_air_dried_coal,
te_lead_pb_maximum_in_air_dried_coal:data.te_lead_pb_maximum_in_air_dried_coal,
te_phosphorus_p_typical_in_air_dried_coal :data.te_phosphorus_p_typical_in_air_dried_coal,
te_phosphorus_p_minimum_in_air_dried_coal :data.te_phosphorus_p_minimum_in_air_dried_coal,
te_phosphorus_p_maximum_in_air_dried_coal :data.te_phosphorus_p_maximum_in_air_dried_coal,
te_selenium_se_typical_in_air_dried_coal :data.te_selenium_se_typical_in_air_dried_coal,
te_selenium_se_minimum_in_air_dried_coal :data.te_selenium_se_minimum_in_air_dried_coal,
te_selenium_se_maximum_in_air_dried_coal :data.te_selenium_se_maximum_in_air_dried_coal,
te_vanadium_v_typical_in_air_dried_coal :data.te_vanadium_v_typical_in_air_dried_coal,
te_vanadium_v_minimum_in_air_dried_coal :data.te_vanadium_v_minimum_in_air_dried_coal,
te_vanadium_v_maximum_in_air_dried_coal :data.te_vanadium_v_maximum_in_air_dried_coal,
te_zinc_zn_typical_in_air_dried_coal:data.te_zinc_zn_typical_in_air_dried_coal,
te_zinc_zn_minimum_in_air_dried_coal:data.te_zinc_zn_minimum_in_air_dried_coal,
te_zinc_zn_maximum_in_air_dried_coal:data.te_zinc_zn_maximum_in_air_dried_coal,
te_arsenic_as_typical:data.te_arsenic_as_typical,
te_arsenic_as_minimum:data.te_arsenic_as_minimum,
te_arsenic_as_maximum:data.te_arsenic_as_maximum,
te_antimony_sb_typical:data.te_antimony_sb_typical,
te_antimony_sb_minimum:data.te_antimony_sb_minimum,
te_antimony_sb_maximum:data.te_antimony_sb_maximum,
te_boron_b_typical:data.te_boron_b_typical,
te_boron_b_minimum:data.te_boron_b_minimum,
te_boron_b_maximum:data.te_boron_b_maximum,
te_cadmium_cd_typical:data.te_cadmium_cd_typical,
te_cadmium_cd_minimum:data.te_cadmium_cd_minimum,
te_cadmium_cd_maximum:data.te_cadmium_cd_maximum,
te_chromium_cr_typical:data.te_chromium_cr_typical,
te_chromium_cr_minimum:data.te_chromium_cr_minimum,
te_chromium_cr_maximum:data.te_chromium_cr_maximum,
te_copper_cu_typical:data.te_copper_cu_typical,
te_copper_cu_minimum:data.te_copper_cu_minimum,
te_copper_cu_maximum:data.te_copper_cu_maximum,
te_chlorine_cl_typical:data.te_chlorine_cl_typical,
te_chlorine_cl_minimum:data.te_chlorine_cl_minimum,
te_chlorine_cl_maximum:data.te_chlorine_cl_maximum,
te_fluorine_f_typical:data.te_fluorine_f_typical,
te_fluorine_f_minimum:data.te_fluorine_f_minimum,
te_fluorine_f_maximum:data.te_fluorine_f_maximum,
te_mercury_hg_typical:data.te_mercury_hg_typical,
te_mercury_hg_minimum:data.te_mercury_hg_minimum,
te_mercury_hg_maximum:data.te_mercury_hg_maximum,
te_molybdenum_mo_typical:data.te_molybdenum_mo_typical,
te_molybdenum_mo_minimum:data.te_molybdenum_mo_minimum,
te_molybdenum_mo_maximum:data.te_molybdenum_mo_maximum,
te_lead_pb_typical:data.te_lead_pb_typical,
te_lead_pb_minimum:data.te_lead_pb_minimum,
te_lead_pb_maximum:data.te_lead_pb_maximum,
te_phosphorus_p_typical:data.te_phosphorus_p_typical,
te_phosphorus_p_minimum:data.te_phosphorus_p_minimum,
te_phosphorus_p_maximum:data.te_phosphorus_p_maximum,
te_selenium_se_typical:data.te_selenium_se_typical,
te_selenium_se_minimum:data.te_selenium_se_minimum,
te_selenium_se_maximum:data.te_selenium_se_maximum,
te_vanadium_v_typical:data.te_vanadium_v_typical,
te_vanadium_v_min:data.te_vanadium_v_min,
te_vanadium_v_max:data.te_vanadium_v_max,
te_zinc_zn_typical:data.te_zinc_zn_typical,
te_zinc_zn_min:data.te_zinc_zn_min,
te_zinc_zn_max:data.te_zinc_zn_max,
size_0_to_50mm_typical:data.size_0_to_50mm_typical,
size_0_to_50mm_min:data.size_0_to_50mm_min,
size_0_to_50mm_max:data.size_0_to_50mm_max,
above_0_to_50mm_typical:data.above_0_to_50mm_typical,
above_0_to_50mm_min:data.above_0_to_50mm_min,
above_0_to_50mm_max:data.above_0_to_50mm_max,
under_2mm_typical:data.under_2mm_typical,
under_2mm_min:data.under_2mm_min,
under_2mm_max:data.under_2mm_max,
size_above_2mm_typical:data.size_above_2mm_typical,
size_above_2mm_min:data.size_above_2mm_min,
size_above_2mm_max:data.size_above_2mm_max,
testing_method :data.testing_method,
created_by :data.created_by,
last_modified_by :data.last_modified_by
    };
    console.log(JSON.stringify(responseData));
    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);


      axios.post(API_URL+'add/product', responseData, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if(response.status===200 ){
          toast.success("Product Master Added Successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/productmaster/product-detail/" + response.data.id);
          setTimeout(() => {
            reset();
          }, 300);
        }else{
          toast.error("Check with fields", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",})
        }
        });

    }
  };

  return (
    <>
      <Header />
      <div className="contactAccount  full-container" id="product-master">
      <div className="row">
        <div className="col-md-2 recent-item">
          <RecentItem/>
        </div>
        <div className="col-md-10 right-section">
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Product Master </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Product Master / Add
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
              {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
            </div>
          </div>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/productmaster/create-view-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
          <Row className="mb-3">
            <h4 className="heading">Information</h4>
            {/* <h4 className='heading'>Account Information</h4> */}
            {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Product No"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product No"
                  name="product_no"
                  {...register("product_no", { required: false })}
                />
                {errors.product_no && <span>Required</span>}
              </FloatingLabel>
            </Form.Group> */}

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Product Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product Name"
                  name="product_name"
                  className="error-validation"
                  {...register("product_name", { required: true })}
                />
                {errors.product_name && <span>Error: You must enter a value</span>}
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Owner"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Owner"
                  name="product_owner"
                  className="owner-role"
                  value={adminRole}
                  {...register("product_owner", { required: false })}
                />
                {errors.product_owner && <span>Required</span>}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">

                 <Select
        options={supplierDropdown1}
        value={supplierSelect1}
        onChange={handleSupplier1}
        placeholder="Account"
        components={{ ClearIndicator1 }}
        isClearable
      />
            </Form.Group>
            <Form.Group as={Col} md="6">

          <input
          type='checkbox'
          name='active'
          checked={actives} // Use the state variable to determine if the checkbox is checked
          onChange={handleActive} // Handle the change event
          className='mx-3'
        /><label>Active</label>
        </Form.Group>
             {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Product code"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product Code"
                  name="product_code"
                  {...register("product_code", { required: false })}
                />
                {errors.product_code && <span>Required</span>}
              </FloatingLabel>
            </Form.Group> */}

            <Form.Group as={Col} md="6">
            <Select
        options={supplierDropdown}
        value={supplierSelect}
        onChange={handleSupplier}
        placeholder="Supplier"
        components={{ ClearIndicator }}
        isClearable
      />
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6"  className="textarea-half">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Product Description"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Product Description"
                  style={{ height: "200px!important" }}
                  name="product_description"
                  {...register("product_description", { required: false })}
                />
                {errors.product_description && <p id="text-area">Required</p>}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Price Basis GAR"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product Price Basis"
                  name="product_price_basis"
                  {...register("product_price_basis", { required: false })}
                />
                {errors.product_price_basis && (
                  <span>Required</span>
                )}
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Product Family"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="product_family"
                  placeholder="Product Family"
                  {...register("product_family", { required: false })}
                >
                  <option value="">--None--</option><option value="Arutmin Cargo">Arutmin Cargo</option><option value="KPC Cargo">KPC Cargo</option><option value="KBN Cargo">KBN Cargo</option><option value="Others">Others</option>
                </Form.Select>
                {errors.product_family && <span>Required</span>}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Shipping Term"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="product_shipping_term"
                  placeholder="Shipping Term"
                  {...register("product_shipping_term", { required: false })}
                >
                   <option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                </Form.Select>
                {errors.product_shipping_term && <span>Required</span>}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="price type"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="product_price_type"
                  placeholder="Price type"
                  defaultValue=""
                  {...register("product_price_type", { required: false })}
                >
                  <option value="">Select</option>


{getPrice.length>0?(
        <>
        {getPrice.map((x) => {
                    return (
                      <option value={x.price_type_name}>
                        {x.price_type_name}
                      </option>
                    );
                  })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                </Form.Select>
                {errors.product_price_type && <span>Required</span>}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Port Of Loading"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Port Of Loading"
                  name="product_port_of_loading"
                  {...register("product_port_of_loading", { required: false })}
                />
                {errors.product_port_of_loading && (
                  <span>Required</span>
                )}
              </FloatingLabel>
            </Form.Group>
          </Row>

          <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>

                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Moisture Typical"
                      name="total_moisture_typical"
                        {...register("total_moisture_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur typical arb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur typical arb"
                      name="sulphur_typical_arb"
                     {...register("sulphur_typical_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="total moisture minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="total moisture minimum"
                      name="total_moisture_minimum"
                      {...register("total_moisture_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur minimum arb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur minimum arb"
                      name="sulphur_minimum_arb"
                     {...register("sulphur_minimum_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="total moisture maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="total moisture maximum"
                      name="total_moisture_maximum"
                      {...register("total_moisture_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur maximum arb "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur maximum arb "
                      name="sulphur_maximum_arb"
                         {...register("sulphur_maximum_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <p className='space-hight'></p>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash typical arb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ash typical arb"
                      name="ash_typical_arb"
                    {...register("ash_typical_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter typical arb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter typical arb"
                      name="volatile_matter_typical_arb"
                      {...register("volatile_matter_typical_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash minimum arb "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ash minimum arb "
                      name="ash_minimum_arb "
                      {...register("ash_minimum_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter minimum arb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter minimum arb"
                      name="volatile_matter_minimum_arb"
                     {...register("volatile_matter_minimum_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash maximum arb "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ash maximum arb "
                      name="ash_maximum_arb "
                        {...register("ash_maximum_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter maximum arb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter maximum arb"
                      name="volatile_matter_maximum_arb"
                     {...register("volatile_matter_maximum_arb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
        </Row>

        <Row className="mb-3">
                <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture typical"
                      name="moisture_typical"
                     {...register("moisture_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter typical"
                      name="volatile_matter_typical"
                      {...register("volatile_matter_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture minimum"
                      name="moisture_minimum"
                     {...register("moisture_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter minimum"
                      name="volatile_matter_minimum"
                     {...register("volatile_matter_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture maximum"
                      name="moisture_maximum"
                     {...register("moisture_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter maximum"
                      name="volatile_matter_maximum"
                    {...register("volatile_matter_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <p className='space-hight'></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ash typical"
                      name="ash_typical"
                       {...register("ash_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

          <input
          type='checkbox'
          name='fixed_carbon_by_difference'
          checked={fixedCorbon} // Use the state variable to determine if the checkbox is checked
          onChange={handleFixed} // Handle the change event
          className='mx-3'
        /><label>Fixed Carbon By Difference </label>
        </Form.Group>

                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ash minimum"
                      name="ash_minimum"
                     {...register("ash_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon typical"
                      name="fixed_carbon_typical"
                      {...register("fixed_carbon_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ash maximum"
                      name="ash_maximum"
                    {...register("ash_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon minimum"
                      name="fixed_carbon_minimum"
                      {...register("fixed_carbon_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon maximum"
                      name="fixed_carbon_maximum"
                      {...register("fixed_carbon_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical"
                      name="gross_air_dried_typical"
                      {...register("gross_air_dried_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as received typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="net as received typical"
                      name="net_as_received_typical"
                     {...register("net_as_received_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross air dried minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="gross air dried minimum"
                      name="gross_air_dried_min"
                      {...register("gross_air_dried_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as received minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="net as received minimum"
                      name="net_as_received_min"
                     {...register("net_as_received_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross air dried maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="gross air dried maximum"
                      name="gross_air_dried_max"
                    {...register("gross_air_dried_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as received maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="net as received maximum"
                      name="net_as_received_max"
                     {...register("net_as_received_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <p className='space-height'></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as received typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as received typical"
                      name="gross_as_received_typical"
                      {...register("gross_as_received_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as received min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as received min"
                      name="gross_as_received_min"
                     {...register("gross_as_received_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as received max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as received max"
                      name="gross_as_received_max"
                       {...register("gross_as_received_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group></Row>
              <Row className="mb-3">
                <h4 className='heading'>HGI</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="HGI typical"
                    name="hgi_typical"
                    {...register("hgi_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="HGI minimum"
                    name="hgi_min"
                       {...register("hgi_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="HGI Maximum"
                    name="hgi_max" {...register("hgi_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                </Row>
                <Row className="mb-3">
                <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon typical"
                      name="carbon_typical"
                      {...register("carbon_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur typical"
                      name="sulphur_typical"
                     {...register("sulphur_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon min"
                      name="carbon_min"
                        {...register("carbon_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur min"
                      name="sulphur_min"
                        {...register("sulphur_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon max"
                      name="carbon_max"
                     {...register("carbon_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur Maximum"
                      name="sulphur_max"
                      {...register("sulphur_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <p className='space-height'></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen typical"
                      name="hydrogen_typical"
                      {...register("hydrogen_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen typical"
                      name="oxygen_typical"
                       {...register("oxygen_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen min"
                      name="hydrogen_min"
                      {...register("hydrogen_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen min"
                      name="oxygen_min"
                     {...register("oxygen_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen max"
                      name="hydrogen_max"
                       {...register("hydrogen_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen max"
                      name="oxygen_max"
                      {...register("oxygen_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

                <p className="space-height"></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen typical"
                      name="nitrogen_typical"
                       {...register("nitrogen_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen min"
                      name="nitrogen_min"
                       {...register("nitrogen_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen max"
                      name="nitrogen_max"
                        {...register("nitrogen_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>SULPHUR %air dried basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                       {...register("sulphur_typical_adb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum Adb"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Sulphur Minimum Adb"
                    name="sulphur_min_adb"
                    {...register("sulphur_min_adb", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Sulphur Maximum"
                    name="product_sulphur_max"
                    {...register("product_sulphur_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

              </Row>
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="initial typical"
                      name="initial_typical"
                      {...register("initial_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical typical"
                      name="hemispherical_typical"
                     {...register("hemispherical_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="initial minimum"
                      name="initial_minimum"
                      {...register("initial_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical minimum"
                      name="hemispherical_minimum"
                      {...register("hemispherical_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="initial maximum"
                      name="initial_maximum"
                      {...register("initial_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical maximum"
                      name="hemispherical_maximum"
                       {...register("hemispherical_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

                <p className="space-height"></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical typical"
                      name="spherical_typical"
                       {...register("spherical_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="flow typical"
                      name="flow_typical"
                      {...register("flow_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical minimum"
                      name="spherical_minimum"
                        {...register("spherical_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="flow minimum"
                      name="flow_minimum"
                       {...register("flow_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical maximum"
                      name="spherical_maximum"
                         {...register("spherical_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="flow maximum"
                      name="flow_maximum"
                       {...register("flow_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>




                 </Row>
                 <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidizing Initial Deformtion Typical"
                      name="oxidizing_initial_typical"
                        {...register("oxidizing_initial_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical typical"
                      name="oxidising_hemispherical_typical"
                       {...register("oxidising_hemispherical_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation minimum"
                      name="oxidising_initial_minimum"
                      {...register("oxidising_initial_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical minimum"
                      name="oxidising_hemispherical_minimum"
                     {...register("oxidising_hemispherical_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation maximum"
                      name="oxidising_initial_maximum"
                      {...register("oxidising_initial_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical maximum"
                      name="oxidising_hemispherical_maximum"
                       {...register("oxidising_hemispherical_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                <p className="space-height"></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical typical"
                      name="oxidising_spherical_typical"
                      {...register("oxidising_spherical_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow typical"
                      name="oxidising_flow_typical"
                     {...register("oxidising_flow_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical minimum"
                      name="oxidising_spherical_minimum"
                      {...register("oxidising_spherical_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow minimum"
                      name="oxidising_flow_minimum"
                     {...register("oxidising_flow_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical maximum"
                      name="oxidising_spherical_maximum"
                        {...register("oxidising_spherical_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow maximum"
                      name="oxidising_flow_maximum"
                    {...register("oxidising_flow_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
                  </FloatingLabel>
                </Form.Group>
                </Row>

          <Row className="mb-3">
            <h4 className="heading">ASH ANALYSIS %Dry Basis in Ash</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="SiO2 Typical"
                  name="SiO2_Typical"
                  {...register("SiO2_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TiO2 Typical"
                  name="TiO2_Typical"
                   {...register("TiO2_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="SiO2 Minimum"
                  name="SiO2_Minimum"
                  {...register("SiO2_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TiO2 Minimum"
                  name="TiO2_Minimum"

                   {...register("TiO2_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="SiO2 Maximum"
                  name="SiO2_Maximum"
                   {...register("SiO2_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TiO2 Maximum"
                  name="TiO2_Maximum" {...register("TiO2_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Al2O3 Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Al2O3 Typical"
                  name="Al2O3_Typical" {...register("Al2O3_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Na2O Typical"
                  name="Na2O_Typical"  {...register("Na2O_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Al2O3 Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Al2O3 Minimum"
                  name="Al2O3_Minimum"  {...register("Al2O3_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Na2O Minimum"
                  name="Na2O_Minimum"  {...register("Na2O_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Al2O3 Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Al2O3 Maximum"
                  name="Al2O3_Maximum"  {...register("Al2O3_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Na2O Maximum"
                  name="Na2O_Maximum"  {...register("Na2O_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Fe2O3 Typical"
                  name="Fe2O3_Typical"  {...register("Fe2O3_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="K2O Typical"
                  name="K2O_Typical"  {...register("K2O_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Fe2O3 Minimum"
                  name="Fe2O3_Minimum"  {...register("Fe2O3_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="K2O Minimum"
                  name="K2O_Minimum" {...register("K2O_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Fe2O3 Maximum"
                  name="Fe2O3_Maximum" {...register("Fe2O3_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="K2O Maximum"
                  name="K2O_Maximum" {...register("K2O_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="CaO Typical"
                  name="CaO_Typical" {...register("CaO_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="P2O5 Typical"
                  name="P2O5_Typical" {...register("P2O5_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="CaO Minimum"
                  name="CaO_Minimum" {...register("CaO_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="P2O5 Minimum"
                  name="P2O5_Minimum" {...register("P2O5_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="CaO Maximum"
                  name="CaO_Maximum" {...register("CaO_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="P2O5 Maximum"
                  name="P2O5_Maximum" {...register("P2O5_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <p className="space-height"></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="MgO Typical"
                  name="MgO_Typical" {...register("MgO_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="SO3 Typical"
                  name="SO3_Typical" {...register("SO3_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="MgO Minimum"
                  name="MgO_Minimum" {...register("MgO_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="SO3 Minimum"
                  name="SO3_Minimum" {...register("SO3_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="MgO Maximum"
                  name="MgO_Maximum" {...register("MgO_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="SO3 Maximum"
                  name="SO3_Maximum" {...register("SO3_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Mn3O4 Typical"
                  name="MnO_Typical" {...register("MnO_Typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Mn3O4 Minimum"
                  name="MnO_Minimum" {...register("MnO_Minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Mn3O4 Maximum"
                  name="MnO_Maximum" {...register("MnO_Maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
          </Row>
          <Row className="mb-3">
            <h4 className="heading">Trace Elements, ppm Air Dried in Coal</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE arsenic as typical"
                  name="te_arsenic_as_typical_in_air_dried_coal"
                   {...register("te_arsenic_as_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE fluorine f typical"
                  name="te_fluorine_f_typical_in_air_dried_coal"
                   {...register("te_fluorine_f_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE rsenic as minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE arsenic as minimum"
                  name="te_arsenic_as_minimum_in_air_dried_coal"
                   {...register("te_arsenic_as_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE fluorine f minimum"
                  name="te_fluorine_f_minimum_in_air_dried_coal"
                   {...register("te_fluorine_f_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE arsenic as maximum"
                  name="te_arsenic_as_maximum_in_air_dried_coal"
                    {...register("te_arsenic_as_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE fluorine f maximum"
                  name="te_fluorine_f_maximum_in_air_dried_coal"
                   {...register("te_fluorine_f_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE antimony sb typical"
                  name="te_antimony_sb_typical_in_air_dried_coal"
                    {...register("te_antimony_sb_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE mercury hg typical"
                  name="te_mercury_hg_typical_in_air_dried_coal"
                   {...register("te_mercury_hg_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE antimony sb minimum"
                  name="te_antimony_sb_minimum_in_air_dried_coal"
                   {...register("te_antimony_sb_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE mercury hg minimum"
                  name="te_mercury_hg_minimum_in_air_dried_coal"
                   {...register("te_mercury_hg_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE antimony sb maximum"
                  name="te_antimony_sb_maximum_in_air_dried_coal"
                   {...register("te_antimony_sb_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE mercury hg maximum"
                  name="te_mercury_hg_maximum_in_air_dried_coal"
                   {...register("te_mercury_hg_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE Beryllium Be Typical"
                  name="te_beryllium_be_typical_in_air_dried_coal"
                   {...register("te_beryllium_be_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE molybdenum mo typical"
                  name="te_molybdenum_mo_typical_in_air_dried_coal"
                   {...register("te_molybdenum_mo_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE Beryllium Be Minimum"
                  name="te_beryllium_be_minimum_in_air_dried_coal"
                   {...register("te_beryllium_be_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE molybdenum mo minimum"
                  name="te_molybdenum_mo_minimum_in_air_dried_coal"
                   {...register("te_molybdenum_mo_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE Beryllium Be Maximum"
                  name="te_beryllium_be_maximum_in_air_dried_coal"
                   {...register("te_beryllium_be_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE molybdenum mo maximum"
                  name="te_molybdenum_mo_maximum_in_air_dried_coal"
                   {...register("te_molybdenum_mo_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

<p className='space-height'></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE boron b typical"
                  name="te_boron_b_typical_in_air_dried_coal"
                   {...register("te_boron_b_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE lead pb typical"
                  name="te_lead_pb_typical_in_air_dried_coal"
                   {...register("te_lead_pb_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE boron b minimum"
                  name="te_boron_b_minimum_in_air_dried_coal"
                   {...register("te_boron_b_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE lead pb minimum"
                  name="te_lead_pb_minimum_in_air_dried_coal"
                   {...register("te_lead_pb_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE boron b maximum"
                  name="te_boron_b_maximum_in_air_dried_coal"
                   {...register("te_boron_b_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE lead pb maximum"
                  name="te_lead_pb_maximum_in_air_dried_coal"
                   {...register("te_lead_pb_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE cadmium cd typical"
                  name="te_cadmium_cd_typical_in_air_dried_coal"
                   {...register("te_cadmium_cd_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p typical"
                  name="te_phosphorus_p_typical_in_air_dried_coal"
                   {...register("te_phosphorus_p_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE cadmium cd minimum"
                  name="te_cadmium_cd_minimum_in_air_dried_coal"
                   {...register("te_cadmium_cd_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p minimum"
                  name="te_phosphorus_p_minimum_in_air_dried_coal"
                   {...register("te_phosphorus_p_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadamium cd maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE cadamium cd maximum"
                  name="te_cadamium_cd_maximum_in_air_dried_coal"
                   {...register("te_cadamium_cd_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p maximum"
                  name="te_phosphorus_p_maximum_in_air_dried_coal"
                   {...register("te_phosphorus_p_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chromium cr typical"
                  name="te_chromium_cr_typical_in_air_dried_coal"
                   {...register("te_chromium_cr_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se typical"
                  name="te_selenium_se_typical_in_air_dried_coal"
                   {...register("te_selenium_se_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chromium cr minimum"
                  name="te_chromium_cr_minimum_in_air_dried_coal"
                   {...register("te_chromium_cr_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se minimum"
                  name="te_selenium_se_minimum_in_air_dried_coal"
                   {...register("te_selenium_se_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chromium cr maximum"
                  name="te_chromium_cr_maximum_in_air_dried_coal"
                   {...register("te_chromium_cr_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se maximum"
                  name="te_selenium_se_maximum_in_air_dried_coal"
                   {...register("te_selenium_se_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
<p className="space-height"></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE copper cu typical"
                  name="te_copper_cu_typical_in_air_dried_coal"
                   {...register("te_copper_cu_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v typical"
                  name="te_vanadium_v_typical_in_air_dried_coal"
                   {...register("te_vanadium_v_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE copper cu minimum"
                  name="te_copper_cu_minimum_in_air_dried_coal"
                   {...register("te_copper_cu_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v min"
                  name="te_vanadium_v_min_in_air_dried_coal"
                   {...register("te_vanadium_v_min_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE copper cu maximum"
                  name="te_copper_cu_maximum_in_air_dried_coal"
                   {...register("te_copper_cu_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v max"
                  name="te_vanadium_v_max_in_air_dried_coal"
                   {...register("te_vanadium_v_max_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chlorine cl typical"
                  name="te_chlorine_cl_typical_in_air_dried_coal"
                   {...register("te_chlorine_cl_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE zinc zn typical"
                  name="te_zinc_zn_typical_in_air_dried_coal"
                   {...register("te_zinc_zn_typical_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chlorine cl minimum"
                  name="te_chlorine_cl_minimum_in_air_dried_coal"
                   {...register("te_chlorine_cl_minimum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE zinc zn min"
                  name="te_zinc_zn_min_in_air_dried_coal"
                   {...register("te_zinc_zn_min_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chlorine cl maximum"
                  name="te_chlorine_cl_maximum_in_air_dried_coal"
                   {...register("te_chlorine_cl_maximum_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE zinc zn max"
                  name="te_zinc_zn_max_in_air_dried_coal"
                   {...register("te_zinc_zn_max_in_air_dried_coal", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            </Row>

         <Row className="mb-3">
            <h4 className="heading">Trace Elements, ppm Dried Basis</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE arsenic as typical"
                  name="te_arsenic_as_typical"
                   {...register("te_arsenic_as_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE fluorine f typical"
                  name="te_fluorine_f_typical"
                   {...register("te_fluorine_f_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE rsenic as minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE arsenic as minimum"
                  name="te_arsenic_as_minimum"
                   {...register("te_arsenic_as_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE fluorine f minimum"
                  name="te_fluorine_f_minimum"
                   {...register("te_fluorine_f_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE arsenic as maximum"
                  name="te_arsenic_as_maximum"
                    {...register("te_arsenic_as_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE fluorine f maximum"
                  name="te_fluorine_f_maximum"
                   {...register("te_fluorine_f_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE antimony sb typical"
                  name="te_antimony_sb_typical"
                    {...register("te_antimony_sb_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE mercury hg typical"
                  name="te_mercury_hg_typical"
                   {...register("te_mercury_hg_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE antimony sb minimum"
                  name="te_antimony_sb_minimum"
                   {...register("te_antimony_sb_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE mercury hg minimum"
                  name="te_mercury_hg_minimum"
                   {...register("te_mercury_hg_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE antimony sb maximum"
                  name="te_antimony_sb_maximum"
                   {...register("te_antimony_sb_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE mercury hg maximum"
                  name="te_mercury_hg_maximum"
                   {...register("te_mercury_hg_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE Beryllium Be Typical"
                  name="te_beryllium_be_typical"
                   {...register("te_beryllium_be_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE molybdenum mo typical"
                  name="te_molybdenum_mo_typical"
                   {...register("te_molybdenum_mo_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE Beryllium Be Minimum"
                  name="te_beryllium_be_minimum"
                   {...register("te_beryllium_be_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE molybdenum mo minimum"
                  name="te_molybdenum_mo_minimum"
                   {...register("te_molybdenum_mo_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE Beryllium Be Maximum"
                  name="te_beryllium_be_maximum"
                   {...register("te_beryllium_be_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE molybdenum mo maximum"
                  name="te_molybdenum_mo_maximum"
                   {...register("te_molybdenum_mo_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

<p className='space-height'></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE boron b typical"
                  name="te_boron_b_typical"
                   {...register("te_boron_b_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE lead pb typical"
                  name="te_lead_pb_typical"
                   {...register("te_lead_pb_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE boron b minimum"
                  name="te_boron_b_minimum"
                   {...register("te_boron_b_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE lead pb minimum"
                  name="te_lead_pb_minimum"
                   {...register("te_lead_pb_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE boron b maximum"
                  name="te_boron_b_maximum"
                   {...register("te_boron_b_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE lead pb maximum"
                  name="te_lead_pb_maximum"
                   {...register("te_lead_pb_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE cadmium cd typical"
                  name="te_cadmium_cd_typical"
                   {...register("te_cadmium_cd_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p typical"
                  name="te_phosphorus_p_typical"
                   {...register("te_phosphorus_p_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE cadmium cd minimum"
                  name="te_cadmium_cd_minimum"
                   {...register("te_cadmium_cd_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p minimum"
                  name="te_phosphorus_p_minimum"
                   {...register("te_phosphorus_p_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadamium cd maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE cadamium cd maximum"
                  name="te_cadmium_cd_maximum"
                   {...register("te_cadmium_cd_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p maximum"
                  name="te_phosphorus_p_maximum"
                   {...register("te_phosphorus_p_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chromium cr typical"
                  name="te_chromium_cr_typical"
                   {...register("te_chromium_cr_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se typical"
                  name="te_selenium_se_typical"
                   {...register("te_selenium_se_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chromium cr minimum"
                  name="te_chromium_cr_minimum"
                   {...register("te_chromium_cr_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se minimum"
                  name="te_selenium_se_minimum"
                   {...register("te_selenium_se_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chromium cr maximum"
                  name="te_chromium_cr_maximum"
                   {...register("te_chromium_cr_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se maximum"
                  name="te_selenium_se_maximum"
                   {...register("te_selenium_se_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
<p className="space-height"></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE copper cu typical"
                  name="te_copper_cu_typical"
                   {...register("te_copper_cu_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v typical"
                  name="te_vanadium_v_typical"
                   {...register("te_vanadium_v_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE copper cu minimum"
                  name="te_copper_cu_minimum"
                   {...register("te_copper_cu_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v min"
                  name="te_vanadium_v_min"
                   {...register("te_vanadium_v_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE copper cu maximum"
                  name="te_copper_cu_maximum"
                   {...register("te_copper_cu_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v max"
                  name="te_vanadium_v_max"
                   {...register("te_vanadium_v_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chlorine cl typical"
                  name="te_chlorine_cl_typical"
                   {...register("te_chlorine_cl_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE zinc zn typical"
                  name="te_zinc_zn_typical"
                   {...register("te_zinc_zn_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chlorine cl minimum"
                  name="te_chlorine_cl_minimum"
                   {...register("te_chlorine_cl_minimum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE zinc zn min"
                  name="te_zinc_zn_min"
                   {...register("te_zinc_zn_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE chlorine cl maximum"
                  name="te_chlorine_cl_maximum"
                   {...register("te_chlorine_cl_maximum", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE zinc zn max"
                  name="te_zinc_zn_max"
                   {...register("te_zinc_zn_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <h4 className="heading">SIZING, %</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size 0 to 50mm typical"
                  name="size_0_to_50mm_typical"
                   {...register("size_0_to_50mm_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Under 2mm typical"
                  name="under_2mm_typical"
                   {...register("under_2mm_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size 0 to 50mm min"
                  name="size_0_to_50mm_min"
                   {...register("size_0_to_50mm_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Under 2mm min"
                  name="under_2mm_min"
                   {...register("under_2mm_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>{" "}

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size 0 to 50mm max"
                  name="size_0_to_50mm_max"
                   {...register("size_0_to_50mm_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Under 2mm max"
                  name="under_2mm_max"
                   {...register("under_2mm_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>{" "}
            <p className='space-height'></p>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Above 0 to 50mm typical"
                  name="above_0_to_50mm_typical"
                   {...register("above_0_to_50mm_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size above 2mm typical"
                  name="size_above_2mm_typical"
                   {...register("size_above_2mm_typical", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>{" "}

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Above 0 to 50mm min"
                  name="above_0_to_50mm_min"
                   {...register("above_0_to_50mm_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>{" "}

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size above 2mm min"
                  name="size_above_2mm_min"
                   {...register("size_above_2mm_min", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Above 0 to 50mm max"
                  name="above_0_to_50mm_max"
                   {...register("above_0_to_50mm_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>{" "}


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm max"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size above 2mm max"
                  name="size_above_2mm_max"  {...register("size_above_2mm_max", {
                    valueAsNumber: true,
                    required: false,
                  })} />
              </FloatingLabel>
            </Form.Group>
            </Row>
          <Row className="mb-3">
            <h4 className="heading">Testing Method</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Testing Method"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="testing_method"
                  placeholder="Testing method"
                   {...register("testing_method", {
                    valueAsNumber: false,
                    required: false,
                  })}
                >
                  <option defaultValue="">Select </option>
                  <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                    According to ASTM Standard Method Unless Otherwise Stated
                  </option>
                  <option defaultValue="According to ISO Methods">
                    According to ISO Methods
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                    According to ISO Methods Except HGI, Trace Elements and Ash
                    Analysis to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI to ASTM">
                    According to ISO Methods Except HGI to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                    According to ISO Methods, Except HGI and Ash Analysis to
                    ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                    According to ISO Methods, Except HGI as per ASTM Test Method
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/productmaster/create-view-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
          <div className="successDiv">
            <p></p><p></p>
          </div>
        </Form>
        <p></p><p></p>
        </div> </div> </div>
      <Footer />
    </>
  );
};

export default NewProduct;
