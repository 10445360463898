import React, { useState } from "react";
import axios from "axios";
import { API_URL } from '../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Testdate = () => {
const [date, setDate] = useState("");

const handleDateChange = (time) => {
setDate(time);
};

const handleSubmit = () => {
const apiUrl = API_URL+"add/postshipment/1";  

const requestData = {
date: date,
};
console.log(JSON.stringify(requestData))
axios
.post(apiUrl, requestData)
.then((response) => {
// Handle successful response
console.log(response.data);
})
.catch((error) => {
// Handle error
console.error(error);
});
};
return (
<div>
<DatePicker
selected={date}
onChange={handleDateChange}
showTimeSelect
dateFormat="yyyy-MM-dd hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
/>
<button onClick={handleSubmit}>Submit</button>
</div>
);
};

export default Testdate;