import React, { useEffect, useState } from 'react'

import { Link } from "react-router-dom";
import axios from "axios";


import { API_URL } from "../config";

import './recentitems.css'



const RecentItems = () => {

    const [recentItems, setRecentItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const fetchRecentItems = async () => {

        try {
            setIsLoading(true)

            const URL = `${API_URL}get/recent_items`

            const recentItemsResponse = await axios.get(URL, {

                headers: {

                    "Content-Type": "application/json",

                }

            })

            if (recentItemsResponse.status === 200) {

                const data = recentItemsResponse?.data?.recent_items

                setRecentItems(data)

            } else {

                setRecentItems([])

            }
        } catch (err) {

            console.log(err, 'err')

        } finally {

            setIsLoading(false)

        }

    }


    useEffect(() => {

        fetchRecentItems()

    }, [])

    return (

        <div className='recent_items_container'>

            <div className='recent_items_header'>

                <p>Recent Items</p>

            </div>

            <div className='recent_items_list'>

                {isLoading ? (

                    <div className="rwood_spinner">

                        <img src="../../../images/loader.svg" alt="spinner" />
                    </div>

                ) : (

                    <ul>

                        {recentItems.map((item, index) => (

                            <li key={item.id}>

                                <Link to={item.url}>{item.code}</Link>

                            </li>

                        ))}

                    </ul>


                )}
            </div>

        </div>
    )

}

export default RecentItems