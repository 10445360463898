/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

const TreeNode = ({ node, selectedNodeId, setSelectedNodeId, index }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren = node.children && node.children.length > 0;

    const toggle = () => {

        setIsOpen(!isOpen);
        
    };

    const handleClick = () => {

        setSelectedNodeId(node.id);

    };

    return (
        <Draggable draggableId={node.id} index={index}>
            {(provided) => (
                <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div
                        onClick={() => {
                            toggle();
                            handleClick();
                        }}
                        className={`caret ${hasChildren ? "parent" : ""} ${selectedNodeId === node.id ? "selected" : ""} ${isOpen ? "caret-down" : ""}`}
                    >
                        {node.label}
                    </div>
                    {hasChildren && isOpen && (
                        <Droppable droppableId={`droppable-${node.id}`} type="FIELD">
                            {(provided) => (
                                <ul
                                    className="nested"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {node.children.map((child, index) => (
                                        <TreeNode
                                            key={child.id}
                                            node={child}
                                            selectedNodeId={selectedNodeId}
                                            setSelectedNodeId={setSelectedNodeId}
                                            index={index}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    )}
                </li>
            )}
        </Draggable>
    );
};

export default TreeNode;
