import React, { useState, useMemo, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { AES, enc } from "crypto-js";
import $ from 'jquery';
import { Link } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function AddPrice() {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [datas,setDatas]=useState([]);
  const [getPrice, setgetPrice]=useState([]);

 const { register, handleSubmit, reset, formState: { errors } } = useForm();
 const onSubmit = data =>{
     const responseData={
      price_description: data.price_description,
      price_type_name:data.price_type_name,
      price_sr_no:data.price_sr_no,
   };

   const encryptedAccessToken = localStorage.getItem('access_token');
     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

     fetch(API_URL+'add/price_item', {
               method: "POST",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())

               .then((response) => {
                console.log('Test'+JSON.stringify(response))
                toast.success('Price Type Successfully', {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                setTimeout(() => {
                  reset();
                }, 300);

               })

             }

     };
     const adminRole=localStorage.getItem('role')
  return (

    <div className="priceAccount" id='prices'>
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Price type</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Price type</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
     <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/price/price-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>

      <Row className="mb-3">
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price type name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="price type name"
              name="price_type_name"
              {...register("price_type_name", { required: true })}
            />
            {errors.price_type_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Owner"
              name="owner"
              className='record-type'
              defaultValue={adminRole}
              {...register("owner", { required: false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price Description"
            className="mb-3"


          >
            <Form.Control
              as="textarea"
              placeholder="Price Description"
              name="price_description"
              {...register("price_description", { required: false })}
            />
            {errors.price_description && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price sr no"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Price sr no"
              id='account_name'
              name="price_sr_no"
              {...register("price_sr_no", { required: false })}
            />
            {errors.price_sr_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>
      <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/price/price-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>

      <div className='priceDiv'><p></p><p></p></div>
    </Form>
    </div>
    <Footer/>
    </div>
  );
}

export default AddPrice;
