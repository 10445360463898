import React, { useState, useMemo, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import {  Link } from "react-router-dom";
import Header from '../Header'
import Footer from '../Footer'
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { API_URL } from '../config';
import { AES, enc } from "crypto-js";
import $ from 'jquery';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function CounterParty() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
    const params = useParams();
    const id = params.id;
    const userRole = localStorage.getItem("role");
    const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
 const onSubmit = data =>{

      const encryptedAccessToken = localStorage.getItem('access_token');
     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

     fetch(`${API_URL}add/counter_party/`+id, {
               method: "POST",
               body: JSON.stringify(data),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())

               .then((response) => {
                console.log('Test'+JSON.stringify(response))
                toast.success('Counter Party Added Successfully', {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                  navigate("/accounts/" + id);
                setTimeout(() => {
                  reset();
                }, 300);

               })

             }

     };

  return (

    <div className="priceAccount" id='prices'>
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Counter Party</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Accounts/ Add Counter Party</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
     <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/accounts/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Name"
              name="name"
              {...register("name", { required: false })}
            />
            {errors.name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="owner"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="owner"
            name="owner"
            defaultValue={userRole|| ""}
            />
        </FloatingLabel>
      </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Origin"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Origin"
              name="origin"
              {...register("origin", { required: false })}
            />
            {errors.origin && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="GAR or NAR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="GAR or NAR"
              id='account_name'
              name="gar_or_nor"
              {...register("gar_or_nor", { required: false })}
            />
            {errors.gar_or_nor && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="GAR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="GAR"
              id='account_name'
              name="gar"
              {...register("gar", { required: false })}
            />
            {errors.gar && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TM"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="TM"
              id='account_name'
              name="tm"
              {...register("tm", { required: false })}
            />
            {errors.tm && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ASH"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="ASH"
              id='account_name'
              name="ash"
              {...register("ash", { required: false })}
            />
            {errors.ash && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TS"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="TS"
              id='account_name'
              name="ts"
              {...register("ts", { required: false })}
            />
            {errors.ts && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>
      <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/accounts/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <div className='priceDiv'><p></p><p></p></div>
    </Form>
    </div>
    <Footer/>
    </div>
  );
}

export default CounterParty;
