import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { AES, enc } from 'crypto-js'; 
import Header from '../Header'
import Footer from '../Footer'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { API_URL } from '../config';
const AddRecord = () => {

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $("#addRecord").css({"min-height":(window_height)+"px"})
  const navigate = useNavigate();

  const [formData, setFromdata] = useState({

    account_record: "",

  });


  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = (event) => {

    event.preventDefault();


    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch(`${API_URL}add/record`, {
              method: "POST",
              body: JSON.stringify(formData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                
                  console.log(response)
                  console.log(response.status)
                if (response.message === "record addedd successfully") {
                                  const account_record_new = response.account_type;
                                  
                                  navigate("/accounts/new-account", {
                        
                                    state: { account_record: account_record_new },
                        
                                  });
                        
                                }
              }) 
            } 
             
    };


     
  return (
<div id='addRecord'>
    <Header/>
    <div className="contactAccount">
      <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Account/ Account Record</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
      </div>
    </div> 
  </div> 
      <form onSubmit={handleSubmit}>
      <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Account Record">
    <Form.Select aria-label="Floating label select example"
       name="account_record"

       value={formData.account_record}

       onChange={handleChange}

    defaultValue=""
    >


<option value="">--Select account record type--</option>

<option value="Agent">Agent</option>

<option value="Supplier">Supplier</option>


      <option value="Bank">Bank</option>

<option value="Buyer">Buyer</option>

<option value="Competitor">Competitor</option>

<option value="Employee">Employee</option>

<option value="Surveyor">Surveyor</option>
      
        
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
       
       
     
              <input type="submit"  className="account-record" value='Save'/>

      </form>

    </div>

 <Footer/>
 
 </div>
  );

};


export default AddRecord;

