import React, {
  Component,
  useState,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { parse, format } from 'date-fns';
import { AES, enc } from "crypto-js";
import { API_URL } from '../config';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import Popup from "reactjs-popup";
import Button from "react-bootstrap/Button";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import $ from "jquery";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { BiSolidHelpCircle } from "react-icons/bi";
import { IoIosHelpCircle } from "react-icons/io";
import { useParams } from "react-router-dom";
const AccountViewPage = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  // Account Name Validation
  const [data2, setData2] = useState({ account_name: '' });
  const [errors, setErrors] = useState({ account_name: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);





  // tooltips section

  const [reference1, setReference1] = useState(new Date());
  const referenceDate =
    reference1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    reference1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    reference1.toLocaleDateString("en-US", { day: "2-digit" });
  const ReferenceInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      name=""
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const trader = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Trader
    </Tooltip>
  );

  const phone = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Phone numbers are a great way to call people on phones. Phones are what we
      call our pocket computers even though we use them for games, selfies, and
      facebook.
    </Tooltip>
  );
  const [startDate1, setStartDate1] = useState(new Date());
  const birthDate =
    startDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      name="birthdate"
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [kycData, setKycdata] = useState([]);

  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  // edit page
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please Enter Full Company Name here
    </Tooltip>
  );
  const renderAnual = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      In MT
    </Tooltip>
  );

  const [account_name, setaccount_name] = useState("");
  // const [documents, setDocuments] = useState([
  //   { document_name: "", validity: "" },
  // ]);

  // const handleDocumentChange = (index, field, value) => {
  //   const updatedDocuments = [...documents];
  //   updatedDocuments[index][field] = value;

  //   setDocuments(updatedDocuments);
  // };

  // const handleAddDocument = () => {
  //   setDocuments([...documents, { document_name: "", validity: "" }]);
  // };

  // const handleRemoveDocument = (index) => {
  //   const updatedDocuments = [...documents];
  //   updatedDocuments.splice(index, 1);
  //   setDocuments(updatedDocuments);
  // };

  const [data1, setData1] = useState([]);
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [surveyors, setSurveyor] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setData1((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'account_name') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        account_name: value.trim() === '' ? 'Error: You must enter a value' : '',
      }));
    }

    setData2((prevData) => ({ ...prevData, [name]: value }));
  };

  setTimeout(() => {
    $("#opp").DataTable();$("#opp-new").DataTable();$("#debit").DataTable();
    $("#contact").DataTable();$("#products-master").DataTable();$("#products").DataTable();
    $("#contract").DataTable();$("#order-supplier").DataTable();
    $("#order-surveyour-1").DataTable(); $("#product").DataTable();  $("#order-surveyour").DataTable();
    $("#orders").DataTable();$("#contractSupplier").DataTable();
    $("#quotes").DataTable();
    $('#quotes-details').DataTable();$('#receipt').DataTable();
    $("#receivable").DataTable();$("#tcf").DataTable();
    $("#historys").dataTable(); $("#history").dataTable();

    $("#counter").dataTable();
    $("#kyc").dataTable();
  }, 100);
  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
    setTimeout(() => {
      $("#opp").DataTable();$("#opp-new").DataTable();$("#debit").DataTable();
      $("#contact").DataTable();$("#products-master").DataTable();$("#products").DataTable();
      $("#contract").DataTable();$("#order-supplier").DataTable();
      $("#order-surveyour-1").DataTable(); $("#product").DataTable();  $("#order-surveyour").DataTable();
      $("#orders").DataTable();$("#contractSupplier").DataTable();
      $("#quotes").DataTable();
      $('#quotes-details').DataTable();$('#receipt').DataTable();
      $("#receivable").DataTable();$("#tcf").DataTable();
      $("#historys").dataTable(); $("#history").dataTable();
      $("#counter").dataTable();
      $("#kyc").dataTable();
    }, 100);
  };

  const [formDatatcf, setFromdatatcf] = useState({
    account_record: "",
  });

  const handleChangetcf = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmittcf = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/record`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            const account_record_new = response.account_type;

            navigate("/tcf/tcf-by-account/" + id, {
              state: { account_record: account_record_new },
            });
            if (response.message === "record addedd successfully") {

            }
          });
      }
    }
  };

  // contract
  const [formDatacontract, setFromdatacontract] = useState({
    account_record: "",
  });

  const handleChangecontract = (e) => {
    setFromdatacontract({ ...formData, [e.target.name]: e.target.value });
  };

  const [error, setError] = useState({});
  const validate = () => {
    const newErrors = {};
    if (!formData.account_record) {
      newErrors.account_record = 'Record Type is required';
    }
    return newErrors;
  };
  const handleSubmitcontract = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/record`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            const account_record_new = response.account_type;

            navigate("/account/contract-by-account/" + id, {
              state: { account_record: account_record_new },
            });
            if (response.message === "record addedd successfully") {

            }
          });
      }
    }
  };

  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/record`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            const account_record_new = response.account_type;

            navigate("/opportunity/opportunity-by-account/" + id, {
              state: { account_record: account_record_new },
            });
            if (response.message === "record addedd successfully") {

            }
          });
      }
    }
  };

  const userRole = localStorage.getItem("role");
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [kyc, setKyc] = useState([]);
  const [history, setHistory] = useState([]);
  const [opportunity, setOpportunity] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [tcfs, setTcfs] = useState([]);
  const [kycDocDate, setKYCDocDate] = useState('');
  const [refrenceCheckDates, setRefrenceCheckDate] = useState('');

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken, id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}accounts/all/` + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        const dataDetails = response.data[0].account_details;
console.log(data)
        const kycDoc = response.data[0].kyc_docs;
        // const kycDocDates = response.data[0].account_details.KYC_doc_date;
        // const parsedDate = parse(kycDocDates, 'dd/MM/yyyy', new Date());
        // const refrence_check_date=parse(response.data[0].account_details.refrence_check_date, 'dd/MM/yyyy', new Date());
        // setKYCDocDate(parsedDate);
        // setRefrenceCheckDate (refrence_check_date)
        setKycdata(kycDoc)
        setData1(dataDetails);
        setDatas(data);
        // setKyc(kycDoc);
        setHistory(data[0].history)
        setOpportunity(data[0].opportunity)
        setContacts(data[0].contacts)
        setTcfs(data[0].tcf)
        setTimeout(() => {
          $("#opp").DataTable();$("#opp-new").DataTable();$("#debit").DataTable();
          $("#contact").DataTable();$("#products-master").DataTable();$("#products").DataTable();
          $("#contract").DataTable();$("#order-supplier").DataTable();
          $("#order-surveyour-1").DataTable(); $("#product").DataTable();  $("#order-surveyour").DataTable();
          $("#orders").DataTable();$("#contractSupplier").DataTable();
          $("#quotes").DataTable();
          $("#receivable").DataTable();$("#tcf").DataTable();
          $("#historys").dataTable(); $("#history").dataTable();
          $("#counter").dataTable();
          $("#kyc").dataTable();
        }, 100);
      }
      else {
        toast.error("Do not have Data", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    } catch (error) {
      // console.log(error);
    }
  };





  useEffect(() => {
    setDocuments(kycData);
  }, [kycData]);
  const [documents, setDocuments] = useState(kycData);

  const handleDocumentChange = (index, field, value) => {
    const updatedDocuments = [...documents];
    updatedDocuments[index][field] = value;
    setDocuments(updatedDocuments);
  };

  const handleAddDocument = () => {
    setDocuments([...documents, { document_name: "", validity: "" }]);
  };

  const handleRemoveDocument = (index) => {
    const updatedDocuments = documents.filter((_, i) => i !== index);
    setDocuments(updatedDocuments);
  };


  // Date picker

  const handleDateChange = (date) => {
    setKYCDocDate(date);
  };
  const handleRefrenceCheck = (date) => {
    setRefrenceCheckDate(date);
  };


  const contactHandle = () => {
    navigate('/contact/contact-by-account/' + id, {
      state: { accountName: data1.account_name },
    })
  }

  const [isFormsubmit, setFormsubmited] = useState(false);

  const handleSaveEdit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      $("#opp").DataTable();$("#opp-new").DataTable();$("#debit").DataTable();
      $("#contact").DataTable();$("#products-master").DataTable();$("#products").DataTable();
      $("#contract").DataTable();$("#order-supplier").DataTable();
      $("#order-surveyour-1").DataTable(); $("#product").DataTable();  $("#order-surveyour").DataTable();
      $("#orders").DataTable();$("#contractSupplier").DataTable();
      $("#quotes").DataTable();
      $('#quotes-details').DataTable();$('#receipt').DataTable();
      $("#receivable").DataTable();$("#tcf").DataTable();
      $("#historys").dataTable(); $("#history").dataTable();
      $("#counter").dataTable();
      $("#kyc").dataTable();
    }, 100);
    setFormsubmited(true)

    const responseData = {
      account_name: data1.account_name,
      account_alias: data1.account_alias,
      website: data1.website,
      account_owner: data1.account_owner,
      parent_account: data1.parent_account,
      account_number: data1.account_number,
      account_record_type: data1.account_record_type,
      annual_revenue: data1.annual_revenue,
      no_of_employees: data1.no_of_employees,
      port: data1.port,
      origin: data1.origin,
      company: data1.company,
      employees: data1.employees,
      remarks: data1.remarks,
      product: data1.product,
      general_terms: data1.general_terms,
      region: data1.region,
      billing_street: data1.billing_street,
      phone: data1.phone,
      shipping_street: data1.shipping_street,
      billing_city: data1.billing_city,
      shipping_postal_code: data1.shipping_postal_code,
      shipping_city: data1.shipping_city,
      shipping_state_or_province: data1.shipping_state_or_province,
      shipping_country: data1.shipping_country,
      billing_state_or_province: data1.billing_state_or_province,
      billing_country: data1.billing_country,
      fax: data1.fax,
      billing_postal_code: data1.billing_postal_code,
      description: data1.description,
      KYC_docs: data1.KYC_docs,
      // KYC_doc_date:format(kycDocDate, 'dd/MM/yyyy')?format(kycDocDate, 'dd/MM/yyyy'):null,
      // refrence_check_date: format(refrenceCheckDates, 'dd/MM/yyyy')?format(refrenceCheckDates, 'dd/MM/yyyy'):null,
      // // "refrence_check": data1.refrence_check,
      commitment: data1.commitment,
      reliability: data1.reliability,
      under_group: data1.under_group,
      station_name: data1.station_name,
      expansion_setup_or_buyer_status: data1.expansion_setup_or_buyer_status,
      industry: data1.industry,
      sector: data1.sector,
      market_impression_rating: data1.market_impression_rating,
      annual_coal_requirement_in_MT: data1.annual_coal_requirement_in_MT,
      imported_volume_PA_in_MT: data1.imported_volume_PA_in_MT,
      quantity_MT_monthly: data1.quantity_MT_monthly,
      production_capacity: data1.production_capacity,
      originiaze_import_breakup: data1.originiaze_import_breakup,
      account_source: data1.account_source,
      rating: data1.rating,
      coal_spec_1_3800_GAR: data1.coal_spec_1_3800_GAR,
      coal_spec_2_4200_GAR: data1.coal_spec_2_4200_GAR,
      coal_spec_3_4700_GAR: data1.coal_spec_3_4700_GAR,
      coal_spec_4_5000_GAR: data1.coal_spec_4_5000_GAR,
      coal_spec_5_5500_GAR: data1.coal_spec_5_5500_GAR,
      coal_spec_6: data1.coal_spec_6,
      coal_spec_7: data1.coal_spec_7,
      coal_spec_8: data1.coal_spec_8,
      berth_details: data1.berth_details,
      nomination_of_vessel: data1.nomination_of_vessel,
      loading_demurge_and_dispatch_details: data.loading_demurge_and_dispatch_details,
      payment_terms: data1.payment_terms,
      documents
    };
    axios.put(`${API_URL}update/account/${id}`, responseData, {
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {


        if (response.status === 200) {
          toast.success("Order Updated Successfully",
            {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          fetchData();

          setTimeout(() => {
            $("#opp").DataTable();$("#opp-new").DataTable();$("#debit").DataTable();
            $("#contact").DataTable();$("#products-master").DataTable();$("#products").DataTable();
            $("#contract").DataTable();$("#order-supplier").DataTable();
            $("#order-surveyour-1").DataTable(); $("#product").DataTable();  $("#order-surveyour").DataTable();
            $("#orders").DataTable();$("#contractSupplier").DataTable();
            $("#quotes").DataTable();
            $("#receivable").DataTable();$("#tcf").DataTable();
            $("#historys").dataTable(); $("#history").dataTable();
            $("#counter").dataTable();
            $("#kyc").dataTable();
          }, 100);
          fetchData();

          setData(response);
          setIsEditMode(false);
        }

        else {
          toast.error("Check with fields", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }
      })
      .catch((error) => console.error(error));

  }





  //  Recent Items

  const [recentItems, setRecentItems] = useState([])
  const [loading, setLoading] = useState(true);

  const fetchRecent = async () => {
    try {
      const response = await axios.get(`${API_URL}get/recent_items`);
      if (response.status === 200) {
        setRecentItems(response.data.recent_items)
      }

    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchRecent();
  }, []);





  return (
    <div id="view-page" className="account-detail-section full-container">
      <Header />

      <div className="row">
        <div className="col-md-2 recent-item">
          <h4>Recent Items</h4>
          {loading ? (
            <p className="loading-text">Loading...</p>
          ) : (
            <div>
              {recentItems.map((x, i) => (
                <p><Link to={x.url}>{x.code}</Link></p>
              ))}
            </div>
          )}
        </div>
        <div className='col-md-10 right-section'>
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts Details </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Accounts Details
                </li>
              </ol>
            </div>
          </div>
          <>
            {datas.length > 0 ? (
              <>
                {datas.map((x, i) => (
                  <>
                    {x.account_details.account_record_type === "Buyer" ? (
                      <>
                        {isEditMode ? (
                          <div className="tasks">
                            <p className="edit-btn">
                              <input
                                type="submit"
                                className="account-save"
                                onClick={handleSaveEdit}
                                value={isSubmitted ? "Saving" : "Save"}
                                disabled={isSubmitted}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </button>
                            </p>

                            <Row className="mb-3">
                              <h4 className="heading">Account Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Account Name"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Account Name"
                                    name="account_name"
                                    defaultValue={data1.account_name || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                {errors.account_name && (
                                  <div className="text-danger">{errors.account_name}</div>
                                )}
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Account Owner"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Account Owner"
                                    name="account_owner"
                                    defaultValue={data1.account_owner || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Account Alias"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Account Alias"
                                    name="account_alias"
                                    className="error-validation"
                                    defaultValue={data1.account_alias || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Parent Account"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Parent Account"
                                    name="parent_account"
                                    defaultValue={data1.parent_account || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Website"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Website"
                                    name="website"
                                    defaultValue={data1.website || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Account Number"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Account Number"
                                    name="account_number"
                                    defaultValue={data1.account_number || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6" className="tools">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Trader"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Trader"
                                    name="trader"
                                    defaultValue={data1.trader || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={trader}
                                >
                                  <Button variant="success" className="tooltips">
                                    <IoIosHelpCircle id="help" />
                                  </Button>
                                </OverlayTrigger>{" "}
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Account record type"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Account record type"
                                    value={data1.account_record_type}
                                    name="account_record_type"
                                    className="record-type"
                                    defaultValue={data1.account_record_type || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Annual Revenue"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Annual Revenue"
                                    name="annual_revenue"
                                    defaultValue={data1.annual_revenue || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="No of employees"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="No of employees"
                                    name="no_of_employees"
                                    defaultValue={data1.no_of_employees || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row>
                              <h4 className="heading">Address Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Region"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Region"
                                    name="region"
                                    defaultValue={data1.region || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingTextarea2"
                                  label="Billing Street"
                                >
                                  <Form.Control
                                    as="textarea" id='textareas'
                                    placeholder="Billing Street"
                                    style={{ height: "100px" }}
                                    name="billing_street"
                                    defaultValue={data1.billing_street || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingTextarea2"
                                  label="Shipping Street"
                                >
                                  <Form.Control
                                    as="textarea" id='textareas'
                                    placeholder="Shipping Street"
                                    style={{ height: "100px" }}
                                    name="shipping_street"
                                    defaultValue={data1.shipping_street || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Billing postal code"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Billing postal code"
                                    name="billing_postal_code"
                                    defaultValue={data1.billing_postal_code || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Shipping postal code"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Shipping postal code"
                                    name="shipping_postal_code"
                                    defaultValue={data1.shipping_postal_code || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Billing city"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Billing city"
                                    name="billing_city"
                                    defaultValue={data1.billing_city || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Shipping city"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Shipping city"
                                    name="shipping_city"
                                    defaultValue={data1.shipping_city || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Billing state province"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Billing state province"
                                    name="billing_state_or_province"
                                    defaultValue={data1.billing_state_or_province || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Shipping state province"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Shipping state province"
                                    name="shipping_state_or_province"
                                    defaultValue={
                                      data1.shipping_state_or_province || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Billing country"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Billing country"
                                    name="billing_country"
                                    defaultValue={data1.billing_country || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Shipping country"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Billing country"
                                    name="shipping_country"
                                    defaultValue={data1.shipping_country || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Phone"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Phone"
                                    name="phone"
                                    defaultValue={data1.phone || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Fax"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="fax"
                                    name="fax"
                                    defaultValue={data1.fax || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>

                            <Row className="mb-3">
                              <h4 className="heading">KYC Documents</h4>
                              {documents.map((document, index) => (
                                <React.Fragment key={index}>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId={`floatingInputName${index}`}
                                      label="Document Name"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Document Name"
                                        defaultValue={document.document_name}
                                        onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="5">
                                    <FloatingLabel
                                      controlId={`floatingInputValidity${index}`}
                                      label="Validity"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Validity"
                                        defaultValue={document.validity}
                                        onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="1">
                                    {index > 0 && (
                                      <span id="links" onClick={() => handleRemoveDocument(index)}>
                                        <FaTrashAlt />
                                      </span>
                                    )}
                                  </Form.Group>
                                </React.Fragment>
                              ))}
                              <span id="links" onClick={handleAddDocument}>Add Document</span>
                              {/* <Button onClick={handleSubmit}>Create Account</Button> */}
                            </Row>                 <Row className="mb-3">
                              <h4 className="heading">Rwood Reference</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="KYC docs"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="KYC docs"
                                    name="KYC_docs"
                                    defaultValue={data1.KYC_docs || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Commitment"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="commitment"
                                    placeholder="Commitment"
                                    defaultValue={data1.KYC_docs || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    <option value="Minimum Term">Minimum Term</option>
                                    <option value="Short Term">Short Term</option>
                                    <option value="Long Term">Long Term</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="KYC doc date"
                          className="mb-3 datepick"
                        >
                         <DatePicker
        selected={kycDocDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
      />

                        </FloatingLabel>
                      </Form.Group> */}

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Reliability"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Reliability"
                                    name="reliability"
                                    defaultValue={data1.reliability || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Refrence check"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Refrence check"
                                    name="refrence_check"
                                    defaultValue={data1.refrence_check || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Refrence check date"
                          className="mb-3"
                        >
                          <DatePicker
        selected={refrenceCheckDates}
        onChange={handleRefrenceCheck}
        dateFormat="dd/MM/yyyy"
      />
                        </FloatingLabel>
                      </Form.Group> */}
                              <Form.Group as={Col} md="6"></Form.Group>
                            </Row>

                            <Row className="mb-3">
                              <h4 className="heading">Industry Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Under group"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Under group"
                                    name="under_group"
                                    defaultValue={data1.under_group || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="annual coal requirement in  MT"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="annual coal requirement in  MT"
                                    name="annual_coal_requirement_in_MT"
                                    defaultValue={
                                      data1.annual_coal_requirement_in_MT || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Station name"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Station name"
                                    name="station_name"
                                    defaultValue={data1.station_name || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Imported volume PA in MT"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Imported volume PA in MT"
                                    name="imported_volume_PA_in_MT"
                                    defaultValue={data1.imported_volume_PA_in_MT || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Expansion setup or buyer status"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="expansion_setup_or_buyer_status"
                                    placeholder="Expansion setup or buyer status"
                                    defaultValue={
                                      data1.expansion_setup_or_buyer_status || ""
                                    }
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    <option value="Under Construction">
                                      Under Construction
                                    </option>
                                    <option value="Operated">Operated</option>
                                    <option value="Permitted">Permitted</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Imported volume from indonesia PA in MT"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Imported volume from indonesia PA in MT"
                                    name="imported_volume_from_indonesia_PA_in_MT"
                                    defaultValue={
                                      data1.imported_volume_from_indonesia_PA_in_MT ||
                                      ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Quantity MT monthly"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Quantity MT monthly"
                                    name="quantity_MT_monthly"
                                    defaultValue={data1.quantity_MT_monthly || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Industry"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="industry"
                                    placeholder="Industry"
                                    defaultValue={data1.quantity_MT_monthly || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    <option value="Chemicals">Chemicals</option>
                                    <option value="Constructions">Constructions</option>
                                    <option value="Electronics">Electronics</option>
                                    <option value="Energy">Energy</option>
                                    <option value="Engineering">Engineering</option>
                                    <option value="Environmental">Environmental</option>
                                    <option value="Government">Government</option>
                                    <option value="Manufacturing">Manufacturing</option>
                                    <option value="Other">Other</option>
                                    <option value="Shipping">Shipping</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Production capacity"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Production capacity"
                                    name="production_capacity"
                                    defaultValue={data1.production_capacity || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Sector"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="sector"
                                    placeholder="Sector"
                                    defaultValue={data1.sector || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    <option value="Government">Government</option>
                                    <option value="Power Sector">Power Sector</option>
                                    <option value="Private Sector">
                                      Private Sector
                                    </option>
                                    <option value="Industrial">Industrial</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Originiaze import breakup"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Originiaze import breakup"
                                    name="originiaze_import_breakup"
                                    defaultValue={data1.originiaze_import_breakup || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Port"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Port"
                                    name="port"
                                    defaultValue={data1.port || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Market impression rating"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Market impression rating"
                                    name="market_impression_rating"
                                    defaultValue={data1.market_impression_rating || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Origin"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Origin"
                                    name="origin"
                                    defaultValue={data1.origin || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            {/* <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                     defaultValue={data1.coal_spec_1_3800_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    defaultValue={data1.coal_spec_2_4200_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    defaultValue={data1.product || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                     defaultValue={data1.coal_spec_2_4200_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea" id='textareas'
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"
                    defaultValue={data1.description || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                     defaultValue={data1.coal_spec_3_4700_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    defaultValue={data1.remarks || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    defaultValue={data1.coal_spec_4_5000_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                     defaultValue={data1.coal_spec_5_5500_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                     defaultValue={data1.coal_spec_6 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    defaultValue={data1.coal_spec_7 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    defaultValue={data1.coal_spec_8 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
            </Row> */}
           <Row id="table-styles">
                                                    <div className="col-md-12 counter" id="head">
                                                      <h4 className="heading">
                                                        <span>Counter Party Information</span>
                                                        <span><Link to={"/account/counter-party/" + id}>
                                                          <i className=""></i>
                                                          New
                                                        </Link>
                                                        </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="counter" className="">
                                                      <thead>
                                                        <tr>

                                                          <th>Origin</th>

                                                          <th>Name</th>

                                                          <th>GAR</th>

                                                          <th>TM</th>
                                                          <th>ASH</th>
                                                          <th>TS</th>
                                                        </tr>
                                                      </thead>

                                                      {x.counter_party.length > 0 ? (
                                                        <tbody>
                                                          {x.counter_party.map((y, i) => (
                                                            <tr>
                                                              <td>{y.origin}</td>
                                                              <td>{y.name}</td>

                                                              <td>{y.gar}</td>
                                                              <td>{y.tm}</td>
                                                              <td>{y.ash}</td>
                                                              <td>{y.ts}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>
                            <Row>
                              <h4 className="heading">Terms & Conditions</h4>
                              <Form.Group as={Col} md="12" className="textarea-full">
                                <FloatingLabel
                                  controlId="floatingTextarea2"
                                  label="General terms"
                                >
                                  <Form.Control
                                    as="textarea"
                                    placeholder="General terms"
                                    style={{ height: "100px" }}
                                    name="general_terms"
                                    defaultValue={data1.general_terms || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="12" className="textarea-full">
                                <FloatingLabel
                                  controlId="floatingTextarea2"
                                  label="Payment terms"
                                >
                                  <Form.Control
                                    as="textarea"
                                    placeholder="Payment terms"
                                    style={{ height: "100px" }}
                                    name="payment_terms"
                                    defaultValue={data1.payment_terms || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">System Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Account Source"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Account Source"
                                    name="account_source"
                                    defaultValue={data1.account_source || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Rating"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="rating"
                                    placeholder="Rating"
                                    value={data1.rating || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">--None--</option>
                                    <option value="Hot">Hot</option>
                                    <option value="Warm">Warm</option>
                                    <option value="Cold">Cold</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                            </Row>

                            <div className="successDiv">
                              <p></p><p></p>
                            </div>

                            <p className="edit-btn">
                              <input
                                type="submit"
                                className="account-save"
                                onClick={handleSaveEdit}
                                value={isSubmitted ? "Saving" : "Save"}
                                disabled={isSubmitted}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </button>
                            </p>

                            <div className="successDiv">
                              <p></p><p></p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="contactAccount">
                              <div className="tasks">
                                <div className="edit-delete">
                                  <span id="edit" onClick={handleEditClick}>
                                    Edit
                                  </span>
                                  <span>
                                    <Link to={"/accounts/account-list"}> Cancel</Link>
                                  </span>
                                </div>
                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <hr></hr>
                                    </div>
                                  </div>
                                </div>
                                <Accordion
                                  allowMultipleExpanded={true}
                                  preExpanded={["a", "b", "c", "d", "e", "f", "g", "h","i"]}
                                >
                                  <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        Account Information
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Account Name</span>
                                            </td>
                                            <td><Link to={`/accounts/${id}`}> {x.account_details.account_name} </Link></td>
                                            <td id="td-right">
                                              <span>Account Owner</span>
                                            </td>
                                            <td>{x.account_details.account_owner}</td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>
                                                Account alias{" "}
                                                <OverlayTrigger
                                                  placement="right"
                                                  delay={{ show: 250, hide: 400 }}
                                                  overlay={renderTooltip}
                                                >
                                                  <Button
                                                    variant="success"
                                                    className="tooltips"
                                                  >
                                                    <IoIosHelpCircle id="help" />
                                                  </Button>
                                                </OverlayTrigger>{" "}
                                              </span>
                                            </td>
                                            <td>{x.account_details.account_alias}</td>
                                            <td id="td-right">
                                              <span>Parent Account</span>
                                            </td>
                                            <td>{x.account_details.parent_account}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Website</span>
                                            </td>
                                            <td>{x.account_details.website}</td>
                                            <td id="td-right">
                                              <span>Account Number</span>
                                            </td>
                                            <td>{x.account_details.account_number}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Trader</span>
                                            </td>
                                            <td>{x.account_details.trader}</td>
                                            <td id="td-right">
                                              <span>Account record type</span>
                                            </td>
                                            <td>
                                              {x.account_details.account_record_type}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td></td>
                                            <td id="td-right">
                                              <span>Annual Revenue</span>
                                            </td>
                                            <td>{x.account_details.annual_revenue}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td></td>
                                            <td id="td-right">
                                              <span>no of employees</span>
                                            </td>
                                            <td>{x.account_details.no_of_employees}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  <AccordionItem uuid="b">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        Address Information
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Region</span>
                                            </td>
                                            <td>{x.account_details.region}</td>
                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>billing Address</span><br />



                                            </td>
                                            <td>
                                              <p className="bill-group"> <span> {x.account_details.billing_street}</span><br />
                                                <span> {x.account_details.billing_postal_code}</span><br />
                                                <span> {x.account_details.billing_city}</span><br />
                                                <span>  {x.account_details.billing_state_or_province}</span><br />
                                                <span> {x.account_details.billing_country}</span><br />
                                              </p>

                                            </td>
                                            <td id="td-right">
                                              <span>shipping Address</span>{" "}
                                            </td>
                                            <td>
                                              <p className="ship-group"><span>{x.account_details.shipping_street}</span><br />
                                                <span>{x.account_details.shipping_postal_code}</span><br />
                                                <span>{x.account_details.shipping_city}</span><br />
                                                <span> {x.account_details.shipping_state_or_province}</span><br />
                                                <span> {x.account_details.shipping_country}</span><br /></p>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>Phone</span>
                                            </td>
                                            <td>{x.account_details.phone}</td>
                                            <td id="td-right">
                                              <span>Fax</span>
                                            </td>
                                            <td>{x.account_details.fax}</td>
                                          </tr>

                                        </tbody>
                                      </table>
                                    </AccordionItemPanel>
                                  </AccordionItem>

                                  <AccordionItem uuid="h">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        Rwood Reference

                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>KYC docs</span>
                                            </td>
                                            <td>
                                              {x.account_details.KYC_docs}
                                            </td>
                                            <td id="td-right">
                                              <span>Commitment</span>
                                            </td>
                                            <td>{x.account_details.commitment}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>KYC doc date</span>
                                            </td>
                                            <td>
                                              {x.account_details.KYC_doc_date}
                                            </td>
                                            <td id="td-right">
                                              <span>Reliability</span>
                                            </td>
                                            <td>{x.account_details.reliability}</td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>Reference Check</span>
                                            </td>
                                            <td>
                                              {x.account_details.refrence_check}
                                            </td>
                                            <td id="td-right">
                                              {/* <span>Refrence check date</span> */}
                                            </td>
                                            <td>
                                              {/* {x.account_details.refrence_check_date} */}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Refrence check date</span>
                                            </td>
                                            <td>
                                              {x.account_details.refrence_check_date}
                                            </td>
                                            <td id="td-right">
                                            </td>
                                            <td>
                                            </td>
                                          </tr>


                                        </tbody>
                                      </table>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  <AccordionItem uuid="g">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        KYC Documents
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {x.kyc_docs.length > 0 ?
                                        <div className="">
                                          <div className="row">
                                            <div className="col-md-12" id="head">
                                              <hr></hr>
                                            </div>
                                          </div>

                                          <table class="table table-bordered account-table kyc-table">
                                            <tbody>
                                              <tr>
                                                <th>Document Name</th>
                                                <th>Validity</th>
                                              </tr>
                                              {x.kyc_docs.map((x, i) =>

                                                <tr>
                                                  <td>
                                                    {x.document_name}
                                                  </td>

                                                  <td>
                                                    {x.validity}
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>

                                        </div>
                                        : <></>
                                      }
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  <AccordionItem uuid="d">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        Industry Information
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Under Gourp</span>
                                            </td>
                                            <td>{x.account_details.under_group}</td>
                                            <td id="td-right">
                                              <span>Annual coal requirement in MT</span>
                                            </td>
                                            <td>
                                              {
                                                x.account_details
                                                  .annual_coal_requirement_in_MT
                                              }
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>Station name</span>
                                            </td>
                                            <td>{x.account_details.station_name}</td>

                                            <td id="td-right">
                                              <span>imported volume PA in MT</span>
                                            </td>
                                            <td>
                                              {
                                                x.account_details
                                                  .imported_volume_PA_in_MT
                                              }
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>
                                                Expansion Setup / Buyer Status
                                              </span>
                                            </td>
                                            <td>
                                              {
                                                x.account_details
                                                  .expansion_setup_or_buyer_status
                                              }
                                            </td>
                                            <td id="td-right">
                                              <span>
                                                Imported volume from indonesia PA in MT
                                              </span>
                                            </td>
                                            <td>
                                              {
                                                x.account_details
                                                  .imported_volume_from_indonesia_PA_in_MT
                                              }
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td></td>
                                            <td id="td-right">
                                              <span>quantity MT (Monthly)</span>
                                            </td>
                                            <td>
                                              {x.account_details.quantity_MT_monthly}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>Industry</span>
                                            </td>
                                            <td>{x.account_details.industry}</td>
                                            <td id="td-right">
                                              <span>
                                                Production /Capacity Utilisation (Unit
                                                %)
                                              </span>
                                            </td>
                                            <td>
                                              {x.account_details.production_capacity}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>Sector</span>
                                            </td>
                                            <td>{x.account_details.sector}</td>
                                            <td id="td-right">
                                              <span>
                                                Originiaze Import Break up (TPO/ %)
                                              </span>
                                            </td>
                                            <td>
                                              {
                                                x.account_details
                                                  .originiaze_import_breakup
                                              }
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td></td>
                                            <td id="td-right">
                                              <span>Port</span>
                                            </td>
                                            <td>{x.account_details.port}</td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>Market impression Rating</span>
                                            </td>
                                            <td>
                                              {
                                                x.account_details
                                                  .market_impression_rating
                                              }
                                            </td>
                                            <td id="td-right">
                                              <span>Origin</span>
                                            </td>
                                            <td>{x.account_details.origin}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </AccordionItemPanel>
                                  </AccordionItem>


                              <AccordionItem uuid="i">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                      Counter Party Information
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                       <Row id="table-styles">
                                                    <div className="col-md-12" id="head">
                                                      <h4 className="heading">
                                                        <span>Counter Party</span>
                                                        <span><Link to={"/account/counter-party/" + id}>
                                                          <i className=""></i>
                                                          New
                                                        </Link>
                                                        </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="counter" className="">
                                                      <thead>
                                                        <tr>

                                                          <th>Origin</th>

                                                          <th>Name</th>

                                                          <th>GAR</th>

                                                          <th>TM</th>
                                                          <th>ASH</th>
                                                          <th>TS</th>
                                                        </tr>
                                                      </thead>

                                                      {x.counter_party.length > 0 ? (
                                                        <tbody>
                                                          {x.counter_party.map((y, i) => (
                                                            <tr>
                                                              <td>{y.origin}</td>
                                                              <td>{y.name}</td>

                                                              <td>{y.gar}</td>
                                                              <td>{y.tm}</td>
                                                              <td>{y.ash}</td>
                                                              <td>{y.ts}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  <AccordionItem uuid="e">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        Terms & Conditions
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>General terms</span>
                                            </td>
                                            <td>{x.account_details.general_terms}</td>

                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Payment terms</span>
                                            </td>
                                            <td>{x.account_details.payment_terms}</td>

                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  <AccordionItem uuid="f">
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        System Information
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Account Source</span>
                                            </td>
                                            <td>{x.account_details.account_source}</td>

                                            <td id="td-right">
                                              <span>Rating</span>
                                            </td>
                                            <td>{x.account_details.rating}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Created By</span>
                                            </td>
                                            <td>
                                              {x.account_details.created_by}
                                            </td>
                                            <td id="td-right">
                                              <span>Last Modified By
                                              </span>
                                            </td>
                                            <td>{x.account_details.last_modified_by}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </Accordion>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contact Roles</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Partners</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contacts</span>
                                      <span onClick={contactHandle}>

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="contact" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Contact code</th>
                                        <th>Contact Name	</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Phone</th>
                                        <th>Title</th>
                                        <th>Reset Birthday Email System	</th>
                                        <th>Receive Birthday Emails</th>
                                      </tr>
                                    </thead>
                                    {x.contacts.length > 0 ? (
                                      <tbody>
                                        {x.contacts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={"/contact/contact-details" + "/" + y.id}
                                                >
                                                  {y.contact_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/contact/contact-details" + "/" + y.id}
                                              >
                                                {y.contact_full_name}
                                              </Link>
                                            </td>
                                            <td>{y.contact_email}</td>
                                            <td>{y.contact_mobile}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="head"
                                  >
                                    <h4 className="heading">
                                      <span>
                                        Opportunities
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmit}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>
                                                Opportunity Record
                                                Type
                                              </label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={
                                                  handleChange
                                                }
                                                value={
                                                  formData.account_record
                                                }
                                              >
                                                <option>
                                                  --Select Opportunity
                                                  Record Type--
                                                </option>

                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Spot">
                                                  Supplier - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <div id="inner-heading"></div>
                                  <table id="opp" className="">
                                    <thead>
                                      <tr>
                                        <th>Opportunity no</th>
                                        <th>Opportunity name</th>
                                        <th>Stage</th>
                                        <th>Quantity</th>
                                        <th>Laycan From  </th>
                                        <th>Laycan To </th>
                                        <th>Price  </th>
                                        <th>Trader Name  </th>
                                        <th>Supplier</th>
                                        <th>Vessel Type
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.opportunity.length > 0 ? (
                                      <tbody>
                                        {x.opportunity.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/opportunity/opportunity-detail" +
                                                    "/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.opportunity_no}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>

                                              {y.opportunity_name}
                                            </td>
                                            <td>

                                              {y.stage}
                                            </td>
                                            <td>
                                              {y.quantity}
                                            </td>
                                            <td>{y.laycan_from}</td>
                                            <td>{y.laycan_to}</td>
                                            <td>{y.price}</td>
                                            <td><Link  to={"/user/user-details/" +y.user_id} >{y.trader_name}</Link></td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                            <td>{y.vessel_type}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr><td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Quotes</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="quotes" className="">
                                    <thead>
                                      <tr>
                                        <th>Quote Number</th>
                                        <th>Account Name</th>
                                        <th>Quote Name</th>
                                        <th>Opportunity Name</th>
                                        <th>Currency</th>
                                        <th>Supplier</th>
                                      </tr>
                                    </thead>

                                    {x.quotes.length > 0 ? (
                                      <tbody>
                                        {x.quotes.map((y, i) => (
                                          <tr>
                                            <td>
                                              <Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_number}</Link>
                                            </td>
                                            <td><Link
                                              to={
                                                "/accounts/" +

                                                y.id
                                              }
                                            >
                                              {y.account_name}
                                            </Link></td>
                                            <td>{y.quote_name}</td>
                                            <td><Link to={`/opportunity/opportunity-detail/${y.opportunity_id}`}>{y.opportunity_name}</Link></td>
                                            <td>{y.currency}</td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Trade Confirmation Form</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmittcf}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>TCF Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option>
                                                  --Select TCF Record Type--
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <div id="inner-heading"></div>
                                    <hr></hr>
                                  </div>
                                  <table id="tcf" className="">
                                    <thead>
                                      <tr>
                                        <th>TCF No</th>
                                        <th>Shipment Month	</th>
                                        <th>Vessel Name	</th>
                                        <th>Product Name	</th>
                                        <th>Final Gross Margin - Shipment	</th>
                                        <th>Final Gross Margin - Ton	</th>
                                        <th>TCF Status
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.tcf.length > 0 ? (
                                      <tbody>
                                        {x.tcf.map((x, i) => (
                                          <tr>
                                            <td>
                                              <Link to={"/tcf/tcf-details/" + x.id}>
                                                {x.tcf_no}
                                              </Link>
                                            </td>
                                            <td>{x.shipment_month}</td>
                                            <td>{x.vessel_type}</td>
                                            <td>{x.product_name}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{x.tcf_status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contract Information</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmitcontract}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>Account Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option value="">--Select record type--</option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                                <option value="Supplier">
                                                  Supplier
                                                </option>
                                              </select>
                                              {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="contract" className="">
                                    <thead>
                                      <tr>
                                        <th>Rwood contract number</th>
                                        <th>Contract Record Type  </th>
                                        <th>Contract Start Date   </th>
                                        <th>Contract End Date  </th>
                                        <th>Status</th>
                                        <th>Contract Quantity (MT)   </th>
                                        <th>Destination</th>
                                        <th>Laycan From  </th>
                                        <th>Laycan To </th>
                                      </tr>
                                    </thead>

                                    {x.contracts.length > 0 ? (
                                      <tbody>
                                        {x.contracts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_number}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.contract_record_type}
                                            </td>
                                            <td>{y.contract_start_date}</td>
                                            <td>{y.contract_end_date}</td>
                                            <td>{y.contract_status}</td>
                                            <td>{y.contract_quantity_in_MT}</td>
                                            <td>{y.destination}</td>
                                            <td>{y.laycan_from}</td>
                                            <td>{y.laycan_to}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>Orders</span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="orders" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Order Code No</th>
                                                          <th>Contract Number	</th>
                                                          <th>Vessel Name	</th>
                                                          <th>BL Date	</th>
                                                          <th>Shipment Qty	</th>
                                                          <th>Actual Payment Received Date	</th>
                                                          <th>Document Discount Date	</th>
                                                          <th>Supplier Invoice Amount
                                                          </th>
                                                        </tr>
                                                      </thead>

                                                      {x.orders.length > 0 ? (
                                                        <tbody>
                                                          {x.orders.map((y, i) => (
                                                            <tr>
                                                              <td>
                                                                <span id="codes">
                                                                  <Link
                                                                    to={
                                                                      "/order/view-order-details/" +
                                                                      y.id
                                                                    }
                                                                  >
                                                                    {y.order_no}
                                                                                </Link>
                                                                </span>
                                                              </td>
                                                              <td><Link
                                                                    to={
                                                                      "/contract/contract-details/" +y.contract_id
                                                                    }
                                                                  >{y.contract_number}</Link></td>
                                                              <td>{y.vessel_name}</td>
                                                              <td>{y.bl_date}</td>
                                                              <td>
                                                                {y.shipment_qty}
                                                              </td>
                                                              <td>
                                                              {y.all_actual_payment_recieved_date}
                                                              </td>
                                                              <td>{y.all_document_discount_date}</td>
                                                              <td></td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td><td></td><td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td><td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>


                                                  <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>
                                        Rw Payable/ Receivable LineItem (Account)
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Rw Payable/ Receivable LineItem
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receivable" className="">
                                    <thead>
                                      <tr>
                                        <th>Rw Payable/ Receivable LineItem	</th>
                                        <th>Amount </th>
                                        <th>Record Type	  </th>
                                        <th>Line Item	</th>
                                        <th>BL Date	</th>
                                        <th>Vessel Name	 </th>
                                        <th>Quantity</th>
                                        <th>Order</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receivable_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receivable_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                              <Link
                              to={"/account/receivable/" + y.id}
                            >
                                                  {y.rw_payment_receivable_lineitem}</Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.amount}
                                            </td>
                                            <td>{y.record_type}</td>
                                            <td>{y.line_item}</td>
                                            <td>{y.bl_date}</td>
                                            <td>{y.vessel_name}</td>
                                            <td>{y.quantity}</td>
                                            <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                            <td>{y.status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>









                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Debit Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Debit Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes & Attachments</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Attach File
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Files</span>
                                      <span>
                                        <i className=""></i>
                                        Upload Files
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts</span>
                                      <span>
                                        <i className=""></i>
                                        New RW Payment/Receipt
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receipt" className="">
                                    <thead>
                                      <tr>
                                        <th>RW Payment/Receipt	</th>
                                        <th>Record Type	 </th>
                                        <th>Payment/Receipt Date	 </th>
                                        <th>Status</th>
                                        <th>Total Amount	</th>
                                        <th>Rwood Bank Master	 </th>
                                        <th>Mode Of Payment	</th>
                                        <th>Description </th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receipts_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receipts_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                              <Link to={"/account/receipt-lineitem/" + y.rw_pay_receipt_detail_id}>
                                                  {y.rw_payment_receipt}
                                                  </Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.record_type}
                                            </td>
                                            <td>{y.payment_receipt_date}</td>
                                            <td>{y.status}</td>
                                            <td>{y.amount}</td>
                                            <td>{y.rwood_bank_master}</td>
                                            <td>{y.mode_of_payment}</td>
                                            <td>{y.description}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>


                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Account History</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="historys" className="">
                                    <thead>
                                      <tr>
                                        <th>Field Name</th>
                                        <th>New Value</th>
                                        <th>Old Value</th>
                                        <th>Changed By</th>
                                        <th>Change Time</th>
                                      </tr>
                                    </thead>

                                    {x.history.length > 0 ? (
                                      <tbody>
                                        {x.history.map((y, i) => (
                                          <tr>
                                            <td>{y.field_name}</td>
                                            <td>{y.new_value}</td>
                                            <td>{y.old_value}</td>
                                            <td>{y.changed_by}</td>
                                            <td>{y.change_time}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {x.account_details.account_record_type === "Agent" ? (
                          <>
                            {isEditMode ? (
                              <div className="tasks">
                                <p className="edit-btn">
                                  <input
                                    type="submit"
                                    className="account-save"
                                    onClick={handleSaveEdit}
                                    value={isSubmitted ? "Saving" : "Save"}
                                    disabled={isSubmitted}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleCancelEdit}
                                  >
                                    Cancel
                                  </button>
                                </p>

                                <Row className="mb-3">
                                  <h4 className="heading">Account Information</h4>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Account Name"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Account Name"
                                        className="error-validation"
                                        name="account_name"
                                        defaultValue={data1.account_name || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                    {errors.account_name && (
                                      <div className="text-danger">{errors.account_name}</div>
                                    )}
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Account Owner"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Account Owner"
                                        name="account_owner"
                                        defaultValue={data1.account_owner || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className="tools">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Account Alias"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Account Alias"
                                        name="account_alias"
                                        defaultValue={data1.account_alias || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip}
                                    >
                                      <Button variant="success" className="tooltips">
                                        <IoIosHelpCircle id="help" />
                                      </Button>
                                    </OverlayTrigger>{" "}
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Account Number"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Account Number"
                                        name="account_number"
                                        defaultValue={data1.account_number || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Website"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Website"
                                        name="website"
                                        defaultValue={data1.website || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Account record type"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Account record type"
                                        value={data1.account_record_type}
                                        name="account_record_type"
                                        className="record-type"
                                        defaultValue={data1.account_record_type || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className="tools">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Trader"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Trader"
                                        name="trader"
                                        defaultValue={data1.trader || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={trader}
                                    >
                                      <Button variant="success" className="tooltips">
                                        <IoIosHelpCircle id="help" />
                                      </Button>
                                    </OverlayTrigger>{" "}
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Annual Revenue"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Annual Revenue"
                                        name="annual_revenue"
                                        defaultValue={data1.annual_revenue || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="No of employees"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="No of employees"
                                        name="no_of_employees"
                                        defaultValue={data1.no_of_employees || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Port"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Port"
                                        name="port"
                                        defaultValue={data1.port || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                   defaultValue={data1.annual_revenue || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group> */}

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Origin"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Origin"
                                        name="origin"
                                        defaultValue={data1.origin || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    defaultValue={data1.no_of_employees || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group> */}

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Product"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Product"
                                        name="product"
                                        defaultValue={data1.product || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Remarks"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Remarks"
                                        name="remarks"
                                        defaultValue={data1.remarks || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">Address Information</h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Region"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Region"
                                        name="region"
                                        defaultValue={data1.region || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6" className="tools">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Phone"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Phone"
                                        name="phone"
                                        defaultValue={data1.phone || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                    <OverlayTrigger
                                      placement="left"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={phone}
                                    >
                                      <Button variant="success" className="tooltips">
                                        <IoIosHelpCircle id="help" />
                                      </Button>
                                    </OverlayTrigger>{" "}
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="Billing Address"
                                    >
                                      <Form.Control
                                        as="textarea" id='textareas'
                                        placeholder="Billing Address"
                                        style={{ height: "100px" }}
                                        name="billing_street"
                                        defaultValue={data1.billing_street || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Fax"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="fax"
                                        name="fax"
                                        defaultValue={data1.fax || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Billing postal code"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Billing postal code"
                                        name="billing_postal_code"
                                        defaultValue={data1.billing_postal_code || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="Shipping Address"
                                    >
                                      <Form.Control
                                        as="textarea" id='textareas'
                                        placeholder="Shipping Address"
                                        style={{ height: "100px" }}
                                        name="shipping_street"
                                        defaultValue={data1.shipping_street || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Billing city"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Billing city"
                                        name="billing_city"
                                        defaultValue={data1.billing_city || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Shipping postal code"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Shipping postal code"
                                        name="shipping_postal_code"
                                        defaultValue={data1.shipping_postal_code || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Billing state province"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Billing state province"
                                        name="billing_state_or_province"
                                        defaultValue={
                                          data1.billing_state_or_province || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Shipping city"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Shipping city"
                                        name="shipping_city"
                                        defaultValue={data1.shipping_city || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Billing country"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Billing country"
                                        name="billing_country"
                                        defaultValue={data1.billing_country || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Shipping state province"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Shipping state province"
                                        name="shipping_state_or_province"
                                        defaultValue={
                                          data1.shipping_state_or_province || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Shipping country"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Billing country"
                                        name="shipping_country"
                                        defaultValue={data1.shipping_country || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">KYC Documents</h4>
                                  {documents.map((document, index) => (
                                    <React.Fragment key={index}>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId={`floatingInputName${index}`}
                                          label="Document Name"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Document Name"
                                            defaultValue={document.document_name}
                                            onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="5">
                                        <FloatingLabel
                                          controlId={`floatingInputValidity${index}`}
                                          label="Validity"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Validity"
                                            defaultValue={document.validity}
                                            onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="1">
                                        {index > 0 && (
                                          <span id="links" onClick={() => handleRemoveDocument(index)}>
                                            <FaTrashAlt />
                                          </span>
                                        )}
                                      </Form.Group>
                                    </React.Fragment>
                                  ))}
                                  <span id="links" onClick={handleAddDocument}>Add Document</span>
                                  {/* <Button onClick={handleSubmit}>Create Account</Button> */}
                                </Row>

                                <Row className="mb-3">
                                  <h4 className="heading">Rwood Reference</h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="KYC docs"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="KYC docs"
                                        name="KYC_docs"
                                        defaultValue={data1.KYC_docs || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Commitment"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="commitment"
                                        placeholder="Commitment"
                                        defaultValue={data1.commitment || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">--None--</option>
                                        <option value="Medium Term">Medium Term</option>
                                        <option value="Short Term">Short Term</option>
                                        <option value="Long Term">Long Term</option>
                                        <option value="Promptly">Promptly</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  {/* <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="KYC doc date"
                              className="mb-3 datepick"
                            >
                               <DatePicker
        selected={kycDocDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
      />
                            </FloatingLabel>
                          </Form.Group> */}

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Reliability"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        as="textarea" id='textareas'
                                        placeholder="Reliability"
                                        name="reliability"
                                        defaultValue={data1.reliability || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Refrence check"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Refrence check"
                                        name="refrence_check"
                                        defaultValue={data1.refrence_check || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  {/* <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Refrence Check Date"
                              className="mb-3 datepick"
                            >
                              <DatePicker
        selected={refrenceCheckDates}
        onChange={handleRefrenceCheck}
        dateFormat="dd/MM/yyyy"
      />
                            </FloatingLabel>
                          </Form.Group> */}
                                  <Form.Group as={Col} md="6"></Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">Industry Information</h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Parent account"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Parent account"
                                        name="parent_account"
                                        defaultValue={data1.parent_account || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="annual coal requirement in  MT"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="annual coal requirement in  MT"
                                        name="annual_coal_requirement_in_MT"
                                        defaultValue={
                                          data1.annual_coal_requirement_in_MT || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Under group"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Under group"
                                        name="under_group"
                                        defaultValue={data1.under_group || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Imported volume PA in MT"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Imported volume PA in MT"
                                        name="imported_volume_PA_in_MT"
                                        defaultValue={
                                          data1.imported_volume_PA_in_MT || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>{" "}
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Station name"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Station name"
                                        name="station_name"
                                        defaultValue={data1.station_name || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>{" "}
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Quantity MT monthly"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Quantity MT monthly"
                                        name="quantity_MT_monthly"
                                        defaultValue={data1.quantity_MT_monthly || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Expansion setup or buyer status"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="expansion_setup_or_buyer_status"
                                        placeholder="Expansion setup or buyer status"
                                        defaultValue={data1.expansion_setup_or_buyer_status || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        <option value="Under Construction">
                                          Under Construction
                                        </option>
                                        <option value="Operated">Operated</option>
                                        <option value="Permitted">Permitted</option>
                                      </Form.Select>

                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Production capacity"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Production capacity"
                                        name="production_capacity"
                                        defaultValue={data1.production_capacity || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Originiaze import breakup"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Originiaze import breakup"
                                        name="originiaze_import_breakup"
                                        defaultValue={
                                          data1.originiaze_import_breakup || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Industry"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="industry"
                                        placeholder="Industry"
                                        className="m-b"
                                        defaultValue={data1.industry || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        <option value="Chemicals">Chemicals</option>
                                        <option value="Constructions">
                                          Constructions
                                        </option>
                                        <option value="Electronics">Electronics</option>
                                        <option value="Energy">Energy</option>
                                        <option value="Engineering">Engineering</option>
                                        <option value="Environmental">
                                          Environmental
                                        </option>
                                        <option value="Government">Government</option>
                                        <option value="Manufacturing">
                                          Manufacturing
                                        </option>
                                        <option value="Other">Other</option>
                                        <option value="Shipping">Shipping</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze import breakup"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze import breakup"
                    name="originiaze_import_breakup"
                    defaultValue={data1.originiaze_import_breakup || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group> */}
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Sector"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="sector"
                                        placeholder="Sector"
                                        className="m-b"
                                        defaultValue={data1.sector || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        <option value="Government">Government</option>
                                        <option value="Power Sector">
                                          Power Sector
                                        </option>
                                        <option value="Private Sector">
                                          Private Sector
                                        </option>
                                        <option value="Industrial">Industrial</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Market impression rating"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        className="m-b"
                                        placeholder="Market impression rating"
                                        name="market_impression_rating"
                                        defaultValue={
                                          data1.market_impression_rating || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>
                                {/* product Information */}
                                {/* <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    defaultValue={data1.coal_spec_1_3800_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    defaultValue={data1.coal_spec_3_4700_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"

                    defaultValue={data1.coal_spec_2_4200_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea" id='textareas'
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"

                    defaultValue={data1.description || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"

                    defaultValue={data1.coal_spec_4_5000_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"

                    defaultValue={data1.coal_spec_5_5500_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"

                    defaultValue={data1.coal_spec_6 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"

                    defaultValue={data1.coal_spec_7 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"

                    defaultValue={data1.coal_spec_8 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
            </Row> */}
                                <Row className="mb-3">
                                  <h4 className="heading">System Information</h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Account Source"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Account Source"
                                        name="account_source"
                                        defaultValue={data1.account_source || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Rating"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="rating"
                                        placeholder="Rating"
                                        defaultValue={data1.rating || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">--None--</option>
                                        <option value="Hot">Hot</option>
                                        <option value="Warm">Warm</option>
                                        <option value="Cold">Cold</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">Account Summary</h4>
                                  <Form.Group as={Col} md="12" className="textarea-full">
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="General terms"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        placeholder="general_terms"
                                        style={{ height: "200px!important" }}
                                        name="general_terms"
                                        defaultValue={data1.general_terms || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>

                                <div className="successDiv">
                                  <p></p><p></p>
                                </div>

                                <p className="edit-btn">
                                  <input
                                    type="submit"
                                    className="account-save"
                                    onClick={handleSaveEdit}
                                    value={isSubmitted ? "Saving" : "Save"}
                                    disabled={isSubmitted}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleCancelEdit}
                                  >
                                    Cancel
                                  </button>
                                </p>
                                <div className="successDiv">
                                  <p></p><p></p>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="contactAccount">
                                  <div className="tasks">
                                    <div className="edit-delete">
                                      <span id="edit" onClick={handleEditClick}>
                                        Edit
                                      </span>
                                      <span>
                                        <Link to={"/accounts/account-list"}>
                                          {" "}
                                          Cancel
                                        </Link>
                                      </span>
                                    </div>
                                    <Accordion
                                      allowMultipleExpanded={true}
                                      preExpanded={["a", "b", "c", "d", "e", "f", "g"]}
                                    >
                                      <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Account Information
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Account Code</span>{" "}
                                                </td>
                                                <td>
                                                  {x.account_details.account_code}
                                                </td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Account Name</span>
                                                </td>
                                                <td>
                                                  <Link to={"/accounts/" + id} > {x.account_details.account_name} </Link>
                                                </td>
                                                <td id="td-right">
                                                  <span>Account Owner</span>
                                                </td>
                                                <td>
                                                  {x.account_details.account_owner}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Account alias{" "}
                                                    <OverlayTrigger
                                                      placement="right"
                                                      delay={{ show: 250, hide: 400 }}
                                                      overlay={renderTooltip}
                                                    >
                                                      <Button
                                                        variant="success"
                                                        className="tooltips"
                                                      >
                                                        <IoIosHelpCircle id="help" />
                                                      </Button>
                                                    </OverlayTrigger>{" "}
                                                  </span>
                                                </td>
                                                <td>
                                                  {x.account_details.account_alias}
                                                </td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                                <td id="td-right">
                                                  <span>Account Number</span>
                                                </td>
                                                <td>
                                                  {x.account_details.account_number}
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Website</span>
                                                </td>
                                                <td>{x.account_details.website}</td>
                                                <td id="td-right">
                                                  <span>Account record type</span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .account_record_type
                                                  }
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Trader</span>
                                                </td>
                                                <td>{x.account_details.trader}</td>
                                                <td id="td-right">
                                                  <span>Annual Revenue</span>
                                                </td>
                                                <td>
                                                  {x.account_details.annual_revenue}
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Port</span>
                                                </td>
                                                <td>{x.account_details.port}</td>
                                                <td id="td-right">
                                                  <span>no of employees</span>
                                                </td>
                                                <td>
                                                  {x.account_details.no_of_employees}
                                                </td>
                                              </tr>



                                              <tr>
                                                <td id="td-right">
                                                  <span>Origin</span>
                                                </td>
                                                <td>{x.account_details.origin}</td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Product</span>
                                                </td>
                                                <td>{x.account_details.product}</td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Remarks</span>
                                                </td>
                                                <td>{x.account_details.remarks}</td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Address Information
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Region</span>
                                                </td>
                                                <td>{x.account_details.region}</td>
                                                <td id="td-right">
                                                  <span>Phone</span>
                                                </td>
                                                <td>{x.account_details.phone}</td>
                                              </tr>


                                              <tr>
                                                <td id="td-right">
                                                  <span>billing Address</span><br />



                                                </td>

                                                <td>
                                                  <p className="bill-group"><span> {x.account_details.billing_street}</span><br />
                                                    <span> {x.account_details.billing_postal_code}</span><br />
                                                    <span> {x.account_details.billing_city}</span><br />
                                                    <span>  {x.account_details.billing_state_or_province}</span><br />
                                                    <span> {x.account_details.billing_country}</span><br />
                                                  </p>
                                                </td><td id="td-right">
                                                  <span>Fax</span>
                                                </td>
                                                <td>{x.account_details.fax}</td>

                                              </tr>

                                              <tr>
                                                <td id="td-right">

                                                </td>
                                                <td>

                                                </td> <td id="td-right">
                                                  <span>shipping Address</span>{" "}
                                                </td>
                                                <td>
                                                  <p className="ship-group"> <span>{x.account_details.shipping_street}</span><br />
                                                    <span>{x.account_details.shipping_postal_code}</span><br />
                                                    <span>{x.account_details.shipping_city}</span><br />
                                                    <span> {x.account_details.shipping_state_or_province}</span><br />
                                                    <span> {x.account_details.shipping_country}</span><br />
                                                  </p>
                                                </td></tr>                                   </tbody>
                                          </table>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem uuid="g">
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            KYC Documents
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          {x.kyc_docs.length > 0 ?
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12" id="head">

                                                  <hr></hr>
                                                </div>
                                              </div>

                                              <table class="table table-bordered account-table kyc-table">
                                                <tbody>
                                                  <tr>
                                                    <th>Document Name</th>
                                                    <th>Validity</th>
                                                  </tr>
                                                  {x.kyc_docs.map((x, i) =>

                                                    <tr>
                                                      <td>
                                                        {x.document_name}
                                                      </td>

                                                      <td>
                                                        {x.validity}
                                                      </td>
                                                    </tr>
                                                  )}
                                                </tbody>
                                              </table>

                                            </div>
                                            : <></>
                                          }
                                          {/* <span id="links" onClick={handleAddDocument}>Add Document</span> */}

                                        </AccordionItemPanel>
                                      </AccordionItem>

                                      <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Rwood Reference
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>KYC Docs</span>
                                                </td>
                                                <td>{x.account_details.KYC_docs}</td>
                                                <td id="td-right">
                                                  <span>Commitment</span>
                                                </td>
                                                <td>{x.account_details.commitment}</td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>KYC doc date</span>
                                                </td>
                                                <td>
                                                  {x.account_details.KYC_doc_date}
                                                </td>
                                                <td id="td-right">
                                                  <span>Reliability</span>
                                                </td>
                                                <td>{x.account_details.reliability}</td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Reference Check</span>
                                                </td>
                                                <td>
                                                  {x.account_details.refrence_check}
                                                </td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Refrence check date</span>
                                                </td>
                                                <td>
                                                  {x.account_details.refrence_check_date}
                                                </td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem uuid="d">
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Industry Information
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>parent account</span>
                                                </td>
                                                <td>
                                                  {x.account_details.parent_account}
                                                </td>
                                                <td id="td-right">
                                                  <span>
                                                    Annual coal requirement in MT
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .annual_coal_requirement_in_MT
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Under Group</span>
                                                </td>
                                                <td>{x.account_details.under_group}</td>
                                                <td id="td-right">
                                                  <span>imported volume PA in MT</span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .imported_volume_PA_in_MT
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>station name</span>
                                                </td>
                                                <td>
                                                  {x.account_details.station_name}
                                                </td>
                                                <td id="td-right">
                                                  <span>quantity MT monthly</span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .quantity_MT_monthly
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Expansion Setup / Buyer Status
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .expansion_setup_or_buyer_status
                                                  }
                                                </td>
                                                <td id="td-right">
                                                  <span>Production /Capacity Utilisation (Unit %)</span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .production_capacity
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                                <td id="td-right">
                                                  <span>originiaze import breakup</span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .originiaze_import_breakup
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Industry</span>
                                                </td>
                                                <td>{x.account_details.industry}</td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>{" "}
                                              <tr>
                                                <td id="td-right">
                                                  <span>Sector</span>
                                                </td>
                                                <td>{x.account_details.sector}</td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Market impression rating</span>
                                                </td>
                                                <td>
                                                  {
                                                    x.account_details
                                                      .market_impression_rating
                                                  }
                                                </td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem uuid="e">
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            System Information
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Account source</span>
                                                </td>
                                                <td>
                                                  {x.account_details.account_source}
                                                </td>
                                                <td id="td-right">
                                                  <span>Rating</span>
                                                </td>
                                                <td>{x.account_details.rating}</td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Created By</span>
                                                </td>
                                                <td>
                                                  {x.account_details.created_at}
                                                </td>
                                                <td id="td-right">
                                                  <span>Last Modified By
                                                  </span>
                                                </td>
                                                <td>{x.account_details.updated_at}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem
                                        uuid="f"
                                        expanded={() => this.setIsCollapse("f")}
                                      >
                                        <AccordionItemHeading>
                                          <AccordionItemButton
                                            onClick={(value) =>
                                              this.onChangeAccordion(value)
                                            }
                                          >
                                            Account Summary
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>general terms</span>
                                                </td>
                                                <td>
                                                  {x.account_details.general_terms}
                                                </td>
                                                <td id="td-right">
                                                  <span></span>
                                                </td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </Accordion>
                                    <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contact Roles</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contacts</span>
                                      <span onClick={contactHandle}>

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="contact" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Contact code</th>
                                        <th>Contact Name	</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Phone</th>
                                        <th>Title</th>
                                        <th>Reset Birthday Email System	</th>
                                        <th>Receive Birthday Emails</th>
                                      </tr>
                                    </thead>
                                    {x.contacts.length > 0 ? (
                                      <tbody>
                                        {x.contacts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={"/contact/contact-details" + "/" + y.id}
                                                >
                                                  {y.contact_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/contact/contact-details" + "/" + y.id}
                                              >
                                                {y.contact_full_name}
                                              </Link>
                                            </td>
                                            <td>{y.contact_email}</td>
                                            <td>{y.contact_mobile}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>

                                <Row id="table-styles">
     <div
       className="col-md-12"
       id="head"
     >
       <h4 className="heading">
         <span>Orders (Load Port Agent1)
         </span>
       </h4>
       <hr></hr>
     </div>
     <table id="order-supplier" className="">
       <thead>
         <tr>
           <th>Order Number</th>
           <th>Status</th>
           <th>Vessel Name  </th>
           <th>Account Name </th>
           <th>Supplier </th>
           <th>BL Date    </th>
           <th>Total BL Qty
           </th>
         </tr>
       </thead>

       {x.orders.length > 0 ? (
         <tbody>
           {x.orders.map((y, i) => (
             <tr>
               <td>
                 <span id="codes">
                   <Link
                     to={
                       "/order/view-order-details/" +
                       y.id
                     }
                   >
                     {y.order_no}
                                 </Link>
                 </span>
               </td>
               <td>{y.status}</td>
               <td>{y.vessel_name}</td>
               <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
               <td>{y.bl_date}</td>
               <td>
                 {y.total_bl_qty}
               </td>

             </tr>
           ))}
         </tbody>
       ) : (
         <tbody>
           <tr>
           <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody>
       )}
     </table>
   </Row>
   <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Orders (Load Port Agent 2)
</span>
                                      <span>
                                        <i className=""></i>
                                        New Order
                                      </span>

                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Orders (Load Port Agent 3)
</span>
                                      <span>
                                        <i className=""></i>
                                        New Order
                                      </span>

                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Order</span>
                                      <span>
                                        <i className=""></i>
                                        New Order
                                      </span>

                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities
</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes & Attachments</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Attach File
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Files</span>
                                      <span>
                                        <i className=""></i>
                                        Upload Files
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts</span>
                                      <span>
                                        <i className=""></i>
                                        New RW Payment/Receipt
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receipt" className="">
                                    <thead>
                                      <tr>
                                        <th>RW Payment/Receipt	</th>
                                        <th>Record Type	 </th>
                                        <th>Payment/Receipt Date	 </th>
                                        <th>Status</th>
                                        <th>Total Amount	</th>
                                        <th>Rwood Bank Master	 </th>
                                        <th>Mode Of Payment	</th>
                                        <th>Description </th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receipts_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receipts_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                              <Link to={"/account/receipt-lineitem/" + y.rw_pay_receipt_detail_id}>
                                                  {y.rw_payment_receipt}
                                                  </Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.record_type}
                                            </td>
                                            <td>{y.payment_receipt_date}</td>
                                            <td>{y.status}</td>
                                            <td>{y.amount}</td>
                                            <td>{y.rwood_bank_master}</td>
                                            <td>{y.mode_of_payment}</td>
                                            <td>{y.description}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>



                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Account History</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="historys" className="">
                                    <thead>
                                      <tr>
                                        <th>Field Name</th>
                                        <th>New Value</th>
                                        <th>Old Value</th>
                                        <th>Changed By</th>
                                        <th>Change Time</th>
                                      </tr>
                                    </thead>

                                    {x.history.length > 0 ? (
                                      <tbody>
                                        {x.history.map((y, i) => (
                                          <tr>
                                            <td>{y.field_name}</td>
                                            <td>{y.new_value}</td>
                                            <td>{y.old_value}</td>
                                            <td>{y.changed_by}</td>
                                            <td>{y.change_time}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>

                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {x.account_details.account_record_type === "Bank" ? (
                              <>
                                {isEditMode ? (
                                  <>
                                    <div className="contactAccount">
                                      <div className="tasks">
                                        <p className="edit-btn">
                                          <input
                                            type="submit"
                                            className="account-save"
                                            onClick={handleSaveEdit}
                                            value={isSubmitted ? "Saving" : "Save"}
                                            disabled={isSubmitted}
                                          />
                                          <button
                                            className="btn btn-primary"
                                            onClick={handleCancelEdit}
                                          >
                                            Cancel
                                          </button>
                                        </p>
                                        <Row className="mb-3">
                                          <h4 className="heading">
                                            Account Information
                                          </h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Name"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Account Name"
                                                name="account_name"
                                                className="error-validation"
                                                defaultValue={data1.account_name || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                            {errors.account_name && (
                                              <div className="text-danger">{errors.account_name}</div>
                                            )}
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account record type"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Account record type"
                                                name="account_record_type"
                                                className="record-type"
                                                defaultValue={
                                                  data1.account_record_type || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Company"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Company"
                                                name="company"
                                                defaultValue={data1.company || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Owner"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Account Owner"
                                                name="account_owner"
                                                defaultValue={data1.account_owner || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Alias"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Account Alias"
                                                name="account_alias"
                                                className="error-validation"
                                                defaultValue={data1.account_alias || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Parent Account"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Parent Account"
                                                name="parent_account"
                                                defaultValue={
                                                  data1.parent_account || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Port"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Port"
                                                name="port"
                                                defaultValue={data1.port || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Fax"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Fax"
                                                name="fax"
                                                defaultValue={data1.fax || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Origin"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Origin"
                                                name="origin"
                                                defaultValue={data1.origin || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Phone"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Phone"
                                                name="phone"
                                                defaultValue={data1.phone || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Product"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Product"
                                                name="product"
                                                defaultValue={data1.product || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Employees"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Employees"
                                                name="employees"
                                                defaultValue={data1.employees || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6" className="tools">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Trader"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Trader"
                                                name="trader"
                                                defaultValue={data1.trader || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                            <OverlayTrigger
                                              placement="right"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={trader}
                                            >
                                              <Button
                                                variant="success"
                                                className="tooltips"
                                              >
                                                <IoIosHelpCircle id="help" />
                                              </Button>
                                            </OverlayTrigger>{" "}
                                          </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">KYC Documents</h4>
                                          {documents.map((document, index) => (
                                            <React.Fragment key={index}>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId={`floatingInputName${index}`}
                                                  label="Document Name"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Document Name"
                                                    defaultValue={document.document_name}
                                                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="5">
                                                <FloatingLabel
                                                  controlId={`floatingInputValidity${index}`}
                                                  label="Validity"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Validity"
                                                    defaultValue={document.validity}
                                                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="1">
                                                {index > 0 && (
                                                  <span id="links" onClick={() => handleRemoveDocument(index)}>
                                                    <FaTrashAlt />
                                                  </span>
                                                )}
                                              </Form.Group>
                                            </React.Fragment>
                                          ))}
                                          <span id="links" onClick={handleAddDocument}>Add Document</span>
                                          {/* <Button onClick={handleSubmit}>Create Account</Button> */}
                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">
                                            Address Information
                                          </h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Billing Address"
                                            >
                                              <Form.Control
                                                as="textarea" id='textareas'
                                                placeholder="Billing Address"
                                                style={{ height: "100px" }}
                                                name="billing_street"
                                                defaultValue={
                                                  data1.billing_street || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Shipping Address"
                                            >
                                              <Form.Control
                                                as="textarea" id='textareas'
                                                placeholder="Shipping Address"
                                                style={{ height: "100px" }}
                                                name="shipping_street"
                                                defaultValue={
                                                  data1.shipping_street || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing postal code"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Billing postal code"
                                                name="billing_postal_code"
                                                defaultValue={
                                                  data1.billing_postal_code || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping postal code"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Shipping postal code"
                                                name="shipping_postal_code"
                                                defaultValue={
                                                  data1.shipping_postal_code || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing city"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Billing city"
                                                name="billing_city"
                                                defaultValue={data1.billing_city || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping city"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Shipping city"
                                                name="shipping_city"
                                                defaultValue={data1.shipping_city || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing state province"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Billing state province"
                                                name="billing_state_or_province"
                                                defaultValue={
                                                  data1.billing_state_or_province || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping state province"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Shipping state province"
                                                name="shipping_state_or_province"
                                                defaultValue={
                                                  data1.shipping_state_or_province || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing country"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Billing country"
                                                name="billing_country"
                                                defaultValue={
                                                  data1.billing_country || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping country"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Billing country"
                                                name="shipping_country"
                                                defaultValue={
                                                  data1.shipping_country || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Row>

                                        <div className="successDiv">
                                          <p></p><p></p>
                                        </div>
                                        <p className="edit-btn">
                                          <input
                                            type="submit"
                                            className="account-save"
                                            onClick={handleSaveEdit}
                                            value={isSubmitted ? "Saving" : "Save"}
                                            disabled={isSubmitted}
                                          />
                                          <button
                                            className="btn btn-primary"
                                            onClick={handleCancelEdit}
                                          >
                                            Cancel
                                          </button>
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="contactAccount">
                                      <div className="tasks">
                                        <div className="edit-delete">
                                          <span id="edit" onClick={handleEditClick}>
                                            Edit
                                          </span>
                                          <span>
                                            <Link to={"/accounts/account-list"}>
                                              {" "}
                                              Cancel
                                            </Link>
                                          </span>
                                        </div>

                                        <Accordion
                                          allowMultipleExpanded={true}
                                          preExpanded={["a", "b", "c", "g"]}
                                        >
                                          <AccordionItem uuid="a">
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                Account Information
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              <table class="table table-bordered account-table tables" id="remove-table">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Account Code</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_code}
                                                    </td>
                                                    <td id="td-right">
                                                      <span></span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Account Name</span>
                                                    </td>
                                                    <td>
                                                      <Link to={"/accounts/" + id} > {x.account_details.account_name} </Link>
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Account record type</span>
                                                    </td>
                                                    <td>
                                                      {
                                                        x.account_details
                                                          .account_record_type
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Company</span>
                                                    </td>
                                                    <td>{x.account_details.company}</td>

                                                    <td id="td-right">
                                                      <span>Account Owner</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_owner}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Account alias{" "}
                                                        <OverlayTrigger
                                                          placement="right"
                                                          delay={{
                                                            show: 250,
                                                            hide: 400,
                                                          }}
                                                          overlay={renderTooltip}
                                                        >
                                                          <Button
                                                            variant="success"
                                                            className="tooltips"
                                                          >
                                                            <IoIosHelpCircle id="help" />
                                                          </Button>
                                                        </OverlayTrigger>{" "}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_alias}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Parent Account</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.parent_account}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Port</span>
                                                    </td>
                                                    <td>{x.account_details.port}</td>
                                                    <td id="td-right">
                                                      <span>Fax</span>
                                                    </td>
                                                    <td>{x.account_details.fax}</td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Origin</span>
                                                    </td>
                                                    <td>{x.account_details.origin}</td>
                                                    <td id="td-right">
                                                      <span>Phone</span>
                                                    </td>
                                                    <td>{x.account_details.phone}</td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Product</span>
                                                    </td>
                                                    <td>{x.account_details.product}</td>
                                                    <td id="td-right">
                                                      <span>Employess</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.employees}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Trader</span>
                                                    </td>
                                                    <td>{x.account_details.trader}</td>
                                                    <td id="td-right">
                                                      <span></span>
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </AccordionItemPanel>
                                          </AccordionItem>
                                          <AccordionItem uuid="g">
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                KYC Documents
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              {x.kyc_docs.length > 0 ?
                                                <div className="">
                                                  <div className="row">
                                                    <div className="col-md-12" id="head">

                                                      <hr></hr>
                                                    </div>
                                                  </div>

                                                  <table class="table table-bordered account-table kyc-table">
                                                    <tbody>
                                                      <tr>
                                                        <th>Document Name</th>
                                                        <th>Validity</th>
                                                      </tr>
                                                      {x.kyc_docs.map((x, i) =>

                                                        <tr>
                                                          <td>
                                                            {x.document_name}
                                                          </td>

                                                          <td>
                                                            {x.validity}
                                                          </td>
                                                        </tr>
                                                      )}
                                                    </tbody>
                                                  </table>

                                                </div>
                                                : <></>
                                              }
                                              {/* <span id="links" onClick={handleAddDocument}>Add Document</span> */}

                                            </AccordionItemPanel>
                                          </AccordionItem>

                                          <AccordionItem uuid="b">
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                Address Information
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Billing Address</span>
                                                    </td>
                                                    <td>
                                                      <p className="bill-group"><span>{x.account_details.billing_street}</span><br />

                                                        <span>{
                                                          x.account_details
                                                            .billing_postal_code
                                                        }</span><br />
                                                        <span>{x.account_details.billing_city}</span><br />
                                                        <span>{
                                                          x.account_details
                                                            .billing_state_or_province
                                                        }</span><br />
                                                        <span>{
                                                          x.account_details
                                                            .billing_country
                                                        }</span><br />
                                                      </p>
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Shipping Address</span>
                                                    </td>
                                                    <td>
                                                      <p className="ship-group"><span>{
                                                        x.account_details
                                                          .shipping_street
                                                      }
                                                      </span><br />
                                                        <span>{
                                                          x.account_details
                                                            .shipping_postal_code
                                                        }</span><br />
                                                        <span> {x.account_details.shipping_city}</span><br />
                                                        <span>{
                                                          x.account_details
                                                            .shipping_state_or_province
                                                        }</span><br />
                                                        <span>{
                                                          x.account_details
                                                            .shipping_country
                                                        }</span><br />
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </AccordionItemPanel>
                                          </AccordionItem>

                                          <AccordionItem uuid="c">
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                System Information
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Created By</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.created_at}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Updated At</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.updated_at}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </AccordionItemPanel>
                                          </AccordionItem>
                                        </Accordion>
                                          <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="head"
                                  >
                                    <h4 className="heading">
                                      <span>
                                        Opportunity Information
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmit}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>
                                                Opportunity Record
                                                Type
                                              </label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={
                                                  handleChange
                                                }
                                                value={
                                                  formData.account_record
                                                }
                                              >
                                                <option>
                                                  --Select Opportunity
                                                  Record Type--
                                                </option>

                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Spot">
                                                  Supplier - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <div id="inner-heading"></div>
                                  <table id="opp" className="">
                                    <thead>
                                      <tr>
                                        <th>Opportunity no</th>
                                        <th>Opportunity name</th>
                                        <th>Stage</th>
                                        <th>Quantity</th>
                                        <th>Laycan From  </th>
                                        <th>Laycan To </th>
                                        <th>Price  </th>
                                        <th>Trader Name  </th>
                                        <th>Supplier</th>
                                        <th>Vessel Type
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.opportunity.length > 0 ? (
                                      <tbody>
                                        {x.opportunity.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/opportunity/opportunity-detail" +
                                                    "/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.opportunity_no}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>

                                              {y.opportunity_name}
                                            </td>
                                            <td>

                                              {y.stage}
                                            </td>
                                            <td>
                                              {y.quantity}
                                            </td>
                                            <td>{y.laycan_from}</td>
                                            <td>{y.laycan_to}</td>
                                            <td>{y.price}</td>
                                            <td><Link  to={"/user/user-details/" +y.user_id} >{y.trader_name}</Link></td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                            <td>{y.vessel_type}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr><td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contacts</span>
                                      <span onClick={contactHandle}>

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="contact" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Contact code</th>
                                        <th>Contact Name	</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Phone</th>
                                        <th>Title</th>
                                        <th>Reset Birthday Email System	</th>
                                        <th>Receive Birthday Emails</th>
                                      </tr>
                                    </thead>
                                    {x.contacts.length > 0 ? (
                                      <tbody>
                                        {x.contacts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={"/contact/contact-details" + "/" + y.id}
                                                >
                                                  {y.contact_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/contact/contact-details" + "/" + y.id}
                                              >
                                                {y.contact_full_name}
                                              </Link>
                                            </td>
                                            <td>{y.contact_email}</td>
                                            <td>{y.contact_mobile}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Files</span>
                                      <span>
                                        <i className=""></i>
                                        Upload Files
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Cases</span>
                                      <span>
                                        <i className=""></i>
                                        New Cases
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Trade Confirmation Form</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmittcf}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>TCF Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option>
                                                  --Select TCF Record Type--
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <div id="inner-heading"></div>
                                    <hr></hr>
                                  </div>
                                  <table id="tcf" className="">
                                    <thead>
                                      <tr>
                                        <th>TCF No</th>
                                        <th>Shipment Month	</th>
                                        <th>Vessel Name	</th>
                                        <th>Product Name	</th>
                                        <th>Final Gross Margin - Shipment	</th>
                                        <th>Final Gross Margin - Ton	</th>
                                        <th>TCF Status
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.tcf.length > 0 ? (
                                      <tbody>
                                        {x.tcf.map((x, i) => (
                                          <tr>
                                            <td>
                                              <Link to={"/tcf/tcf-details/" + x.id}>
                                                {x.tcf_no}
                                              </Link>
                                            </td>
                                            <td>{x.shipment_month}</td>
                                            <td>{x.vessel_type}</td>
                                            <td>{x.product_name}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{x.tcf_status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>  <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Product Master</span>
                                      <span >

                                        <i className=""></i>
                                        New Product Master

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="products-master" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Product Code	</th>
                                        <th>Product Name	</th>
                                        <th>Gross as received Typical	</th>
                                        <th>Gross Air Dried Typical	</th>
                                        <th>Ash Typical	</th>
                                        <th>Total Moisture Typical	</th>
                                        <th>Active
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr></tbody></table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Quotes (Surveyour 2)
                                      </span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Order Products

                                      </span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Buyer Products


                                      </span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Debit Notes



                                      </span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts




                                      </span>
                                      <span>New RW Payments/Receipts</span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>


                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {x.account_details.account_record_type ===
                                  "Supplier" ? (
                                  <>
                                    {isEditMode ? (
                                      <div className="tasks">
                                        <p className="edit-btn">
                                          <input
                                            type="submit"
                                            className="account-save"
                                            onClick={handleSaveEdit}
                                            value={isSubmitted ? "Saving" : "Save"}
                                            disabled={isSubmitted}
                                          />
                                          <button
                                            className="btn btn-primary"
                                            onClick={handleCancelEdit}
                                          >
                                            Cancel
                                          </button>
                                        </p>
                                        <Row className="mb-3">
                                          <h4 className="heading">Account Information</h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Name"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.account_name || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Account Name"
                                                name="account_name"
                                              />
                                            </FloatingLabel>
                                            {errors.account_name && (
                                              <div className="text-danger">{errors.account_name}</div>
                                            )}
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Owner"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.account_owner || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Account Owner"
                                                name="account_owner"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Alias"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.account_alias || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Account Alias"
                                                name="account_alias"
                                                className="error-validation"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          {/* <Form.Group as={Col} md="6"></Form.Group> */}
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account record type"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.account_record_type || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Account record type"
                                                value={data1.account_record_type}
                                                name="account_record_type" className='record-type'
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Website"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.website || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Website"
                                                name="website"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>


                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Number"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.account_number || ""}
                                                onChange={handleChangeInput}
                                                type="number"
                                                placeholder="Account Number"
                                                name="account_number"

                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6" className="tools">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Trader"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.trader || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Trader"
                                                name="trader"
                                              />
                                            </FloatingLabel>
                                            <OverlayTrigger
                                              placement="right"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={trader}
                                            >
                                              <Button variant="success" className="tooltips">
                                                <IoIosHelpCircle id="help" />
                                              </Button>
                                            </OverlayTrigger>{" "}
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="No of employees"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.no_of_employees || ""}
                                                onChange={handleChangeInput}
                                                type="number"
                                                placeholder="No of employees"
                                                name="no_of_employees"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>


                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Product"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.product || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Product"
                                                name="product"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">

                                          </Form.Group>




                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Remarks"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.remarks || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Remarks"
                                                name="remarks"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6"></Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">Address Information</h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Region"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.region || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Region"
                                                name="region"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Billing Address"
                                            >
                                              <Form.Control defaultValue={data1.billing_street || ""}
                                                onChange={handleChangeInput}
                                                as="textarea" id='textareas'
                                                placeholder="Billing Address"
                                                style={{ height: "100px" }}
                                                name="billing_street"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Shipping Address"
                                            >
                                              <Form.Control defaultValue={data1.shipping_street || ""}
                                                onChange={handleChangeInput}
                                                as="textarea" id='textareas'
                                                placeholder="Shipping Address"
                                                style={{ height: "100px" }}
                                                name="shipping_street"
                                              />                </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing postal code"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.billing_postal_code || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Billing postal code"
                                                name="billing_postal_code"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping postal code"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.shipping_postal_code || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Shipping postal code"
                                                name="shipping_postal_code"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing city"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.billing_city || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Billing city"
                                                name="billing_city"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping city"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.shipping_city || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Shipping city"
                                                name="shipping_city"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>


                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing state province"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.billing_state_or_province || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Billing state province"
                                                name="billing_state_or_province"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping state province"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.shipping_state_or_province || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Shipping state province"
                                                name="shipping_state_or_province"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>


                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Billing country"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.billing_country || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Billing country"
                                                name="billing_country"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Shipping country"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.shipping_country || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Billing country"
                                                name="shipping_country"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                          <h4 className="heading">KYC Documents</h4>
                                          {documents.map((document, index) => (
                                            <React.Fragment key={index}>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId={`floatingInputName${index}`}
                                                  label="Document Name"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Document Name"
                                                    defaultValue={document.document_name}
                                                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="5">
                                                <FloatingLabel
                                                  controlId={`floatingInputValidity${index}`}
                                                  label="Validity"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Validity"
                                                    defaultValue={document.validity}
                                                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="1">
                                                {index > 0 && (
                                                  <span id="links" onClick={() => handleRemoveDocument(index)}>
                                                    <FaTrashAlt />
                                                  </span>
                                                )}
                                              </Form.Group>
                                            </React.Fragment>
                                          ))}
                                          <span id="links" onClick={handleAddDocument}>Add Document</span>
                                          {/* <Button onClick={handleSubmit}>Create Account</Button> */}
                                        </Row> <Row className="mb-3">
                                          <h4 className="heading">Rwood Reference</h4>
                                          {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Refrence Check Date"
            className="mb-3 datepick"


          >

<DatePicker
        selected={refrenceCheckDates}
        onChange={handleRefrenceCheck}
        dateFormat="dd/MM/yyyy"
      />
          </FloatingLabel>
        </Form.Group> */}
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Commitment"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="commitment"
                                                placeholder="Commitment"
                                                defaultValue={data1.commitment || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">--None--</option><option value="Medium Term">Medium Term</option><option value="Short Term">Short Term</option><option value="Long Term">Long Term</option><option value="Promptly">Promptly</option>
                                              </Form.Select>

                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Refrence check"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.refrence_check || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Refrence check"
                                                name="refrence_check"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Reliability"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.reliability || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Reliability"
                                                name="reliability"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="KYC docs"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.KYC_docs || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="KYC docs"
                                                name="KYC_docs"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6"></Form.Group>
                                          {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="KYC doc date"
            className="mb-3 datepick"


          >
             <DatePicker
        selected={kycDocDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
      />
          </FloatingLabel>
        </Form.Group> */}
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Rating"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="rating"
                                                placeholder="Rating"
                                                defaultValue={data1.rating || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">--None--</option><option value="Hot">Hot</option><option value="Warm">Warm</option><option value="Cold">Cold</option></Form.Select>

                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6"></Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">Industry Information</h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Parent account"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.parent_account || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Parent account"
                                                name="parent_account"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="annual coal requirement in  MT"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.annual_coal_requirement_in_MT || ""}
                                                onChange={handleChangeInput}
                                                type="number"
                                                placeholder="annual coal requirement in  MT"
                                                name="annual_coal_requirement_in_MT"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Under group"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.under_group || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Under group"
                                                name="under_group"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Imported volume PA in MT"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.imported_volume_PA_in_MT || ""}
                                                onChange={handleChangeInput}
                                                type="number"
                                                placeholder="Imported volume PA in MT"
                                                name="imported_volume_PA_in_MT"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>{" "}
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Expansion setup or buyer status"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="expansion_setup_or_buyer_status"
                                                placeholder="Expansion setup or buyer status"
                                                defaultValue={data1.expansion_setup_or_buyer_status || ""}
                                                onChange={handleChangeInput}

                                              >
                                                <option value="">Select</option>
                                                <option value="Under Construction">
                                                  Under Construction
                                                </option>
                                                <option value="Operated">Operated</option>
                                                <option value="Permitted">Permitted</option>
                                              </Form.Select>

                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Quantity MT monthly"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.quantity_MT_monthly || ""}
                                                onChange={handleChangeInput}
                                                type="number"
                                                placeholder="Quantity MT monthly"
                                                name="quantity_MT_monthly"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6"></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Production /Capacity Utilisation (Unit %"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.production_capacity || ""}
                                                onChange={handleChangeInput}
                                                type="number"
                                                placeholder="Production /Capacity Utilisation (Unit %"
                                                name="production_capacity"

                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Sector"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="sector"
                                                placeholder="Sector"
                                                defaultValue={data1.sector || ""}
                                                onChange={handleChangeInput}

                                              >
                                                <option value="">Select</option>
                                                <option value="Chemicals">Chemicals</option>
                                                <option value="Constructions">Constructions</option>
                                                <option value="Electronics">Electronics</option>
                                                <option value="Energy">Energy</option>
                                                <option value="Engineering">Engineering</option>
                                                <option value="Environmental">Environmental</option>
                                                <option value="Government">Government</option>
                                                <option value="Manufacturing">Manufacturing</option>
                                                <option value="Other">Other</option>
                                                <option value="Shipping">Shipping</option>
                                              </Form.Select>

                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Originiaze import breakup"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.originiaze_import_breakup || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Originiaze import breakup"
                                                name="originiaze_import_breakup"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Market impression rating"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.market_impression_rating || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Market impression rating"
                                                name="market_impression_rating"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Port"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.port || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Port"
                                                name="port"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6"></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Origin"
                                              className="mb-3"
                                            >
                                              <Form.Control defaultValue={data1.origin || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Origin"
                                                name="origin"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                        </Row>
                                        <Row id="table-styles">
                                                    <div className="col-md-12 counter" id="head">
                                                      <h4 className="heading">
                                                        <span>Counter Party Information</span>
                                                        <span><Link to={"/account/counter-party/" + id}>
                                                          <i className=""></i>
                                                          New
                                                        </Link>
                                                        </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="counter" className="">
                                                      <thead>
                                                        <tr>

                                                          <th>Origin</th>

                                                          <th>Name</th>

                                                          <th>GAR</th>

                                                          <th>TM</th>
                                                          <th>ASH</th>
                                                          <th>TS</th>
                                                        </tr>
                                                      </thead>

                                                      {x.counter_party.length > 0 ? (
                                                        <tbody>
                                                          {x.counter_party.map((y, i) => (
                                                            <tr>
                                                              <td>{y.origin}</td>
                                                              <td>{y.name}</td>

                                                              <td>{y.gar}</td>
                                                              <td>{y.tm}</td>
                                                              <td>{y.ash}</td>
                                                              <td>{y.ts}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>   <Row className="mb-3">
                                          <h4 className="heading">Terms and Conditions</h4>
                                          <Form.Group as={Col} md="12" className="textarea-full">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Nomination of Vessel
                  "
                                            >
                                              <Form.Control defaultValue={data1.nomination_of_vessel || ""}
                                                onChange={handleChangeInput}
                                                as="textarea"
                                                placeholder="nomination_of_vessel"
                                                style={{ height: "300px!important" }}
                                                name="nomination_of_vessel"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="12" className="textarea-full">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Berth Details

                  "
                                            >
                                              <Form.Control defaultValue={data1.berth_details || ""}
                                                onChange={handleChangeInput}
                                                as="textarea"
                                                placeholder="berth_details"
                                                style={{ height: "300px!important" }}
                                                name="berth_details"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="12" className="textarea-full">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Loading Demurrage and Despatch Details
                  "
                                            >
                                              <Form.Control defaultValue={data1.loading_demurge_and_dispatch_details || ""}
                                                onChange={handleChangeInput}
                                                as="textarea"
                                                placeholder="loading_demurge_and_dispatch_details"
                                                style={{ height: "300px!important" }}
                                                name="loading_demurge_and_dispatch_details"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="12" className="textarea-full">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="General terms"
                                            >
                                              <Form.Control defaultValue={data1.general_terms || ""}
                                                onChange={handleChangeInput}
                                                as="textarea"
                                                placeholder="general_terms"
                                                style={{ height: "300px!important" }}
                                                name="general_terms"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="12" className="textarea-full">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Payment terms"
                                            >
                                              <Form.Control defaultValue={data1.payment_terms || ""}
                                                onChange={handleChangeInput}
                                                as="textarea"
                                                placeholder="Payment terms"
                                                style={{ height: "300px!important" }}
                                                name="payment_terms"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">System Information</h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Account Source"
                                              className="mb-3"


                                            >
                                              <Form.Control defaultValue={data1.account_source || ""}
                                                onChange={handleChangeInput}
                                                type="text"
                                                placeholder="Account Source"
                                                name="account_source"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                        </Row>

                                        <p className="edit-btn">
                                          <input
                                            type="submit"
                                            className="account-save"
                                            onClick={handleSaveEdit}
                                            value={isSubmitted ? "Saving" : "Save"}
                                            disabled={isSubmitted}
                                          />
                                          <button
                                            className="btn btn-primary"
                                            onClick={handleCancelEdit}
                                          >
                                            Cancel
                                          </button>
                                        </p>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="contactAccount">
                                          <div className="tasks">
                                            <div className="edit-delete">
                                              <span id="edit" onClick={handleEditClick}>
                                                Edit
                                              </span>
                                              <span>
                                                <Link to={"/accounts/account-list"}>
                                                  {" "}
                                                  Cancel
                                                </Link>
                                              </span>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12" id="head">
                                                  <h4 className="heading">
                                                    Account Information
                                                  </h4>

                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Account Code</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_code}
                                                    </td>
                                                    <td id="td-right">

                                                    </td>
                                                    <td>

                                                    </td>
                                                  </tr>

                                                  <tr>
                                                    <td id="td-right">
                                                      <span>account name</span>{" "}
                                                    </td>
                                                    <td>
                                                      <Link to={"/accounts/" + id} > {x.account_details.account_name} </Link>
                                                    </td>
                                                    <td id="td-right">
                                                      <span>account owner</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_owner}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>account alias</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_alias}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>account record type</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_record_type}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>website</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.website}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>account number</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_number}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>trader</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.trader}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>no of employees</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.no_of_employees}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Product</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.product}
                                                    </td>
                                                    <td id="td-right">
                                                      <span></span>{" "}
                                                    </td>
                                                    <td>

                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>remarks</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.remarks}
                                                    </td>
                                                    <td id="td-right">
                                                      <span></span>{" "}
                                                    </td>
                                                    <td>

                                                    </td>
                                                  </tr>

                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12" id="head">
                                                  <h4 className="heading">
                                                    Address Information
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>region</span>{" "}
                                                    </td>
                                                    <td>
                                                      {x.account_details.region}
                                                    </td>
                                                    <td id="td-right">

                                                    </td>
                                                    <td>

                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>billing Address</span><br />



                                                    </td>
                                                    <td>
                                                      <p className="bill-group"><span> {x.account_details.billing_street}</span><br />
                                                        <span> {x.account_details.billing_postal_code}</span><br />
                                                        <span> {x.account_details.billing_city}</span><br />
                                                        <span>  {x.account_details.billing_state_or_province}</span><br />
                                                        <span> {x.account_details.billing_country}</span><br />
                                                      </p>
                                                    </td>
                                                    <td id="td-right">
                                                      <span>shipping Address</span>{" "}
                                                    </td>
                                                    <td>
                                                      <p className="bill-group"><span>{x.account_details.shipping_street}</span><br />
                                                        <span>{x.account_details.shipping_postal_code}</span><br />
                                                        <span>{x.account_details.shipping_city}</span><br />
                                                        <span> {x.account_details.shipping_state_or_province}</span><br />
                                                        <span> {x.account_details.shipping_country}</span><br />
                                                      </p>
                                                    </td>
                                                  </tr>

                                                </tbody>
                                              </table>
                                            </div>
                                            <Row>
                                              {/* <h4 className="heading">KYC Documents</h4> */}

                                              {x.kyc_docs.length > 0 ? (
                                                <div className="">
                                                  <div className="row">
                                                    <div className="col-md-12" id="head">
                                                      <h4 className="heading">
                                                        KYC Documents
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                  </div>

                                                  <table class="table table-bordered account-table kyc-table">
                                                    <tbody>
                                                      <tr>
                                                        <th>Document Name</th>
                                                        <th>Validity</th>
                                                      </tr>
                                                      {x.kyc_docs.map((x, i) => (
                                                        <tr>
                                                          <td>{x.document_name}</td>

                                                          <td>{x.validity}</td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </Row>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12" id="head">
                                                  <h4 className="heading">
                                                    Rwood Reference
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Refrence check date</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.refrence_check_date}
                                                    </td>

                                                    <td id="td-right">
                                                      <span>Commitment</span>
                                                    </td>
                                                    <td>{x.account_details.commitment}</td>
                                                  </tr>
                                                  <tr>

                                                    <td id="td-right">
                                                      <span>Reference Check</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.refrence_check}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Reliability</span>
                                                    </td>
                                                    <td>{x.account_details.reliability}</td>
                                                  </tr>
                                                  <tr><td id="td-right">
                                                    <span>KYC docs</span>
                                                  </td>
                                                    <td>
                                                      {x.account_details.KYC_docs}
                                                    </td>
                                                    <td id="td-right">
                                                      <span></span>
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>KYC doc date</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.KYC_doc_date}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Rating</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.rating}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12" id="head">
                                                  <h4 className="heading">
                                                    Industry Information
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Parent Account</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.parent_account}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Annual coal requirement in MT
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.annual_coal_requirement_in_MT}
                                                    </td>
                                                  </tr>

                                                  <tr>

                                                    <td id="td-right">
                                                      <span>Under Gourp</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.under_group}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Imported Volume PA in MT
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        x.account_details
                                                          .imported_volume_PA_in_MT
                                                      }
                                                    </td>
                                                  </tr>

                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Expansion Setup / Buyer Status
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        x.account_details
                                                          .expansion_setup_or_buyer_status
                                                      }
                                                    </td>

                                                    <td id="td-right">
                                                      <span>Quantity MT monthly</span>
                                                    </td>
                                                    <td>{x.account_details.quantity_MT_monthly}</td>
                                                  </tr>

                                                  <tr>
                                                    <td id="td-right">
                                                      <span></span>
                                                    </td>
                                                    <td></td>
                                                    <td id="td-right">
                                                      <span>Production /Capacity Utilisation (Unit %)</span>
                                                    </td>
                                                    <td>{x.account_details.production_capacity}</td>



                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Sector</span>
                                                    </td>
                                                    <td>{x.account_details.sector}</td>
                                                    <td id="td-right">
                                                      <span>Originiaze import breakup</span>
                                                    </td>
                                                    <td>{x.account_details.originiaze_import_breakup}</td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>market impression rating</span>
                                                    </td>
                                                    <td>{x.account_details.market_impression_rating}</td>

                                                    <td id="td-right">
                                                      <span>Port</span>
                                                    </td>
                                                    <td>{x.account_details.port}</td>

                                                  </tr>

                                                </tbody>
                                              </table>
                                            </div>
                                            <Row id="table-styles">
                                                    <div className="col-md-12 counter" id="head">
                                                      <h4 className="heading ">
                                                        <span>Counter Party Information</span>
                                                        <span><Link to={"/account/counter-party/" + id}>
                                                          <i className=""></i>
                                                          New
                                                        </Link>
                                                        </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="counter" className="">
                                                      <thead>
                                                        <tr>

                                                          <th>Origin</th>

                                                          <th>Name</th>

                                                          <th>GAR</th>

                                                          <th>TM</th>
                                                          <th>ASH</th>
                                                          <th>TS</th>
                                                        </tr>
                                                      </thead>

                                                      {x.counter_party.length > 0 ? (
                                                        <tbody>
                                                          {x.counter_party.map((y, i) => (
                                                            <tr>
                                                              <td>{y.origin}</td>
                                                              <td>{y.name}</td>

                                                              <td>{y.gar}</td>
                                                              <td>{y.tm}</td>
                                                              <td>{y.ash}</td>
                                                              <td>{y.ts}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12" id="head">
                                                  <h4 className="heading">
                                                    Terms and Conditions
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>nomination of vessel</span>
                                                    </td>
                                                    <td>{x.account_details.nomination_of_vessel}</td>
                                                    <td id="td-right"></td>
                                                    <td></td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>berth details</span>
                                                    </td>
                                                    <td>{x.account_details.berth_details}</td>
                                                    <td id="td-right"></td>
                                                    <td></td>
                                                  </tr>

                                                  <tr>
                                                    <td id="td-right">
                                                      <span>loading demurge and dispatch details</span>
                                                    </td>
                                                    <td>{x.account_details.loading_demurge_and_dispatch_details}</td>
                                                    <td id="td-right"></td>
                                                    <td></td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>general terms</span>
                                                    </td>
                                                    <td>{x.account_details.general_terms}</td>
                                                    <td id="td-right"></td>
                                                    <td></td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>payment terms</span>
                                                    </td>
                                                    <td>{x.account_details.payment_terms}</td>
                                                    <td id="td-right"></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12" id="head">
                                                  <h4 className="heading">
                                                    System Information
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Account Source</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.account_source}
                                                    </td>
                                                    <td id="td-right">
                                                      <span></span>
                                                    </td>
                                                    <td>

                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Created By</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.created_at}
                                                    </td>
                                                    <td id="td-right">
                                                      <span></span>
                                                    </td>
                                                    <td>

                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Last Modified By	</span>
                                                    </td>
                                                    <td>
                                                      {x.account_details.last_viewed_at}
                                                    </td>
                                                    <td id="td-right">
                                                      <span></span>
                                                    </td>
                                                    <td>

                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="tasks view-table"
                                          Style="box-showdow:0px 13px 20px #d6d1d1"
                                        >
                                 <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contact Roles</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contacts</span>
                                      <span onClick={contactHandle}>

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="contact" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Contact code</th>
                                        <th>Contact Name	</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Phone</th>
                                        <th>Title</th>
                                        <th>Reset Birthday Email System	</th>
                                        <th>Receive Birthday Emails</th>
                                      </tr>
                                    </thead>
                                    {x.contacts.length > 0 ? (
                                      <tbody>
                                        {x.contacts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={"/contact/contact-details" + "/" + y.id}
                                                >
                                                  {y.contact_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/contact/contact-details" + "/" + y.id}
                                              >
                                                {y.contact_full_name}
                                              </Link>
                                            </td>
                                            <td>{y.contact_email}</td>
                                            <td>{y.contact_mobile}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Partners</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Product Master</span>
                                      <span >

                                        <i className=""></i>
                                        New Product Master

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="products-master" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Product Code	</th>
                                        <th>Product Name	</th>
                                        <th>Gross as received Typical	</th>
                                        <th>Gross Air Dried Typical	</th>
                                        <th>Ash Typical	</th>
                                        <th>Total Moisture Typical	</th>
                                        <th>Active
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr></tbody></table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Products</span>
                                      <span >

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="products" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Product Name	</th>
                                        <th>Product Code	</th>
                                        <th>Gross as received Typical	</th>
                                        <th>Ash Typical	</th>
                                        <th>Total Moisture Typical	</th>
                                        <th>Sulphur Typical	</th>
                                        <th>Sulphur Typical ADB
                                        </th>
                                        <th>Month</th>
                                        <th>Year</th>
                                        <th>Buyer Name
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                      <td></td>
                                      <td></td>
                                       <td></td>
                                       <td></td>
                                       <td></td>
                                       <td></td>
                                       <td></td>
                                       <td></td>
                                       <td></td> <td></td>
                                       </tr></tbody></table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Master Clauses
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Master Clauses

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="head"
                                  >
                                    <h4 className="heading">
                                      <span>
                                        Opportunity Information
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmit}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>
                                                Opportunity Record
                                                Type
                                              </label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={
                                                  handleChange
                                                }
                                                value={
                                                  formData.account_record
                                                }
                                              >
                                                <option>
                                                  --Select Opportunity
                                                  Record Type--
                                                </option>

                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Spot">
                                                  Supplier - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="opp" className="">
                                    <thead>
                                      <tr>
                                        <th>Opportunity name</th>
                                        <th>Account Name  </th>
                                        <th>Close Date  </th>
                                        <th>Inquiry Date  </th>
                                        <th>Destination </th>
                                        <th>Stage</th>
                                      </tr>
                                    </thead>

                                    {x.opportunity.length > 0 ? (
                                      <tbody>
                                        {x.opportunity.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/opportunity/opportunity-detail" +
                                                    "/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.opportunity_name}
                                                </Link>
                                              </span>
                                            </td>

                                            <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td>
                                              {y.close_date}
                                            </td>
                                            <td>{y.inquiry_date }</td>
                                            <td>{y.destination  }</td>
                                            <td>{y.stage  }</td>

                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>

                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="head"
                                  >
                                    <h4 className="heading">
                                      <span>
                                        Opportunity Information
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmit}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>
                                                Opportunity Record
                                                Type
                                              </label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={
                                                  handleChange
                                                }
                                                value={
                                                  formData.account_record
                                                }
                                              >
                                                <option>
                                                  --Select Opportunity
                                                  Record Type--
                                                </option>

                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Spot">
                                                  Supplier - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <div id="inner-heading"></div>
                                  <table id="opp-new" className="">
                                    <thead>
                                      <tr>
                                        <th>Opportunity name</th>
                                        <th>Stage</th>
                                        <th>Amount</th>
                                        <th>Close Date
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.opportunity.length > 0 ? (
                                      <tbody>
                                        {x.opportunity.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/opportunity/opportunity-detail" +
                                                    "/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.opportunity_name}
                                                </Link>
                                              </span>
                                            </td>

                                             <td>{y.stage}</td>
                                            <td>{y.amount }</td>
                                            <td>
                                              {y.close_date}
                                            </td>


                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>

                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Quotes</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="quotes" className="">
                                    <thead>
                                      <tr>
                                        <th>Quote Name</th>
                                        <th>Opportunity Name</th>
                                        <th>Syncing</th>
                                        <th>Expiration Date </th>
                                        <th>Subtotal</th>
                                        <th>Total Price
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.quotes.length > 0 ? (
                                      <tbody>
                                        {x.quotes.map((y, i) => (
                                          <tr>
                                            <td>
                                              <Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_name}</Link>
                                            </td>
                                            <td><Link to={`/opportunity/opportunity-detail/${y.opportunity_id}`}>{y.opportunity_name}</Link></td>
                                           <td>{y.syncing}</td>
                                           <td>{y.expiration_date}</td>
                                           <td>{y.subtotal}</td>
                                           <td>{y.total_price}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contract Information</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmitcontract}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>Account Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option value="">--Select record type--</option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                                <option value="Supplier">
                                                  Supplier
                                                </option>
                                              </select>
                                              {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="contract" className="">
                                    <thead>
                                      <tr>
                                        <th>Rwood contract number</th>
                                        <th>Contract Record Type  </th>
                                        <th>Contract Start Date   </th>
                                        <th>Contract End Date  </th>
                                        <th>Status</th>
                                        <th>Contract Quantity (MT)   </th>
                                        <th>Destination</th>
                                        <th>Laycan From  </th>
                                        <th>Laycan To </th>
                                      </tr>
                                    </thead>

                                    {x.contracts.length > 0 ? (
                                      <tbody>
                                        {x.contracts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_number}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.contract_record_type}
                                            </td>
                                            <td>{y.contract_start_date}</td>
                                            <td>{y.contract_end_date}</td>
                                            <td>{y.contract_status}</td>
                                            <td>{y.contract_quantity_in_MT}</td>
                                            <td>{y.destination}</td>
                                            <td>{y.laycan_from}</td>
                                            <td>{y.laycan_to}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contract Information (Supplier)</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmitcontract}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>Account Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option value="">--Select record type--</option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                                <option value="Supplier">
                                                  Supplier
                                                </option>
                                              </select>
                                              {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="contractSupplier" className="">
                                    <thead>
                                      <tr>
                                        <th>contract number</th>
                                        <th>rwood contract number</th>
                                        <th>Account Name  </th>
                                        <th>Status </th>
                                        <th>Contract Quantity (MT)  </th>
                                        <th>Price PMT  </th>
                                      </tr>
                                    </thead>

                                    {x.contracts.length > 0 ? (
                                      <tbody>
                                        {x.contracts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_no}
                                                </Link>
                                              </span>
                                            </td><td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_number}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td>{y.status}</td>
                                            <td>{y.contract_quantity_in_MT}</td>
                                            <td>{y.price_PMT}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>

                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
     <div
       className="col-md-12"
       id="head"
     >
       <h4 className="heading">
         <span>Orders (Supplier)</span>
       </h4>
       <hr></hr>
     </div>
     <table id="order-supplier" className="">
       <thead>
         <tr>
           <th>Order Number</th>
           <th>Status</th>
           <th>Vessel Name  </th>
           <th>Account Name </th>
           <th>Supplier </th>
           <th>BL Date    </th>
           <th>Total BL Qty
           </th>
         </tr>
       </thead>

       {x.orders.length > 0 ? (
         <tbody>
           {x.orders.map((y, i) => (
             <tr>
               <td>
                 <span id="codes">
                   <Link
                     to={
                       "/order/view-order-details/" +
                       y.id
                     }
                   >
                     {y.order_no}
                                 </Link>
                 </span>
               </td>
               <td>{y.status}</td>
               <td>{y.vessel_name}</td>
               <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
               <td>{y.bl_date}</td>
               <td>
                 {y.total_bl_qty}
               </td>

             </tr>
           ))}
         </tbody>
       ) : (
         <tbody>
           <tr>
           <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody>
       )}
     </table>
   </Row>
   <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Order Products
                                      </span>

                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>



                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Trade Confirmation Form</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmittcf}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>TCF Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option>
                                                  --Select TCF Record Type--
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <div id="inner-heading"></div>
                                    <hr></hr>
                                  </div>
                                  <table id="tcf" className="">
                                    <thead>
                                      <tr>
                                        <th>TCF No</th>
                                        <th>Shipment Month	</th>
                                        <th>Vessel Name	</th>
                                        <th>Product Name	</th>
                                        <th>Final Gross Margin - Shipment	</th>
                                        <th>Final Gross Margin - Ton	</th>
                                        <th>TCF Status
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.tcf.length > 0 ? (
                                      <tbody>
                                        {x.tcf.map((x, i) => (
                                          <tr>
                                            <td>
                                              <Link to={"/tcf/tcf-details/" + x.id}>
                                                {x.tcf_no}
                                              </Link>
                                            </td>
                                            <td>{x.shipment_month}</td>
                                            <td>{x.vessel_type}</td>
                                            <td>{x.product_name}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{x.tcf_status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>  <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Debit Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Debit Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="debit" className="">
                                    <thead>
                                      <tr>
                                        <th>Record Type	</th>
                                        <th>Debit Note No	</th>
                                        <th>Debit Note Amount	</th>
                                        <th>Debit Note Date	</th>
                                        <th>Debit Note Type	</th>
                                        <th>Debite Note Status	</th>
                                        <th>Vessel Name	</th>
                                        <th>BL Quantity
                                        </th>
                                        </tr></thead>
                                        <tbody>
                                          <tr>
                                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                          </tr>
                                        </tbody>
                                  </table>

                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>
                                        Rw Payable/ Receivable LineItem (Account)
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Rw Payable/ Receivable LineItem
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receivable" className="">
                                    <thead>
                                      <tr>
                                        <th>Rw Payable/ Receivable LineItem	</th>
                                        <th>Amount </th>
                                        <th>Record Type	  </th>
                                        <th>Line Item	</th>
                                        <th>BL Date	</th>
                                        <th>Vessel Name	 </th>
                                        <th>Quantity</th>
                                        <th>Order</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receivable_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receivable_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                              <Link
                              to={"/account/receivable/" + y.id}
                            >
                                                  {y.rw_payment_receivable_lineitem}</Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.amount}
                                            </td>
                                            <td>{y.record_type}</td>
                                            <td>{y.line_item}</td>
                                            <td>{y.bl_date}</td>
                                            <td>{y.vessel_name}</td>
                                            <td>{y.quantity}</td>
                                            <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                            <td>{y.status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts</span>
                                      <span>
                                        <i className=""></i>
                                        New RW Payment/Receipt
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receipt" className="">
                                    <thead>
                                      <tr>
                                        <th>RW Payment/Receipt	</th>
                                        <th>Record Type	 </th>
                                        <th>Payment/Receipt Date	 </th>
                                        <th>Status</th>
                                        <th>Total Amount	</th>
                                        <th>Rwood Bank Master	 </th>
                                        <th>Mode Of Payment	</th>
                                        <th>Description </th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receipts_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receipts_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">

                                              <Link to={"/account/receipt-lineitem/" + y.rw_pay_receipt_detail_id}>
                                                  {y.rw_payment_receipt}
                                                  </Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.record_type}
                                            </td>
                                            <td>{y.payment_receipt_date}</td>
                                            <td>{y.status}</td>
                                            <td>{y.amount}</td>
                                            <td>{y.rwood_bank_master}</td>
                                            <td>{y.mode_of_payment}</td>
                                            <td>{y.description}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>
                             <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts LineItem
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New RW Payments/Receipts LineItem

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Open Activities

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Account History</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="historys" className="">
                                    <thead>
                                      <tr>
                                        <th>Field Name</th>
                                        <th>New Value</th>
                                        <th>Old Value</th>
                                        <th>Changed By</th>
                                        <th>Change Time</th>
                                      </tr>
                                    </thead>

                                    {x.history.length > 0 ? (
                                      <tbody>
                                        {x.history.map((y, i) => (
                                          <tr>
                                            <td>{y.field_name}</td>
                                            <td>{y.new_value}</td>
                                            <td>{y.old_value}</td>
                                            <td>{y.changed_by}</td>
                                            <td>{y.change_time}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes & Attachments</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Attach File
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Notes
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Files</span>
                                      <span>
                                        <i className=""></i>
                                        Upload Files
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {x.account_details.account_record_type ===
                                      "Competitor" ? (
                                      <>
                                        {isEditMode ? (
                                          <div className="tasks">
                                            <p className="edit-btn">
                                              <input
                                                type="submit"
                                                className="account-save"
                                                onClick={handleSaveEdit}
                                                value={isSubmitted ? "Saving" : "Save"}
                                                disabled={isSubmitted}
                                              />
                                              <button
                                                className="btn btn-primary"
                                                onClick={handleCancelEdit}
                                              >
                                                Cancel
                                              </button>
                                            </p>

                                            <Row className="mb-3">
                                              <h4 className="heading">Account Information</h4>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Account Name"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.account_name || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Account Name"
                                                    name="account_name"
                                                    className="error-validation"
                                                  />
                                                </FloatingLabel>
                                                {errors.account_name && (
                                                  <div className="text-danger">{errors.account_name}</div>
                                                )}
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Account Owner"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.account_owner || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Account Owner"
                                                    name="account_owner"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Account Alias"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.account_alias || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Account Alias"
                                                    name="account_alias"
                                                    className="error-validation"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Parent Account"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.parent_account || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Parent Account"
                                                    name="parent_account"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Website"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.website || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Website"
                                                    name="website"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Account Number"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.account_number || ""}
                                                    onChange={handleChangeInput}
                                                    type="number"
                                                    placeholder="Account Number"
                                                    name="account_number"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6" className="tools">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Trader"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.trader || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Trader"
                                                    name="trader"
                                                  />
                                                </FloatingLabel>
                                                <OverlayTrigger
                                                  placement="right"
                                                  delay={{ show: 250, hide: 400 }}
                                                  overlay={trader}
                                                >
                                                  <Button variant="success" className="tooltips">
                                                    <IoIosHelpCircle id="help" />
                                                  </Button>
                                                </OverlayTrigger>{" "}
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Account record type"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Account record type"
                                                    value={data1.account_record_type}
                                                    name="account_record_type" className='record-type'
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>




                                              <Form.Group as={Col} md="6"></Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Annual Revenue"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.annual_revenue || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Annual Revenue"
                                                    name="annual_revenue"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6"></Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="No of employees"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.no_of_employees || ""}
                                                    onChange={handleChangeInput}
                                                    type="number"
                                                    placeholder="No of employees"
                                                    name="no_of_employees"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                              <h4 className="heading">Address Information</h4>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Region"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.region || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Region"
                                                    name="region"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6"></Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingTextarea2"
                                                  label="Billing Address"
                                                >
                                                  <Form.Control defaultValue={data1.billing_street || ""}
                                                    onChange={handleChangeInput}
                                                    as="textarea" id='textareas'
                                                    placeholder="Billing Address"
                                                    style={{ height: "100px" }}
                                                    name="billing_street"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingTextarea2"
                                                  label="Shipping Address"
                                                >
                                                  <Form.Control defaultValue={data1.shipping_street || ""}
                                                    onChange={handleChangeInput}
                                                    as="textarea" id='textareas'
                                                    placeholder="Shipping Address"
                                                    style={{ height: "100px" }}
                                                    name="shipping_street"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Billing postal code"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.billing_postal_code || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Billing postal code"
                                                    name="billing_postal_code"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Billing city"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.billing_city || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Billing city"
                                                    name="billing_city"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Shipping postal code"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.shipping_postal_code || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Shipping postal code"
                                                    name="shipping_postal_code"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Billing state province"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.billing_state_or_province || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Billing state province"
                                                    name="billing_state_or_province"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Shipping state province"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.shipping_state_or_province || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Shipping state province"
                                                    name="shipping_state_or_province"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Shipping city"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.shipping_city || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Shipping city"
                                                    name="shipping_city"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Billing country"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.billing_country || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Billing country"
                                                    name="billing_country"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Shipping country"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.shipping_country || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Billing country"
                                                    name="shipping_country"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Phone"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.phone || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Phone"
                                                    name="phone"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Fax"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.fax || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="fax"
                                                    name="fax"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                              <h4 className="heading">KYC Documents</h4>
                                              {documents.map((document, index) => (
                                                <React.Fragment key={index}>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId={`floatingInputName${index}`}
                                                      label="Document Name"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        type="text"
                                                        placeholder="Document Name"
                                                        defaultValue={document.document_name}
                                                        onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="5">
                                                    <FloatingLabel
                                                      controlId={`floatingInputValidity${index}`}
                                                      label="Validity"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        type="text"
                                                        placeholder="Validity"
                                                        defaultValue={document.validity}
                                                        onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="1">
                                                    {index > 0 && (
                                                      <span id="links" onClick={() => handleRemoveDocument(index)}>
                                                        <FaTrashAlt />
                                                      </span>
                                                    )}
                                                  </Form.Group>
                                                </React.Fragment>
                                              ))}
                                              <span id="links" onClick={handleAddDocument}>Add Document</span>
                                              {/* <Button onClick={handleSubmit}>Create Account</Button> */}
                                            </Row>  <Row className="mb-3">
                                              <h4 className="heading">Rwood Reference</h4>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="KYC docs"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="KYC docs"
                                                    name="KYC_docs"
                                                    defaultValue={data1.KYC_docs || ""}
                                                    onChange={handleChangeInput}
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingSelect"
                                                  className="dropDown"
                                                  label="Commitment"
                                                >
                                                  <Form.Select
                                                    aria-label="Floating label select example"
                                                    name="commitment"
                                                    placeholder="Commitment"
                                                    defaultValue={data1.commitment || ""}
                                                    onChange={handleChangeInput}
                                                  >
                                                    <option value="">--None--</option>
                                                    <option value="Medium Term">Medium Term</option>
                                                    <option value="Short Term">Short Term</option>
                                                    <option value="Long Term">Long Term</option>
                                                    <option value="Promptly">Promptly</option>
                                                  </Form.Select>
                                                </FloatingLabel>
                                              </Form.Group>

                                              {/* <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="KYC doc date"
                              className="mb-3 datepick"
                            >
                               <DatePicker
        selected={kycDocDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
      />
                            </FloatingLabel>
                          </Form.Group> */}

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Reliability"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Reliability"
                                                    name="reliability"
                                                    defaultValue={data1.reliability || ""}
                                                    onChange={handleChangeInput}
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Refrence check"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Refrence check"
                                                    name="refrence_check"
                                                    defaultValue={data1.refrence_check || ""}
                                                    onChange={handleChangeInput}
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6"></Form.Group>
                                              {/* <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Refrence Check Date"
                              className="mb-3 datepick"
                            >
                             <DatePicker
        selected={refrenceCheckDates}
        onChange={handleRefrenceCheck}
        dateFormat="dd/MM/yyyy"
      />
                            </FloatingLabel>
                          </Form.Group> */}
                                              <Form.Group as={Col} md="6"></Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                              <h4 className="heading">Industry Information</h4>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Under group"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.under_group || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Under group"
                                                    name="under_group"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="annual coal requirement in  MT"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.annual_coal_requirement_in_MT || ""}
                                                    onChange={handleChangeInput}
                                                    type="number"
                                                    placeholder="annual coal requirement in  MT"
                                                    name="annual_coal_requirement_in_MT"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Station name"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.station_name || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Station name"
                                                    name="station_name"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Imported volume PA in MT"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.imported_volume_PA_in_MT || ""}
                                                    onChange={handleChangeInput}
                                                    type="number"
                                                    placeholder="Imported volume PA in MT"
                                                    name="imported_volume_PA_in_MT"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingSelect"
                                                  className="dropDown"
                                                  label="Expansion setup or buyer status"
                                                >
                                                  <Form.Select
                                                    aria-label="Floating label select example"
                                                    name="expansion_setup_or_buyer_status"
                                                    placeholder="Expansion setup or buyer status"
                                                    defaultValue={data1.expansion_setup_or_buyer_status || ""}
                                                    onChange={handleChangeInput}
                                                  >
                                                    <option value="">Select</option>
                                                    <option value="Under Construction">
                                                      Under Construction
                                                    </option>
                                                    <option value="Operated">Operated</option>
                                                    <option value="Permitted">Permitted</option>
                                                  </Form.Select>

                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Imported volume from indonesia PA in MT"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.imported_volume_from_indonesia_PA_in_MT || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Imported volume from indonesia PA in MT"
                                                    name="imported_volume_from_indonesia_PA_in_MT"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6"></Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Quantity MT monthly"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.quantity_MT_monthly || ""}
                                                    onChange={handleChangeInput}
                                                    type="number"
                                                    placeholder="Quantity MT monthly"
                                                    name="quantity_MT_monthly"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingSelect"
                                                  className="dropDown"
                                                  label="Industry"
                                                >
                                                  <Form.Select
                                                    aria-label="Floating label select example"
                                                    name="industry"
                                                    placeholder="Industry"
                                                    defaultValue={data1.industry || ""}
                                                    onChange={handleChangeInput}

                                                  >
                                                    <option value="">Select</option>
                                                    <option value="Chemicals">Chemicals</option>
                                                    <option value="Constructions">Constructions</option>
                                                    <option value="Electronics">Electronics</option>
                                                    <option value="Energy">Energy</option>
                                                    <option value="Engineering">Engineering</option>
                                                    <option value="Environmental">Environmental</option>
                                                    <option value="Government">Government</option>
                                                    <option value="Manufacturing">Manufacturing</option>
                                                    <option value="Other">Other</option>
                                                    <option value="Shipping">Shipping</option>
                                                  </Form.Select>

                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Production /Capacity Utilisation (Unit %)"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.production_capacity || ""}
                                                    onChange={handleChangeInput}
                                                    type="number"
                                                    placeholder="Production /Capacity Utilisation (Unit %)"
                                                    name="production_capacity"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>


                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingSelect"
                                                  className="dropDown"
                                                  label="Sector"
                                                >
                                                  <Form.Select
                                                    defaultValue={data1.sector || ""}
                                                    onChange={handleChangeInput}
                                                    aria-label="Floating label select example"
                                                    name="sector"
                                                    placeholder="Sector"

                                                  >
                                                    <option value="">Select</option>
                                                    <option value="Government">Government</option>
                                                    <option value="Power Sector">Power Sector</option>
                                                    <option value="Private Sector">Private Sector</option>
                                                    <option value="Industrial">Industrial</option>
                                                  </Form.Select>
                                                </FloatingLabel>
                                              </Form.Group>{" "}
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Originiaze import breakup"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.originiaze_import_breakup || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Originiaze import breakup"
                                                    name="originiaze_import_breakup"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6"></Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Port"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.port || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Port"
                                                    name="port"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Market impression rating"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.market_impression_rating || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Market impression rating"
                                                    name="market_impression_rating"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Origin"
                                                  className="mb-3"
                                                >
                                                  <Form.Control defaultValue={data1.origin || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Origin"
                                                    name="origin"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                              <h4 className="heading">Terms and Conditions</h4>

                                              <Form.Group as={Col} md="12" className="textarea-full">
                                                <FloatingLabel
                                                  controlId="floatingTextarea2"
                                                  label="General terms"
                                                >
                                                  <Form.Control defaultValue={data1.general_terms || ""}
                                                    onChange={handleChangeInput}
                                                    as="textarea"
                                                    placeholder="General terms"
                                                    style={{ height: "100px" }}
                                                    name="general_terms"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>

                                              <Form.Group as={Col} md="12" className="textarea-full">
                                                <FloatingLabel
                                                  controlId="floatingTextarea2"
                                                  label="Payment terms"
                                                >
                                                  <Form.Control defaultValue={data1.payment_terms || ""}
                                                    onChange={handleChangeInput}
                                                    as="textarea"
                                                    placeholder="Payment terms"
                                                    style={{ height: "100px" }}
                                                    name="payment_terms"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                              <h4 className="heading">System Information</h4>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Account Source"
                                                  className="mb-3"


                                                >
                                                  <Form.Control defaultValue={data1.account_source || ""}
                                                    onChange={handleChangeInput}
                                                    type="text"
                                                    placeholder="Account Source"
                                                    name="account_source"
                                                  />
                                                </FloatingLabel>
                                              </Form.Group>
                                              <Form.Group as={Col} md="6">
                                                <FloatingLabel
                                                  controlId="floatingSelect"
                                                  className="dropDown"
                                                  label="Rating"
                                                >
                                                  <Form.Select
                                                    aria-label="Floating label select example"
                                                    name="rating"
                                                    placeholder="Rating"
                                                    defaultValue={data1.rating || ""}
                                                    onChange={handleChangeInput}
                                                  >
                                                    <option value="">--None--</option><option value="Hot">Hot</option><option value="Warm">Warm</option><option value="Cold">Cold</option></Form.Select>

                                                </FloatingLabel>
                                              </Form.Group>
                                            </Row>
                                            <p className="edit-btn">
                                              <input
                                                type="submit"
                                                className="account-save"
                                                onClick={handleSaveEdit}
                                                value={isSubmitted ? "Saving" : "Save"}
                                                disabled={isSubmitted}
                                              />
                                              <button
                                                className="btn btn-primary"
                                                onClick={handleCancelEdit}
                                              >
                                                Cancel
                                              </button>
                                            </p>{" "}
                                          </div>
                                        ) : (
                                          <>
                                            <div className="contactAccount">
                                              <div className="tasks">
                                                <div className="edit-delete">
                                                  <span
                                                    id="edit"
                                                    onClick={handleEditClick}
                                                  >
                                                    Edit
                                                  </span>
                                                  <span>
                                                    <Link to={"/accounts/account-list"}>
                                                      {" "}
                                                      Cancel
                                                    </Link>
                                                  </span>
                                                </div>
                                                <div className="">
                                                  <div className="row">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        Account Information
                                                      </h4>

                                                      <hr></hr>
                                                    </div>
                                                  </div>
                                                  <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Account Code</span>{" "}
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .account_code
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span></span>
                                                        </td>
                                                        <td>

                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Account Name</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .account_name
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Account Owner</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .account_owner
                                                          }
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>
                                                            Account alias{" "}
                                                            <OverlayTrigger
                                                              placement="right"
                                                              delay={{
                                                                show: 250,
                                                                hide: 400,
                                                              }}
                                                              overlay={renderTooltip}
                                                            >
                                                              <Button
                                                                variant="success"
                                                                className="tooltips"
                                                              >
                                                                <IoIosHelpCircle id="help" />
                                                              </Button>
                                                            </OverlayTrigger>{" "}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .account_alias
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Parent Account</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .parent_account
                                                          }
                                                        </td>
                                                      </tr>


                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Website</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.website}
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Account Number</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .account_number
                                                          }
                                                        </td>
                                                      </tr>


                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Trader</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.trader}
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Account Record Type</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .account_record_type
                                                          }
                                                        </td>
                                                      </tr>



                                                      <tr>

                                                        <td id="td-right">
                                                          <span></span>
                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td id="td-right">
                                                          <span>annual revenue</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.annual_revenue}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span></span>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td id="td-right">
                                                          <span>no of employees</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.no_of_employees}
                                                        </td>



                                                      </tr>

                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="">
                                                  <div className="row">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        Address Information
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                  </div>
                                                  <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>region</span>{" "}
                                                        </td>
                                                        <td>
                                                          {x.account_details.region}
                                                        </td>
                                                        <td id="td-right">

                                                        </td>
                                                        <td>

                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>billing Address</span>{" "}
                                                        </td>
                                                        <td>
                                                          <span> {x.account_details.billing_street}</span><br />
                                                          <span> {x.account_details.billing_postal_code}</span><br />
                                                          <span>  {x.account_details.billing_city}</span><br />
                                                          <span>{x.account_details.billing_state_or_province}</span><br />
                                                          <span> {x.account_details.billing_country}</span><br />


                                                        </td>
                                                        <td id="td-right">
                                                          <span>shipping Address</span>{" "}
                                                        </td>
                                                        <td>

                                                          <span>{x.account_details.shipping_street}</span><br />
                                                          <span>{x.account_details.shipping_postal_code}</span><br />
                                                          <span>{x.account_details.shipping_city}</span><br />
                                                          <span> {x.account_details.shipping_state_or_province}</span><br />
                                                          <span>{x.account_details.shipping_country}</span><br />
                                                        </td>
                                                      </tr>




                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Phone</span>{" "}
                                                        </td>
                                                        <td>
                                                          {x.account_details.phone}
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Fax</span>{" "}
                                                        </td>
                                                        <td>
                                                          {x.account_details.fax}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                {x.kyc_docs.length > 0 ? (
                                                  <div className="">
                                                    <div className="row">
                                                      <div
                                                        className="col-md-12"
                                                        id="head"
                                                      >
                                                        <h4 className="heading">
                                                          KYC Documents
                                                        </h4>
                                                        <hr></hr>
                                                      </div>
                                                    </div>

                                                    <table class="table table-bordered account-table kyc-table">
                                                      <tbody>
                                                        <tr>
                                                          <th>Document Name</th>
                                                          <th>Validity</th>
                                                        </tr>
                                                        {x.kyc_docs.map((x, i) => (
                                                          <tr>
                                                            <td>{x.document_name}</td>

                                                            <td>{x.validity}</td>
                                                          </tr>
                                                        ))}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                <div className="">
                                                  <div className="row">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        Rwood Reference
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                  </div>
                                                  <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>KYC Docs</span>
                                                        </td>
                                                        <td>{x.account_details.KYC_docs}</td>
                                                        <td id="td-right">
                                                          <span>Commitment</span>
                                                        </td>
                                                        <td>{x.account_details.commitment}</td>
                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>KYC doc date</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.KYC_doc_date}
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Reliability</span>
                                                        </td>
                                                        <td>{x.account_details.reliability}</td>
                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Reference Check</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.refrence_check}
                                                        </td>
                                                        <td id="td-right">
                                                          <span></span>
                                                        </td>
                                                        <td></td>
                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Refrence check date</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.refrence_check_date}
                                                        </td>
                                                        <td id="td-right">
                                                          <span></span>
                                                        </td>
                                                        <td></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>                       </div>
                                                <div className="">
                                                  <div className="row">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        Industry Information
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                  </div>
                                                  <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Under Gourp</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .under_group
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>
                                                            Annual coal requirement in
                                                            MT
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .annual_coal_requirement_in_MT
                                                          }
                                                        </td>

                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Station name</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .station_name
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>
                                                            Imported Volume PA in MT
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .imported_volume_PA_in_MT
                                                          }
                                                        </td>

                                                      </tr>

                                                      <tr>
                                                        <td id="td-right">
                                                          <span>
                                                            Expansion Setup / Buyer Status
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .expansion_setup_or_buyer_status
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>
                                                            Imported volume from indonesia PA in MT
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .imported_volume_from_indonesia_PA_in_MT
                                                          }
                                                        </td>

                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span></span>{" "}
                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td id="td-right">
                                                          <span>quantity MT (Monthly)</span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.quantity_MT_monthly}
                                                        </td>

                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Industry</span>
                                                        </td>
                                                        <td>{x.account_details.industry}</td>
                                                        <td id="td-right">
                                                          <span>
                                                            Production /Capacity Utilisation (Unit
                                                            %)
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {x.account_details.production_capacity}
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Sector</span>
                                                        </td>
                                                        <td>{x.account_details.sector}</td>
                                                        <td id="td-right">
                                                          <span>
                                                            Originiaze Import Break up (TPO/ %)
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .originiaze_import_breakup
                                                          }
                                                        </td>
                                                      </tr>


                                                      <tr>
                                                        <td id="td-right">
                                                          <span></span>{" "}
                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td id="td-right">
                                                          <span>Port</span>
                                                        </td>
                                                        <td>{x.account_details.port}</td>

                                                      </tr>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>Market impression Rating</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .market_impression_rating
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Origin</span>
                                                        </td>
                                                        <td>{x.account_details.origin}</td>

                                                      </tr>

                                                    </tbody>
                                                  </table>
                                                </div>

                                                <div className="">
                                                  <div className="row">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        Terms and Conditions
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                  </div>
                                                  <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>General terms</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .general_terms
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Payment Terms</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .payment_terms
                                                          }
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="">
                                                  <div className="row">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        System Information
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                  </div>
                                                  <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                      <tr>
                                                        <td id="td-right">
                                                          <span>account source</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .account_source
                                                          }
                                                        </td>
                                                        <td id="td-right">
                                                          <span>Rating</span>
                                                        </td>
                                                        <td>
                                                          {
                                                            x.account_details
                                                              .rating
                                                          }
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>

                                              </div>
                                            </div>

                                            <div
                                              className="tasks view-table"
                                              Style="box-showdow:0px 13px 20px #d6d1d1"
                                            >
                                           <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contact Roles</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Partners</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contacts</span>
                                      <span onClick={contactHandle}>

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="contact" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Contact code</th>
                                        <th>Contact Name  </th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Phone</th>
                                        <th>Title</th>
                                        <th>Reset Birthday Email System </th>
                                        <th>Receive Birthday Emails</th>
                                      </tr>
                                    </thead>
                                    {x.contacts.length > 0 ? (
                                      <tbody>
                                        {x.contacts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={"/contact/contact-details" + "/" + y.id}
                                                >
                                                  {y.contact_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/contact/contact-details" + "/" + y.id}
                                              >
                                                {y.contact_full_name}
                                              </Link>
                                            </td>
                                            <td>{y.contact_email}</td>
                                            <td>{y.contact_mobile}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="head"
                                  >
                                    <h4 className="heading">
                                      <span>
                                        Opportunities
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmit}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>
                                                Opportunity Record
                                                Type
                                              </label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={
                                                  handleChange
                                                }
                                                value={
                                                  formData.account_record
                                                }
                                              >
                                                <option>
                                                  --Select Opportunity
                                                  Record Type--
                                                </option>

                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Spot">
                                                  Supplier - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <div id="inner-heading"></div>
                                  <table id="opp" className="">
                                    <thead>
                                      <tr>
                                        <th>Opportunity no</th>
                                        <th>Opportunity name</th>
                                        <th>Stage</th>
                                        <th>Quantity</th>
                                        <th>Laycan From  </th>
                                        <th>Laycan To </th>
                                        <th>Price  </th>
                                        <th>Trader Name  </th>
                                        <th>Supplier</th>
                                        <th>Vessel Type
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.opportunity.length > 0 ? (
                                      <tbody>
                                        {x.opportunity.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/opportunity/opportunity-detail" +
                                                    "/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.opportunity_no}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>

                                              {y.opportunity_name}
                                            </td>
                                            <td>

                                              {y.stage}
                                            </td>
                                            <td>
                                              {y.quantity}
                                            </td>
                                            <td>{y.laycan_from}</td>
                                            <td>{y.laycan_to}</td>
                                            <td>{y.price}</td>
                                            <td><Link  to={"/user/user-details/" +y.user_id} >{y.trader_name}</Link></td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                            <td>{y.vessel_type}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr><td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Quotes</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="quotes" className="">
                                    <thead>
                                      <tr>
                                        <th>Quote Number</th>
                                        <th>Account Name</th>
                                        <th>Quote Name</th>
                                        <th>Opportunity Name</th>
                                        <th>Currency</th>
                                        <th>Supplier</th>
                                      </tr>
                                    </thead>

                                    {x.quotes.length > 0 ? (
                                      <tbody>
                                        {x.quotes.map((y, i) => (
                                          <tr>
                                            <td>
                                              <Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_number}</Link>
                                            </td>
                                            <td><Link
                                              to={
                                                "/accounts/" +

                                                y.id
                                              }
                                            >
                                              {y.account_name}
                                            </Link></td>
                                            <td>{y.quote_name}</td>
                                            <td><Link to={`/opportunity/opportunity-detail/${y.opportunity_id}`}>{y.opportunity_name}</Link></td>
                                            <td>{y.currency}</td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Trade Confirmation Form</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmittcf}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>TCF Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option>
                                                  --Select TCF Record Type--
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <div id="inner-heading"></div>
                                    <hr></hr>
                                  </div>
                                  <table id="tcf" className="">
                                    <thead>
                                      <tr>
                                        <th>TCF No</th>
                                        <th>Shipment Month	</th>
                                        <th>Vessel Name	</th>
                                        <th>Product Name	</th>
                                        <th>Final Gross Margin - Shipment	</th>
                                        <th>Final Gross Margin - Ton	</th>
                                        <th>TCF Status
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.tcf.length > 0 ? (
                                      <tbody>
                                        {x.tcf.map((x, i) => (
                                          <tr>
                                            <td>
                                              <Link to={"/tcf/tcf-details/" + x.id}>
                                                {x.tcf_no}
                                              </Link>
                                            </td>
                                            <td>{x.shipment_month}</td>
                                            <td>{x.vessel_type}</td>
                                            <td>{x.product_name}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{x.tcf_status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contract Information</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmitcontract}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>Account Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option value="">--Select record type--</option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                                <option value="Supplier">
                                                  Supplier
                                                </option>
                                              </select>
                                              {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="contract" className="">
                                    <thead>
                                      <tr>
                                        <th>Rwood contract number</th>
                                        <th>Contract Record Type  </th>
                                        <th>Contract Start Date   </th>
                                        <th>Contract End Date  </th>
                                        <th>Status</th>
                                        <th>Contract Quantity (MT)   </th>
                                        <th>Destination</th>
                                        <th>Laycan From  </th>
                                        <th>Laycan To </th>
                                      </tr>
                                    </thead>

                                    {x.contracts.length > 0 ? (
                                      <tbody>
                                        {x.contracts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_number}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.contract_record_type}
                                            </td>
                                            <td>{y.contract_start_date}</td>
                                            <td>{y.contract_end_date}</td>
                                            <td>{y.contract_status}</td>
                                            <td>{y.contract_quantity_in_MT}</td>
                                            <td>{y.destination}</td>
                                            <td>{y.laycan_from}</td>
                                            <td>{y.laycan_to}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>Orders</span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="orders" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Order Code No</th>
                                                          <th>Contract Number </th>
                                                          <th>Vessel Name </th>
                                                          <th>BL Date </th>
                                                          <th>Shipment Qty  </th>
                                                          <th>Actual Payment Received Date  </th>
                                                          <th>Document Discount Date  </th>
                                                          <th>Supplier Invoice Amount
                                                          </th>
                                                        </tr>
                                                      </thead>

                                                      {x.orders.length > 0 ? (
                                                        <tbody>
                                                          {x.orders.map((y, i) => (
                                                            <tr>
                                                              <td>
                                                                <span id="codes">
                                                                  <Link
                                                                    to={
                                                                      "/order/view-order-details/" +
                                                                      y.id
                                                                    }
                                                                  >
                                                                    {y.order_no}
                                                                                </Link>
                                                                </span>
                                                              </td>
                                                              <td><Link
                                                                    to={
                                                                      "/contract/contract-details/" +y.contract_id
                                                                    }
                                                                  >{y.contract_number}</Link></td>
                                                              <td>{y.vessel_name}</td>
                                                              <td>{y.bl_date}</td>
                                                              <td>
                                                                {y.shipment_qty}
                                                              </td>
                                                              <td>
                                                              {y.all_actual_payment_recieved_date}
                                                              </td>
                                                              <td>{y.all_document_discount_date}</td>
                                                              <td></td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td><td></td><td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td><td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>


                                                  <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>
                                        Rw Payable/ Receivable LineItem (Account)
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Rw Payable/ Receivable LineItem
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receivable" className="">
                                    <thead>
                                      <tr>
                                        <th>Rw Payable/ Receivable LineItem	</th>
                                        <th>Amount </th>
                                        <th>Record Type	  </th>
                                        <th>Line Item	</th>
                                        <th>BL Date	</th>
                                        <th>Vessel Name	 </th>
                                        <th>Quantity</th>
                                        <th>Order</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receivable_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receivable_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                              <Link
                              to={"/account/receivable/" + y.id}
                            >
                                                  {y.rw_payment_receivable_lineitem}</Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.amount}
                                            </td>
                                            <td>{y.record_type}</td>
                                            <td>{y.line_item}</td>
                                            <td>{y.bl_date}</td>
                                            <td>{y.vessel_name}</td>
                                            <td>{y.quantity}</td>
                                            <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                            <td>{y.status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>
                                        Rw Payable/ Receivable LineItem (Account)
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Rw Payable/ Receivable LineItem
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receivable" className="">
                                    <thead>
                                      <tr>
                                        <th>Rw Payable/ Receivable LineItem	</th>
                                        <th>Amount </th>
                                        <th>Record Type	  </th>
                                        <th>Line Item	</th>
                                        <th>BL Date	</th>
                                        <th>Vessel Name	 </th>
                                        <th>Quantity</th>
                                        <th>Order</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receivable_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receivable_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">

                                              <Link
                              to={"/account/receivable/" + y.id}
                            >
                                                  {y.rw_payment_receivable_lineitem}</Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.amount}
                                            </td>
                                            <td>{y.record_type}</td>
                                            <td>{y.line_item}</td>
                                            <td>{y.bl_date}</td>
                                            <td>{y.vessel_name}</td>
                                            <td>{y.quantity}</td>
                                            <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                            <td>{y.status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>
   <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Debit Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Debit Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes & Attachments</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Attach File
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Files</span>
                                      <span>
                                        <i className=""></i>
                                        Upload Files
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New RW Payments/Receipts

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Account History</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="historys" className="">
                                    <thead>
                                      <tr>
                                        <th>Field Name</th>
                                        <th>New Value</th>
                                        <th>Old Value</th>
                                        <th>Changed By</th>
                                        <th>Change Time</th>
                                      </tr>
                                    </thead>

                                    {x.history.length > 0 ? (
                                      <tbody>
                                        {x.history.map((y, i) => (
                                          <tr>
                                            <td>{y.field_name}</td>
                                            <td>{y.new_value}</td>
                                            <td>{y.old_value}</td>
                                            <td>{y.changed_by}</td>
                                            <td>{y.change_time}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>


                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {x.account_details.account_record_type ===
                                          "Employee" ? (
                                          <>
                                            {isEditMode ? (
                                              <div className="tasks">
                                                <p className="edit-btn">
                                                  <input
                                                    type="submit"
                                                    className="account-save"
                                                    onClick={handleSaveEdit}
                                                    value={isSubmitted ? "Saving" : "Save"}
                                                    disabled={isSubmitted}
                                                  />
                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={handleCancelEdit}
                                                  >
                                                    Cancel
                                                  </button>
                                                </p>

                                                <Row className="mb-3">
                                                  <h4 className="heading">
                                                    Account Information
                                                  </h4>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Account Name"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.account_name || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Account Name"
                                                        name="account_name"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Account Owner"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.account_owner || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Account Owner"
                                                        name="account_owner"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Company"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.company || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Company"
                                                        name="company"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Account Alias"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.account_alias || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Account Alias"
                                                        name="account_alias"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Account record type"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.account_record_type ||
                                                          ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Account record type"
                                                        name="account_record_type"
                                                        className="record-type"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Parent Account"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.parent_account || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Parent Account"
                                                        name="parent_account"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Port"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={data1.port || ""}
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Port"
                                                        name="port"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Fax"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={data1.fax || ""}
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Fax"
                                                        name="fax"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Origin"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.origin || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Origin"
                                                        name="origin"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Phone"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={data1.phone || ""}
                                                        onChange={handleChangeInput}
                                                        type="number"
                                                        placeholder="Phone"
                                                        name="phone"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Product"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.product || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Product"
                                                        name="product"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Employees"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.employees || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Employees"
                                                        name="employees"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Remarks"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.remarks || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Remarks"
                                                        name="remarks"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                  <h4 className="heading">KYC Documents</h4>
                                                  {documents.map((document, index) => (
                                                    <React.Fragment key={index}>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId={`floatingInputName${index}`}
                                                          label="Document Name"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Document Name"
                                                            defaultValue={document.document_name}
                                                            onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="5">
                                                        <FloatingLabel
                                                          controlId={`floatingInputValidity${index}`}
                                                          label="Validity"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Validity"
                                                            defaultValue={document.validity}
                                                            onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="1">
                                                        {index > 0 && (
                                                          <span id="links" onClick={() => handleRemoveDocument(index)}>
                                                            <FaTrashAlt />
                                                          </span>
                                                        )}
                                                      </Form.Group>
                                                    </React.Fragment>
                                                  ))}
                                                  <span id="links" onClick={handleAddDocument}>Add Document</span>
                                                  {/* <Button onClick={handleSubmit}>Create Account</Button> */}
                                                </Row>
                                                <Row className="mb-3">
                                                  <h4 className="heading">
                                                    Address Information
                                                  </h4>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingTextarea2"
                                                      label="Billing Address"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.billing_street || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        as="textarea" id='textareas'
                                                        placeholder="Billing Address"
                                                        style={{ height: "100px" }}
                                                        name="billing_street"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>

                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingTextarea2"
                                                      label="Shipping Address"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.shipping_street || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        as="textarea" id='textareas'
                                                        placeholder="Shipping Address"
                                                        style={{ height: "100px" }}
                                                        name="shipping_street"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Billing postal code"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.billing_postal_code ||
                                                          ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Billing postal code"
                                                        name="billing_postal_code"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Shipping postal code"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.shipping_postal_code ||
                                                          ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Shipping postal code"
                                                        name="shipping_postal_code"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Billing city"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.billing_city || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Billing city"
                                                        name="billing_city"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Shipping city"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.shipping_city || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Shipping city"
                                                        name="shipping_city"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Billing state province"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.billing_state_or_province ||
                                                          ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Billing state province"
                                                        name="billing_state_or_province"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Shipping state province"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.shipping_state_or_province ||
                                                          ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Shipping state province"
                                                        name="shipping_state_or_province"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Billing country"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.billing_country || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Billing country"
                                                        name="billing_country"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                  <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                      controlId="floatingInput"
                                                      label="Shipping country"
                                                      className="mb-3"
                                                    >
                                                      <Form.Control
                                                        defaultValue={
                                                          data1.shipping_country || ""
                                                        }
                                                        onChange={handleChangeInput}
                                                        type="text"
                                                        placeholder="Billing country"
                                                        name="shipping_country"
                                                      />
                                                    </FloatingLabel>
                                                  </Form.Group>
                                                </Row>
                                                <p className="edit-btn">
                                                  <input
                                                    type="submit"
                                                    className="account-save"
                                                    onClick={handleSaveEdit}
                                                    value={isSubmitted ? "Saving" : "Save"}
                                                    disabled={isSubmitted}
                                                  />
                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={handleCancelEdit}
                                                  >
                                                    Cancel
                                                  </button>
                                                </p>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="contactAccount">
                                                  <div className="tasks">
                                                    <div className="edit-delete">
                                                      <span
                                                        id="edit"
                                                        onClick={handleEditClick}
                                                      >
                                                        Edit
                                                      </span>
                                                      <span>
                                                        <Link
                                                          to={"/accounts/account-list"}
                                                        >
                                                          {" "}
                                                          Cancel
                                                        </Link>
                                                      </span>
                                                    </div>
                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            Account Information
                                                          </h4>

                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Account Code</span>{" "}
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_code
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Account Name</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_name
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Account Owner</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_owner
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>
                                                                Account alias{" "}
                                                                <OverlayTrigger
                                                                  placement="right"
                                                                  delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                  }}
                                                                  overlay={
                                                                    renderTooltip
                                                                  }
                                                                >
                                                                  <Button
                                                                    variant="success"
                                                                    className="tooltips"
                                                                  >
                                                                    <IoIosHelpCircle id="help" />
                                                                  </Button>
                                                                </OverlayTrigger>{" "}
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_alias
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>
                                                                Account record type
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_record_type
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Company</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .company
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Port</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.port}
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Origin</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.origin}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Fax</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.fax}
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Phone</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.phone}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Product</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .product
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Product</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .product
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Employees</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .employees
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Remarks</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .remarks
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>

                                                    <Row>
                                                      {/* <h4 className="heading">KYC Documents</h4> */}

                                                      {x.kyc_docs.length > 0 ? (
                                                        <div className="">
                                                          <div className="row">
                                                            <div className="col-md-12" id="head">
                                                              <h4 className="heading">
                                                                KYC Documents
                                                              </h4>
                                                              <hr></hr>
                                                            </div>
                                                          </div>

                                                          <table class="table table-bordered account-table kyc-table">
                                                            <tbody>
                                                              <tr>
                                                                <th>Document Name</th>
                                                                <th>Validity</th>
                                                              </tr>
                                                              {x.kyc_docs.map((x, i) => (
                                                                <tr>
                                                                  <td>{x.document_name}</td>

                                                                  <td>{x.validity}</td>
                                                                </tr>
                                                              ))}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </Row>
                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            Address Information
                                                          </h4>
                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>billing Address</span>{" "}
                                                            </td>
                                                            <td>
                                                              <p className="bill-group"> <span> {x.account_details.billing_street}</span><br />
                                                                <span> {x.account_details.billing_postal_code}</span><br />
                                                                <span>  {x.account_details.billing_city}</span><br />
                                                                <span>{x.account_details.billing_state_or_province}</span><br />
                                                                <span> {x.account_details.billing_country}</span><br />
                                                              </p>


                                                            </td>
                                                            <td id="td-right">
                                                              <span>shipping Address</span>{" "}
                                                            </td>
                                                            <td><p className="bill-group">

                                                              <span>{x.account_details.shipping_street}</span><br />
                                                              <span>{x.account_details.shipping_postal_code}</span><br />
                                                              <span>{x.account_details.shipping_city}</span><br />
                                                              <span> {x.account_details.shipping_state_or_province}</span><br />
                                                              <span>{x.account_details.shipping_country}</span><br />
                                                            </p>
                                                            </td>
                                                          </tr>

                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className="tasks view-table"
                                                  Style="box-showdow:0px 13px 20px #d6d1d1"
                                                >

                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="head"
                                  >
                                    <h4 className="heading">
                                      <span>
                                        Opportunities
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmit}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>
                                                Opportunity Record
                                                Type
                                              </label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={
                                                  handleChange
                                                }
                                                value={
                                                  formData.account_record
                                                }
                                              >
                                                <option>
                                                  --Select Opportunity
                                                  Record Type--
                                                </option>

                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Spot">
                                                  Supplier - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <div id="inner-heading"></div>
                                  <table id="opp" className="">
                                    <thead>
                                      <tr>
                                        <th>Opportunity no</th>
                                        <th>Opportunity name</th>
                                        <th>Stage</th>
                                        <th>Quantity</th>
                                        <th>Laycan From  </th>
                                        <th>Laycan To </th>
                                        <th>Price  </th>
                                        <th>Trader Name  </th>
                                        <th>Supplier</th>
                                        <th>Vessel Type
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.opportunity.length > 0 ? (
                                      <tbody>
                                        {x.opportunity.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/opportunity/opportunity-detail" +
                                                    "/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.opportunity_no}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>

                                              {y.opportunity_name}
                                            </td>
                                            <td>

                                              {y.stage}
                                            </td>
                                            <td>
                                              {y.quantity}
                                            </td>
                                            <td>{y.laycan_from}</td>
                                            <td>{y.laycan_to}</td>
                                            <td>{y.price}</td>
                                            <td><Link  to={"/user/user-details/" +y.user_id} >{y.trader_name}</Link></td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                            <td>{y.vessel_type}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td><td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Files</span>
                                      <span>
                                        <i className=""></i>
                                        Upload Files
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Cases</span>
                                      <span>
                                        <i className=""></i>
                                        New Cases
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row> <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Trade Confirmation Form</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmittcf}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>TCF Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option>
                                                  --Select TCF Record Type--
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                              </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <div id="inner-heading"></div>
                                    <hr></hr>
                                  </div>
                                  <table id="tcf" className="">
                                    <thead>
                                      <tr>
                                        <th>TCF No</th>
                                        <th>Shipment Month	</th>
                                        <th>Vessel Name	</th>
                                        <th>Product Name	</th>
                                        <th>Final Gross Margin - Shipment	</th>
                                        <th>Final Gross Margin - Ton	</th>
                                        <th>TCF Status
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.tcf.length > 0 ? (
                                      <tbody>
                                        {x.tcf.map((x, i) => (
                                          <tr>
                                            <td>
                                              <Link to={"/tcf/tcf-details/" + x.id}>
                                                {x.tcf_no}
                                              </Link>
                                            </td>
                                            <td>{x.shipment_month}</td>
                                            <td>{x.vessel_type}</td>
                                            <td>{x.product_name}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{x.tcf_status}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>   <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Product Master</span>
                                      <span >

                                        <i className=""></i>
                                        New Product Master

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="products-master" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Product Code	</th>
                                        <th>Product Name	</th>
                                        <th>Gross as received Typical	</th>
                                        <th>Gross Air Dried Typical	</th>
                                        <th>Ash Typical	</th>
                                        <th>Total Moisture Typical	</th>
                                        <th>Active
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr></tbody></table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Quotes (Surveyour 2)
</span>

                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Order Products

                                      </span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Buyer Products


                                      </span>
                                      <span> New Buyer Product
                                      </span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Debit Notes



                                      </span>
                                        </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts
</span>
                                      <span>
                                        <i className=""></i>
                                        New RW Payments/Receipt

                                      </span>

                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>


                                       </div>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {x.account_details.account_record_type ===
                                              "Surveyor" ? (
                                              <>
                                                {isEditMode ? (
                                                  <div className="tasks">
                                                    <p className="edit-btn">
                                                      <input
                                                        type="submit"
                                                        className="account-save"
                                                        onClick={handleSaveEdit}
                                                        value={isSubmitted ? "Saving" : "Save"}
                                                        disabled={isSubmitted}
                                                      />
                                                      <button
                                                        className="btn btn-primary"
                                                        onClick={handleCancelEdit}
                                                      >
                                                        Cancel
                                                      </button>
                                                    </p>

                                                    <Row className="mb-3">
                                                      <h4 className="heading">Account Information</h4>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Account Name"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Account Name"
                                                            className="error-validation"
                                                            name="account_name"
                                                            defaultValue={data1.account_name || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                        {errors.account_name && (
                                                          <div className="text-danger">{errors.account_name}</div>
                                                        )}
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Account Owner"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Account Owner"
                                                            name="account_owner"
                                                            defaultValue={data1.account_owner || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6" className="tools">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Account Alias"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Account Alias"
                                                            name="account_alias"
                                                            className="error-validation"
                                                            defaultValue={data1.account_alias || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                        <OverlayTrigger
                                                          placement="right"
                                                          delay={{ show: 250, hide: 400 }}
                                                          overlay={renderTooltip}
                                                        >
                                                          <Button variant="success" className="tooltips">
                                                            <IoIosHelpCircle id="help" />
                                                          </Button>
                                                        </OverlayTrigger>{" "}
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Account Number"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Account Number"
                                                            name="account_number"
                                                            defaultValue={data1.account_number || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Website"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Website"
                                                            name="website"
                                                            defaultValue={data1.website || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Account record type"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Account record type"
                                                            value={data1.account_record_type}
                                                            name="account_record_type"
                                                            className="record-type"
                                                            defaultValue={data1.account_record_type || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6" className="tools">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Trader"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Trader"
                                                            name="trader"
                                                            defaultValue={data1.trader || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                        <OverlayTrigger
                                                          placement="right"
                                                          delay={{ show: 250, hide: 400 }}
                                                          overlay={trader}
                                                        >
                                                          <Button variant="success" className="tooltips">
                                                            <IoIosHelpCircle id="help" />
                                                          </Button>
                                                        </OverlayTrigger>{" "}
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Annual Revenue"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Annual Revenue"
                                                            name="annual_revenue"
                                                            defaultValue={data1.annual_revenue || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="No of employees"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="No of employees"
                                                            name="no_of_employees"
                                                            defaultValue={data1.no_of_employees || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Port"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Port"
                                                            name="port"
                                                            defaultValue={data1.port || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                   defaultValue={data1.annual_revenue || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group> */}

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Origin"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Origin"
                                                            name="origin"
                                                            defaultValue={data1.origin || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    defaultValue={data1.no_of_employees || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group> */}

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Product"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Product"
                                                            name="product"
                                                            defaultValue={data1.product || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Remarks"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Remarks"
                                                            name="remarks"
                                                            defaultValue={data1.remarks || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <h4 className="heading">Address Information</h4>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Region"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Region"
                                                            name="region"
                                                            defaultValue={data1.region || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6" className="tools">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Phone"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Phone"
                                                            name="phone"
                                                            defaultValue={data1.phone || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                        <OverlayTrigger
                                                          placement="left"
                                                          delay={{ show: 250, hide: 400 }}
                                                          overlay={phone}
                                                        >
                                                          <Button variant="success" className="tooltips">
                                                            <IoIosHelpCircle id="help" />
                                                          </Button>
                                                        </OverlayTrigger>{" "}
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingTextarea2"
                                                          label="Billing Address"
                                                        >
                                                          <Form.Control
                                                            as="textarea" id='textareas'
                                                            placeholder="Billing Address"
                                                            style={{ height: "100px" }}
                                                            name="billing_street"
                                                            defaultValue={data1.billing_street || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Fax"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="fax"
                                                            name="fax"
                                                            defaultValue={data1.fax || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Billing postal code"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Billing postal code"
                                                            name="billing_postal_code"
                                                            defaultValue={data1.billing_postal_code || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingTextarea2"
                                                          label="Shipping Address"
                                                        >
                                                          <Form.Control
                                                            as="textarea" id='textareas'
                                                            placeholder="Shipping Address"
                                                            style={{ height: "100px" }}
                                                            name="shipping_street"
                                                            defaultValue={data1.shipping_street || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Billing city"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Billing city"
                                                            name="billing_city"
                                                            defaultValue={data1.billing_city || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Shipping postal code"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Shipping postal code"
                                                            name="shipping_postal_code"
                                                            defaultValue={data1.shipping_postal_code || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Billing state province"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Billing state province"
                                                            name="billing_state_or_province"
                                                            defaultValue={
                                                              data1.billing_state_or_province || ""
                                                            }
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Shipping city"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Shipping city"
                                                            name="shipping_city"
                                                            defaultValue={data1.shipping_city || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Billing country"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Billing country"
                                                            name="billing_country"
                                                            defaultValue={data1.billing_country || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Shipping state province"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Shipping state province"
                                                            name="shipping_state_or_province"
                                                            defaultValue={
                                                              data1.shipping_state_or_province || ""
                                                            }
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Shipping country"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Billing country"
                                                            name="shipping_country"
                                                            defaultValue={data1.shipping_country || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <h4 className="heading">KYC Documents</h4>
                                                      {documents.map((document, index) => (
                                                        <React.Fragment key={index}>
                                                          <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                              controlId={`floatingInputName${index}`}
                                                              label="Document Name"
                                                              className="mb-3"
                                                            >
                                                              <Form.Control
                                                                type="text"
                                                                placeholder="Document Name"
                                                                defaultValue={document.document_name}
                                                                onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                                                              />
                                                            </FloatingLabel>
                                                          </Form.Group>
                                                          <Form.Group as={Col} md="5">
                                                            <FloatingLabel
                                                              controlId={`floatingInputValidity${index}`}
                                                              label="Validity"
                                                              className="mb-3"
                                                            >
                                                              <Form.Control
                                                                type="text"
                                                                placeholder="Validity"
                                                                defaultValue={document.validity}
                                                                onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                                                              />
                                                            </FloatingLabel>
                                                          </Form.Group>
                                                          <Form.Group as={Col} md="1">
                                                            {index > 0 && (
                                                              <span id="links" onClick={() => handleRemoveDocument(index)}>
                                                                <FaTrashAlt />
                                                              </span>
                                                            )}
                                                          </Form.Group>
                                                        </React.Fragment>
                                                      ))}
                                                      <span id="links" onClick={handleAddDocument}>Add Document</span>
                                                      {/* <Button onClick={handleSubmit}>Create Account</Button> */}
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <h4 className="heading">Rwood Reference</h4>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="KYC docs"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="KYC docs"
                                                            name="KYC_docs"
                                                            defaultValue={data1.KYC_docs || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingSelect"
                                                          className="dropDown"
                                                          label="Commitment"
                                                        >
                                                          <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="commitment"
                                                            placeholder="Commitment"
                                                            defaultValue={data1.commitment || ""}
                                                            onChange={handleChangeInput}
                                                          >
                                                            <option value="">--None--</option>
                                                            <option value="Medium Term">Medium Term</option>
                                                            <option value="Short Term">Short Term</option>
                                                            <option value="Long Term">Long Term</option>
                                                            <option value="Promptly">Promptly</option>
                                                          </Form.Select>
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      {/* <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="KYC doc date"
                              className="mb-3 datepick"
                            >
                               <DatePicker
        selected={kycDocDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
      />
                            </FloatingLabel>
                          </Form.Group> */}

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Reliability"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Reliability"
                                                            name="reliability"
                                                            defaultValue={data1.reliability || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Refrence check"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Refrence check"
                                                            name="refrence_check"
                                                            defaultValue={data1.refrence_check || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      {/* <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Refrence Check Date"
                              className="mb-3 datepick"
                            >
                             <DatePicker
        selected={refrenceCheckDates}
        onChange={handleRefrenceCheck}
        dateFormat="dd/MM/yyyy"
      />
                            </FloatingLabel>
                          </Form.Group> */}
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <h4 className="heading">Industry Information</h4>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Parent account"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Parent account"
                                                            name="parent_account"
                                                            defaultValue={data1.parent_account || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="annual coal requirement in  MT"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="annual coal requirement in  MT"
                                                            name="annual_coal_requirement_in_MT"
                                                            defaultValue={
                                                              data1.annual_coal_requirement_in_MT || ""
                                                            }
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Under group"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Under group"
                                                            name="under_group"
                                                            defaultValue={data1.under_group || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Imported volume PA in MT"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Imported volume PA in MT"
                                                            name="imported_volume_PA_in_MT"
                                                            defaultValue={
                                                              data1.imported_volume_PA_in_MT || ""
                                                            }
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>{" "}
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Station name"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Station name"
                                                            name="station_name"
                                                            defaultValue={data1.station_name || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>{" "}
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Quantity MT monthly"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Quantity MT monthly"
                                                            name="quantity_MT_monthly"
                                                            defaultValue={data1.quantity_MT_monthly || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingSelect"
                                                          className="dropDown"
                                                          label="Expansion setup or buyer status"
                                                        >
                                                          <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="expansion_setup_or_buyer_status"
                                                            placeholder="Expansion setup or buyer status"
                                                            defaultValue={data1.expansion_setup_or_buyer_status || ""}
                                                            onChange={handleChangeInput}
                                                          >
                                                            <option value="">Select</option>
                                                            <option value="Under Construction">
                                                              Under Construction
                                                            </option>
                                                            <option value="Operated">Operated</option>
                                                            <option value="Permitted">Permitted</option>
                                                          </Form.Select>

                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Production capacity"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Production capacity"
                                                            name="production_capacity"
                                                            defaultValue={data1.production_capacity || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Originiaze import breakup"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Originiaze import breakup"
                                                            name="originiaze_import_breakup"
                                                            defaultValue={
                                                              data1.originiaze_import_breakup || ""
                                                            }
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingSelect"
                                                          className="dropDown"
                                                          label="Industry"
                                                        >
                                                          <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="industry"
                                                            placeholder="Industry"
                                                            className="m-b"
                                                            defaultValue={data1.industry || ""}
                                                            onChange={handleChangeInput}
                                                          >
                                                            <option value="">Select</option>
                                                            <option value="Chemicals">Chemicals</option>
                                                            <option value="Constructions">
                                                              Constructions
                                                            </option>
                                                            <option value="Electronics">Electronics</option>
                                                            <option value="Energy">Energy</option>
                                                            <option value="Engineering">Engineering</option>
                                                            <option value="Environmental">
                                                              Environmental
                                                            </option>
                                                            <option value="Government">Government</option>
                                                            <option value="Manufacturing">
                                                              Manufacturing
                                                            </option>
                                                            <option value="Other">Other</option>
                                                            <option value="Shipping">Shipping</option>
                                                          </Form.Select>
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze import breakup"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze import breakup"
                    name="originiaze_import_breakup"
                    defaultValue={data1.originiaze_import_breakup || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group> */}
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingSelect"
                                                          className="dropDown"
                                                          label="Sector"
                                                        >
                                                          <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="sector"
                                                            placeholder="Sector"
                                                            className="m-b"
                                                            defaultValue={data1.sector || ""}
                                                            onChange={handleChangeInput}
                                                          >
                                                            <option value="">Select</option>
                                                            <option value="Government">Government</option>
                                                            <option value="Power Sector">
                                                              Power Sector
                                                            </option>
                                                            <option value="Private Sector">
                                                              Private Sector
                                                            </option>
                                                            <option value="Industrial">Industrial</option>
                                                          </Form.Select>
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                      <Form.Group as={Col} md="6"></Form.Group>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Market impression rating"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            className="m-b"
                                                            placeholder="Market impression rating"
                                                            name="market_impression_rating"
                                                            defaultValue={
                                                              data1.market_impression_rating || ""
                                                            }
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                    </Row>
                                                    {/* product Information */}
                                                    {/* <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    defaultValue={data1.coal_spec_1_3800_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    defaultValue={data1.coal_spec_3_4700_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"

                    defaultValue={data1.coal_spec_2_4200_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea" id='textareas'
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"

                    defaultValue={data1.description || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"

                    defaultValue={data1.coal_spec_4_5000_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"

                    defaultValue={data1.coal_spec_5_5500_GAR || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"

                    defaultValue={data1.coal_spec_6 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"

                    defaultValue={data1.coal_spec_7 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"

                    defaultValue={data1.coal_spec_8 || ""}
           onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
            </Row> */}
                                                    <Row className="mb-3">
                                                      <h4 className="heading">System Information</h4>
                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingInput"
                                                          label="Account Source"
                                                          className="mb-3"
                                                        >
                                                          <Form.Control
                                                            type="text"
                                                            placeholder="Account Source"
                                                            name="account_source"
                                                            defaultValue={data1.account_source || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>

                                                      <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                          controlId="floatingSelect"
                                                          className="dropDown"
                                                          label="Rating"
                                                        >
                                                          <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="rating"
                                                            placeholder="Rating"
                                                            defaultValue={data1.rating || ""}
                                                            onChange={handleChangeInput}
                                                          >
                                                            <option value="">--None--</option>
                                                            <option value="Hot">Hot</option>
                                                            <option value="Warm">Warm</option>
                                                            <option value="Cold">Cold</option>
                                                          </Form.Select>
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <h4 className="heading">Account Summary</h4>
                                                      <Form.Group as={Col} md="12" className="textarea-full">
                                                        <FloatingLabel
                                                          controlId="floatingTextarea2"
                                                          label="General terms"
                                                        >
                                                          <Form.Control
                                                            as="textarea"
                                                            placeholder="general_terms"
                                                            style={{ height: "200px!important" }}
                                                            name="general_terms"
                                                            defaultValue={data1.general_terms || ""}
                                                            onChange={handleChangeInput}
                                                          />
                                                        </FloatingLabel>
                                                      </Form.Group>
                                                    </Row>

                                                    <div className="successDiv">
                                                      <p></p><p></p>
                                                    </div>

                                                    <p className="edit-btn">
                                                      <input
                                                        type="submit"
                                                        className="account-save"
                                                        onClick={handleSaveEdit}
                                                        value={isSubmitted ? "Saving" : "Save"}
                                                        disabled={isSubmitted}
                                                      />
                                                      <button
                                                        className="btn btn-primary"
                                                        onClick={handleCancelEdit}
                                                      >
                                                        Cancel
                                                      </button>
                                                    </p>
                                                    <div className="successDiv">
                                                      <p></p><p></p>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>

                                                    <div className="contactAccount">
                                                      <div className="tasks">
                                                        <div className="edit-delete">
                                                          <span id="edit" onClick={handleEditClick}>
                                                            Edit
                                                          </span>
                                                          <span>
                                                            <Link to={"/accounts/account-list"}>
                                                              {" "}
                                                              Cancel
                                                            </Link>
                                                          </span>
                                                        </div>
                                                        <Accordion
                                                          allowMultipleExpanded={true}
                                                          preExpanded={["a", "b", "c", "d", "e", "f", "g"]}
                                                        >
                                                          <AccordionItem uuid="a">
                                                            <AccordionItemHeading>
                                                              <AccordionItemButton>
                                                                Account Information
                                                              </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                              <table class="table table-bordered account-table tables">
                                                                <tbody>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Account Code</span>{" "}
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.account_code}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Account Name</span>
                                                                    </td>
                                                                    <td>
                                                                      <Link to={"/accounts/" + x.account_id} > {x.account_details.account_name} </Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span>Account Owner</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.account_owner}
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>
                                                                        Account alias{" "}
                                                                        <OverlayTrigger
                                                                          placement="right"
                                                                          delay={{ show: 250, hide: 400 }}
                                                                          overlay={renderTooltip}
                                                                        >
                                                                          <Button
                                                                            variant="success"
                                                                            className="tooltips"
                                                                          >
                                                                            <IoIosHelpCircle id="help" />
                                                                          </Button>
                                                                        </OverlayTrigger>{" "}
                                                                      </span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.account_alias}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                    <td id="td-right">
                                                                      <span>Account Number</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.account_number}
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Website</span>
                                                                    </td>
                                                                    <td>{x.account_details.website}</td>
                                                                    <td id="td-right">
                                                                      <span>Account record type</span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .account_record_type
                                                                      }
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Port</span>
                                                                    </td>
                                                                    <td>{x.account_details.port}</td>
                                                                    <td id="td-right">
                                                                      <span>Annual Revenue</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.annual_revenue}
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Origin</span>
                                                                    </td>
                                                                    <td>{x.account_details.origin}</td>
                                                                    <td id="td-right">
                                                                      <span>no of employees</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.no_of_employees}
                                                                    </td>
                                                                  </tr>


                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Product</span>
                                                                    </td>
                                                                    <td>{x.account_details.product}</td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Remarks</span>
                                                                    </td>
                                                                    <td>{x.account_details.remarks}</td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </AccordionItemPanel>
                                                          </AccordionItem>
                                                          <AccordionItem uuid="b">
                                                            <AccordionItemHeading>
                                                              <AccordionItemButton>
                                                                Address Information
                                                              </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                              <table class="table table-bordered account-table tables">
                                                                <tbody>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Region</span>
                                                                    </td>
                                                                    <td>{x.account_details.region}</td>
                                                                    <td id="td-right">
                                                                      <span>Phone</span>
                                                                    </td>
                                                                    <td>{x.account_details.phone}</td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Fax</span>
                                                                    </td>
                                                                    <td>{x.account_details.fax}</td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td>

                                                                    </td>

                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>billing Address</span><br />



                                                                    </td>
                                                                    <td>
                                                                      <span> {x.account_details.billing_street}</span><br />
                                                                      <span> {x.account_details.billing_postal_code}</span><br />
                                                                      <span> {x.account_details.billing_city}</span><br />
                                                                      <span>  {x.account_details.billing_state_or_province}</span><br />
                                                                      <span> {x.account_details.billing_country}</span><br />

                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span>shipping Address</span>{" "}
                                                                    </td>
                                                                    <td>
                                                                      <span>{x.account_details.shipping_street}</span><br />
                                                                      <span>{x.account_details.shipping_postal_code}</span><br />
                                                                      <span>{x.account_details.shipping_city}</span><br />
                                                                      <span> {x.account_details.shipping_state_or_province}</span><br />
                                                                      <span> {x.account_details.shipping_country}</span><br />
                                                                    </td>
                                                                  </tr>

                                                                </tbody>
                                                              </table>
                                                            </AccordionItemPanel>
                                                          </AccordionItem>
                                                          <AccordionItem uuid="g">
                                                            <AccordionItemHeading>
                                                              <AccordionItemButton>
                                                                KYC Documents
                                                              </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                              {x.kyc_docs.length > 0 ?
                                                                <div className="">
                                                                  <div className="row">
                                                                    <div className="col-md-12" id="head">

                                                                      <hr></hr>
                                                                    </div>
                                                                  </div>

                                                                  <table class="table table-bordered account-table kyc-table">
                                                                    <tbody>
                                                                      <tr>
                                                                        <th>Document Name</th>
                                                                        <th>Validity</th>
                                                                      </tr>
                                                                      {x.kyc_docs.map((x, i) =>

                                                                        <tr>
                                                                          <td>
                                                                            {x.document_name}
                                                                          </td>

                                                                          <td>
                                                                            {x.validity}
                                                                          </td>
                                                                        </tr>
                                                                      )}
                                                                    </tbody>
                                                                  </table>

                                                                </div>
                                                                : <></>
                                                              }
                                                              {/* <span id="links" onClick={handleAddDocument}>Add Document</span> */}

                                                            </AccordionItemPanel>
                                                          </AccordionItem>
                                                          <AccordionItem uuid="c">
                                                            <AccordionItemHeading>
                                                              <AccordionItemButton>
                                                                Rwood Reference
                                                              </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                              <table class="table table-bordered account-table tables">
                                                                <tbody>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>KYC Docs</span>
                                                                    </td>
                                                                    <td>{x.account_details.KYC_docs}</td>
                                                                    <td id="td-right">
                                                                      <span>Commitment</span>
                                                                    </td>
                                                                    <td>{x.account_details.commitment}</td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>KYC doc date</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.KYC_doc_date}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span>Reliability</span>
                                                                    </td>
                                                                    <td>{x.account_details.reliability}</td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Reference Check</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.refrence_check}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Refrence check date</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.refrence_check_date}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </AccordionItemPanel>
                                                          </AccordionItem>
                                                          <AccordionItem uuid="d">
                                                            <AccordionItemHeading>
                                                              <AccordionItemButton>
                                                                Industry Information
                                                              </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                              <table class="table table-bordered account-table tables">
                                                                <tbody>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>parent account</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.parent_account}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span>
                                                                        Annual coal requirement in MT
                                                                      </span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .annual_coal_requirement_in_MT
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Under Group</span>
                                                                    </td>
                                                                    <td>{x.account_details.under_group}</td>
                                                                    <td id="td-right">
                                                                      <span>imported volume PA in MT</span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .imported_volume_PA_in_MT
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>station name</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.station_name}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span>quantity MT monthly</span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .quantity_MT_monthly
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>
                                                                        Expansion Setup / Buyer Status
                                                                      </span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .expansion_setup_or_buyer_status
                                                                      }
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span>Production capacity</span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .production_capacity
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                    <td id="td-right">
                                                                      <span>originiaze import breakup</span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .originiaze_import_breakup
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Industry</span>
                                                                    </td>
                                                                    <td>{x.account_details.industry}</td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>{" "}
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Sector</span>
                                                                    </td>
                                                                    <td>{x.account_details.sector}</td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Market impression rating</span>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        x.account_details
                                                                          .market_impression_rating
                                                                      }
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </AccordionItemPanel>
                                                          </AccordionItem>
                                                          <AccordionItem uuid="e">
                                                            <AccordionItemHeading>
                                                              <AccordionItemButton>
                                                                System Information
                                                              </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                              <table class="table table-bordered account-table tables">
                                                                <tbody>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>Account source</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.account_source}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span>Rating</span>
                                                                    </td>
                                                                    <td>{x.account_details.rating}</td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </AccordionItemPanel>
                                                          </AccordionItem>
                                                          <AccordionItem
                                                            uuid="f"
                                                            expanded={() => this.setIsCollapse("f")}
                                                          >
                                                            <AccordionItemHeading>
                                                              <AccordionItemButton
                                                                onClick={(value) =>
                                                                  this.onChangeAccordion(value)
                                                                }
                                                              >
                                                                Account Summary
                                                              </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                              <table class="table table-bordered account-table tables">
                                                                <tbody>
                                                                  <tr>
                                                                    <td id="td-right">
                                                                      <span>general terms</span>
                                                                    </td>
                                                                    <td>
                                                                      {x.account_details.general_terms}
                                                                    </td>
                                                                    <td id="td-right">
                                                                      <span></span>
                                                                    </td>
                                                                    <td></td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </AccordionItemPanel>
                                                          </AccordionItem>
                                                        </Accordion>
                                                        <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contact Roles</span>
                                      <span>
                                        <i className=""></i>
                                        New
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contacts</span>
                                      <span onClick={contactHandle}>

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="contact" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Contact code</th>
                                        <th>Contact Name	</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Phone</th>
                                        <th>Title</th>
                                        <th>Reset Birthday Email System	</th>
                                        <th>Receive Birthday Emails</th>
                                      </tr>
                                    </thead>
                                    {x.contacts.length > 0 ? (
                                      <tbody>
                                        {x.contacts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={"/contact/contact-details" + "/" + y.id}
                                                >
                                                  {y.contact_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/contact/contact-details" + "/" + y.contact_id}
                                              >
                                                {y.contact_full_name}
                                              </Link>
                                            </td>
                                            <td>{y.contact_email}</td>
                                            <td>{y.contact_mobile}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Quotes</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="quotes" className="">
                                    <thead>
                                      <tr>
                                        <th>Quote Name</th>
                                        <th>Opportunity Name</th>
                                        <th>Syncing</th>
                                        <th>Expiration Date </th>
                                        <th>Subtotal</th>
                                        <th>Total Price
                                        </th>
                                      </tr>
                                    </thead>

                                    {x.quotes.length > 0 ? (
                                      <tbody>
                                        {x.quotes.map((y, i) => (
                                          <tr>
                                            <td>
                                              <Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_name}</Link>
                                            </td>
                                            <td><Link to={`/opportunity/opportunity-detail/${y.opportunity_id}`}>{y.opportunity_name}</Link></td>
                                           <td>{y.syncing}</td>
                                           <td>{y.expiration_date}</td>
                                           <td>{y.subtotal}</td>
                                           <td>{y.total_price}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contract Information (Supplier)</span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmitcontract}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>Account Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option value="">--Select record type--</option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                                <option value="Supplier">
                                                  Supplier
                                                </option>
                                              </select>
                                              {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="contractSupplier" className="">
                                    <thead>
                                      <tr>
                                        <th>contract number</th>
                                        <th>rwood contract number</th>
                                        <th>Account Name  </th>
                                        <th>Status </th>
                                        <th>Contract Quantity (MT)  </th>
                                        <th>Price PMT  </th>
                                      </tr>
                                    </thead>

                                    {x.contracts.length > 0 ? (
                                      <tbody>
                                        {x.contracts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_no}
                                                </Link>
                                              </span>
                                            </td><td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_number}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td>{y.status}</td>
                                            <td>{y.contract_quantity_in_MT}</td>
                                            <td>{y.price_PMT}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>

                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contracts (Surveyour 3)
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmitcontract}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>Account Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option value="">--Select record type--</option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                                <option value="Supplier">
                                                  Supplier
                                                </option>
                                              </select>
                                              {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="contractSupplier" className="">
                                    <thead>
                                      <tr>
                                        <th>contract number</th>
                                        <th>rwood contract number</th>
                                        <th>Account Name  </th>
                                        <th>Status </th>
                                        <th>Contract Quantity (MT)  </th>
                                        <th>Price PMT  </th>
                                      </tr>
                                    </thead>

                                    {x.contracts.length > 0 ? (
                                      <tbody>
                                        {x.contracts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_no}
                                                </Link>
                                              </span>
                                            </td><td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_number}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td>{y.status}</td>
                                            <td>{y.contract_quantity_in_MT}</td>
                                            <td>{y.price_PMT}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>

                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contracts (Surveyour 2)
                                      </span>
                                      <span>
                                        <Popup
                                          trigger={
                                            <a className="button add-accountrecord">
                                              {" "}
                                              New{" "}
                                            </a>
                                          }
                                          modal
                                        >
                                          <form
                                            onSubmit={handleSubmitcontract}
                                            className="add-accounts"
                                          >
                                            <div className="form-group">
                                              <label>Account Record Type</label>

                                              <select
                                                name="account_record"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.account_record}
                                              >
                                                <option value="">--Select record type--</option>
                                                <option value="Buyer - Long Term">
                                                  Buyer - Long Term
                                                </option>
                                                <option value="Buyer - Spot">
                                                  Buyer - Spot
                                                </option>
                                                <option value="Supplier - Long Term">
                                                  Supplier - Long Term
                                                </option>
                                                <option value="Supplier">
                                                  Supplier
                                                </option>
                                              </select>
                                              {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                            </div>
                                            <input
                                              type="submit"
                                              className="btn btn-primary "
                                              value="Submit"
                                            />
                                          </form>
                                        </Popup>
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="contractSupplier" className="">
                                    <thead>
                                      <tr>
                                        <th>contract number</th>
                                        <th>rwood contract number</th>
                                        <th>Account Name  </th>
                                        <th>Status </th>
                                        <th>Contract Quantity (MT)  </th>
                                        <th>Price PMT  </th>
                                      </tr>
                                    </thead>

                                    {x.contracts.length > 0 ? (
                                      <tbody>
                                        {x.contracts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_no}
                                                </Link>
                                              </span>
                                            </td><td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/contract/contract-details/" + y.id
                                                  }
                                                >
                                                  {y.rwood_contract_number}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td>{y.status}</td>
                                            <td>{y.contract_quantity_in_MT}</td>
                                            <td>{y.price_PMT}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>

                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
     <div
       className="col-md-12"
       id="head"
     >
       <h4 className="heading">
         <span>Orders (Surveyour Agent)

         </span>
       </h4>
       <hr></hr>
     </div>
     <table id="order-supplier" className="">
       <thead>
         <tr>
           <th>Order Number</th>
           <th>Status</th>
           <th>Vessel Name  </th>
           <th>Account Name </th>
           <th>Supplier </th>
           <th>BL Date    </th>
           <th>Total BL Qty
           </th>
         </tr>
       </thead>

       {x.orders.length > 0 ? (
         <tbody>
           {x.orders.map((y, i) => (
             <tr>
               <td>
                 <span id="codes">
                   <Link
                     to={
                       "/order/view-order-details/" +
                       y.id
                     }
                   >
                     {y.order_no}
                                 </Link>
                 </span>
               </td>
               <td>{y.status}</td>
               <td>{y.vessel_name}</td>
               <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
               <td>{y.bl_date}</td>
               <td>
                 {y.total_bl_qty}
               </td>

             </tr>
           ))}
         </tbody>
       ) : (
         <tbody>
           <tr>
           <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody>
       )}
     </table>
   </Row>
   <Row id="table-styles">
     <div
       className="col-md-12"
       id="head"
     >
       <h4 className="heading">
         <span>Orders (Surveyour Agent 1)

         </span>
       </h4>
       <hr></hr>
     </div>
     <table id="order-surveyour" className="">
       <thead>
         <tr>
           <th>Order Number</th>
           <th>Status</th>
           <th>Vessel Name  </th>
           <th>Account Name </th>
           <th>Supplier </th>
           <th>BL Date    </th>
           <th>Total BL Qty
           </th>
         </tr>
       </thead>

       {x.orders.length > 0 ? (
         <tbody>
           {x.orders.map((y, i) => (
             <tr>
               <td>
                 <span id="codes">
                   <Link
                     to={
                       "/order/view-order-details/" +
                       y.id
                     }
                   >
                     {y.order_no}
                                 </Link>
                 </span>
               </td>
               <td>{y.status}</td>
               <td>{y.vessel_name}</td>
               <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
               <td>{y.bl_date}</td>
               <td>
                 {y.total_bl_qty}
               </td>

             </tr>
           ))}
         </tbody>
       ) : (
         <tbody>
           <tr>
           <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody>
       )}
     </table>
   </Row>

   <Row id="table-styles">
     <div
       className="col-md-12"
       id="head"
     >
       <h4 className="heading">
         <span>Orders (Surveyour Agent 2)

         </span>
       </h4>
       <hr></hr>
     </div>
     <table id="order-surveyour-1" className="">
       <thead>
         <tr>
           <th>Order Number</th>
           <th>Status</th>
           <th>Vessel Name  </th>
           <th>Account Name </th>
           <th>Supplier </th>
           <th>BL Date    </th>
           <th>Total BL Qty
           </th>
         </tr>
       </thead>

       {x.orders.length > 0 ? (
         <tbody>
           {x.orders.map((y, i) => (
             <tr>
               <td>
                 <span id="codes">
                   <Link
                     to={
                       "/order/view-order-details/" +
                       y.id
                     }
                   >
                     {y.order_no}
                                 </Link>
                 </span>
               </td>
               <td>{y.status}</td>
               <td>{y.vessel_name}</td>
               <td>
                                              <Link
                                                to={"/accounts" + "/" + y.account_id}
                                              >
                                                {y.account_name}
                                              </Link>
                                            </td>
                                            <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
               <td>{y.bl_date}</td>
               <td>
                 {y.total_bl_qty}
               </td>

             </tr>
           ))}
         </tbody>
       ) : (
         <tbody>
           <tr>
           <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody>
       )}
     </table>
   </Row>
   <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history-activity" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes & Attachments</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Attach File
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Files</span>
                                      <span>
                                        <i className=""></i>
                                        Upload Files
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">No records to display</p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>RW Payments/Receipts</span>
                                      <span>
                                        <i className=""></i>
                                        New RW Payment/Receipt
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="receipt" className="">
                                    <thead>
                                      <tr>
                                        <th>RW Payment/Receipt	</th>
                                        <th>Record Type	 </th>
                                        <th>Payment/Receipt Date	 </th>
                                        <th>Status</th>
                                        <th>Total Amount	</th>
                                        <th>Rwood Bank Master	 </th>
                                        <th>Mode Of Payment	</th>
                                        <th>Description </th>
                                      </tr>
                                    </thead>

                                    {x.all_payable_receipts_lineitem.length > 0 ? (
                                      <tbody>
                                        {x.all_payable_receipts_lineitem.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">

                                              <Link to={"/account/receipt-lineitem/" + y.rw_pay_receipt_detail_id}>
                                                  {y.rw_payment_receipt}
                                                  </Link>
                                              </span>
                                            </td>
                                            <td>
                                              {y.record_type}
                                            </td>
                                            <td>{y.payment_receipt_date}</td>
                                            <td>{y.status}</td>
                                            <td>{y.amount}</td>
                                            <td>{y.rwood_bank_master}</td>
                                            <td>{y.mode_of_payment}</td>
                                            <td>{y.description}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td><td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>

                                </Row>

<Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Account History</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="historys" className="">
                                    <thead>
                                      <tr>
                                        <th>Field Name</th>
                                        <th>New Value</th>
                                        <th>Old Value</th>
                                        <th>Changed By</th>
                                        <th>Change Time</th>
                                      </tr>
                                    </thead>

                                    {x.history.length > 0 ? (
                                      <tbody>
                                        {x.history.map((y, i) => (
                                          <tr>
                                            <td>{y.field_name}</td>
                                            <td>{y.new_value}</td>
                                            <td>{y.old_value}</td>
                                            <td>{y.changed_by}</td>
                                            <td>{y.change_time}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>

                                                      </div>
                                                    </div>


                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <div className="contactAccount">
                                                  <div className="tasks">
                                                    <div className="edit-delete">
                                                      <span
                                                        id="edit"
                                                        onClick={handleEditClick}
                                                      >
                                                        Edit
                                                      </span>
                                                      <span>
                                                        <Link
                                                          to={"/accounts/account-list"}
                                                        >
                                                          {" "}
                                                          Cancel
                                                        </Link>
                                                      </span>
                                                    </div>
                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            Account Information
                                                          </h4>

                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Account Code</span>{" "}
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_code
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Account Name</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_name
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Account Owner</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_owner
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>
                                                                Account alias{" "}
                                                                <OverlayTrigger
                                                                  placement="right"
                                                                  delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                  }}
                                                                  overlay={
                                                                    renderTooltip
                                                                  }
                                                                >
                                                                  <Button
                                                                    variant="success"
                                                                    className="tooltips"
                                                                  >
                                                                    <IoIosHelpCircle id="help" />
                                                                  </Button>
                                                                </OverlayTrigger>{" "}
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_alias
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>
                                                                Account record type
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_record_type
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>
                                                                Account Number
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .account_number
                                                              }
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Website</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .website
                                                              }
                                                            </td>

                                                            <td id="td-right">
                                                              <span>Port</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.port}
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Origin</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.origin}
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Product</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .product
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Remarks</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .remarks
                                                              }
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            Address Information
                                                          </h4>
                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Region</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.region}
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Phone</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.phone}
                                                            </td>
                                                          </tr>

                                                          <tr>

                                                            <td id="td-right">
                                                              <span>

                                                              </span>
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td id="td-right">
                                                              <span>Fax</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.fax}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>billing Address</span><br />



                                                            </td>
                                                            <td>
                                                              <p className="bill-group"><span> {x.account_details.billing_street}</span><br />
                                                                <span> {x.account_details.billing_postal_code}</span><br />
                                                                <span> {x.account_details.billing_city}</span><br />
                                                                <span>  {x.account_details.billing_state_or_province}</span><br />
                                                                <span> {x.account_details.billing_country}</span><br />
                                                              </p>
                                                            </td>
                                                            <td id="td-right">
                                                              <span>shipping Address</span>{" "}
                                                            </td>
                                                            <td>
                                                              <p className="ship-group"><span>{x.account_details.shipping_street}</span><br />
                                                                <span>{x.account_details.shipping_postal_code}</span><br />
                                                                <span>{x.account_details.shipping_city}</span><br />
                                                                <span> {x.account_details.shipping_state_or_province}</span><br />
                                                                <span> {x.account_details.shipping_country}</span><br />
                                                              </p>
                                                            </td>

                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    {x.kyc_docs.length > 0 ? (
                                                      <div className="">
                                                        <div className="row">
                                                          <div
                                                            className="col-md-12"
                                                            id="head"
                                                          >
                                                            <h4 className="heading">
                                                              KYC Documents
                                                            </h4>
                                                            <hr></hr>
                                                          </div>
                                                        </div>

                                                        <table class="table table-bordered account-table kyc-table">
                                                          <tbody>
                                                            <tr>
                                                              <th>Document Name</th>
                                                              <th>Validity</th>
                                                            </tr>
                                                            {x.kyc_docs.map((x, i) => (
                                                              <tr>
                                                                <td>
                                                                  {x.document_name}
                                                                </td>

                                                                <td>{x.validity}</td>
                                                              </tr>
                                                            ))}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            Rwood Reference
                                                          </h4>
                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>KYC Docs</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .KYC_docs
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>KYC doc date</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .KYC_doc_date
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Commitment</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .commitment
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Reliability</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .reliability
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>
                                                                Reference Check
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .refrence_check
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>
                                                                Refrence check date
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .refrence_check
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            Industry Information
                                                          </h4>
                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>
                                                                Parent Account
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .parent_account
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Under Gourp</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .under_group
                                                              }
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td id="td-right">
                                                              <span>
                                                                Annual coal requirement
                                                                in MT
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .annual_coal_requirement_in_MT
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Station name</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .station_name
                                                              }
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td id="td-right">
                                                              <span>
                                                                Imported Volume PA in MT
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .imported_volume_PA_in_MT
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Industry</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .industry
                                                              }
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td id="td-right">
                                                              <span>
                                                                Production capacity
                                                              </span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .production_capacity
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Sector</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.sector}
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Origin</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.origin}
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>

                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            System Information
                                                          </h4>
                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>Rating</span>
                                                            </td>
                                                            <td>
                                                              {x.account_details.rating}
                                                            </td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="">
                                                      <div className="row">
                                                        <div
                                                          className="col-md-12"
                                                          id="head"
                                                        >
                                                          <h4 className="heading">
                                                            Account Summary
                                                          </h4>
                                                          <hr></hr>
                                                        </div>
                                                      </div>
                                                      <table class="table table-bordered account-table tables">
                                                        <tbody>
                                                          <tr>
                                                            <td id="td-right">
                                                              <span>General terms</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .general_terms
                                                              }
                                                            </td>
                                                            <td id="td-right">
                                                              <span>Payment Terms</span>
                                                            </td>
                                                            <td>
                                                              {
                                                                x.account_details
                                                                  .payment_terms
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className="tasks view-table"
                                                  Style="box-showdow:0px 13px 20px #d6d1d1"
                                                >
                                                  <Row id="table-styles">
                                                    <div className="col-md-12 counter" id="head">
                                                      <h4 className="heading">
                                                        <span>Counter Party Information</span>
                                                        <span><Link to={"/account/counter-party/" + id}>
                                                          <i className=""></i>
                                                          New
                                                        </Link>
                                                        </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="counter" className="">
                                                      <thead>
                                                        <tr>

                                                          <th>Origin</th>

                                                          <th>Name</th>

                                                          <th>GAR</th>

                                                          <th>TM</th>
                                                          <th>ASH</th>
                                                          <th>TS</th>
                                                        </tr>
                                                      </thead>

                                                      {x.counter_party.length > 0 ? (
                                                        <tbody>
                                                          {x.counter_party.map((y, i) => (
                                                            <tr>
                                                              <td>{y.origin}</td>
                                                              <td>{y.name}</td>

                                                              <td>{y.gar}</td>
                                                              <td>{y.tm}</td>
                                                              <td>{y.ash}</td>
                                                              <td>{y.ts}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>       <Row id="table-styles">
                                                    <div className="col-md-12" id="head">
                                                      <h4 className="heading">KYC Documents</h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="kyc" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Document Name</th>
                                                          <th>Validity</th>
                                                        </tr>
                                                      </thead>

                                                      {x.kyc_docs.length > 0 ? (
                                                        <tbody>
                                                          {x.kyc_docs.map((y, i) => (
                                                            <tr>
                                                              <td>{y.document_name}</td>
                                                              <td>{y.validity}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td>No data available</td>

                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>
                                                  <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>History Information</span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="history" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Field Name</th>
                                                          <th>New Value</th>
                                                          <th>Old Value</th>
                                                          <th>Changed By</th>
                                                          <th>Change Time</th>
                                                        </tr>
                                                      </thead>

                                                      {x.history.length > 0 ? (
                                                        <tbody>
                                                          {x.history.map((y, i) => (
                                                            <tr>
                                                              <td>{y.field_name}</td>
                                                              <td>{y.new_value}</td>
                                                              <td>{y.old_value}</td>
                                                              <td>{y.changed_by}</td>
                                                              <td>{y.change_time}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>

                                                  <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contacts</span>
                                      <span onClick={contactHandle}>

                                        <i className=""></i>
                                        New

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  {/* <p id='inner-heading'><span><Link to={'/contact/contact-by-account/'+id}><i className=""></i><FaPlus/> Add Contact By Account</Link></span></p> */}
                                  <table id="contact" className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Contact code</th>
                                        <th>Contact Name	</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Phone</th>
                                        <th>Title</th>
                                        <th>Reset Birthday Email System	</th>
                                        <th>Receive Birthday Emails</th>
                                      </tr>
                                    </thead>
                                    {x.contacts.length > 0 ? (
                                      <tbody>
                                        {x.contacts.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={"/contact/contact-details" + "/" + y.id}
                                                >
                                                  {y.contact_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>
                                              <Link
                                                to={"/contact/contact-details" + "/" + y.contract_id}
                                              >
                                                {y.contact_full_name}
                                              </Link>
                                            </td>
                                            <td>{y.contact_email}</td>
                                            <td>{y.contact_mobile}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                    <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>
                                                          Opportunity Information
                                                        </span>
                                                        <span>
                                                          <Popup
                                                            trigger={
                                                              <a className="button add-accountrecord">
                                                                {" "}
                                                                New{" "}
                                                              </a>
                                                            }
                                                            modal
                                                          >
                                                            <form
                                                              onSubmit={handleSubmit}
                                                              className="add-accounts"
                                                            >
                                                              <div className="form-group">
                                                                <label>
                                                                  Opportunity Record
                                                                  Type
                                                                </label>

                                                                <select
                                                                  name="account_record"
                                                                  className="form-control"
                                                                  onChange={
                                                                    handleChange
                                                                  }
                                                                  value={
                                                                    formData.account_record
                                                                  }
                                                                >
                                                                  <option>
                                                                    --Select Opportunity
                                                                    Record Type--
                                                                  </option>

                                                                  <option value="Buyer - Long Term">
                                                                    Buyer - Long Term
                                                                  </option>
                                                                  <option value="Buyer - Spot">
                                                                    Buyer - Spot
                                                                  </option>
                                                                  <option value="Supplier - Spot">
                                                                    Supplier - Spot
                                                                  </option>
                                                                  <option value="Supplier - Long Term">
                                                                    Supplier - Long Term
                                                                  </option>
                                                                </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                                              </div>
                                                              <input
                                                                type="submit"
                                                                className="btn btn-primary "
                                                                value="Submit"
                                                              />
                                                            </form>
                                                          </Popup>
                                                        </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <div id="inner-heading"></div>
                                                    <table id="opp" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Opportunity no</th>
                                                          <th>Stage</th>
                                                          <th>Quantity</th>
                                                          <th>Laycan From	</th>
                                                          <th>Laycan To	</th>
                                                          <th>Price	</th>
                                                          <th>Trader Name	</th>
                                                          <th>Supplier</th>
                                                          <th>Vessel Type
                                                          </th>
                                                        </tr>
                                                      </thead>

                                                      {x.opportunity.length > 0 ? (
                                                        <tbody>
                                                          {x.opportunity.map((y, i) => (
                                                            <tr>
                                                              <td>
                                                                <span id="codes">
                                                                  <Link
                                                                    to={
                                                                      "/opportunity/opportunity-detail" +
                                                                      "/" +
                                                                      y.id
                                                                    }
                                                                  >
                                                                    {y.opportunity_no}
                                                                  </Link>
                                                                </span>
                                                              </td>
                                                              <td>

                                                                {y.stage}
                                                              </td>
                                                              <td>
                                                                {y.quantity}
                                                              </td>
                                                              <td>{y.laycan_from}</td>
                                                              <td>{y.laycan_to}</td>
                                                              <td>{y.price}</td>
                                                              <td><Link  to={"/user/user-details/" +y.user_id} >{y.trader_name}</Link></td>
                                                              <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                                              <td>{y.vessel_type}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr><td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td><td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td><td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>

                                                  <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>Quotes </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="quotes" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Quote Number</th>
                                                          <th>Account Name</th>
                                                          <th>Quote Name</th>
                                                          <th>Opportunity Name</th>
                                                          <th>Currency</th>
                                                          <th>Supplier</th>
                                                        </tr>
                                                      </thead>

                                                      {x.quotes.length > 0 ? (
                                                        <tbody>
                                                          {x.quotes.map((y, i) => (
                                                            <tr>
                                                              <td>
                                                                <span id="codes">
                                                                  <Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_number}</Link>
                                                                </span>
                                                              </td>
                                                              <td><Link
                                                                to={
                                                                  "/accounts/" +

                                                                  y.account_id
                                                                }
                                                              >
                                                                {y.account_name}
                                                              </Link></td>
                                                              <td>{y.quote_name}</td>
                                                              <td>
                                                                <Link to={`/opportunity/opportunity-detail/${y.opportunity_id}`}>{y.opportunity_name}</Link>
                                                              </td>
                                                              <td>{y.currency}</td>
                                                              <td><Link  to={"/accounts/" +y.supplier_id} >{y.supplier}</Link></td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>
                                                  <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>
                                                          Trade Confirmation Form
                                                        </span>
                                                        <span>
                                                          <Popup
                                                            trigger={
                                                              <a className="button add-accountrecord">
                                                                {" "}
                                                                New{" "}
                                                              </a>
                                                            }
                                                            modal
                                                          >
                                                            <form
                                                              onSubmit={handleSubmittcf}
                                                              className="add-accounts"
                                                            >
                                                              <div className="form-group">
                                                                <label>
                                                                  TCF Record Type
                                                                </label>

                                                                <select
                                                                  name="account_record"
                                                                  className="form-control"
                                                                  onChange={
                                                                    handleChange
                                                                  }
                                                                  value={
                                                                    formData.account_record
                                                                  }
                                                                >
                                                                  <option>
                                                                    --Select TCF Record
                                                                    Type--
                                                                  </option>
                                                                  <option value="Buyer - Spot">
                                                                    Buyer - Spot
                                                                  </option>
                                                                  <option value="Buyer - Long Term">
                                                                    Buyer - Long Term
                                                                  </option>
                                                                </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                                              </div>
                                                              <input
                                                                type="submit"
                                                                className="btn btn-primary "
                                                                value="Submit"
                                                              />
                                                            </form>
                                                          </Popup>
                                                        </span>
                                                      </h4>
                                                      <div id="inner-heading"></div>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="tcf" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>TCF No</th>
                                                          <th>Company</th>
                                                          <th>Opportunity</th>
                                                          <th>Quote</th>
                                                          <th>Record Type</th>
                                                          <th>Currency</th>
                                                          <th>
                                                            Purchase Confirmation Date
                                                          </th>
                                                        </tr>
                                                      </thead>

                                                      {x.tcf.length > 0 ? (
                                                        <tbody>
                                                          {x.tcf.map((x, i) => (
                                                            <tr>
                                                              <td>
                                                                <Link
                                                                  to={
                                                                    "/tcf/tcf-details/" +
                                                                    x.id
                                                                  }
                                                                >
                                                                  {x.tcf_no}
                                                                </Link>
                                                              </td>
                                                              <td><Link to={`/company/company-details/${x.rwoodcompany_id}`}>{x.company}</Link></td>
                                                              <td><Link to={`/opportunity/opportunity-detail/${x.opportunity_id}`}>{x.opportunity}</Link></td>
                                                              <td>{x.quote}</td>
                                                              <td>{x.record_type}</td>
                                                              <td>{x.currency}</td>
                                                              <td>
                                                                {
                                                                  x.purchase_confirmation_date
                                                                }
                                                              </td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>
                                                  <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>
                                                          Contract Information
                                                        </span>
                                                        <span>
                                                          <Popup
                                                            trigger={
                                                              <a className="button add-accountrecord">
                                                                {" "}
                                                                New{" "}
                                                              </a>
                                                            }
                                                            modal
                                                          >
                                                            <form
                                                              onSubmit={
                                                                handleSubmitcontract
                                                              }
                                                              className="add-accounts"
                                                            >
                                                              <div className="form-group">
                                                                <label>
                                                                  Account Record Type
                                                                </label>

                                                                <select
                                                                  name="account_record"
                                                                  className="form-control"
                                                                  onChange={
                                                                    handleChange
                                                                  }
                                                                  value={
                                                                    formData.account_record
                                                                  }
                                                                >

                                                                  <option value="Buyer Long Term">
                                                                    Buyer Long Term
                                                                  </option>
                                                                  <option value="Contract Buyer Spot">
                                                                    Contract Buyer Spot
                                                                  </option>
                                                                  <option value="Contract Supplier Long Term">
                                                                    Contract Supplier
                                                                    Long Term
                                                                  </option>
                                                                  <option value="Contract Supplier">
                                                                    Contract Supplier
                                                                  </option>
                                                                </select> {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                                              </div>
                                                              <input
                                                                type="submit"
                                                                className="btn btn-primary "
                                                                value="Submit"
                                                              />
                                                            </form>
                                                          </Popup>
                                                        </span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="contract" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Rwood contract number</th>
                                                          <th>Account name</th>
                                                          <th>Company</th>
                                                          <th>Contract record type</th>
                                                          <th>Trader name</th>
                                                          <th>Renewal date</th>
                                                        </tr>
                                                      </thead>

                                                      {x.contracts.length > 0 ? (
                                                        <tbody>
                                                          {x.contracts.map((y, i) => (
                                                            <tr>
                                                              <td>
                                                                <span id="codes">
                                                                  <Link
                                                                    to={
                                                                      "/contract/contract-details/" +
                                                                      y.id
                                                                    }
                                                                  >
                                                                    {
                                                                      y.rwood_contract_number
                                                                    }
                                                                  </Link>
                                                                </span>
                                                              </td>
                                                              <td>
                                                                <Link
                                                                  to={
                                                                    "/accounts/" +

                                                                    y.account_id
                                                                  }
                                                                >
                                                                  {y.account_name}
                                                                </Link>
                                                              </td>
                                                              <td>{y.company}</td>
                                                              <td>
                                                                {y.contract_record_type}
                                                              </td>
                                                              <td><Link  to={"/user/user-details/" +y.user_id} >{y.trader_name}</Link></td>
                                                              <td>{y.renewal_date}</td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>
                                                  <Row id="table-styles">
                                                    <div
                                                      className="col-md-12"
                                                      id="head"
                                                    >
                                                      <h4 className="heading">
                                                        <span>Orders Information</span>
                                                      </h4>
                                                      <hr></hr>
                                                    </div>
                                                    <table id="orders" className="">
                                                      <thead>
                                                        <tr>
                                                          <th>Order Code No</th>
                                                          <th>Contract Number	</th>
                                                          <th>Vessel Name	</th>
                                                          <th>BL Date	</th>
                                                          <th>Shipment Qty	</th>
                                                          <th>Actual Payment Received Date	</th>
                                                          <th>Document Discount Date	</th>
                                                          <th>Supplier Invoice Amount
                                                          </th>
                                                        </tr>
                                                      </thead>

                                                      {x.orders.length > 0 ? (
                                                        <tbody>
                                                          {x.orders.map((y, i) => (
                                                            <tr>
                                                              <td>
                                                                <span id="codes">
                                                                  <Link
                                                                    to={
                                                                      "/order/view-order-details/" +
                                                                      y.id
                                                                    }
                                                                  >
                                                                    {y.order_no}
                                                                                </Link>
                                                                </span>
                                                              </td>
                                                              <td><Link
                                                                    to={
                                                                      "/contract/contract-details/" +y.contract_id
                                                                    }
                                                                  >{y.contract_number}</Link></td>
                                                              <td>{y.vessel_name}</td>
                                                              <td>{y.bl_date}</td>
                                                              <td>
                                                                {y.shipment_qty}
                                                              </td>
                                                              <td>
                                                              {y.all_actual_payment_recieved_date}
                                                              </td>
                                                              <td>{y.all_document_discount_date}</td>
                                                              <td></td>
                                                            </tr>
                                                          ))}
                                                        </tbody>
                                                      ) : (
                                                        <tbody>
                                                          <tr>
                                                            <td></td><td></td><td></td>
                                                            <td></td>
                                                            <td>No data available</td>
                                                            <td></td>
                                                            <td></td><td></td>
                                                          </tr>
                                                        </tbody>
                                                      )}
                                                    </table>
                                                  </Row>

                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            ) : (
              <div className="tasks">
                <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading" /></h1>
              </div>
            )}
          </>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountViewPage;
