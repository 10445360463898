import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
export default class Footer extends Component {
  render() {
    return (
      <>
      <div className='footer bottom-footer'>
        <p className="text-center">© 2023 <span>Rwood</span> Resource. All Rights Reserved</p>
        </div>
      </>
    );
  }
}
