import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,

  OverlayTrigger,

  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from '../Header'
import { API_URL } from '../config';
import Footer from '../Footer'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaEnvelope,FaPhoneSquareAlt,FaMobileAlt,FaFax } from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams  } from "react-router-dom";
import $ from 'jquery'
const ViewUser = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const params = useParams()
  const id=params.id
  // console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState({});
  const [history, setHistory] = useState([]);
  // const userRole = localStorage.getItem("role");


  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/rwood_usersbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwood_users_details;
      const history = response.data.history;
      console.log(data)
      setHistory(history)
      setDatas(data);
      setTimeout(() => {
          $("#history").dataTable();
          $("#tcf").dataTable();
          $("#quote").dataTable();
          $("#opp").dataTable();
          $("#contract").dataTable();
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };
  if (!datas) {
    return null;
  }
  return (

    <div id='view-page'>
    <Header/>


             <div className="contactAccount">

     <div className="page-header btn-margin" id='add-account'>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">User Details</h2>
       <ol className="breadcrumb">
         <li className="breadcrumb-item active" aria-current="page">Dashboard / User details</li>
       </ol>
     </div>

       </div>
       <div className='tasks'>
       <div className="edit-delete">
        <span id="edit" > Edit </span>
        <span>
          <Link to={`/user/userlist`}> Cancel</Link>
        </span>
      </div>
   <div className=''>
   <div className='row'>
     <div className="col-md-12" id='adatas'>
     <h4 className="heading"> Rwood User Detail</h4>
     <hr></hr>
     </div>
   </div>
   {datas.length > 0 ? (
<>
    {datas.map((x)=>(
      <>
      <table class="table table-bordered account-table tables">
      <tbody>
        <tr>
          <td id="td-right"><span>Rwood User Name No</span> </td>
          <td>{x.rwood_username_no}</td>
          <td id="td-right"><span>Owner</span></td>
          <td>{x.created_by}
          </td>

        </tr>
        <tr>
        <td id="td-right">
            <span>
            Rwood User Type{" "}

            </span>
            </td><td>
            {x.rwood_user_type}
          </td>
        <td id="td-right">
          </td>
          <td>
          </td>

        </tr>
        <tr>
        <td id="td-right">
            <span>Rwood Username Number</span>
          </td>
          <td>
            {x.rwood_username_no}
          </td>
          <td id="td-right">
          </td>
          <td>
          </td>
        </tr>
        <tr>

        <td id="td-right">
            <span>Active</span>
            </td>
            <td>
             {x.active}
          </td>
          <td id="td-right">
            </td>
            <td>
          </td>
        </tr>
        <tr>


            <td id="td-right">
              <span>Created At</span>
              </td>
              <td>
               {x.created_at}
            </td>


        <td id="td-right">
            <span>Last Modified By
            </span>
            </td>
            <td>

            {x.updated_at}
          </td>
          </tr>


      </tbody>
    </table>
    <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Opportunities</span></h4>
                    <hr></hr>
                  </div>
                  <table id="opp" className="">
                    <thead>
                      <tr>
                      <th>Opportunity Name	</th>
                      <th>Opportunity Record Type	</th>
                      <th>Stage</th>
                      <th>Account Name	</th>
                      <th>Destination	</th>
                      <th>No of Order	</th>
                      <th>Quantity (MT)	</th>
                      <th>Inquiry Date	</th>
                      <th>Close Date
                      </th>
                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Orders</span>
         <span>
           <i className=""></i> New Order </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Trade Confirmation Forms
                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf" className="">
                    <thead>
                      <tr>
                      <th>TCF No	</th>
                      <th>Buyer	</th>
                      <th>Supplier</th>
                      <th>Quantity (MT)		</th>
                      <th>Contractual GAR (P)		</th>
                      <th>Actual GAR (P)		</th>
                      <th>Actual GAR (S)
                      </th>

                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Open Activities</span>
           <span>
             <i className=""></i> New Task </span>
           <span>
             <i className=""></i> New Event </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Activity History</span>
           <span>
             <i className=""></i> Log a Call </span>
           <span>
             <i className=""></i> Mail merge </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>

   <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Quotes</span></h4>
                    <hr></hr>
                  </div>
                  <table id="quote" className="">
                    <thead>
                      <tr>
                      <th>Quote Name	</th>
                      <th>Opportunity Name		</th>
                      <th>Syncing	</th>
                      <th>Expiration Date	</th>
                      <th>Subtotal</th>
                      <th>Total Price
                      </th>


                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>

                        </tr>
                      </tbody>
                  </table>
                </Row>

   <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Contracts</span></h4>
                    <hr></hr>
                  </div>
                  <table id="contract" className="">
                    <thead>
                      <tr>
                      <th>Contract Number	</th>
                      <th>Rwood Contract No	</th>
                      <th>Account Name		</th>
                      <th>Status</th>
                      <th>Contract Quantity (MT)
                      </th>



                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>

                        </tr>
                      </tbody>
                  </table>
                </Row>
    <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
                </>
    )
    )}
    </>
   ):(
    <></>
  )}
   {/* <table class="table table-bordered account-table tables">
                      <tbody>
                        <tr>
                          <td id="td-right"><span>Account Code</span> </td>
                          <td>{x.account_details.account_code}</td>
                          <td id="td-right"><span>Account Name</span></td>
                          <td>{x.account_details.account_name}
                          </td>

                        </tr>
                        <tr>
                        <td id="td-right">
                            <span>Account Owner</span>
                          </td>
                          <td>
                            {x.account_details.account_owner}
                          </td>
                          <td id="td-right">
                            <span>
                              Account alias{" "}
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
                            </span>
                            </td><td>
                            {x.account_details.account_alias}
                          </td>
                        </tr>
                        <tr>
                        <td id="td-right">
                            <span>Account record type</span>
                            </td>
                            <td>
                             {x.account_details.account_record_type}
                          </td>
                          <td id="td-right">
                            <span>Account Number</span>
                            </td>
                            <td>
                             {x.account_details.account_number}
                          </td>
                        </tr>
                        <tr>

                        <td id="td-right">
                            <span>Parent Account</span>
                            </td>
                            <td>

                            {x.account_details.parent_account}
                          </td>
                          <td id="td-right">
                            <span>Website</span>
                            </td>
                            <td>
                            {x.account_details.website}
                          </td>

                        </tr>
                      </tbody>
                    </table> */}
      </div>
      </div></div>
      <Footer/>
      </div>
  );
};

export default ViewUser;