import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import { useLocation , useParams, Link} from "react-router-dom";
import Header from '../Header'
import Footer from '../Footer';import { API_URL } from '../config';
import axios from "axios";
import $ from 'jquery';

import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useNavigate } from "react-router-dom";
const AddContactOpportunity = () => {

    const navigate = useNavigate();

  const location = useLocation();
  const { account_record } = location.state;
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const params = useParams()
  const ids=params.id
  console.log(ids)
  const [startDate1, setStartDate1] = useState(new Date());
   const birthDate=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate2, setStartDate2] = useState(new Date());
   const BankDate=startDate2.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate2.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate2.toLocaleDateString("en-US", { day: '2-digit' }))
  const BanktoDate = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $("#addOpportunity").css({"min-height":(window_height)+"px"})
const [accountName,setAccountName]=useState([]);
const [accountName1,setAccountName1]=useState([]);
const [supplier, setSupplier]=useState([]);
const [company,setCompany]=useState([]);
const [rwoodusers, setRwoodusers]=useState([]);
// supplier
useEffect(() => {

  fetchData3();
}, [decryptedAccessToken]);

const fetchData3 = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/rwoodusers ', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    console.log(data)
    setRwoodusers(data);

  } catch (error) {
    console.error(error);
  }
};
useEffect(() => {

  fetchDataSupplier();
}, [decryptedAccessToken]);

const fetchDataSupplier = async () => {
  try {
    const response = await axios.get(API_URL+'all/accountsbyID/'+ids, {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.account_name;
    setAccountName(data)
     console.log('Supplier'+ JSON.stringify(data))
  } catch (error) {
    console.error(error);
  }
};





useEffect(() => {

  fetchDataSupplier1();
}, [decryptedAccessToken]);

const fetchDataSupplier1 = async () => {
  try {
    const response = await axios.get(API_URL+'get/supplier', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    setSupplier(data)
     console.log('Supplier'+ JSON.stringify(data))
  } catch (error) {
    console.error(error);
  }
};




useEffect(() => {

  fetchDataAccount();
}, [decryptedAccessToken]);

const fetchDataAccount = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/rwoodcompany', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.rwoodcompany;
    setCompany(data)
     console.log(data)
  } catch (error) {
    console.error(error);
  }
};
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    if (account_record=== "Buyer - Long Term") {
    const onSubmit = data =>{
        console.log(data)
        const responseData={
          company:data.company,

exchange_rate:data.exchange_rate,
currency:data.currency,
opportunity_name:data.opportunity_name,
account_name:data.account_name,
supplier:data.supplier,
trader_name:data.trader_name,
inquiry_date:birthDate,
close_date:BankDate,
stage:data.stage,
primary_campaign_source:data.primary_campaign_source,
no_of_opp_shipments:data.no_of_opp_shipments,
laycan_year:data.laycan_year,
laycan_month:data.laycan_month,
locked:true,
opportunity_record_type:data.opportunity_record_type,
opportunity_owner:data.opportunity_owner,
probability:data.probability,
no_of_trade_confirmation:data.no_of_trade_confirmation,
loss_reason:data.loss_reason,
loss_reason_remark:data.loss_reason_remark,
no_of_order:data.no_of_order,
commodity:data.commodity,
destination:data.destination,
origin:data.origin,
quantity_mt:data.quantity_mt,
quantity:data.quantity,
product_description:data.product_description,
amount:data.amount,
contract:data.contract,
bonus_penalty_clause:data.bonus_penalty_clause,
rejections:data.rejections,
payment_terms:data.payment_terms,
validity:data.validity,
applicable_law:data.applicable_law,
synced_quote_id:data.synced_quote_id,
next_step:data.next_step,
description:data.description,

      };
      console.log( JSON.stringify(responseData))
    const encryptedAccessToken = localStorage.getItem('access_token');
    const id = localStorage.getItem('product_id');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

        fetch(API_URL+"add/contact/opportunity/"+ids, {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())

                  .then((response) => {
                    if(Response.success==='Added'){
                    toast.success("Contact Opportunity Added Successfully",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    setTimeout(() => {
                                        reset();
                                        //navigate("/contact/contact-details/"+ids);
                                      }, 2000);
                                    }
                                    else{
                                      toast.success("Already Present",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    }

                  })


                }

            }
    return(
        <div id='addOpportunity' className='m-contract'>
            <Header/>

            <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Buyer - Long Term</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
  <div className='tasks'>

  <Form onSubmit={handleSubmit(onSubmit)}>
  <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
        <h4 className='heading'>Opportunity Information</h4>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Company">
    <Form.Select aria-label="Floating label select example"
    name="company"
    placeholder="Company"
    defaultValue=""
    {...register("company", { required: true })}
    >
      <option value='' >Select</option>
       {
          company.map((x)=>{
              return(
                  <option value={x.company_name}>{x.company_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.company && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
<Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Exchange rate"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Exchange rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {valueAsNumber:true,  required: true })}
                    />
                     {errors.exchange_rate && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Opportunity record type"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Opportunity record type"
                    name="opportunity_record_type"
                    value={account_record}
                    {...register("opportunity_record_type", {  required: false })}
                    />
                     {errors.opportunity_record_type && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Opportunity name"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Opportunity name"
                    name="opportunity_name"
                    {...register("opportunity_name", {  required: true })}
                    />
                     {errors.opportunity_name && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Currency">
  <Form.Select aria-label="Floating label select example"
  name="currency"
  placeholder="Currency"
  defaultValue=""
  {...register("currency", { required: false })}
  >
    <option value='' >---None---</option>
    <option value='USD' >USD</option>
    <option value='IDR' >IDR</option>

  </Form.Select>
  {errors.currency && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="probability"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="probability"
                    name="probability"
                    {...register("probability", { valueAsNumber:true, required: false })}
                    />
                     {errors.probability && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Account Name"
                    className="mb-3 "
                  >

                    <Form.Control type="text"
                    placeholder="Account Name"
                    name="account_name"
                    className="accountName"
                    defaultValue={accountName}
                    {...register("account_name", {  required: false })}
                    />
                     {errors.account_name && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>

              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="supplier"
    placeholder="Supplier"
    defaultValue=""
    {...register("supplier", { required: true })}
    >
      <option value='' >Select</option>
       {
          supplier.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.supplier && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Trader Name">
    <Form.Select aria-label="Floating label select example"
    name="trader_name"
    placeholder="Trader Name"
    defaultValue=""
    {...register("trader_name", { required: true })}
    >
      <option value='' >Select</option>
       {
          rwoodusers.map((x)=>{
              return(
                  <option value={x.rwood_username}>{x.rwood_username}</option>
              )
          })
       }
    </Form.Select>
    {errors.trader_name && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="No of order"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="No of order"
                    name="no_of_order"
                    {...register("no_of_order", { valueAsNumber:true, required: false })}
                    />
                     {errors.no_of_order && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Inquiry Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Inquiry Date'
    />
          </FloatingLabel>
        </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="No of trade confirmation"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="No of trade confirmation"
                    name="no_of_trade_confirmation"
                    {...register("no_of_trade_confirmation", { valueAsNumber:true, required: false })}
                    />
                     {errors.no_of_trade_confirmation && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Close Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<BanktoDate />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Close Date'
    />
          </FloatingLabel>
        </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Loss reason "
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Loss reason"
                    name="loss_reason "
                    {...register("loss_reason  ", {  required: false })}
                    />
                     {errors.loss_reason   && <span>Required</span>}
                     </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
        <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Stage">
    <Form.Select aria-label="Floating label select example"
    name="stage"
    placeholder="Stage"
    {...register("stage", { required: false })}
    >
      <option >Select</option>
      <option value='Proposal' >Proposal</option>
      <option value='Concluded' >Concluded</option>
      <option value='Quoted' >Quoted</option>
      <option value='Dropped' >Dropped</option>
      <option value='Last' >Last</option>
      <option value='Closed' >Closed</option>

    </Form.Select>
    {errors.stage && <span>Required</span>}
    </FloatingLabel>
     </Form.Group>

<Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Primary campaign source"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Primary campaign source"
                    name="primary_campaign_source"
                    {...register("primary_campaign_source", {  required: false })}
                    />
                     {errors.primary_campaign_source && <span>Required</span>}
                     </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Laycan Year">
  <Form.Select aria-label="Floating label select example"
  name="laycan_year"
  placeholder="Laycan Year"
  defaultValue=""
  {...register("laycan_year", { required: false })}
  >
    <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
  </Form.Select>
  {errors.laycan_year && <span>Required </span>}
</FloatingLabel>
            </Form.Group>

      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Product Information</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Quantity MT"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Quantity MT"
                    name="quantity_mt"
                    {...register("quantity_mt", {  required: false })}
                    />
                     {errors.quantity_mt && <span>Required</span>}
                     </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Amount"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Amount"
                    name="amount"
                    {...register("amount", {valueAsNumber:true,  required: false })}
                    />
                     {errors.amount && <span>Required</span>}
                     </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Quantity"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Quantity"
                    name="quantity"
                    {...register("quantity", {  required: false })}
                    />
                     {errors.quantity && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Contract"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Contract "
                    name="contract"
                    {...register("contract", {  required: false })}
                    />
                     {errors.contract && <span>Required</span>}
                     </FloatingLabel>
                </Form.Group>
                <Form.Group
      as={Col}
      md="12"
    >

      <FloatingLabel controlId="floatingTextarea2" label="Product description">
        <Form.Control
          as="textarea"
          placeholder="Product description"
          style={{ height: '200px' }}
          name="product_description"
          {...register("product_description", { required: false })}
          />
           {errors.product_description && <p id='text-area'>Required</p>}
      </FloatingLabel>
    </Form.Group>

      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Market Information</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Origin"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", {  required: false })}
                    />
                     {errors.origin && <span>Required</span>}
                     </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Commodity"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Commodity"
                    name="commodity"
                    {...register("commodity", {  required: false })}
                    />
                     {errors.commodity && <span>Required</span>}
                     </FloatingLabel>
                </Form.Group>
                <Form.Group
      as={Col}
      md="12"
    >

      <FloatingLabel controlId="floatingTextarea2" label="Description">
        <Form.Control
          as="textarea"
          placeholder="Description"
          style={{ height: '200px' }}
          name="description"
          {...register("description", { required: false })}
          />
           {errors.description && <p id='text-area'>Required</p>}
      </FloatingLabel>
    </Form.Group>


      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Price and Price Adjustment</h4>

<Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Bonus penalty clause"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    {...register("bonus_penalty_clause", {  required: false })}
                    />
                     {errors.bonus_penalty_clause && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Rejections"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Rejections"
                    name="rejections"
                    {...register("rejections", {  required: false })}
                    />
                     {errors.rejections && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group
      as={Col}
      md="12"
    >

      <FloatingLabel controlId="floatingTextarea2" label="Payment terms">
        <Form.Control
          as="textarea"
          placeholder="Payment terms"
          style={{ height: '200px' }}
          name="payment_terms"
          {...register("payment_terms", { required: false })}
          />
           {errors.payment_terms && <p id='text-area'>Required</p>}
      </FloatingLabel>
    </Form.Group>
        </Row>
      {/* product Information */}
      <Row className="mb-3">
        <h4 className='heading'>Description Information</h4>
       <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Validity"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Validity"
                    name="validity"
                    {...register("validity", {  required: false })}
                    />
                     {errors.validity && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Applicable law"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Applicable law"
                    name="applicable_law"
                    {...register("applicable_law", {  required: false })}
                    />
                     {errors.applicable_law && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
      as={Col}
      md="12"
    >

      <FloatingLabel controlId="floatingTextarea2" label="Description">
        <Form.Control
          as="textarea"
          placeholder="Description"
          style={{ height: '200px' }}
          name="description"
          {...register("description", { required: false })}
          />
           {errors.description && <p id='text-area'>Required</p>}
      </FloatingLabel>
    </Form.Group>

      </Row>


     <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
            </div>
            <Footer/>
            </div>
            )


}
else if (account_record=== "Buyer") {
  const onSubmit = data =>{
      console.log(data)
      const responseData={
        company:data.company,

exchange_rate:data.exchange_rate,
currency:data.currency,
opportunity_name:data.opportunity_name,
account_name:data.account_name,
supplier:data.supplier,
trader_name:data.trader_name,
inquiry_date:birthDate,
close_date:BankDate,
stage:data.stage,
primary_campaign_source:data.primary_campaign_source,
no_of_opp_shipments:data.no_of_opp_shipments,
laycan_year:data.laycan_year,
laycan_month:data.laycan_month,
locked:true,
opportunity_record_type:data.opportunity_record_type,
opportunity_owner:data.opportunity_owner,
probability:data.probability,
no_of_trade_confirmation:data.no_of_trade_confirmation,
loss_reason:data.loss_reason,
loss_reason_remark:data.loss_reason_remark,
no_of_order:data.no_of_order,
commodity:data.commodity,
destination:data.destination,
origin:data.origin,
quantity_mt:data.quantity_mt,
quantity:data.quantity,
product_description:data.product_description,
amount:data.amount,
contract:data.contract,
bonus_penalty_clause:data.bonus_penalty_clause,
rejections:data.rejections,
payment_terms:data.payment_terms,
validity:data.validity,
applicable_law:data.applicable_law,
synced_quote_id:data.synced_quote_id,
next_step:data.next_step,
description:data.description,

    };
    console.log( JSON.stringify(responseData))
  const encryptedAccessToken = localStorage.getItem('access_token');
  const id = localStorage.getItem('product_id');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+"add/contact/opportunity/"+ids, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())

                .then((response) => {
                  console.log(response)
                  if(Response.success==='Added'){
                    toast.success("Contact Opportunity Added Successfully",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    setTimeout(() => {
                                        reset();
                                        //navigate("/contact/contact-details/"+ids);
                                      }, 2000);
                                    }
                                    else{
                                      toast.success("Already Present",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    }

                })

              }

          }
  return(
      <div id='addOpportunity' className='m-contract'>
          <Header/>

          <div className="page-header" id=''>
  <div>
    <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity</h2>
    <ol className="breadcrumb">
      <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Buyer</li>
    </ol>
  </div>
  <div className="d-flex">
    <div className="justify-content-center">

      {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
       <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
      </button> */}

    </div>
  </div>
</div>
<div className='tasks'>

<Form onSubmit={handleSubmit(onSubmit)}>
<p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <Row className="mb-3">
      <h4 className='heading'>Opportunity Information</h4>
      <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Company">
  <Form.Select aria-label="Floating label select example"
  name="company"
  placeholder="Company"
  defaultValue=""
  {...register("company", { required: true })}
  >
    <option value='' >Select</option>
     {
        company.map((x)=>{
            return(
                <option value={x.company_name}>{x.company_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.company && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Exchange rate"
                  name="exchange_rate"
                  {...register("exchange_rate", {valueAsNumber:true,  required: true })}
                  />
                   {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity record type"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity record type"
                  name="opportunity_record_type"
                  value={account_record}
                  {...register("opportunity_record_type", {  required: false })}
                  />
                   {errors.opportunity_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity name"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity name"
                  name="opportunity_name"
                  {...register("opportunity_name", {  required: true })}
                  />
                   {errors.opportunity_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Currency">
  <Form.Select aria-label="Floating label select example"
  name="currency"
  placeholder="Currency"
  defaultValue=""
  {...register("currency", { required: false })}
  >
    <option value='' >---None---</option>
    <option value='USD' >USD</option>
    <option value='IDR' >IDR</option>

  </Form.Select>
  {errors.currency && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="probability"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="probability"
                  name="probability"
                  {...register("probability", { valueAsNumber:true, required: false })}
                  />
                   {errors.probability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Account Name"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Account Name"
                    name="account_name"
                    className="accountName"
                    defaultValue={accountName}
                    {...register("account_name", {  required: false })}
                    />
                     {errors.account_name && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Supplier">
  <Form.Select aria-label="Floating label select example"
  name="supplier"
  placeholder="Supplier"
  defaultValue=""
  {...register("supplier", { required: true })}
  >
    <option value='' >Select</option>
     {
        supplier.map((x)=>{
            return(
                <option value={x.account_name}>{x.account_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.supplier && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Trader Name">
  <Form.Select aria-label="Floating label select example"
  name="trader_name"
  placeholder="Trader Name"
  defaultValue=""
  {...register("trader_name", { required: true })}
  >
    <option value='' >Select</option>
     {
        rwoodusers.map((x)=>{
            return(
                <option value={x.rwood_username}>{x.rwood_username}</option>
            )
        })
     }
  </Form.Select>
  {errors.trader_name && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of order"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of order"
                  name="no_of_order"
                  {...register("no_of_order", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_order && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Inquiry Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      required
      dateFormat='yyyy-MM-dd'
      placeholderText='Inquiry Date'
    />
          </FloatingLabel>
        </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of trade confirmation"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of trade confirmation"
                  name="no_of_trade_confirmation"
                  {...register("no_of_trade_confirmation", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_trade_confirmation && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

             <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Close Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<BanktoDate />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Close Date'
    />
          </FloatingLabel>
        </Form.Group>



              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loss reason "
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Loss reason"
                  name="loss_reason "
                  {...register("loss_reason  ", {  required: false })}
                  />
                   {errors.loss_reason   && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
      <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Stage">
  <Form.Select aria-label="Floating label select example"
  name="stage"
  placeholder="Stage"
  {...register("stage", { required: false })}
  >
    <option >---None---</option>
    <option value='Proposal' >Proposal</option>
    <option value='Concluded' >Concluded</option>
    <option value='Quoted' >Quoted</option>
    <option value='Dropped' >Dropped</option>
    <option value='Last' >Last</option>
    <option value='Closed' >Closed</option>

  </Form.Select>
  {errors.stage && <span>Required</span>}
  </FloatingLabel>
   </Form.Group>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Primary campaign source"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Primary campaign source"
                  name="primary_campaign_source"
                  {...register("primary_campaign_source", {  required: false })}
                  />
                   {errors.primary_campaign_source && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Laycan Year">
  <Form.Select aria-label="Floating label select example"
  name="laycan_year"
  placeholder="Laycan Year"
  defaultValue=""
  {...register("laycan_year", { required: false })}
  >
    <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
  </Form.Select>
  {errors.laycan_year && <span>Required </span>}
</FloatingLabel>
            </Form.Group>

    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Product Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity MT"
                  name="quantity_mt"
                  {...register("quantity_mt", {  required: false })}
                  />
                   {errors.quantity_mt && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Amount"
                  name="amount"
                  {...register("amount", {valueAsNumber:true,  required: false })}
                  />
                   {errors.amount && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity"
                  name="quantity"
                  {...register("quantity", {  required: false })}
                  />
                   {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Contract "
                  name="contract"
                  {...register("contract", {  required: false })}
                  />
                   {errors.contract && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Product description">
      <Form.Control
        as="textarea"
        placeholder="Product description"
        style={{ height: '200px' }}
        name="product_description"
        {...register("product_description", { required: false })}
        />
         {errors.product_description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Market Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Origin"
                  name="origin"
                  {...register("origin", {  required: false })}
                  />
                   {errors.origin && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commodity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Commodity"
                  name="commodity"
                  {...register("commodity", {  required: false })}
                  />
                   {errors.commodity && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>


    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Price and Price Adjustment</h4>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Bonus penalty clause"
                  name="bonus_penalty_clause"
                  {...register("bonus_penalty_clause", {  required: false })}
                  />
                   {errors.bonus_penalty_clause && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Rejections"
                  name="rejections"
                  {...register("rejections", {  required: false })}
                  />
                   {errors.rejections && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Payment terms">
      <Form.Control
        as="textarea"
        placeholder="Payment terms"
        style={{ height: '200px' }}
        name="payment_terms"
        {...register("payment_terms", { required: false })}
        />
         {errors.payment_terms && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>
      </Row>
    {/* product Information */}
    <Row className="mb-3">
      <h4 className='heading'>Description Information</h4>
     <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Validity"
                  name="validity"
                  {...register("validity", {  required: false })}
                  />
                   {errors.validity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Applicable law"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Applicable law"
                  name="applicable_law"
                  {...register("applicable_law", {  required: false })}
                  />
                   {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>


   <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <div className='successDiv'><p></p><p></p></div>
  </Form>
          </div>
          <Footer/>
          </div>
          )


}
else if (account_record=== "Buyer - Spot") {
  const onSubmit = data =>{
      console.log(data)
      const responseData={
        company:data.company,

exchange_rate:data.exchange_rate,
currency:data.currency,
opportunity_name:data.opportunity_name,
account_name:data.account_name,
supplier:data.supplier,
trader_name:data.trader_name,
inquiry_date:birthDate,
close_date:BankDate,
stage:data.stage,
primary_campaign_source:data.primary_campaign_source,
no_of_opp_shipments:data.no_of_opp_shipments,
laycan_year:data.laycan_year,
laycan_month:data.laycan_month,
locked:true,
opportunity_record_type:data.opportunity_record_type,
opportunity_owner:data.opportunity_owner,
probability:data.probability,
no_of_trade_confirmation:data.no_of_trade_confirmation,
loss_reason:data.loss_reason,
loss_reason_remark:data.loss_reason_remark,
no_of_order:data.no_of_order,
commodity:data.commodity,
destination:data.destination,
origin:data.origin,
quantity_mt:data.quantity_mt,
quantity:data.quantity,
product_description:data.product_description,
amount:data.amount,
contract:data.contract,
bonus_penalty_clause:data.bonus_penalty_clause,
rejections:data.rejections,
payment_terms:data.payment_terms,
validity:data.validity,
applicable_law:data.applicable_law,
synced_quote_id:data.synced_quote_id,
next_step:data.next_step,
description:data.description,

    };
    console.log( JSON.stringify(responseData))
  const encryptedAccessToken = localStorage.getItem('access_token');
  const id = localStorage.getItem('product_id');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+"add/contact/opportunity/"+ids, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())

                .then((response) => {
                  console.log(response)
                  toast.success("Opportunity Added Successfully",
                                 {
                                  position: "bottom-center",
                                  autoClose: 1000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                  });
                                  setTimeout(() => {
                                    reset();
                                    //navigate("/contact/contact-details/"+ids);
                                  }, 2000);

                })

              }

          }
  return(
      <div id='addOpportunity' className='m-contract'>
          <Header/>

          <div className="page-header" id=''>
  <div>
    <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity</h2>
    <ol className="breadcrumb">
      <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Buyer - Spot</li>
    </ol>
  </div>
  <div className="d-flex">
    <div className="justify-content-center">

      {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
       <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
      </button> */}

    </div>
  </div>
</div>
<div className='tasks'>

<Form onSubmit={handleSubmit(onSubmit)}>
<p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <Row className="mb-3">
      <h4 className='heading'>Opportunity Information</h4>
      <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Company">
  <Form.Select aria-label="Floating label select example"
  name="company"
  placeholder="Company"
  defaultValue=""
  {...register("company", { required: true })}
  >
    <option value='' >Select</option>
     {
        company.map((x)=>{
            return(
                <option value={x.company_name}>{x.company_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.company && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Exchange rate"
                  name="exchange_rate"
                  {...register("exchange_rate", {valueAsNumber:true,  required: true })}
                  />
                   {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity record type"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity record type"
                  name="opportunity_record_type"
                  value={account_record}
                  {...register("opportunity_record_type", {  required: false })}
                  />
                   {errors.opportunity_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity name"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity name"
                  name="opportunity_name"
                  {...register("opportunity_name", {  required: true })}
                  />
                   {errors.opportunity_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Currency">
  <Form.Select aria-label="Floating label select example"
  name="currency"
  placeholder="Currency"
  defaultValue=""
  {...register("currency", { required: false })}
  >
    <option value='' >---None---</option>
    <option value='USD' >USD</option>
    <option value='IDR' >IDR</option>

  </Form.Select>
  {errors.currency && <span>Required </span>}
</FloatingLabel>
            </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="probability"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="probability"
                  name="probability"
                  {...register("probability", { valueAsNumber:true, required: false })}
                  />
                   {errors.probability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Account Name"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Account Name"
                    name="account_name"
                    className="accountName"
                    defaultValue={accountName}
                    {...register("account_name", {  required: false })}
                    />
                     {errors.account_name && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Supplier">
  <Form.Select aria-label="Floating label select example"
  name="supplier"
  placeholder="Supplier"
  defaultValue=""
  {...register("supplier", { required: true })}
  >
    <option value='' >Select</option>
     {
        supplier.map((x)=>{
            return(
                <option value={x.account_name}>{x.account_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.supplier && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Trader Name">
  <Form.Select aria-label="Floating label select example"
  name="trader_name"
  placeholder="Trader Name"
  defaultValue=""
  {...register("trader_name", { required: true })}
  >
    <option value='' >Select</option>
     {
        rwoodusers.map((x)=>{
            return(
                <option value={x.rwood_username}>{x.rwood_username}</option>
            )
        })
     }
  </Form.Select>
  {errors.trader_name && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of order"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of order"
                  name="no_of_order"
                  {...register("no_of_order", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_order && <span>Required</span>}
                </FloatingLabel>
              </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Inquiry Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Inquiry Date'
    />
          </FloatingLabel>
        </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of trade confirmation"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of trade confirmation"
                  name="no_of_trade_confirmation"
                  {...register("no_of_trade_confirmation", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_trade_confirmation && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

             <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Close Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<BanktoDate />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Close Date'
    />
          </FloatingLabel>
        </Form.Group>



              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loss reason "
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Loss reason"
                  name="loss_reason "
                  {...register("loss_reason  ", {  required: false })}
                  />
                   {errors.loss_reason   && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
      <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Stage">
  <Form.Select aria-label="Floating label select example"
  name="stage"
  placeholder="Stage"
  {...register("stage", { required: false })}
  >
    <option >---None---</option>
    <option value='Proposal' >Proposal</option>
    <option value='Concluded' >Concluded</option>
    <option value='Quoted' >Quoted</option>
    <option value='Dropped' >Dropped</option>
    <option value='Last' >Last</option>
    <option value='Closed' >Closed</option>

  </Form.Select>
  {errors.stage && <span>Required</span>}
  </FloatingLabel>
   </Form.Group>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Primary campaign source"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Primary campaign source"
                  name="primary_campaign_source"
                  {...register("primary_campaign_source", {  required: false })}
                  />
                   {errors.primary_campaign_source && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Laycan Year">
  <Form.Select aria-label="Floating label select example"
  name="laycan_year"
  placeholder="Laycan Year"
  defaultValue=""
  {...register("laycan_year", { required: false })}
  >
    <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
  </Form.Select>
  {errors.laycan_year && <span>Required </span>}
</FloatingLabel>
            </Form.Group>


    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Product Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity MT"
                  name="quantity_mt"
                  {...register("quantity_mt", {  required: false })}
                  />
                   {errors.quantity_mt && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Amount"
                  name="amount"
                  {...register("amount", {valueAsNumber:true,  required: false })}
                  />
                   {errors.amount && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity"
                  name="quantity"
                  {...register("quantity", {  required: false })}
                  />
                   {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Contract "
                  name="contract"
                  {...register("contract", {  required: false })}
                  />
                   {errors.contract && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Product description">
      <Form.Control
        as="textarea"
        placeholder="Product description"
        style={{ height: '200px' }}
        name="product_description"
        {...register("product_description", { required: false })}
        />
         {errors.product_description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Market Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Origin"
                  name="origin"
                  {...register("origin", {  required: false })}
                  />
                   {errors.origin && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commodity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Commodity"
                  name="commodity"
                  {...register("commodity", {  required: false })}
                  />
                   {errors.commodity && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>


    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Price and Price Adjustment</h4>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Bonus penalty clause"
                  name="bonus_penalty_clause"
                  {...register("bonus_penalty_clause", {  required: false })}
                  />
                   {errors.bonus_penalty_clause && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Rejections"
                  name="rejections"
                  {...register("rejections", {  required: false })}
                  />
                   {errors.rejections && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Payment terms">
      <Form.Control
        as="textarea"
        placeholder="Payment terms"
        style={{ height: '200px' }}
        name="payment_terms"
        {...register("payment_terms", { required: false })}
        />
         {errors.payment_terms && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>
      </Row>
    {/* product Information */}
    <Row className="mb-3">
      <h4 className='heading'>Description Information</h4>
     <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Validity"
                  name="validity"
                  {...register("validity", {  required: false })}
                  />
                   {errors.validity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Applicable law"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Applicable law"
                  name="applicable_law"
                  {...register("applicable_law", {  required: false })}
                  />
                   {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>


   <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <div className='successDiv'><p></p><p></p></div>
  </Form>
          </div>
          <Footer/>
          </div>
          )


}
else if (account_record=== "Supplier - Spot") {
  const onSubmit = data =>{
      console.log(data)
      const responseData={
        company:data.company,

exchange_rate:data.exchange_rate,
currency:data.currency,
opportunity_name:data.opportunity_name,
account_name:data.account_name,
supplier:data.supplier,
trader_name:data.trader_name,
inquiry_date:birthDate,
close_date:BankDate,
stage:data.stage,
primary_campaign_source:data.primary_campaign_source,
no_of_opp_shipments:data.no_of_opp_shipments,
laycan_year:data.laycan_year,
laycan_month:data.laycan_month,
locked:true,
opportunity_record_type:data.opportunity_record_type,
opportunity_owner:data.opportunity_owner,
probability:data.probability,
no_of_trade_confirmation:data.no_of_trade_confirmation,
loss_reason:data.loss_reason,
loss_reason_remark:data.loss_reason_remark,
no_of_order:data.no_of_order,
commodity:data.commodity,
destination:data.destination,
origin:data.origin,
quantity_mt:data.quantity_mt,
quantity:data.quantity,
product_description:data.product_description,
amount:data.amount,
contract:data.contract,
bonus_penalty_clause:data.bonus_penalty_clause,
rejections:data.rejections,
payment_terms:data.payment_terms,
validity:data.validity,
applicable_law:data.applicable_law,
synced_quote_id:data.synced_quote_id,
next_step:data.next_step,
description:data.description,

    };
    console.log( JSON.stringify(responseData))
  const encryptedAccessToken = localStorage.getItem('access_token');
  const id = localStorage.getItem('product_id');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+"add/contact/opportunity/"+ids, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())

                .then((response) => {
                  console.log(response)
                  if(Response.success==='Added'){
                    toast.success("Contact Opportunity Added Successfully",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    setTimeout(() => {
                                        reset();
                                        //navigate("/contact/contact-details/"+ids);
                                      }, 2000);
                                    }
                                    else{
                                      toast.success("Already Present",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    }

                })

              }

          }
  return(
      <div id='addOpportunity' className='m-contract'>
          <Header/>

          <div className="page-header" id=''>
  <div>
    <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity</h2>
    <ol className="breadcrumb">
      <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Supplier</li>
    </ol>
  </div>
  <div className="d-flex">
    <div className="justify-content-center">

      {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
       <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
      </button> */}

    </div>
  </div>
</div>
<div className='tasks'>

<Form onSubmit={handleSubmit(onSubmit)}>
<p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <Row className="mb-3">
      <h4 className='heading'>Opportunity Information</h4>
      <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Company">
  <Form.Select aria-label="Floating label select example"
  name="company"
  placeholder="Company"
  defaultValue=""
  {...register("company", { required: true })}
  >
    <option value='' >Select</option>
     {
        company.map((x)=>{
            return(
                <option value={x.company_name}>{x.company_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.company && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Exchange rate"
                  name="exchange_rate"
                  {...register("exchange_rate", {valueAsNumber:true,  required: true })}
                  />
                   {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity record type"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity record type"
                  name="opportunity_record_type"
                  value={account_record}
                  {...register("opportunity_record_type", {  required: false })}
                  />
                   {errors.opportunity_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity name"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity name"
                  name="opportunity_name"
                  {...register("opportunity_name", {  required: true })}
                  />
                   {errors.opportunity_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Currency">
  <Form.Select aria-label="Floating label select example"
  name="currency"
  placeholder="Currency"
  defaultValue=""
  {...register("currency", { required: false })}
  >
    <option value='' >---None---</option>
    <option value='USD' >USD</option>
    <option value='IDR' >IDR</option>

  </Form.Select>
  {errors.currency && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="probability"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="probability"
                  name="probability"
                  {...register("probability", { valueAsNumber:true, required: false })}
                  />
                   {errors.probability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Account Name"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Account Name"
                    name="account_name"
                    className="accountName"
                    defaultValue={accountName}
                    {...register("account_name", {  required: false })}
                    />
                     {errors.account_name && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Supplier">
  <Form.Select aria-label="Floating label select example"
  name="supplier"
  placeholder="Supplier"
  defaultValue=""
  {...register("supplier", { required: true })}
  >
    <option value='' >Select</option>
     {
        supplier.map((x)=>{
            return(
                <option value={x.account_name}>{x.account_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.supplier && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Trader Name">
  <Form.Select aria-label="Floating label select example"
  name="trader_name"
  placeholder="Trader Name"
  defaultValue=""
  {...register("trader_name", { required: true })}
  >
    <option value='' >Select</option>
     {
        rwoodusers.map((x)=>{
            return(
                <option value={x.rwood_username}>{x.rwood_username}</option>
            )
        })
     }
  </Form.Select>
  {errors.trader_name && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of order"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of order"
                  name="no_of_order"
                  {...register("no_of_order", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_order && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Inquiry Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Inquiry Date'
    />
          </FloatingLabel>
        </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of trade confirmation"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of trade confirmation"
                  name="no_of_trade_confirmation"
                  {...register("no_of_trade_confirmation", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_trade_confirmation && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

             <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Close Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<BanktoDate />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Close Date'
    />
          </FloatingLabel>
        </Form.Group>



              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loss reason "
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Loss reason"
                  name="loss_reason "
                  {...register("loss_reason  ", {  required: false })}
                  />
                   {errors.loss_reason   && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
      <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Stage">
  <Form.Select aria-label="Floating label select example"
  name="stage"
  placeholder="Stage"
  {...register("stage", { required: false })}
  >
    <option >Select</option>
    <option value='Proposal' >Proposal</option>
    <option value='Concluded' >Concluded</option>
    <option value='Quoted' >Quoted</option>
    <option value='Dropped' >Dropped</option>
    <option value='Last' >Last</option>
    <option value='Closed' >Closed</option>

  </Form.Select>
  {errors.stage && <span>Required</span>}
  </FloatingLabel>
   </Form.Group>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Primary campaign source"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Primary campaign source"
                  name="primary_campaign_source"
                  {...register("primary_campaign_source", {  required: false })}
                  />
                   {errors.primary_campaign_source && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Laycan Year">
  <Form.Select aria-label="Floating label select example"
  name="laycan_year"
  placeholder="Laycan Year"
  defaultValue=""
  {...register("laycan_year", { required: false })}
  >
    <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
  </Form.Select>
  {errors.laycan_year && <span>Required </span>}
</FloatingLabel>
            </Form.Group>

    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Product Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity MT"
                  name="quantity_mt"
                  {...register("quantity_mt", {  required: false })}
                  />
                   {errors.quantity_mt && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Amount"
                  name="amount"
                  {...register("amount", {valueAsNumber:true,  required: false })}
                  />
                   {errors.amount && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity"
                  name="quantity"
                  {...register("quantity", {  required: false })}
                  />
                   {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Contract "
                  name="contract"
                  {...register("contract", {  required: false })}
                  />
                   {errors.contract && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Product description">
      <Form.Control
        as="textarea"
        placeholder="Product description"
        style={{ height: '200px' }}
        name="product_description"
        {...register("product_description", { required: false })}
        />
         {errors.product_description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Market Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Origin"
                  name="origin"
                  {...register("origin", {  required: false })}
                  />
                   {errors.origin && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commodity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Commodity"
                  name="commodity"
                  {...register("commodity", {  required: false })}
                  />
                   {errors.commodity && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>


    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Price and Price Adjustment</h4>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Bonus penalty clause"
                  name="bonus_penalty_clause"
                  {...register("bonus_penalty_clause", {  required: false })}
                  />
                   {errors.bonus_penalty_clause && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Rejections"
                  name="rejections"
                  {...register("rejections", {  required: false })}
                  />
                   {errors.rejections && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Payment terms">
      <Form.Control
        as="textarea"
        placeholder="Payment terms"
        style={{ height: '200px' }}
        name="payment_terms"
        {...register("payment_terms", { required: false })}
        />
         {errors.payment_terms && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>
      </Row>
    {/* product Information */}
    <Row className="mb-3">
      <h4 className='heading'>Description Information</h4>
     <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Validity"
                  name="validity"
                  {...register("validity", {  required: false })}
                  />
                   {errors.validity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Applicable law"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Applicable law"
                  name="applicable_law"
                  {...register("applicable_law", {  required: false })}
                  />
                   {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>


   <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <div className='successDiv'><p></p><p></p></div>
  </Form>
          </div>
          <Footer/>
          </div>
          )


}
else if (account_record=== "Supplier - Long Term") {
  const onSubmit = data =>{
      console.log(data)
      const responseData={
        company:data.company,

exchange_rate:data.exchange_rate,
currency:data.currency,
opportunity_name:data.opportunity_name,
account_name:data.account_name,
supplier:data.supplier,
trader_name:data.trader_name,
inquiry_date:birthDate,
close_date:BankDate,
stage:data.stage,
primary_campaign_source:data.primary_campaign_source,
no_of_opp_shipments:data.no_of_opp_shipments,
laycan_year:data.laycan_year,
laycan_month:data.laycan_month,
locked:true,
opportunity_record_type:data.opportunity_record_type,
opportunity_owner:data.opportunity_owner,
probability:data.probability,
no_of_trade_confirmation:data.no_of_trade_confirmation,
loss_reason:data.loss_reason,
loss_reason_remark:data.loss_reason_remark,
no_of_order:data.no_of_order,
commodity:data.commodity,
destination:data.destination,
origin:data.origin,
quantity_mt:data.quantity_mt,
quantity:data.quantity,
product_description:data.product_description,
amount:data.amount,
contract:data.contract,
bonus_penalty_clause:data.bonus_penalty_clause,
rejections:data.rejections,
payment_terms:data.payment_terms,
validity:data.validity,
applicable_law:data.applicable_law,
synced_quote_id:data.synced_quote_id,
next_step:data.next_step,
description:data.description,

    };
    console.log( JSON.stringify(responseData))
  const encryptedAccessToken = localStorage.getItem('access_token');
  const id = localStorage.getItem('product_id');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+"add/contact/opportunity/"+ids, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())

                .then((response) => {
                  console.log(response)
                  if(Response.success==='Added'){
                    toast.success("Contact Opportunity Added Successfully",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    setTimeout(() => {
                                        reset();
                                        //navigate("/contact/contact-details/"+ids);
                                      }, 2000);
                                    }
                                    else{
                                      toast.success("Already Present",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    }

                })

              }

          }
  return(
      <div id='addOpportunity' className='m-contract'>
          <Header/>

          <div className="page-header" id=''>
  <div>
    <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity</h2>
    <ol className="breadcrumb">
      <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Supplier - Long Term</li>
    </ol>
  </div>
  <div className="d-flex">
    <div className="justify-content-center">

      {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
       <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
      </button> */}

    </div>
  </div>
</div>
<div className='tasks'>

<Form onSubmit={handleSubmit(onSubmit)}>
<p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <Row className="mb-3">
      <h4 className='heading'>Opportunity Information</h4>
      <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Company">
  <Form.Select aria-label="Floating label select example"
  name="company"
  placeholder="Company"
  defaultValue=""
  {...register("company", { required: true })}
  >
    <option value='' >Select</option>
     {
        company.map((x)=>{
            return(
                <option value={x.company_name}>{x.company_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.company && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Exchange rate"
                  name="exchange_rate"
                  {...register("exchange_rate", {valueAsNumber:true,  required: true })}
                  />
                   {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity record type"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity record type"
                  name="opportunity_record_type"
                  value={account_record}
                  {...register("opportunity_record_type", {  required: false })}
                  />
                   {errors.opportunity_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity name"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity name"
                  name="opportunity_name"
                  {...register("opportunity_name", {  required: true })}
                  />
                   {errors.opportunity_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Currency">
  <Form.Select aria-label="Floating label select example"
  name="currency"
  placeholder="Currency"
  defaultValue=""
  {...register("currency", { required: false })}
  >
    <option value='' >---None---</option>
    <option value='USD' >USD</option>
    <option value='IDR' >IDR</option>

  </Form.Select>
  {errors.currency && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="probability"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="probability"
                  name="probability"
                  {...register("probability", { valueAsNumber:true, required: false })}
                  />
                   {errors.probability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Account Name"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Account Name"
                    name="account_name"
                    defaultValue={accountName}
                    className="accountName"
                    {...register("account_name", {  required: false })}
                    />
                     {errors.account_name && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Supplier">
  <Form.Select aria-label="Floating label select example"
  name="supplier"
  placeholder="Supplier"
  defaultValue=""
  {...register("supplier", { required: true })}
  >
    <option value='' >Select</option>
     {
        supplier.map((x)=>{
            return(
                <option value={x.account_name}>{x.account_name}</option>
            )
        })
     }
  </Form.Select>
  {errors.supplier && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Trader Name">
  <Form.Select aria-label="Floating label select example"
  name="trader_name"
  placeholder="Trader Name"
  defaultValue=""
  {...register("trader_name", { required: true })}
  >
    <option value='' >Select</option>
     {
        rwoodusers.map((x)=>{
            return(
                <option value={x.rwood_username}>{x.rwood_username}</option>
            )
        })
     }
  </Form.Select>
  {errors.trader_name && <span>Required</span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of order"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of order"
                  name="no_of_order"
                  {...register("no_of_order", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_order && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Inquiry Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Inquiry Date'
    />
          </FloatingLabel>
        </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of trade confirmation"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="No of trade confirmation"
                  name="no_of_trade_confirmation"
                  {...register("no_of_trade_confirmation", { valueAsNumber:true, required: false })}
                  />
                   {errors.no_of_trade_confirmation && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

     <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Close Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<BanktoDate />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Close Date'
    />
          </FloatingLabel>
        </Form.Group>



              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loss reason "
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Loss reason"
                  name="loss_reason "
                  {...register("loss_reason  ", {  required: false })}
                  />
                   {errors.loss_reason   && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
      <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Stage">
  <Form.Select aria-label="Floating label select example"
  name="stage"
  placeholder="Stage"
  {...register("stage", { required: false })}
  >
    <option >---None---</option>
    <option value='Proposal' >Proposal</option>
    <option value='Concluded' >Concluded</option>
    <option value='Quoted' >Quoted</option>
    <option value='Dropped' >Dropped</option>
    <option value='Last' >Last</option>
    <option value='Closed' >Closed</option>

  </Form.Select>
  {errors.stage && <span>Required</span>}
  </FloatingLabel>
   </Form.Group>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Primary campaign source"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Primary campaign source"
                  name="primary_campaign_source"
                  {...register("primary_campaign_source", {  required: false })}
                  />
                   {errors.primary_campaign_source && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Laycan Year">
  <Form.Select aria-label="Floating label select example"
  name="laycan_year"
  placeholder="Laycan Year"
  defaultValue=""
  {...register("laycan_year", { required: false })}
  >
    <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
  </Form.Select>
  {errors.laycan_year && <span>Required </span>}
</FloatingLabel>
            </Form.Group>

    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Product Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity MT"
                  name="quantity_mt"
                  {...register("quantity_mt", {  required: false })}
                  />
                   {errors.quantity_mt && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Amount"
                  name="amount"
                  {...register("amount", {valueAsNumber:true,  required: false })}
                  />
                   {errors.amount && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Quantity"
                  name="quantity"
                  {...register("quantity", {  required: false })}
                  />
                   {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Contract "
                  name="contract"
                  {...register("contract", {  required: false })}
                  />
                   {errors.contract && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Product description">
      <Form.Control
        as="textarea"
        placeholder="Product description"
        style={{ height: '200px' }}
        name="product_description"
        {...register("product_description", { required: false })}
        />
         {errors.product_description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Market Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Origin"
                  name="origin"
                  {...register("origin", {  required: false })}
                  />
                   {errors.origin && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commodity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Commodity"
                  name="commodity"
                  {...register("commodity", {  required: false })}
                  />
                   {errors.commodity && <span>Required</span>}
                   </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>


    </Row>
    <Row className="mb-3">
      <h4 className='heading'>Price and Price Adjustment</h4>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Bonus penalty clause"
                  name="bonus_penalty_clause"
                  {...register("bonus_penalty_clause", {  required: false })}
                  />
                   {errors.bonus_penalty_clause && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Rejections"
                  name="rejections"
                  {...register("rejections", {  required: false })}
                  />
                   {errors.rejections && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Payment terms">
      <Form.Control
        as="textarea"
        placeholder="Payment terms"
        style={{ height: '200px' }}
        name="payment_terms"
        {...register("payment_terms", { required: false })}
        />
         {errors.payment_terms && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>
      </Row>
    {/* product Information */}
    <Row className="mb-3">
      <h4 className='heading'>Description Information</h4>
     <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Validity"
                  name="validity"
                  {...register("validity", {  required: false })}
                  />
                   {errors.validity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Applicable law"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Applicable law"
                  name="applicable_law"
                  {...register("applicable_law", {  required: false })}
                  />
                   {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group
    as={Col}
    md="12"
  >

    <FloatingLabel controlId="floatingTextarea2" label="Description">
      <Form.Control
        as="textarea"
        placeholder="Description"
        style={{ height: '200px' }}
        name="description"
        {...register("description", { required: false })}
        />
         {errors.description && <p id='text-area'>Required</p>}
    </FloatingLabel>
  </Form.Group>

    </Row>


   <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contact/contact-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    <div className='successDiv'><p></p><p></p></div>
  </Form>
          </div>
          <Footer/>
          </div>
          )


}
}
export default AddContactOpportunity;