import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import {Link, useNavigate } from "react-router-dom";
import Header from '../Header'
import Footer from '../Footer'
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import $ from 'jquery'
import DatePicker from "react-datepicker";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../config";
function  AddAccountmaster(props) {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate=useNavigate();
  const {register, handleSubmit, reset, formState: { errors } } = useForm();
  const adminRole=localStorage.getItem('role')
  const [check1, setCheck1] = useState(false);
     const onSubmit = data =>{
        const responseData={
          chart_of_account_no :data.chart_of_account_no,
account_type:data.account_type,
acc_code:data.acc_code,
ledger_name:data.ledger_name,
group_code:data.group_code,
sub_group_code:data.sub_group_code,
ledger_code:data.ledger_code,
subledger_code:data.subledger_code,
sub_subledger_code:data.sub_subledger_code,
owner:data.owner,
// locked:locked,
type_of_entry:data.type_of_entry,

          };
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(`${API_URL}add/chart_of_account_master`, {
        method: "POST",
        body: JSON.stringify(responseData),
        headers: { Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((response) => {
          navigate(`/account-master/details/${response.id}`)
          console.log(response)
          toast.success('Added Chart Account Master Successfully', {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          setTimeout(() => {
            reset();
          }, 300);
        })

        console.log(JSON.stringify(data))
      }
};

  return (

    <div className="contactAccount" id='contacts'>
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Account Master </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Account Master</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
   <Form onSubmit={handleSubmit(onSubmit)}>
   <p className="edit-cancel">
              <input type="submit" className="account-save" value="Save" />
              <Link to={"/account-master/list"}>
                <input type="submit" className="account-save" value="Cancel" />
              </Link>
            </p>
      <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Information</h4>
        </div>


    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="Chart of Account No"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Chart of Account No"
              name="chart_of_account_no"
              className="error-validation"
              {...register("chart_of_account_no", { required: false })}
            />
             {errors.chart_of_account_no && <span>Required</span>}
          </FloatingLabel>

            </Form.Group>
            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Owner"
            name="owner"
            className="owner-role"
            defaultValue={adminRole}
            {...register("owner", { required: true })}
            />
             {errors.owner && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_type"
                    placeholder="Account Type"
                    defaultValue=""
                    {...register("account_type", {
                      required: false,
                    })}
                  >
                    <option value="">--None--</option><option value="Income">Income</option><option value="Expense">Expense</option><option value="Asset">Asset</option><option value="Liability">Liability</option>
                  </Form.Select>
                  {errors.account_type && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" id="lockeds">
                <input
                  type="checkbox"
                  name="locked"
                  onChange={(e) => setCheck1(e.target.checked)}
                />
                <label>Locked</label>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ledger Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Ledger Name"
            name="ledger_name"
            className="error-validation"
            {...register("ledger_name", { required: true })}
            />
             {errors.ledger_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Type of Entry">
      <Form.Select aria-label="Floating label select example"
      name="type_of_entry"
      placeholder="Type of Entry"
      defaultValue=''
      {...register("type_of_entry", { required: false })}
      >
       <option value="">--None--</option><option value="Line Item Level">Line Item Level</option><option value="Header Level">Header Level</option>
      </Form.Select>
      {errors.type_of_entry && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>



      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Group Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Group Code"
            name="group_code"
            className="error-validation"
            {...register("group_code", { required: true })}
            />
             {errors.group_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sub Group Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Sub Group Code"
            name="sub_group_code"

            {...register("sub_group_code", { required: false })}
            />
             {errors.sub_group_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ledger Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Ledger Code"
            name="ledger_code"

            {...register("ledger_code", { required: false })}
            />
             {errors.ledger_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Subledger Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Subledger Code"
            name="subledger_code"

            {...register("subledger_code", { required: false })}
            />
             {errors.ledger_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"></Form.Group>
    <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sub SubLedger Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Sub SubLedger Code"
            name="sub_subledger_code"

            {...register("sub_subledger_code", { required: false })}
            />
             {errors.sub_subledger_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


           </Row>
           <p className="edit-cancel">
              <input type="submit" className="account-save" value="Save" />
              <Link to={"/account-master/list"}>
                <input type="submit" className="account-save" value="Cancel" />
              </Link>
            </p>
      <div className='contact-success'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default AddAccountmaster;
