import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { parse, format , isValid } from 'date-fns';
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";
const ViewaccountMaster = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };



  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };


  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [lenthData, setlenthData] = useState([]);
  const [historys, setHistory] = useState([]);
  const [opp, setOpp] = useState([]);
  const [bdate, setbirthDate]=useState('')
  const [accountNamedrop, setAccountnameDropdown]=useState('')

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken], lenthData);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/chart_account_master/${id}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      console.log(response)
      setDatas(response.data.chart_account_master_detail[0]);

    } catch (error) {
      console.error(error);
    }
  };



  const handleSaveEdit = async (date) => {


    const responseData={
      "saluation":datas.saluation,
      "first_name":datas.first_name,
      "last_name":datas.last_name,
      "birthdate":bdate?bdate:null,
      "title":datas.title,
      "account_name":accountNamedrop.value,
      "reports_to":datas.reports_to,
      "contact_email":datas.contact_email,
      "contact_mobile":datas.contact_mobile,
      "contact_owner":datas.contact_owner,
      "department":datas.department,
      "lead_source":datas.lead_source,
      "mail_street":datas.mail_street,
      "mail_postal_code":datas.mail_postal_code,
      "mail_city":datas.mail_city,
      "mail_state":datas.mail_state,
      "mail_country":datas.mail_country

    };
    console.log(JSON.stringify(responseData))

    fetch(`${API_URL}contact/update/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData();
        if(updatedData.msg==='contact updated successfully'){

          // fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
    }

  return (
    <div id="view-page">
      <Header />
      <>
            {/* {lenthData.length > 0 ? ( */}
      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
            Account Master
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Account Master / details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (

         <div className="tasks">

            <>

            <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p>
     <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Contact Information</h4>
        </div>


    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="price type name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="price type name"
              name="price_type_name"
              defaultValue={datas.price_type_name || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>

            </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="price description"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="price description"
            name="price_description"
            defaultValue={datas.price_description || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>



            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="price sr no"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="price sr no"
            name="price_sr_no"
             defaultValue={datas.price_sr_no || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>



     </Row>
     <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p> </>

         </div>
        ):(
          <>




         <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/price/price-list'}> Cancel</Link>
                  </span>

                  </div>

             <>
             <div className="">
               <div id="contracts">
                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                        <tr>
                         <td id="td-right">
                           <span>Chart of Account No
</span>
                         </td>
                         <td>{datas.chart_of_account_no}</td>
                         <td id="td-right">
                           <span>Owner
</span>
                         </td>
                         <td>{datas.owner}</td>

                       </tr>

                       <tr>
                         <td id="td-right">
                           <span>Account Type
</span>
                         </td>
                         <td>{datas.account_type}</td>
                         <td id="td-right">
                           <span>Locked
</span>
                         </td>
                         <td>{datas.locked}</td>

                       </tr>


                        <tr>
                         <td id="td-right">
                           <span>Acc Code	</span>
                         </td>
                         <td>{datas.acc_code}</td>
                         <td id="td-right">
                           <span>Acc Code	</span>
                         </td>
                         <td>{datas.acc_code}</td>
                       </tr>


                       <tr>
                         <td id="td-right">
                           <span>  Type of Entry	</span>
                         </td>
                         <td>{datas.type_of_entry}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span>Group Code
</span>
                         </td>
                         <td>{datas.group_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span> Sub Group Code		</span>
                         </td>
                         <td>{datas.sub_group_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span> Ledger Code	</span>
                         </td>
                         <td>{datas.ledger_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span> Subledger Code
</span>
                         </td>
                         <td>{datas.subledger_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>
                       <tr>
                         <td id="td-right">
                           <span> Sub SubLedger Code
</span>
                         </td>
                         <td>{datas.sub_subledger_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                      </tbody>
                   </table>



                 </div>
               <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/price/price-list'}> Cancel</Link>
                  </span>

                  </div>
               </div>
             </div>

            <div className="">
               <div id="contracts">


                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Cases</span>
                                      <span>
                                        <i className=""></i>
                                        New Case
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

               </div>
             </div>
           </>

         </div>

       </>
        )
}
      </div>
      {/* ):(
        <div className="tasks">
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
      )} */}
</>

      <Footer />
    </div>
  );
};

export default ViewaccountMaster;
