import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import Footer from '../Footer'
import $ from 'jquery';
import { API_URL } from "../config";

function Addaccount() {

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $(".contactAccount").css({"min-height":(window_height)+"px"})
     const [countries,setContries]=useState([]);
     useEffect(()=>{
      const data=require('./CountryData.json')
      setContries(data)
     })

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{
        const responseData={
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,
        account_record_type: data.account_record_type,
        region: data.region,
        billing_address: data.billing_address,
        phone: data.phone,
        country:data.country,
        shipping_address: data.shipping_address

      };
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

        fetch(`${API_URL}add/all/account/data`, {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())

                  .then((response) => {
                    $('.successDiv').html('<p class="result">Data added</p>');

                    setTimeout(() => {
                      $('.successDiv').hide();
                      reset();
                    }, 300);

                  })
                  console.log(responseData)
                }

        };

  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Account</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Account / Add Account</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
   <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Account Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Account Name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Account Name"
              name="account_name"
              {...register("account_name", { required: true })}
            />
            {errors.account_name && <span>Required Account Name</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Account Alias"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Account Alias"
              name="account_alias"
              {...register("account_alias", { required: true })}
            />
             {errors.account_alias && <span>Required Account Alias</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Website"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Website"
            name="website"
            {...register("website", { required: true })}
            />
             {errors.website && <span>Required Website</span>}
          </FloatingLabel>
        </Form.Group>



      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Address Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Region"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Region"
              name="region"
              {...register("region", { required: true })}
            />
             {errors.region && <span>Required Region</span>}
          </FloatingLabel>
        </Form.Group>
            <Form.Group
              as={Col}
              md="6"
            >


              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Country">
      <Form.Select aria-label="Floating label select example"
      name="country"
      placeholder="Select Country"
      defaultValue=""
      {...register("country", { required:true })}
      >
        <option value='' >Select Country</option>
         {
            countries.map((x)=>{
                return(
                    <option key={x.country}>{x.country}</option>
                )
            })
         }
      </Form.Select>
      {errors.country && <span>Required Country</span>}
    </FloatingLabel>

            </Form.Group>

<Form.Group
      as={Col}
      md="6"
    >


      <FloatingLabel controlId="floatingTextarea2" label="Billing Address">
        <Form.Control
          as="textarea"
          placeholder="Billing Address"
          style={{ height: '100px' }}
          name="billing_address"
          {...register("billing_address", { required: true })}
          />
           {errors.billing_address && <p id='text-area'>Required Billing Address</p>}
      </FloatingLabel>
    </Form.Group>

<Form.Group
      as={Col}
      md="6"
    >

      <FloatingLabel controlId="floatingTextarea2" label="Shipping Address">
        <Form.Control
          as="textarea"
          placeholder="Shipping Address"
          style={{ height: '100px' }}
          name="shipping_address"
          {...register("shipping_address", { required: true })}
          />
           {errors.shipping_address && <p id='text-area'>Required Shipping Address</p>}
      </FloatingLabel>
    </Form.Group>

    <Form.Group
      as={Col}
      md="6"
    >

      <FloatingLabel
        controlId="floatingInput"
        label="Phone 1"
        className="mb-3"
      >
        <Form.Control
         type="text"
          placeholder="phone"
          name="phone"
          {...register("phone", { required: true })}
          />
           {errors.phone && <span>Required Phone 1</span>}
      </FloatingLabel>
    </Form.Group>

    <Form.Group
      as={Col}
      md="6"
    >

      <FloatingLabel
        controlId="floatingInput"
        label="Phone 2"
        className="mb-3"
      >
        <Form.Control
         type="text"
          placeholder="Phone 2"
          name="phone2"
          {...register("phone2", { required: true })}
          />
           {errors.phone2 && <span>Required Phone 2</span>}
      </FloatingLabel>
    </Form.Group>
      </Row>
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default Addaccount;

