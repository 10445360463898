import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import Popup from 'reactjs-popup';
import { API_URL } from '../config';
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const ContractShippmentList = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);



  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/allcontract_shipments', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contractshipment;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };

    const navigate = useNavigate();
    const [formData, setFromdata] = useState({

      account_record: "",

    });


    const handleChange = (e) => {

      setFromdata({ ...formData, [e.target.name]: e.target.value });

    };

    const handleSubmit = (event) => {

      event.preventDefault();


      const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+'add/record', {
                method: "POST",
                body: JSON.stringify(formData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())

                .then((response) => {

                    console.log(response)
                    console.log(response.status)
                    const account_record_new = response.account_type;

                                    navigate("/shippment/add-contract-shippment", {

                                      state: { account_record: account_record_new },

                                    });
                  if (response.message === "record addedd successfully") {


                                  }
                })
              }

      };


  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Contract Shipment List </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / contract / Contract Shipment List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

      <Popup trigger={<button className="button add-accountrecord"> <FaPlus/> Add Contract Shipment </button>} modal>

    <form onSubmit={handleSubmit} className="add-accounts">
     <div className='form-group'>
     <label>Contract Shipment Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>

       <option >--Select Record Type--</option>
       <option value='Buyer - Long Term'>Buyer Long Term</option>
       <option value='Buyer - Spot'>Buyer Spot</option>
       <option value='Supplier - Long Term'>Supplier - Long Term</option>
       <option value='Supplier - Spot'>Supplier - Spot</option>

       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup>

      </div>
    </div>
  </div>
  <div class='tasks'>
  <table id='tables' className="">
    <thead>
    <tr>
      <th>Contract Ship Code</th>
      <th>Currency</th>
      <th>Exchange Rate</th>
      <th>Laycan Month</th>
      <th>Laycan From Date</th>
      <th>Laycan To Date</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
       <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
      <td><Link to={'/shipment/contract-shipment-details/'+x.id}><p id='owner'><span>{x.contract_ship_code}</span></p></Link></td>
      <td>{x.currency}</td>
      <td>{x.exchange_rate}</td>
      <td>{x.laycan_month}</td>
      <td>{x.laycan_from_date}</td>
      <td>{x.laycan_to_date}</td>
      <td><FaPencilAlt/> <FaTrashAlt/></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>
    </div> </div>
    <Footer/>
    </div>
  );
};

export default ContractShippmentList;