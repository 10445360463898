// import React from 'react';
// import axios from 'axios';

// function LogoutButton() {
  

//   const handleLogout = () => {
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('email');
//     localStorage.removeItem('uuid');
//     localStorage.removeItem('uuids');
//     localStorage.removeItem('product_id');
//     localStorage.removeItem('role');
//     window.location.href = "/";
//   };


//   return (
//     <p id='m-0'onClick={handleLogout}>Logout</p>
//   );
// }

// export default LogoutButton;
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AES, enc } from 'crypto-js';
import {useAuth} from './AuthProvider'


const LogoutButton = () => {
  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleLogout = async () => {
    logout()
  }

  return (
    <p id='m-0' onClick={handleLogout}>Logout</p>
  );
}

export default LogoutButton;