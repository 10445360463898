import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer'; 
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import { API_URL } from '../config';
import "react-data-table-component-extensions/dist/index.css";
import $ from "jquery";

function Search() {
  const location = useLocation();
  const { searchText } = location.state; 

  const [searchQuery, setSearchQuery] = useState('');
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchResults, setSearchResults] = useState({
    account_results: [],
    banks: [],
    buyer_product: [],
    contact_results: [],
    contract_results: [],
    contract_shipments: [],
    finance_config: [],
    opp_shipments: [],
    opportunity: [],
    orders: [],
    pay_recieve_detail: [],
    pay_recieve_lineitems: [],
    post_shipment: [],
    pre_shipment: [],
    price_books: [],
    price_items: [],
    products: [],
    quote_shipment: [],
    quotes: [],
    rw_pay_receipt_detail: [],
    rw_receipt_lineitem_results: [],
    rwood_company: [],
    rwood_users: [],
    tcf: []
  });

  const fetchSearchResults = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${API_URL}search?query=${searchQuery}`);
      console.log('API response:', response.data);
      setSearchResults(response.data);
      setLoading(false);
    } catch (error) {
    //   setError('Error fetching search results');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (searchQuery.trim() !== '') {
      fetchSearchResults();
    //   $("#table-1").DataTable();
    //   $("#table-2").DataTable();
    //   $("#table-3").DataTable();
    //   $("#table-4").DataTable();
    //   $("#table-5").DataTable();
    //   $("#table-6").DataTable();
    //   $("#table-7").DataTable();
    //   $("#table-8").DataTable();
    //   $("#table-9").DataTable();
    //   $("#table-10").DataTable();
    //   $("#table-11").DataTable();
    //   $("#table-12").DataTable();
    //   $("#table-13").DataTable();
    //   $("#table-14").DataTable();
    //   $("#table-15").DataTable();
    //   $("#table-16").DataTable();
    //   $("#table-17").DataTable();
    //   $("#table-18").DataTable();
    //   $("#table-19").DataTable();
    //   $("#table-20").DataTable();

    }
  };

//   useEffect(() => {
//     if (!loading) {
//       $("table[id^='table-']").each(function() {
//         if (!$.fn.dataTable.isDataTable(this)) {
//           $(this).DataTable();
//         }
//       });
//     }
//   }, [loading, searchResults]);
  useEffect(() => {
    if (searchText) {
      setSearchQuery(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    }
  }, [searchQuery]);

  const renderTable = (data, columns, tableId) => (
    <table border="1" cellPadding="5" className="table table-bordered" id={`table-${tableId}`}>
      <thead>
        <tr>
          {columns.map((col, index) => <th key={index}>{col.label}</th>)}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {columns.map((col, colIndex) => (
              <td key={colIndex}>
                {col.isLink ? (
                  <Link to={col.linkPath(item)}>{item[col.accessor]}</Link>
                ) : (
                  item[col.accessor]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const dataConfig = [
    {
      title: 'Account Results',
      key: 'account_results',
      columns: [
        { label: 'Account Code', accessor: 'account_code', isLink: true, linkPath: (item) => `/accounts/${item.id}` },
        { label: 'Account Name', accessor: 'account_name', isLink: false },
        { label: 'Account Alias', accessor: 'account_alias', isLink: false },
        { label: 'Company', accessor: 'company', isLink: false },
        { label: 'Origin', accessor: 'origin', isLink: false }
      ]
    },
    {
      title: 'Contract Results',
      key: 'contract_results',
      columns: [
        { label: 'Rwood Contract Number', accessor: 'rwood_contract_number', isLink: true, linkPath: (item) => `/contract/contract-details/${item.id}` },
        { label: 'Account Name', accessor: 'account_name', isLink: false },
        { label: 'Company', accessor: 'company', isLink: false },
        { label: 'Currency', accessor: 'currency', isLink: false }
      ]
    },
    {
      title: 'Opportunity',
      key: 'opportunity',
      columns: [
        { label: 'opportunity no', accessor: 'opportunity_no', isLink: true, linkPath: (item) => `/opportunity/opportunity-detail/${item.id}` },
        { label: 'Opportunity Name', accessor: 'opportunity_name', isLink: false },
        { label: 'account name', accessor: 'account_name', isLink: false },
        { label: 'Company', accessor: 'company', isLink: false },
        { label: 'Currency', accessor: 'currency', isLink: false }
      ]
    },
    {
      title: 'Orders',
      key: 'orders',
      columns: [
        { label: 'order no', accessor: 'order_no', isLink: true, linkPath: (item) => `/order/view-order-details/${item.id}` },
        { label: 'buyer full name', accessor: 'buyer_full_name', isLink: false },
        { label: 'Company', accessor: 'company', isLink: false },
        { label: 'Currency', accessor: 'currency', isLink: false },
        { label: 'exchange rate', accessor: 'exchange_rate', isLink: false }
      ]
    },
    {
      title: 'Post Shipment',
      key: 'post_shipment',
      columns: [
        { label: 'postshipment No', accessor: 'post_code_no', isLink: true, linkPath: (item) => `/postshipment/postshipment-details/${item.id}` },
        { label: 'Exchange Rate', accessor: 'exchange_rate', isLink: false },
        { label: 'Bl No', accessor: 'bl_no', isLink: false },
        { label: 'Currency', accessor: 'currency', isLink: false },
        { label: 'RW margin', accessor: 'rw_margin', isLink: false }
      ]
    },
    {
      title: 'Pre Shipment',
      key: 'pre_shipment',
      columns: [
        { label: 'preshipment no', accessor: 'pre_code_no', isLink: true, linkPath: (item) => `/shipment/preshipment-details/${item.id}` },
        { label: 'commodity', accessor: 'commodity', isLink: false },
        { label: 'interest', accessor: 'interest', isLink: false },
        { label: 'master lc quantity', accessor: 'master_lc_quantity', isLink: false },
        { label: 'price basis', accessor: 'price_basis', isLink: false }
      ]
    },
    {
      title: 'TCF',
      key: 'tcf',
      columns: [
        { label: 'tcf no', accessor: 'tcf_no', isLink: true, linkPath: (item) => `/tcf/tcf-details/${item.id}` },
        { label: 'rwood bank master', accessor: 'rwood_bank_master', isLink: false },
        { label: 'order', accessor: 'order', isLink: false },
        { label: 'laycan', accessor: 'laycan', isLink: false },
        { label: 'company', accessor: 'company', isLink: false }
      ]
    },
    {
      title: 'RW receipt lineitem results',
      key: 'rw_receipt_lineitem_results',
      columns: [
        { label: 'rw payment receipt', accessor: 'rw_payment_receipt', isLink: true, linkPath: (item) => `/shipment/preshipment-details/${item.id}` },
        { label: 'account', accessor: 'account', isLink: false },
        { label: 'rw payment receipt name', accessor: 'rw_payment_receipt_name', isLink: false },
        { label: 'line item', accessor: 'line_item', isLink: false },
        { label: 'order', accessor: 'order', isLink: false }
      ]
    },
    {
      title: 'Rw pay receipt detail',
      key: 'rw_pay_receipt_detail',
      columns: [
        { label: 'rw payment receipt', accessor: 'rw_payment_receipt', isLink: true, linkPath: (item) => `/shipment/preshipment-details/${item.id}` },
        { label: 'company', accessor: 'company', isLink: false },
        { label: 'account', accessor: 'account', isLink: false },
        { label: 'total amount', accessor: 'total_amount', isLink: false } 
      ]
    },
    {
      title: 'Quotes',
      key: 'quotes',
      columns: [
        { label: 'quote number', accessor: 'quote_number', isLink: true, linkPath: (item) => `/opportunity/quote-view/${item.id}` },
        { label: 'quote name', accessor: 'quote_name', isLink: false },
        { label: 'quantity MT', accessor: 'quantity_MT', isLink: false },
        { label: 'origin', accessor: 'origin', isLink: false } ,
        { label: 'currency', accessor: 'currency', isLink: false }
      ]
    },
    {
      title: 'pay recieve lineitems',
      key: 'pay_recieve_lineitems',
      columns: [
        { label: 'rw payment receivable lineitem', accessor: 'rw_payment_receivable_lineitem', isLink: true, linkPath: (item) => `/order/order-receivable-details/${item.id}` },
        { label: 'rw billing', accessor: 'rw_billing', isLink: false },
        { label: 'record type', accessor: 'record_type', isLink: false },
        { label: 'quantity', accessor: 'quantity', isLink: false } ,
        { label: 'line_item', accessor: 'line_item', isLink: false }
      ]
    },
    {
      title: 'pay recieve detail',
      key: 'pay_recieve_detail',
      columns: [
        { label: 'rw code no', accessor: 'rw_code_no', isLink: true, linkPath: (item) => `/order/order-receivable-details/${item.id}` },
        { label: 'rwood bank master', accessor: 'rwood_bank_master', isLink: false },
        { label: 'total amount', accessor: 'total_amount', isLink: false },
        { label: 'total balance due', accessor: 'total_balance_due', isLink: false } ,
        { label: 'total paid or received amount', accessor: 'total_paid_or_received_amount', isLink: false }
      ]
    },
    {
      title: 'Banks',
      key: 'banks',
      columns: [
        { label: 'bank code', accessor: 'bank_code', isLink: true, linkPath: (item) => `/bank/bank-details/${item.id}` },
        { label: 'rwood bank name', accessor: 'rwood_bank_name', isLink: false },
        { label: 'LC negotiation commission rate', accessor: 'lc_negotiation_or_commission_rate', isLink: false },
        { label: 'LC transfer rate advising cost', accessor: 'lc_transfer_rate_or_advising_cost', isLink: false } ,
        { label: 'LC type', accessor: 'lc_type', isLink: false }
      ]
    },
    {
      title: 'contract shipments',
      key: 'contract_shipments',
      columns: [
        { label: 'contract shipments', accessor: 'contract_ship_code', isLink: true, linkPath: (item) => `/shipment/contract-shipment-details/${item.id}` },
        { label: 'parent shipment', accessor: 'parent_shipment', isLink: false },
        { label: 'pricing', accessor: 'pricing', isLink: false },
        { label: 'quantity', accessor: 'quantity', isLink: false } ,
        { label: 'quantity type', accessor: 'quantity_type', isLink: false }
      ]
    },
    {
      title: 'opp shipments',
      key: 'opp_shipments',
      columns: [
        { label: 'opp ship code', accessor: 'opp_ship_code', isLink: true, linkPath: (item) => `/opportunity/oppshipment-detail/${item.id}` },
        { label: 'opportunity shipment', accessor: 'opportunity_shipment', isLink: false },
        { label: 'quantity from', accessor: 'quantity_from', isLink: false },
        { label: 'quantity to', accessor: 'quantity_to', isLink: false } ,
        { label: 'quantity', accessor: 'quantity', isLink: false }
      ]
    },
    {
      title: 'price books',
      key: 'price_books',
      columns: [
        { label: 'pricebook code', accessor: 'pricebook_code', isLink: true, linkPath: (item) => `/pricebook/pricebook-details/${item.id}` },
        { label: 'price book name', accessor: 'price_book_name', isLink: false },
        { label: 'projected GCNEWC', accessor: 'projected_GCNEWC', isLink: false },
        { label: 'projected HBA', accessor: 'projected_HBA', isLink: false } ,
        { label: 'actual HBA', accessor: 'actual_HBA', isLink: false }
      ]
    },
    {
      title: 'Quote Shipment',
      key: 'quote_shipment',
      columns: [
        { label: 'Quote shipment code', accessor: 'quote_shipment_code', isLink: true, linkPath: (item) => `/quote-shipment/detail/${item.id}` },
        { label: 'Quantity type', accessor: 'quote_shipment_quantity_type', isLink: false },
        { label: 'Opportunity shipment', accessor: 'quote_shipment_opportunity_shipment', isLink: false },
        { label: 'quote shipment quantity', accessor: 'quote_shipment_quantity', isLink: false } ,
        { label: 'Quote shipment laycan year from', accessor: 'quote_shipment_laycan_year_from', isLink: false }
      ]
    }
  ];

  const allAccessors = dataConfig.flatMap(config =>
    config.columns.map(column => column.accessor)
  );

  console.log(allAccessors);

  return (
    <>
      <Header />
      <div className="container" id='search-result'>
        <div className="recent">
          <h4>Recent Search</h4>
          <form ref={formRef} onSubmit={handleSearch} className="search">
            <i className="fa fa-search"></i>
            <input
              type="text"
              className="form-control"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
            />
            <button type="submit">Search</button>
          </form>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        <div className="search-table">
          {dataConfig.map((config, index) => (
            searchResults[config.key] && searchResults[config.key].length > 0 && (
              <div key={config.key}>
                <h2>{config.title}</h2>
                {renderTable(searchResults[config.key], config.columns, index + 1)}
              </div>
            )
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Search;
