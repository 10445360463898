import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
// import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import Select from 'react-select';
import { API_URL } from '../config';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { parse, format, isValid } from 'date-fns';
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { AES, enc } from 'crypto-js';

// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const ViewContractProduct = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const params = useParams();
  const ids = params.id;
  const [datas, setDatas] = useState([]);
  const [contractShipment, setContractShipment] = useState([]);
   const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [supplier, setSupplier] = useState([]);



  useEffect(() => {
    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/contract_productsbyID/` + ids,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response)
      const data = response.data.contract_products[0];
      setDatas(data)

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataSupplier1();
  }, [decryptedAccessToken]);

  const fetchDataSupplier1 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/supplier`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      setSupplier(data);
      // console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };


const [product,setProduct]=useState([])
useEffect(() => {

  fetchDataProduct();
}, [decryptedAccessToken]);

const fetchDataProduct = async () => {
  try {
    const response = await axios.get(`${API_URL}get/allproducts`, {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    console.log(data)
    setProduct(data);

  } catch (error) {
    console.error(error);
  }
};


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/allcontract_shipments`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response);
      // if (response.status === 200) {


        const data = response.data.contractshipment;
        setContractShipment(data)
        console.log(data);
              //  }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(datas);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(datas);

  };



  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "",
  });



  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [lenthData, setlenthData] = useState([]);
  const [accountNamedrop, setAccountnameDropdown] = useState('')

  // useEffect(() => {
  //   fetchData();
  // }, [decryptedAccessToken]);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${API_URL}get/contract_productsbyID/" + id,
  //       {
  //         headers: { Authorization: `Bearer ${decryptedAccessToken}` },
  //       }
  //     );

  //     const data = response.data[0].contact_details;

  //     // navigate("/accounts/" + response.account_id);
  //     // setTimeout(() => {
  //     //   //navigate("/accounts/2");
  //     //   reset2();
  //     // }, 2000);
  //     console.log(response)
  //     // const dropdownValue = response.data[0].contact_details.account_name;


  //     // setDatas(dropdownValue); ;
  //     setTimeout(() => {
  //       $("#quotes").DataTable();
  //       $("#history").DataTable();
  //     }, 10);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [defferece, setDefferece] = useState(null); // Initialize the state of the checkbox
  const [lockeds, setLockeds] = useState(null); // Initialize the state of the checkbox
  const check_box = datas.locked;
  const check_active = datas.fixed_carbon_by_difference;
  useEffect(() => {
    setLockeds(check_box); // Update lockeds with the value of tt
    setDefferece(check_active)
  }, [check_box, check_active]); // Run this effect whenever tt changes

  useEffect(() => {
    console.log("Value of lockeds:", lockeds); // Log lockeds
  }, [lockeds, defferece]); // Run

  const handleCheckboxChange = (e) => {
    setLockeds(e.target.checked);
  };


  const handleCheckboxActive = (e) => {
    setDefferece(e.target.checked);
  };

  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);




  setTimeout(() => {
    $("#quotes").DataTable();
    $("#history").DataTable();
  }, 10);
  const handleSaveEdit = async (date) => {

    const responseData={
      "contract_product_number":datas.contract_product_number,
      "contract":datas.contract,
      "product_master":datas.product_master,
      "supplier":datas.supplier,
      "vessel_type":datas.vessel_type,
      "no_of_orders":datas.no_of_orders,
      "order_quanty":parseFloat(datas.order_quanty),
      "locked":lockeds,

      "record_type":datas.record_type,
      "product_name":datas.product_name,
      "price_basis_GAR":parseFloat(datas.price_basis_GAR),
      "product":datas.product,
      "product_code":datas.product_code,
      "quantity":parseFloat(datas.quantity),
      "status":datas.status,
      "product_description":datas.product_description,
      "port_of_loading":datas.port_of_loading,
      "shipping_term":datas.shipping_term,
      "SO3_rejection":parseFloat(datas.SO3_rejection),
      "SO3_Typical":parseFloat(datas.SO3_Typical),
      "AI2O3_rejection":parseFloat(datas.AI2O3_rejection),"total_moisture_typical":parseFloat(datas.total_moisture_typical),
      "total_moisture_rejection":parseFloat(datas.total_moisture_rejection),
      "ash_typical_arb":parseFloat(datas.ash_typical_arb),
      "ash_rejection_arb":parseFloat(datas.ash_rejection_arb),
      "sulphur_typical_arb":parseFloat(datas.sulphur_typical_arb),
      "sulphur_rejection_arb":parseFloat(datas.sulphur_rejection_arb),
      "volatile_matter_typical_arb":parseFloat(datas.volatile_matter_typical_arb),
      "volatile_matter_rejection_arb":parseFloat(datas.volatile_matter_rejection_arb),
      "volatile_matter_maximum_arb":parseFloat(datas.volatile_matter_maximum_arb),
      "volatile_matter_minimum_arb":parseFloat(datas.volatile_matter_minimum_arb),
      "moisture_typical":parseFloat(datas.moisture_typical),
      "moisture_rejection":parseFloat(datas.moisture_rejection),
      "ash_typical":parseFloat(datas.ash_typical),
      "ash_rejection":parseFloat(datas.ash_rejection),
      "volatile_matter_typical":parseFloat(datas.volatile_matter_typical),
      "volatile_matter_rejection":parseFloat(datas.volatile_matter_rejection),
      "fixed_carbon_by_difference":defferece,
      "fixed_carbon_typical":parseFloat(datas.fixed_carbon_typical),
      "fixed_carbon_rejection":parseFloat(datas.fixed_carbon_rejection),
      "gross_as_received_typical":parseFloat(datas.gross_as_received_typical),
      "gross_as_received_rejection":parseFloat(datas.gross_as_received_rejection),
      "gross_air_dried_typical":parseFloat(datas.gross_air_dried_typical),
      "gross_air_dried_rejection":parseFloat(datas.gross_air_dried_rejection),
      "net_as_received_rejection":parseFloat(datas.net_as_received_rejection),
      "net_as_received_typical":parseFloat(datas.net_as_received_typical),
      "hgi_typical":parseFloat(datas.hgi_typical),
      "hgi_rejection":parseFloat(datas.hgi_rejection),
      "carbon_typical":parseFloat(datas.carbon_typical),
      "carbon_rejection":parseFloat(datas.carbon_rejection),
      "hydrogen_typical":parseFloat(datas.hydrogen_typical),
      "hydrogen_rejection":parseFloat(datas.hydrogen_rejection),
      "nitrogen_typical":parseFloat(datas.nitrogen_typical),
      "nitrogen_rejection":parseFloat(datas.nitrogen_rejection),
      "sulphur_typical":parseFloat(datas.sulphur_typical),
      "sulphur_rejection":parseFloat(datas.sulphur_rejection),
      "oxygen_typical":parseFloat(datas.oxygen_typical),
      "oxygen_rejection":parseFloat(datas.oxygen_rejection),
      "sulphur_typical_adb":parseFloat(datas.sulphur_typical_adb),
      "sulphur_rejection_adb":parseFloat(datas.sulphur_rejection_adb),
      "initial_typical":parseFloat(datas.initial_typical),
      "initial_rejection":parseFloat(datas.initial_rejection),
      "spherical_typical":parseFloat(datas.spherical_typical),
      "spherical_rejection":parseFloat(datas.spherical_rejection),
      "hemispherical_typical":parseFloat(datas.hemispherical_typical),
      "hemispherical_rejection":parseFloat(datas.hemispherical_rejection),
      "flow_typical":parseFloat(datas.flow_typical),
      "flow_rejection":parseFloat(datas.flow_rejection),
      "oxidising_initial_typical":parseFloat(datas.oxidising_initial_typical),
      "oxidising_initial_rejection":parseFloat(datas.oxidising_initial_rejection),
      "oxidising_spherical_typical":parseFloat(datas.oxidising_spherical_typical),
      "oxidising_spherical_rejection":parseFloat(datas.oxidising_spherical_rejection),
      "oxidising_hemispherical_typical":parseFloat(datas.oxidising_hemispherical_typical),
      "oxidising_hemispherical_rejection":parseFloat(datas.oxidising_hemispherical_rejection),
      "oxidising_flow_typical":parseFloat(datas.oxidising_flow_typical),
      "oxidising_flow_rejection":parseFloat(datas.oxidising_flow_rejection),
      "SiO2_Typical":parseFloat(datas.SiO2_Typical),
      "SiO2_rejection":parseFloat(datas.SiO2_rejection),
      "Al2O3_Typical":parseFloat(datas.Al2O3_Typical),
      "Al2O3_rejection":parseFloat(datas.Al2O3_rejection),
      "Fe2O3_Typical":parseFloat(datas.Fe2O3_Typical),
      "Fe2O3_rejection":parseFloat(datas.Fe2O3_rejection),
      "CaO_Typical":parseFloat(datas.CaO_Typical),
      "CaO_rejection":parseFloat(datas.CaO_rejection),
      "MgO_Typical":parseFloat(datas.MgO_Typical),
      "MgO_rejection":parseFloat(datas.MgO_rejection),
      "Mn3O4_Typical":parseFloat(datas.Mn3O4_Typical),
      "Mn3O4_rejection":parseFloat(datas.Mn3O4_rejection),
      "TiO2_Typical":parseFloat(datas.TiO2_Typical),
      "TiO2_rejection":parseFloat(datas.TiO2_rejection),
      "Na2O_Typical":parseFloat(datas.Na2O_Typical),
      "Na2O_rejection":parseFloat(datas.Na2O_rejection),
      "K2O_Typical":parseFloat(datas.K2O_Typical),
      "K2O_rejection":parseFloat(datas.K2O_rejection),
      "P2O5_Typical":parseFloat(datas.P2O5_Typical),
      "P2O5_rejection":parseFloat(datas.P2O5_rejection),
      "te_arsenic_as_typical_in_air_dried_coal":parseFloat(datas.te_arsenic_as_typical_in_air_dried_coal),
      "te_arsenic_as_rejection_in_air_dried_coal":parseFloat(datas.te_arsenic_as_rejection_in_air_dried_coal),
      "te_antimony_sb_typical_in_air_dried_coal":parseFloat(datas.te_antimony_sb_typical_in_air_dried_coal),
      "te_antimony_sb_rejection_in_air_dried_coal":parseFloat(datas.te_antimony_sb_rejection_in_air_dried_coal),
      "te_beryllium_be_typical_in_air_dried_coal":parseFloat(datas.te_beryllium_be_typical_in_air_dried_coal),
      "te_beryllium_be_rejection_in_air_dried_coal":parseFloat(datas.te_beryllium_be_rejection_in_air_dried_coal),
      "te_boron_b_typical_in_air_dried_coal":parseFloat(datas.te_boron_b_typical_in_air_dried_coal),
      "te_boron_b_rejection_in_air_dried_coal":parseFloat(datas.te_boron_b_rejection_in_air_dried_coal),
      "te_cadmium_cd_typical_in_air_dried_coal":parseFloat(datas.te_cadmium_cd_typical_in_air_dried_coal),
      "te_cadmium_cd_rejection_in_air_dried_coal":parseFloat(datas.te_cadmium_cd_rejection_in_air_dried_coal),
      "te_chromium_cr_typical_in_air_dried_coal":parseFloat(datas.te_chromium_cr_typical_in_air_dried_coal),
      "te_chromium_cr_rejection_in_air_dried_coal":parseFloat(datas.te_chromium_cr_rejection_in_air_dried_coal),
      "te_copper_cu_typical_in_air_dried_coal":parseFloat(datas.te_copper_cu_typical_in_air_dried_coal),
      "te_copper_cu_rejection_in_air_dried_coal":parseFloat(datas.te_copper_cu_rejection_in_air_dried_coal),
      "te_chlorine_cl_typical_in_air_dried_coal":parseFloat(datas.te_chlorine_cl_typical_in_air_dried_coal),
      "te_chlorine_cl_rejection_in_air_dried_coal":parseFloat(datas.te_chlorine_cl_rejection_in_air_dried_coal),
      "te_fluorine_f_typical_in_air_dried_coal":parseFloat(datas.te_fluorine_f_typical_in_air_dried_coal),
      "te_fluorine_f_rejection_in_air_dried_coal":parseFloat(datas.te_fluorine_f_rejection_in_air_dried_coal),
      "te_mercury_hg_typical_in_air_dried_coal":parseFloat(datas.te_mercury_hg_typical_in_air_dried_coal),
      "te_mercury_hg_rejection_in_air_dried_coal":parseFloat(datas.te_mercury_hg_rejection_in_air_dried_coal),
      "te_molybdenum_mo_typical_in_air_dried_coal":parseFloat(datas.te_molybdenum_mo_typical_in_air_dried_coal),
      "te_molybdenum_mo_rejection_in_air_dried_coal":parseFloat(datas.te_molybdenum_mo_rejection_in_air_dried_coal),
      "te_lead_pb_typical_in_air_dried_coal":parseFloat(datas.te_lead_pb_typical_in_air_dried_coal),
      "te_lead_pb_rejection_in_air_dried_coal":parseFloat(datas.te_lead_pb_rejection_in_air_dried_coal),
      "te_phosphorus_p_typical_in_air_dried_coal":parseFloat(datas.te_phosphorus_p_typical_in_air_dried_coal),
      "te_phosphorus_p_rejection_in_air_dried_coal":parseFloat(datas.te_phosphorus_p_rejection_in_air_dried_coal),
      "te_selenium_se_typical_in_air_dried_coal":parseFloat(datas.te_selenium_se_typical_in_air_dried_coal),
      "te_selenium_se_rejection_in_air_dried_coal":parseFloat(datas.te_selenium_se_rejection_in_air_dried_coal),
      "te_vanadium_v_typical_in_air_dried_coal":parseFloat(datas.te_vanadium_v_typical_in_air_dried_coal),
      "te_vanadium_v_rejection_in_air_dried_coal":parseFloat(datas.te_vanadium_v_rejection_in_air_dried_coal),
      "te_zinc_zn_typical_in_air_dried_coal":parseFloat(datas.te_zinc_zn_typical_in_air_dried_coal),
      "te_zinc_zn_rejection_in_air_dried_coal":parseFloat(datas.te_zinc_zn_rejection_in_air_dried_coal),
      //"te_arsenic_as_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_arsenic_as_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_antimony_sb_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_antimony_sb_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_boron_b_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_boron_b_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_cadmium_cd_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_cadmium_cd_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_chromium_cr_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_chromium_cr_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_copper_cu_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_copper_cu_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_chlorine_cl_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_chlorine_cl_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_fluorine_f_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_fluorine_f_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_mercury_hg_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_mercury_hg_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_molybdenum_mo_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_molybdenum_mo_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_lead_pb_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_lead_pb_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_phosphorus_p_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_phosphorus_p_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_selenium_se_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_selenium_se_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_vanadium_v_typical":parseFloat(datas.dsdsdsdsdsds),
      //"te_vanadium_v_rejection":parseFloat(datas.dsdsdsdsdsds),
      //"te_zinc_zn_typical":parseFloat(datas.dsdsdsdsdsds),
      "te_zinc_zn_rejection":parseFloat(datas.size_0_to_50mm_typical),
      "size_0_to_50mm_typical":parseFloat(datas.size_0_to_50mm_typical),
      "size_0_to_50mm_rejection":parseFloat(datas.size_0_to_50mm_rejection),
      "above_0_to_50mm_typical":parseFloat(datas.above_0_to_50mm_typical),
      "above_0_to_50mm_rejection":parseFloat(datas.above_0_to_50mm_rejection),
      "under_2mm_typical":parseFloat(datas.under_2mm_typical),
      "under_2mm_rejection":parseFloat(datas.under_2mm_rejection),
      "size_above_2mm_typical":parseFloat(datas.size_above_2mm_typical),
      "size_above_2mm_rejection":parseFloat(datas.size_above_2mm_rejection),
      "testing_method":datas.testing_method,
      };
    console.log(JSON.stringify(responseData))

    axios.put(`${API_URL}update/contract_products/${ids}`, responseData, {
      headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json'
      }
  })
  .then((response) => {
      const updatedData = response.data;
      console.log(updatedData);

      // if (updatedData.success === 'contact updated successfully') {
      //     // fetchData();
      // }

      // setDatas(response);
      setIsEditMode(false);
  })
  .catch((error) => {
      console.error(error);
  });
  }

  return (
    <div id="view-page">
      <Header />
      <>
        {/* {lenthData.length > 0 ? ( */}
          <div className="contactAccount">
            <div className="page-header btn-margin" id="add-account">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Contacts Product Details
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard / Contact Product / details
                  </li>
                </ol>
              </div>
            </div>
            {datas.record_type==="Buyer - Spot" ? (
<div>
            {isEditMode ? (

              <div className="tasks">

                <>
                  {/* {console} */}

                  <p className='edit-btn'>
                    <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
                    <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


                  </p>

          <Row className="mb-3">
        <h4 className='heading'>Information</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="contract product number"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="contract product number"
                      name="contract_product_number"
                        defaultValue={datas.contract_product_number }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <label>Locked</label>
                  <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3'
                  />
                </Form.Group>
                  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="contract"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="contract"
                      name="contract"
                        defaultValue={datas.contract }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="record type"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="record type"
                      name="record_type"
                        defaultValue={datas.record_type }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="contract shipment"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="contract_shipment"
                  placeholder="contract shipment"

                 defaultValue={datas.contract_shipment }
                        onChange={handleChangeInput}>
                  <option value="">Select</option>


{contractShipment.length>0?(
        <>
        {contractShipment.map((x) => {
                    return (
                      <option defaultValue={x.contract_shipment}>
                        {x.contract_ship_code}
                      </option>
                    );
                  })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="product name"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="product name"
                      name="product_name"
                        defaultValue={datas.product_name }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Product Master">
  <Form.Select aria-label="Floating label select example"
  name="product_master"
  placeholder="Product Master"
  defaultValue={datas.product_master}
  onChange={handleChangeInput}
  >
    <option value='' >Select</option>
     {
        product.map((x)=>{
            return(
                <option value={x.product_code}>{x.product_code}</option>
            )
        })
     }
  </Form.Select>
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingSelect"
                      className="dropDown"
                      label="Supplier"
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        name="supplier"
                        placeholder="Supplier"
                        defaultValue={datas.supplier || ""}
                        onChange={handleChangeInput}
                      >
                        <option value="">Select</option>
                        {supplier.map((x) => {
                          return (
                            <option value={x.account_name}>
                              {x.account_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>

        </Row>
        <Row className="mb-3">
        <h4 className='heading'>Product Details</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Price Basis GAR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Price Basis GAR"
                      name="price_basis_GAR"
                        defaultValue={datas.price_basis_GAR }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Port of Loading"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Port of Loading"
                      name="port_of_loading"
                        defaultValue={datas.port_of_loading }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Product Description"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Product Description"
                      name="product_description"
                        defaultValue={datas.product_description }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="shipping term"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="shipping_term"
                 defaultValue={datas.shipping_term }
                        onChange={handleChangeInput}
                ><option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
        </Row>
          <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>

                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Typical	"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Moisture Typical"
                      name="total_moisture_typical"
                        defaultValue={datas.total_moisture_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Rejection	"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Moisture Rejection	"
                      name="total_moisture_rejection"
                        defaultValue={datas.total_moisture_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Content Typical ARB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Ash Content Typical ARB"
                      name="ash_typical_arb"
                        defaultValue={datas.ash_typical_arb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash rejection arb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ash rejection arb"
                      name="ash_rejection_arb"
                        defaultValue={datas.ash_rejection_arb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Typical ARB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Volatile Matter Typical ARB	"
                      name="volatile_matter_typical_arb"
                        defaultValue={datas.volatile_matter_typical_arb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Rejection ARB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Volatile Matter Rejection ARB"
                      name="volatile_matter_rejection_arb"
                        defaultValue={datas.volatile_matter_rejection_arb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Sulphur Typical ARB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Sulphur Typical ARB"
                      name="sulphur_typical_arb"
                        defaultValue={datas.sulphur_typical_arb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Sulphur Rejection ARB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Sulphur Rejection ARB"
                      name="sulphur_rejection_arb"
                        defaultValue={datas.sulphur_rejection_arb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>

        </Row>
        <Row className="mb-3">
        <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Inherent Moisture Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Inherent Moisture Typical "
                      name="moisture_typical"
                        defaultValue={datas.moisture_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Inherent Moisture Rejection   "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Inherent Moisture Rejection "
                      name="moisture_rejection"
                        defaultValue={datas.moisture_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Content Typical  "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Ash Content Typical   "
                      name="ash_typical"
                        defaultValue={datas.ash_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Content Rejection  "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Ash Content Rejection"
                      name="ash_rejection"
                        defaultValue={datas.ash_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Typical ADB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Volatile Matter Typical ADB"
                      name="volatile_matter_minimum_arb"
                        defaultValue={datas.volatile_matter_minimum_arb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Rejection ADB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Volatile Matter Rejection ADB "
                      name="volatile_matter_maximum_arb"
                        defaultValue={datas.volatile_matter_maximum_arb
}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Typical ADB "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Fixed Carbon Typical ADB"
                      name="fixed_carbon_typical"
                        defaultValue={datas.fixed_carbon_typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Rejection ADB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Fixed Carbon Rejection ADB"
                      name="fixed_carbon_rejection"
                        defaultValue={datas.fixed_carbon_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

                  <input
                    type='checkbox'
                    name='fixed_carbon_by_difference'
                    checked={defferece} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxActive} // Handle the change event
                    className='mx-3'
                  />
                   <label>Fixed Carbon By Difference</label>
                </Form.Group>

        </Row>

        <Row className="mb-3">
        <h4 className='heading'>CALORIFIC VALUE, kcal / kg</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Received Typical "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross As Received Typical  "
                      name="gross_as_received_typical"
                        defaultValue={datas.gross_as_received_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Received Rejection   "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross As Received Rejection "
                      name="gross_as_received_rejection"
                        defaultValue={datas.gross_as_received_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical "
                      name="gross_air_dried_typical"
                        defaultValue={datas.gross_air_dried_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Rejection "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Rejection  "
                      name="gross_air_dried_rejection"
                        defaultValue={datas.gross_air_dried_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Received Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Net As Received Typical "
                      name="net_as_received_typical"
                        defaultValue={datas.net_as_received_typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Received Rejection"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Net As Received Rejection "
                      name="net_as_received_rejection"
                        defaultValue={datas.net_as_received_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>HGI</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="HGI Typical"
                      name="hgi_typical"
                        defaultValue={datas.hgi_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Rejection"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="HGI Rejection"
                      name="hgi_rejection"
                        defaultValue={datas.hgi_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>

        <Row className="mb-3">
        <h4 className='heading'>ULTIMATE ANALYSIS, %dry ash free basis</h4>

 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Typical "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon Typical   "
                      name="carbon_typical"
                        defaultValue={datas.carbon_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon rejection"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon rejection "
                      name="carbon_rejection"
                        defaultValue={datas.carbon_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Typical  "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Hydrogen Typical    "
                      name="hydrogen_typical"
                        defaultValue={datas.hydrogen_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Rejection"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Hydrogen Rejection"
                      name="hydrogen_rejection"
                        defaultValue={datas.hydrogen_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Typical "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Nitrogen Typical"
                      name="nitrogen_typical"
                        defaultValue={datas.nitrogen_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen rejection"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen rejection"
                      name="nitrogen_rejection"
                        defaultValue={datas.nitrogen_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Typical "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Oxygen Typical   "
                      name="oxygen_typical"
                        defaultValue={datas.oxygen_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen rejection"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen rejection "
                      name="oxygen_rejection"
                        defaultValue={datas.oxygen_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>

        <Row className="mb-3">
        <h4 className='heading'>SULPHUR, %air dried basis</h4>

        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical ADB"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Sulphur Typical ADB  "
                      name="sulphur_typical_adb"
                        defaultValue={datas.sulphur_typical_adb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Rejection ADB"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Sulphur Rejection ADB"
                      name="sulphur_rejection_adb"
                        defaultValue={datas.sulphur_rejection_adb }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, DEG(C) Reducing </h4>

 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Deformation Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Initial Deformation Typical  "
                      name="initial_typical"
                        defaultValue={datas.initial_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Deformation Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Initial Deformation Rejection"
                      name="initial_rejection"
                        defaultValue={datas.initial_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Spherical Typical "
                      name="spherical_typical"
                        defaultValue={datas.spherical_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Spherical Rejection"
                      name="spherical_rejection"
                        defaultValue={datas.spherical_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Hemispherical Typical"
                      name="hemispherical_typical"
                        defaultValue={datas.hemispherical_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical rejection "
                      name="hemispherical_rejection"
                        defaultValue={datas.hemispherical_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Flow Typical  "
                      name="flow_typical"
                        defaultValue={datas.flow_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="flow rejection"
                      name="flow_rejection"
                        defaultValue={datas.flow_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Oxidizing</h4>

        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation Typical"
                      name="oxidising_initial_typical"
                        defaultValue={datas.oxidising_initial_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation Rejection"
                      name="oxidising_initial_rejection"
                        defaultValue={datas.oxidising_initial_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemisperical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Hemisperical Typical"
                      name="oxidising_hemispherical_typical"
                        defaultValue={datas.oxidising_hemispherical_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemisperical Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Hemisperical Rejection "
                      name="oxidising_hemispherical_rejection"
                        defaultValue={datas.oxidising_hemispherical_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Spherical Typical "
                      name="oxidising_spherical_typical"
                        defaultValue={datas.oxidising_spherical_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Spherical Rejection"
                      name="oxidising_spherical_rejection"
                        defaultValue={datas.oxidising_spherical_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Flow Typical   "
                      name="oxidising_flow_typical"
                        defaultValue={datas.oxidising_flow_typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Flow Rejection"
                      name="oxidising_flow_rejection"
                        defaultValue={datas.oxidising_flow_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>

        <Row className="mb-3">
        <h4 className='heading'>ASH ANALYSIS, %dry basis in ash</h4>

        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SiO2 Typical"
                      name="SiO2_Typical"
                        defaultValue={datas.SiO2_Typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>

               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SiO2 Rejection"
                      name="SiO2_rejection"
                        defaultValue={datas.SiO2_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="AI2O3 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="AI2O3 Typical"
                      name="AI2O3_Typical"
                        defaultValue={datas.AI2O3_Typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="AI2O3 Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="AI2O3 Rejection"
                      name="AI2O3_rejection"
                        defaultValue={datas.AI2O3_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Fe2O3 Typical"
                      name="Fe2O3_Typical"
                        defaultValue={datas.Fe2O3_Typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Fe2O3 Rejection"
                      name="Fe2O3_rejection"
                        defaultValue={datas.Fe2O3_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="CaO Typical"
                      name="CaO_Typical"
                        defaultValue={datas.CaO_Typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="CaO Rejection  "
                      name="CaO_rejection"
                        defaultValue={datas.CaO_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>

               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="P2O5 Typical"
                      name="P2O5_Typical"
                        defaultValue={datas.P2O5_Typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="P2O5 Rejection"
                      name="P2O5_rejection"
                        defaultValue={datas.P2O5_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SO3 Typical"
                      name="SO3_Typical"
                        defaultValue={datas.SO3_Typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SO3 Rejection"
                      name="SO3_rejection"
                        defaultValue={datas.SO3_rejection }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>

               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="MgO Typical"
                      name="MgO_Typical"
                        defaultValue={datas.MgO_Typical }
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="MgO Rejection "
                      name="MgO_rejection"
                        defaultValue={datas.MgO_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
               <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TiO2 Typical"
                      name="TiO2_rejection"
                        defaultValue={datas.TiO2_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TiO2 Rejection"
                      name="TiO2_rejection"
                        defaultValue={datas.TiO2_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="NA2O Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="NA2O Typical"
                      name="NA2O_Typical"
                        defaultValue={datas.NA2O_Typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="NA2O Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="NA2O Rejection  "
                      name="NA2O_rejection"
                        defaultValue={datas.NA2O_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="K2O Typical "
                      name="K2O_Typical"
                        defaultValue={datas.K2O_Typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="K2O Rejection"
                      name="K2O_rejection"
                        defaultValue={datas.K2O_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="KMn3O4 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="KMn3O4 Typical"
                      name="KMn3O4_Typical"
                        defaultValue={datas.KMn3O4_Typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>    <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mn3O4 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Mn3O4 Rejection"
                      name="Mn3O4_rejection"
                        defaultValue={datas.Mn3O4_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>TRACE ELEMENT, ppm Air Dried in Coal
        </h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Antimony Sb Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Antimony Sb Typical"
                      name="te_antimony_sb_typical_in_air_dried_coal"
                        defaultValue={datas.te_antimony_sb_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Antimony Sb Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Antimony Sb Rejection "
                      name="te_antimony_sb_rejection_in_air_dried_coal"
                        defaultValue={datas.te_antimony_sb_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Arsenic As Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Arsenic As Typical "
                      name="te_arsenic_as_typical_in_air_dried_coal"
                        defaultValue={datas.te_arsenic_as_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Arsenic As Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Arsenic As Rejection  "
                      name="te_arsenic_as_rejection_in_air_dried_coal"
                        defaultValue={datas.te_arsenic_as_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Beryllium Be Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Beryllium Be Typical  "
                      name="te_beryllium_be_typical_in_air_dried_coal"
                        defaultValue={datas.te_beryllium_be_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Beryllium Be Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Beryllium Be Rejection"
                      name="te_beryllium_be_rejection_in_air_dried_coal"
                        defaultValue={datas.te_beryllium_be_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Boron B Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Boron B Typical"
                      name="te_boron_b_typical_in_air_dried_coal"
                        defaultValue={datas.te_boron_b_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Boron B Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Boron B Rejection  "
                      name="te_boron_b_rejection_in_air_dried_coal"
                        defaultValue={datas.te_boron_b_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Cadmium Cd Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Cadmium Cd Typical "
                      name="te_cadmium_cd_typical_in_air_dried_coal"
                        defaultValue={datas.te_cadmium_cd_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Cadmium Cd Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Cadmium Cd Rejection  "
                      name="te_cadmium_cd_rejection_in_air_dried_coal"
                        defaultValue={datas.te_cadmium_cd_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chromium Cr Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chromium Cr Typical   "
                      name="te_chromium_cr_typical_in_air_dried_coal"
                        defaultValue={datas.te_chromium_cr_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chromium Cr Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chromium Cr Rejection "
                      name="te_chromium_cr_rejection_in_air_dried_coal"
                        defaultValue={datas.te_chromium_cr_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chlorine Cl Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chlorine Cl Typical   "
                      name="te_chlorine_cl_typical_in_air_dried_coal"
                        defaultValue={datas.te_chlorine_cl_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chromium Cr Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chromium Cr Rejection "
                      name="te_chlorine_cl_rejection_in_air_dried_coal"
                        defaultValue={datas.te_chlorine_cl_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Copper Cu Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Copper Cu Typical  "
                      name="te_copper_cu_typical_in_air_dried_coal"
                        defaultValue={datas.te_copper_cu_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Copper Cu Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Copper Cu Rejection   "
                      name="te_copper_cu_rejection_in_air_dried_coal"
                        defaultValue={datas.te_copper_cu_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Fluorine F Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Fluorine F Typical "
                      name="te_fluorine_f_typical_in_air_dried_coal"
                        defaultValue={datas.te_fluorine_f_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Fluorine F Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Fluorine F Rejection  "
                      name="te_fluorine_f_rejection_in_air_dried_coal"
                        defaultValue={datas.te_fluorine_f_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Lead Pb Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Lead Pb Typical "
                      name="te_lead_pb_typical_in_air_dried_coal"
                        defaultValue={datas.te_lead_pb_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Lead Pb Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Lead Pb Rejection  "
                      name="te_lead_pb_rejection_in_air_dried_coal"
                        defaultValue={datas.te_lead_pb_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Mercury Hg Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Mercury Hg Typical "
                      name="te_mercury_hg_typical_in_air_dried_coal"
                        defaultValue={datas.te_mercury_hg_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Mercury Hg Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Mercury Hg Rejection  "
                      name="te_mercury_hg_rejection_in_air_dried_coal"
                        defaultValue={datas.te_mercury_hg_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Molybdenum Mo Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Molybdenum Mo Typical "
                      name="te_molybdenum_mo_typical_in_air_dried_coal"
                        defaultValue={datas.te_molybdenum_mo_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Molybdenum Mo Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Molybdenum Mo Rejection  "
                      name="te_molybdenum_mo_rejection_in_air_dried_coal"
                        defaultValue={datas.te_molybdenum_mo_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Phosporous P Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Phosporous P Typical  "
                      name="te_phosphorus_p_typical_in_air_dried_coal"
                        defaultValue={datas.te_phosphorus_p_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Phosporous P Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Phosporous P Rejection   "
                      name="te_phosphorus_p_rejection_in_air_dried_coal"
                        defaultValue={datas.te_phosphorus_p_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Selenium Se Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Selenium Se Typical   "
                      name="te_selenium_se_typical_in_air_dried_coal"
                        defaultValue={datas.te_selenium_se_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Selenium Se Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Selenium Se Rejection "
                      name="te_selenium_se_rejection_in_air_dried_coal"
                        defaultValue={datas.te_selenium_se_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Vanadium V Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Vanadium V Typical "
                      name="te_vanadium_v_typical_in_air_dried_coal"
                        defaultValue={datas.te_vanadium_v_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Vanadium V Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Vanadium V Rejection  "
                      name="te_vanadium_v_rejection_in_air_dried_coal"
                        defaultValue={datas.te_vanadium_v_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Zinc Zn Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Zinc Zn Typical "
                      name="te_zinc_zn_typical_in_air_dried_coal"
                        defaultValue={datas.te_zinc_zn_typical_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Zinc Zn Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Zinc Zn Rejection  "
                      name="te_zinc_zn_rejection_in_air_dried_coal"
                        defaultValue={datas.te_zinc_zn_rejection_in_air_dried_coal}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>Sizing, %</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size (0-50 mm) Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size (0-50 mm) Typical"
                      name="size_0_to_50mm_typical"
                        defaultValue={datas.size_0_to_50mm_typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size (0-50 mm) Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size (0-50 mm) Rejection "
                      name="size_0_to_50mm_rejection"
                        defaultValue={datas.size_0_to_50mm_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 50 mm Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Above 50 mm Typical   "
                      name="above_0_to_50mm_typical"
                        defaultValue={datas.above_0_to_50mm_typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 50 mm Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Above 50 mm Rejection "
                      name="above_0_to_50mm_rejection"
                        defaultValue={datas.above_0_to_50mm_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2 mm Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Under 2 mm Typical "
                      name="under_2mm_typical"
                        defaultValue={datas.under_2mm_typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2 mm Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Under 2 mm Rejection  "
                      name="under_2mm_rejection"
                        defaultValue={datas.under_2mm_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size Above 2 mm Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size Above 2 mm Typical  "
                      name="size_above_2mm_typical"
                        defaultValue={datas.size_above_2mm_typical}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size Above 2 mm Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size Above 2 mm Rejection   "
                      name="size_above_2mm_rejection"
                        defaultValue={datas.size_above_2mm_rejection}
                        onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>Testing Method</h4>
        <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Testing Method"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="testing_method"
                 defaultValue={datas.testing_method }
                        onChange={handleChangeInput}
                >
                  <option defaultValue="">Select </option>
                  <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                    According to ASTM Standard Method Unless Otherwise Stated
                  </option>
                  <option defaultValue="According to ISO Methods">
                    According to ISO Methods
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                    According to ISO Methods Except HGI, Trace Elements and Ash
                    Analysis to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI to ASTM">
                    According to ISO Methods Except HGI to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                    According to ISO Methods, Except HGI and Ash Analysis to
                    ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                    According to ISO Methods, Except HGI as per ASTM Test Method
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
        </Row>
         <p className='edit-btn'>
                    <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
                    <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


                  </p> </>

              </div>
            ) : (
              <>




                <div className="tasks">
                  <div className="edit-delete">
                    <span id="edit" onClick={handleEditClick}>
                      Edit
                    </span>
                    <span>
                      <Link to={'/contract/create-contract-list'}> Cancel</Link>
                    </span>

                  </div>

               <div className="col-md-12" id='adatas'>
                <h4 className="heading">Contract Product Detail</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Contract Product Number
                      </span> </td>
                      <td>{datas.contract_product_number}</td>
                      <td id="td-right"><span>Locked</span> </td>
                      <td> <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3' disabled={isButtonEnabled}
                  /></td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Contract</span> </td>
                      <td><Link to={`/contract/contract-details/${datas.contract_id}`}>{datas.contract}</Link></td>
                      <td id="td-right"><span>Record Type
                      </span> </td>
                      <td>{datas.record_type}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Contract Shipment
                      </span> </td>
                      <td><Link to={`/shipment/contract-shipment-details/${datas.contract_shipment_id}`}>{datas.contract_shipment}</Link></td>
                      <td id="td-right"><span>Product Name
                      </span> </td>
                      <td>{datas.product_name}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Product Master
                      </span> </td>
                      <td><Link to={`/productmaster/product-detail/${datas.product_master_id}`}>{datas.product_master}</Link></td>
                      <td id="td-right"><span>Supplier</span> </td>
                      <td>{datas.supplier}</td>

                    </tr>
                    </tbody>
                    </table>
                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">Product Details</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Price Basis GAR

                      </span> </td>
                      <td>{datas.price_basis_GAR}</td>
                      <td id="td-right"><span>Port of Loading
                      </span> </td>
                      <td>{datas.port_of_loading}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Product Description

                      </span> </td>
                      <td>{datas.product_description}</td>
                      <td id="td-right"><span>Shipping Term
                      </span> </td>
                      <td>{datas.shipping_term}</td>

                    </tr>

                    </tbody>
                    </table>
                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">Product Details</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Total Moisture Typical

                      </span> </td>
                      <td>{datas.total_moisture_typical}</td>
                      <td id="td-right"><span>Total Moisture Rejection
                      </span> </td>
                      <td>{datas.total_moisture_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Ash Content Typical ARB

                      </span> </td>
                      <td>{datas.ash_typical_arb}</td>
                      <td id="td-right"><span>Ash Content Rejection ARB
                      </span> </td>
                      <td>{datas.ash_rejection_arb}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Volatile Matter Typical ARB

                      </span> </td>
                      <td>{datas.volatile_matter_typical_arb}</td>
                      <td id="td-right"><span>Volatile Matter Rejection ARB
                      </span> </td>
                      <td>{datas.volatile_matter_rejection_arb}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Total Sulphur Typical ARB

                      </span> </td>
                      <td>{datas.sulphur_typical_arb}</td>
                      <td id="td-right"><span>Total Sulphur Rejection ARB
                      </span> </td>
                      <td>{datas.sulphur_rejection_arb}</td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">Proximate Analysis %Air Dried Basis</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Inherent Moisture Typical

                      </span> </td>
                      <td>{datas.moisture_typical}</td>
                      <td id="td-right"><span>Inherent Moisture Rejection
                      </span> </td>
                      <td>{datas.moisture_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Ash Content Typical

                      </span> </td>
                      <td>{datas.ash_typical}</td>
                      <td id="td-right"><span>Ash Content Rejection
                      </span> </td>
                      <td>{datas.ash_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Volatile Matter Typical ADB

                      </span> </td>
                      <td>{datas.volatile_matter_minimum_arb}</td>
                      <td id="td-right"><span>Volatile Matter Rejection ADB
                      </span> </td>
                      <td>{datas.volatile_matter_maximum_arb}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Fixed Carbon Typical ADB

                      </span> </td>
                      <td>{datas.fixed_carbon_typical}</td>
                      <td id="td-right"><span>Fixed Carbon Rejection ADB
                      </span> </td>
                      <td>{datas.fixed_carbon_rejection}</td>

                    </tr>

                    <tr>
                      <td id="td-right"><span>Fixed Carbon By Difference

                      </span> </td>
                      <td><input
                   type='checkbox'
                   name='fixed_carbon_by_difference'
                   checked={defferece} // Use the state variable to determine if the checkbox is checked
                   onChange={handleCheckboxActive} // Handle the change event
                   className='mx-3' disabled={isButtonEnabled}
                 /></td>
                      <td id="td-right">
                        {/* <span>Locdsdsdsked</span>  */}
                        </td>
                      <td>
                        {/* {datas.locked} */}

                      </td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">CALORIFIC VALUE, kcal / kg</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Gross As Received Typical
                      </span> </td>
                      <td>{datas.gross_as_received_typical}</td>
                      <td id="td-right"><span>Gross As Received Rejection
                      </span> </td>
                      <td>{datas.gross_as_received_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Gross Air Dried Typical

                      </span> </td>
                      <td>{datas.gross_air_dried_typical}</td>
                      <td id="td-right"><span>Gross Air Dried Rejection
                      </span> </td>
                      <td>{datas.gross_air_dried_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Net As Received Typical

                      </span> </td>
                      <td>{datas.net_as_received_typical}</td>
                      <td id="td-right"><span>Net As Received Rejection
                      </span> </td>
                      <td>{datas.net_as_received_rejection}</td>

                    </tr>

                    </tbody>
                    </table>
                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">HGI</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>HGI Typical
                      </span> </td>
                      <td>{datas.hgi_typical}</td>
                      <td id="td-right"><span>HGI Rejection
                      </span> </td>
                      <td>{datas.hgi_rejection}</td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">ULTIMATE ANALYSIS, %dry ash free basis</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Carbon Typical

                      </span> </td>
                      <td>{datas.carbon_typical}</td>
                      <td id="td-right"><span>Carbon Rejection
                      </span> </td>
                      <td>{datas.carbon_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Hydrogen Typical

                      </span> </td>
                      <td>{datas.hydrogen_typical}</td>
                      <td id="td-right"><span>Hydrogen Rejection
                      </span> </td>
                      <td>{datas.hydrogen_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Nitrogen Typical

                      </span> </td>
                      <td>{datas.nitrogen_typical}</td>
                      <td id="td-right"><span>Nitrogen Rejection
                      </span> </td>
                      <td>{datas.nitrogen_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Oxygen Typical

                      </span> </td>
                      <td>{datas.oxygen_typical}</td>
                      <td id="td-right"><span>Oxygen Rejection
                      </span> </td>
                      <td>{datas.oxygen_rejection}</td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">SULPHUR, %air dried basis</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Sulphur Typical ADB

                      </span> </td>
                      <td>{datas.sulphur_typical_adb}</td>
                      <td id="td-right"><span>Sulphur Rejection ADB
                      </span> </td>
                      <td>{datas.sulphur_rejection_adb}</td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">ASH FUSION TEMPERATURE, DEG(C) Reducing</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Initial Deformation Typical

                      </span> </td>
                      <td>{datas.initial_typical}</td>
                      <td id="td-right"><span>Initial Deformation Rejection
                      </span> </td>
                      <td>{datas.initial_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Spherical Typical

                      </span> </td>
                      <td>{datas.spherical_typical}</td>
                      <td id="td-right"><span>Spherical Rejection
                      </span> </td>
                      <td>{datas.spherical_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Hemispherical Typical

                      </span> </td>
                      <td>{datas.hemispherical_typical}</td>
                      <td id="td-right"><span>Hemispherical Rejection
                      </span> </td>
                      <td>{datas.hemispherical_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Flow Typical

                      </span> </td>
                      <td>{datas.flow_typical}</td>
                      <td id="td-right"><span>Flow Rejection
                      </span> </td>
                      <td>{datas.flow_rejection}</td>

                    </tr>

                    </tbody>
                    </table>


                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">ASH FUSION TEMPERATURE, DEG (C) Oxidizing</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Oxidizing Initial Deformation Typical
                      </span> </td>
                      <td>{datas.oxidising_initial_typical}</td>
                      <td id="td-right"><span>Oxidizing Initial Deformation Rejection
                      </span> </td>
                      <td>{datas.oxidising_initial_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Oxidizing Hemisperical Typical

                      </span> </td>
                      <td>{datas.oxidising_hemispherical_typical}</td>
                      <td id="td-right"><span>Oxidizing Hemisperical Rejection
                      </span> </td>
                      <td>{datas.oxidising_hemispherical_rejection}</td>

                    </tr>

                    <tr>
                      <td id="td-right"><span>Oxidizing Spherical Typical

                      </span> </td>
                      <td>{datas.oxidising_spherical_typical}</td>
                      <td id="td-right"><span>Oxidizing Spherical Rejection
                      </span> </td>
                      <td>{datas.oxidising_spherical_rejection}</td>

                    </tr>

                    <tr>
                      <td id="td-right"><span>Oxidizing Flow Typical

                      </span> </td>
                      <td>{datas.oxidising_flow_typical}</td>
                      <td id="td-right"><span>Oxidizing Flow Rejection
                      </span> </td>
                      <td>{datas.oxidising_flow_rejection}</td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">ASH ANALYSIS, %dry basis in ash</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>SiO2 Typical

                      </span> </td>
                      <td>{datas.SiO2_Typical}</td>
                      <td id="td-right"><span>SiO2 Rejection
                      </span> </td>
                      <td>{datas.SiO2_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>AI2O3 Typical

                      </span> </td>
                      <td>{datas.AI2O3_Typical}</td>
                      <td id="td-right"><span>AI2O3 Rejection
                      </span> </td>
                      <td>{datas.AI2O3_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Fe2O3 Typical

                      </span> </td>
                      <td>{datas.Fe2O3_Typical}</td>
                      <td id="td-right"><span>Fe2O3 Rejection
                      </span> </td>
                      <td>{datas.Fe2O3_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>CaO Typical

                      </span> </td>
                      <td>{datas.CaO_Typical}</td>
                      <td id="td-right"><span>CaO Rejection
                      </span> </td>
                      <td>{datas.CaO_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>P2O5 Typical

                      </span> </td>
                      <td>{datas.P2O5_Typical}</td>
                      <td id="td-right"><span>P2O5 Rejection
                      </span> </td>
                      <td>{datas.P2O5_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>SO3 Typical

                      </span> </td>
                      <td>{datas.SO3_Typical}</td>
                      <td id="td-right"><span>SO3 Rejection
                      </span> </td>
                      <td>{datas.SO3_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>MgO Typical

                      </span> </td>
                      <td>{datas.MgO_Typical}</td>
                      <td id="td-right"><span>MgO Rejection
                      </span> </td>
                      <td>{datas.MgO_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TiO2 Typical

                      </span> </td>
                      <td>{datas.TiO2_rejection}</td>
                      <td id="td-right"><span>TiO2 Rejection
                      </span> </td>
                      <td>{datas.TiO2_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>NA2O Typical

                      </span> </td>
                      <td>{datas.NA2O_Typical}</td>
                      <td id="td-right"><span>NA2O Rejection
                      </span> </td>
                      <td>{datas.NA2O_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>K2O Typical

                      </span> </td>
                      <td>{datas.K2O_Typical}</td>
                      <td id="td-right"><span>K2O Rejection
                      </span> </td>
                      <td>{datas.K2O_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Mn3O4 Typical

                      </span> </td>
                      <td>{datas.KMn3O4_Typical}</td>
                      <td id="td-right"><span>Mn3O4 Rejection
                      </span> </td>
                      <td>{datas.Mn3O4_rejection}</td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">TRACE ELEMENT, ppm Air Dried in Coal</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>TE Antimony Sb Typical

                      </span> </td>
                      <td>{datas.te_antimony_sb_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Antimony Sb Rejection
                      </span> </td>
                      <td>{datas.te_antimony_sb_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Arsenic As Typical

                      </span> </td>
                      <td>{datas.te_arsenic_as_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Arsenic As Rejection
                      </span> </td>
                      <td>{datas.te_arsenic_as_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Beryllium Be Typical

                      </span> </td>
                      <td>{datas.te_beryllium_be_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Beryllium Be Rejection
                      </span> </td>
                      <td>{datas.te_beryllium_be_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Boron B Typical

                      </span> </td>
                      <td>{datas.te_boron_b_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Boron B Rejection
                      </span> </td>
                      <td>{datas.te_boron_b_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Cadmium Cd Typical

                      </span> </td>
                      <td>{datas.te_cadmium_cd_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Cadmium Cd Rejection
                      </span> </td>
                      <td>{datas.te_cadmium_cd_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Chromium Cr Typical

                      </span> </td>
                      <td>{datas.te_chromium_cr_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Chromium Cr Rejection
                      </span> </td>
                      <td>{datas.te_chromium_cr_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Chlorine Cl Typical

                      </span> </td>
                      <td>{datas.te_chlorine_cl_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Chlorine Cl Rejection
                      </span> </td>
                      <td>{datas.te_chlorine_cl_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Copper Cu Typical

                      </span> </td>
                      <td>{datas.te_copper_cu_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Copper Cu Rejection
                      </span> </td>
                      <td>{datas.te_copper_cu_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Fluorine F Typical

                      </span> </td>
                      <td>{datas.te_fluorine_f_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Fluorine F Rejection
                      </span> </td>
                      <td>{datas.te_fluorine_f_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Lead Pb Typical

                      </span> </td>
                      <td>{datas.te_lead_pb_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Lead Pb Rejection
                      </span> </td>
                      <td>{datas.te_lead_pb_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Mercury Hg Typical

                      </span> </td>
                      <td>{datas.te_mercury_hg_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Mercury Hg Rejection
                      </span> </td>
                      <td>{datas.te_mercury_hg_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Molybdenum Mo Typical

                      </span> </td>
                      <td>{datas.te_molybdenum_mo_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Molybdenum Mo Rejection
                      </span> </td>
                      <td>{datas.te_molybdenum_mo_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Phosporous P Typical

                      </span> </td>
                      <td>{datas.te_phosphorus_p_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Phosporous P Rejection
                      </span> </td>
                      <td>{datas.te_phosphorus_p_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Selenium Se Typical

                      </span> </td>
                      <td>{datas.te_selenium_se_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Selenium Se Rejection
                      </span> </td>
                      <td>{datas.te_selenium_se_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Vanadium V Typical

                      </span> </td>
                      <td>{datas.te_vanadium_v_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Vanadium V Rejection

</span> </td>
                      <td>{datas.te_vanadium_v_rejection_in_air_dried_coal}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>TE Zinc Zn Typical

                      </span> </td>
                      <td>{datas.te_zinc_zn_typical_in_air_dried_coal}</td>
                      <td id="td-right"><span>TE Zinc Zn Rejection
                      </span> </td>
                      <td>{datas.te_zinc_zn_rejection_in_air_dried_coal}</td>

                    </tr>

                    </tbody>
                    </table>


                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">Sizing, %</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Size (0-50 mm) Typical

                      </span> </td>
                      <td>{datas.size_0_to_50mm_typical}</td>
                      <td id="td-right"><span>Size (0-50 mm) Rejection
                      </span> </td>
                      <td>{datas.size_0_to_50mm_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Above 50 mm Typical

                      </span> </td>
                      <td>{datas.above_0_to_50mm_typical}</td>
                      <td id="td-right"><span>Above 50 mm Rejection
                      </span> </td>
                      <td>{datas.above_0_to_50mm_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Under 2 mm Typical

                      </span> </td>
                      <td>{datas.under_2mm_typical}</td>
                      <td id="td-right"><span>Under 2 mm Rejection
                      </span> </td>
                      <td>{datas.under_2mm_rejection}</td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Size Above 2 mm Typical

                      </span> </td>
                      <td>{datas.size_above_2mm_typical}</td>
                      <td id="td-right"><span>Size Above 2 mm Rejection
                      </span> </td>
                      <td>{datas.size_above_2mm_rejection}</td>

                    </tr>

                    </tbody>
                    </table>

                    <div className="col-md-12" id='adatas'>
                <h4 className="heading">Testing Method</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Testing Method

                      </span> </td>
                      <td>{datas.testing_method}</td>
                      <td id="td-right"><span></span> </td>
                      <td></td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Created By


                      </span> </td>
                      <td>{datas.created_by}</td>
                      <td id="td-right"><span>Last Modified By


   </span> </td>
   <td>{datas.last_modified_by}</td>

                    </tr>

                    </tbody>
                    </table>
                </div>

              </>
            )
            }
            </div>
            ):(
              <>
              {datas.record_type==='Buyer - Long Term'?(
               <div>
               {isEditMode ? (

                 <div className="tasks">

                   <>
                     {/* {console} */}

                     <p className='edit-btn'>
                       <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
                       <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


                     </p>

             <Row className="mb-3">
           <h4 className='heading'>Information</h4>
           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="contract product number"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="contract product number"
                         name="contract_product_number"
                           defaultValue={datas.contract_product_number }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>

           <Form.Group as={Col} md="6">
                     <label>Locked</label>
                     <input
                       type='checkbox'
                       name='locked'
                       checked={lockeds} // Use the state variable to determine if the checkbox is checked
                       onChange={handleCheckboxChange} // Handle the change event
                       className='mx-3'
                     />
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="contract"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="contract"
                         name="contract"
                           defaultValue={datas.contract }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>

                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="record type"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="record type"
                         name="record_type"
                           defaultValue={datas.record_type }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="product name"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="product name"
                         name="product_name"
                           defaultValue={datas.product_name }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>

                   <Form.Group as={Col} md="6"></Form.Group>

                   <Form.Group as={Col} md="6"></Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Number of orders"
                       className="mb-3"


                     >
                       <Form.Control
                         type="number"
                         placeholder="No of orders"
                         name="no_of_orders"
                           defaultValue={datas.no_of_orders }  ///field missing
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingSelect"
                         className="dropDown"
                         label="Supplier"
                       >
                         <Form.Select
                           aria-label="Floating label select example"
                           name="supplier"
                           placeholder="Supplier"
                           defaultValue={datas.supplier || ""}
                           onChange={handleChangeInput}
                         >
                           <option value="">Select</option>
                           {supplier.map((x) => {
                             return (
                               <option value={x.account_name}>
                                 {x.account_name}
                               </option>
                             );
                           })}
                         </Form.Select>
                       </FloatingLabel>
                     </Form.Group>


           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Order quantity"
                       className="mb-3"


                     >
                       <Form.Control
                         type="number"
                         placeholder="Order quantity"
                         name="order_quantiy"
                           defaultValue={datas.order_quanty }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="description"
                       className="mb-3"


                     >
                       <Form.Control
                         type="number"
                         placeholder="description"
                         name="description"
                           defaultValue={datas.description }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingSelect"
                   className="dropDown"
                   label="shipping term"
                 >
                   <Form.Select
                     aria-label="Floating label select example"
                     name="shipping_term"
                    defaultValue={datas.shipping_term }
                           onChange={handleChangeInput}
                   ><option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                   </Form.Select>
                 </FloatingLabel>
               </Form.Group>

           <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingSelect"
                   className="dropDown"
                   label="contract shipment"
                 >
                   <Form.Select
                     aria-label="Floating label select example"
                     name="contract_shipment"
                     placeholder="contract shipment"

                    defaultValue={datas.contract_shipment }
                           onChange={handleChangeInput}>
                     <option value="">Select</option>


   {contractShipment.length>0?(
           <>
           {contractShipment.map((x) => {
                       return (
                         <option defaultValue={x.contract_shipment}>
                           {x.contract_ship_code}
                         </option>
                       );
                     })}
          </>
          ):(

           <option className='no-data'>No data  available</option>
           )}
                   </Form.Select>
                 </FloatingLabel>
               </Form.Group>
               <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Port of Loading"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Port of Loading"
                         name="port_of_loading"
                           defaultValue={datas.port_of_loading }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Product Master">
     <Form.Select aria-label="Floating label select example"
     name="product_master"
     placeholder="Product Master"
     defaultValue={datas.product_master}
     onChange={handleChangeInput}
     >
       <option value='' >Select</option>
        {
           product.map((x)=>{
               return(
                   <option value={x.product_code}>{x.product_code}</option>
               )
           })
        }
     </Form.Select>
   </FloatingLabel>
               </Form.Group>
               <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Price Basis GAR"
                       className="mb-3"


                     >
                       <Form.Control
                         type="number"
                         placeholder="Price Basis GAR"
                         name="price_basis_GAR"
                           defaultValue={datas.price_basis_GAR }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="vessel type">
     <Form.Select aria-label="Floating label select example"
     name="vessel_type"
     placeholder="vessel type"
     defaultValue={datas.vessel_type}
     onChange={handleChangeInput}
     >
       <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
     </Form.Select>
   </FloatingLabel>
               </Form.Group>
               <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Product Description"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Product Description"
                         name="product_description"
                           defaultValue={datas.product_description }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>
           <Row className="mb-3">
           <h4 className='heading'>List of fields - Move to Contract shipment</h4>

           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Product"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Product"
                         name="product"
                           defaultValue={datas.product }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6"></Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="quantity"
                       className="mb-3"


                     >
                       <Form.Control
                         type="number"
                         placeholder="quantity"
                         name="quantity"
                           defaultValue={datas.quantity }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6"></Form.Group>
                   <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Status">
     <Form.Select aria-label="Floating label select example"
     name="status"
     placeholder="Status"
     defaultValue={datas.status}
     onChange={handleChangeInput}
     >
      <option value="">--None--</option><option value="Activated">Activated</option><option value="Partially Completed">Partially Completed</option><option value="Completed">Completed</option><option value="Cancelled">Cancelled</option><option value="Partially Cancelled">Partially Cancelled</option>
     </Form.Select>
   </FloatingLabel>
               </Form.Group>

           </Row>
           <Row className="mb-3">
           <h4 className='heading'>Product Details</h4>
           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Price Basis GAR"
                       className="mb-3"


                     >
                       <Form.Control
                         type="number"
                         placeholder="Price Basis GAR"
                         name="price_basis_GAR"
                           defaultValue={datas.price_basis_GAR }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Port of Loading"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Port of Loading"
                         name="port_of_loading"
                           defaultValue={datas.port_of_loading }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Product Description"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Product Description"
                         name="product_description"
                           defaultValue={datas.product_description }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingSelect"
                   className="dropDown"
                   label="shipping term"
                 >
                   <Form.Select
                     aria-label="Floating label select example"
                     name="shipping_term"
                    defaultValue={datas.shipping_term }
                           onChange={handleChangeInput}
                   ><option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                   </Form.Select>
                 </FloatingLabel>
               </Form.Group>
           </Row>
             <Row className="mb-3">
           <h4 className='heading'>%As Received Basis</h4>

                   {/* <h4 className='heading'>Account Information</h4> */}
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Total Moisture Typical	"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Total Moisture Typical"
                         name="total_moisture_typical"
                           defaultValue={datas.total_moisture_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Total Moisture Rejection	"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Total Moisture Rejection	"
                         name="total_moisture_rejection"
                           defaultValue={datas.total_moisture_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Ash Content Typical ARB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Ash Content Typical ARB"
                         name="ash_typical_arb"
                           defaultValue={datas.ash_typical_arb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="ash rejection arb"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="ash rejection arb"
                         name="ash_rejection_arb"
                           defaultValue={datas.ash_rejection_arb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Volatile Matter Typical ARB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Volatile Matter Typical ARB	"
                         name="volatile_matter_typical_arb"
                           defaultValue={datas.volatile_matter_typical_arb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Volatile Matter Rejection ARB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Volatile Matter Rejection ARB"
                         name="volatile_matter_rejection_arb"
                           defaultValue={datas.volatile_matter_rejection_arb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Total Sulphur Typical ARB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Total Sulphur Typical ARB"
                         name="sulphur_typical_arb"
                           defaultValue={datas.sulphur_typical_arb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Total Sulphur Rejection ARB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Total Sulphur Rejection ARB"
                         name="sulphur_rejection_arb"
                           defaultValue={datas.sulphur_rejection_arb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>

           </Row>
           <Row className="mb-3">
           <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>

                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Inherent Moisture Typical"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Inherent Moisture Typical "
                         name="moisture_typical"
                           defaultValue={datas.moisture_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Inherent Moisture Rejection   "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Inherent Moisture Rejection "
                         name="moisture_rejection"
                           defaultValue={datas.moisture_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Ash Content Typical  "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Ash Content Typical   "
                         name="ash_typical"
                           defaultValue={datas.ash_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Ash Content Rejection  "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Ash Content Rejection"
                         name="ash_rejection"
                           defaultValue={datas.ash_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Volatile Matter Typical ADB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Volatile Matter Typical ADB"
                         name="volatile_matter_minimum_arb"
                           defaultValue={datas.volatile_matter_minimum_arb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Volatile Matter Rejection ADB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Volatile Matter Rejection ADB "
                         name="volatile_matter_maximum_arb"
                           defaultValue={datas.volatile_matter_maximum_arb}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Fixed Carbon Typical ADB "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Fixed Carbon Typical ADB"
                         name="fixed_carbon_typical"
                           defaultValue={datas.fixed_carbon_typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Fixed Carbon Rejection ADB"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Fixed Carbon Rejection ADB"
                         name="fixed_carbon_rejection"
                           defaultValue={datas.fixed_carbon_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">

                 <input
                   type='checkbox'
                   name='fixed_carbon_by_difference'
                   checked={defferece} // Use the state variable to determine if the checkbox is checked
                   onChange={handleCheckboxActive} // Handle the change event
                   className='mx-3'
                 />
                  <label>Fixed Carbon By Difference</label>
               </Form.Group>

           </Row>

           <Row className="mb-3">
           <h4 className='heading'>CALORIFIC VALUE, kcal / kg</h4>
           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Gross As Received Typical "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Gross As Received Typical  "
                         name="gross_as_received_typical"
                           defaultValue={datas.gross_as_received_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Gross As Received Rejection   "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Gross As Received Rejection "
                         name="gross_as_received_rejection"
                           defaultValue={datas.gross_as_received_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group> <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Gross Air Dried Typical"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Gross Air Dried Typical "
                         name="gross_air_dried_typical"
                           defaultValue={datas.gross_air_dried_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group> <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Gross Air Dried Rejection "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Gross Air Dried Rejection  "
                         name="gross_air_dried_rejection"
                           defaultValue={datas.gross_air_dried_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group> <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Net As Received Typical"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Net As Received Typical "
                         name="net_as_received_typical"
                           defaultValue={datas.net_as_received_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group> <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Net As Received Rejection"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Net As Received Rejection "
                         name="net_as_received_rejection"
                           defaultValue={datas.net_as_received_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>
           <Row className="mb-3">
           <h4 className='heading'>HGI</h4>
           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="HGI Typical"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="HGI Typical"
                         name="hgi_typical"
                           defaultValue={datas.hgi_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="HGI Rejection"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="HGI Rejection"
                         name="hgi_rejection"
                           defaultValue={datas.hgi_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>

           <Row className="mb-3">
           <h4 className='heading'>ULTIMATE ANALYSIS, %dry ash free basis</h4>

    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Carbon Typical "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Carbon Typical   "
                         name="carbon_typical"
                           defaultValue={datas.carbon_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="carbon rejection"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="carbon rejection "
                         name="carbon_rejection"
                           defaultValue={datas.carbon_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Hydrogen Typical  "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Hydrogen Typical    "
                         name="hydrogen_typical"
                           defaultValue={datas.hydrogen_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Hydrogen Rejection"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Hydrogen Rejection"
                         name="hydrogen_rejection"
                           defaultValue={datas.hydrogen_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Nitrogen Typical "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Nitrogen Typical"
                         name="nitrogen_typical"
                           defaultValue={datas.nitrogen_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="nitrogen rejection"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="nitrogen rejection"
                         name="nitrogen_rejection"
                           defaultValue={datas.nitrogen_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxygen Typical "
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="Oxygen Typical   "
                         name="oxygen_typical"
                           defaultValue={datas.oxygen_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="oxygen rejection"
                       className="mb-3"


                     >
                       <Form.Control
                         type="text"
                         placeholder="oxygen rejection "
                         name="oxygen_rejection"
                           defaultValue={datas.oxygen_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>

           <Row className="mb-3">
           <h4 className='heading'>SULPHUR, %air dried basis</h4>

           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Sulphur Typical ADB"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Sulphur Typical ADB  "
                         name="sulphur_typical_adb"
                           defaultValue={datas.sulphur_typical_adb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Sulphur Rejection ADB"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Sulphur Rejection ADB"
                         name="sulphur_rejection_adb"
                           defaultValue={datas.sulphur_rejection_adb }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>
           <Row className="mb-3">
           <h4 className='heading'>ASH FUSION TEMPERATURE, DEG(C) Reducing </h4>

    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Initial Deformation Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Initial Deformation Typical  "
                         name="initial_typical"
                           defaultValue={datas.initial_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Initial Deformation Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Initial Deformation Rejection"
                         name="initial_rejection"
                           defaultValue={datas.initial_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Spherical Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Spherical Typical "
                         name="spherical_typical"
                           defaultValue={datas.spherical_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Spherical Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Spherical Rejection"
                         name="spherical_rejection"
                           defaultValue={datas.spherical_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Hemispherical Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Hemispherical Typical"
                         name="hemispherical_typical"
                           defaultValue={datas.hemispherical_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="hemispherical rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="hemispherical rejection "
                         name="hemispherical_rejection"
                           defaultValue={datas.hemispherical_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Flow Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Flow Typical  "
                         name="flow_typical"
                           defaultValue={datas.flow_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="flow rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="flow rejection"
                         name="flow_rejection"
                           defaultValue={datas.flow_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>
           <Row className="mb-3">
           <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Oxidizing</h4>

           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Initial Deformation Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Initial Deformation Typical"
                         name="oxidising_initial_typical"
                           defaultValue={datas.oxidising_initial_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Initial Deformation Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Initial Deformation Rejection"
                         name="oxidising_initial_rejection"
                           defaultValue={datas.oxidising_initial_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Hemisperical Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Hemisperical Typical"
                         name="oxidising_hemispherical_typical"
                           defaultValue={datas.oxidising_hemispherical_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Hemisperical Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Hemisperical Rejection "
                         name="oxidising_hemispherical_rejection"
                           defaultValue={datas.oxidising_hemispherical_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Spherical Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Spherical Typical "
                         name="oxidising_spherical_typical"
                           defaultValue={datas.oxidising_spherical_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Spherical Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Spherical Rejection"
                         name="oxidising_spherical_rejection"
                           defaultValue={datas.oxidising_spherical_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Flow Typical  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Flow Typical   "
                         name="oxidising_flow_typical"
                           defaultValue={datas.oxidising_flow_typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Oxidizing Flow Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Oxidizing Flow Rejection"
                         name="oxidising_flow_rejection"
                           defaultValue={datas.oxidising_flow_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>

           <Row className="mb-3">
           <h4 className='heading'>ASH ANALYSIS, %dry basis in ash</h4>

           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="SiO2 Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="SiO2 Typical"
                         name="SiO2_Typical"
                           defaultValue={datas.SiO2_Typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>

                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="SiO2 Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="SiO2 Rejection"
                         name="SiO2_rejection"
                           defaultValue={datas.SiO2_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="AI2O3 Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="AI2O3 Typical"
                         name="AI2O3_Typical"
                           defaultValue={datas.AI2O3_Typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="AI2O3 Rejection  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="AI2O3 Rejection"
                         name="AI2O3_rejection"
                           defaultValue={datas.AI2O3_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Fe2O3 Typical  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Fe2O3 Typical"
                         name="Fe2O3_Typical"
                           defaultValue={datas.Fe2O3_Typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Fe2O3 Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Fe2O3 Rejection"
                         name="Fe2O3_rejection"
                           defaultValue={datas.Fe2O3_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="CaO Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="CaO Typical"
                         name="CaO_Typical"
                           defaultValue={datas.CaO_Typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="CaO Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="CaO Rejection  "
                         name="CaO_rejection"
                           defaultValue={datas.CaO_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>

                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="P2O5 Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="P2O5 Typical"
                         name="P2O5_Typical"
                           defaultValue={datas.P2O5_Typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="P2O5 Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="P2O5 Rejection"
                         name="P2O5_rejection"
                           defaultValue={datas.P2O5_rejection }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>

                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="MgO Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="MgO Typical"
                         name="MgO_Typical"
                           defaultValue={datas.MgO_Typical }
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="MgO Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="MgO Rejection "
                         name="MgO_rejection"
                           defaultValue={datas.MgO_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TiO2 Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TiO2 Typical"
                         name="TiO2_rejection"
                           defaultValue={datas.TiO2_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TiO2 Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TiO2 Rejection"
                         name="TiO2_rejection"
                           defaultValue={datas.TiO2_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="NA2O Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="NA2O Typical"
                         name="NA2O_Typical"
                           defaultValue={datas.NA2O_Typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="NA2O Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="NA2O Rejection  "
                         name="NA2O_rejection"
                           defaultValue={datas.NA2O_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="K2O Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="K2O Typical "
                         name="K2O_Typical"
                           defaultValue={datas.K2O_Typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="K2O Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="K2O Rejection"
                         name="K2O_rejection"
                           defaultValue={datas.K2O_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="KMn3O4 Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="KMn3O4 Typical"
                         name="KMn3O4_Typical"
                           defaultValue={datas.KMn3O4_Typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Mn3O4 Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Mn3O4 Rejection"
                         name="Mn3O4_rejection"
                           defaultValue={datas.Mn3O4_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>
           <Row className="mb-3">
           <h4 className='heading'>TRACE ELEMENT, ppm Air Dried in Coal
           </h4>
           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Antimony Sb Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Antimony Sb Typical"
                         name="te_antimony_sb_typical_in_air_dried_coal"
                           defaultValue={datas.te_antimony_sb_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Antimony Sb Rejection   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Antimony Sb Rejection "
                         name="te_antimony_sb_rejection_in_air_dried_coal"
                           defaultValue={datas.te_antimony_sb_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Arsenic As Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Arsenic As Typical "
                         name="te_arsenic_as_typical_in_air_dried_coal"
                           defaultValue={datas.te_arsenic_as_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Arsenic As Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Arsenic As Rejection  "
                         name="te_arsenic_as_rejection_in_air_dried_coal"
                           defaultValue={datas.te_arsenic_as_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Beryllium Be Typical "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Beryllium Be Typical  "
                         name="te_beryllium_be_typical_in_air_dried_coal"
                           defaultValue={datas.te_beryllium_be_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Beryllium Be Rejection"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Beryllium Be Rejection"
                         name="te_beryllium_be_rejection_in_air_dried_coal"
                           defaultValue={datas.te_beryllium_be_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Boron B Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Boron B Typical"
                         name="te_boron_b_typical_in_air_dried_coal"
                           defaultValue={datas.te_boron_b_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Boron B Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Boron B Rejection  "
                         name="te_boron_b_rejection_in_air_dried_coal"
                           defaultValue={datas.te_boron_b_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Cadmium Cd Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Cadmium Cd Typical "
                         name="te_cadmium_cd_typical_in_air_dried_coal"
                           defaultValue={datas.te_cadmium_cd_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Cadmium Cd Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Cadmium Cd Rejection  "
                         name="te_cadmium_cd_rejection_in_air_dried_coal"
                           defaultValue={datas.te_cadmium_cd_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Chromium Cr Typical  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Chromium Cr Typical   "
                         name="te_chromium_cr_typical_in_air_dried_coal"
                           defaultValue={datas.te_chromium_cr_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Chromium Cr Rejection   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Chromium Cr Rejection "
                         name="te_chromium_cr_rejection_in_air_dried_coal"
                           defaultValue={datas.te_chromium_cr_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Chlorine Cl Typical  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Chlorine Cl Typical   "
                         name="te_chlorine_cl_typical_in_air_dried_coal"
                           defaultValue={datas.te_chlorine_cl_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Chromium Cr Rejection   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Chromium Cr Rejection "
                         name="te_chlorine_cl_rejection_in_air_dried_coal"
                           defaultValue={datas.te_chlorine_cl_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Copper Cu Typical "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Copper Cu Typical  "
                         name="te_copper_cu_typical_in_air_dried_coal"
                           defaultValue={datas.te_copper_cu_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Copper Cu Rejection  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Copper Cu Rejection   "
                         name="te_copper_cu_rejection_in_air_dried_coal"
                           defaultValue={datas.te_copper_cu_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Fluorine F Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Fluorine F Typical "
                         name="te_fluorine_f_typical_in_air_dried_coal"
                           defaultValue={datas.te_fluorine_f_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Fluorine F Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Fluorine F Rejection  "
                         name="te_fluorine_f_rejection_in_air_dried_coal"
                           defaultValue={datas.te_fluorine_f_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Lead Pb Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Lead Pb Typical "
                         name="te_lead_pb_typical_in_air_dried_coal"
                           defaultValue={datas.te_lead_pb_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Lead Pb Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Lead Pb Rejection  "
                         name="te_lead_pb_rejection_in_air_dried_coal"
                           defaultValue={datas.te_lead_pb_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Mercury Hg Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Mercury Hg Typical "
                         name="te_mercury_hg_typical_in_air_dried_coal"
                           defaultValue={datas.te_mercury_hg_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Mercury Hg Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Mercury Hg Rejection  "
                         name="te_mercury_hg_rejection_in_air_dried_coal"
                           defaultValue={datas.te_mercury_hg_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Molybdenum Mo Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Molybdenum Mo Typical "
                         name="te_molybdenum_mo_typical_in_air_dried_coal"
                           defaultValue={datas.te_molybdenum_mo_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Molybdenum Mo Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Molybdenum Mo Rejection  "
                         name="te_molybdenum_mo_rejection_in_air_dried_coal"
                           defaultValue={datas.te_molybdenum_mo_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Phosporous P Typical "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Phosporous P Typical  "
                         name="te_phosphorus_p_typical_in_air_dried_coal"
                           defaultValue={datas.te_phosphorus_p_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Phosporous P Rejection  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Phosporous P Rejection   "
                         name="te_phosphorus_p_rejection_in_air_dried_coal"
                           defaultValue={datas.te_phosphorus_p_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Selenium Se Typical  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Selenium Se Typical   "
                         name="te_selenium_se_typical_in_air_dried_coal"
                           defaultValue={datas.te_selenium_se_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Selenium Se Rejection   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Selenium Se Rejection "
                         name="te_selenium_se_rejection_in_air_dried_coal"
                           defaultValue={datas.te_selenium_se_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Vanadium V Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Vanadium V Typical "
                         name="te_vanadium_v_typical_in_air_dried_coal"
                           defaultValue={datas.te_vanadium_v_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Vanadium V Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Vanadium V Rejection  "
                         name="te_vanadium_v_rejection_in_air_dried_coal"
                           defaultValue={datas.te_vanadium_v_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Zinc Zn Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Zinc Zn Typical "
                         name="te_zinc_zn_typical_in_air_dried_coal"
                           defaultValue={datas.te_zinc_zn_typical_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
                    <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="TE Zinc Zn Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="TE Zinc Zn Rejection  "
                         name="te_zinc_zn_rejection_in_air_dried_coal"
                           defaultValue={datas.te_zinc_zn_rejection_in_air_dried_coal}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>
           <Row className="mb-3">
           <h4 className='heading'>Sizing, %</h4>
           <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Size (0-50 mm) Typical"
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Size (0-50 mm) Typical"
                         name="size_0_to_50mm_typical"
                           defaultValue={datas.size_0_to_50mm_typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Size (0-50 mm) Rejection   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Size (0-50 mm) Rejection "
                         name="size_0_to_50mm_rejection"
                           defaultValue={datas.size_0_to_50mm_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Above 50 mm Typical  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Above 50 mm Typical   "
                         name="above_0_to_50mm_typical"
                           defaultValue={datas.above_0_to_50mm_typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Above 50 mm Rejection   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Above 50 mm Rejection "
                         name="above_0_to_50mm_rejection"
                           defaultValue={datas.above_0_to_50mm_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Under 2 mm Typical   "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Under 2 mm Typical "
                         name="under_2mm_typical"
                           defaultValue={datas.under_2mm_typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Under 2 mm Rejection "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Under 2 mm Rejection  "
                         name="under_2mm_rejection"
                           defaultValue={datas.under_2mm_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Size Above 2 mm Typical "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Size Above 2 mm Typical  "
                         name="size_above_2mm_typical"
                           defaultValue={datas.size_above_2mm_typical}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>  <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingInput"
                       label="Size Above 2 mm Rejection  "
                       className="mb-3" >
                       <Form.Control
                         type="text"
                         placeholder="Size Above 2 mm Rejection   "
                         name="size_above_2mm_rejection"
                           defaultValue={datas.size_above_2mm_rejection}
                           onChange={handleChangeInput}/>
                     </FloatingLabel>
                   </Form.Group>
           </Row>
           <Row className="mb-3">
           <h4 className='heading'>Testing Method</h4>
           <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingSelect"
                   className="dropDown"
                   label="Testing Method"
                 >
                   <Form.Select
                     aria-label="Floating label select example"
                     name="testing_method"
                    defaultValue={datas.testing_method }
                           onChange={handleChangeInput}
                   >
                     <option defaultValue="">Select </option>
                     <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                       According to ASTM Standard Method Unless Otherwise Stated
                     </option>
                     <option defaultValue="According to ISO Methods">
                       According to ISO Methods
                     </option>
                     <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                       According to ISO Methods Except HGI, Trace Elements and Ash
                       Analysis to ASTM
                     </option>
                     <option defaultValue="According to ISO Methods Except HGI to ASTM">
                       According to ISO Methods Except HGI to ASTM
                     </option>
                     <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                       According to ISO Methods, Except HGI and Ash Analysis to
                       ASTM
                     </option>
                     <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                       According to ISO Methods, Except HGI as per ASTM Test Method
                     </option>
                   </Form.Select>
                 </FloatingLabel>
               </Form.Group>
           </Row>
            <p className='edit-btn'>
                       <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
                       <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


                     </p> </>

                 </div>
               ) : (
                 <>




                   <div className="tasks">
                     <div className="edit-delete">
                       <span id="edit" onClick={handleEditClick}>
                         Edit
                       </span>
                       <span>
                         <Link to={'/contract/create-contract-list'}> Cancel</Link>
                       </span>

                     </div>

                  <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Contract Product Detail</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Contract Product Number
                         </span> </td>
                         <td>{datas.contract_product_number}</td>
                         <td id="td-right"><span>Locked</span> </td>
                         <td> <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3' disabled={isButtonEnabled}
                  /></td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Contract</span> </td>
                         <td><Link to={`/contract/contract-details/${datas.contract_id}`}>{datas.contract}</Link></td>
                         <td id="td-right"><span>Record Type
                         </span> </td>
                         <td>{datas.record_type}</td>

                       </tr>
                       <tr>

                         <td id="td-right"><span>Product Name
                         </span> </td>
                         <td>{datas.product_name}</td>
                         <td id="td-right">
                          {/* <span>Contract Shipment
                         </span>  */}
                         </td>
                         <td>
                          {/* {datas.contract_shipment} */}
                          </td>

                       </tr>
                       <tr>


                         <td id="td-right">
                          {/* <span>Contract Shipment
                         </span>  */}
                         </td>
                         <td>
                          {/* {datas.contract_shipment} */}
                          </td>
                          <td id="td-right"><span>no of orders
                         </span> </td>
                         <td>{datas.no_of_orders}</td>

                       </tr>
                       <tr>
                       <td id="td-right"><span>Supplier</span> </td>
                       <td>{datas.supplier}</td>
                         <td id="td-right"><span>Order Quantity
                         </span> </td>
                         <td>{datas.order_quanty}</td>


                       </tr>
                       <tr>
                       <td id="td-right"><span>Description</span> </td>
                       <td>{datas.description}</td>
                         <td id="td-right"><span>Shipping Term
                         </span> </td>
                         <td>{datas.shipping_term}</td>


                       </tr>
                       <tr>
                       <td id="td-right"><span>contract shipment</span> </td>
                       <td><Link to={`/shipment/contract-shipment-details/${datas.contract_shipment_id}`}>{datas.contract_shipment}</Link></td>
                         <td id="td-right"><span>port of loading
                         </span> </td>
                         <td>{datas.port_of_loading}</td>


                       </tr>
                       <tr>
                       <td id="td-right"><span>product master</span> </td>
                       <td><Link to={`/productmaster/product-detail/${datas.product_master_id}`}>{datas.product_master}</Link></td>
                         <td id="td-right"><span>Price Basis GAR
                         </span> </td>
                         <td>{datas.price_basis_GAR}</td>


                       </tr>
                       <tr>
                       <td id="td-right"><span>Vessel Type	</span> </td>
                       <td>{datas.vessel_type}</td>
                         <td id="td-right"><span>Product Description
                         </span> </td>
                         <td>{datas.product_description}</td>


                       </tr>
                       </tbody>
                       </table>
                       <div className="col-md-12" id='adatas'>
                <h4 className="heading">List of fields - Move to Contract shipment
                </h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Product
                      </span> </td>
                      <td>{datas.product}</td>
                      <td id="td-right">
                        {/* <span>Locdsdsdsked</span>  */}
                        </td>
                      <td>
                        {/* {datas.locked} */}
                        </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Quantity
                      </span> </td>
                      <td>{datas.quantity}</td>
                      <td id="td-right">
                        {/* <span>Locdsdsdsked</span>  */}
                        </td>
                      <td>
                        {/* {datas.locked} */}
                        </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Status
                      </span> </td>
                      <td>{datas.status}</td>
                      <td id="td-right">
                        {/* <span>Locdsdsdsked</span>  */}
                        </td>
                      <td>
                        {/* {datas.locked} */}
                        </td>

                    </tr>

                    </tbody>
                    </table>
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Product Details</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Total Moisture Typical

                         </span> </td>
                         <td>{datas.total_moisture_typical}</td>
                         <td id="td-right"><span>Total Moisture Rejection
                         </span> </td>
                         <td>{datas.total_moisture_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Ash Content Typical ARB

                         </span> </td>
                         <td>{datas.ash_typical_arb}</td>
                         <td id="td-right"><span>Ash Content Rejection ARB
                         </span> </td>
                         <td>{datas.ash_rejection_arb}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Volatile Matter Typical ARB

                         </span> </td>
                         <td>{datas.volatile_matter_typical_arb}</td>
                         <td id="td-right"><span>Volatile Matter Rejection ARB
                         </span> </td>
                         <td>{datas.volatile_matter_rejection_arb}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Total Sulphur Typical ARB

                         </span> </td>
                         <td>{datas.sulphur_typical_arb}</td>
                         <td id="td-right"><span>Total Sulphur Rejection ARB
                         </span> </td>
                         <td>{datas.sulphur_rejection_arb}</td>

                       </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Proximate Analysis %Air Dried Basis</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Inherent Moisture Typical

                         </span> </td>
                         <td>{datas.moisture_typical}</td>
                         <td id="td-right"><span>Inherent Moisture Rejection
                         </span> </td>
                         <td>{datas.moisture_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Ash Content Typical

                         </span> </td>
                         <td>{datas.ash_typical}</td>
                         <td id="td-right"><span>Ash Content Rejection
                         </span> </td>
                         <td>{datas.ash_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Volatile Matter Typical ADB

                         </span> </td>
                         <td>{datas.volatile_matter_minimum_arb}</td>
                         <td id="td-right"><span>Volatile Matter Rejection ADB
                         </span> </td>
                         <td>{datas.volatile_matter_maximum_arb}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Fixed Carbon Typical ADB

                         </span> </td>
                         <td>{datas.fixed_carbon_typical}</td>
                         <td id="td-right"><span>Fixed Carbon Rejection ADB
                         </span> </td>
                         <td>{datas.fixed_carbon_rejection}</td>

                       </tr>

                       <tr>
                      <td id="td-right"><span>Fixed Carbon By Difference

                      </span> </td>
                      <td><input
                   type='checkbox'
                   name='fixed_carbon_by_difference'
                   checked={defferece} // Use the state variable to determine if the checkbox is checked
                   onChange={handleCheckboxActive} // Handle the change event
                   className='mx-3'
                   disabled={isButtonEnabled}
                 /></td>
                      <td id="td-right">
                        {/* <span>Locdsdsdsked</span>  */}
                        </td>
                      <td>
                        {/* {datas.locked} */}

                      </td>

                    </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">CALORIFIC VALUE, kcal / kg</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Gross As Received Typical
                         </span> </td>
                         <td>{datas.gross_as_received_typical}</td>
                         <td id="td-right"><span>Gross As Received Rejection
                         </span> </td>
                         <td>{datas.gross_as_received_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Gross Air Dried Typical

                         </span> </td>
                         <td>{datas.gross_air_dried_typical}</td>
                         <td id="td-right"><span>Gross Air Dried Rejection
                         </span> </td>
                         <td>{datas.gross_air_dried_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Net As Received Typical

                         </span> </td>
                         <td>{datas.net_as_received_typical}</td>
                         <td id="td-right"><span>Net As Received Rejection
                         </span> </td>
                         <td>{datas.net_as_received_rejection}</td>

                       </tr>

                       </tbody>
                       </table>
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">HGI</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>HGI Typical
                         </span> </td>
                         <td>{datas.hgi_typical}</td>
                         <td id="td-right"><span>HGI Rejection
                         </span> </td>
                         <td>{datas.hgi_rejection}</td>

                       </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ULTIMATE ANALYSIS, %dry ash free basis</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Carbon Typical

                         </span> </td>
                         <td>{datas.carbon_typical}</td>
                         <td id="td-right"><span>Carbon Rejection
                         </span> </td>
                         <td>{datas.carbon_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Hydrogen Typical

                         </span> </td>
                         <td>{datas.hydrogen_typical}</td>
                         <td id="td-right"><span>Hydrogen Rejection
                         </span> </td>
                         <td>{datas.hydrogen_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Nitrogen Typical

                         </span> </td>
                         <td>{datas.nitrogen_typical}</td>
                         <td id="td-right"><span>Nitrogen Rejection
                         </span> </td>
                         <td>{datas.nitrogen_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Oxygen Typical

                         </span> </td>
                         <td>{datas.oxygen_typical}</td>
                         <td id="td-right"><span>Oxygen Rejection
                         </span> </td>
                         <td>{datas.oxygen_rejection}</td>

                       </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">SULPHUR, %air dried basis</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Sulphur Typical ADB

                         </span> </td>
                         <td>{datas.sulphur_typical_adb}</td>
                         <td id="td-right"><span>Sulphur Rejection ADB
                         </span> </td>
                         <td>{datas.sulphur_rejection_adb}</td>

                       </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ASH FUSION TEMPERATURE, DEG(C) Reducing</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Initial Deformation Typical

                         </span> </td>
                         <td>{datas.initial_typical}</td>
                         <td id="td-right"><span>Initial Deformation Rejection
                         </span> </td>
                         <td>{datas.initial_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Spherical Typical

                         </span> </td>
                         <td>{datas.spherical_typical}</td>
                         <td id="td-right"><span>Spherical Rejection
                         </span> </td>
                         <td>{datas.spherical_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Hemispherical Typical

                         </span> </td>
                         <td>{datas.hemispherical_typical}</td>
                         <td id="td-right"><span>Hemispherical Rejection
                         </span> </td>
                         <td>{datas.hemispherical_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Flow Typical

                         </span> </td>
                         <td>{datas.flow_typical}</td>
                         <td id="td-right"><span>Flow Rejection
                         </span> </td>
                         <td>{datas.flow_rejection}</td>

                       </tr>

                       </tbody>
                       </table>


                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ASH FUSION TEMPERATURE, DEG (C) Oxidizing</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Oxidizing Initial Deformation Typical
                         </span> </td>
                         <td>{datas.oxidising_initial_typical}</td>
                         <td id="td-right"><span>Oxidizing Initial Deformation Rejection
                         </span> </td>
                         <td>{datas.oxidising_initial_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Oxidizing Hemisperical Typical

                         </span> </td>
                         <td>{datas.oxidising_hemispherical_typical}</td>
                         <td id="td-right"><span>Oxidizing Hemisperical Rejection
                         </span> </td>
                         <td>{datas.oxidising_hemispherical_rejection}</td>

                       </tr>

                       <tr>
                         <td id="td-right"><span>Oxidizing Spherical Typical

                         </span> </td>
                         <td>{datas.oxidising_spherical_typical}</td>
                         <td id="td-right"><span>Oxidizing Spherical Rejection
                         </span> </td>
                         <td>{datas.oxidising_spherical_rejection}</td>

                       </tr>

                       <tr>
                         <td id="td-right"><span>Oxidizing Flow Typical

                         </span> </td>
                         <td>{datas.oxidising_flow_typical}</td>
                         <td id="td-right"><span>Oxidizing Flow Rejection
                         </span> </td>
                         <td>{datas.oxidising_flow_rejection}</td>

                       </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ASH ANALYSIS, %dry basis in ash</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>SiO2 Typical

                         </span> </td>
                         <td>{datas.SiO2_Typical}</td>
                         <td id="td-right"><span>SiO2 Rejection
                         </span> </td>
                         <td>{datas.SiO2_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>AI2O3 Typical

                         </span> </td>
                         <td>{datas.AI2O3_Typical}</td>
                         <td id="td-right"><span>AI2O3 Rejection
                         </span> </td>
                         <td>{datas.AI2O3_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Fe2O3 Typical

                         </span> </td>
                         <td>{datas.Fe2O3_Typical}</td>
                         <td id="td-right"><span>Fe2O3 Rejection
                         </span> </td>
                         <td>{datas.Fe2O3_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>CaO Typical

                         </span> </td>
                         <td>{datas.CaO_Typical}</td>
                         <td id="td-right"><span>CaO Rejection
                         </span> </td>
                         <td>{datas.CaO_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>P2O5 Typical

                         </span> </td>
                         <td>{datas.P2O5_Typical}</td>
                         <td id="td-right"><span>P2O5 Rejection
                         </span> </td>
                         <td>{datas.P2O5_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>SO3 Typical

                         </span> </td>
                         <td>{datas.SO3_Typical}</td>
                         <td id="td-right"><span>SO3 Rejection
                         </span> </td>
                         <td>{datas.SO3_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>MgO Typical

                         </span> </td>
                         <td>{datas.MgO_Typical}</td>
                         <td id="td-right"><span>MgO Rejection
                         </span> </td>
                         <td>{datas.MgO_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TiO2 Typical

                         </span> </td>
                         <td>{datas.TiO2_rejection}</td>
                         <td id="td-right"><span>TiO2 Rejection
                         </span> </td>
                         <td>{datas.TiO2_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>NA2O Typical

                         </span> </td>
                         <td>{datas.NA2O_Typical}</td>
                         <td id="td-right"><span>NA2O Rejection
                         </span> </td>
                         <td>{datas.NA2O_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>K2O Typical

                         </span> </td>
                         <td>{datas.K2O_Typical}</td>
                         <td id="td-right"><span>K2O Rejection
                         </span> </td>
                         <td>{datas.K2O_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Mn3O4 Typical

                         </span> </td>
                         <td>{datas.KMn3O4_Typical}</td>
                         <td id="td-right"><span>Mn3O4 Rejection
                         </span> </td>
                         <td>{datas.Mn3O4_rejection}</td>

                       </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">TRACE ELEMENT, ppm Air Dried in Coal</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>TE Antimony Sb Typical

                         </span> </td>
                         <td>{datas.te_antimony_sb_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Antimony Sb Rejection
                         </span> </td>
                         <td>{datas.te_antimony_sb_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Arsenic As Typical

                         </span> </td>
                         <td>{datas.te_arsenic_as_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Arsenic As Rejection
                         </span> </td>
                         <td>{datas.te_arsenic_as_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Beryllium Be Typical

                         </span> </td>
                         <td>{datas.te_beryllium_be_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Beryllium Be Rejection
                         </span> </td>
                         <td>{datas.te_beryllium_be_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Boron B Typical

                         </span> </td>
                         <td>{datas.te_boron_b_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Boron B Rejection
                         </span> </td>
                         <td>{datas.te_boron_b_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Cadmium Cd Typical

                         </span> </td>
                         <td>{datas.te_cadmium_cd_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Cadmium Cd Rejection
                         </span> </td>
                         <td>{datas.te_cadmium_cd_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Chromium Cr Typical

                         </span> </td>
                         <td>{datas.te_chromium_cr_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Chromium Cr Rejection
                         </span> </td>
                         <td>{datas.te_chromium_cr_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Chlorine Cl Typical

                         </span> </td>
                         <td>{datas.te_chlorine_cl_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Chlorine Cl Rejection
                         </span> </td>
                         <td>{datas.te_chlorine_cl_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Copper Cu Typical

                         </span> </td>
                         <td>{datas.te_copper_cu_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Copper Cu Rejection
                         </span> </td>
                         <td>{datas.te_copper_cu_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Fluorine F Typical

                         </span> </td>
                         <td>{datas.te_fluorine_f_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Fluorine F Rejection
                         </span> </td>
                         <td>{datas.te_fluorine_f_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Lead Pb Typical

                         </span> </td>
                         <td>{datas.te_lead_pb_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Lead Pb Rejection
                         </span> </td>
                         <td>{datas.te_lead_pb_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Mercury Hg Typical

                         </span> </td>
                         <td>{datas.te_mercury_hg_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Mercury Hg Rejection
                         </span> </td>
                         <td>{datas.te_mercury_hg_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Molybdenum Mo Typical

                         </span> </td>
                         <td>{datas.te_molybdenum_mo_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Molybdenum Mo Rejection
                         </span> </td>
                         <td>{datas.te_molybdenum_mo_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Phosporous P Typical

                         </span> </td>
                         <td>{datas.te_phosphorus_p_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Phosporous P Rejection
                         </span> </td>
                         <td>{datas.te_phosphorus_p_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Selenium Se Typical

                         </span> </td>
                         <td>{datas.te_selenium_se_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Selenium Se Rejection
                         </span> </td>
                         <td>{datas.te_selenium_se_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Vanadium V Typical

                         </span> </td>
                         <td>{datas.te_vanadium_v_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Vanadium V Rejection

   </span> </td>
                         <td>{datas.te_vanadium_v_rejection_in_air_dried_coal}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Zinc Zn Typical

                         </span> </td>
                         <td>{datas.te_zinc_zn_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Zinc Zn Rejection
                         </span> </td>
                         <td>{datas.te_zinc_zn_rejection_in_air_dried_coal}</td>

                       </tr>

                       </tbody>
                       </table>


                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Sizing, %</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Size (0-50 mm) Typical

                         </span> </td>
                         <td>{datas.size_0_to_50mm_typical}</td>
                         <td id="td-right"><span>Size (0-50 mm) Rejection
                         </span> </td>
                         <td>{datas.size_0_to_50mm_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Above 50 mm Typical

                         </span> </td>
                         <td>{datas.above_0_to_50mm_typical}</td>
                         <td id="td-right"><span>Above 50 mm Rejection
                         </span> </td>
                         <td>{datas.above_0_to_50mm_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Under 2 mm Typical

                         </span> </td>
                         <td>{datas.under_2mm_typical}</td>
                         <td id="td-right"><span>Under 2 mm Rejection
                         </span> </td>
                         <td>{datas.under_2mm_rejection}</td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Size Above 2 mm Typical

                         </span> </td>
                         <td>{datas.size_above_2mm_typical}</td>
                         <td id="td-right"><span>Size Above 2 mm Rejection
                         </span> </td>
                         <td>{datas.size_above_2mm_rejection}</td>

                       </tr>

                       </tbody>
                       </table>

                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Testing Method</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Testing Method

                         </span> </td>
                         <td>{datas.testing_method}</td>
                         <td id="td-right"><span></span> </td>
                         <td></td>

                       </tr>
                       <tr>
                         <td id="td-right"><span>Created By


                         </span> </td>
                         <td>{datas.created_by}</td>
                         <td id="td-right"><span>Last Modified By


      </span> </td>
      <td>{datas.last_modified_by}</td>

                       </tr>

                       </tbody>
                       </table>
                   </div>

                 </>
               )
               }
               </div>
            ):(<></>)}
            </>
          )}
          </div>
        {/* // ) : (
        //   <div className="tasks">
        //     <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading" /></h1>
        //   </div>
        // )} */}
      </>

      <Footer />
    </div>
  );
};

export default ViewContractProduct;
