import React, { useEffect, useState, forwardRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables.js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Header from "../Header";
import Footer from "../Footer";
import { AES, enc } from "crypto-js";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import $ from "jquery";
import DatePicker from "react-datepicker";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { API_URL } from '../config';
import { ToastContainer, toast } from "react-toastify";
import { AiFillDiff } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
function BuyerSupplier({ tableData }) {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const location = useLocation();
  const navigate=useNavigate()
  const { record_type, id } = location.state;
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  // console.log(id)
  const toggleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
      console.log(rowId)
    }
  };

  const handleCreate = async () => {
    if (selectedRows.length === 0) {
      // Show an alert if no checkbox is selected
      alert('Please select at least one item before proceeding.');
    } else{
    // Filter the tableData based on selectedRows and send it to the backend
    const selectedData = datas.filter((row) => selectedRows.includes(row.id));

    console.log(JSON.stringify(selectedData))
    try {
      fetch(`${API_URL}add/new_quotes/`+id, {
        method: "POST",

        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          if (response.success===true) {
            fetch(`${API_URL}add/quote_ship/long_term/${id}/${response.quote_id}`,{
              method: "POST",
              body:JSON.stringify(selectedData),
              headers: {
                Authorization: `Bearer ${decryptedAccessToken}`,
                "Content-Type": "application/json",
              },
            })
              .then((response) => response.json())
              .then((responseData) => {
console.log(responseData)
                if(response.success===true){
                  fetch(`${API_URL}add/tcf_new/${response.quote_id}`, {
                          method: "POST",
                          headers: {
                              Authorization: `Bearer ${decryptedAccessToken}`,
                              "Content-Type": "application/json",
                          },
                      })
                      .then((response) => response.json())
                          .then((response) => {
                            console.log('TCF Created',response);
                              toast.success("TCF & Quotes Created Successfully", {
                                  position: "bottom-center",
                                  autoClose: 1000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                });});
                                setTimeout(() => {
                                  // reset();
                                  navigate("/opportunity/quote-view/" + response.quote_id);
                                }, 2000);



                }

                console.log(responseData);
              });
          }
        });
    } catch (error) {
      console.error("Error sending data:", error);
    }
  }
  };

  useEffect(() => {
    const fetchDataSupplier = async () => {
      try {
        const response = await axios.get(`${API_URL}get/opp_ship/long_term/`+id, {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
        );
          const data = response;
        setDatas(data.data.opp_shipments);
        console.log(response)
        $("#tables").DataTable();
      } catch (error) {
        console.error(error);
      }
    };
    fetchDataSupplier();
  }, [decryptedAccessToken,id]);


  // @offers.route("/add/quoteshipment/<id>", methods=['POST'])


  if (record_type === "Buyer - Long Term") {


    return (
      <div className="contactAccount" id="contacts">
        <Header />
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Buyer Long Term</h2>
            <ol className="breadcrumyb">
              <li className="breadcrumb-item active" aria-current="page">
              Dashboard / Opportunity / Buyer Long Term
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
              {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
            </div>
          </div>
        </div>
        <div class="tasks">
          <table id="tables">
            <thead>
              <tr>
                <th>Selected</th>
                <th>opp ship code</th>
                <th>opportunity shipment</th>
                <th>Price</th>
                <th>price basis</th>
                <th>Record Type</th>
                <th>quantity type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
               {datas.length >=0 ? (
                  datas.map((x) => (
                    <tr key={x.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(x.id)}
                          onChange={() => toggleRowSelection(x.id)}
                        />
                      </td>
                      <td>
                        <p id="link">
                          <span>
                          <Link to={`/opportunity/oppshipment-detail/${x.opportunity_id}`}>{x.opp_ship_code} </Link>
                          </span>
                        </p>
                      </td>
                      <td>
                        <Link to={`/opportunity/oppshipment-detail/${x.opportunity_id}`}>{x.opportunity_shipment}</Link>
                      </td>
                      <td>{x.price}</td>
                      <td>{x.price_basis}</td>
                      <td>
                        <span className="account-record">{x.record_type}</span>
                      </td>
                      <td>{x.quantity_type}</td>
                      <td>
                        <FaPencilAlt /> <FaTrashAlt />
                        <Link to="">
                          <AiFillDiff id="details" />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No data available</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </React.Fragment>
            </tbody>
          </table>
          <button onClick={handleCreate}>Create</button>
        </div>

        <Footer />
      </div>
      //  Accepted
    );
  }
  else if (record_type === "Supplier - Long Term") {


    return (
      <div className="contactAccount" id="contacts">
        <Header />
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Supplier Long Term</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Opportunity / Supplier Long Term
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
              {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
            </div>
          </div>
        </div>
        <div class="tasks">
          <table id="tables">
            <thead>
              <tr>
                <th>Selected</th>
                <th>opp ship code</th>
                <th>opportunity shipment</th>
                <th>Price</th>
                <th>price basis</th>
                <th>Record Type</th>
                <th>quantity type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
               {datas.length >=0 ? (
                  datas.map((x) => (
                    <tr key={x.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(x.id)}
                          onChange={() => toggleRowSelection(x.id)}
                        />
                      </td>
                      <td>
                        <p id="link">
                          <span>
                          <Link to={`/opportunity/oppshipment-detail/${x.opportunity_id}`}>{x.opp_ship_code} </Link>
                          </span>
                        </p>
                      </td>
                      <td>
                        <Link to={`/opportunity/oppshipment-detail/${x.opportunity_id}`}>{x.opportunity_shipment}</Link>
                      </td>
                      <td>{x.price}</td>
                      <td>{x.price_basis}</td>
                      <td>
                        <span className="account-record">{x.record_type}</span>
                      </td>
                      <td>{x.quantity_type}</td>
                      <td>
                        <FaPencilAlt /> <FaTrashAlt />
                        <Link to="">
                          <AiFillDiff id="details" />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No data available</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </React.Fragment>
            </tbody>
          </table>
          <button onClick={handleCreate}>Create</button>
        </div>

        <Footer />
      </div>
      //  Accepted
    );
  }
  return <></>;
}

export default BuyerSupplier;
