import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import Footer from '../Footer'
import DatePicker from "react-datepicker";
import $ from 'jquery';
import axios from "axios";
import { API_URL } from '../config';
import { useParams  } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EditContact() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [startDate1, setStartDate1] = useState(new Date());
   const birthDate=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      name='birthdate'
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)

  const params = useParams()
  const idUpdate=params.id
  $(".contactAccount").css({"min-height":(window_height)+"px"})
  const [update,setUpdate]=useState([]);
  const [accounts,setAccount]=useState({});
  const [accountNames, setAccountName]=useState([]);
  useEffect(() => {

    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(`${API_URL}all/accounts`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts;
      setAccountName(data)
       // console.log('Supplier'+ JSON.stringify(data))
    } catch (error) {
      console.error(error);
    }
  };

 useEffect(() => {
  fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}allcontactsbyID/`+idUpdate, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      setAccount(data);
      setValue('saluation', data.saluation);
      setValue('first_name', data.first_name);
      setValue('last_name', data.last_name);
      setValue('birthdate', data.birthdate);
      setValue('title', data.title);
      setValue('account_name', data.account_name);
      setValue('reports_to', data.reports_to);
      setValue('contact_email', data.contact_email);
      setValue('contact_mobile', data.contact_mobile);
      setValue('contact_owner', data.contact_owner);
      setValue('department', data.department);
      setValue('lead_source', data.lead_source);
      setValue('mail_street', data.mail_street);
      setValue('mail_postal_code', data.mail_postal_code);
      setValue('mail_city', data.mail_city);
      setValue('mail_state', data.mail_state);
      setValue('mail_country', data.mail_country);
    } catch (error) {
      console.error(error);
    }
  };


    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = data =>{
        const responseData={
          saluation:data.saluation,
            first_name:data.first_name,
            last_name:data.last_name,
            birthdate:data.birthdate,
            title:data.title,
            account_name:data.account_name,
            reports_to:data.reports_to,
            contact_email:data.contact_email,
            contact_mobile:data.contact_mobile,
            contact_owner:data.contact_owner,
            department:data.department,
            lead_source:data.lead_source,
            mail_street:data.mail_street,
            mail_postal_code:data.mail_postal_code,
            mail_city:data.mail_city,
            mail_state:data.mail_state,
            mail_country:data.mail_country

      };
      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

        fetch(`${API_URL}contact/update/`+idUpdate, {
                  method: "PUT",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())

                  .then((response) => {

      if(response.message=="Contact updated successfully"){
      toast.success(response.message, {
                      position: "bottom-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
    }
                  })

                }

        };
        const handleChange = (e) => {
          const { name, value } = e.target;
          setUpdate((prevData) => ({
            ...prevData,
            [name]: value
          }));
        };

  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Edit Contact</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Edit Contact </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Contact Information</h4>
        </div>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Salutation">
      <Form.Select aria-label="Floating label select example"
      name="saluation"
      placeholder="Salutation"
 defaultValue={accounts.saluation || ""}
      onChange={handleChange}
      {...register("saluation", { required: false })}
      >
        <option value='' disabled>--None--</option>
        <option value="Mr">Mr</option>
        <option value="Ms">Ms</option>
        <option value="Mrs">Mrs</option>
        <option value="Dr">Dr</option>
        <option value="Prof">Prof</option>
      </Form.Select>
      {errors.saluation && <span>Required</span>}
    </FloatingLabel>
    </Form.Group>
    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="First Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="First Name"
              name="first_name"
         defaultValue={accounts.first_name || ""}
              onChange={handleChange}

              {...register("first_name", { required: false })}
            />
             {errors.first_name && <span>Required</span>}
          </FloatingLabel>

            </Form.Group>
            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Last Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Last Name"
            name="last_name"
         defaultValue={accounts.last_name || ""}
              onChange={handleChange}

            {...register("last_name", { required: true })}
            />
             {errors.last_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Birth Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Birth Date'
    />
          </FloatingLabel>
        </Form.Group>



                <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Account name">
    <Form.Select aria-label="Floating label select example"
    name="account_name"
    id='account_name'
    placeholder="Account name"
       defaultValue={accounts.account_name || ""}
              onChange={handleChange}

    {...register("account_name", { required:true })}
    >
      <option value='' >Select</option>
       {
          accountNames.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.account_name && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Reports to"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Reports to"
            name="reports_to"
       defaultValue={accounts.reports_to || ""}
              onChange={handleChange}

            {...register("reports_to", { required: false })}
            />
             {errors.reports_to && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Owner"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contact Owner"
            name="contact_owner"
       defaultValue={accounts.contact_owner || ""}
              onChange={handleChange}

            {...register("contact_owner", { required: false })}
            />
             {errors.contact_owner && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Department"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Department"
            name="department"
         defaultValue={accounts.department || ""}
              onChange={handleChange}

     {...register("department", { required: false })}
            />
             {errors.department && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

            <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Lead Source">
    <Form.Select aria-label="Floating label select example"
    name="lead_source"
    id='account_name'
    placeholder="Lead Source"
       defaultValue={accounts.lead_source || ""}
              onChange={handleChange}

    {...register("lead_source", { required:true })}
    >
      <option value="">--None--</option>
    <option value="Advertisement">Advertisement</option>
    <option value="Customer Event">Customer Event</option>
    <option value="Employee Referral">Employee Referral</option>
    <option value="Google AdWords">Google AdWords</option>
    <option value="Other">Other</option>
    <option value="Partner">Partner</option>
    <option value="Purchased List">Purchased List</option>
    <option value="Trade Show">Trade Show</option>
    <option value="Webinar">Webinar</option>
    <option value="Website">Website</option>
    <option value="Rajgopalan">Rajgopalan</option>
    <option value="Olivia">Olivia</option>

    </Form.Select>
    {errors.lead_source && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>



      </Row>
      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>Contact Information</h4>
        </div>
        <Form.Group as={Col} md="12" id='contact-title'>
          <FloatingLabel
            controlId="floatingInput"
            label="Title"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Title"
            name="title"
       defaultValue={accounts.title || ""}
              onChange={handleChange}

            {...register("title", { required: false })}
            />
             {errors.title && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Email"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contact Email"
            name="contact_email"
       defaultValue={accounts.contact_email || ""}
              onChange={handleChange}

            {...register("contact_email", { required: false })}
            />
             {errors.contact_email && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Mobile"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contact Mobile"
            name="contact_mobile"
       defaultValue={accounts.contact_mobile || ""}
              onChange={handleChange}

            {...register("contact_mobile", { required: false })}
            />
             {errors.contact_mobile && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>

      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>Address Information</h4>
        </div>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail Street"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail Street"
            name="mail_street"
       defaultValue={accounts.mail_street || ""}
              onChange={handleChange}

            {...register("mail_street", { required: false })}
            />
             {errors.mail_street && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail postal code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail postal code"
            name="mail_postal_code"
       defaultValue={accounts.mail_postal_code || ""}
              onChange={handleChange}

            {...register("mail_postal_code", { required: false })}
            />
             {errors.mail_postal_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail city"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail city"
            name="mail_city"
       defaultValue={accounts.mail_city || ""}
              onChange={handleChange}
            {...register("mail_city", { required: false })}
            />
             {errors.mail_city && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail state"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail state"
            name="mail_state"
       defaultValue={accounts.mail_state || ""}
              onChange={handleChange}
            {...register("mail_state", { required: false })}
            />
             {errors.mail_state && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail country"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail country"
            name="mail_country"
       defaultValue={accounts.mail_country || ""}
              onChange={handleChange}
            {...register("mail_country", { required: false })}
            />
             {errors.mail_country && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>
      <input type="submit"  className="contact-save" value='Save'/>
      <div className='contact-success'><p></p><p></p></div>
    </Form>

    <Footer/>
    </div>
  );
}

export default EditContact;
