import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { AES, enc } from "crypto-js";
import $ from "jquery";
import { API_URL } from '../config';
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
// import SingleValue from "react-select/dist/declarations/src/components/SingleValue";
function ContractShippment() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate = useNavigate();
  const location = useLocation();
  const { account_record } = location.state;
  // const body=$(window).height()
  // const header=$("#header").height()
  // const nav=$(".navbar").height()
  // const footer=$(".footer").height()
  // const total_height=header+nav+footer;
  // const window_height=body-total_height;
  // $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas, setDatas] = useState([]);
  const [getPrice, setPrice] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [one, setOne] = useState(new Date());
  const ones =
    one.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    one.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    one.toLocaleDateString("en-US", { day: "2-digit" });
  const Date1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      name="laycan_to_date"
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [two, setTwo] = useState(new Date());
  const twos =
    two.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    two.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    two.toLocaleDateString("en-US", { day: "2-digit" });
  const Date2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      name="laycan_from_date"
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [contractNumber, setContractNumber] = useState([]);

  useEffect(() => {
    fetchDataAccount1();
  }, [decryptedAccessToken]);

  const fetchDataAccount1 = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/contract",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contract;
      setContractNumber(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/contract",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      setCompanies(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);
  const [check, setCheck1] = useState(false);
  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      setPrice(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  if (account_record === "Buyer - Long Term") {
    const onSubmit = (data) => {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      const responseData = {
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_shipment_status: data.contract_shipment_status,
        record_type: data.record_type,
        locked: data.locked,
        parent_shipment: data.parent_shipment,
        order_quantity: data.order_quantity,
        shipping_term: data.shipping_term,
        price: data.price,
        price_type: data.price_type,
        price_basis: data.price_basis,
        sales_price_margin: data.sales_price_margin,
        price_pmt_in_words: data.price_pmt_in_words,
        //  laycan_year_from:data.laycan_year_from,
        //  laycan_year_to:data.laycan_year_to,
        //  laycan_month_from:data.laycan_month_from,
        laycan_month_to: data.laycan_month_to,
        laycan_from_date: ones,
        laycan_to_date: twos,

        quantity_type: data.quantity_type,
        quantity: data.quantity,
        quantity_from: data.quantity_from,
        quantity_to: data.quantity_to,
        no_of_shipments: data.no_of_shipments,
        child_shipment_count: data.child_shipment_count,
        shipment_quantity_tolerance: data.shipment_quantity_tolerance,
        tcf_count: data.tcf_count,
        product_count: data.product_count,
        no_of_orders: data.no_of_orders,
        order_city: data.order_city,
      };
      const dataWithRoute = {
        API_URL:"/add/contract/shipments",
      };
      function determineFormURL() {
        return API_URL; // Replace with your actual logic
      }

      const formURL = determineFormURL();

      // Get the route corresponding to the form URL
      const route = dataWithRoute[formURL];

      console.log("Test" + JSON.stringify(responseData));
      if (route) {
        // Construct the complete URL by combining the base URL with the route
        const url = `${API_URL}${route}`;
        console.log(url);
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);

          fetch(url, {
            method: "POST",
            body: JSON.stringify(responseData),
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())

            .then((response) => {
              console.log(response);
              toast.success("Contract Shipment successfully", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              navigate(
                "/shipment/contract-shipment-details/" +
                  response.contract_ship_id
              );
              reset();
            });
        }
      }
    };

    return (
      <div className="priceAccount" id="view-contract-ship">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Add Contract Shippment
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Shipment / Add Shippment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} id="price">
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Shipment Detail</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="IDR">IDR </option>
                    <option value="USD">USD </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                    defaultValue={account_record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract"
                    placeholder="Contract"
                    {...register("contract", { required: false })}
                  >
                    <option value="">Select</option>



{companies.length>0?(
                 <>
                                         {companies.map((x) => {
                      return (
                        <option value={x.rwood_contract_number}>
                          {x.rwood_contract_number}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
                  </Form.Select>
                  {errors.contract && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract shipment status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_shipment_status"
                    placeholder="Contract shipment status"
                    {...register("contract_shipment_status", {
                      required: false,
                    })}
                  >
                    <option value="">--None--</option>
                    <option value="Activated">Activated</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Draft">Draft</option>
                    <option value="Shipments - Arutmin">
                      Shipments - Arutmin
                    </option>
                    <option value="Cancelled with Penalty">
                      Cancelled with Penalty
                    </option>
                  </Form.Select>
                  {errors.contract_shipment_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6" id="lockeds">
                <input
                  type="checkbox"
                  name="locked"
                  onChange={(e) => setCheck1(e.target.checked)}
                />
                <label>Locked</label>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Price</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping Term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping Term"
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    {...register("price_type", { required: false })}
                  >
                    <option value="">Select</option>



{getPrice.length>0?(
                 <>
                                                            {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price Basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price Basis"
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    className="error-validation"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price pmt in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price pmt in words"
                    name="price_pmt_in_words"
                    {...register("price_pmt_in_words", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_pmt_in_words && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Delivery</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                    className="error-validation"
                    {...register("laycan_year_from", { required: true })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan From Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={one}
                    onChange={(date) => setOne(date)}
                    customInput={<Date1 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_to"
                    placeholder="Laycan Year To"
                    className="error-validation"
                    {...register("laycan_year_to", { required: true })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan To Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={two}
                    onChange={(date) => setTwo(date)}
                    customInput={<Date2 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    className="error-validation"
                    {...register("laycan_month_from", { required: true })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_to"
                    placeholder="Laycan Month To"
                    className="error-validation"
                    {...register("laycan_month_to", { required: true })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Quanitity</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity Type"
                    {...register("quantity_type", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="Base Quantity">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity_type && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of Shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of Shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity"
                    name="quantity"
                    {...register("quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child Shipment Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Child Shipment Count"
                    name="child_shipment_count"
                    {...register("child_shipment_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.child_shipment_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity From"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity From"
                    name="quantity_from"
                    {...register("quantity_from", { required: false })}
                  />
                  {errors.quantity_from && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment Quantity Tolerance"
                    className="error-validation"
                    name="shipment_quantity_tolerance"
                    {...register("shipment_quantity_tolerance", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.shipment_quantity_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity To"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity To"
                    name="quantity_to"
                    {...register("quantity_to", { required: false })}
                  />
                  {errors.quantity_to && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">System Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="TCF Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="TCF Count"
                    name="tcf_count"
                    {...register("tcf_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.tcf_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of orders"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of orders"
                    name="no_of_orders"
                    {...register("no_of_orders", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_orders && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Product Count"
                    name="product_count"
                    {...register("product_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.product_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Order Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Order Quantity"
                    name="order_quantity"
                    {...register("order_quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.order_quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
  if (account_record === "Supplier - Long Term") {
    console.log(account_record);
    const onSubmit = (data) => {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      const responseData = {
        currency: data.currency,
        record_type: data.record_type,
        exchange_rate: data.exchange_rate,
        laycan_from_date: ones,
        laycan_to_date: twos,
        price_type: data.price_type,
        quantity: data.quantity,
        quantity_type: data.quantity_type,
        contract_shipment_status: data.contract_shipment_status,
        quantity_from: data.quantity_from,
        quantity_to: data.quantity_to,
        price: data.price,
        price_pmt_in_words: data.price_pmt_in_words,
        shipment_quantity_tolerance: data.shipment_quantity_tolerance,
        //  laycan_year_from:data.laycan_year_from,
        // laycan_year_to:data.laycan_year_to,
        // laycan_month_from:data.laycan_month_from,
        laycan_month_to: data.laycan_month_to,
        no_of_shipments: data.no_of_shipments,

        contract: data.contract,
        sales_price_margin: data.sales_price_margin,

        order_quantity: data.order_quantity,

        price_basis: data.price_basis,
        shipping_term: data.shipping_term,
        laycan_year: data.laycan_year,
        tcf_count: data.tcf_count,
      };

      const dataWithRoute = {
        API_URL: "/add/contract/shipments",
      };
      function determineFormURL() {
        return API_URL; // Replace with your actual logic
      }

      const formURL = determineFormURL();

      // Get the route corresponding to the form URL
      const route = dataWithRoute[formURL];

      console.log("Test" + JSON.stringify(responseData));
      if (route) {
        // Construct the complete URL by combining the base URL with the route
        const url = `${API_URL}${route}`;
        console.log(url);
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);

          fetch(url, {
            method: "POST",
            body: JSON.stringify(responseData),
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())

            .then((response) => {
              console.log(response);
              toast.success("Contract Shipment successfully", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              navigate(
                "/shipment/contract-shipment-details/" +
                  response.contract_ship_id
              );
              reset();
            });
        }
      }
    };

    return (
      <div className="priceAccount" id="view-contract-ship">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Add Contract Shippment
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / shipment / Add Supplier - Long Term
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} id="price">
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Shipment Detail</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="IDR">IDR </option>
                    <option value="USD">USD </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                    defaultValue={account_record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan From Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={one}
                    onChange={(date) => setOne(date)}
                    customInput={<Date1 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan To Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={two}
                    onChange={(date) => setTwo(date)}
                    customInput={<Date2 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    {...register("price_type", { required: false })}
                  >
                    <option value="">Select</option>



{getPrice.length>0?(
                 <>
                                                            {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity"
                    name="quantity"
                    {...register("quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity From"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity From"
                    name="quantity_from"
                    {...register("quantity_from", { required: false })}
                  />
                  {errors.quantity_from && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity To"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity To"
                    name="quantity_to"
                    {...register("quantity_to", { required: false })}
                  />
                  {errors.quantity_to && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity Type"
                    {...register("quantity_type", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="Base Quantity">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity_type && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price pmt in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price pmt in words"
                    name="price_pmt_in_words"
                    {...register("price_pmt_in_words", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_pmt_in_words && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quantity_tolerance"
                    {...register("shipment_quantity_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quantity_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                    {...register("laycan_year_from", { required: false })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_to"
                    placeholder="Laycan Year To"
                    {...register("laycan_year_to", { required: false })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    {...register("laycan_month_from", { required: false })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_to"
                    placeholder="Laycan Month To"
                    {...register("laycan_month_to", { required: false })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of Shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of Shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract"
                    placeholder="Contract"
                    className="error-validation"
                    {...register("contract", { required: true })}
                  >
                    <option value="">Select</option>




{getPrice.length>0?(
                 <>
                                                            {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
                  </Form.Select>
                  {errors.contract && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract shipment status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_shipment_status"
                    placeholder="Contract shipment status"
                    {...register("contract_shipment_status", {
                      required: false,
                    })}
                  >
                    <option value="">--None--</option>
                    <option value="Activated">Activated</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Draft">Draft</option>
                    <option value="Shipments - Arutmin">
                      Shipments - Arutmin
                    </option>
                    <option value="Cancelled with Penalty">
                      Cancelled with Penalty
                    </option>
                  </Form.Select>
                  {errors.contract_shipment_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Order quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Order quantity"
                    name="order_quantity"
                    {...register("order_quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.order_quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price Basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price Basis"
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping Term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping Term"
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    {...register("laycan_year", { required: false })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="TCF Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="TCF Count"
                    name="tcf_count"
                    {...register("tcf_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.tcf_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }

  if (account_record === "Buyer - Spot") {
    const onSubmit = (data) => {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      const responseData = {
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_shipment_status: data.contract_shipment_status,
        record_type: data.record_type,
        locked: data.locked,
        parent_shipment: data.parent_shipment,
        order_quantity: data.order_quantity,
        shipping_term: data.shipping_term,
        price: data.price,
        price_type: data.price_type,
        price_basis: data.price_basis,
        sales_price_margin: data.sales_price_margin,
        price_pmt_in_words: data.price_pmt_in_words,
         laycan_year_from:data.laycan_year_from,
         laycan_year_to:data.laycan_year_to,
         laycan_month_from:data.laycan_month_from,
        laycan_month_to: data.laycan_month_to,
        laycan_from_date: ones,
        laycan_to_date: twos,

        quantity_type: data.quantity_type,
        quantity: data.quantity,
        quantity_from: data.quantity_from,
        quantity_to: data.quantity_to,
        no_of_shipments: data.no_of_shipments,
        child_shipment_count: data.child_shipment_count,
        shipment_quantity_tolerance: data.shipment_quantity_tolerance,
        tcf_count: data.tcf_count,
        product_count: data.product_count,
        no_of_orders: data.no_of_orders,
        order_quantity: data.order_quantity,
        parent_shipment: data.parent_shipment,
      };
      const dataWithRoute = {
        API_URL: "/add/contract/shipments",
      };
      function determineFormURL() {
        return API_URL; // Replace with your actual logic
      }

      const formURL = determineFormURL();

      // Get the route corresponding to the form URL
      const route = dataWithRoute[formURL];

      console.log("Test" + JSON.stringify(responseData));
      if (route) {
        // Construct the complete URL by combining the base URL with the route
        const url = `${API_URL}${route}`;
        console.log(url);
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);

          fetch(url, {
            method: "POST",
            body: JSON.stringify(responseData),
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())

            .then((response) => {
              console.log(response);
              toast.success("Contract Shipment successfully", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              navigate(
                "/shipment/contract-shipment-details/" +
                  response.contract_ship_id
              );
              reset();
            });
        }
      }
    };

    return (
      <div className="priceAccount" id="view-contract-ship">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Add Contract Shippment
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Shipment / Add Shippment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} id="price">
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Shipment Detail</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="IDR">IDR </option>
                    <option value="USD">USD </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                    defaultValue={account_record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract"
                    placeholder="Contract"
                    {...register("contract", { required: false })}
                  >
                    <option value="">Select</option>



{companies.length>0?(
                 <>

                    {companies.map((x) => {
                      return (
                        <option value={x.rwood_contract_number}>
                          {x.rwood_contract_number}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
                  </Form.Select>
                  {errors.contract && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract shipment status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_shipment_status"
                    placeholder="Contract shipment status"
                    {...register("contract_shipment_status", {
                      required: false,
                    })}
                  >
                    <option value="">--None--</option>
                    <option value="Activated">Activated</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Draft">Draft</option>
                    <option value="Shipments - Arutmin">
                      Shipments - Arutmin
                    </option>
                    <option value="Cancelled with Penalty">
                      Cancelled with Penalty
                    </option>
                  </Form.Select>
                  {errors.contract_shipment_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Order Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Order Quantity"
                    name="order_quantity"
                    {...register("order_quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.order_quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent Shipment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent Shipment"
                    name="parent_shipment"
                    {...register("parent_shipment", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.parent_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6" id="lockeds">
                <input
                  type="checkbox"
                  name="locked"
                  onChange={(e) => setCheck1(e.target.checked)}
                />
                <label>Locked</label>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Price</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    {...register("price_type", { required: false })}
                  >
                    <option value="">Select</option>


{getPrice.length>0?(
                 <>

                          {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price Basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price Basis"
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping Term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping Term"
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price pmt in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price pmt in words"
                    name="price_pmt_in_words"
                    {...register("price_pmt_in_words", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_pmt_in_words && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Delivery</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                    className="error-validation"
                    {...register("laycan_year_from", { required: true })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan From Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={one}
                    onChange={(date) => setOne(date)}
                    customInput={<Date1 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_to"
                    placeholder="Laycan Year To"
                    className="error-validation"
                    {...register("laycan_year_to", { required: true })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan To Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={two}
                    onChange={(date) => setTwo(date)}
                    customInput={<Date2 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    className="error-validation"
                    {...register("laycan_month_from", { required: true })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_to"
                    placeholder="Laycan Month To"
                    className="error-validation"
                    {...register("laycan_month_to", { required: true })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Quanitity</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity Type"
                    {...register("quantity_type", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="Base Quantity">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity_type && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of Shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of Shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity"
                    name="quantity"
                    {...register("quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child Shipment Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Child Shipment Count"
                    name="child_shipment_count"
                    {...register("child_shipment_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.child_shipment_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity From"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity From"
                    name="quantity_from"
                    {...register("quantity_from", { required: false })}
                  />
                  {errors.quantity_from && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment Quantity Tolerance"
                    className="error-validation"
                    name="shipment_quantity_tolerance"
                    {...register("shipment_quantity_tolerance", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.shipment_quantity_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity To"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity To"
                    name="quantity_to"
                    {...register("quantity_to", { required: false })}
                  />
                  {errors.quantity_to && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">System Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="TCF Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="TCF Count"
                    name="tcf_count"
                    {...register("tcf_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.tcf_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of orders"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of orders"
                    name="no_of_orders"
                    {...register("no_of_orders", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_orders && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Product Count"
                    name="product_count"
                    {...register("product_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.product_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Order Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Order Quantity"
                    name="order_quantity"
                    {...register("order_quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.order_quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }

  if (account_record === "Supplier - Spot") {
    console.log(account_record);
    const onSubmit = (data) => {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      const responseData = {
        currency: data.currency,
        record_type: data.record_type,
        exchange_rate: data.exchange_rate,
        laycan_from_date: ones,
        laycan_to_date: twos,
        price_type: data.price_type,
        quantity: data.quantity,
        quantity_type: data.quantity_type,
        contract_shipment_status: data.contract_shipment_status,
        quantity_from: data.quantity_from,
        quantity_to: data.quantity_to,
        price: data.price,
        price_pmt_in_words: data.price_pmt_in_words,
        shipment_quantity_tolerance: data.shipment_quantity_tolerance,
        //  laycan_year_from:data.laycan_year_from,
        // laycan_year_to:data.laycan_year_to,
        // laycan_month_from:data.laycan_month_from,
        laycan_month_to: data.laycan_month_to,
        no_of_shipments: data.no_of_shipments,

        contract: data.contract,
        sales_price_margin: data.sales_price_margin,

        order_quantity: data.order_quantity,

        price_basis: data.price_basis,
        shipping_term: data.shipping_term,
        laycan_year: data.laycan_year,
        tcf_count: data.tcf_count,
      };

      const dataWithRoute = {
        API_URL: "/add/contract/shipments",
      };
      function determineFormURL() {
        return API_URL; // Replace with your actual logic
      }

      const formURL = determineFormURL();

      // Get the route corresponding to the form URL
      const route = dataWithRoute[formURL];

      console.log("Test" + JSON.stringify(responseData));
      if (route) {
        // Construct the complete URL by combining the base URL with the route
        const url = `${API_URL}${route}`;
        console.log(url);
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);

          fetch(url, {
            method: "POST",
            body: JSON.stringify(responseData),
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())

            .then((response) => {
              console.log(response);
              toast.success("Contract Shipment successfully", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              navigate(
                "/shipment/contract-shipment-details/" +
                  response.contract_ship_id
              );
              reset();
            });
        }
      }
    };

    return (
      <div className="priceAccount" id="view-contract-ship">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Add Contract Shippment
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / shipment / Add Supplier - Long Term
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} id="price">
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Shipment Detail</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="IDR">IDR </option>
                    <option value="USD">USD </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                    defaultValue={account_record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan From Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={one}
                    onChange={(date) => setOne(date)}
                    customInput={<Date1 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Laycan To Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={two}
                    onChange={(date) => setTwo(date)}
                    customInput={<Date2 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    {...register("price_type", { required: false })}
                  >
                    <option value="">Select</option>

                    {getPrice.length>0?(
                 <>

                          {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}

                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity"
                    name="quantity"
                    {...register("quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity From"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity From"
                    name="quantity_from"
                    {...register("quantity_from", { required: false })}
                  />
                  {errors.quantity_from && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity To"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Quantity To"
                    name="quantity_to"
                    {...register("quantity_to", { required: false })}
                  />
                  {errors.quantity_to && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity Type"
                    {...register("quantity_type", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="Base Quantity">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity_type && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price pmt in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price pmt in words"
                    name="price_pmt_in_words"
                    {...register("price_pmt_in_words", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_pmt_in_words && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quantity_tolerance"
                    {...register("shipment_quantity_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quantity_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                    {...register("laycan_year_from", { required: false })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_to"
                    placeholder="Laycan Year To"
                    {...register("laycan_year_to", { required: false })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    {...register("laycan_month_from", { required: false })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_from && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_to"
                    placeholder="Laycan Month To"
                    {...register("laycan_month_to", { required: false })}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_to && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of Shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of Shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract"
                    placeholder="Contract"
                    className="error-validation"
                    {...register("contract", { required: true })}
                  >
                    <option value="">Select</option>

                    {companies.length>0?(
                 <>

                          {companies.map((x) => {
                      return (
                        <option value={x.rwood_contract_number}>
                          {x.rwood_contract_number}
                        </option>
                      );
                    })}


                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}

                  </Form.Select>
                  {errors.contract && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract shipment status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_shipment_status"
                    placeholder="Contract shipment status"
                    {...register("contract_shipment_status", {
                      required: false,
                    })}
                  >
                    <option value="">--None--</option>
                    <option value="Activated">Activated</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Draft">Draft</option>
                    <option value="Shipments - Arutmin">
                      Shipments - Arutmin
                    </option>
                    <option value="Cancelled with Penalty">
                      Cancelled with Penalty
                    </option>
                  </Form.Select>
                  {errors.contract_shipment_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Order quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Order quantity"
                    name="order_quantity"
                    {...register("order_quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.order_quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price Basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price Basis"
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping Term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping Term"
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="GCV (ARB)">GCV (ARB)</option>
                    <option value="GCV (ADB)">GCV (ADB)</option>
                    <option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    {...register("laycan_year", { required: false })}
                  >
                    <option value="">---None---</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="TCF Count"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="TCF Count"
                    name="tcf_count"
                    {...register("tcf_count", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.tcf_count && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContractShippment;
