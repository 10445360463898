import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AiFillDiff } from "react-icons/ai";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import Footer from '../Footer'
import { API_URL } from '../config';
import Header from '../Header'
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const QuoteProductList = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState({});
const [loading, setLoading] = useState(false);
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $(".contactAccount").css({"min-height":(window_height)+"px"})


  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(API_URL+'get/all/quotes', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const dataResponse = response.data.new_product;
      console.log(response)
      setLoading(false);
      setDatas(dataResponse);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Product List </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Product / Product list</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to='/pricebook/add-pricebook'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Pricebook</Link>
        </button> */}

      </div>
    </div>
  </div>
  <div class='tasks'>
  {loading ? <p id='loading'>Loading Data..</p>
    :<table id='tables'>
    <thead>
    <tr>
    <th>Product No</th>
      <th>Product Name</th>
      <th>Product Month</th>
      <th>Buyer Name</th>
      <th>Purchase Contract</th>
      <th>Supplier</th>
      <th>Description</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
      <td><Link to={"/product/product-detail/"+x.id}>{x.product_no}</Link></td>
      <td>{x.product_name}</td>
      <td>{x.product_month}</td>
      <td>{x.buyer_name}</td>
      <td>{x.purchase_contract}</td>
      <td>{x.supplier}</td>
      <td>{x.product_description}</td>
      <td>
      <Link to={"/product/product-detail/"+x.id}><FaPencilAlt/></Link><Link to={"" }><AiFillDiff/></Link>
       </td>
    </tr>
  ))
) : (
  <tr>
    <td></td><td></td>
    <td></td>
    <td></td>
    <td>No data available</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>
    }
    </div>
    </div>
    <Footer/>
    </div>
  );
};

export default QuoteProductList;