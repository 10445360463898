import React, { useState, useEffect } from "react";
import axios from "axios";
import { AES, enc } from "crypto-js";
import { useNavigate, Link } from "react-router-dom";
import Header from "../Header";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer";
import { API_URL } from '../config';
import { useLocation } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import Popup from "reactjs-popup";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function BuyerDebitNoteFields({ data, index, handleInputChange }) {
  return (
    <td>
      <select
        name="line_item_type"
        value={data.line_item_type}
        className="form-control line-item-type"
        onChange={(e) => handleInputChange(index, e)}
      >
        <option value="">---Select---</option>
        <option value="GCV Variation">GCV Variation</option>
        <option value="Price Difference">Price Difference</option>
        <option value="Dead Freight Receivable">Dead Freight Receivable</option>
        <option value="Vessel Detention">Vessel Detention</option>
        <option value="Despatch">Umpire Analysis - PENDING</option>
        <option value="Demurrage">Demurrage</option>
      </select>
    </td>
  );
}

function SupplierFields({ data, index, handleInputChange }) {
  return (
    <td>
      <select
        name="name"
        value={data.name}
        onChange={(e) => handleInputChange(index, e)}
      >
        <option value="Option3">Option 3</option>
        <option value="Option4">Option 4</option>
      </select>
    </td>
  );
}

function Payreceiptdetails() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const location = useLocation();
  const [updateData, setFormDataUpdate] = useState([]);
const navigate=useNavigate()
  const { ids, recordtype, order_id } = location.state;
  const [order_data, setOrder_data] = useState([]);

  const [orderID, setorderID] = useState();

  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const post_id = parseInt(ids, 10);



  const handleRowClick = (productCode) => {
    setSelectedProductCode(productCode);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (selectedProductCode) {
    }
  }, [selectedProductCode]);



  const [formData1, setFormData1] = useState({
    record_type: recordtype,
    account: order_data.account_name,
    currency: order_data.currency,
    exchange_rate: parseFloat(order_data.exchange_rate),
    payment_receipt_date: "2023-07-12",
    rwood_bank_master: order_data.rwood_bank_master,
    mode_of_payment: order_data.mode_of_payment,
    payment_receipt_ref_no: order_data.payment_receipt_ref_no,
    description: order_data.description,
    remark: order_data.remark,
    document_discount_date: "2023-07-12",
    actual_payment_recieved_date: "2023-07-12",

    locked: true,
    company: order_data.company,

    receipt_status: order_data.receipt_status,
  });

  // console.log(recordorder_idtype)
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/postship_byID/" + post_id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data);
      const orderId = data.order_id;
      const intValue = parseInt(orderId, 10);
      setorderID(intValue);
      console.log(intValue);

      const order_details = response.data.postshipment_details[0];

      setFormDataUpdate(order_details);
      // console.log(order_details);
    } catch (error) {
      console.error(error);
    }
  };

  const [lineItem, setlineItem] = useState([]);
  useEffect(() => {
    lineItemList();
  }, [decryptedAccessToken]);

  const lineItemList = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/financeconfig",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.all_finance;
      setlineItem(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };



  const [lineitems, setLineitems]=useState()
  useEffect(() => {
    fetchlineitem();
  }, [decryptedAccessToken]);

  const fetchlineitem = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/pay_recieve_lineitem",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.pay_lineitem_all;
      console.log(data);
       setLineitems(data)

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchDataReceipt();
  }, [decryptedAccessToken]);

  const fetchDataReceipt = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/orderbyID/" + order_id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data)
      const order_details = response.data;
      const orderDetail = order_details.order_detail[0];
      setOrder_data(orderDetail);

      console.log(orderDetail);
    } catch (error) {
      console.error(error);
    }
  };



  const [bill, setBill] = useState([]);
  useEffect(() => {
    billing();
  }, [decryptedAccessToken]);

  const billing = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/pay_recieve",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.pay_detail_all;
      setBill(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };




  const [selectedOption, setSelectedOption] = useState('');

  const handleInputSelect = (e) => {
    setSelectedOption(e.target.value);

  }

  useEffect(() => {
    // This code will run whenever selectedOption changes
    console.log('Selected option changed:', selectedOption);

  }, [selectedOption]);





  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    return savedFormData
      ? JSON.parse(savedFormData)
      : [
          {
            currency: "",
            exchange_rate: "",
            rw_payment_receipt: "",
            payment_receipt_date: "",
            account: "",
            amount: "",
            description: "",
            locked: "",
            record_type: recordtype,
            company: "",
            line_item: "",
            line_item_type: selectedProductCode,
            order: order_data.order_code_no,
            tcf_no: "",
            contract: "",
            post_shipment_document: "",
            buyer_product: "",
            document_reference_type: "",
            campaign: "",
            opportunity: "",
            rw_billing: "",
            rw_pay_receivable_lineitem: "",
            vessel_name: order_data.vessel_barge_name,
            bl_date: "",

            buyer: "",
            supplier: "",
            destination: "",

            payment_dr_acc_no: "",
            payment_dr_acc: "",
            payment_dr_message: "",
            payment_cr_acc: "",
            payment_cr_acc_no: "",
            payment_cr_messaage: "",
          },
        ];
  });

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formData1, [name]: value });
  };

  const handleAddField = () => {
    setFormData([
      ...formData,
      {
        currency: "",
        exchange_rate: "",
        rw_payment_receipt: "",
        payment_receipt_date: "",
        account: "",
        amount: "",
        description: "",
        locked: "",
        record_type: recordtype,
        company: "",
        line_item: "",
        line_item_type: selectedProductCode,
        order: order_data.order_code_no,
        tcf_no: "",
        contract: "",
        post_shipment_document: "",
        buyer_product: "",
        document_reference_type: "",
        campaign: "",
        opportunity: "",
        rw_billing: "",
        rw_pay_receivable_lineitem: "",
        vessel_name: order_data.vessel_barge_name,
        bl_date: "",

        buyer: "",
        supplier: "",
        destination: "",

        payment_dr_acc_no: "",
        payment_dr_acc: "",
        payment_dr_message: "",
        payment_cr_acc: "",
        payment_cr_acc_no: "",
        payment_cr_messaage: "",
      },
    ]);
  };

  const handleRemoveField = (index) => {
    if (index === 0) {
      return;
    }
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };
  const [responseId, setId] = useState({});
  console.log(responseId);


  const renderNameFields = (data, index) => {
    if (data.buyer === "Buyer - Debit Note") {
      return (
        <BuyerDebitNoteFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else if (data.buyer === "Supplier") {
      return (
        <SupplierFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else {
      return null;
    }
  };

  const [lockedCheck, setLockedCheck] = useState(false);

  const handleSubmit = (e) => {
    const requestData = {
      rw_payreceipt_lineitem: formData,
    };

    sendDataToAPI2(requestData);
  };

  const sendDataToAPI2 = async (requestData) => {
    const id = 1;
    const api1Url = `${API_URL}add/postship/rw_pay_receipt_detail/${post_id}`;

    // apiurl1  datas to send

    //  end apiurl datas
    console.log(formData1)
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response1 = await axios.post(api1Url, formData1, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${decryptedAccessToken}`,
          },
        });

        if (response1.status === 200) {
          $(".clear").val("");
          console.log("Data sent to API 1 successfully");
          const response1Id = response1.data.rw_receipt_id;
          console.log(response1Id);

          const response2 = await axios.post(
            `${API_URL}add/postship/pay_receipt_lineitem/${post_id}/${response1Id}`,

            requestData,

            //
            // `${API_URL}add/postship/pay_receipt_lineitem/<postship_id>/${response1Id}/${post_id}`,
            // datas  to send

            //         currency :,
            // exchange_rate = int
            // rw_payment_receipt :,
            // payment_receipt_date = date
            // account :,
            // amount = int
            // description :,
            // locked = bool
            // record_type :,
            // company :,
            // line_item :,
            // line_item_type :,
            // order :,
            // tcf_no :,
            // contract :,
            // post_shipment_document :,
            // buyer_product :,
            // document_reference_type :,
            // campaign :,
            // opportunity :,
            // rw_billing :,
            // rw_pay_receivable_lineitem :,
            // vessel_name :,
            // bl_date = date
            // created_by :,
            // buyer :,
            // supplier :,
            // destination :,
            // last_modified_by :,
            // payment_dr_acc_no :,
            // payment_dr_acc :,
            // payment_dr_message :,
            // payment_cr_acc :,
            // payment_cr_acc_no :,
            // payment_cr_messaage :,

            //
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${decryptedAccessToken}`,
              },
            }
          );
          console.log(response2);
          if (response2.status === 200) {
            //swindow.location.reload()
            toast.success("Added Successfully", {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate(`/postshipment/payable-receipt-details/${post_id}`)
          } else {
            console.error(
              `Failed to send data to API 2. Status code: ${response2.status}`
            );
          }
        } else {
          console.error(
            `Failed to send data to API 1. Status code: ${response1.status}`
          );
        }
      } else {
        console.error("No access token found.");
      }
    } catch (error) {
      console.error("Error sending data to API 1 or API 2:", error);
    }
  };

  const [company, setCompany] = useState([]);
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/rwoodcompany",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
            setCompany(data);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormDataUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div>
      <Header />
      <div className="page-header" id="">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Add Receipt</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard / Payable / Add Receipt
            </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
          </div>
        </div>
      </div>
      <div className="tasks">
      <p className='edit-cancel'> <Link to={`/postshipment/postshipment-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

        <Row className="mb-3">
          <h4 className="heading">RW Payment/Receipt Information</h4>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Record Type"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Record Type"
                name="record_type"
                defaultValue={recordtype}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6" id="lockeds">
            <input
              type="checkbox"
              name="locked"
              onChange={(e) => setLockedCheck(e.target.checked)}
            />
            <label>Locked</label>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Account"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Account"
                name="account"
                className="clear"
                defaultValue={formData1.account_name || order_data.account_name}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Company"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="company"
                  placeholder="Company"
                  value={formData1.company || updateData.company}
                  onChange={handleChange1}
                >
                  <option value="">Select</option>
                  {company.map((x) => {
                    return (
                      <option value={x.company_name}>{x.company_name}</option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>




          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="payment receipt ref no"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="payment receipt ref no"
                name="payment_receipt_ref_no"
                className="clear"
                defaultValue={formData1.payment_receipt_ref_no || ""}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="rwood bank master"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="rwood bank master"
                name="rwood_bank_master"
                className="clear"
                defaultValue={formData1.rwood_bank_master || ""}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="payment receipt date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="payment receipt date"
                name="payment_receipt_date"
                className="clear"
                defaultValue={formData1.payment_receipt_date || ""}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="mode of payment"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="mode_of_payment"
                placeholder="mode of payment"
                defaultValue={formData1.mode_of_payment || ""}
                onChange={handleChange1}
              >
                <option value="">--None--</option>
                <option value="Letter Of Credit" selected="selected">
                  Letter Of Credit
                </option>
                <option value="Telegraphic Transfer">
                  Telegraphic Transfer
                </option>
                <option value="Cheque">Cheque</option>
                <option value="Cash">Cash</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="document discount date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="document discount date"
                name="document_discount_date"
                defaultValue={formData1.document_discount_date || ""}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="actual payment recieved date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="actual payment recieved date"
                name="actual_payment_recieved_date"
                defaultValue={formData1.actual_payment_recieved_date || ""}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="receipt_status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="receipt_status"
                placeholder="receipt_status"
                defaultValue={formData1.receipt_status || ""}
                onChange={handleChange1}
                className="clear"
              >
                <option value="">--None--</option>
                <option value="Raised" selected="selected">
                  Raised
                </option>
                <option value="Paid">Paid</option>
                <option value="Partial Payment">Partial Payment</option>
                <option value="Adjusted">Adjusted</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Settled By Arutmin">Settled By Arutmin</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Row>
        <h4 className="heading">RW Payment Receipt Line Items</h4>
        <table id="rw-table">
          <thead>
            <tr>
              <th>Order</th>
              <th>Rw pay receivable lineitem</th>

              <th>RW Billing</th>
              <th>Line Item Type</th>
              <th>Line Item</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Vessel name</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name="order"
                    placeholder="Order"
                    className="form-control"
                    defaultValue={order_data.order_no||data.order}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>
                <td>
                  {/* <input
                    type="text"
                    name="rw_pay_receivable_lineitem"
                    className="form-control clear"
                    placeholder="Rw pay receivable lineitem"
                    defaultValue={data.rw_pay_receivable_lineitem || ""}
                    onChange={(e) => handleInputChange(index, e)}
                  /> */}
                  <select type="text"
                    name="rw_pay_receivable_lineitem"
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}>
                  {lineitems?.map((x, index) => (
                    <option value={x.rw_payment_receivable_lineitem}>{x.rw_payment_receivable_lineitem}</option>

))}
                  </select>
                </td>

                <td>
                  {/* <input
                    type="text"
                    name="rw_billing"
                    placeholder="RW Billing"
                    className="form-control clear"
                    defaultValue={data.rw_billing || ""}
                    onChange={(e) => handleInputChange(index, e)}
                  /> */}
                  <select type="text"
                    name="rw_billing"
                    className="form-control clear"
                    defaultValue={selectedOption||order_data.rw_code_no}
                    onChange={handleInputSelect}>
                  {bill?.map((x) => (
                    <option value={x.rw_code_no}>{x.rw_code_no}</option>

))}
                  </select>
                </td>


                <td>
                <div className="lineGroup">      <input
        type="text"
        placeholder="Line Item Type"
        name="line_item_type"
        value={selectedProductCode}
        readOnly
      />
      <button
        className="button select-search"
        onClick={() => setIsPopupOpen(true)}
      >
        <IoSearch/>

      </button>
      </div>
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
      >
        <table className="table table-hovered">
          <thead>
            <tr>
              <th>Billing Config Name</th>
              <th>Line Item</th>
              <th>Lineitem Type</th>
            </tr>
          </thead>
          <tbody>
            {lineItem.map((y, i) => (
              <tr
                key={i}
                onClick={() => handleRowClick(y.billing_config_name)}
              >
                <td>{y.billing_config_name}</td>
                <td>{y.line_item}</td>
                <td>{y.lineitem_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Popup>

                  </td>


                <td>
                  {/* <input
                    type="text"
                    name="line_item"
                    placeholder="Line Item"
                    defaultValue={data.line_item || ""}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  /> */}
                  <select className="form-control lineitem">
                  {lineItem.map((x) => (
                    <>
                    <option value={x.billing_config_name}>{x.billing_config_name}</option>
                    </>

                  ))}
                  </select>
                </td>

                <td>
                  <input
                    type="text"
                    name="amount"
                    placeholder="Amount"
                    defaultValue={data.amount || ""}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="description"
                    placeholder="Description"
                    defaultValue={data.description || ""}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="vessel_name"
                    placeholder="vessel name"
                    defaultValue={data.vessel_name || order_data.vessel_barge_name}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>

                <td>{renderNameFields(data, index)}</td>
                <td>
                  <FaTrashAlt
                    className="cursor"
                    onClick={() => handleRemoveField(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className="text-center">
          <button
            type="button "
            className="add-line-item"
            onClick={handleAddField}
          >
            Add Line Item
          </button>
          <button className="add-line-item" onClick={handleSubmit}>
            Submit
          </button>
        </p>
      </div>
      <p></p><p></p>
      <Footer />
    </div>
  );
}

export default Payreceiptdetails;