import React, { Component } from 'react'


export default class Navigation extends Component {
    render() {
        return (
           <div className='container-fluid'>
            <div className='row'>
            <div className='col-md-6'>
                sasa
                </div><div className='col-md-6'>
                sasa
                </div>   
            </div>
            </div>
        )
    }
}