

import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import $ from 'jquery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ProductCreateView() {
   const [filterConditions, setFilterConditions] = useState([
    { input: "", operator: "", values: [] },
 
  ]);

  const [account_keys, setaccount_keys] = useState([]);

  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [formData, setFormData] = useState({
    view_name: "",
    unique_name: "",
    all_productmasters: "",
    my_productmasters: "",
  });
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleFieldSelection = (e) => {
    const options = Array.from(e.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setSelectedOptions(options);
  };

  const handleAddFields = () => {
    const newFields = selectedOptions.filter(
      (option) => !selectedFields.includes(option)
    );

    setSelectedFields((prevFields) => [...prevFields, ...newFields]);
    setAvailableFields((prevFields) =>
      prevFields.filter((field) => !newFields.includes(field))
    );
    setSelectedOptions([]);
  };

  const handleRemoveFields = () => {
    setAvailableFields((prevFields) => {
      const updatedFields = [...prevFields, ...selectedOptions].filter(
        (field, index, self) => index === self.findIndex((f) => f === field)
      );
      

      return updatedFields;
    });
    setSelectedFields((prevFields) =>
      prevFields.filter((field) => !selectedOptions.includes(field))
    );
    setSelectedOptions([]);
  };

  const handleFieldToggle = (field) => {
    if (selectedOptions.includes(field)) {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((option) => option !== field)
      );
    } else {
      setSelectedOptions((prevOptions) => [...prevOptions, field]);
    }
  };

  const handleInputChanges = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL+"get/product_keys", {
          method: "GET",
        });

        if (response.ok) {
          const responseData = await response.json();
          setAvailableFields(responseData.fields);
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const response = await fetch(API_URL+"get/product_keys", {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error ("Request failed");
        }

        const data = await response.json();
        setaccount_keys(data.fields);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchKeys();
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFilterConditions = [...filterConditions];
    const updatedCondition = {
      ...updatedFilterConditions[index],
      [name]: name === "values" ? value.split(",") : value,
    };
    updatedFilterConditions[index] = updatedCondition;
    setFilterConditions(updatedFilterConditions);
  };

  const addFilterCondition = () => {
    if (filterConditions.length < 10) {
      setFilterConditions([
        ...filterConditions,
        { input: "", operator: "", values: [] },
      ]);
    } else {
      alert("You can't add more than 10 filters.");
    }
  };

  const removeFilterCondition = (index) => {
    const updatedFilterConditions = [...filterConditions];
    updatedFilterConditions.splice(index, 1);
    setFilterConditions(updatedFilterConditions);
  };

  const handleSubmit = async (e) => {
        e.preventDefault();
        const isFilterConditionsEmpty =
        filterConditions.length === 1 &&
        filterConditions[0].input === "" &&
        filterConditions[0].operator === "" &&
        filterConditions[0].values.length === 0;
      
        const dataToSend = {
          ...formData,
          filterConditions: isFilterConditionsEmpty ? [] : filterConditions,
          selectedFields: isFilterConditionsEmpty ? [] : selectedFields,
        };
    if (filterConditions.length === 0) {
      alert("You should add filters.");
      return;
    }

    if (!formData.view_name) {
      alert("You should enter a view name.");
      return;
    }

    try {
      const response = await fetch(
        API_URL+"get/product_keys",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
 
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Create New View</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Product Master / Create New View </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>  
    <div id='view-container'>
      <Row className="mb-3">
        <h4 className='heading'>Step 1: Enter View Name</h4>
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="View Name"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
          name="view_name"
          placeholder="View Name"
          value={formData.view_name}
          onChange={handleInputChanges} 
            />
            
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Unique Name"
            className="mb-3"
            
            
          >
            <Form.Control
          type="text"
          name="unique_name"
          value={formData.unique_name}
          onChange={handleInputChanges}
        placeholder="Unique Name"
            />
            
          </FloatingLabel>
        </Form.Group>
        </Row>
      
      <Row className="mb-3">
        <h4 className='heading'>Step 2: Specify Filter Criteria</h4>
        <p>Filter By Owner:</p>
        
        <Form.Group as={Col} md="12">
          
      <input
        className="form-check-input"
        type="checkbox"
        checked={formData.all_productmasters}
        name="all_productmasters"
        id="flexCheckChecked"
        onChange={handleInputChanges}
      />
      <label className="form-check-label" htmlFor="flexCheckChecked">
        All Product Masters
      </label>
        </Form.Group>
        <Form.Group as={Col} md="12">
          
      <input
        className="form-check-input"
        type="checkbox"
        checked={formData.my_productmasters}
        name="my_productmasters"
        id="flexCheckDefault"
        onChange={handleInputChanges}
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        My Product Masters
      </label>

        </Form.Group>
        <p id='filter-by'>Filter By Additional Fields (Optional):</p>
        <Form.Group as={Col} md="12" id='view-filters'>
                {filterConditions.map((condition, index) => (
        <div key={index} >
          <select
            name="input"
            value={condition.input}
            onChange={(e) => handleInputChange(e, index)}
          >
            <option value="">Select an account key</option>
            {account_keys.map((key, keyIndex) => (
              <option key={keyIndex} value={key}>
                {key}
              </option>
            ))}
          </select>
          <select
            name="operator"
            value={condition.operator}
            onChange={(e) => handleInputChange(e, index)}
          >
            <option value="" disabled defaultValue>
              Operator
            </option>
            <option value="equals">equals</option>
            <option value="not_equals">not_equals</option>
            <option value="greater">greater</option>
            <option value="greater_equal">greater_equal</option>
            <option value="less">less</option>
            <option value="less_equal">less_equal</option>
          </select>
          <input
            type="text"
            name="values"
            placeholder="Values"
            value={condition.values.join(",")}
            onChange={(e) => handleInputChange(e, index)}
          />
          <FaTrashAlt id='trash' onClick={() => removeFilterCondition(index)}/>
          
        </div>
      ))}

      <button onClick={addFilterCondition}>Add Filter</button>
      

  </Form.Group>
        </Row>
         <Row className="mb-3 available">
        <h4 className='heading'>Step 3: Select Fields to Display</h4>
        <Form.Group as={Col} md="6">
          <p>Available Fields</p>
          <select
            multiple
            value={selectedOptions}
            className='form-control selectedField'
            onChange={handleFieldSelection} 
          >
            {availableFields.map((field) => (
              <option key={field.id} value={field}>
                {field}
              </option>
            ))}
          </select>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <p>Selected Fields</p>
           <div
          className="selectedFields"
          
        >
          
          {selectedFields.map((field) => (
            <div
              key={field}
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                cursor: "pointer",
                padding: "0px",
                background: selectedOptions.includes(field)
                  ? "lightblue"
                  : "#ffffff",
              }}
              onClick={() => handleFieldToggle(field)}
            >
              {field}
            </div>
          ))}
        </div>
      
        </Form.Group>
        <Form.Group as={Col} id='add-remove' md="12">
      <button
        type="button"
        onClick={handleRemoveFields}
        disabled={!selectedFields.length}
      >
        Remove Selected Fields
      </button>

      <button
        type="button"
        onClick={handleAddFields}
        disabled={!selectedOptions.length}
      >
        Add Selected Fields
      </button>
  </Form.Group>
        </Row>
        <input type="button" className="addaccount-save" onClick={handleSubmit} value='Submit'/>
        
      
      <div className='successDiv'><p></p><p></p></div>
    </div>
    <Footer/>
    </div>
  );
}

export default ProductCreateView;

