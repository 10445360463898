import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";
import Footer from "../Footer";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import { API_URL } from '../config';
import { parse, format, isValid } from 'date-fns';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const PreshipmentDetails = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [post, setPost] = useState([]);
  const[lengths,setLengths]=useState([]);
  const [history, setHistory] = useState([])
  const [startDate1, setStartDate1] = useState('');
  const [startDateExp, setStartDateExp] = useState('');
  const [startDateExp1, setStartDateExp1] = useState('');
  const [startDateExp2, setStartDateExp2] = useState('');
  const [startDateExp3, setStartDateExp3] = useState('');
  const [startDateExp4, setStartDateExp4] = useState('');
  const [startDateExp5, setStartDateExp5] = useState('');
  const [startDateExp6, setStartDateExp6] = useState('');
  const [startDateExp7, setStartDateExp7] = useState('');
  const [startDateExp8, setStartDateExp8] = useState('');
  const [startDateExp9, setStartDateExp9] = useState('');
  const [startDateExp10, setStartDateExp10] = useState('');
  const [startDateExp11, setStartDateExp11] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);


  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Button state
  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);


  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/preship_byID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response)
      const data = response.data;
      const dataResult = response.data.preship_details[0];

      // const opp = response.data[0].opportunity;
      setLengths(data.preship_details.length);
      setDatas(data.preship_details[0]);
      if(lengths){

      const dates1 = dataResult.adding_confirmation_date ? parse(dataResult.adding_confirmation_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDate1(dates1);
      const dates2 = dataResult.master_lc_expiry_date_1 ? parse(dataResult.master_lc_expiry_date_1, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp(dates2);
      const dates3 = dataResult.master_lc_reviewed_date ? parse(dataResult.master_lc_reviewed_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp1(dates3);
      const dates4 = dataResult.master_lc_shipment_date_1 ? parse(dataResult.master_lc_shipment_date_1, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp2(dates4);
      const dates5 = dataResult.master_lc_issued_date ? parse(dataResult.master_lc_issued_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp3(dates5);
      const dates6 = dataResult.master_lc_presentation_date ? parse(dataResult.master_lc_presentation_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp4(dates6);
      const dates7 = dataResult.child_lc_issued_date ? parse(dataResult.child_lc_issued_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp5(dates7);
      const dates8 = dataResult.child_lc_expiry_date ? parse(dataResult.child_lc_expiry_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp6(dates8);
      const dates9 = dataResult.child_lc_shipment_date ? parse(dataResult.child_lc_shipment_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp7(dates9);
      const dates10 = dataResult.child_lc_prepared_date ? parse(dataResult.child_lc_prepared_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp8(dates10);
      const dates11 = dataResult.child_lc_reviewed_date ? parse(dataResult.child_lc_reviewed_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp9(dates11);
      const dates12 = dataResult.child_lc_application_date ? parse(dataResult.child_lc_application_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp10(dates12);
      const dates13 = dataResult.lc_discounting_application_date ? parse(dataResult.lc_discounting_application_date, 'dd/MM/yyyy', new Date()) : null;
      setStartDateExp11(dates13);

      setPost(data.postshipments)
      setHistory(data.history)
      console.log(data.preship_details[0])

      $('#post').DataTable();
      $('#historys').DataTable();
      }

    } catch (error) {
      console.error(error);
    }
  };
  $('#post').DataTable();
  $('#historys').DataTable();
  const handleAdding = (date) => {
    setStartDate1(date);
  };
  const handleAddingExp = (date) => {
    setStartDateExp(date);
  };
  const handleAddingExp1 = (date) => {
    setStartDateExp1(date);
  };
  const handleAddingExp2 = (date) => {
    setStartDateExp2(date);
  };
  const handleAddingExp3 = (date) => {
    setStartDateExp3(date);
  };
  const handleAddingExp4 = (date) => {
    setStartDateExp4(date);
  };
  const handleAddingExp5 = (date) => {
    setStartDateExp5(date);
  };
  const handleAddingExp6 = (date) => {
    setStartDateExp6(date);
  };
  const handleAddingExp7 = (date) => {
    setStartDateExp7(date);
  };
  const handleAddingExp8 = (date) => {
    setStartDateExp8(date);
  };
  const handleAddingExp9 = (date) => {
    setStartDateExp9(date);
  };
  const handleAddingExp10 = (date) => {
    setStartDateExp10(date);
  };
  const handleAddingExp11 = (date) => {
    setStartDateExp11(date);
  };


  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };

  //   const [one, setOne] = useState(new Date());
  //   const [thirteen, setThirteen] = useState(new Date());
  //   const thirteens =
  //      thirteen.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      thirteen.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      thirteen.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date13 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));

  //              const [twele, setTwele] = useState(new Date());
  // const tweles =
  //   twele.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   twele.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   twele.toLocaleDateString("en-US", { day: "2-digit" });
  // const Date12 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name=""
  //     ref={ref}
  //   ></input>
  // ));

  //   const [nine, setNine] = useState(new Date());
  //   const nines =
  //      nine.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      nine.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      nine.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date9 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));

  //   const [eight, setEight] = useState(new Date());
  //   const eights =
  //      eight.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      eight.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      eight.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date8 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));

  //   const [seven, setSeven] = useState(new Date());
  //   const sevens =
  //      seven.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      seven.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      seven.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date7 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));
  //   const [six, setSix] = useState(new Date());
  //   const sixs =
  //      six.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      six.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      six.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date6 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));
  //   const [five, setFive] = useState(new Date());
  //   const fives =
  //      five.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      five.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      five.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date5 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));

  //   const [four, setFour] = useState(new Date());
  //   const fours =
  //      four.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      four.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      four.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date4 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));
  //   const [three, setThree] = useState(new Date());
  //   const threes =
  //      three.toLocaleDateString("en-US", { year: "numeric" }) +
  //      "-" +
  //      three.toLocaleDateString("en-US", { month: "2-digit" }) +
  //      "-" +
  //      three.toLocaleDateString("en-US", { day: "2-digit" });
  //    const Date3 = forwardRef(({ value, onClick, onChange }, ref) => (
  //      <input
  //        value={value}
  //        className="example-custom-input"
  //        onClick={onClick}
  //        onChange={onChange}
  //        name=""
  //        ref={ref}
  //      ></input>
  //    ));
  //   const [two, setTwo] = useState(new Date());

  //   const twos =
  //   two.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   two.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   two.toLocaleDateString("en-US", { day: "2-digit" });
  // const Date2 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name="renewal_date"
  //     ref={ref}
  //   ></input>
  // ));
  const [check, setCheck1] = useState(false)

  // const [ten, setTen] = useState(new Date());
  // const tens =
  //    ten.toLocaleDateString("en-US", { year: "numeric" }) +
  //    "-" +
  //    ten.toLocaleDateString("en-US", { month: "2-digit" }) +
  //    "-" +
  //    ten.toLocaleDateString("en-US", { day: "2-digit" });
  //  const Date10 = forwardRef(({ value, onClick, onChange }, ref) => (
  //    <input
  //      value={value}
  //      className="example-custom-input"
  //      onClick={onClick}
  //      onChange={onChange}
  //      name=""
  //      ref={ref}
  //    ></input>
  //  ));

  //  const [seventeen, setSeventeen] = useState(new Date());
  //  const seventeens =
  //     seventeen.toLocaleDateString("en-US", { year: "numeric" }) +
  //     "-" +
  //     seventeen.toLocaleDateString("en-US", { month: "2-digit" }) +
  //     "-" +
  //     seventeen.toLocaleDateString("en-US", { day: "2-digit" });
  //   const Date17 = forwardRef(({ value, onClick, onChange }, ref) => (
  //     <input
  //       value={value}
  //       className="example-custom-input"
  //       onClick={onClick}
  //       onChange={onChange}
  //       name=""
  //       ref={ref}
  //     ></input>
  //   ));
  //  const [eleven, setEleven] = useState(new Date());
  //  const elevens =
  //     eleven.toLocaleDateString("en-US", { year: "numeric" }) +
  //     "-" +
  //     eleven.toLocaleDateString("en-US", { month: "2-digit" }) +
  //     "-" +
  //     eleven.toLocaleDateString("en-US", { day: "2-digit" });
  //   const Date11 = forwardRef(({ value, onClick, onChange }, ref) => (
  //     <input
  //       value={value}
  //       className="example-custom-input"
  //       onClick={onClick}
  //       onChange={onChange}
  //       name=""
  //       ref={ref}
  //     ></input>
  //   ));




  const [accountName, setAccountName] = useState([]);
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL+"get/buyer", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data;
      setAccountName(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };




  const [rwoodbank, setRwoodbank] = useState([]);

  useEffect(() => {

    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodbank', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
      );
      const data = response.data.rwood_bank;
      console.log(response)
      setRwoodbank(data);

    } catch (error) {
      console.error(error);
    }
  };


  const [lockeds, setLockeds] = useState(null);

  useEffect(() => {
    if (lengths > 0) {
      const check_box = datas.locked; // Assuming locked is a property of the first element
      setLockeds(check_box);
    }
  }, [datas]);

  useEffect(() => {
    console.log('Value of lockeds:', lockeds); // Log lockeds
  }, [lockeds]);
  const handleCheckboxActive0 = (e) => {
    setLockeds(e.target.checked);
  };


  const [accounts, setAccounts] = useState([]);

  useEffect(() => {

    fetchData4();
  }, [decryptedAccessToken]);

  const fetchData4 = async () => {
    try {
      const response = await axios.get(API_URL+'all/accounts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
      );
      const data = response.data.accounts;
      console.log(data)
      setAccounts(data);

    } catch (error) {
      console.error(error);
    }
  };




  const [user, setUser] = useState([]);
  useEffect(() => {
    fetchData3();
  }, [decryptedAccessToken]);

  const fetchData3 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/users', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
      );
      // console.log(response)
      const data = response.data;
      setUser(data);

    } catch (error) {
      console.error(error);
    }
  };

  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", options).format(number);
  };

  const renderShipmentQty = (tcfValues) => {
    if (tcfValues > 0) {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    } else if (tcfValues < 0) {
      return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
    } else {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    }
  };

  const handleSaveEdit = async (date) => {

    const responseData = {
      "rwood_bank": datas.rwood_bank,
      "currency": datas.currency,
      "locked":lockeds,
      "master_lc_no": datas.master_lc_no,
      "other_bank_cost": datas.other_bank_cost,
      "adding_confirmation_date": startDate1 ? format(startDate1, 'dd/MM/yyyy') : null,
      "master_lc_expiry_date_1": startDateExp ? format(startDateExp, 'dd/MM/yyyy') : null,
      "exchange_rate": parseFloat(datas.exchange_rate),
      "master_lc_status": datas.master_lc_status,
      "lc_transfer_cost":parseFloat(datas.lc_transfer_cost),
      "master_lc_reviewed_date": startDateExp1 ? format(startDateExp1, 'dd/MM/yyyy') : null,
      "master_lc_issued_date": startDateExp3 ? format(startDateExp3, 'dd/MM/yyyy') : null,
      "master_lc_shipment_date_1": startDateExp2 ? format(startDateExp2, 'dd/MM/yyyy') : null,
      "master_lc_presentation_date": startDateExp4 ? format(startDateExp4, 'dd/MM/yyyy') : null,
      "child_lc_issued_date": startDateExp5 ? format(startDateExp5, 'dd/MM/yyyy') : null,
      "child_lc_expiry_date": startDateExp6 ? format(startDateExp6, 'dd/MM/yyyy') : null,
      "child_lc_shipment_date": startDateExp7 ? format(startDateExp7, 'dd/MM/yyyy') : null,
      "child_lc_prepared_date": startDateExp8 ? format(startDateExp8, 'dd/MM/yyyy') : null,
      "child_lc_reviewed_date": startDateExp9 ? format(startDateExp9, 'dd/MM/yyyy') : null,
      "child_lc_application_date": startDateExp10 ? format(startDateExp10, 'dd/MM/yyyy') : null,
      "lc_discounting_application_date": startDateExp11 ? format(startDateExp11, 'dd/MM/yyyy') : null,
      "master_lc_reviewed_by": datas.master_lc_reviewed_by,
      "master_lc_place_of_expiry": datas.master_lc_place_of_expiry,
      "master_lc_issuing_bank": datas.master_lc_issuing_bank,
      "commodity": datas.commodity,
      "issuing_bank_swift_code": datas.issuing_bank_swift_code,
      "master_lc_unit_price": parseFloat(datas.master_lc_unit_price),
      "master_lc_applicant": datas.master_lc_applicant,
      "master_lc_quantity": parseFloat(datas.master_lc_quantity),
      "master_lc_amount": parseFloat(datas.master_lc_amount),
      "master_lc_quantity_tolerance": parseFloat(datas.master_lc_quantity_tolerance),
      "master_lc_tolerance": parseFloat(datas.master_lc_tolerance),
      "price_basis": datas.price_basis,
      "master_lc_consignee": datas.master_lc_consignee,
      "notify_party_1": datas.notify_party_1,
      "master_lc_desination": datas.master_lc_desination,
      "notify_party": datas.notify_party,
      "master_lc_port_of_loading": datas.master_lc_port_of_loading,
      "lc_type": datas.lc_type,
      "master_lc_presentation_days": parseFloat(datas.master_lc_presentation_days),
      "child_lc_no": datas.child_lc_no,
      "child_lc_status": datas.child_lc_status,
      "child_lc_port_of_loading": datas.child_lc_port_of_loading,
      "child_lc_place_of_expiry": datas.child_lc_place_of_expiry,
      "child_lc_quantity": parseFloat(datas.child_lc_quantity),
      "child_lc_place_of_expiry": datas.child_lc_place_of_expiry,
      "child_lc_applicant": datas.child_lc_applicant,
      "child_lc_unit_price": parseFloat(datas.child_lc_unit_price),
      "child_lc_amount": parseFloat(datas.child_lc_amount),
      "child_lc_prepared_by": datas.child_lc_prepared_by,
      "child_lc_notify_party": datas.child_lc_notify_party,
      "child_lc_consignee": datas.child_lc_consignee,
      "child_lc_rwood_bank": datas.child_lc_rwood_bank,
      "child_lc_destination": datas.child_lc_destination,
      "child_lc_shipper_bank": datas.child_lc_shipper_bank,
      "child_lc_reviewed_by": datas.child_lc_reviewed_by,
      "child_lc_tolerance":parseFloat(datas.child_lc_tolerance),
      "child_lc_presentation_days": parseFloat(datas.child_lc_presentation_days),
      "days_to_add_into_child_lc_period": parseFloat(datas.days_to_add_into_child_lc_period),
      "libor_rate_n_bank_cost_rate": parseFloat(datas.libor_rate_n_bank_cost_rate),
      "days_to_add_into_adding_confirmation_day": parseFloat(datas.days_to_add_into_adding_confirmation_day),
      "interest": parseFloat(datas.interest),
      "other_bank_cost": parseFloat(datas.other_bank_cost),
      "adding_confirmation_charges": parseFloat(datas.adding_confirmation_charges),
      "vat_on_bank_charges": parseFloat(datas.vat_on_bank_charges),
      "bill_handling_charges": parseFloat(datas.bill_handling_charges)
    };
    console.log(JSON.stringify(responseData))
    setIsSubmitted(true)
    try {
      const response = await axios.put(
        `${API_URL}update/preshipment/${id}`,
        responseData,
        {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );


  console.log(response)
      if (response.data.success) {
        setIsSubmitted(false);
        setIsSubmitted(true);
        setTimeout(() => {
          $('#post').DataTable();
          $('#historys').DataTable();
          document.getElementById('messageContainer').innerHTML = `
            <div class="success-message">
                ${response.data.msg}
            </div>
          `;
          window.scrollTo({
            top: 0,
            behavior: "smooth" // Optional: animated scrolling
          });
          fetchData();
          setData(response.data);
          setIsEditMode(false);
        }, 1000);
      } else {
        $('#post').DataTable();
        $('#historys').DataTable();
        document.getElementById('messageContainer').innerHTML = `
            <div class="error-message">
                ${response.data.msg}
            </div>
        `;
        window.scrollTo({
          top: 0,
          behavior: "smooth" // Optional: animated scrolling
        });
      }
    } catch (error) {
      console.error('Error updating pre-shipment:', error);
      // You can handle the error here, like displaying a message to the user
    }
  }



  return (
    <div id="view-page">
      <Header />

      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Preshipment Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Preshipment / Preshipment details
              </li>
            </ol>
          </div>
        </div>
        {lengths>0?(
        <>
        {isEditMode ? (

          <div className="tasks fulid-section order-section">

            <>
              <div id="messageContainer"></div>
              <p className='edit-btn'>
              <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
                <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


              </p>

              <Row className="mb-3">

                <div className='col-md-12'>
                  <h4 className='heading'>Information</h4>
                </div>
                <Form.Group as={Col} md="6">
                <label>Locked</label>
                <input
                  type='checkbox'
                  name='locked'
                  checked={lockeds} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive0} // Handle the change event
                  className='mx-3'
                />
              </Form.Group>
              </Row>
              <Row className="mb-3">

                <div className='col-md-12'>
                  <h4 className='heading'>Master LC</h4>
                </div>
                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Rwood Bank Master">
                    <Form.Select aria-label="Floating label select example"
                      name="rwood_bank"
                      placeholder="Rwood bank"
                      defaultValue={datas.rwood_bank || ""}
                      onChange={handleChangeInput}
                    >
                      <option value='' >Select</option>
                      {rwoodbank.length > 0 ? (
      <>
        {
          rwoodbank.map((x) => {
            return (
              <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>
            )
          })
        }
      </> ) : (

<option className='no-data'>No data  available</option>
)}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Adding Confirmation Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDate1}
                      onChange={handleAdding}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Currency">
                    <Form.Select aria-label="Floating label select example"
                      name="currency"
                      placeholder="Currency"
                      defaultValue={datas.currency || ""}
                      onChange={handleChangeInput}
                    >
                      <option value='' >Select</option>
                      <option value='USD' >USD</option>
                      <option value='IDR' >IDR</option>

                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Master LC Status">
                    <Form.Select aria-label="Floating label select example"
                      name="master_lc_status"
                      placeholder="Master LC Status"
                      defaultValue={datas.master_lc_status || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option><option value="Pending">Pending</option><option value="Draft">Draft</option><option value="Issued">Issued</option>

                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Exchange rate"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Exchange rate"
                      name="exchange_rate"
                      defaultValue={datas.exchange_rate || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Master LC Reviewed By">
                    <Form.Select aria-label="Floating label select example"
                      name="master_lc_reviewed_by"
                      placeholder="Master LC Reviewed By"
                      defaultValue={datas.master_lc_reviewed_by || ""}
                      onChange={handleChangeInput}     >
                      <option value='' >Select</option>
                      {user && user.length > 0 ? (
                        user.map((x) => {
                          return (
                            <option key={x.id} value={x.username}>
                              {x.username}
                            </option>
                          );
                        })
                      ) : (
                        <option value='' disabled>
                          Loading...
                        </option>
                      )}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Expiry Date 1"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp}
                      onChange={handleAddingExp}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Reviewed Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp1}
                      onChange={handleAddingExp1}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Place of Expiry"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Place of Expiry"
                      name="master_lc_place_of_expiry"
                      defaultValue={datas.master_lc_place_of_expiry || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Shipment date1"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp2}
                      onChange={handleAddingExp2}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Issuing Bank"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Issuing Bank"
                      name="master_lc_issuing_bank"
                      defaultValue={datas.master_lc_issuing_bank || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Commodity">
                    <Form.Select aria-label="Floating label select example"
                      name="commodity"
                      id='account_name'
                      placeholder="Commodity"
                      defaultValue={datas.commodity || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option><option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
                    </Form.Select>

                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Issuing Bank Swift Code"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Issuing Bank Swift Code"
                      name="issuing_bank_swift_code"
                      defaultValue={datas.issuing_bank_swift_code || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Unit Price"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Unit Price"
                      name="master_lc_unit_price"
                      defaultValue={datas.master_lc_unit_price || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Master LC Applicant">
                    <Form.Select aria-label="Floating label select example"
                      name="master_lc_applicant"
                      placeholder="Master LC Applicant"
                      defaultValue={datas.master_lc_applicant || ""}
                      onChange={handleChangeInput}
                    >
                      <option value='' >Select</option>
                      {
                        accounts.map((x) => {
                          return (
                            <option value={x.account_name}>{x.account_name}</option>
                          )
                        })
                      }
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Quantity"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Quantity"
                      name="master_lc_quantity"
                      defaultValue={datas.master_lc_quantity || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Amount"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Amount"
                      name="master_lc_amount"
                      defaultValue={datas.master_lc_amount || ""}
                      onChange={handleChangeInput} disabled
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Quantity Tolerance"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Quantity Tolerance"
                      name="master_lc_quantity_tolerance"
                      defaultValue={datas.master_lc_quantity_tolerance || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Tolerance"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Tolerance"
                      name="master_lc_tolerance"
                      defaultValue={datas.master_lc_tolerance || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Price Basis"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="price_basis"
                      placeholder="Price Basis"
                      defaultValue={datas.price_basis || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Consignee"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Consignee"
                      name="master_lc_consignee"
                      defaultValue={datas.master_lc_consignee || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Notify Party1"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Notify Party1"
                      name="notify_party_1"
                      defaultValue={datas.notify_party_1 || ""}
                      onChange={handleChangeInput}
                    />

                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Destination"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Destination"
                      name="master_lc_desination"
                      defaultValue={datas.master_lc_desination || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="notify party"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="notify party"
                      name="notify_party"
                      defaultValue={datas.notify_party || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="notify party"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="notify_party"
                    placeholder="notify party"
                    defaultValue={datas.notify_party || ""}
                      onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {accountName.length>0?(
        <>
       {accountName.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
           */}

                <Form.Group as={Col} md="6" id=''>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Port of Loading"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Port of Loading"
                      name="master_lc_port_of_loading"
                      defaultValue={datas.master_lc_port_of_loading || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="LC Type">
                    <Form.Select aria-label="Floating label select example"
                      name="lc_type"
                      placeholder="LC Type"
                      defaultValue={datas.lc_type || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option><option value="Transfer LC">Transfer LC</option><option value="B2B LC">B2B LC</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

              </Row>
              <Row className="mb-3">

                <div className='col-md-12'>
                  <h4 className='heading'>Master LC Issued</h4>
                </div>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Issue Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp3}
                      onChange={handleAddingExp3}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Presentation Days"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC Presentation Days"
                      name="master_lc_presentation_days"
                      defaultValue={datas.master_lc_presentation_days || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC No"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Master LC No"
                      name="master_lc_no"
                      defaultValue={datas.master_lc_no || ""}
                      onChange={handleChangeInput}
                      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Master LC Presentation Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp4}
                      onChange={handleAddingExp4}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>

              </Row>
              <Row className="mb-3">

                <div className='col-md-12'>
                  <h4 className='heading'>Child LC</h4>

                </div>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC No"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC No"
                      name="child_lc_no"
                      defaultValue={datas.child_lc_no || ""}
                      onChange={handleChangeInput}  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Child LC Status">
                    <Form.Select aria-label="Floating label select example"
                      name="child_lc_status"
                      placeholder="Child LC Status"
                      defaultValue={datas.child_lc_status || ""}
                      onChange={handleChangeInput}    >
                      <option value="">--None--</option><option value="Draft">Draft</option><option value="Issued">Issued</option><option value="Pending">Pending</option>
                    </Form.Select>

                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Issue Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp5}
                      onChange={handleAddingExp5}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Port of Loading"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Port of Loading"
                      name="child_lc_port_of_loading"
                      defaultValue={datas.child_lc_port_of_loading || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Expiry Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp6}
                      onChange={handleAddingExp6}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Shipment date	"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp7}
                      onChange={handleAddingExp7}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Place of Expiry"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Place of Expiry"
                      name="child_lc_place_of_expiry"
                      defaultValue={datas.child_lc_place_of_expiry || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Quantity"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Quantity"
                      name="child_lc_quantity"
                      defaultValue={datas.child_lc_quantity || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

<FloatingLabel controlId="floatingSelect"
  className='dropDown' label="Child LC Applicant">
  <Form.Select aria-label="Floating label select example"
    name="child_lc_applicant"
    placeholder="Child LC Applicant"
    defaultValue={datas.child_lc_applicant || ""}
                      onChange={handleChangeInput}    >
    <option value='' >Select</option>

    {accounts.length > 0 ? (
      <>
        {
          accounts.map((x) => {
            return (
              <option value={x.account_name}>{x.account_name}</option>
            )
          })
        }
      </>
    ) : (

      <option className='no-data'>No data  available</option>
    )}
  </Form.Select>
</FloatingLabel>
</Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Unit Price"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Unit Price"
                      name="child_lc_unit_price"
                      defaultValue={datas.child_lc_unit_price || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Amount"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Amount"
                      name="child_lc_amount"
                      defaultValue={datas.child_lc_amount || ""}
                      onChange={handleChangeInput} disabled
                    />
                  </FloatingLabel>
                </Form.Group>
                {/* <Form.Group as={Col} md="6">

<FloatingLabel controlId="floatingSelect"
  className='dropDown' label="child LC prepared by">
  <Form.Select aria-label="Floating label select example"
    name="child_lc_prepared_by"
    placeholder="child LC prepared by"
    defaultValue={datas.child_lc_prepared_by || ""}
                      onChange={handleChangeInput} >
    <option value='' >Select</option>

    {user && user.length > 0 ? (
                        user.map((x) => {
                          return (
                            <option key={x.id} value={x.username}>
                              {x.username}
                            </option>
                          );
                        })
                      ) : (
                        <option value='' >
                          Loading...
                        </option>
                      )}  </Form.Select>
</FloatingLabel>
</Form.Group> */}


<Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="child LC prepared by"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="child LC prepared by"
                      name="child_lc_prepared_by"
                      defaultValue={datas.child_lc_prepared_by || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Notify Party"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Notify Party"
                      name="child_lc_notify_party"
                      defaultValue={datas.child_lc_notify_party || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Prepared Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp8}
                      onChange={handleAddingExp8}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Consignee"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Consignee"
                      name="child_lc_consignee"
                      defaultValue={datas.child_lc_consignee || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Rwood Bank"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Rwood Bank"
                      name="child_lc_rwood_bank"
                      defaultValue={datas.child_lc_rwood_bank || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Destination"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Destination"
                      name="child_lc_destination"
                      defaultValue={datas.child_lc_destination || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Shipper Bank"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Child LC Shipper Bank"
                      name="child_lc_shipper_bank"
                      defaultValue={datas.child_lc_shipper_bank || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC tolerance"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="Child LC tolerance"
                name="child_lc_tolerance"
                defaultValue={datas.child_lc_tolerance || ""}
                onChange={handleChangeInput}
              />
            </FloatingLabel>
          </Form.Group>
                <Form.Group as={Col} md="6">

                  <FloatingLabel controlId="floatingSelect"
                    className='dropDown' label="Child  LC Reviewed By">
                    <Form.Select aria-label="Floating label select example"
                      name="child_lc_reviewed_by"
                      placeholder="Child LC Reviewed By"
                      defaultValue={datas.child_lc_reviewed_by || ""}
                      onChange={handleChangeInput}      >
                      <option value='' >Select</option>
                      {user && user.length > 0 ? (
                        user.map((x) => {
                          return (
                            <option key={x.id} value={x.username}>
                              {x.username}
                            </option>
                          );
                        })
                      ) : (
                        <option value='' disabled>
                          Loading...
                        </option>
                      )}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Reviewed Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp9}
                      onChange={handleAddingExp9}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>


              </Row>

              <Row className="mb-3">

                <div className='col-md-12'>
                  <h4 className='heading'>LC Application</h4>

                </div>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Application Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp10}
                      onChange={handleAddingExp10}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="LC Discounting Application Date"
                    className="mb-3 date-picker"
                  >
                    <DatePicker
                      selected={startDateExp11}
                      onChange={handleAddingExp11}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      placeholderText="Select"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Child LC Presentation Days"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Child LC Presentation Days"
                      name="child_lc_presentation_days"
                      defaultValue={datas.child_lc_presentation_days || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-3">

                <div className='col-md-12'>
                  <h4 className='heading'>LC Costing</h4>
                </div>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Days to add into Child LC Period"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Days to add into Child LC Period"
                      name="days_to_add_into_child_lc_period"
                      defaultValue={datas.days_to_add_into_child_lc_period || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Libor Rate n Bank Cost Rate"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Libor Rate n Bank Cost Rate"
                      name="libor_rate_n_bank_cost_rate"
                      defaultValue={datas.libor_rate_n_bank_cost_rate || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Days to add into Adding Confirmation day"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Days to add into Adding Confirmation day"
                      name="days_to_add_into_adding_confirmation_day"
                      defaultValue={datas.days_to_add_into_adding_confirmation_day || ""}
                      onChange={handleChangeInput}
                    />

                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Interest"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Interest"
                      name="interest"
                      defaultValue={datas.interest || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="LC Transfer Cost"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="LC Transfer Cost"
                      name="lc_transfer_cost"
                      defaultValue={datas.lc_transfer_cost || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Other Bank Cost"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Other Bank Cost"
                      name="other_bank_cost"
                      defaultValue={datas.other_bank_cost || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Adding Confirmation charges"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Adding Confirmation charges"
                      name="adding_confirmation_charges"
                      defaultValue={datas.adding_confirmation_charges || ""}
                      onChange={handleChangeInput}
                    />

                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="VAT on Bank Charges"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="VAT on Bank Charges"
                      name="vat_on_bank_charges"
                      defaultValue={datas.vat_on_bank_charges || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Bill Handling Charges"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Bill Handling Charges"
                      name="bill_handling_charges"
                      defaultValue={datas.bill_handling_charges || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <div className="successDiv">
                  <p></p><p></p>
                </div>
              </Row>

              <p className='edit-btn'>
              <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
                <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


              </p> </>

          </div>
        ) : (
          <>
            <div className="tasks fulid-section order-section">

              <div className="edit-delete">
                <span id="edit" onClick={handleEditClick}>
                  Edit
                </span>
                <span>
                  <Link to={'/pre-shipment/create-view-list'}> Cancel</Link>
                </span>

              </div>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right"><span>Pre Shipment Document No	</span>
                    </td><td>
                      {datas.pre_code_no}
                    </td>
                    <td id="td-right"><span>Locked</span>
                    </td><td>
                    <input
                  type='checkbox'
                  name='locked'
                  checked={lockeds} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive0} // Handle the change event
                  className='mx-3'
                  disabled={isButtonEnabled}
                />
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span></span>
                    </td><td>

                    </td>
                    <td id="td-right"><span>Order</span>
                    </td><td>
                      <Link to={`/order/view-order-details/${datas.order_id}`}>{datas.order}</Link>
                    </td>

                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Master LC</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right"><span>Rwood Bank Master</span>
                    </td><td>
                      <Link to={"/bank/bank-details/"+datas.rwood_bank_id}>{datas.rwood_bank}</Link>
                    </td>
                    <td id="td-right"><span>Adding Confirmation Date</span>
                    </td><td>
                      {datas.adding_confirmation_date}
                    </td>
                  </tr>
                  <tr>

                    <td id="td-right"><span>Currency</span>
                    </td><td>
                      {datas.currency}
                    </td>
                    <td id="td-right"><span>Master LC Status</span>
                    </td><td>
                      {datas.master_lc_status}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>Exchange Rate</span>
                    </td><td>
                      {datas.exchange_rate}
                    </td>
                    <td id="td-right"><span>Master LC Reviewed By</span>
                    </td><td>
                      {datas.master_lc_reviewed_by}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span>Bank Address</span>
                    </td><td>
                      {datas.bank_address}
                    </td>
                    <td id="td-right"><span>Master LC Reviewed Date</span>
                    </td><td>
                      {datas.master_lc_reviewed_date}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span>Master LC Expiry Date 1</span>
                    </td><td>
                      {datas.master_lc_expiry_date_1}
                    </td>
                    <td id="td-right"><span>Master LC shipment Date 1</span>
                    </td><td>
                      {datas.master_lc_shipment_date_1}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span>Master LC Place of Expiry</span>
                    </td><td>
                      {datas.master_lc_place_of_expiry}
                    </td>
                    <td id="td-right"><span>Commodity</span>
                    </td><td>
                      {datas.commodity}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span>Master LC Issuing Bank</span>
                    </td><td>
                      {datas.master_lc_issuing_bank}
                    </td>
                    <td id="td-right"><span>Master LC Unit Price</span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_unit_price)}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span>Issuing Bank Swift Code</span>
                    </td><td>
                      {datas.issuing_bank_swift_code}
                    </td>

                    <td id="td-right"><span>Master LC Quantity</span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_quantity)}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span>Master LC Applicant</span>
                    </td><td>
                      {datas.master_lc_applicant}
                    </td>
                    <td id="td-right"><span>Master LC Quantity Tolerance</span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_quantity_tolerance)}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span>applicant full name</span>
                    </td><td>
                      {datas.applicant_full_name}
                    </td>
                    <td id="td-right"><span>Master LC Quantity with Tolerance</span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_value_with_tolerance)}
                    </td>
                  </tr>
                  <tr>
                  <td id="td-right"><span>applicant address</span>
                    </td><td>
                      {datas.applicant_address}
                    </td>
                    <td id="td-right"><span>Price Basis</span>
                    </td><td>
                      {datas.price_basis}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span>Master LC Amount</span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_amount)}
                    </td>
                    <td id="td-right"><span>Notify Party 1</span>
                    </td><td>
                      {datas.notify_party_1}
                    </td>


                  </tr>

                  <tr>
                    <td id="td-right"><span>Master LC Tolerance</span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_tolerance)}
                    </td>
                    <td id="td-right"><span>Notify Party</span>
                    </td><td>
                      {datas.notify_party}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>Master LC Value With Tollerence	</span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_value_with_tolerance)}
                    </td>
                    <td id="td-right"><span>LC Type</span>
                    </td><td>
                      {datas.lc_type}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>Master LC Consignee</span>
                    </td><td>
                      {datas.master_lc_consignee}
                    </td>
                    <td id="td-right"><span></span>
                    </td><td>
                    </td>

                  </tr>

                  <tr>

                    <td id="td-right"><span>Master LC Destination</span>
                    </td><td>
                      {datas.master_lc_desination}
                    </td>

                    <td id="td-right"><span></span>
                    </td><td>
                    </td>

                  </tr>


                  <tr>
                    <td id="td-right"><span>Master LC Port of Loading</span>
                    </td><td>
                      {datas.master_lc_port_of_loading}
                    </td>
                    <td id="td-right"><span></span>
                    </td><td>
                    </td>

                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Master LC Issued</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right"><span>Master LC Issue Date</span>
                    </td><td>
                      {datas.master_lc_issued_date}
                    </td>
                    <td id="td-right"><span>Master LC Presentation Days </span>
                    </td><td>
                      {renderShipmentQty(datas.master_lc_presentation_days)}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>Master LC No</span>
                    </td><td>
                      {datas.master_lc_no}
                    </td>
                    <td id="td-right"><span>Master LC Presentation Date </span>
                    </td><td>
                      {datas.master_lc_presentation_date}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Child LC</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right"><span>Child LC No</span>
                    </td><td>

                      {datas.child_lc_no}
                    </td>
                    <td id="td-right"><span>Child LC Status </span>
                    </td><td>
                      {datas.child_lc_status}
                    </td>
                  </tr>



                  <tr>
                    <td id="td-right"><span>Child LC Issue Date</span>
                    </td><td>
                      {datas.child_lc_issued_date}
                    </td>
                    <td id="td-right"><span>Child LC Port of Loading </span>
                    </td><td>
                      {datas.child_lc_port_of_loading}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>Child LC Expiry Date</span>
                    </td><td>
                      {datas.child_lc_expiry_date}
                    </td>
                    <td id="td-right"><span>Child LC Shipment Date </span>
                    </td><td>
                      {datas.child_lc_shipment_date}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>Child LC Place of Expiry </span>
                    </td><td>
                      {datas.child_lc_place_of_expiry}
                    </td>
                    <td id="td-right"><span>Child LC Quantity</span>
                    </td><td>
                      {renderShipmentQty(datas.child_lc_quantity)}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right"><span>Child LC Applicant </span>
                    </td><td>
                      {datas.child_lc_applicant}
                    </td>
                    <td id="td-right"><span>Child LC Unit Price</span>
                    </td><td>
                      {renderShipmentQty(datas.child_lc_unit_price)}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span>Child LC Amount</span>
                    </td><td>
                      {renderShipmentQty(datas.child_lc_amount)}
                    </td>
                    <td id="td-right"><span>Child LC Prepared By </span>
                    </td><td>
                      {datas.child_lc_prepared_by}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span>Child LC with Tolerance	</span>
                    </td><td>
                      {renderShipmentQty(datas.child_lc_tolerance)}
                    </td>
                    <td id="td-right"><span>Child LC Prepared Date </span>
                    </td><td>
                      {datas.child_lc_prepared_date}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span>Child LC Notify Party</span>
                    </td><td>
                      {datas.child_lc_notify_party}
                    </td>
                    <td id="td-right"><span>Child LC Rwood Bank </span>
                    </td><td>
                      {datas.child_lc_rwood_bank}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span>Child LC Consignee</span>
                    </td><td>
                      {datas.child_lc_consignee}
                    </td>
                    <td id="td-right"><span>Child LC Shipper Bank </span>
                    </td><td>
                      {datas.child_lc_shipper_bank}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span>Child LC Destination</span>
                    </td><td>
                      {datas.child_lc_destination}
                    </td>
                    <td id="td-right"><span>Child LC Reviewed By</span>
                    </td><td>
                      {datas.child_lc_reviewed_by}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right"><span></span>
                    </td><td>

                    </td>
                    <td id="td-right"><span>Child LC Reviewed Date </span>
                    </td><td>
                      {datas.child_lc_reviewed_date}
                    </td>

                  </tr>

                </tbody>
              </table>


              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">LC Application</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right"><span>Child LC Application Date</span>
                    </td><td>
                      {datas.child_lc_application_date}
                    </td>
                    <td id="td-right"><span>LC Discounting Application Date </span>
                    </td><td>
                      {datas.lc_discounting_application_date}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right"><span>Child LC Presentation Days</span>
                    </td><td>
                      {renderShipmentQty(datas.child_lc_presentation_days)}
                    </td>
                    <td></td>
                  </tr>

                </tbody>
              </table>


              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">LC Costing</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right"><span>Days to add into Child LC Period</span>
                    </td><td>
                      {renderShipmentQty(datas.days_to_add_into_child_lc_period)}
                    </td>
                    <td id="td-right"><span>Libor Rate n Bank Cost Rate </span>
                    </td><td>
                      {renderShipmentQty(datas.libor_rate_n_bank_cost_rate)}
                    </td>
                  </tr>

                  <tr>

                    <td id="td-right"><span>Days to add into Adding Confirmation day </span>
                    </td><td>
                      {renderShipmentQty(datas.days_to_add_into_adding_confirmation_day)}
                    </td>
                    <td id="td-right"><span>Adding Confirmation days </span>
                    </td><td>
                      {datas.adding_confrimation_days}
                    </td>
                  </tr>

                  <tr>

                    <td id="td-right"><span>Child LC Period
                    </span>
                    </td><td>
                      {datas.child_lc_period}
                    </td>
                    <td id="td-right"><span>Interest</span>
                    </td><td>
                      {renderShipmentQty(datas.interest)}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>LC Transfer Cost</span>
                    </td><td>
                      {renderShipmentQty(datas.lc_transfer_cost)}
                    </td>
                    <td id="td-right"><span>Other Bank Cost</span>
                    </td><td>
                      {renderShipmentQty(datas.other_bank_cost)}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right"><span>Adding Confirmation Charges </span>
                    </td><td>
                      {renderShipmentQty(datas.adding_confirmation_charges)}
                    </td>
                    <td id="td-right">
                    </td><td>
                    </td></tr>
                  <tr>
                    <td id="td-right"><span>VAT on Bank Charges</span>
                    </td><td>
                      {renderShipmentQty(datas.vat_on_bank_charges)}
                    </td>
                    <td id="td-right">
                    </td><td>
                    </td></tr>
                  <tr>
                    <td id="td-right"><span>Bill Handling Charges </span>
                    </td><td>
                      {renderShipmentQty(datas.bill_handling_charges)}
                    </td>
                    <td id="td-right">
                    </td><td>
                    </td></tr>
                    <tr>
                    <td id="td-right"><span>Created By
                    </span>
                    </td><td>
                      {datas.created_by
                      }
                    </td><td id="td-right"><span>Last Modified By

                    </span>
                    </td><td>
                      {datas.last_modified_by
                      }
                    </td></tr>
                </tbody>
              </table>


              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Open Activities</span>
                    <span>
                      <i className=""></i>
                      New Task
                    </span>
                    <span>
                      <i className=""></i>
                      New Event
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">
                        No records to display
                      </p>
                    </td>
                  </tr>
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Activity History</span>
                    <span>
                      <i className=""></i>
                      Log a Call
                    </span>
                    <span>
                      <i className=""></i>
                      Mail merge
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Notes & Attachments</span>
                    <span>
                      <i className=""></i>
                      New Note
                    </span>
                    <span>
                      <i className=""></i>
                      Attach File
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">
                        No records to display
                      </p>
                    </td>
                  </tr>
                </table>
              </Row>



              <Row id="table-styles">
                <div className="col-md-12" id='ax.account_details'>
                  <h4 className="heading"><span>Postshipments Information</span>

                  </h4>
                  <hr></hr>
                </div>
                <table id='post' className="">
                  <thead>
                    <tr>
                      <th>RWood Post Shipment Doc No	</th>
                      <th>BL Date	</th>
                      <th>BL No</th>
                      <th>BL Quantity	</th>
                      <th>BL Status	</th>
                      <th>COA Status	</th>
                      <th>Document Collection Date	</th>
                      <th>Document Discount Date	</th>
                      <th>Document Submission Date
                      </th>

                    </tr>
                  </thead>

                  {post.length > 0 ?

                    <tbody>
                      {
                        post.map((y, i) => (
                          <tr>
                            <td><span id='codes'><Link to={"/postshipment/postshipment-details/" + y.id}>{y.post_code_no}</Link></span></td>
                            <td>{y.bl_date}</td>
                            <td>{y.bl_no}</td>
                            <td>{y.bl_quantity}</td>
                            <td>{y.bl_status}</td>
                            <td>{y.coa_status}</td>
                            <td>{y.document_collection_date}</td>
                            <td>{y.document_discount_date}</td>
                            <td>{y.document_submission_date}</td>
                          </tr>
                        ))}
                    </tbody>
                    : <tbody>
                      <tr>
                      <td></td>
                        <td></td>
                        <td></td><td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td >
                        <td></td>
                        <td></td >
                      </tr>
                    </tbody>
                  }
                </table>

              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading"><span>History Information</span></h4>
                  <hr></hr>
                </div>
                <table id="historys" className="">
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>New Value</th>
                      <th>Old Value</th>
                      <th>Changed By</th>
                      <th>Change Time</th>
                    </tr>
                  </thead>

                  {history.length > 0 ? (
                    <tbody>
                      {history.map((y, i) => (
                        <tr>
                          <td>{y.field_name}</td>
                          <td>{y.new_value}</td>
                          <td>{y.old_value}</td>
                          <td>{y.changed_by}</td>
                          <td>{y.change_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
            </div>

          </>
        )}
        </>):(
          <div className="tasks fulid-section order-section">
          <h1 className="no-data-detail">No Data Available</h1></div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PreshipmentDetails;
