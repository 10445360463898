import React, { useEffect, useState } from "react";
import Header from '../Header'
import Footer from '../Footer'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { API_URL } from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AES, enc } from 'crypto-js';
import { useNavigate } from "react-router-dom";

const AddRecords = () => {

  const navigate = useNavigate();

  const [formData, setFromdata] = useState({

    type_of_new_record: "",

  });


  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = (event) => {

    event.preventDefault();


    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch(`${API_URL}add/new/record`, {
              method: "POST",
              body: JSON.stringify(formData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                
                  console.log(response) 
                if (response.message === "record addedd successfully") {
                                  const record_type_new = response.account_type;
                                  toast.success("Selected "+record_type_new,
                                   {
                                    position: "bottom-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                  navigate("/opportunity/add-opportunity", {
                        
                                    state: { record_type: record_type_new },
                        
                                  });
                                }
              }) 
            } 
             
    };


     
  return (
<>
    <Header/>
    <div className="contactAccount">
      <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Select Opportunity </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Opportunity/ Select Opportunity</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
      </div>
    </div> 
  </div> 


<form onSubmit={handleSubmit}>
      <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Account Record">
    <Form.Select aria-label="Floating label select example"
       name="type_of_new_record"

       value={formData.type_of_new_record}

       onChange={handleChange}

    defaultValue=""
    >
<option>----Select----</option>
<option value='Buyer' >Buyer</option>
      <option value='Buyer - Long Term' >Buyer - Long Term</option>
      <option value='Buyer - Spot' >Buyer - Spot</option>
      <option value='Supplier' >Supplier</option>
      <option value='Supplier - Long Term' >Supplier - Long Term</option>

        
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
       
       
     
              <input type="submit"  className="account-record" value='Save'/>
<p></p><p></p>
</form>
</div>
<Footer/>
</>

  );

};


export default AddRecords;

