import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ToastContainer, toast } from 'react-toastify';
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const OppourtunityList = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $(".contactAccount").css({"min-height":(window_height)+"px"})
  const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

  const [formData, setFromdata] = useState({

    type_of_new_record: "",

  });


  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = (event) => {

    event.preventDefault();


    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

    fetch(API_URL+'add/new/record', {
              method: "POST",
              body: JSON.stringify(formData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())

              .then((response) => {

                if (response.message === "record addedd successfully") {
                                  const record_type_new = response.account_type;
                                  toast.success("Selected "+record_type_new,
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                  navigate("/opportunity/add-opportunity", {

                                    state: { record_type: record_type_new },

                                  });
                                }
              })
            }

    };


  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(API_URL+'all/opportunity', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.opportunity;
      console.log(data)
      setDatas(data);
      setLoading(false);
      $(document).ready(function(){
          $('#tables').DataTable();
       })
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Opportunity List </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Opportunity List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      <Popup trigger={<button className="button add-accountrecord"> <FaPlus/> Add Opportunity </button>} modal>
          <form onSubmit={handleSubmit} className="add-accounts">
        <div className='form-group'>
        <label>Opportunity Record Type</label><br/>
        <select  className='form-control' name="type_of_new_record" onChange={handleChange} value={formData.account_record}>
          {/* <option>----Select----</option> */}
{/* <option value='Buyer' >Buyer</option> */}
      <option value='Buyer - Long Term' >Buyer - Long Term</option>
      <option value='Buyer - Spot' >Buyer - Spot</option>
      {/* <option value='Supplier' >Supplier</option> */}
      <option value='Supplier - Long Term' >Supplier - Long Term</option>
      <option value='Supplier - Spot'>Supplier - Spot</option>

          </select>
          </div>
          <input type='submit' className="btn btn-primary " value='Submit'/>

          </form>
      </Popup>
      </div>
    </div>
  </div>
  <div class='tasks'>
  {loading ? <p id='loading'>Loading Data..</p>
  :<table id='tables'>
    <thead>
    <tr>
      <th>Opportunity no</th>
      <th>Account Name</th>
      <th>Company</th>
      <th>Email</th>
      <th>Record Type</th>
      <th>Origin</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
    <td><p id='link'><span><Link to={"/opportunity/opportunity-detail"+"/"+x.id }>{x.opportunity_no}</Link></span></p></td>
      <td><Link to={"/opportunity/opportunity-detail"+"/"+x.id }>{x.account_name}</Link></td>
      <td>{x.company}</td>
      <td>{x.email}</td>
      <td><span className="account-record">{x.opportunity_record_type}</span></td>
      <td>{x.origin}</td>
      <td><FaPencilAlt/> <FaTrashAlt/><Link to={"/opportunity/opportunity-detail"+"/"+x.id }><AiFillDiff id='details'/></Link></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>
   } </div>

   </div>
    <Footer/>
    </div>
  );
};

export default OppourtunityList;