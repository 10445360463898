import React, { useState } from 'react';
import axios from 'axios';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from "react-bootstrap/Form";
import { API_URL } from './config';
function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${API_URL}reset_password`, { email });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response.data.message);
        }
    };

    return (
        <div className='login'>
      <img src='/images/logo.jpg' className="login-image"/>
      <Form onSubmit={handleSubmit}>
      <FloatingLabel
        controlId="floatingInput"
        label="Email Address"
        className="mb-3"

      >
        <Form.Control
         type="text"
          placeholder="name@example.com"
          name="email"
          value={email} onChange={(e) => setEmail(e.target.value)}
        />
      </FloatingLabel>
      <button type="submit" className='btn btn-primary'>Reset Password</button>
      </Form>
      {message && <p className='reset-link'>{message}</p>}
     </div>
    );
}

export default  ResetPassword