import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams  } from "react-router-dom";
const OpportunityDetails = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const params = useParams()
  const id=params.id
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);



  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL +'all/opportunnity/datas/byID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      console.log(data)
      setDatas(data);
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const editClick=()=>{
    navigate("/opportunity/add-shippment", {

      state: { record_type: datas.opportunity_record_type },

    });
  }
  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Opportunity Details</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Opportunity Details</li>
      </ol>
    </div   >
    <div className="d-flex opd">
      <div className="justify-content-center">
      <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to={`/opportunity/add-shipment/${id}`} onClick={editClick}> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Shipment</Link>
        </button>
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to={`/opportunity/add-quote/${id}`}> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Quotes</Link>
        </button>
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to={`/all-quotes`}> <i className="fe fe-download-cloud me-2"></i><FaPlus/> All Quotes</Link>
        </button>

      </div>
    </div>
  </div>
  <div className='tasks'>

      <p id='account-name'><span>Account Name</span><br/><span>{datas.account_name}</span></p>
     <div className="pull-left">
     <p id='account' ><span>Account Number</span><br/><span>{datas.account_number}</span></p>
     {/* <p id='account' className="pull-right"><span>Account Owner</span><br/><span>{datas.account_owner}</span></p> */}
     </div>
     <div className="pull-right text-right">
     {/* <p id='account' ><span>Account Number</span><br/><span>{datas.account_number}</span></p> */}
     <p id='account' ><span>Account Owner</span><br/><span>{datas.account_owner}</span></p>
     </div>
     <p className="clear"></p>
     <div className="pull-left">
     <p id='account'><span>Annual Revenue</span><br/><span>{datas.annual_revenue}</span></p>
     </div>
     <div className="pull-right text-right">
     <p id='account' className="pull-right"><span>Email</span><br/><span>{datas.email}</span></p>
     </div>
     <p className="clear"></p>
     <div className="pull-left">
     <p id='account'><span>Currency</span><br/><span>AED</span></p>
     </div>
     <div className="pull-right text-right">
     <p id='account' className="pull-right"><span>Website</span><br/><span>{datas.website}</span></p>
     </div>
     <p className="clear"></p>
     <table class="table table-bordered  opp-details">

    <tbody>
      <tr>
        <td><span>Account Name</span><br/>{datas.account_name}</td>
        <td><span>Account alias</span><br/>{datas.account_alias}</td>
        <td><span>Account record type</span><br/>{datas.account_record_type}</td>
        <td><span>No of employees</span><br/>{datas.no_of_employees}</td>
      </tr>
      <tr>
      <td><span>Opportunity name</span><br/>{datas.opportunity_name}</td>
      <td><span>Account alias</span><br/>{datas.account_alias}</td>
        <td><span>Account record type</span><br/>{datas.account_record_type}</td>
        <td><span>No of employees</span><br/>{datas.no_of_employees}</td>
      </tr>
      <tr>
      <td><span>Parent account</span><br/>{datas.parent_account}</td>
      <td><span>Quantity</span><br/>{datas.quantity}</td>
        <td><span>Supplier</span><br/>{datas.supplier}</td>
        <td><span>Updated at</span><br/>{datas.updated_at}</td>
      </tr>
      <tr>
      <td><span>Company</span><br/>{datas.company}</td>
      <td><span>Opportunity no</span><br/>{datas.opportunity_no}</td>
        <td><span>Origin</span><br/>Dubai</td>
        <td><span>Validity</span><br/>90 days</td>
      </tr>
    </tbody>
  </table>
    </div>
    </div>
    <Footer/>
    </div>
  );
};

export default OpportunityDetails;