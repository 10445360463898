const modifiedTableKeysData = (data) => {
    
    const transformed = [];
    const fieldNames = data.field_names;

    const createChildren = (fields) => {

        return fields.map(field => ({
            id: field,
            label: field.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())  
        }));

    };

    for (const [key, fields] of Object.entries(fieldNames)) {

        transformed.push({
            id: key,
            label: key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()),  
            children: createChildren(fields),
        });
    }


    return transformed;
};

export default modifiedTableKeysData;
