import React, { Component } from 'react'
import Accounts from './accounts'
import Footer from './Footer'
import Header from './Header' 
function Accountlist() {
   
  
    return (
      <div>
    <Header/>
        <Accounts/>
    <Footer/>
      </div>
    );
  
};

export default Accountlist;