import React, { useState, useMemo, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from './Header'
import Footer from './Footer'

import axios from "axios";
import { AES, enc } from "crypto-js";
import $ from 'jquery';
import { API_URL } from "./config";
// import { decryptedAccessToken, encryptedAccessToken } from "../Token";
function Price() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas,setDatas]=useState([]);
  const [getPrice, setgetPrice]=useState([]);

console.log(getPrice)
 const { register, handleSubmit, reset, formState: { errors } } = useForm();
 const onSubmit = data =>{
     const responseData={
      price_description: data.price_description
   };
   const encryptedAccessToken = localStorage.getItem('access_token');
     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

     fetch(`${API_URL}add/price_item`, {
               method: "POST",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())

               .then((response) => {
                console.log('Test'+JSON.stringify(response))
               $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
              //  $('.priceDiv').hide(2000)

               reset();

               })

             }

     };

  return (

    <div className="priceAccount">
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Price</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Price</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
      <Row className="mb-3">

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price Description"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Price Description"
              name="price_description"
              {...register("price_description", { required: true })}
            />
            {errors.price_description && <span>Required Price Description</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>
      <input type="submit" className='price-submit' />
      <div className='priceDiv'></div>
    </Form>
    </div>
    <Footer/>
    </div>
  );
}

export default Price;
