import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "./AuthProvider";
import $ from "jquery";
import axios from "axios";
import Footer from './Footer'
import Header from './Header'
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { API_URL } from "./config";
// import { decryptedAccessToken, encryptedAccessToken } from "../Token";
const AllTcf = () => {
  const userRole = localStorage.getItem("role");
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $(".contactAccount").css({"min-height":(window_height)+"px"})


  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/tcf`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        $('#tables').DataTable().destroy();
        setTimeout(()=>{
          $('#tables').DataTable();
        },500)
       })
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">TCF List </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / TCF List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='account/contacts/:id'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add New Contact</a>
        </button>
         */}
      </div>
    </div>
  </div>
  <div class='tasks'>
  <table id='tables'>
    <thead>
    <tr>
      <th>Id</th>
      <th>TCF No</th>
      <th>Currency</th>
      <th>Product master</th>
      <th>TCF date</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x,i) => (
    <tr key={i}>
      <td>{x.id}</td>
      <td>{x.tcf_no}</td>
      <td>{x.currency}</td>
      <td>{x.product_master}</td>
      <td>{x.tcf_date}</td>
      <td><FaPencilAlt/> <FaTrashAlt/></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>

    </tbody>
    </table>
    </div> </div>
    <Footer/>
    </div>
  );
};

export default AllTcf;