import React, { useState, useEffect } from "react";
import axios from "axios";
import { AES, enc } from "crypto-js";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { IoSearch } from "react-icons/io5";
import $ from "jquery";

import Popup from "reactjs-popup";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import Editor from 'react-simple-wysiwyg';
import { API_URL } from '../config';
import Footer from "../Footer";
import { useLocation, useParams, Link } from "react-router-dom";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function BuyerDebitNoteFields({ data, index, handleInputChange }) {
  return (
    <td>
      <select
        name="line_item_type"
        value={data.line_item_type}
        className="form-control line-item-type"
        onChange={(e) => handleInputChange(index, e)}
      >
        <option value="">---Select---</option>
        <option value="GCV Variation">GCV Variation</option>
        <option value="Price Difference">Price Difference</option>
        <option value="Dead Freight Receivable">Dead Freight Receivable</option>
        <option value="Vessel Detention">Vessel Detention</option>
        <option value="Despatch">Umpire Analysis - PENDING</option>
        <option value="Demurrage">Demurrage</option>
      </select>
    </td>
  );
}

function SupplierFields({ data, index, handleInputChange }) {
  return (
    <td>
      <select
        name="name"
        value={data.name}
        onChange={(e) => handleInputChange(index, e)}
      >
        <option value="Option3">Option 3</option>
        <option value="Option4">Option 4</option>
      </select>
    </td>
  );
}

function Rwpayreceipt() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const params = useParams();
  const urlID = params.id;
  const navigate = useNavigate();

  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const location = useLocation();
  const [updateData, setFormDataUpdate] = useState([]);
  const [lineitemText, setLineitemText] = useState("");
  const handleRowClick = (productCode, lineItem) => {
    setSelectedProductCode(productCode);
    setIsPopupOpen(false);
    setLineitemText(lineItem);
  };

  useEffect(() => {
    if (selectedProductCode) {
      console.log('Selected Product Code:', selectedProductCode);
    }
  }, [selectedProductCode]);

  const { ids,recordtype } = location.state;

  console.log(ids, "order_id")
  const [order_data, setorder_data] = useState([]);

  const [orderID, setorderID] = useState();

  let post_id = ids
  console.log(post_id,"post_id")

  // console.log(recordorder_idtype)

  useEffect(() => {
    fetchDataReceipt();
  }, [decryptedAccessToken]);

  const fetchDataReceipt = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/orderbyID/" + ids,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      console.log(response);
      const data = response.data;
      const order_details = response.data;
      const orderDetail = order_details.order_detail[0];
      setorder_data(orderDetail);

    } catch (error) {
      console.error(error);
    }
  };




  // date
  const [paymentReceipt, setPaymentReceipt] = useState(null);
  const paymentReceipts = paymentReceipt ? `${paymentReceipt.getDate().toString().padStart(2, '0')}/${(paymentReceipt.getMonth() + 1).toString().padStart(2, '0')}/${paymentReceipt.getFullYear()}` : null;
  const handlePaymentReceipt = (date) => {
    setPaymentReceipt(date);
  };
  const [documentDate, setDocumentDate] = useState(null);
  const documentDates = documentDate ? `${documentDate.getDate().toString().padStart(2, '0')}/${(documentDate.getMonth() + 1).toString().padStart(2, '0')}/${documentDate.getFullYear()}` : null;
  const handleDocumentDate = (date) => {
    setDocumentDate(date);
  };
  const [actualDate, setActualDate] = useState(null);
  const actualDates = actualDate ? `${actualDate.getDate().toString().padStart(2, '0')}/${(actualDate.getMonth() + 1).toString().padStart(2, '0')}/${actualDate.getFullYear()}` : null;
  const handleActualDate = (date) => {
    setActualDate(date);
  };


  console.log(paymentReceipts)
  console.log(documentDates)
  console.log(actualDates)



  const [lineitems, setLineitems]=useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/order_pay_recieve_lineitem/${ids}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log("*********************",response);
      const data = response.data.pay_lineitem_detail;

       setLineitems(data)

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);



  const [datas, setDatas] = useState([]);
  useEffect(() => {

    fetchDatas();
  }, [decryptedAccessToken]);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodbank', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      console.log(response)
      const data = response.data.rwood_bank;
      setDatas(data);
    } catch (error) {
      console.error(error);
    }
  };


  const [accountName, setAccountName] = useState([]);
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL+"all/accounts", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.accounts;
      setAccountName(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [editor1,setEditor1]=useState()
  function onChanges(e) {
    console.log('onChange event:', e);
    console.log('Current HTML:', e.target.value);
    setEditor1(e.target.value);
  }

  useEffect(() => {

  }, [editor1]);

  const [selectedOption, setSelectedOption] = useState('');

  const handleInputBilling = (e) => {
    const newValues = e.target.value;
    setSelectedOption(newValues);
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[0].rw_billing = newValues;
      return updatedFormData;
    });

  }



  useEffect(() => {
    if (selectedProductCode) {
      console.log('Selected Product Code:', selectedProductCode);
      setFormData((prevFormData) => [
        {
          ...prevFormData[0],
          line_item_type: selectedProductCode,
        }
      ]);
    }
  }, [selectedProductCode]);



  const [selectLineitem, setselectLineiten] = useState('');

  const handleInputLineitem = (e) => {
    const newValue = e.target.value;
    setselectLineiten(newValue);
    console.log(newValue);

    // Update formData with the new value
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[0].line_item_type = newValue;
      return updatedFormData;
    });
  }
  const [selectLineitems, setSelectLineitems] = useState('');

  const handleInputLineitems = (e) => {
    const newValue = e.target.value;
    setSelectLineitems(newValue);
    console.log(newValue);
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[0].rw_pay_receivable_lineitem = newValue;

      return updatedFormData;
    });
  };


  // useEffect(() => {
  //   if(selectedOption && selectLineitem && selectLineitems){
  //     setFormData([
  //       {
  //         currency: "",
  //         exchange_rate: "",
  //         rw_payment_receipt: "",
  //         payment_receipt_date: paymentReceipts?paymentReceipts:null,
  //         account: "",
  //         amount: "",
  //         description: "",
  //         locked: "",
  //         record_type: recordtype,
  //         company: '',
  //         line_item: selectLineitems,
  //         line_item_type: "",
  //         order: order_data.order_no,
  //         tcf_no: "",
  //         contract: "",
  //         post_shipment_document: "",
  //         buyer_product: "",
  //         document_reference_type: "",
  //         campaign: "",
  //         opportunity: "",
  //         rw_billing: selectedOption,
  //         rw_pay_receivable_lineitem: selectLineitem,
  //         vessel_name: order_data.vessel_barge_name,
  //         bl_date: "",

  //         buyer: "",
  //         supplier: "",
  //         destination: "",

  //         payment_dr_acc_no: "",
  //         payment_dr_acc: "",
  //         payment_dr_message: "",
  //         payment_cr_acc: "",
  //         payment_cr_acc_no: "",
  //         payment_cr_messaage: "",
  //       },
  //     ]);
  //   }

  // }, [selectedOption, selectLineitem, selectLineitems]);

  const [formData, setFormData] = useState([
    {
      currency: "",
      exchange_rate: "",
      rw_payment_receipt: "",
      payment_receipt_date: "",
      amount: "",
      order:"",
      record_type: recordtype,
      line_item_type: selectedProductCode,
      order: order_data.order_no,
      rw_billing: selectedOption,
      rw_pay_receivable_lineitem: selectLineitems,
      vessel_name: order_data.vessel_barge_name,

    }
  ]);

  // const [formData, setFormData] = useState(() =>
  //   // const savedFormData = localStorage.getItem("formData");
  //   // return savedFormData
  //   //   ? JSON.parse(savedFormData)
  //   //   : [
  //         {
  //           currency: "",
  //           exchange_rate: "",
  //           rw_payment_receipt: "",
  //           payment_receipt_date: "",
  //           account: "",
  //           amount: "",
  //           description: "",
  //           locked: "",
  //           record_type: recordtype,
  //           company: "",
  //           line_item: "",
  //           line_item_type: "",
  //           order: order_data.order_no,
  //           tcf_no: "",
  //           contract: "",
  //           post_shipment_document: "",
  //           buyer_product: "",
  //           document_reference_type: "",
  //           campaign: "",
  //           opportunity: "",
  //           rw_billing: selectedOption,
  //           rw_pay_receivable_lineitem: '',
  //           vessel_name: order_data.vessel_barge_name,
  //           bl_date: "",

  //           buyer: "",
  //           supplier: "",
  //           destination: "",

  //           payment_dr_acc_no: "",
  //           payment_dr_acc: "",
  //           payment_dr_message: "",
  //           payment_cr_acc: "",
  //           payment_cr_acc_no: "",
  //           payment_cr_messaage: "",
  //         },
  //       ];
  // });



  // useEffect(() => {
  //   localStorage.setItem("formData", JSON.stringify(formData));

  // }, [formData]);


  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    // const updatedFormData = [...formData];
    // updatedFormData[index][name] = value;
    // setFormData(updatedFormData);
    setFormData(prevFormDataList => {
      const newList = [...prevFormDataList];
      newList[index] = { ...newList[index], [name]: value };
      return newList;
    });
  };


  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   setFormDataList(prevFormDataList => {
  //     const newList = [...prevFormDataList];
  //     newList[index] = { ...newList[index], [name]: value };
  //     return newList;
  //   });
  // };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formData1, [name]: value });
  };

  const handleAddField = () => {
    setFormData([
      ...formData,
      {
        currency: "",
        exchange_rate: "",
        rw_payment_receipt: "",
        payment_receipt_date: "",
        account: "",
        amount: "",
        order:"",
        description: "",
        locked: "",
        record_type: recordtype,
        company: "",
        line_item: "",
        line_item_type: selectedProductCode,
        order: order_data.order_no,
        tcf_no: "",
        contract: "",
        post_shipment_document: "",
        buyer_product: "",
        document_reference_type: "",
        campaign: "",
        opportunity: "",
        rw_billing: selectedOption,
        rw_pay_receivable_lineitem: selectLineitems,
        vessel_name: order_data.vessel_barge_name,
        bl_date: "",

        buyer: "",
        supplier: "",
        destination: "",

        payment_dr_acc_no: "",
        payment_dr_acc: "",
        payment_dr_message: "",
        payment_cr_acc: "",
        payment_cr_acc_no: "",
        payment_cr_messaage: "",
      },
    ]);
  };

  const handleRemoveField = (index) => {
    if (index === 0) {
      return;
    }
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };
  const [responseId, setId] = useState({});



  const renderNameFields = (data, index) => {
    if (data.buyer === "Buyer - Debit Note") {
      return (
        <BuyerDebitNoteFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else if (data.buyer === "Supplier") {
      return (
        <SupplierFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else {
      return null;
    }
  };

  const [lockedCheck, setLockedCheck] = useState(false);
  useEffect(() => {
  setFormData1(prevState => ({
    ...prevState,
    payment_receipt_date: paymentReceipts ? paymentReceipts : null,
    document_discount_date: documentDates ? documentDates : null,
    actual_payment_recieved_date: actualDates ? actualDates : null,
  }));
}, [paymentReceipts, documentDates, actualDates]);
  const [formData1, setFormData1] = useState({
    record_type: recordtype,
    account: order_data.account_name,
    company: order_data.company,
    exchange_rate: parseFloat(order_data.exchange_rate),
    payment_receipt_date: paymentReceipts?paymentReceipts:null,
    rwood_bank_master: order_data.rwood_bank_master,
    mode_of_payment: order_data.mode_of_payment,
    payment_receipt_ref_no: order_data.payment_receipt_ref_no,
    description: editor1,
    currency:order_data.currency,
    remark: order_data.remark,
    document_discount_date: documentDates?documentDates:null,
    actual_payment_recieved_date: actualDates?actualDates:null,
    locked: false,
    total_amount:order_data.total_amount,
    receipt_status: order_data.receipt_status
  });
console.log(formData1)

  const handleSubmit = (e) => {
    const requestData = {
      rw_payreceipt_lineitem: formData,
    };
    sendDataToAPI2(requestData);

  console.log(JSON.stringify(requestData))
  };
  console.log(JSON.stringify(formData1,"formdata1"))

  const sendDataToAPI2 = async (requestData) => {
     console.log(JSON.stringify(requestData))
    const id = 1;
    const api1Url = `${API_URL}add/order/rw_pay_receipt_detail/${post_id}`;


    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response1 = await axios.post(api1Url, formData1, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${decryptedAccessToken}`,
          },
        });
        if (response1.status === 200) {
          // $(".clear").val("");
          console.log(response1);
          console.log("Data sent to API 1 successfully");
          const response1Id = response1.data.rw_receipt_id;

console.log(response1Id)
          const response2 = await axios.post(
            `${API_URL}add/order/pay_receipt_lineitem/${post_id}/${response1Id}`,

            requestData,

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${decryptedAccessToken}`,
              },
            }
          );

          if (response2.status === 200) {
            console.log(response2)
            navigate(`/order/view-order-details/${urlID}`);
            toast.success("Added Successfully", {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log("Data sent to  API 2 successfully");
          } else {
            console.error(
              `Failed to send data to API 2. Status code: ${response2.status}`
            );
          }
        } else {
          console.error(
            `Failed to send data to API 1. Status code: ${response1.status}`
          );
        }
      } else {
        console.error("No access token found.");
      }
    } catch (error) {
      console.error("Error sending data to API 1 or API 2:", error);
    }
  };

  const [company, setCompany] = useState([]);
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/rwoodcompany",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      setCompany(data);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormDataUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [lineItem, setlineItem] = useState([]);

  const recordTypes={"record_type":recordtype}
  console.log(recordTypes)
  const lineItemList = async () => {
    try {
      const response = await axios.post(API_URL+"get/all/financeconfig/orders",JSON.stringify(recordTypes), {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      const data = response.data.all_finance;
      setlineItem(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    lineItemList();
  }, [decryptedAccessToken]);


  const [bill, setBill] = useState([]);
  useEffect(() => {
    billing();
  }, [decryptedAccessToken]);

  const billing = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/order_pay_recieve/${ids}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response,"************");
      const data = response.data.pay_recieve_detail;

      setBill(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Header />
      <div className="page-header" id="">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Add Receipt</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard / Payable / Add Receipt
            </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
          </div>
        </div>
      </div>
      <div className="tasks">
        <Row className="mb-3">
          <h4 className="heading">RW Payment/Receipt Information</h4>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Record Type"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Record Type"
                name="record_type"
                defaultValue={recordtype}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6" id="lockeds">
            <input
              type="checkbox"
              name="locked"
              onChange={(e) => setLockedCheck(e.target.checked)}
            />
            <label>Locked</label>
          </Form.Group>
          {/* <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Account"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Account"
                name="account"
                className="clear"
                defaultValue={formData1.account_name || order_data.account_name}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group> */}
          <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Account"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="account"
                  placeholder="Account"
                  value={formData1.account || order_data.account_name}
                  onChange={handleChange1}
                >
                   <option value="">Select</option>
                    {accountName.length > 0 ? (
                      <>
                        {accountName.map((x,index) => {
                          return (
                            <option key={index} value={x.account_name}>
                              {x.account_name}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <option className="no-data">No data available</option>

                    )}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>

          <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Company"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="company"
                  placeholder="Company"
                  value={ formData1.company|| updateData.company||order_data.company}
                  onChange={handleChange1}
                >
                  <option value="">Select</option>
                  {company.map((x) => {
                    return (
                      <option value={x.company_name}>{x.company_name}</option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>



          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="payment receipt ref no"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="payment receipt ref no"
                name="payment_receipt_ref_no"
                className="clear"
                defaultValue={formData1.payment_receipt_ref_no || ""}
                onChange={handleChange1}
              />
            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">

             <FloatingLabel controlId="floatingSelect"
             className='dropDown' label="Rwood bank master" >
   <Form.Select aria-label="Floating label select example"
   name="rwood_bank_master"
   placeholder="bank master master"
   defaultValue={formData1.rwood_bank_master || ""}
                onChange={handleChange1}

   >
     <option value='' >Select</option>
      {
         datas.map((x)=>{
             return(
                 <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>

             )
         })
      }
   </Form.Select>

 </FloatingLabel>
             </Form.Group>

          <Form.Group as={Col} md="6" className="datepicker-receipt">
            <FloatingLabel
              controlId="floatingInput"
              label="payment receipt date"
              className="mb-3"
            >
               <DatePicker
              selected={paymentReceipt}
        onChange={handlePaymentReceipt}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="mode of payment"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="mode_of_payment"
                placeholder="mode of payment"
                defaultValue={formData1.mode_of_payment || ""}
                onChange={handleChange1}
              >
                <option value="">--None--</option>
                <option value="Letter Of Credit" selected="selected">
                  Letter Of Credit
                </option>
                <option value="Telegraphic Transfer">
                  Telegraphic Transfer
                </option>
                <option value="Cheque">Cheque</option>
                <option value="Cash">Cash</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"  className="datepicker-receipt">
            <FloatingLabel
              controlId="floatingInput"
              label="document discount date"
              className="mb-3"
            >
               <DatePicker
              selected={documentDate}
        onChange={handleDocumentDate}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"  className="datepicker-receipt">
            <FloatingLabel
              controlId="floatingInput"
              label="actual payment recieved date"
              className="mb-3"
            >
               <DatePicker
              selected={actualDate}
        onChange={handleActualDate}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="receipt_status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="receipt_status"
                placeholder="receipt status"
                defaultValue={formData1.receipt_status || ""}
                onChange={handleChange1}
                className="clear"
              >
                                  <option value="" selected="selected">
        --None--
      </option>

                <option value="Raised">
                  Raised
                </option>
                <option value="Paid">Paid</option>
                <option value="Partial Payment">Partial Payment</option>
                <option value="Adjusted">Adjusted</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Settled By Arutmin">Settled By Arutmin</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Total Amount"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  tabIndex={7}
                  placeholder="Total Amount"
                  name="total_amount"
                  className="clear"
                  defaultValue={formData1.total_amount || ""}
                  onChange={handleChange1} disabled

                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
            <label>Description</label>
              <Editor value={editor1} name='description' onChange={onChanges} />

          </Form.Group>
          <p className='edit-cancel'> <Link to={`/order/view-order-details/${ids}`}><input type="submit" className="account-save cancel-bottomm" value="Cancel" /></Link></p>
        </Row>
        <h4 className="heading">RW Payment Receipt Line Items</h4>
        <table id="rw-table">
          <thead>
            <tr>
              <th>Order</th>
              <th>Rw pay receivable lineitem</th>

              <th>RW Billing</th>
              <th>Line Item Type</th>
              <th>Line Item </th>
              <th>Amount</th>
              <th>Description</th>
              <th>Vessel name</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name="order"
                    placeholder="Order"
                    className="form-control"
                    defaultValue={data.order || order_data.order_no}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>
                <td>
                  {/* <input
                    type="text"
                    name="rw_pay_receivable_lineitem"
                    className="form-control clear"
                    placeholder="Rw pay receivable lineitem"
                    defaultValue={data.rw_pay_receivable_lineitem || ""}
                    onChange={(e) => handleInputChange(index, e)}
                  /> */}
                   <select type="text"
                    name="rw_pay_receivable_lineitem"
                    defaultValue={selectLineitems ||''}
                    className="form-control clear"
                    onChange={handleInputLineitems}>

                      <option>---None--</option>
                  {lineitems.map((x, index) => (

                    <option value={x.rw_pay_receivable_lineitem}>{x.rw_pay_receivable_lineitem}</option>

))}
                  </select>
                </td>

                <td>
                  {/* <input
                    type="text"
                    name="rw_billing"
                    placeholder="RW Billing"
                    className="form-control clear"
                    defaultValue={data.rw_billing || ""}
                    onChange={(e) => handleInputChange(index, lineItem  e)}
                  /> */}
                  <select type="text"
                    name="rw_billing"
                    className="form-control clear"
                    defaultValue={selectedOption||  ''}
                    onChange={handleInputBilling}>
                       <option>---None--</option>
                  {bill?.map((x) => (
                    <option value={x.rw_code_no}>{x.rw_code_no}</option>

))}
                  </select>
                </td>

                {/* <td>
                  <input
                    type="text"
                    name="line_item_type"
                    placeholder="Line Item Type"
                    defaultValue={data.line_item_type || ""}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td> */}

                <td>
                <div className="lineGroup">      <input
        type="text"
        placeholder="Line Item Type"
        name="line_item_type"
        value={selectedProductCode}
        readOnly
      />
      <button
        className="button select-search"
        onClick={() => setIsPopupOpen(true)}
      >
        <IoSearch/>

      </button>
      </div>
                  </td>
                  <td>
          <input
            type="text"
            name="line_item"
            placeholder="Line item"
            id="line_item_clear"
            value={lineitemText || data.line_item}
            className="form-control clear"
            onChange={(e) => handleInputChange(index, e)}
          />
        </td>
                <td>
                  <input
                    type="text"
                    name="amount"
                    placeholder="Amount"
                    defaultValue={data.amount || ""}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="description"
                    placeholder="Description"
                    defaultValue={data.description || ""}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="vessel_name"
                    placeholder="vessel name"
                    defaultValue={data.vessel_name || order_data.vessel_barge_name}
                    className="form-control clear"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </td>

                <td>{renderNameFields(data, index)}</td>
                <td>
                  <FaTrashAlt
                    className="cursor"
                    onClick={() => handleRemoveField(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
      >
        <table className="table table-hovered">
          <thead>
            <tr>
              <th>Billing Config Name</th>
              <th>Line Item</th>
              <th>Lineitem Type</th>
            </tr>
          </thead>
          <tbody>
            {lineItem.map((y, i) => (
              <tr
                key={i}
                onClick={() => handleRowClick(y.billing_config_name, y.line_item)}
              >
                <td>{y.billing_config_name}</td>
                <td>{y.line_item}</td>
                <td>{y.lineitem_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Popup>
        <p className='edit-cancel'><input type="submit" className="account-save" value="Add Line Item" onClick={handleAddField}/><input type="submit" className="account-save" value="Save" onClick={handleSubmit} /><Link to={`/order/view-order-details/${ids}`}><input type="submit" className="account-save cancel-bottomm" value="Cancel" /></Link></p>
      </div>
      <p></p><p></p>
      <Footer />
    </div>
  );
}

export default Rwpayreceipt;