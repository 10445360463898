import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from '../config';
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const OppShipmentLists = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);



  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/shipments', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.opportunity_shipment;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Opportunity Shipment List </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Opportunity / Opportunity Shipment List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">


      </div>
    </div>
  </div>
  <div class='tasks'>
  <table id='tables' className="">
    <thead>
    <tr>
      <th>Opportunity shipment</th>
      <th>Record type</th>
      {/* <th>Quotes Name</th> */}
      <th>Price basis</th>
      <th>Price type</th>
      <th>Quantity</th>
      <th>Vessel type</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
       <td><Link to={"/opportunity/oppshipment-detail/"+x.id }>{x.opportunity_shipment}</Link></td>
      <td>{x.record_type}</td>
      {/* <td><Link to={"/opportunity/oppshipment-detail"+"/"+x.id }>{x.quotes_name}</Link></td> */}
      <td>{x.price_basis}</td>
      <td>{x.price_type}</td>
      <td>{x.quantity}</td>
      <td>{x.vessel_type}</td>
      <td>{x.Status}</td>
      <td><FaPencilAlt/> <FaTrashAlt/><Link to={"/opportunity/oppshipment-detail"+"/"+x.id }><AiFillDiff id='details'/></Link></td>
    </tr>
  ))
) : (
  <tr>
    <td></td>
    <td></td>
    <td></td>
    <td>No data available</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>
    </div> </div>
    <Footer/>
    </div>
  );
};

export default OppShipmentLists;