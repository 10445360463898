import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import $ from 'jquery'
import DatePicker from "react-datepicker";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, Link  } from "react-router-dom";
function  AddPostshipment(props) {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
const navigate=useNavigate();
    const [check,setCheck1]=useState(false)
    const [check2,setCheck2]=useState(false)
    const [check3,setCheck3]=useState(false)
    const [check4,setCheck4]=useState(false)
    const [check5,setCheck5]=useState(false)
    const [check6,setCheck6]=useState(false)
    const [check7,setCheck7]=useState(false)
    const [check8,setCheck8]=useState(false)
    const [check9,setCheck9]=useState(false)
    const [check10,setCheck10]=useState(false)
  const params = useParams()
  const [value, onChange] = useState(new Date());
  const [accountNames, setAccountName]=useState([]);
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(window_height)
  $(".contactAccount").css({"min-height":(window_height)+"px"})

  const [rwoodbank, setRwoodbank]=useState([]);
  const [date, setDate] = useState(null);


const handleDateChange = (time) => {
setDate(time);
};
const [date1, setDate1] = useState(null);


const handleDateChange1 = (time) => {
setDate1(time);
};
const [date2, setDate2] = useState(null);


const handleDateChange2 = (time) => {
setDate2(time);
};

const [date3, setDate3] = useState(null);


const handleDateChange3 = (time) => {
setDate3(time);
};

const [date4, setDate4] = useState(null);


const handleDateChange4 = (time) => {
setDate4(time);
};
const [date5, setDate5] = useState(null);


const handleDateChange5 = (time) => {
setDate5(time);
};const [date6, setDate6] = useState(null);


const handleDateChange6 = (time) => {
setDate6(time);
};const [date7, setDate7] = useState(null);


const handleDateChange7 = (time) => {
setDate7(time);
};const [date8, setDate8] = useState(null);


const handleDateChange8 = (time) => {
setDate8(time);
};const [date9, setDate9] = useState(null);


const handleDateChange9 = (time) => {
setDate9(time);
};const [date10, setDate10] = useState(null);


const handleDateChange10 = (time) => {
setDate10(time);
};const [date11, setDate11] =useState(null);


const handleDateChange11 = (time) => {
setDate11(time);
};
const [date12, setDate12] = useState(null);


const handleDateChange12 = (time) => {
setDate12(time);
};
const [date13, setDate13] = useState(null);


const handleDateChange13 = (time) => {
setDate13(time);
};
  useEffect(() => {

    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/preshipment', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.preshipments;
      console.log(data)
      setRwoodbank(data);

    } catch (error) {
      console.error(error);
    }
  };


  const [four, setFour] = useState(new Date());
  const fours =
  four.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
  .split('/')
  .reverse()
  .join('-');
    const Date4 = forwardRef(({ value, onClick, onChange }, ref) => (
     <input
       value={value}
       className="example-custom-input"
       onClick={onClick}
       onChange={onChange}
       name=""
       ref={ref}
     ></input>
   ));
   const [five, setFive] = useState(new Date());
      const fives =
      five.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
     .split('/')
     .reverse()
     .join('-');
    const Date5 = forwardRef(({ value, onClick, onChange }, ref) => (
      <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        name=""
        ref={ref}
      ></input>
    ));
    const [eight, setEight] = useState(new Date());
       const eights =
       eight.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
     const Date8 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));

     const [seven, setSeven] = useState(new Date());

        const sevens =
        seven.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
       .split('/')
       .reverse()
       .join('-');
      const Date7 = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
          value={value}
          className="example-custom-input"
          onClick={onClick}
          onChange={onChange}
          name=""
          ref={ref}
        ></input>
      ));

      const [six, setSix] = useState(new Date());
      const sixs =
      six.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
       .split('/')
       .reverse()
       .join('-');
       const Date6 = forwardRef(({ value, onClick, onChange }, ref) => (
         <input
           value={value}
           className="example-custom-input"
           onClick={onClick}
           onChange={onChange}
           name=""
           ref={ref}
         ></input>
       ));

       const [eleven, setEleven] = useState(new Date());

          const elevens =
          eleven.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
       .split('/')
       .reverse()
       .join('-');
        const Date11 = forwardRef(({ value, onClick, onChange }, ref) => (
          <input
            value={value}
            className="example-custom-input"
            onClick={onClick}
            onChange={onChange}
            name=""
            ref={ref}
          ></input>
        ));


        const [ten, setTen] = useState(new Date());
        const tens =
        ten.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
     .split('/')
     .reverse()
     .join('-');
         const Date10 = forwardRef(({ value, onClick, onChange }, ref) => (
           <input
             value={value}
             className="example-custom-input"
             onClick={onClick}
             onChange={onChange}
             name=""
             ref={ref}
           ></input>
         ));


         const [nine, setNine] = useState(new Date()); ;

            const nines =
            nine.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
         .split('/')
         .reverse()
         .join('-');
          const Date9 = forwardRef(({ value, onClick, onChange }, ref) => (
            <input
              value={value}
              className="example-custom-input"
              onClick={onClick}
              onChange={onChange}
              name=""
              ref={ref}
            ></input>
          ));

  const [startDate, setStartDate] = useState(null);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  const handlestartDateChange = (date) => {
    setStartDate(date);
  };

  const [startDate1, setstartDate1] = useState(null);
  const birthDate1 = startDate1 ? `${startDate1.getDate().toString().padStart(2, '0')}/${(startDate1.getMonth() + 1).toString().padStart(2, '0')}/${startDate1.getFullYear()}` : null;
  const handlestartDateChange1 = (date) => {
    setstartDate1(date);
  };
  const [startDate2, setstartDate2] = useState(null);
  const birthDate2 = startDate2 ? `${startDate2.getDate().toString().padStart(2, '0')}/${(startDate2.getMonth() + 1).toString().padStart(2, '0')}/${startDate2.getFullYear()}` : null;
  const handlestartDateChange2 = (date) => {
    setstartDate2(date);
  };
  const [startDate3, setstartDate3] = useState(null);
  const birthDate3 = startDate3 ? `${startDate3.getDate().toString().padStart(2, '0')}/${(startDate3.getMonth() + 1).toString().padStart(2, '0')}/${startDate3.getFullYear()}` : null;
  const handlestartDateChange3 = (date) => {
    setstartDate3(date);
  };

  const [startDate4, setstartDate4] = useState(null);
  const birthDate4 = startDate4 ? `${startDate4.getDate().toString().padStart(2, '0')}/${(startDate4.getMonth() + 1).toString().padStart(2, '0')}/${startDate4.getFullYear()}` : null;
  const handlestartDateChange4 = (date) => {
    setstartDate4(date);
  };
  const [startDate5, setstartDate5] = useState(null);
  const birthDate5 = startDate5 ? `${startDate5.getDate().toString().padStart(2, '0')}/${(startDate5.getMonth() + 1).toString().padStart(2, '0')}/${startDate5.getFullYear()}` : null;
  const handlestartDateChange5 = (date) => {
    setstartDate5(date);
  };

  const [startDate6, setstartDate6] = useState(null);
  const birthDate6 = startDate6 ? `${startDate6.getDate().toString().padStart(2, '0')}/${(startDate6.getMonth() + 1).toString().padStart(2, '0')}/${startDate6.getFullYear()}` : null;
  const handlestartDateChange6 = (date) => {
    setstartDate6(date);
  };
  const [startDate7, setstartDate7] = useState(null);
  const birthDate7 = startDate7 ? `${startDate7.getDate().toString().padStart(2, '0')}/${(startDate7.getMonth() + 1).toString().padStart(2, '0')}/${startDate7.getFullYear()}` : null;
  const handlestartDateChange7 = (date) => {
    setstartDate7(date);
  };
  const [startDate8, setstartDate8] = useState(null);
  const birthDate8 = startDate8 ? `${startDate8.getDate().toString().padStart(2, '0')}/${(startDate8.getMonth() + 1).toString().padStart(2, '0')}/${startDate8.getFullYear()}` : null;
  const handlestartDateChange8 = (date) => {
    setstartDate8(date);
  };
  const [startDate9, setstartDate9] = useState(null);
  const birthDate9 = startDate9 ? `${startDate9.getDate().toString().padStart(2, '0')}/${(startDate9.getMonth() + 1).toString().padStart(2, '0')}/${startDate9.getFullYear()}` : null;
  const handlestartDateChange9 = (date) => {
    setstartDate9(date);
  };
  const [startDate10, setstartDate10] = useState(null);
  const birthDate10 = startDate10 ? `${startDate10.getDate().toString().padStart(2, '0')}/${(startDate10.getMonth() + 1).toString().padStart(2, '0')}/${startDate10.getFullYear()}` : null;
  const handlestartDateChange10 = (date) => {
    setstartDate10(date);
  };


const id=params.id
     const {register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{
        const responseData={
          currency:data.currency,
          pre_ship_document:data.pre_ship_document,
          locked:data.locked,
          exchange_rate:data.exchange_rate,
          bl_issue_time_and_date:null,
          coo_destination:data.coo_destination,
          bl_confirm:null,
          bl_draft_or_splits_review_confirm_date:birthDate,
          bl_no:data.bl_no,
          bl_quantity:data.bl_quantity,
          bl_date:birthDate1,
          obl_email_recieved_date_and_time:null,
          bl_issue_time_and_date:null,
coo_form_govt_recieve_by_email:null,
bl_issue_place:data.bl_issue_place,
bl_status:data.bl_status,
shiper_surveyor_coa_date:null,
certificate_of_weight:null,
rwood_or_cust_surveyor_coa_date:null,
certificate_of_hold_cleanliness:check4,
net_weight_as_per_document:data.net_weight_as_per_document,
draft_survey_report:null,
addi_surveyor_certification_date:null,
certificate_of_registry:null,
coa_status:data.coa_status,
classification_certificate:null,
coo_status:data.coo_status,
safety_management_certificate:null,
cow_status:data.cow_status,
dsr_status:data.dsr_status,
govt_coo_status:data.govt_coo_status,
reason_for_delay:data.reason_for_delay,
coo_issuance_date:birthDate2,
coo_courier_date:birthDate3,
scan_bl_rcvd_date:birthDate4,
scan_iia_certs_rcvd_date:birthDate5,
scan_all_original_docs_rcvd_date:birthDate6,
org_document_to_buyer_80_percent:birthDate7,
arrived_at_discharge_port:birthDate8,
org_document_to_buyer_20_percent:birthDate9,
org_document_to_buyer_20_percent:birthDate9,
expected_value_date:birthDate10,
coo_courier_tracking_no:data.coo_courier_tracking_no,
coo_courier_company:data.coo_courier_company



          };
          console.log(responseData)
          console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+'add/postshipment/'+id, {
        method: "POST",
        body: JSON.stringify(responseData),
        headers: { Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response)
          if(response.success===true){
          toast.success('Post shipments added successfully', {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          setTimeout(() => {
            navigate(`/postshipment/postshipment-details/${response.postshipment_id}`)
            reset();
          }, 300);
        }
        else if(response.success===false){
          toast.success("Record Already Exists", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
        })

      }
};
const [one, setOne] = useState(new Date());
const ones =
   one.toLocaleDateString("en-US", { year: "numeric" }) +
   "-" +
   one.toLocaleDateString("en-US", { month: "2-digit" }) +
   "-" +
   one.toLocaleDateString("en-US", { day: "2-digit" });
 const Date1 = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     value={value}
     className="example-custom-input"
     onClick={onClick}
     onChange={onChange}
     name="renewal_date"
     ref={ref}
   ></input>
 ));



 const [three, setThree] = useState(new Date());
 const threes =
    three.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    three.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    three.toLocaleDateString("en-US", { day: "2-digit" });
  const Date3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [thirteen, setThirteen] = useState(new Date());
  const thirteens =
     thirteen.toLocaleDateString("en-US", { year: "numeric" }) +
     "-" +
     thirteen.toLocaleDateString("en-US", { month: "2-digit" }) +
     "-" +
     thirteen.toLocaleDateString("en-US", { day: "2-digit" });
   const Date13 = forwardRef(({ value, onClick, onChange }, ref) => (
     <input
       value={value}
       className="example-custom-input"
       onClick={onClick}
       onChange={onChange}
       name=""
       ref={ref}
     ></input>
   ));

   const [twele, setTwele] = useState(new Date());
   const tweles =
      twele.toLocaleDateString("en-US", { year: "numeric" }) +
      "-" +
      twele.toLocaleDateString("en-US", { month: "2-digit" }) +
      "-" +
      twele.toLocaleDateString("en-US", { day: "2-digit" });
    const Date12 = forwardRef(({ value, onClick, onChange }, ref) => (
      <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        name=""
        ref={ref}
      ></input>
    ));

  return (

    <div className="contactAccount post-shipment" id='contacts'>
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Postshipment </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Postshipment / Add Postshipment</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
   <Form onSubmit={handleSubmit(onSubmit)}>
   <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/post-shipment/create-view-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Information</h4>
        </div>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency" // value or onChnage ?
                    placeholder="Currency"
                    {...register("currency", { required:false })}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Exchange rate"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Exchange rate"
              name="exchange_rate"
              {...register("exchange_rate", {valueAsNumber:true, required:false })}
            />
            {errors.exchange_rate && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Pre Shipment Document">
    <Form.Select aria-label="Floating label select example"
    name="pre_ship_document"
    placeholder="Pre Shipment Document"
    {...register("pre_ship_document", { required:true })}
    >
      <option value='' >Select</option>


{rwoodbank.length>0?(
        <>
      {
          rwoodbank.map((x)=>{
              return(
                  <option value={x.pre_code_no}>{x.pre_code_no}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.pre_ship_document && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

      </Row>
      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>BL Draft/AI COO</h4>
        </div>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Draft Sent Date n Time"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date}
                    onChange={handleDateChange}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="COO Destination"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="COO Destination"
            name="coo_destination"
            {...register("coo_destination", { required: false })}
            />
             {errors.coo_destination && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='bl_confirm' onChange={(e) => setCheck2(e.target.checked) } /><label>BL Confirm</label>
          </Form.Group>
          <DatePicker
              selected={startDate}
        onChange={handlestartDateChange}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

              </Row>

      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>BL Issued</h4>
        </div>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="BL No">
    <Form.Select aria-label="Floating label select example"
     type="text"
    name="bl_no"
    placeholder="BL No"
    {...register("bl_no", { required:true })}
    >
      <option value='' >Select</option>
      {rwoodbank.length>0?(
        <>
      {
          rwoodbank.map((x)=>{
              return(
                  <option value={x.pre_code_no}>{x.pre_code_no}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}

    </Form.Select>
    {errors.bl_no && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="BL Quantity"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="BL Quantity"
            name="bl_quantity"
            {...register("bl_quantity", {valueAsNumber:true, required: true })}
            />
             {errors.bl_quantity && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate1}
        onChange={handlestartDateChange1}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="OBL Email Received Date n Time"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date1}
                    onChange={handleDateChange1}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Issue Date and Time"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date3}
                    onChange={handleDateChange3}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO form Govt receive by email"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date4}
                    onChange={handleDateChange4}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="BL Issue Place"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="BL Issue Place"
            name="bl_issue_place"
            {...register("bl_issue_place", { required: false })}
            />
             {errors.bl_issue_place && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="BL Status">
    <Form.Select aria-label="Floating label select example"
    name="bl_status"
    id='account_name'
    placeholder="BL Status"
    defaultValue=""
    {...register("bl_status", { required:true })}
    >
    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
    </Form.Select>
    {errors.bl_status && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>

              </Row>
              <Row>
              <div className='col-md-12'>
      <h4 className='heading'>Additional Surveyors Certificates</h4>
        </div>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shiper Surveyor COA Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date5}
                    onChange={handleDateChange5}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

                    dateFormat='dd/MM/yyyy hh:mm:aa'
                    timeIntervals={15}
                  timeCaption="Time"

                  showTimeSelect
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='certificate_of_weight' onChange={(e) => setCheck3(e.target.checked) } /><label>Certificate of Weight</label>
          </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood/Cust Surveyor COA Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date6}
                    onChange={handleDateChange6}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='certificate_of_hold_cleanliness' onChange={(e) => setCheck4(e.target.checked) } /><label>Certificate of hold cleanliness</label>
          </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Net Weight As per Document"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Net Weight As per Document"
            name="net_weight_as_per_document"
            {...register("net_weight_as_per_document", {valueAsNumber:true, required: false })}
            />
             {errors.net_weight_as_per_document && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck5(e.target.checked) } /><label>Draft Survey Report</label>

          </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Addi Surveyor Certificate Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date6}
                    onChange={handleDateChange6}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='certificate_of_registry' onChange={(e) => setCheck6(e.target.checked) } /><label>Certificate of Registry	</label>

          </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="COA Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="coa_status"
                    placeholder="COA Status"
                    {...register("coa_status", { required:false })}
                  >
                    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>
                  {errors.coa_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='classification_certificate' onChange={(e) => setCheck7(e.target.checked) } /><label>Classification Certificate</label>

          </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="COO Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="coo_status"
                    placeholder="COO Status"
                    {...register("coo_status", { required:false })}
                  >
                   <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>
                  {errors.coo_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='safety_management_certificate' onChange={(e) => setCheck8(e.target.checked) } /><label>Safety Management Certificate</label>

          </Form.Group>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="COW Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="cow_status"
                    placeholder="COW Status"
                    {...register("cow_status", { required:false })}
                  >
                   <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>
                  {errors.cow_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>



          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="DSR Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="dsr_status"
                    placeholder="DSR Status"
                    {...register("dsr_status", { required:false })}
                  >
                   <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>
                  {errors.dsr_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Govt COO Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="govt_coo_status"
                    placeholder="Govt COO Status"
                    {...register("govt_coo_status", { required:false })}
                  >
                   <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>
                  {errors.govt_coo_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Reason for Delay"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Reason for Delay"
            name="reason_for_delay"
            {...register("reason_for_delay", { required: false })}
            />
             {errors.reason_for_delay  && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>

      <div className='col-md-12'>
      <h4 className='heading'>COO Issued and Courier information</h4>
        </div>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO Issuance Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate2}
        onChange={handlestartDateChange2}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO Courier Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate3}
        onChange={handlestartDateChange3}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="COO Courier Tracking No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="COO Courier Tracking No"
            name="coo_courier_tracking_no"
            {...register("coo_courier_tracking_no", { required: false })}
            />
             {errors.coo_courier_tracking_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="COO Courier Company"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="COO Courier Company"
            name="coo_courier_company"
            {...register("coo_courier_company", { required: false })}
            />
             {errors.coo_courier_company && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        {/* pending */}
        <Row>

      <div className='col-md-12'>
      <h4 className='heading'>Import Invoice</h4>
        </div>
        <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck9(e.target.checked) } /><label>Checking of Import Invoice</label>
          </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Invoice Price"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier Invoice Price"
            name="supplier_invoice_price"
            {...register("supplier_invoice_price", {valueAsNumber:true, required: false })}
            />
             {errors.supplier_invoice_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Supplier Invoice Check Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date3}
                    onChange={handleDateChange3}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              </Row>
              <Row>
              <div className='col-md-12'>
      <h4 className='heading'>Document Submitted by Shipper/Supplier</h4>
        </div>


        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Submited by Shiper to Shiper Bank Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date4}
                    onChange={handleDateChange4}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Scan BL Rcvd Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate4}
        onChange={handlestartDateChange4}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Supplier Bank to RWood Bank Sent Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date5}
                    onChange={handleDateChange5}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Scan IIA Certs Rcvd Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate5}
        onChange={handlestartDateChange5}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Import Document RWood Bank recieved Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date6}
                    onChange={handleDateChange6}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Scan all Orginal Docs Rcvd Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate6}
        onChange={handlestartDateChange6}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Import Document Airway No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Import Document Airway No"
            name="import_document_airway_no"
            {...register("import_document_airway_no", { required: false })}
            />
             {errors.import_document_airway_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Import Document Airway Company"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Import Document Airway Company"
            name="import_document_airway_company"
            {...register("import_document_airway_company ", { required: false })}
            />
             {errors.import_document_airway_company  && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>

      <div className='col-md-12'>
      <h4 className='heading'>Documents Submitted to Buyer</h4>
        </div>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Org Document to Buyer 80%"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate7}
        onChange={handlestartDateChange7}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Arrived at Discharge Port	"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate8}
        onChange={handlestartDateChange8}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Org Document to Buyer 20%"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate9}
        onChange={handlestartDateChange9}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>
</Row>
<Row>
<div className='col-md-12'>
      <h4 className='heading'>Non Negotiable Document</h4>
        </div>


        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="NN Document sent Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date7}
                    onChange={handleDateChange7}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>
</Row>
<Row>

<div className='col-md-12'>
      <h4 className='heading'>Rwood Export Document Status
</h4>
        </div>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Submission Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date8}
                    onChange={handleDateChange8}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood Bank to Buyer Bank Airway No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Rwood Bank to Buyer Bank Airway No"
            name="rwood_bank_to_buyer_bank_airway_no"
            {...register("rwood_bank_to_buyer_bank_airway_no", { required: false })}
            />
             {errors.rwood_bank_to_buyer_bank_airway_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood Bank to Buyer Bank Send Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date9}
                    onChange={handleDateChange9}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood Bank to Buyer Bank Airway Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date10}
                    onChange={handleDateChange10}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Buyer Bank Document Receive Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date11}
                    onChange={handleDateChange11}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood Bank to Buyer Bank Airway Company"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Rwood Bank to Buyer Bank Airway Company"
            name="rwood_bank_to_buyer_bank_airway_company"
            {...register("rwood_bank_to_buyer_bank_airway_company", { required: false })}
            />
             {errors.rwood_bank_to_buyer_bank_airway_company  && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <div className="col-md-12">
          <h4 className="heading">Payment</h4></div>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Collection Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date12}
                    onChange={handleDateChange12}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>

                <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck10(e.target.checked) } /><label>Actual Payment Received	</label>
          </Form.Group>
              </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Expected Value Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate10}
        onChange={handlestartDateChange10}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Actual Payment Received Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
                    selected={thirteen}
                    onChange={(date) => setThirteen(date)}
                    customInput={<Date13 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Choose Date"
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel controlId="floatingTextarea2" label="Payment Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Payment Remark"
                    style={{ height: "100px!important" }}
                    name="payment_remark"
                    {...register("payment_remark", { required: false })}
                  />
                  {errors.payment_remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Discount Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={date13}
                    onChange={handleDateChange13}
                    showTimeSelect
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"

dateFormat="dd/MM/yyyy hh:mm aa"
timeFormat="hh:mm aa"
timeIntervals={15}
timeCaption="Time"
                  />
                </FloatingLabel>
            </Form.Group>
      </Row>
      <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/post-shipment/create-view-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>

      <div className='contact-success'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default AddPostshipment;
