import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import Button from "@mui/material/Button";

import { AES, enc } from "crypto-js";
import { API_URL } from "../config";
import "./company.css";

import { Link, useNavigate } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiFillDiff } from "react-icons/ai";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import RecentItems from "../recentItems/RecentItems";

const RwoodCompanyLists = () => {
  const [isLoading, setLoading] = useState(false);
  const [companyViews, setcompanyViews] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [selectedView, setSelectedView] = useState("all");
  const [selectedViewId, setSelectedViewId] = useState("");

  const [requestDatatype, setRequestDataType] = useState("recentmodifier");

  const [allTableData, setAllTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [columns, setColumns] = useState([]);

  const [modifiedEntriesLabel, setModifiedEntriesLabel] =
    useState("recently_created");

  const navigate = useNavigate();

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  // Function To Handle Recent Modifiers //

  const handleRandomDropdownChange = (e) => {
    const modifiedEntry = e.target.value;

    setModifiedEntriesLabel(modifiedEntry);

    setRequestDataType("recentmodifier");
  };

  // Function To Handle Page Change //

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Function To Handle Rows Per Change //

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Formatting Table Header Data //

  const formatHeaderName = (key) => {
    const replaced = key.replace(/_/g, " ").toLowerCase();
    return replaced.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const [excludedFields, setExcludedFields] = useState([
    "id",


  ]);
  const [linkFields, setLinkFields] = useState({
    rwood_company_code: { id: "id", link: "/company/company-details/" },


  });

  const renderTableRowsData = (data, type) => {
    switch (type) {
      case "headers":
        return data.map((col, index) => {
          if (!excludedFields.includes(col.field)) {
            return (
              <TableCell sx={{ width: 200 }} key={index}>
                {col.headerName}
              </TableCell>
            );
          }
          return null;
        });

      case "rows":
        return data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {columns.map((col, colIndex) => {
              if (!excludedFields.includes(col.field)) {
                return (
                  <TableCell sx={{ width: 200 }} key={colIndex}>
                    {linkFields.hasOwnProperty(col.field) ? (
                      <Link
                        to={`${linkFields[col.field].link}${
                          row[linkFields[col.field].id]
                        }`}
                      >
                        {row[col.field]}
                      </Link>
                    ) : (
                      row[col.field]
                    )}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        ));

      default:
        return null;
    }
  };

  const getColumnsData = (data) => {
    //desired order

    const desiredOrder = [
      "rwood_company_code",
      "company_name",

    ];

    // Get the keys from the data
    const dataKeys = Object.keys(data[0] || {});

    const columnsData = desiredOrder

      .filter((key) => dataKeys.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    const remainingColumns = dataKeys

      .filter((key) => !desiredOrder.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    return [...columnsData, ...remainingColumns];
  };

  // Function To Fetch Created Views //
  const fetchcompanyViews = async () => {
    try {
      setLoading(true);

      const createViewResponse = await axios.get(
        `${API_URL}get/rwoodcompany/create_view`
      );

      if (createViewResponse.status === 200) {
        const viewData = createViewResponse.data.rwood_company_view;

        console.log(viewData, "viewData");
        setcompanyViews(viewData);

        setLoading(false);

        setIsOptionsLoaded(true);
      }
    } catch (err) {
      console.log("An Error Occurred", err);
    }
  };

  // const handleViewFocus = () => {

  //   !isOptionsLoaded && fetchcompanyViews();

  // };

  const handleViewChange = (e) => {
    const { value, selectedIndex } = e.target;

    const selectedOptionId = e.target.options[selectedIndex].id;

    setSelectedViewId(selectedOptionId);

    setSelectedView(value);

    setRequestDataType("viewmodifier");

    sessionStorage.setItem("rwoodcompanyView", value);
    sessionStorage.setItem("rwoodcompanyViewId", selectedOptionId);
  };

  // On Component Load Set View To Previous //

  useEffect(() => {
    fetchcompanyViews();

    const rwoodcompanyViewName = sessionStorage.getItem("rwoodcompanyView");

    const rwoodcompanyViewNameId = sessionStorage.getItem("rwoodcompanyViewId");

    const rwoodcompanyViewEdit = sessionStorage.getItem("rwoodcompanyViewEdit");

    const isrwoodcompanyView = sessionStorage.getItem("isrwoodcompanyView");

    setSelectedView(rwoodcompanyViewName);

    setSelectedViewId(rwoodcompanyViewNameId);

    if (rwoodcompanyViewEdit && rwoodcompanyViewEdit === "true" && rwoodcompanyViewName && rwoodcompanyViewNameId) {
      setRequestDataType("viewmodifier");
    } else if (
      isrwoodcompanyView &&
      isrwoodcompanyView === "true" &&
      rwoodcompanyViewName &&
      rwoodcompanyViewNameId
    ) {
      setRequestDataType("viewmodifier");
    } else {
      setRequestDataType("recentmodifier");

      setModifiedEntriesLabel("recently_created");
    }

    sessionStorage.removeItem("rwoodcompanyViewEdit");
    sessionStorage.removeItem("isrwoodcompanyView");
  }, []);
  //   useEffect(() => {

  //     fetchcompanyViews()

  //     const rwoodcompanyViewName = sessionStorage.getItem("rwoodcompanyView")

  //     const rwoodcompanyViewNameId = sessionStorage.getItem("rwoodcompanyViewId")

  //     const rwoodcompanyViewEdit = sessionStorage.getItem("rwoodcompanyViewEdit")

  //     if (rwoodcompanyViewEdit && rwoodcompanyViewName && rwoodcompanyViewNameId)    {
  //       setSelectedView(rwoodcompanyViewName)

  //       setSelectedViewId(rwoodcompanyViewNameId)

  //       rwoodcompanyViewEdit === "true" && setRequestDataType("viewmodifier")
  //     } else {

  //       setRequestDataType("recentmodifier")
  //       setModifiedEntriesLabel("recently_created")

  //     }

  //     sessionStorage.removeItem("rwoodcompanyViewEdit");

  //   }, [])
  // Fetching View Based Data //

  const fetchViewData = async () => {
    try {
      setLoading(true);

      const URL = `${API_URL}get/rwood_company/view`;

      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          page: page + 1,
          page_size: rowsPerPage,
          view_name: selectedView,
        },
      });

      if (response.status === 200) {
        const data = response.data.rwoodcompany || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total || 0);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Retriving View Data", err);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getModifiedEntries = async () => {
    setLoading(true);

    const URL = `${API_URL}get/all/rwoodcompany/new`;

    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          random_dropdown_option: modifiedEntriesLabel,
          page: page + 1,
          page_size: rowsPerPage,
        },
      });

      if (response.status === 200) {
        const data = response.data.rwoodcompany || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Getting Modified Entries");
    } finally {
      setLoading(false);
    }
  };

  const navigaterwoodcompanyViewEdit = (e) => {
    e.preventDefault();

    sessionStorage.setItem("rwoodcompanyViewEdit", true);

    navigate(`/rwood-company/edit-view/${selectedViewId}`);
  };

  const navigateCreateView = (e) => {
    e.preventDefault();

    sessionStorage.setItem("rwoodcompanyViewEdit", true);

    navigate(`/rwood-company/create-view`);
  };

  const navigateViewDelete = () => {};

  //  set page to 0 whenever request data type changes //
  useEffect(() => {
    if (requestDatatype) {
      setPage(0);
    }
  }, [requestDatatype]);

  useEffect(() => {
    requestDatatype === "recentmodifier" && getModifiedEntries();
  }, [modifiedEntriesLabel, page, rowsPerPage]);

  // Fetch Selected View //

  useEffect(() => {
    requestDatatype === "viewmodifier" && fetchViewData();
  }, [selectedView, page, rowsPerPage]);

  return (
    <>
      <Header />

      <div className="dashboard-container">
        <div className="recent-items">
          <h2>Recent Items</h2>

          <RecentItems />
        </div>

        <div className="accounts">
          <div className="accounts-header">
            <h2>Company</h2>

            <div className="header-actions">
              <Button variant="outlined" color="error">
                <Link
                  to="/company/add-company"
                  style={{ textDecoration: "none", color: "red" }}
                >
                  <i className="fe fe-download-cloud me-2"></i>
                  <FaPlus /> Add Company
                </Link>
              </Button>
            </div>
          </div>

          <div className="accounts-content">
            <div className="view-controls">
              <div className="view_label">
                <a>View:</a>
              </div>

              <div className="select-container">
                <select value={selectedView} onChange={handleViewChange}>
                  <option>Select a view</option>

                  {companyViews.length > 0 ? (
                    companyViews.map((option) => (
                      <option
                        key={option.id}
                        id={option.id}
                        value={option.unique_name}
                      >
                        {option.view_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Data Available</option>
                  )}
                </select>
              </div>

              <div className="button-container">
                <button onClick={fetchViewData}>Go</button>
              </div>

              <div className="view_actions">
                <span className="rwood_view_edit" onClick={navigaterwoodcompanyViewEdit}>
                  Edit
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateViewDelete}>
                  Delete
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateCreateView}>
                  Create New View
                </span>
              </div>
            </div>

            <div className="recent-accounts">
              <div className="recemt_contract_content">
                <div className="account-search">
                  <p>Recent Companies</p>

                  <div className="select-modifier-container">
                    <select onChange={handleRandomDropdownChange}>
                      <option value="recently_created">Recently Created</option>
                      <option value="recently_modified">
                        Recently Modified
                      </option>
                      <option value="recently_viewed">Recently Viewed</option>
                    </select>
                  </div>
                </div>

                <div className="data_table">
                  {!isLoading ? (
                    allTableData.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ tableLayout: "fixed", width: "100%" }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead sx={{ background: "#f7f7f7" }}>
                            <TableRow>
                              {renderTableRowsData(columns, "headers")}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {renderTableRowsData(allTableData, "rows")}
                          </TableBody>
                        </Table>

                        <TablePagination
                          component="div"
                          count={totalRecords}
                          page={page}
                          onPageChange={handlePageChange}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleRowsPerPageChange}
                        />
                      </TableContainer>
                    ) : (
                      <div className="contract_data_status">
                        <h3>No Data Available</h3>
                      </div>
                    )
                  ) : (
                    <div className="rwood_spinner">
                      <img src="../../../images/loader.svg" alt="spinner" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RwoodCompanyLists;
