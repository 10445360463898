import React, { useState, useEffect } from "react";
import axios from "axios";
import { AES, enc } from 'crypto-js';
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Header";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import $ from 'jquery'
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import Editor from 'react-simple-wysiwyg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../Footer";
import { API_URL } from '../config';
import { IoSearch } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function BuyerDebitNoteFields({ data, index, handleInputChange }) {

  return (


    <td>
      <select
        name="line_item_type"
        value={data.line_item_type}
        className="form-control line-item-type"
        onChange={(e) => handleInputChange(index, e)}
      >
        <option value=''>---Select---</option>
        <option value="GCV Variation">GCV Variation</option>
        <option value="Price Difference">Price Difference</option>
        <option value="Dead Freight Receivable">Dead Freight Receivable</option>
        <option value="Vessel Detention">Vessel Detention</option>
        <option value="Despatch">Umpire Analysis - PENDING</option>
        <option value="Demurrage">Demurrage</option>
      </select>
    </td>
  );
}

function SupplierFields({ data, index, handleInputChange }) {
  return (
    <td>
      <select
        name="name"
        value={data.name}
        onChange={(e) => handleInputChange(index, e)}
      >
        {/* Add your dropdown options for "Supplier" here */}
        <option value="Option3">Option 3</option>
        <option value="Option4">Option 4</option>
      </select>
    </td>
  );
}

function RwpayDetails() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
    const location = useLocation();
    const [updateData,setFormDataUpdate]=useState([]);
    const navigate=useNavigate()
    const adminRole=localStorage.getItem('role')
  const { ids, recordtype } = location.state;
  console.log(ids, "order_ids")
  const [accountName, setAccountName] = useState([]);
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [quantitys,setQuantitys]= useState('');
  const [paymentReceipt, setPaymentReceipt] = useState(new Date());
  const [lineitemText, setLineitemText] = useState("");
  const paymentReceipts = paymentReceipt ? `${paymentReceipt.getDate().toString().padStart(2, '0')}/${(paymentReceipt.getMonth() + 1).toString().padStart(2, '0')}/${paymentReceipt.getFullYear()}` : null;
  const handlePaymentReceipt = (date) => {
    setPaymentReceipt(date);

  };
  const handleRowClick = (productCode, lineItem) => {

    setSelectedProductCode(productCode);
    // setIsPopupOpen(false);
    setLineitemText(lineItem);
  };

  // useEffect(() => {
  //   if (selectedProductCode) {
  //   }
  // }, [selectedProductCode]);

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL+"all/accounts", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.accounts;
      setAccountName(data);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };


  const [editor1,setEditor1]=useState()
  function onChanges(e) {
    console.log('onChange event:', e);
    console.log('Current HTML:', e.target.value);
    setEditor1(e.target.value);
  }

  useEffect(() => {

  }, [editor1]);


  const [selectLineitem, setselectLineiten] = useState('');

  const handleInputLineitem = (e) => {
    const newValue = e.target.value;
    setselectLineiten(newValue);
    console.log(newValue);

    // Update formData with the new value
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[0].line_item_type = newValue;
      return updatedFormData;
    });
  }

  useEffect(() => {
    setFormData1(prevState => ({
      ...prevState,
      document_date: paymentReceipts ? paymentReceipts : null,
      description:editor1,
    }));
  }, [paymentReceipts, editor1]);

  const [formData1, setFormData1] = useState({
    "currency":updateData.currency,
    "exchange_rate":updateData.exchange_rate,
    "company":updateData.company,
    "account":updateData.account,
    "document_date":paymentReceipts ? paymentReceipts : null,
    "document_reference_no":updateData.document_reference_no,
    "description":editor1,
    "status":updateData.status,
    "total_amount":updateData.total_amount,
    "total_paid_or_received_amount":updateData.total_paid_or_received_amount,
    "total_balance_due":updateData.total_balance_due,
    "created_by":updateData.created_by,
    "last_modified_by":updateData.last_modified_by,
    "locked": true,
    "rwood_bank_master":updateData.rwood_bank_master,
    "record_type":recordtype,
    "owner":updateData.owner,
    "debit_note_no":updateData.debit_note_no,
    "rwood_bank_master":updateData.rwood_bank_master,
    "amount_in_words":updateData.amount_in_words,
    "payment": true,
    "del_paid_or_received_date": "2023-02-22",
    "del_paid_or_received_amount":updateData.payment,
    "del_balance_due":updateData.del_balance_due,

  });

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/orderbyID/" + ids,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log("siva",response);
      const data = response.data;
      const order_details = response.data.order_detail[0];
      setFormDataUpdate(order_details);

    } catch (error) {
      console.error(error);
    }
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formData1, [name]: value });
  };


  const [formData, setFormData] = useState([
    {
      order: "",
      post_ship_document: "",
      line_item_type: selectedProductCode,
      line_item:"",
      unit_rate:"",
      exchange_rate:"",
      quantity:"",
      description:"",
      amount:"",
      record_type: recordtype
  }
  ]);


  useEffect(() => {
    if (selectedProductCode) {
      console.log('Selected Product Code:', selectedProductCode);
      setFormData((prevFormData) => [
        {
          ...prevFormData[0],
          line_item_type: selectedProductCode,
        }
      ]);
    }
  }, [selectedProductCode]);
  // useEffect(() => {
  //   // Save formData to localStorage whenever it changes
  //   localStorage.setItem("formData", JSON.stringify(formData));
  // }, [formData]);

  // const handleInputChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const updatedFormData = [...formData];
  //   updatedFormData[index][name] = value;
  //   setFormData(updatedFormData);
  // };



  const [lineitems, setLineitems]=useState([])
  useEffect(() => {
    fetchDatas();
  }, [decryptedAccessToken]);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(
        `${API_URL}order/post_shipemnt/${ids}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.order_postshipments;
      console.log(response);
       setLineitems(data)

    } catch (error) {
      console.error(error);
    }
  };




  const [datas, setDatas] = useState([]);
  useEffect(() => {

    fetchDatasBank();
  }, [decryptedAccessToken]);

  const fetchDatasBank = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodbank', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      console.log(response)
      const data = response.data.rwood_bank;
      setDatas(data);
    } catch (error) {
      console.error(error);
    }
  };




  const handleAddField = () => {
    setFormData([...formData, {
    order: "",
    post_ship_document: "",
    line_item_type: selectedProductCode,
    line_item:"",
    unit_rate:"",
    exchange_rate:"",
    quantity:"",
    description:"",
    amount:"",
    record_type: recordtype }]);
  };

  const handleRemoveField = (index) => {
    if (index === 0) {
      return;
    }
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };
  const [responseId,setId]=useState({})
  // console.log(responseId)
  const renderNameFields = (data, index) => {
    if (data.buyer === recordtype && data.buyer ==='Buyer - Debit Note') {
      return (
        <BuyerDebitNoteFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else if (data.buyer === "Supplier") {
      return (
        <SupplierFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else {
      return null;
    }
  };


  const [lineItem, setlineItem] = useState([]);


  const recordTypes={"record_type":recordtype}
  const lineItemList = async () => {
    try {
      const response = await axios.post(API_URL+"get/all/financeconfig/orders", recordTypes, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data.all_finance;
      setlineItem(data);
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    lineItemList();
  }, [decryptedAccessToken]);
  const [selectLineitems, setselectLineitens] = useState('');

  const handleInputLineitems = (e) => {
    setselectLineitens(e.target.value);

  }
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newFormData = [...formData]; // Copying the array to avoid mutating the state directly
    const currentItem = newFormData[index];

    // Update the current item with the new value
    newFormData[index] = {
      ...currentItem,
      [name]: value
    };

    // If the input is related to post_ship_document, set bl_quantity and update the quantity input box
    if (name === 'post_ship_document') {
      const selectedLineItem = lineitems.find(item => item.post_code_no === value);

      if (selectedLineItem) {
        const bl_quantity = selectedLineItem.bl_quantity || 0;
        newFormData[index].bl_quantity = bl_quantity; // Update bl_quantity
        newFormData[index].quantity = bl_quantity; // Set quantity to bl_quantity value

        // Automatically set quantity input box to bl_quantity using dynamic ID
        document.getElementById(`quantity-${index}`).value = bl_quantity;

      }
    }

    // Calculate the amount based on unit rate and quantity
    const unitRate = parseFloat(newFormData[index].unit_rate) || 0;
    const quantity = parseFloat(newFormData[index].quantity) || 0;
    const amount = unitRate * quantity;

    // Update the amount in the current item
    newFormData[index] = {
      ...newFormData[index],
      amount: amount.toFixed(2) // Rounding to 2 decimal places
    };

    // Update the state with the new form data
    setFormData(newFormData);
  };







  const handleSubmit = (e) => {

    // receive_update
    const requestData = {
      add_lineitem: formData,

    };

    console.log(JSON.stringify(requestData));
    sendDataToAPI2(requestData);
  };


  const sendDataToAPI2 = async (requestData) => {


      const api1Url = `${API_URL}add/rw_pay_recieve/${ids}`;

    try {
      const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        const response1 = await axios.post(api1Url, formData1, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${decryptedAccessToken}`,
          },
        });
        // setId(response1.data.rw_id)

        if (response1.status === 200) {
            // $('.clear').val('');
            // //swindow.location.reload();
          console.log(response1);
          const response1Id = response1.data.rw_id;
          console.log(ids,"*********",response1Id)
          // Now, you can use response1Id in the second API call
          const response2 = await axios.post(`${API_URL}add_line_item/${ids}/${response1Id}`, requestData, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${decryptedAccessToken}`
            },
          });
          console.log(response2)
          if (response2.status === 200) {
            console.log(response2)
            //swindow.location.reload()
            toast.success('Added Successfully', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            console.log("Data sent to  API 2 successfully");
            navigate(`/order/view-order-details/${ids}`)
          } else {
            console.error(`Failed to send data to API 2. Status code: ${response2.status}`);
          }
        } else {
          console.error(`Failed to send data to API 1. Status code: ${response1.status}`);
        }
      } else {
        console.error("No access token found.");
      }
    } catch (error) {
      console.error("Error sending data to API 1 or API 2:", error);
    }
  };






const [company, setCompany] = useState([]);
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/rwoodcompany",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      setCompany(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
        <Header/>
        <div className="page-header" >
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Add Payable</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Payable / Add Payable
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
              {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
            </div>
          </div>
        </div>
        <div className="tasks" id='opportunity-section'>


        <Row className="mb-3">
            <h4 className="heading">RW Payable Receivable Information</h4>
            {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="RW Payable/Receivable"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="RW Payable/Receivable"
                  name="order_no"

                  defaultValue={formData1.order_no ||updateData.business_confirm_to_supplier_no}
                  onChange={handleChange1}
                />
              </FloatingLabel>
            </Form.Group> */}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Company"
              >
                <Form.Select
                tabIndex={1}
                  aria-label="Floating label select example"
                  name="company"
                  placeholder="Company"
                  value={formData1.company || updateData.company}
                  onChange={handleChange1}
                >
                  <option value="">Select</option>
                  {company.map((x) => {
                    return (
                      <option value={x.company_name}>{x.company_name}</option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Record Type"
                className="mb-3"
              >
                <Form.Control
                tabIndex={9}
                  type="text"
                  placeholder="Record Type"
                  name="record_type"
                  defaultValue={recordtype}
                  onChange={handleChange1}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Currency"
              >
                <Form.Select
                tabIndex={2}
                  aria-label="Floating label select example"
                  name="currency"
                  placeholder="Currency"
                  value={formData1.currency || updateData.currency}
                  onChange={handleChange1}
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="IDR">IDR</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Account Name"
              >
                <Form.Select
                tabIndex={10}
                  aria-label="Floating label select example"
                  name="account_name"
                  placeholder="Account Name"
                  value={formData1.account_name || updateData.account_name}
                  onChange={handleChange1}
                >
                   <option value="">Select</option>
                    {accountName.length > 0 ? (
                      <>
                        {accountName.map((x) => {
                          return (
                            <option value={x.account_name}>
                              {x.account_name}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <option className="no-data">No data available</option>
                    )}

                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Exchange Rate"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  tabIndex={3}
                  placeholder="Exchange Rate"
                  name="exchange_rate"
                  className
                  ="clear"
                  defaultValue={formData1.exchange_rate || updateData.exchange_rate}
                  onChange={handleChange1}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Owner"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  tabIndex={11}
                  placeholder="Owner"
                  name="owner"
                  defaultValue={formData1.order_owner || adminRole}
                  onChange={handleChange1}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6" className="datepicker-receipt">
            <FloatingLabel
              controlId="floatingInput"
              label="Document Date"
              className="mb-3"
            >
               <DatePicker
               name='document_date'
              selected={paymentReceipt}
        onChange={handlePaymentReceipt}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
         tabIndex={5}
      />

            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6" className="datepicker-receipt"></Form.Group>
            <Form.Group as={Col} md="6" className='indexes-align'>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Document Reference No"
              >
                <Form.Control
                  as="textarea"
                  tabIndex={6}
                  placeholder="Document Reference No"
                  style={{ height: "100px" }}
                  className="clear"
                  name="document_reference_no"
                  defaultValue={formData1.document_reference_no || ""}
                  onChange={handleChange1}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6"  className='indexes-align'>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Debit Note No"
              >
                <Form.Control
                tabIndex={12}
                  as="textarea"
                  placeholder="Debit Note No"
                  style={{ height: "100px" }}
                  name="debit_note_no"
                  className="clear"
                  defaultValue={formData1.debit_note_no || ""}
                  onChange={handleChange1}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
            <label>Description</label>
              <Editor value={editor1} name='description' onChange={onChanges} />

          </Form.Group>

          <Form.Group as={Col} md="6"> </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Total Amount"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  tabIndex={7}
                  placeholder="Total Amount"
                  name="total_amount"
                  className="clear"
                  defaultValue={formData1.total_amount || ""}
                  onChange={handleChange1} disabled

                />
              </FloatingLabel>
            </Form.Group>


            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="status"
              >
                <Form.Select
                tabIndex={13}
                  aria-label="Floating label select example"
                  name="status"
                  placeholder="status"
                  defaultValue={formData1.status || ""}
                  onChange={handleChange1}
                  className="clear"
                >
                                    <option value="" selected="selected">
        --None--
      </option>

                  <option value="Raised">
                    Raised
                  </option>
                  <option value="Paid">Paid</option>
                  <option value="Partial Payment">Partial Payment</option>
                  <option value="Adjusted">Adjusted</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Settled By Arutmin">Settled By Arutmin</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">

             <FloatingLabel controlId="floatingSelect"
             className='dropDown' label="Rwood bank master" >
   <Form.Select aria-label="Floating label select example"
   name="rwood_bank_master"
   placeholder="bank master master"
   tabIndex={8}
   defaultValue={formData1.rwood_bank_master || ""}
                onChange={handleChange1}

   >
     <option value='' >Select</option>
      {
         datas.map((x)=>{
             return(
                 <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>

             )
         })
      }
   </Form.Select>

 </FloatingLabel>
             </Form.Group>

            <p className='edit-cancel'> <Link to={`/order/view-order-details/${ids}`}><input type="submit" className="account-save cancel-bottomm" value="Cancel" /></Link></p>
          </Row>
          <h4 className="heading">RW Payable Receivable Line Items</h4>
          <table id="rw-table">
  <thead>
    <tr>
      <th>Order</th>
      <th>Post Shipment Document</th>
      <th>Line Item Type</th>
      <th>Line Item</th>
      <th>Unit Rate</th>
      <th>Exchange Rate</th>
      <th>Quantity</th>
      <th>Description</th>
      <th>Amount</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {formData.map((data, index) => (
      <tr key={index}>
        <td>
          <input
            tabIndex={14}
            type="text"
            name="order"
            placeholder="Order"
            className="form-control"
            value={updateData.order_no || data.order}
            onChange={(e) => handleInputChange(index, e)}
          />
        </td>
        <td>
          <select
            tabIndex={15}
            name="post_ship_document"
            value={data.post_ship_document}
            className="form-control clear"
            onChange={(e) => handleInputChange(index, e)}
          >
            <option>---None---</option>
            {lineitems?.map((x, idx) => (
              <option key={idx} value={x.post_code_no}>
                {x.post_code_no}
              </option>
            ))}
          </select>
        </td>
        <td>
          <div className="lineGroup">
            <input
              type="text"
              placeholder="Line Item Type"
              name="line_item_type"
              value={selectedProductCode}
              readOnly
            />
            <button
              className="button select-search"
              onClick={() => setIsPopupOpen(true)}
              type="button"
            >
              <IoSearch />
            </button>
          </div>
        </td>
        <td>
          <input
            type="text"
            name="line_item"
            placeholder="Line item"
            id="line_item_clear"
            value={lineitemText || data.line_item}
            className="form-control clear"
            onChange={(e) => handleInputChange(index, e)}
          />
        </td>
        <td>
          <input
            tabIndex={17}
            type="number"
            name="unit_rate"
            placeholder="Unit Rate"
            onInput={(e) => (e.target.value = parseInt(e.target.value) || '')}
            className="form-control clear"
            value={data.unit_rate}
            onChange={(e) => handleInputChange(index, e)}
          />
        </td>
        <td>
          <input
            type="number"
            tabIndex={18}
            name="exchange_rate"
            placeholder="Exchange Rate"
            value={data.exchange_rate}
            className="form-control clear"
            onChange={(e) => handleInputChange(index, e)}
          />
        </td>
        <td>
        {/* <input
  type="number"
  tabIndex={19}
  id="quantity"
  placeholder="Quantity"
  onInput={(e) => (e.target.value = parseInt(e.target.value) || '')}
  className="form-control clear"
  id={`quantity-${index}`} // Dynamic ID for each quantity input
  name="quantity"
  value={formData[index].quantity}
  onChange={(e) => handleInputChange(index, e)}
/> */}
<input
className="form-control clear"
 tabIndex={19}
  id={`quantity-${index}`} // Dynamic ID for each quantity input
  name="quantity"
  value={formData[index].quantity}
  onChange={(e) => handleInputChange(index, e)}
/>

        </td>
        <td>
          <input
            type="text"
            name="description"
            tabIndex={20}
            placeholder="Description"
            className="form-control clear"
            value={data.description}
            onChange={(e) => handleInputChange(index, e)}
          />
        </td>
        <td>
          <input
            type="number"
            name="amount"
            tabIndex={21}
            placeholder="Amount"
            value={data.amount}
            className="form-control clear"
            readOnly
          />
        </td>
        <td>
          <FaTrashAlt
            className="cursor"
            onClick={() => handleRemoveField(index)}
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>

<p className="edit-cancel">
  <input
    type="submit"
    className="account-save"
    value="Add Line Item"
    onClick={handleAddField}
  />
  <input
    type="submit"
    className="account-save"
    value="Save"
    onClick={handleSubmit}
  />
  <Link to={`/order/view-order-details/${ids}`}>
    <input
      type="submit"
      className="account-save cancel-bottomm"
      value="Cancel"
    />
  </Link>
</p>

<Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
  <table className="table table-hovered">
    <thead>
      <tr>
        <th>Billing Config Name</th>
        <th>Line Item</th>
        <th>Lineitem Type</th>
      </tr>
    </thead>
    <tbody>
      {lineItem.map((y, i) => (
        <tr key={i} onClick={() => handleRowClick(y.billing_config_name, y.line_item)}>
          <td>{y.billing_config_name}</td>
          <td>{y.line_item}</td>
          <td>{y.lineitem_type}</td>
        </tr>
      ))}
    </tbody>
  </table>
</Popup>

     </div>
    <p></p><p></p>
    <Footer/>
    </div>
  );
}

export default RwpayDetails;
