import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { useParams, useLocation, useNavigate , Link} from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import { API_URL } from '../config';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AES, enc } from "crypto-js";
import $ from "jquery";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function AddoppShippmentInner() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const location = useLocation();
  const [isFormsubmit, setFormsubmited] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;


  const [opp, setOpp]=useState([])

    // const fetchData4 = async () => {
    //   try {
    //     const response = await axios.get(API_URL+'all/opportunity/datas/byID/'+id, {
    //       headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
    //     );
    //     const data = response.data[0].opportunity_details;
    //     console.log(data)
    //     setOpp(data);
    //     // setValue('oppord_name', data.account_name);

    //   } catch (error) {
    //     console.error(error);
    //   }
    // };
  const [startDate1, setStartDate1] = useState(new Date());
  const datepick1 =
    startDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate2, setStartDate2] = useState(new Date());
  const datepick2 =
    startDate2.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate3, setStartDate3] = useState(new Date());
  const datepick3 =
    startDate3.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate4, setStartDate4] = useState(new Date());
  const datepick4 =
    startDate4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));


  const [datas, setDatas] = useState([]);
  const [getPrice, setgetPrice] = useState([]);
  const [productValues, setProductValues] = useState([]);
  const [products, setproducts] = useState([]);
  const [record, setRecord] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [qty, setQty] = useState([]);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  console.log("startDate:", birthDate);

  const BankDate = endDate ? `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}` : null;
  console.log("startDate:", BankDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    console.log(date)
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };




  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        API_URL+"all/opportunity/datas/byID/new/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const oppdata = response.data.opportunity_details;
      setOpp(oppdata);
      const quantityFrom=response.data.opportunity_details.quantity_mt;
      setQty(quantityFrom)
      const data = response.data.opportunity_details.opportunity_record_type;
      console.log(response.data.opportunity_details)
      setRecord(data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecord((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  useEffect(() => {
    fetchData1();
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      console.log(data);
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const priceType = watch("price_type");
  if (record === "Buyer") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData={
        currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_year_from:'2023-03-22',
laycan_from_date:birthDate,
laycan_to_date:BankDate,
laycan_year_to:'2023-03-22',
laycan_month_from:'2023-03-22',
laycan_month_to:'2023-03-22',
quantity:22,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:'Good',
quantity_info:'test',
quantity_type:'test',
quantity_from:33,
quantity_to:33,
laycan:"test",
pricing:'test',
// laycan_month:data.laycan_month,
// laycan_year:data.laycan_year,
no_of_shipments:data.no_of_shipments
     };
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(API_URL+"add/shipment/" + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              navigate("/opportunity/oppshipment-detail/"+response.opp_ship_id)
          } else if (response.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }



            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opportunity Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>   <Form onSubmit={handleSubmit(onSubmit)} id="price">
          <div id="messageContainer"></div>
          {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    defaultValue={opp.currency || ""} onChange={handleChange}
                    placeholder="Currency"

                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    defaultValue={opp.exchange_rate || ""} onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"

                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity" className='link-text'
                    name="opportunity_shipment"
                    defaultValue={opp.opportunity_name || ""} onChange={handleChange}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"

                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    disabled={priceType !== "Fix Price"}
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"

                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      {...register("sales_price_margin", {
        valueAsNumber: true,
        required: false,
      })}
      disabled={priceType === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
        <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  {...register("price_type", {
    valueAsNumber: false,
    required: false,
  })}
  onChange={(e) => {
    setValue("price_type", e.target.value); // Set value for price_type
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>


                </FloatingLabel>
              </Form.Group>

            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan from date"
            className="mb-3 datepick"


          >
            <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan From Date"
          className="mb-3 datepick"


        >
            <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
          placeholderText="Select Date"
      />
        </FloatingLabel>
      </Form.Group>
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    className={`error-validation form-control ${errors.laycan_year ? 'is-invalid' : ''}`}
                    {...register("laycan_year", { required: 'Laycan Year is required' })}
                  >
                    <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                  {errors.laycan_year && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan month to date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate4}
      onChange={(date) => setStartDate4(date)}
      customInput={<CustomInput4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='dd/MM/yyyy'
      placeholderText='Laycan month to date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    className={`error-validation form-control ${errors.laycan_month ? 'is-invalid' : ''}`}
                    {...register("laycan_month", { required: 'Laycan Month is required' })}
                  >
                    <option value="">---None---</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>
              </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity Type"

                    {...register("quantity_type", {valueAsNumber:false, required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"

                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment quality tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment quality tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quality_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"
      // value={isFormsubmit ? "Saving" : "Save"}

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>

        </div>
        <Footer />
      </div>
    );
  }
  if (record === "Buyer - Long Term") {
    const onSubmit = (data) => {
      setFormsubmited(true)
          const responseData={
            exchange_rate:data.exchange_rate,
            status:data.status,
            quantity_from:data.quantity_from,
            quantity_to:data.quantity_to,
            opportunity:data.opportunity,
            opportunity_shipment:data.opportunity_shipment,
            record_type:data.record_type,
            price_type:data.price_type,
            price_basis:data.price_basis,
            price:data.price,
            sales_price_margin:data.sales_price_margin,
            shipping_term:data.shipping_term,
            laycan_year:data.laycan_year,
            laycan_year_to:data.laycan_year_to,
            laycan_month_from:data.laycan_month_from,
            laycan_month_to:data.laycan_month_to,
            laycan_year_from:data.laycan_year_from,
            // laycan_month_to_date:data.CustomInput4datepick4,
            quantity:data.quantity,
            shipment_quality_tolerance:data.shipment_quality_tolerance,
            vessel_type:data.vessel_type,
            remark:data.remark,
            currency:data.currency,
            // laycan_from_date:BankDate ||null,
            // laycan_to_date:birthDate,
            laycan_month:data.laycan_month,
            laycan_year:data.laycan_year,
            currency:data.currency,
            no_of_shipments:data.no_of_shipments,
            quantity_type:data.quantity_type,
            remark:data.remark

       };

      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(API_URL+"add/shipment/" + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            if(response.success===true){

            const messageContainer = document.getElementById('messageContainer');

          // Create the success message element
          const successMessage = document.createElement('div');
          successMessage.className = 'error-message';
          successMessage.innerText = response.msg;

          // Append the success message to the container
          messageContainer.appendChild(successMessage);

          // Scroll to the top
          window.scrollTo({
              top: 0,
              behavior: "smooth"
          });
          setTimeout(() => {
            successMessage.remove();
        }, 2000);
            setTimeout(() => {
              reset();
              navigate("/opportunity/oppshipment-detail/"+response.opp_ship_id)
            }, 2000);

          }
          else if(response.success === false) {
            const messageContainer = document.getElementById('messageContainer');

          // Create the success message element
          const successMessage = document.createElement('div');
          successMessage.className = 'error-message';
          successMessage.innerText = response.msg;

          // Append the success message to the container
          messageContainer.appendChild(successMessage);

          // Scroll to the top
          window.scrollTo({
              top: 0,
              behavior: "smooth"
          });
          setTimeout(() => {
            successMessage.remove();
        }, 2000);
          }
            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opportunity Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)} id="price">
          <div id="messageContainer"></div>
          {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

          <Row className="mb-3">
<h4 className="heading">Information</h4>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Currency"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="currency"
      defaultValue={opp.currency || ""} onChange={handleChange}
      placeholder="Currency"

      {...register("currency", { required: false })}
    >
      <option value="">Select</option>
      <option value="USD">USD</option>
      <option value="IDR">IDR</option>
    </Form.Select>
    {errors.currency && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Record type"
    className="mb-3"
  >
    <Form.Control
      type="text"
      placeholder="Record type"
      name="record_type"
      value={record}
      {...register("record_type", { required: false })}
    />
    {errors.record_type && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Exchange Rate"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Exchange Rate"
      name="exchange_rate"
      defaultValue={opp.exchange_rate || ""} onChange={handleChange}
      {...register("exchange_rate", {
        valueAsNumber: true,
        required: false,
      })}
    />
    {errors.exchange_rate && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Status"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="status"
      placeholder="Status"

      {...register("status", { required: false })}
    >
      <option value="">Select</option>
      <option value="Open">Open</option>
      <option value="Closed">Closed</option>
    </Form.Select>
    {errors.status && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Opportunity"
    className="mb-3"
  >
    <Form.Control
      type="text"
      placeholder="Opportunity" className='link-text'
      defaultValue={opp.opportunity_name || ""} onChange={handleChange}

      {...register("opportunity_shipment", { required: false })}
    />
    {errors.opportunity_shipment && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
</Row>
<Row className="mb-3">
<h4 className="heading">Price</h4>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Shipping term"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="shipping_term"
      placeholder="Shipping term"

      {...register("shipping_term", { required: false })}
    >
      <option value="">Select</option>
      <option value="FOB-B">FOB-B</option>
      <option value="FOB-MV">FOB-MV</option>
      <option value="CFR-MV">CFR-MV</option>
      <option value="CIF-MV">CIF-MV</option>
      <option value="FAS">FAS</option>
    </Form.Select>
    {errors.shipping_term && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Price"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Price"
      name="price"
      {...register("price", {
        valueAsNumber: true,
        required: false,
      })}
      disabled={priceType !== "Fix Price"}
    />
    {errors.price && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Price basis"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="price_basis"
      placeholder="Price basis"

      {...register("price_basis", { required: false })}
    >
      <option value="">Select</option>
      <option value="GCV(ARB)">GCV(ARB)</option>
      <option value="GCV(ADB)">GCV(ADB)</option>
      <option value="NCV(ARB)">NCV(ARB)</option>
    </Form.Select>
    {errors.price_basis && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
<FloatingLabel
controlId="floatingInput"
label="Sales price margin"
className="mb-3"
>
<Form.Control
type="number"
placeholder="Sales price margin"
name="sales_price_margin"
{...register("sales_price_margin", {
valueAsNumber: true,
required: false,
})}
disabled={priceType === "Fix Price"} // Disable if price_type is "Fix Price"
/>
</FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Price type"
  >
<Form.Select
aria-label="Floating label select example"
name="price_type"
placeholder="Price type"
{...register("price_type", {
valueAsNumber: false,
required: false,
})}
onChange={(e) => {
setValue("price_type", e.target.value); // Set value for price_type
}}
>
<option value="">Select</option>
{getPrice.map((x) => (
<option value={x.price_type_name}>{x.price_type_name}</option>
))}
</Form.Select>


  </FloatingLabel>
</Form.Group>

</Row>
<Row className="mb-3">
<h4 className="heading">Delivery</h4>

<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="laycan Year From"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="laycan_year_from"
      placeholder="Laycan Year From"
      {...register("laycan_year_from", { required: false })}
    >
      <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
    </Form.Select>

  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6"></Form.Group>

 <Form.Group as={Col} md="6">
   <FloatingLabel
     controlId="floatingSelect"
     className="dropDown"
     label="laycan Year To"
   >
     <Form.Select
       aria-label="Floating label select example"
       name="laycan_year_to"
       placeholder="Laycan Year To"
        {...register("laycan_year_to", { required: false })}
     >
       <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
     </Form.Select>

   </FloatingLabel>
 </Form.Group>
 <Form.Group as={Col} md="6"></Form.Group>


<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Laycan Month From"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="laycan_month_from"
      placeholder="Laycan Month From"
      {...register("laycan_month_from", { required: false })}
    >
     <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
    </Form.Select>

  </FloatingLabel>
</Form.Group>

<Form.Group as={Col} md="6"></Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Laycan Month To"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="laycan_month_to"
      placeholder="Laycan Month To"
      {...register("laycan_month_to", { required: false })}
    >
     <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
    </Form.Select>

  </FloatingLabel>
</Form.Group>
       </Row>

<Row className="mb-3">
<h4 className="heading">Quantity</h4>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingSelect"
    className="dropDown"
    label="Quantity"
  >
    <Form.Select
      aria-label="Floating label select example"
      name="quantity_type"

      placeholder="Quantity"

      {...register("quantity_type", {valueAsNumber:false, required: false })}
    >
      <option value="">Select</option>
      <option value="Buyers Option">Base Quantity</option>
      <option value="Buyers Option">Buyers Option</option>
    </Form.Select>
    {errors.quantity_type && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="No of shipments"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="No of shipments"
      name="no_of_shipments"
      defaultValue={opp.no_of_shipments || ""} onChange={handleChange}
      {...register("no_of_shipments", {
        valueAsNumber: true,
        required: false,
      })}
    />
    {errors.no_of_shipments && <span>Required</span>}
  </FloatingLabel>
</Form.Group>

<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="quantity from"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="quantity from"
      name="quantity_from"
      defaultValue={qty}
      {...register("quantity_from", {valueAsNumber:true, required: false })} />
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="quantity"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="quantity"
      name="quantity"
      defaultValue={opp.quantity || ""} onChange={handleChange}
      // className={`error-validation form-control ${errors.quantity ? 'is-invalid' : ''}`}
      {...register("quantity", {
        valueAsNumber: true,
        required: false,
      })}
      />
  </FloatingLabel>
  {errors.quantity && <div className="invalid-feedback"></div>}

</Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="quantity to"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="quantity to"
      name="quantity_to"
      {...register("quantity_to", {valueAsNumber:true, required: false })}/>
  </FloatingLabel>
</Form.Group>

<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Shipment Quantity Tolerance"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Shipment Quantity Tolerance"
      name="shipment_quality_tolerance"
      {...register("shipment_quality_tolerance", {valueAsNumber:true, required: false })}/>
  </FloatingLabel>
</Form.Group>

</Row>
            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
  if (record === "Buyer - Spot") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData={
        exchange_rate:data.exchange_rate,
        status:data.status,
        opportunity:data.opportunity,
        record_type:data.record_type,
        price_type:data.price_type,
        price_basis:data.price_basis,
        price:data.price,
        sales_price_margin:data.sales_price_margin,
        shipping_term:data.shipping_term,
        laycan_year:data.laycan_year,
        quantity:data.quantity,
        shipment_quality_tolerance:data.shipment_quality_tolerance,
        vessel_type:data.vessel_type,
        remark:data.remark,
        currency:data.currency,
        laycan_from_date:birthDate,
        laycan_to_date:BankDate,
        laycan_month:data.laycan_month,
        no_of_shipments:data.no_of_shipments,
        quantity_type:data.quantity_type,
        remark:data.remark };
       console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(API_URL+"add/shipment/" + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              navigate("/opportunity/oppshipment-detail/"+response.opp_ship_id)
          } else if (response.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }


            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opportunity Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>
           <Form onSubmit={handleSubmit(onSubmit)} id="price">
           <div id="messageContainer"></div>
           {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
           <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    defaultValue={opp.currency || ""} onChange={handleChange}
                    placeholder="Currency"

                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    defaultValue={opp.exchange_rate || ""} onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"

                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity" className='link-text'
                    name="opportunity_shipment"
                    defaultValue={opp.opportunity_name || ""} onChange={handleChange}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
        <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  {...register("price_type", {
    valueAsNumber: false,
    required: false,
  })}
  onChange={(e) => {
    setValue("price_type", e.target.value); // Set value for price_type
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>


                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    disabled={priceType !== "Fix Price"}
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                      {...register("price_basis", {
                      valueAsNumber: false,
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      {...register("sales_price_margin", {
        valueAsNumber: true,
        required: false,
      })}
      disabled={priceType === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                       {...register("shipping_term", {
                      valueAsNumber: false,
                      required: false, })}>
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              </Row>


              <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan From Date"
          className="mb-3 datepick"


        >
            <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
          placeholderText="Select Date"
      />
        </FloatingLabel>
      </Form.Group>



              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    className={`error-validation form-control ${errors.laycan_year ? 'is-invalid' : ''}`}
                    {...register("laycan_year", { required: 'Laycan Year is required' })}
                  >
                    <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                  {errors.laycan_year && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan To Date"
          className="mb-3 datepick error-validation"


        >
           <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        dateFormat='dd/MM/yyyy'
        selectsEnd

         placeholderText="Select Date"
      />
 </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    className={`error-validation form-control ${errors.laycan_month ? 'is-invalid' : ''}`}
                    {...register("laycan_month", { required: 'Laycan Month is required' })}
                  >
                   <option value="">---None---</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>

              </Row>
              <Row className="mb-3">
              <h4 className="heading">Quantity</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity"
                    name="quantity"
                    defaultValue={opp.quantity || ""} onChange={handleChange}
                    // className={`error-validation form-control ${errors.quantity ? 'is-invalid' : ''}`}
                    // {...register("quantity", { required: 'Quantity is required' })}
                    {...register("quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                    />
                </FloatingLabel>
                {errors.quantity && <div className="invalid-feedback"></div>}

              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="vessel type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="vessel type"
                                            {...register("vessel_type", {
                      valueAsNumber: false,
                      required: false,
                    })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quality_tolerance"
                                              {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remark"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remark"
                    name="remark"
                                             {...register("remark", {
                      valueAsNumber: false,
                      required: false,
                    })}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
               </Row>
               <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>

        </div>
        <Footer />
      </div>
    );
  }

  if (record === "Supplier") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData={
        currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_year_from:'2023-03-22',
laycan_from_date:'2023-03-22',
laycan_to_date:'2023-03-22',
laycan_year_to:'2023-03-22',
laycan_month_from:'2023-03-22',
laycan_month_to:'2023-03-22',
quantity:22,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:'Good',
quantity_info:'test',
quantity_type:'test',
quantity_from:33,
quantity_to:33,
laycan:"test",
pricing:'test',
// laycan_month:data.laycan_month,
// laycan_year:data.laycan_year,
no_of_shipments:data.no_of_shipments
     };
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(API_URL+"add/shipment/" + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              navigate("/opportunity/oppshipment-detail/"+response.opp_ship_id)
          } else if (response.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }

            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opportunity Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} id="price">
          <div id="messageContainer"></div>
          {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    defaultValue={opp.currency || ""} onChange={handleChange}
                    placeholder="Currency"

                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    defaultValue={opp.exchange_rate || ""} onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"

                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity" className='link-text'
                    name="opportunity_shipment"
                    defaultValue={opp.opportunity_name || ""} onChange={handleChange}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"

                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    disabled={priceType !== "Fix Price"}
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"

                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      {...register("sales_price_margin", {
        valueAsNumber: true,
        required: false,
      })}
      disabled={priceType === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
        <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  {...register("price_type", {
    valueAsNumber: false,
    required: false,
  })}
  onChange={(e) => {
    setValue("price_type", e.target.value); // Set value for price_type
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>


                </FloatingLabel>
              </Form.Group>

            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan from date"
            className="mb-3 datepick"


          >
          <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan From Date"
          className="mb-3 datepick"


        >
            <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
          placeholderText="Select Date"
      />
        </FloatingLabel>
      </Form.Group>
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    className={`error-validation form-control ${errors.laycan_year ? 'is-invalid' : ''}`}
                    {...register("laycan_year", { required: 'Laycan Year is required' })}
                  >
                    <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                  {errors.laycan_year && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan month to date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate4}
      onChange={(date) => setStartDate4(date)}
      customInput={<CustomInput4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='dd/MM/yyyy'
      placeholderText='Laycan month to date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    className={`error-validation form-control ${errors.laycan_month ? 'is-invalid' : ''}`}
                    {...register("laycan_month", { required: 'Laycan Month is required' })}
                  >
                    <option value="">---None---</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>

              </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity Type"

                    {...register("quantity_type", {valueAsNumber:false, required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"

                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment quality tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment quality tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quality_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>

        </div>
        <Footer />
      </div>
    );
  }

    if (record === "Supplier - Long Term") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData={
        exchange_rate:data.exchange_rate,
      status:data.status,
      opportunity:data.opportunity,
      opportunity_shipment:data.opportunity_shipment,
      record_type:data.record_type,
      price_type:data.price_type,
      price_basis:data.price_basis,
      price:data.price,
      sales_price_margin:data.sales_price_margin,
      shipping_term:data.shipping_term,
      laycan_year:data.laycan_year,
      laycan_year_to:data.laycan_year_to,
      laycan_month_from:data.laycan_month_from,
      laycan_month_to:data.laycan_month_to,
      laycan_year_from:data.laycan_year_from,
      laycan_from_date:birthDate,
      laycan_to_date:BankDate,
      // laycan_month_to_date:data.CustomInput4datepick4,
      quantity:data.quantity,
      shipment_quality_tolerance:data.shipment_quality_tolerance,
      vessel_type:data.vessel_type,
      remark:data.remark,
      currency:data.currency,
      // laycan_from_date:BankDate ||null,
      // laycan_to_date:birthDate,
      laycan_month:data.laycan_month,
      laycan_year:data.laycan_year,
      currency:data.currency,
      no_of_shipments:data.no_of_shipments,
      quantity_type:data.quantity_type,
      remark:data.remark

     };

      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(API_URL+"add/shipment/" + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              navigate("/opportunity/oppshipment-detail/"+response.opp_ship_id)
          } else if (response.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }



            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opportunity Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>
         <Form onSubmit={handleSubmit(onSubmit)} id="price">
         <div id="messageContainer"></div>
         {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
         <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    defaultValue={opp.currency || ""} onChange={handleChange}
                    placeholder="Currency"

                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    defaultValue={opp.exchange_rate || ""} onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"

                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity" className='link-text'
                    defaultValue={opp.opportunity_name || ""} onChange={handleChange}

                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"

                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    disabled={priceType !== "Fix Price"}
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"

                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      {...register("sales_price_margin", {
        valueAsNumber: true,
        required: false,
      })}
      disabled={priceType === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
        <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  {...register("price_type", {
    valueAsNumber: false,
    required: false,
  })}
  onChange={(e) => {
    setValue("price_type", e.target.value); // Set value for price_type
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>


                </FloatingLabel>
              </Form.Group>

            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                    {...register("laycan_year_from", { required: true })}
                  >
                    <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

               <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingSelect"
                   className="dropDown"
                   label="laycan Year To"
                 >
                   <Form.Select
                     aria-label="Floating label select example"
                     name="laycan_year_to"
                     placeholder="Laycan Year To"
                      {...register("laycan_year_to", { required: false })}
                   >
                     <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                   </Form.Select>

                 </FloatingLabel>
               </Form.Group>
               <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    {...register("laycan_month_from", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_to"
                    placeholder="Laycan Month To"
                    {...register("laycan_month_to", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
                     </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity"

                    {...register("quantity_type", {valueAsNumber:false, required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity from"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity from"
                    name="quantity_from"
                     defaultValue={qty}
                    {...register("quantity_from", {valueAsNumber:true, required: false })} />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity"
                    name="quantity"
                    defaultValue={opp.quantity || ""} onChange={handleChange}
                    // className={`error-validation form-control ${errors.quantity ? 'is-invalid' : ''}`}
                    // {...register("quantity", { required: 'Quantity is required' })}
                    {...register("quantity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                    />
                </FloatingLabel>
                {errors.quantity && <div className="invalid-feedback"></div>}

              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity to"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity to"
                    name="quantity_to"
                    {...register("quantity_to", {valueAsNumber:true, required: false })}/>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {valueAsNumber:true, required: false })}/>
                </FloatingLabel>
              </Form.Group>

            </Row>
            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>

        </div>
        <Footer />
      </div>
    );
  }

  if (record === "Supplier - Spot") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData={
        exchange_rate:data.exchange_rate,
        status:data.status,
        opportunity:data.opportunity,
        opportunity_shipment:data.opportunity_shipment,
        record_type:data.record_type,
        price_type:data.price_type,
        price_basis:data.price_basis,
        price:data.price,
        sales_price_margin:data.sales_price_margin,
        shipping_term:data.shipping_term,
        laycan_year:data.laycan_year,
        laycan_year_to:data.laycan_year_to,
        laycan_month_from:data.laycan_month_from,
        laycan_from_date:birthDate,
        laycan_to_date:BankDate,
        laycan_month_to:data.laycan_month_to,
        laycan_year_from:data.laycan_year_from,
        // laycan_month_to_date:data.CustomInput4datepick4,
        quantity:data.quantity,
        shipment_quality_tolerance:data.shipment_quality_tolerance,
        vessel_type:data.vessel_type,
        remark:data.remark,
        currency:data.currency,
        laycan_month:data.laycan_month,
        laycan_year:data.laycan_year,
        currency:data.currency,
        quantity_to:data.quantity_to,
        no_of_shipments:data.no_of_shipments,
        quantity_type:data.quantity_type,
        remark:data.remark

     };

     console.log(JSON.stringify(responseData));

      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(API_URL+"add/shipment/" + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              navigate("/opportunity/oppshipment-detail/"+response.opp_ship_id)
          } else if (response.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }

            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opportunity Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>
            <Form onSubmit={handleSubmit(onSubmit)} id="price">
            <div id="messageContainer"></div>
            {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    defaultValue={opp.currency || ""} onChange={handleChange}
                    placeholder="Currency"

                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    defaultValue={opp.exchange_rate || ""} onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"

                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity" className='link-text'
                    name="opportunity_shipment"
                    defaultValue={opp.opportunity_name || ""} onChange={handleChange}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
        <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  {...register("price_type", {
    valueAsNumber: false,
    required: false,
  })}
  onChange={(e) => {
    setValue("price_type", e.target.value); // Set value for price_type
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>


                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    disabled={priceType !== "Fix Price"}
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                      {...register("price_basis", {
                      valueAsNumber: false,
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                </FloatingLabel>
                </Form.Group>
<Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      {...register("sales_price_margin", {
        valueAsNumber: true,
        required: false,
      })}
      disabled={priceType === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                       {...register("shipping_term", {
                      valueAsNumber: false,
                      required: false, })}>
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              </Row>
            <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan From Date"
          className="mb-3 datepick"


        >
            <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
          placeholderText="Select Date"
      />
        </FloatingLabel>
      </Form.Group>




      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    className={`error-validation form-control ${errors.laycan_year ? 'is-invalid' : ''}`}
                    {...register("laycan_year", { required: 'Laycan Year is required' })}
                  >
                    <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                  {errors.laycan_year && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan To Date"
          className="mb-3 datepick error-validation"


        >
           <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        dateFormat='dd/MM/yyyy'
        selectsEnd

         placeholderText="Select Date"
      />
 </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    className={`error-validation form-control ${errors.laycan_month ? 'is-invalid' : ''}`}
                    {...register("laycan_month", { required: 'Laycan Month is required' })}
                  >
                   <option value="">---None---</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <div className="invalid-feedback"></div>}
                </FloatingLabel>
              </Form.Group>

              </Row>
              <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity"
                    name="quantity"
                    // className={`error-validation form-control ${errors.quantity ? 'is-invalid' : ''}`}
                    {...register("quantity", {
                      valueAsNumber: true,
                      required: false,
                    })} />
                </FloatingLabel>
                {errors.quantity && <div className="invalid-feedback"></div>}

              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="vessel type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="vessel type"
                                            {...register("vessel_type", {
                      valueAsNumber: false,
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quality_tolerance"
                                              {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remark"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remark"
                    name="remark"
                                             {...register("remark", {
                      valueAsNumber: false,
                      required: false,
                    })}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
               </Row>


               <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"

    /><Link to={`/opportunity/opportunity-detail/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>

        </div>
        <Footer />
      </div>
    );
  }
}

export default AddoppShippmentInner;
