import React, { Component, useState, useEffect, useContext } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import { API_URL } from '../config';
import Footer from "../Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const QuotesView = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState({});

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/offer_quote_byID/"+id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data);
      setDatas(data);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };
  if (!datas) {
    return null;
  }
  return (
    <div id="view-page">
      <Header />

      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
            Quotes Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Quotes Details
              </li>
            </ol>

          </div>
        </div>
        <div className='tasks'>
          <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Quote Detail</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">

     <tbody>
      <tr>
         <td><span>Quote Number</span><br/>{datas.quote_number}</td>
         <td><span>Quote Record Type</span><br/>{datas.quote_record_type}</td>
         <td><span>Currency</span><br/>{datas.currency}</td>
      </tr>
      <tr>
         <td><span>Locked</span><br/>{datas.locked}</td>
         <td><span>Exchange Rate</span><br/>{datas.exchange_rate}</td>
         <td><span>Quote Name</span><br/>{datas.quote_name}</td>
      </tr>
      <tr>
         <td><span>Opportunity Name</span><br/>{datas.opportunity_name}</td>
         <td><span>Account Name</span><br/>{datas.account_name}</td>
         <td><span>Status</span><br/>{datas.status}</td>
      </tr>
      <tr>
         <td><span>Contact Name</span><br/>{datas.contact_name}</td>
         <td><span>Supplier</span><br/>{datas.supplier}</td>
         <td><span>Quote Date</span><br/>{datas.quote_date}</td>
      </tr>
      <tr>
         <td><span>Expiration Date</span><br/>{datas.expiration_date}</td>
         <td><span>Trader Name</span><br/>{datas.trader_name}</td>
         <td><span>No of Quote Shipment</span><br/>{datas.no_of_quote_shipment}</td>
      </tr>

     </tbody>
   </table>

  <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Product information</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">

     <tbody>
      <tr>
         <td><span>Quantity MT</span><br/>{datas.quantity_MT}</td>
         <td><span>Contract</span><br/>{datas.contract}</td>
         <td><span>Product Description</span><br/>{datas.product_description}</td>
      </tr>

     </tbody>
   </table>

  <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Market Information</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">

     <tbody>
      <tr>
         <td><span>Origin</span><br/>{datas.origin}</td>
         <td><span>Commodity</span><br/>{datas.commodity}</td>
         <td><span>Destination</span><br/>{datas.destination}</td>
      </tr>

     </tbody>
   </table>
  <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Price and Price Adjustment</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">

     <tbody>
      <tr>
         <td><span>Bonus/Penalty Clause</span><br/>{datas.bonus_penalty_clause}</td>
         <td><span>Rejections</span><br/>{datas.rejections}</td>
         <td><span>Payment Term </span><br/>{datas.payment_term}</td>
      </tr>
           <tr>
         <td><span>General Terms </span><br/>{datas.general_terms}</td>
         <td></td>
         <td></td>
      </tr>

     </tbody>
   </table>

  <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Terms & Conditions</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">

     <tbody>
      <tr>
         <td><span>Arbitration</span><br/>{datas.arbitration}</td>
         <td><span>Rwood Surveyor Agent </span><br/>{datas.rwood_surveyor_agent }</td>
         <td><span>Applicable Law</span><br/>{datas.applicable_law}</td>
      </tr>

      <tr>
         <td><span>Surveyor 2 </span><br/>{datas.surveyor2 }</td>
         <td><span>Offer Validity Terms</span><br/>{datas.offer_validity_terms }</td>
         <td><span>Surveyor 3 </span><br/>{datas.surveyor3 }</td>
      </tr>
         <tr>
         <td><span>Description</span><br/>{datas.description }</td>
         <td></td>
         <td></td>
      </tr>


     </tbody>
   </table>

  <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Prepared For</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">

     <tbody>
      <tr>
         <td><span>Quote To Name</span><br/>{datas.quote_to_name}</td>
         <td><span>Additional To Name </span><br/>{datas.additional_to_name }</td>
         <td><span>Quote To</span><br/>{datas.quote_to_name}</td>
      </tr>

      <tr>
         <td><span>Additional To </span><br/>{datas.additional_to_name }</td>
         <td><span>Email</span><br/>{datas.email }</td>
         <td><span>Phone</span><br/>{datas.phone }</td>
      </tr>
         <tr>
         <td><span>Fax</span><br/>{datas.fax}</td>
         <td></td>
         <td></td>
      </tr>


     </tbody>
   </table>

  <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Address Information</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">

     <tbody>
      <tr>
         <td><span>Bill To Name</span><br/>{datas.bill_to_name}</td>
         <td><span>Ship To Name </span><br/>{datas.ship_to_name  }</td>
         <td></td>
      </tr>
      </tbody>
   </table>
  <div className='row'>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">System Information</h4>
     <hr></hr>
     </div>
   </div>

   <table class="table table-bordered account-table tables">
     <tbody>
      <tr>
         <td><span>Created By</span><br/>{datas.created_by}</td>
         <td><span>Last Modified By</span><br/>{datas.modified_by}</td>
         <td></td>
      </tr>
      </tbody>
   </table>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default QuotesView;
