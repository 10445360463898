// import React,{useState, useEffect, useContext} from 'react'
// import { useNavigate } from 'react-router-dom';
import { TokenContext } from './AuthProvider';

// const UserName = () => {
//     const {username} = useContext(TokenContext)
//     console.log(username)
//     const authLinks = (
//         <div style={{color:'#1E90FF'}}>
//         {username ? `${username}` : ""}
//         </div>
//       );

//       const guestLinks = "";

//       return <div>{username ? authLinks : guestLinks}</div>;

// }

// export default UserName


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const UserName = () => {
  const { userName } = useAuth();
  const navigate = useNavigate();

  // const usernames= 'Siva'
  return (
    <div style={{ color: '#e47027' }}>
      {userName ? userName : ""}
    </div>
  );
};

export default UserName;