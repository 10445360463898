import React, { Component,useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import Login from "./Login";
import Navigation from "../navigation";
import { GoDashboard } from "react-icons/go";
import Dropdown from "react-bootstrap/Dropdown";
import UserName from "./UserName";
import Logout from "./Logout";
import "../css/style.css";
import DropdownButton from 'react-bootstrap/DropdownButton';
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import Container from 'react-bootstrap/Container'; 
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
  BsPerson,
} from "react-icons/bs";
 

import $ from "jquery";
import { useNavigate } from "react-router-dom";
export default function HeaderFinanace() {
  const [selectedItem, setSelectedItem] = useState("change page ");
  const [active, setActive] = useState('default');
  const prev = () => {
    $(".menu-inner-box").animate({ scrollLeft: "-=100px" });
  };
  const next = () => {
    $(".menu-inner-box").animate({ scrollLeft: "+=100px" });
  };
  const navigate = useNavigate();
  const [status, setStatus] = useState('');

  function changeStatus(e) {
    setStatus(e.target.value);
  }
  if(status==='trading'){
    window.location='/rwood-trading'
    alert(status)
  }
  else if(status=='finance'){
    window.location='/rwood-finance'
    alert(status)
  }
  const toggle = () => {
    $(".nav").toggleClass("justify-content-end");
    $(".toggle").toggleClass("text-light");
  }; 
    $(document).on('click','#actives',function(){
      setTimeout(function(){ 
        $('.active-menu').removeClass('active-menu');
        
        $(this).addClass('active-menu');
      }, 5000);
        
    });    
   const changeValue=(text)=>
    {
      this.setState({dropDownValue: text});
    }
  return (
    <>
      <div className="container-fluid" id="header">
        <div className="row">
          <div className="col-md-12">
            <div className="pull-left d-flex">
              <img src="../../images/logo.jpg" className="logo" />
              <div class="search">
                <i class="fa fa-search"></i>
                <input type="text" class="form-control" placeholder="Search" />
              </div>
            </div>
            <div className="pull-right">
             
            <div className="pull-right">
              
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="rounded-0"
                  style={{
                    height: "50px",
                  }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="user-name"
                  >
                    <BsPerson />
                    <div style={{ marginLeft: "5px", marginBottom: "5px" }}>
                      <p id="admin-label">
                        <span>Admin</span>
                        <br />
                        <span>
                          <UserName />
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Setting</Dropdown.Item>
                  <Dropdown.Item className="logout-btn">
                    <Logout />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
           
       
      <div>
    <Dropdown className="dropdown-groove">
      <Dropdown.Toggle variant="link" id="dropdown-basic">
        {selectedItem}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          as={Link}
          to="/dashboard"
          onClick={() => setSelectedItem("Trading")}
        >
          Trading
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to="/dashboard-finance"
          onClick={() => setSelectedItem("Finance")}
        >
          Finanace
        </Dropdown.Item>

        
      </Dropdown.Menu>
    </Dropdown>
      </div>
    
              </div>
          </div>
        </div>
      </div>

      <div>
                <div class="navbar">
                
        <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
         
            <Nav.Link id='actives' ><Link to="/dashboard" className="menu-item">
                  Dashboard
                </Link></Nav.Link>
            <Nav.Link id='actives'> <Link to="#" className="menu-item">
                  Reports
                </Link></Nav.Link>
                <Nav.Link id='actives' as={Link} to="/accounts/account-lists"> <Link to="/accounts/account-lists" className="menu-item">
                  Accounts
                </Link> </Nav.Link>
                <Nav.Link id='actives' as={Link} to="/contact/contact-list"><Link to="/contact/contact-list" className="menu-item">
                  Contacts
                </Link> </Nav.Link>
                <Nav.Link id='actives'>   <Link to="/productmaster/create-view-list" className="menu-item">
                  ProductMaster
                </Link> </Nav.Link>
               
                <Nav.Link id='actives'>  <Link to="/contract/create-contract-list" className="menu-item">
                  Contracts
                </Link></Nav.Link>
                <Nav.Link id='actives'> <Link to="/order/create-order-list" className="menu-item">
                  Orders
                </Link> </Nav.Link>
                <Nav.Link className="tcfs"> <Link to="/tcf/create-view-list" className="menu-item">
                  TCF
                </Link> </Nav.Link>
            <NavDropdown title="More" id="basic-nav-dropdown" className="navmenu">
              <NavDropdown.Item>
              <Nav.Link id='actives'><Link
                  to=""
                  className="menu-item"
                >
                  Campaigns
                </Link></Nav.Link></NavDropdown.Item>
                {/* <NavDropdown.Item>
                <Nav.Link id='actives'> <Link
                  to="/quote/quote-list"
                  className="menu-item"
                >
                  Quotes
                </Link></Nav.Link></NavDropdown.Item> */}
                
                
                <NavDropdown.Item as={Link} to="/contract-shipment/create-view-list">
                <Nav.Link as={Link} to="/contract-shipment/create-view-list"
               className="menu-item"
               >
                  Contract Shipments
               </Nav.Link></NavDropdown.Item>
                 


                <NavDropdown.Item>
                <Nav.Link><Link
                  to=""
                  className="menu-item"
                >
                  Chatter
                </Link></Nav.Link></NavDropdown.Item>
                <NavDropdown.Item>
                <Nav.Link><Link
                  to=""
                  className="menu-item"
                >
                  Loading Status
                </Link></Nav.Link></NavDropdown.Item>
                <NavDropdown.Item>
                <Nav.Link><Link
                  to=""
                  className="menu-item"
                >
                  Debit Notes
                </Link></Nav.Link></NavDropdown.Item>
              
              
                <NavDropdown.Item as={Link} to="/buyer/buyer-list">
                <Nav.Link as={Link} to="/buyer/buyer-list"
               className="menu-item"
               >
                  Buyer Products
               </Nav.Link></NavDropdown.Item>
                 
                <NavDropdown.Item>
                <Nav.Link><Link
                  to=""
                  className="menu-item"
                >
                  Laytime Calculations
                </Link></Nav.Link></NavDropdown.Item>
                <NavDropdown.Item>
                <Nav.Link><Link
                  to=""
                  className="menu-item"
                >
                  Master Clauses
                </Link></Nav.Link></NavDropdown.Item>
                <NavDropdown.Item>
                <Nav.Link> <Link
                  to=""
                  className="menu-item"
                >
                  Contract Clauses
                </Link></Nav.Link></NavDropdown.Item>
                <NavDropdown.Item>
                <Nav.Link><Link
                  to=""
                  className="menu-item"
                >
                  Contract Products
                </Link></Nav.Link></NavDropdown.Item>
             
                <NavDropdown.Item as={Link} to="/bank/bank-list">
                <Nav.Link as={Link} to="/bank/bank-list"
               className="menu-item"
               >
                 Rwood Banks Master
               </Nav.Link></NavDropdown.Item>

 
                
              
                <NavDropdown.Item as={Link} to="/price/price-list">
                <Nav.Link as={Link} to="/price/price-list"
                  
                  className="menu-item"
                >
                  
                   Price Types  
                </Nav.Link></NavDropdown.Item>
               
                <NavDropdown.Item as={Link} to="/pricebook/create-view-list">
                <Nav.Link as={Link} to="/pricebook/create-view-list"
               className="menu-item"
               >
                  Price Book
               </Nav.Link></NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/company/company-list">
                <Nav.Link as={Link} to="/company/company-list"
               className="menu-item"
               >
                  Companies
               </Nav.Link></NavDropdown.Item>
              


               <NavDropdown.Item as={Link} to="/user/userlist">
                <Nav.Link as={Link} to="/user/userlist"
               className="menu-item"
               >
                   Rwood Users
               </Nav.Link></NavDropdown.Item>
                 

 
            <NavDropdown.Item>
            <Nav.Link id='actives'> <Link
                  to=""
                  className="menu-item"
                >
                  Market Analysis
                </Link></Nav.Link></NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/post-shipment/create-view-list">
                <Nav.Link as={Link} to="/post-shipment/create-view-list"
               className="menu-item"
               >
                  Post Shipment Documents
               </Nav.Link></NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/pre-shipment/create-view-list">
                <Nav.Link as={Link} to="/pre-shipment/create-view-list"
               className="menu-item"
               >
                 Pre Shipment Documents
               </Nav.Link></NavDropdown.Item>

 
           
            <NavDropdown.Item>
            <Nav.Link> <Link
                  to=""
                  className="menu-item"
                >
                  Cases
                </Link></Nav.Link></NavDropdown.Item>
               
            <NavDropdown.Item>
            <Nav.Link> <Link
                  to=""
                  className="menu-item"
                >
                  Legacy Data
                </Link></Nav.Link></NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/all-quotes">
                <Nav.Link as={Link} to="/all-quotes"
               className="menu-item"
               >
                 Quote Product
               </Nav.Link></NavDropdown.Item>

 
                
            </NavDropdown>
            
               
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar> 
          {/* <nav id="menu-container" class="arrow">
            <div id="btn-nav-previous" Style="fill: #333" onClick={prev}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </div>
            <div id="btn-nav-next" onClick={next}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                Style="fill: #333"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </div>
            <div class="menu-inner-box">
              <div class="menu">
                <Link to="/dashboard" className="menu-item">
                  Dashboard
                </Link>
                <Link to="#" className="menu-item">
                  Reports
                </Link>

                <Link to="/accounts/account-list" className="menu-item">
                  Accounts
                </Link>
                <Link to="/contact/contact" className="menu-item">
                  Contacts
                </Link>
                <Link to="#" className="menu-item">
                  Products
                </Link>
                <Link to="/price/price-list" className="menu-item">
                  Price Type
                </Link>
                <Link to="/pricebook/pricebook-list" className="menu-item">
                  Pricebook
                </Link>
                <Link to="/user/userlist" className="menu-item">
                  Rwood User
                </Link>
                <Link to="/bank/bank-list" className="menu-item">
                  Rwood Bank
                </Link>
                <Link to="/company/company-list" className="menu-item">
                  Company
                </Link>
                <Link to="/opportunity/opportunity-list" className="menu-item">
                  Opportunities
                </Link>
                <Link to="/tcf/tcf-list" className="menu-item">
                  TCF
                </Link>
                <Link to="/contract/contract-list" className="menu-item">
                  Contract
                </Link>
                <Link
                  to="/shippment/contract-shippment-list"
                  className="menu-item"
                >
                  Contract Shipment
                </Link>
                <Link to="/preshipment/list" className="menu-item">
                  Preshipment
                </Link>
                <Link
                  to="/postshipment/postshipment-list"
                  className="menu-item"
                >
                  Postshipment
                </Link>
                <Link to="/opportunity/opp-shipment-list" className="menu-item">
                  Opp Shipments
                </Link>
                <Link to="/order/order-list" className="menu-item">
                  Orders
                </Link>
                <a href="#" className="menu-item">
                  Compaigns
                </a>
                <Link to="/product-master-list" className="menu-item">
                  Product Master
                </Link>
              </div>
            </div>
          </nav> */}
          {/* <div class="d-flex align-items-center">
        <div class="flex-shrink-0">
            <a href="#" class="btn-left btn-link p-2 toggle text-dark" onClick={toggle}><BsFillArrowLeftCircleFill/></a>
        </div>
        <div class="flex-grow-1 w-100 o-hidden">
            <ul class="nav nav-fill text-uppercase small position-relative flex-nowrap">
            <li class="nav-item">
                    <Link to="/add-product-master" class="nav-link">Dashboard</Link>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link">Reports</a>
                </li>
                <li class="nav-item">
                    <Link to="/account-list" class="nav-link">Accounts</Link>
                </li>
                <li class="nav-item"> 
                    <Link to="/account/contact-list" class="nav-link">Contacts</Link>
                    
                </li>
                <li class="nav-item">
                <Link to="#" class="nav-link">Products</Link>
                </li>
                <li class="nav-item">
                <Link to="/price-list" class="nav-link">Price Type</Link>
                </li>
                <li class="nav-item">
                <Link to="/opportunity" class="nav-link">Opportunities</Link>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link">TCF</a>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link">Contracts</a>
                </li>
                <li class="nav-item">
                    <Link to="/add-shipment" class="nav-link">Opp Shippments</Link>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link">Orders</a>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link">Compaigns</a>
                </li>
                <li class="nav-item"> 
                    <Link to="/product-master" class="nav-link">Product Master</Link>
                </li> 
                 
            </ul>
        </div>
        <div class="flex-shrink-0">
            <a href="#" class="btn-right btn-link toggle p-2 text-dark" onClick={toggle}><BsFillArrowRightCircleFill/></a>
        </div>
    </div> */}
        </div>
      </div>
    </>
  );
}
