import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import { API_URL } from '../config';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { parse, format , isValid } from 'date-fns';
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const Quoteshipmentdetail = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [getPrice, setgetPrice] = useState([]);
  const [accountNames, setAccountName]=useState([]);
  const [apiDate, setApiDate] = useState(null);
  const [endDateError, setEndDateError] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL+'get/quotesbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;

      setAccountName(data)
       console.log('Supplier'+ JSON.stringify(data))
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data)
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };


  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };



  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL+"add/record", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          console.log(response);
          console.log(response.status);
          const account_record_new = response.account_type;

          navigate("/contact/contact-by-opportunity/" + id, {
            state: { account_record: account_record_new },
          });
          if (response.status === 200) {

          }
        });
    }
  };
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [lenthData, setlenthData] = useState([]);
  const [history, setHistory] = useState([]);
  const [dataLength, setDataLength] = useState([]);
  const [bdate, setbirthDate]=useState('')


  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/shipmentsbyID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response)
      setDataLength(response.data.quote_shipments.length)
    const data = response.data.quote_shipments[0];
    const historyData=response.data.history;
    console.log(data)
    const startDate=parse(data.quote_shipment_laycan_from_date, 'dd/MM/yyyy', new Date());
    setStartDate(startDate);
    const endDate=parse(data.quote_shipment_laycan_to_date, 'dd/MM/yyyy', new Date());
    setEndDate(endDate);
    setHistory(historyData)

    console.log(response.data.quote_shipments.length)



      setDatas(data);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
    const dateObject = new Date(startDate1);
    if (dateObject) {
      const formattedDate = format(dateObject, 'yyyy-MM-dd', { timeZone: 'UTC' });
      setbirthDate(formattedDate)
    }
  })

  const [startDate, setStartDate] = useState('');

  const handleStartDateChange = (date) => {
    setStartDate(date);

  };
  const handleEndDateChange = (date) => {
    setEndDate(date);

    if (!startDate) {
      setEndDateError('Please select a Laycan From Date	.');
    } else {
      const diffInTime = date.getTime() - startDate.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      if (diffInDays !== 10) {
        setEndDateError('Laycan to Date must be exactly 10 days after the Laycan From Date ');
      } else {
        setEndDateError('');
      }
    }
  };


  // if (!datas) {
  //   return null;
  // }
  setTimeout(() => {
    $("#quotes").DataTable();
    $("#history").DataTable();
  }, 10);
  const handleSaveEdit = async (date) => {

    //  if(datas.quote_shipment_record_type==='Buyer - Spot'){


    const responseData={
      "quote_shipment_currency":datas.quote_shipment_currency,
"quote_shipment_exchange_rate ":datas.quote_shipment_exchange_rate,
"quote_shipment_status":datas.quote_shipment_status,
"quote_shipment_opportunity_shipment":datas.quote_shipment_opportunity_shipment,
"quote_shipment_record_type":datas.quote_shipment_record_type,
"quote_shipment_price_type":datas.quote_shipment_price_type,
"quote_shipment_laycan_year":datas.quote_shipment_laycan_year,
"quote_shipment_price_basis":datas.quote_shipment_price_basis,
"quote_shipment_price":datas.quote_shipment_price,
"quote_shipment_sales_price_margin":datas.quote_shipment_sales_price_margin,
"quote_shipment_shipping_term":datas.quote_shipment_shipping_term,
"quote_shipment_laycan_year_from":datas.quote_shipment_laycan_year_from,
"quote_shipment_laycan_year_to":datas.quote_shipment_laycan_year_to,
"quote_shipment_laycan_month_from":datas.quote_shipment_laycan_month_from,
"quote_shipment_laycan_month_to":datas.quote_shipment_laycan_month_to,
"quote_shipment_quantity":datas.quote_shipment_quantity,
"quote_shipment_shipment_quality_tolerance":datas.quote_shipment_shipment_quality_tolerance,
"quote_shipment_vessel_type":datas.quote_shipment_vessel_type,
"quote_shipment_remark":datas.quote_shipment_remark,
"quote_shipment_laycan_from_date":startDate?format(startDate, 'dd/MM/yyyy'):null,
"quote_shipment_laycan_to_date":endDate?format(endDate, 'dd/MM/yyyy'):null,
"quote_shipment_no_of_shipments":datas.quote_shipment_no_of_shipments,
"quote_shipment_quantity_info":datas.quote_shipment_quantity_info,
"quote_shipment_quantity_type":datas.quote_shipment_quantity_type,
"quote_shipment_quantity_from":datas.quote_shipment_quantity_from,
"quote_shipment_quantity_to":datas.quote_shipment_quantity_to,
"quote_shipment_laycan":datas.quote_shipment_laycan,
"quote_shipment_pricing":datas.quote_shipment_pricing

    };
    console.log(JSON.stringify(responseData))

    fetch(`${API_URL}update/quote_shipment/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData();
        if(updatedData.msg==='Quote updated successfully'){

          // fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
    }
  // }
  return (
    <div id="view-page">
      <Header />

      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Quote Shipment Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Quote Shipment Details
              </li>
            </ol>
          </div>
        </div>
        <div className='tasks'>
   <div className=''>
   <div className='row'>
        {dataLength>0?(
          <>
        {datas.quote_shipment_record_type==='Buyer - Long Term' ? (

            <>
            <div className="tasks">
            <p className="edit-btn">
                      <button
                        className="btn btn-primary"

                      >
                        Edit
                      </button>
                      <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                        className="btn btn-primary"

                      >
                       Cancel
                      </button></Link>
                    </p>
             <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Quote Shipment Detail
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Quote Shipment Name
</span>
         </td>
         <td>{datas.quote_shipment_code}</td>
         <td id="td-right">
           <span>Record Type
</span>
         </td>
         <td>{datas.quote_shipment_record_type  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Quote</span>
         </td>
          <td><Link to={`/opportunity/quote-view/${datas.offer_id}`}>{datas.quote_shipment_opportunity_shipment}</Link></td>
         <td id="td-right">
           <span>Status</span>
         </td>
         <td>{datas.quote_shipment_status  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Currency</span>
         </td>
         <td>{datas.quote_shipment_currency}</td>
         <td id="td-right">
           <span></span>
         </td>
         <td> </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Exchange Rate
</span>
         </td>
         <td>{datas.quote_shipment_exchange_rate}</td>
         <td id="td-right">
           <span></span>
         </td>
         <td> </td>
       </tr>


     </tbody>
   </table>

   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Price
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Shipping Term

</span>
         </td>
         <td>{datas.quote_shipment_shipping_term}</td>
         <td id="td-right">
           <span>Pricing
</span>
         </td>
         <td>{datas.quote_shipment_pricing  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Price Basis


</span>
         </td>
         <td>{datas.quote_shipment_price_basis}</td>
         <td id="td-right">
           <span>Price
</span>
         </td>
         <td>{datas.quote_shipment_price  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Price Type


</span>
         </td>
         <td>{datas.quote_shipment_price_type}</td>
         <td id="td-right">
           <span>Sales Price Margin


</span>
         </td>
         <td>{datas.quote_shipment_sales_price_margin  } </td>
       </tr>

       </tbody>
       </table>

       <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Delivery
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Laycan Year From


</span>
         </td>
         <td>{datas.quote_shipment_laycan_year_from}</td>
         <td id="td-right">
           <span>Laycan
</span>
         </td>
         <td>{datas.quote_shipment_laycan  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Laycan Year To


</span>
         </td>
         <td>{datas.quote_shipment_laycan_year_to}</td>
         <td id="td-right">
           {/* <span>Laycan From Date

</span> */}
         </td>
         <td>
          {/* {datas.quote_shipment_laycan_from_date  } */}
           </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>

</span>
         </td>
         <td></td>
         <td id="td-right">
           {/* <span>Laycan To Date

</span> */}
         </td>
         <td>
          {/* {datas.quote_shipment_laycan_to_date  }  */}
          </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Laycan Month From


</span>
         </td>
         <td>{datas.quote_shipment_laycan_month_from}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td>  </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Laycan Month To


</span>
         </td>
         <td>{datas.quote_shipment_laycan_month_to}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>
       </tr>

       </tbody>
       </table>

       <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Quantity
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Quanity Info



</span>
         </td>
         <td>{datas.quote_shipment_quantity_info}</td>
         <td id="td-right">
           <span>No of Shipments

</span>
         </td>
         <td>{datas.quote_shipment_no_of_shipments  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Quantity Type
</span>
         </td>
         <td>{datas.quote_shipment_quantity_type}</td>
         <td id="td-right">
           <span>Quantity
</span>
         </td>
         <td>{datas.quote_shipment_quantity  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Quantity from



</span>
         </td>
         <td>{datas.quote_shipment_quantity_from}</td>
         <td id="td-right">
           <span>Shipment Quantity Tolerance

</span>
         </td>
         <td>{datas.quote_shipment_shipment_quality_tolerance  } </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Quantity To



</span>
         </td>
         <td>{datas.quote_shipment_quantity_to}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>
       </tr>
        </tbody>
       </table>
       <p className="edit-btn">
                      <button
                        className="btn btn-primary"

                      >
                        Edit
                      </button>
                      <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                        className="btn btn-primary"

                      >
                       Cancel
                      </button></Link>
                    </p>
                    <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>History</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <thead>
                              <tr>
                                <th>Field Name</th>
                                <th>New Value</th>
                                <th>Old Value</th>
                                <th>Changed By</th>
                                <th>Change Time</th>
                              </tr>
                            </thead>

                            {history.length > 0 ? (
                              <tbody>
                                {history.map((y, i) => (
                                  <tr>
                                    <td>{y.field_name}</td>
                                    <td>{y.new_value}</td>
                                    <td>{y.old_value}</td>
                                    <td>{y.changed_by}</td>
                                    <td>{y.change_time}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
       </div>
   </>
        ):
        ( <>

        {datas.quote_shipment_record_type==='Buyer - Spot' ? (<>
          {isEditMode ? (

          <>
          <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p>
 <Row className="mb-3">
        <h4 className='heading'>Pricebook Detail Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quote Shipment Name "
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Quote Shipment Name  "
              name="quote_shipment_code"
               defaultValue={datas.quote_shipment_code || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quote"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Quote"
              name="quote_shipment_opportunity_shipment"
               defaultValue={datas.quote_shipment_opportunity_shipment || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quote_shipment_status"
                    placeholder="Status"
                    defaultValue={datas.quote_shipment_status || ""}
     onChange={handleChangeInput}
                  >
                  <option value="">--None--</option><option value="Open">Open</option><option value="Closed">Closed</option>
                  </Form.Select>
                                  </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="record type"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="record type"
              name="quote_shipment_record_type"
defaultValue={datas.quote_shipment_record_type || ""}
     onChange={handleChangeInput}            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quote_shipment_currency"
                    placeholder="Currency"
                     value={datas.quote_shipment_currency || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="exchange rate"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="exchange rate"
              name="quote_shipment_exchange_rate"
                   defaultValue={datas.quote_shipment_exchange_rate || ""}
     onChange={handleChangeInput}
            />
          </FloatingLabel>
        </Form.Group>
        <h4 className='heading'>Price</h4>
        <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Price type"
                   >
                    <Form.Select
  aria-label="Floating label select example"
  name="quote_shipment_price_type"
  placeholder="Price type"
  defaultValue={datas.quote_shipment_price_type || ""}
  onChange={(e) => {
    handleChangeInput(e);
    if (e.target.value === "Fix Price") {
      // If price_type is "Fix Price", disable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = true;
      document.getElementById("sales_price_margin").value = "";
    } else if (e.target.value !== "Fix Price"){
      document.getElementById("price").disabled = true;
       document.getElementById("price").value = "";
    } else {
      // Otherwise, enable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = false;
    }
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Price"
              name="quote_shipment_price"
                   defaultValue={datas.quote_shipment_price || ""}
     onChange={handleChangeInput}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quote_shipment_price_basis"
                    placeholder="Price basis"
                     defaultValue={datas.quote_shipment_price_basis || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="quote_shipment_sales_price_margin"
      id="sales_price_margin" // Add an id to easily access the input
      defaultValue={datas.quote_shipment_sales_price_margin || ""}
      onChange={handleChangeInput}
      disabled={datas.quote_shipment_price_type === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6"></Form.Group>
<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quote_shipment_shipping_term"
                    placeholder="Shipping term"
                   defaultValue={datas.quote_shipment_shipping_term || ""}
     onChange={handleChangeInput}>
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
  <h4 className='heading'>Delivery</h4>
  <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan From Date"
          className="mb-3 datepick"


        >
            <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
          placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quote_shipment_laycan_year"
                    placeholder="Laycan Year"
                      defaultValue={datas.quote_shipment_laycan_year || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan To Date"
          className="mb-3 datepick error-validation"


        >
           <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        dateFormat='dd/MM/yyyy'
        selectsEnd

         placeholderText="Select Date"
      />
 </FloatingLabel>
 {endDateError && <div className='error-validations' style={{ color: 'red' }}>{endDateError}</div>}
      </Form.Group>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quote_shipment_laycan_month_from"
                    placeholder="Laycan Month"
                    defaultValue={datas.quote_shipment_laycan_month_from || ""}
     onChange={handleChangeInput}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

               <h4 className='heading'>Quantity</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quantity"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Quantity"
              name="quote_shipment_quantity"
 defaultValue={datas.quote_shipment_quantity || ""}
     onChange={handleChangeInput}                 />
          </FloatingLabel>
        </Form.Group>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="vessel type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quote_shipment_vessel_type"
                    placeholder="vessel type"
                     value={datas.quote_shipment_vessel_type || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Shipment Quantity Tolerance"
                     className="mb-3"
                   >
                     <Form.Control
                       type="number"
                       placeholder="Shipment Quantity Tolerance"
                       name="quote_shipment_quantity"
                         defaultValue={datas.quote_shipment_quantity || ""}
        onChange={handleChangeInput}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Remark"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual HBA 1"
              name="quote_shipment_remark"
defaultValue={datas.quote_shipment_remark || ""}
     onChange={handleChangeInput}              />
          </FloatingLabel>
        </Form.Group>
        <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p>

             </Row>

 </>
        ):(
            <div className="tasks">
                <p className="edit-btn">
                          <button  onClick={handleEditClick}
                            className="btn btn-primary"

                          >
                            Edit
                          </button>
                          <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                            className="btn btn-primary"

                          >
                           Cancel
                          </button></Link>
                        </p>
                 <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">Quote Shipment Detail
    </h4>
         <hr>
         </hr>
       </div>
       <table class="table table-bordered account-table tables">
         <tbody>
           <tr>
             <td id="td-right">
               <span>Quote Shipment Name
    </span>
             </td>
             <td>{datas.quote_shipment_code}</td>
             <td id="td-right">
               <span>Record Type
    </span>
             </td>
             <td>{datas.quote_shipment_record_type  } </td>
           </tr>
           <tr>
             <td id="td-right">
               <span>Quote</span>
             </td>
             <td><Link to={`/opportunity/quote-view/${datas.offer_id}`}>{datas.quote_shipment_opportunity_shipment}</Link></td>
             <td id="td-right">
               <span>Status</span>
             </td>
             <td>{datas.quote_shipment_status  } </td>
           </tr>

           <tr>
             <td id="td-right">
               <span>Quanity Info

    </span>
             </td>
             <td>{datas.quote_shipment_quantity_info}</td>
             <td id="td-right">
               <span></span>
             </td>
             <td></td>
             <td id="td-right">
               <span></span>
             </td>
           </tr>
           <tr>
             <td id="td-right">
               <span>Currency</span>
             </td>
             <td>{datas.quote_shipment_currency}</td>
             <td id="td-right">
               <span></span>
             </td>
             <td> </td>
           </tr>


           <tr>
             <td id="td-right">
               <span>Exchange Rate
    </span>
             </td>
             <td>{datas.quote_shipment_exchange_rate}</td>
             <td id="td-right">
               <span></span>
             </td>
             <td> </td>
           </tr>


         </tbody>
       </table>

       <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">Price
    </h4>
         <hr>
         </hr>
       </div>
       <table class="table table-bordered account-table tables">
         <tbody>
           <tr>
             <td id="td-right">
               <span>Shipping Term

    </span>
             </td>
             <td>{datas.quote_shipment_shipping_term}</td>
             <td id="td-right">
               <span>Pricing
    </span>
             </td>
             <td>{datas.quote_shipment_pricing  } </td>
           </tr>
           <tr>
             <td id="td-right">
               <span>Price Basis


    </span>
             </td>
             <td>{datas.quote_shipment_price_basis}</td>
             <td id="td-right">
               <span>Price
    </span>
             </td>
             <td>{datas.quote_shipment_price  } </td>
           </tr>
           <tr>
             <td id="td-right">
               <span>Price Type


    </span>
             </td>
             <td>{datas.quote_shipment_price_type}</td>
             <td id="td-right">
               <span>Sales Price Margin


    </span>
             </td>
             <td>{datas.quote_shipment_sales_price_margin  } </td>
           </tr>

           </tbody>
           </table>

           <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">Delivery
    </h4>
         <hr>
         </hr>
       </div>
       <table class="table table-bordered account-table tables">
         <tbody>

         <tr>
             <td id="td-right">
               <span>Laycan
    </span>
             </td>
             <td>{datas.quote_shipment_laycan  } </td>
             <td id="td-right">
               <span>Laycan Year
    </span>
             </td>
             <td>{datas.quote_shipment_laycan_year  } </td>
           </tr>
       <tr>
              <td id="td-right">
               <span>Laycan From Date

    </span>
             </td>
             <td>{datas.quote_shipment_laycan_from_date  } </td>
             <td id="td-right">
               <span>Laycan To Date

    </span>
             </td>
             <td>{datas.quote_shipment_laycan_to_date  } </td>
           </tr>

           <tr>
             <td id="td-right">
               <span>Laycan Month


    </span>
             </td>
             <td>{datas.quote_shipment_laycan_month}</td>
             <td id="td-right">
               <span>
    </span>
             </td>
             <td>  </td>
           </tr>
           </tbody>
           </table>

           <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">Quantity
    </h4>
         <hr>
         </hr>
       </div>
       <table class="table table-bordered account-table tables">
         <tbody>
           <tr>
           <td id="td-right">
               <span>Quantity
    </span>
             </td>
             <td>{datas.quote_shipment_quantity  } </td>
             <td id="td-right">
               <span>Vessel Type


    </span>
             </td>
             <td>{datas.quote_shipment_vessel_type  } </td>
           </tr>


           <tr>
           <td id="td-right">
               <span>Shipment Quantity Tolerance

    </span>
             </td>
             <td>{datas.quote_shipment_shipment_quality_tolerance  } </td>
             <td id="td-right">
               <span>


    </span>
             </td>
             <td></td>
           </tr>

           <tr>
           <td id="td-right">
               <span>Remark

    </span>
             </td>
             <td>{datas.quote_shipment_remark  } </td>
             <td id="td-right">
               <span>


    </span>
             </td>
             <td></td>
           </tr>


            </tbody>
           </table>
           <p className="edit-btn">
                          <button  onClick={handleEditClick}
                            className="btn btn-primary"

                          >
                            Edit
                          </button>
                          <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                            className="btn btn-primary"

                          >
                           Cancel
                          </button></Link>
                        </p>
                        <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>History</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <thead>
                              <tr>
                                <th>Field Name</th>
                                <th>New Value</th>
                                <th>Old Value</th>
                                <th>Changed By</th>
                                <th>Change Time</th>
                              </tr>
                            </thead>

                            {history.length > 0 ? (
                              <tbody>
                                {history.map((y, i) => (
                                  <tr>
                                    <td>{y.field_name}</td>
                                    <td>{y.new_value}</td>
                                    <td>{y.old_value}</td>
                                    <td>{y.changed_by}</td>
                                    <td>{y.change_time}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
           </div>
           )}
          </>):(<>
            {datas.quote_shipment_record_type==='Supplier - Long Term' ? (
            <>
            <div className="tasks">
            <p className="edit-btn">
                      <button
                        className="btn btn-primary"

                      >
                        Edit
                      </button>
                      <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                        className="btn btn-primary"

                      >
                       Cancel
                      </button></Link>
                    </p>
             <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Quote Shipment Detail
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
       <td id="td-right">
           <span>Quote Shipment Name
</span>
         </td>
         <td>{datas.quote_shipment_code}</td>
         <td id="td-right">
           <span>Status</span>
         </td>
         <td>{datas.quote_shipment_status  } </td>

        </tr>
        <tr>
        <td id="td-right">
           <span>Quote</span>
         </td>
         <td><Link to={`/opportunity/quote-view/${datas.offer_id}`}>{datas.quote_shipment_opportunity_shipment}</Link></td>
          <td id="td-right">
           <span>Record Type
</span>
         </td>
         <td>{datas.quote_shipment_record_type  } </td>

        </tr>
        <tr>
        <td id="td-right">
           <span>Currency</span>
         </td>
         <td>{datas.quote_shipment_currency}</td>
         <td id="td-right">
           <span></span>
         </td>
         <td> </td>


        </tr>
        <tr>
 <td id="td-right">
           <span>Exchange Rate
</span>
         </td>
         <td>{datas.quote_shipment_exchange_rate}</td>
         <td id="td-right">
           <span></span>
         </td>
         <td> </td>


        </tr>
        <tr>
        <td id="td-right">
           <span>Remark
</span>
         </td>
         <td>{datas.remark}</td>
         <td id="td-right">
           <span></span>
         </td>
         <td> </td>


        </tr>
        <tr>
        <td id="td-right">
           <span>Opp Shipment
</span>
         </td>
         <td><Link to={`/opportunity/quote-view/${datas.offer_id}`}>{datas.quote_shipment_opportunity_shipment}</Link></td>
         <td id="td-right">
           <span></span>
         </td>
         <td> </td>


        </tr>
                <tr>
        <td id="td-right">
           <span>shipping term
</span>
         </td>
         <td></td>


         <td id="td-right">
           <span>


</span>
         </td>
         <td></td>


        </tr>

        <tr>


         <td id="td-right">
           <span>Laycan Month From


</span>
         </td>
         <td>{datas.quote_shipment_laycan_month_from}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>


        </tr>
        <tr>
        <td id="td-right">
           <span>Laycan Month To


</span>
         </td>
         <td>{datas.quote_shipment_laycan_month_to}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>

        </tr>
        <tr>
        <td id="td-right">
           <span>Laycan Year From


</span>
         </td>
         <td>{datas.quote_shipment_laycan_year_from}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>

        </tr>
        <tr>
        <td id="td-right">
           <span>Laycan Year To


</span>
         </td>
         <td>{datas.quote_shipment_laycan_year_to}</td>
         <td id="td-right">
           <span>
</span>
         </td>

         <td id="td-right">
           <span>
</span>
         </td>
         <td>{}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>
        </tr>
        <tr>
        <td id="td-right">
           <span>Quantity Type
</span>
         </td>
         <td>{datas.quote_shipment_quantity_type}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>
        <td id="td-right">
           <span>


</span>
         </td>
         <td>{}</td>
         <td id="td-right">
           <span>
</span>
         </td>


        </tr>
        <tr>
        <td id="td-right">
           <span>Quantity from



</span>
         </td>
         <td>{datas.quote_shipment_quantity_from}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>
        </tr>
        <tr>
        <td id="td-right">
           <span>Quantity To



</span>
         </td>
         <td>{datas.quote_shipment_quantity_to}</td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td></td>

        </tr>
        <tr>
        <td id="td-right">
           <span>Quantity Info
            </span>
            </td>
        <td>{datas.quote_shipment_quantity_info}</td>
         <td id="td-right">
           <span>

</span>
         </td>


        </tr>
        <tr>
       <td id="td-right">
           <span>no of shipments
            </span>
            </td>
        <td>{datas.quote_shipment_no_of_shipments  } </td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td>{  } </td>

        </tr>
        </tbody>
        </table>

   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Price
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>

     <tr>
     <td id="td-right">
           <span>Price Type


</span>
         </td>
         <td>{datas.quote_shipment_price_type}</td>

         <td id="td-right">
           <span>Price Book


</span>
         </td>
         <td></td>

</tr>
<tr>
<td id="td-right">
           <span>Price Basis


</span>
         </td>
         <td>{datas.quote_shipment_price_basis}</td>
         <td id="td-right">
           <span>Price
</span>
         </td>
         <td>{datas.quote_shipment_price  } </td>

</tr>
<tr>
<td id="td-right">
           <span>
</span>
         </td>
         <td> </td>

         <td id="td-right">
           <span>Sales Price Margin


</span>
         </td>
         <td>{datas.quote_shipment_sales_price_margin  } </td>

</tr>
       </tbody>
       </table>

       <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Delivery
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Laycan Year


</span>
         </td>
         <td>{datas.quote_shipment_laycan_year}</td>
         <td id="td-right">
           <span>Laycan From Date

</span>
         </td>
         <td>{datas.quote_shipment_laycan_from_date  } </td>
       </tr>
       <tr>
       <td id="td-right">
           <span>Laycan Month


</span>
         </td>
         <td>{datas.quote_shipment_laycan_month_from}</td>
         <td id="td-right">
           <span>Laycan To Date

</span>
         </td>
         <td>{datas.quote_shipment_laycan_to_date  } </td>
       </tr>

       <tr>
       <td id="td-right">
               <span>Vessel Type


    </span>
             </td>
             <td>{datas.quote_shipment_vessel_type  } </td>
         <td id="td-right">
           <span>
</span>
         </td>
         <td>  </td>
       </tr>


       </tbody>
       </table>

       <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Quantity
</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Quanity



</span>
         </td>
         <td>{datas.quote_shipment_quantity}</td>
         <td id="td-right">
           <span>

</span>
         </td>
         <td> </td>
       </tr>
       <tr>
       <td id="td-right">
           <span>Shipment Quantity Tolerance

</span>
         </td>
         <td>{datas.quote_shipment_shipment_quality_tolerance  } </td>
         <td id="td-right">
           <span>

</span>
         </td>
         <td></td>
       </tr>

        </tbody>
       </table>
       <p className="edit-btn">
                      <button
                        className="btn btn-primary"

                      >
                        Edit
                      </button>
                      <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                        className="btn btn-primary"

                      >
                       Cancel
                      </button></Link>
                    </p>
                    <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>History</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <thead>
                              <tr>
                                <th>Field Name</th>
                                <th>New Value</th>
                                <th>Old Value</th>
                                <th>Changed By</th>
                                <th>Change Time</th>
                              </tr>
                            </thead>

                            {history.length > 0 ? (
                              <tbody>
                                {history.map((y, i) => (
                                  <tr>
                                    <td>{y.field_name}</td>
                                    <td>{y.new_value}</td>
                                    <td>{y.old_value}</td>
                                    <td>{y.changed_by}</td>
                                    <td>{y.change_time}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
       </div>
   </>):(<>
            {datas.quote_shipment_record_type==='Supplier - Spot' ? (
           <>
           <div className="tasks">
           <p className="edit-btn">
                     <button
                       className="btn btn-primary"

                     >
                       Edit
                     </button>
                     <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                       className="btn btn-primary"

                     >
                      Cancel
                     </button></Link>
                   </p>
            <div className="col-md-12" id='ax.account_details'>
    <h4 className="heading">Quote Shipment Detail
</h4>
    <hr>
    </hr>
  </div>
  <table class="table table-bordered account-table tables">
    <tbody>
      <tr>
      <td id="td-right">
          <span>Quote Shipment Name
</span>
        </td>
        <td>{datas.quote_shipment_code}</td>
        <td id="td-right">
          <span>Status</span>
        </td>
        <td>{datas.quote_shipment_status  } </td>

       </tr>
       <tr>
       <td id="td-right">
          <span>Quote</span>
        </td>
        <td><Link to={`/opportunity/quote-view/${datas.offer_id}`}>{datas.quote_shipment_opportunity_shipment}</Link></td>
         <td id="td-right">
          <span>Record Type
</span>
        </td>
        <td>{datas.quote_shipment_record_type  } </td>

       </tr>
       <tr>
       <td id="td-right">
          <span>Currency</span>
        </td>
        <td>{datas.quote_shipment_currency}</td>
        <td id="td-right">
          <span></span>
        </td>
        <td> </td>


       </tr>
       <tr>
<td id="td-right">
          <span>Exchange Rate
</span>
        </td>
        <td>{datas.quote_shipment_exchange_rate}</td>
        <td id="td-right">
          <span></span>
        </td>
        <td> </td>


       </tr>
       <tr>
       <td id="td-right">
          <span>Remark
</span>
        </td>
        <td>{datas.remark}</td>
        <td id="td-right">
          <span></span>
        </td>
        <td> </td>


       </tr>
       <tr>
       <td id="td-right">
          <span>Opp Shipment
</span>
        </td>
        <td><Link to={`/opportunity/quote-view/${datas.offer_id}`}>{datas.quote_shipment_opportunity_shipment}</Link></td>
        <td id="td-right">
          <span></span>
        </td>
        <td> </td>


       </tr>
               <tr>
       <td id="td-right">
          <span>shipping term
</span>
        </td>
        <td></td>


        <td id="td-right">
          <span>


</span>
        </td>
        <td></td>


       </tr>

       <tr>


        <td id="td-right">
          <span>Laycan Month From


</span>
        </td>
        <td>{datas.quote_shipment_laycan_month_from}</td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td></td>


       </tr>
       <tr>
       <td id="td-right">
          <span>Laycan Month To


</span>
        </td>
        <td>{datas.quote_shipment_laycan_month_to}</td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td></td>

       </tr>
       <tr>
       <td id="td-right">
          <span>Laycan Year From


</span>
        </td>
        <td>{datas.quote_shipment_laycan_year_from}</td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td></td>

       </tr>
       <tr>
       <td id="td-right">
          <span>Laycan Year To


</span>
        </td>
        <td>{datas.quote_shipment_laycan_year_to}</td>
        <td id="td-right">
          <span>
</span>
        </td>

        <td id="td-right">
          <span>
</span>
        </td>
        <td>{}</td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td></td>
       </tr>
       <tr>
       <td id="td-right">
          <span>Quantity Type
</span>
        </td>
        <td>{datas.quote_shipment_quantity_type}</td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td></td>
       <td id="td-right">
          <span>


</span>
        </td>
        <td>{}</td>
        <td id="td-right">
          <span>
</span>
        </td>


       </tr>
       <tr>
       <td id="td-right">
          <span>Quantity from



</span>
        </td>
        <td>{datas.quote_shipment_quantity_from}</td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td></td>
       </tr>
       <tr>
       <td id="td-right">
          <span>Quantity To



</span>
        </td>
        <td>{datas.quote_shipment_quantity_to}</td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td></td>

       </tr>

       <tr>
      <td id="td-right">
          <span>no of shipments
           </span>
           </td>
       <td>{datas.quote_shipment_no_of_shipments  } </td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td>{  } </td>

       </tr>
       </tbody>
       </table>

  <div className="col-md-12" id='ax.account_details'>
    <h4 className="heading">Price
</h4>
    <hr>
    </hr>
  </div>
  <table class="table table-bordered account-table tables">
    <tbody>

    <tr>
    <td id="td-right">
          <span>Price Type


</span>
        </td>
        <td>{datas.quote_shipment_price_type}</td>

        <td id="td-right">
          <span>Price Book


</span>
        </td>
        <td></td>

</tr>
<tr>
<td id="td-right">
          <span>Price Basis


</span>
        </td>
        <td>{datas.quote_shipment_price_basis}</td>
        <td id="td-right">
          <span>Price
</span>
        </td>
        <td>{datas.quote_shipment_price  } </td>

</tr>
<tr>
<td id="td-right">
          <span>
</span>
        </td>
        <td> </td>

        <td id="td-right">
          <span>Sales Price Margin


</span>
        </td>
        <td>{datas.quote_shipment_sales_price_margin  } </td>

</tr>
      </tbody>
      </table>

      <div className="col-md-12" id='ax.account_details'>
    <h4 className="heading">Delivery
</h4>
    <hr>
    </hr>
  </div>
  <table class="table table-bordered account-table tables">
    <tbody>
      <tr>
        <td id="td-right">
          <span>Laycan Year


</span>
        </td>
        <td>{datas.quote_shipment_laycan_year}</td>
        <td id="td-right">
          <span>Laycan From Date

</span>
        </td>
        <td>{datas.quote_shipment_laycan_from_date  } </td>
      </tr>
      <tr>
      <td id="td-right">
          <span>Laycan Month


</span>
        </td>
        <td>{datas.quote_shipment_laycan_month_from}</td>
        <td id="td-right">
          <span>Laycan To Date

</span>
        </td>
        <td>{datas.quote_shipment_laycan_to_date  } </td>
      </tr>

      <tr>
      <td id="td-right">
              <span>Vessel Type


   </span>
            </td>
            <td>{datas.quote_shipment_vessel_type  } </td>
        <td id="td-right">
          <span>
</span>
        </td>
        <td>  </td>
      </tr>


      </tbody>
      </table>

      <div className="col-md-12" id='ax.account_details'>
    <h4 className="heading">Quantity
</h4>
    <hr>
    </hr>
  </div>
  <table class="table table-bordered account-table tables">
    <tbody>
      <tr>
        <td id="td-right">
          <span>Quanity



</span>
        </td>
        <td>{datas.quote_shipment_quantity}</td>
        <td id="td-right">
          <span>

</span>
        </td>
        <td> </td>
      </tr>
      <tr>
      <td id="td-right">
          <span>Shipment Quantity Tolerance

</span>
        </td>
        <td>{datas.quote_shipment_shipment_quality_tolerance  } </td>
        <td id="td-right">
          <span>

</span>
        </td>
        <td></td>
      </tr>

       </tbody>
      </table>
      <p className="edit-btn">
                     <button
                       className="btn btn-primary"

                     >
                       Edit
                     </button>
                     <Link to={`/opportunity/oppshipment-detail/${datas.opp_ship_id}`}>    <button
                       className="btn btn-primary"

                     >
                      Cancel
                     </button></Link>
                   </p>
                   <div className="col-md-12" id="head">
                           <h4 className="heading">
                             <span>History</span>
                           </h4>
                           <hr></hr>
                         </div>
                         <table id="history" className="">
                           <thead>
                             <tr>
                               <th>Field Name</th>
                               <th>New Value</th>
                               <th>Old Value</th>
                               <th>Changed By</th>
                               <th>Change Time</th>
                             </tr>
                           </thead>

                           {history.length > 0 ? (
                             <tbody>
                               {history.map((y, i) => (
                                 <tr>
                                   <td>{y.field_name}</td>
                                   <td>{y.new_value}</td>
                                   <td>{y.old_value}</td>
                                   <td>{y.changed_by}</td>
                                   <td>{y.change_time}</td>
                                 </tr>
                               ))}
                             </tbody>
                           ) : (
                             <tbody>
                               <tr>
                                 <td></td>
                                 <td></td>
                                 <td>No data available</td>
                                 <td></td>
                                 <td></td>
                               </tr>
                             </tbody>
                           )}
                         </table>
      </div>
  </>):(<></>)}</>)}</>)
}
        </>

        )}
        </>
        ):(
          <div className="tasks">
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
        )}
        </div></div></div></div>
      <Footer />
    </div>
  );
};

export default Quoteshipmentdetail;
