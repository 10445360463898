// store.js
import { configureStore } from '@reduxjs/toolkit';
import FilterDataReducer from './FilterDataReducer'

const store  = configureStore({

  reducer: {

    filterData: FilterDataReducer
     
  }
  
});

export default store
