import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import Footer from '../Footer'
import $ from 'jquery';
import { API_URL } from '../config';
import axios from "axios";
import { useParams  } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EditUser() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  const params = useParams()
  const idUpdate=params.id
  $(".contactAccount").css({"min-height":(window_height)+"px"})
  const [update,setUpdate]=useState([]);
  const [account,setAccount]=useState({});
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/rwood_usersbyID/'+idUpdate, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      setAccount(data);
      setValue('rwood_username', data.rwood_username);
      setValue('rwood_username_no', data.rwood_username_no);
      setValue('rwood_user_type', data.rwood_user_type);
      setValue('active', data.active);
    } catch (error) {
      console.error(error);
    }
  };

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = data =>{
        const responseData={
            rwood_username:data.rwood_username,
            rwood_username_no:data.rwood_username_no,
            rwood_user_type:data.rwood_user_type,
            active:data.active,
            };
      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

        fetch(API_URL+'update/rwoodusers/'+idUpdate, {
                  method: "PUT",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())

                  .then((response) => {
                      toast.success("Company Updated Successfully", {
                      position: "bottom-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });

                  })

                }

        };
        const handleChange = (e) => {
          const { name, value } = e.target;
          setUpdate((prevData) => ({
            ...prevData,
            [name]: value
          }));
        };

  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Edit Company</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Edit Company </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Account Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood username"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Rwood username"
              name="rwood_username"
              defaultValue={account.rwood_username}
              onChange={handleChange}
              {...register("rwood_username", { required: true })}
            />
            {errors.rwood_username && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood username no"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Rwood username no"
              name="rwood_username_no"

              defaultValue={account.rwood_username_no}
              onChange={handleChange}
              {...register("rwood_username_no", { required: true })}
            />
            {errors.rwood_username_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Rwood user type">
    <Form.Select aria-label="Floating label select example"
    name="rwood_user_type"
    placeholder="Rwood user type"
    defaultValue={account.rwood_user_type}
              onChange={handleChange}
    {...register("rwood_user_type", { required:true })}
    >
      <option value='' >Select</option>
      <option value='Trader' >Trader</option>
      <option value='Operation' >Operation</option>
      <option value='Staff' >Staff</option>

    </Form.Select>
    {errors.rwood_user_type && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

       <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Active"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Active"
              name="active"
              defaultValue={account.active}
              onChange={handleChange}
              {...register("active", { required: true })}
            />
            {errors.active && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

                </Row>
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>

    <Footer/>
    </div>
  );
}

export default EditUser;
