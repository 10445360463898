import { createSlice } from '@reduxjs/toolkit';
import {produce} from 'immer'; // Ensure immer is imported for immutability

const initialState = {

  filteredData: {},
  responseData: null
};

const filterDataSlice = createSlice({
  name: 'filterData',
  initialState,
  reducers: {
    setJsonData: (state, action) => {
       
      state.filteredData = { ...state.filteredData, ...action.payload };
      
    },

    setFilteredData: (state, action) => {

      state.responseData = action.payload

    }

  },

});

export const { setJsonData, setFilteredData } = filterDataSlice.actions;
export default filterDataSlice.reducer;
