import React, { useState, useContext } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { TokenContext } from './AuthProvider';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(TokenContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <div className='login'>
      <div className='login-container'>
      <img src="/images/rwood1.jpg" alt="Logo" style={{ height: "50px", width: 'auto', }} className="logo" />
      <br/>
      <h4>Sign in to your account</h4>
        <Form onSubmit={handleSubmit}>
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FloatingLabel>
          <input type='submit' value='Sign In' className='btn btn-primary' />
        </Form>
        <p id='forgot'>Forgot your password? <Link to='/reset-password'>Reset</Link></p>
      </div>
    </div>
  );
};

export default Login;
