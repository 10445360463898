 import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { AES, enc } from "crypto-js";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import $ from 'jquery';
import Header from '../Header'
import Footer from '../Footer'
import { FaRegTrashAlt } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegPlusSquare } from "react-icons/fa";
import { API_URL } from '../config';
import { FaSave } from "react-icons/fa";

import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { parse, format } from 'date-fns';
import { get } from 'react-hook-form';
import { MdDeleteOutline } from "react-icons/md";
const TimeInputRow = ({ data, onFieldChange, onAddRow }) => {
  const {existing_date,  time_start, time_end, percent_to_count, days_addtion } = data;
  // console.log(existing_date)
   return (
    <tr>

      <td><input type="text" value={existing_date || ""} onChange={(e) => onFieldChange('existing_date', e.target.value)} /></td>

      <td><input type="text" pattern="[0-2][0-9]:[0-5][0-9]:[0-5][0-9]" value={time_start} onChange={(e) => onFieldChange('time_start', e.target.value)} /></td>
      <td><input type="text" pattern="[0-2][0-9]:[0-5][0-9]:[0-5][0-9]" value={time_end} onChange={(e) => onFieldChange('time_end', e.target.value)} /></td>
      <td><input type="number" placeholder="Percent Count" pattern="[\d]+" value={percent_to_count} onChange={(e) => onFieldChange('percent_to_count', parseInt(e.target.value))} /></td>
      <td><input type="text" placeholder="Days Addtion" value={days_addtion} onChange={(e) => onFieldChange('days_addtion', e.target.value)} /></td>
      <td><button onClick={onAddRow} className='add-row'><FaRegPlusSquare /></button></td>

    </tr>


  );
};


const Suplierlaytime = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [apiData, setApiData] = useState([]);
  const [formats, setDateformat] = useState('');

  const location = useLocation();
  const { id, orderId } = location.state;
  const laytime_id=id;
  const [laytime, setLaytime] = useState([])
  const [exist, setExist] = useState([])
  const [rows, setRows] = useState([]);
  const [timeAllowed, settimeAllowed]=useState('');
  const [timeUsed, settimeUsed]=useState('');
  const[exceed, setExceed]=useState('');
  const[despatch, setDespatch]=useState('');


  useEffect(() => {

    fetchData5();
  }, [decryptedAccessToken, formats]);

  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/supplier_laytime_byID/' + laytime_id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
      );

      console.log(response)
      const data = response.data.laytime_supplier[0];
      const data1 = response.data.existing_laytime_supplier;

      const rowDate = response.data.laytime_supplier[0].time_to_count_for;


      setLaytime(data)
      setExist(data1)


      const [datePart] = rowDate.split(' ');
      const [day, month, year] = datePart.split('/');
      const formattedDate = `${day}/${month}/${year}`;

      const dateOnly = formattedDate;
      setDateformat(dateOnly)
      console.log(dateOnly,'**********',rowDate);
      settimeAllowed(data1[0].time_allowed)
      setExceed(data1[0].time_exceed_or_saved)
      setDespatch(data1[0].despatch)
      settimeUsed(data1[0].time_used_days)


    } catch (error) {
      console.error(error);
    }
  };
 console.log(formats)

// date

const [startDate, setStartDate] = useState(null);



const [completeResult,setCompleteResult]=useState("");
const loadComplete = (date) => {
  const formattedDateTime = date
    ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
    : null;

    setCompleteResult(formattedDateTime);
};

const handleStartDateChange = (date) => {
  setStartDate(date);
  loadComplete(date)
};

const [etadate, setEtadate] = useState(null);

const [timeforResult,setTimeforResult]=useState("");
const loadtimeForDate = (date) => {
  const formattedDateTime = date
    ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
    : null;

    setTimeforResult(formattedDateTime);
};

const handleEtaChange = (date) => {
  setEtadate(date);
  loadtimeForDate(date)
};

// commence load date
const [etbdate, setEtbdate] = useState(null);
const [commenseResult,setCommenseResult]=useState("");
const loadDateCommense = (date) => {
  const formattedDateTime = date
    ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
    : null;

    setCommenseResult(formattedDateTime);
};


const handleEtbChange = (date) => {
  setEtbdate(date);
  loadDateCommense(date)

};



const [supplietoDate, setSupplietoDate] = useState(null);
const [sailingResult,setSailingResult]=useState("");

const logDateAndTime = (date) => {
  const formattedDateTime = date
    ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
    : null;

    setSailingResult(formattedDateTime);
};

const handleSupplierToDateChange = (date) => {
  setSupplietoDate(date);
  logDateAndTime(date);
};






const [confirmationDate, setConfirmationDate] = useState(null);

const [noracceptedResult,setNoracceptedResult]=useState("");

const logDateNorAccepted = (date) => {
  const formattedDateTime = date
    ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
    : null;

    setNoracceptedResult(formattedDateTime);
};


const handleConfirmeDateChange = (date) => {
  setConfirmationDate(date);
  logDateNorAccepted(date)
};


  const removeRowtable = (id) => {
    const responseUpdate = {
      "delete_existing_laytime": exist


    }
    setExist((prevRows) => prevRows.filter((x) => x.id !== id));

    fetch(`${API_URL}delete/existing_laytime_supplier/${laytime_id}/${id}`, {
      method: 'DELETE',
      body: JSON.stringify(responseUpdate),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json'
      },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData5()
        toast.success('Delete Successfully', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      })
      .catch((error) => console.error(error));


  };



  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);

  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setLaytime((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };



  const handleChangeExist = (index, field, value) => {
    const newRows = [...exist];
    newRows[index][field] = value;
    setExist(newRows);

  };


  const tableSave = (id, newData) => {
    setExist((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, row: newData, editing: false } : row
      )
    );

    const responseUpdate = {
      update_existing_supplier_laytime: exist,
    };

    fetch(`${API_URL}update/existing_supplier_laytime/${laytime_id}`, {
      method: 'PUT',
      body: JSON.stringify(responseUpdate),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log('Update response:', updatedData);
        // After successful update, fetch data again
        fetchData5();
        toast.success('Updated Exist Laytime', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => console.error('Error during update:', error));
  };

  // time to count
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [formattedDate, setFormattedDate] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [selectedDate1, setSelectedDate1] = useState(new Date());
  // const [formattedDate, setFormattedDate] = useState('');

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [formattedDateApend, setFormattedDateApend] = useState('');
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);const formatted = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',

    }).replace(/\//g, '/');

    setFormattedDateApend(formatted);


  };
  const [selectedDate3, setSelectedDate3] = useState(new Date());
  // const [formattedDate, setFormattedDate] = useState('');

  const handleDateChange3 = (date) => {
    setSelectedDate3(date);

  };
  console.log(selectedDate3)
  const [assign, setAssign]=useState(new Date())

  const handleAssign = (date) => {
    setAssign(date);
  };

  // const [formattedDate, setFormattedDate] = useState('');

  const [startread, setStartread] = useState(new Date());
  // const readline=startread.toLocaleDateString("en-US", { year: 'numeric' })+"/"+(startread.toLocaleDateString("en-US", { month: '2-digit' }))+"/"+(startread.toLocaleDateString("en-US", { day: '2-digit' }))
  const readline=startread.toLocaleDateString("en-US", { day: '2-digit' })+"/"+(startread.toLocaleDateString("en-US", { month: '2-digit' }))+"/"+(startread.toLocaleDateString("en-US", { year: 'numeric' }))

 const CustomRead = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     value={value}
     className="custom-input"
     onClick={onClick}
     onChange={onChange}
     ref={ref}
   ></input>
 ));
 const [sai,setsai]=useState('')
 useEffect(()=>{
  const dateObject = new Date(startread);
  if (dateObject) {
    const formattedDate = format(dateObject, 'dd/MM/yyyy HH:mm:ss', { timeZone: 'UTC' });
    setsai(formattedDate);
  }
})



  const handleSaveEdit = (e) => {
    e.preventDefault();


    const responseUpdate = {
      "vessel_name": laytime.vessel_name,
      "orderID": laytime.orderID,
      "loading_at": laytime.loading_at,
      "buyer_name": laytime.buyer_name,
      "cargo": laytime.cargo,
      "seller": laytime.seller,
      "bl_quantity": laytime.bl_quantity,
      "shipper": laytime.shipper,
      "contract_number": laytime.contract_number,
      "days_allowed": laytime.days_allowed,
      "lp_agent": laytime.lp_agent,
      "sailing_date": sailingResult,
      "loading_rate": laytime.loading_rate,
      // "NOR_tendered": nor,
      "notice_of_readiness": readline,
      "NOR_accepted": noracceptedResult,
      "commenced_load": commenseResult,
      "laycan": laytime.laycan,
      "time_to_count_for": timeforResult,
      "demurrage": laytime.demurrage,
      "completed_load": completeResult,
      "despatch": laytime.despatch


    }


    console.log(JSON.stringify(responseUpdate));

    //  "/update/existing_laytime/<laytime_id>"          update_existing_laytime
    fetch(`${API_URL}update/supplier_laytime/${laytime_id}`, {
      method: 'PUT',
      body: JSON.stringify(responseUpdate),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json'
      },
    })

      .then((response) => {
        console.log(response)
        if(response.status===200){
        // Only call fetchData5 if the PUT request is successful
        fetchData5();

        toast.success('Laytime Updated Successfully', {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setIsEditMode(false);
        setEditedData(response);
      }
      })
      .catch((error) => {
        console.error(error);
        // Handle error, e.g., show an error toast or log the error
      });
  };



  // add row
  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

  const currentDate = new Date();
  const formattedDate = formatDate(currentDate.toISOString().split('T')[0]);
  useEffect(() => {
    if (formats || rows.length>0) {
      setRows([
        {
          existing_date: formats,
          time_start: '00:00:00',
          time_end: '24:00:00',
          percent_to_count: '',
          days_addtion: ''
        },
      ]);
    }
    else{
      setRows([
        {
          existing_date: formattedDate,
          time_start: '00:00:00',
          time_end: '24:00:00',
          percent_to_count: '',
          days_addition: ''
        },
      ]);
    }
  }, [formats]);


  const handleFieldChange = (index, field, value) => {

    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);

  };



  const handleAddRow = () => {
    fetchData5();
    const lastIndex = rows.length - 1;
    const lastRow = rows[lastIndex];
    const nextDate = addOneDay(lastRow.existing_date);
    console.log(lastRow.time_end);
    const nextStartTime = lastRow.time_start === '24:00:00' ? '00:00:00' : lastRow.time_end;
    const nextEndTime = '00:00:00';

    setRows((prevRows) => [
      ...prevRows,
      { existing_date: nextDate, time_start: nextStartTime, time_end: nextEndTime, percent_to_count: '', days_addition: '' },
    ]);
  };

 // Function to add one day to a date string
 const addOneDay = (dateString) => {
  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day); // month - 1 because months are 0-indexed in JavaScript Date
  date.setDate(date.getDate() + 1);

  const newDay = date.getDate();
  const newMonth = date.getMonth() + 1; // Adding 1 because months are 0-indexed
  const newYear = date.getFullYear();

  // Pad day and month with leading zeros if needed
  const formattedDay = String(newDay).padStart(2, '0');
  const formattedMonth = String(newMonth).padStart(2, '0');

  return `${formattedDay}/${formattedMonth}/${newYear}`;
};


  const handleRemoveRow = (index) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
    } else {
      // If there's only one row, clear its values instead of removing it
      setRows([{ existing_date: '', time_start: '', time_end: '', percent_to_count: '', days_addtion: '' }]);
    }
  };



  const handleSubmit = () => {
    const responseUpdate = {
      "existing_supplier_laytime": rows


    }

console.log(JSON.stringify(responseUpdate));
    fetch(`${API_URL}add/existing_supplier_laytime/${laytime_id}`, {
      method: 'POST',
      body: JSON.stringify(responseUpdate),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json'
      },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        //swindow.location.reload();
        fetchData5()
        console.log(updatedData);
        toast.success('Add Existing Laytime Successfully', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      })
      .catch((error) => console.error(error));
  }

// table row edit and add
const [editingRowId, setEditingRowId] = useState(null);
 const startEditing = (rowId) => {
    setEditingRowId(rowId);
  };

  const cancelEditing = () => {
    setEditingRowId(null);
  };

  const saveEditing = (rowId, newData) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId ? { ...row, ...newData } : row
      )
    );
    setEditingRowId(null);
  };

  return (
    <div>
      <div className="priceAccount" id='prices'>
        <Header />
        <div>
          <div className="page-header" id=''>
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">LayTime Details</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Dashboard / Supplier LayTime Details</li>
              </ol>
            </div>
            {/* <button onClick={handlesubmit}>ddsdsd</button> */}

          </div>
        </div>
        <div className='tasks'>

          {isEditMode ? (
            <>
               <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p>
              <Row className="mb-3">
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Order Id "
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Order Id "
                      name="orderID"
                      defaultValue={laytime.orderID}
                      disabled
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Vessel Name "
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Vessel Name"
                      name="vessel_name"
                      disabled
                      defaultValue={laytime.vessel_name}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Loading At"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Loading At"
                      name="loading_at"
                      defaultValue={laytime.loading_at}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer Name"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Buyer Name"
                      name="buyer_name"
                      disabled
                      defaultValue={laytime.buyer_name}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Cargo"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Cargo"
                      disabled
                      name="cargo"
                      defaultValue={laytime.cargo}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Seller"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Seller"
                      disabled
                      name="seller"
                      defaultValue={laytime.seller}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="B/L Quantity"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="B/L Quantity"
                      name="bl_quantity"
                      disabled
                      defaultValue={laytime.bl_quantity}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Shipper"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Shipper"
                      name="shipper"
                      disabled
                      defaultValue={laytime.shipper}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Contract Number"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Contract Number"
                      name="contract_number"
                      disabled
                      defaultValue={laytime.contract_number}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Day(s) Allowed:"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Day(s) Allowed:"
                      name="days_allowed"
                      disabled
                      defaultValue={laytime.days_allowed}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="LP Agent"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="LP Agent "
                      name="lp_agent"
                      disabled
                      defaultValue={laytime.lp_agent}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className='d-p'>
        <FloatingLabel
          controlId="floatingInput"
          label="Sailing Date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={supplietoDate}
        onChange={handleSupplierToDateChange}

        dateFormat='dd/MM/yyyy h:mm aa'
        showTimeSelect
                      timeFormat="h:mm aa"
                      timeCaption="Time"

         placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Loading Rate"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Loading Rate"
                      name="loading_rate"
                      defaultValue={laytime.loading_rate}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className='d-p'>
        <FloatingLabel
          controlId="floatingInput"
          label="NOR Tendered"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={confirmationDate}
        onChange={handleConfirmeDateChange}

        dateFormat='dd/MM/yyyy h:mm aa'
        showTimeSelect
        timeFormat="h:mm aa"
                      timeCaption="Time"

         placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
                <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Notice Of Readiness"
     className="mb-3 datepick"


     >
     <DatePicker
     selected={startread}
     onChange={(date) => setStartread(date)}
     customInput={<CustomRead />}
     dayClassName={() => "example-datepicker-day-class"}
     popperClassName="example-datepicker-class"
     todayButton="TODAY"
     dateFormat='dd/MM/yyyy'
     />
     </FloatingLabel>
     </Form.Group>

                <Form.Group as={Col} md="6" className='d-p'>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="NOR Accepted"
                    className="mb-3 datepick"


                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="h:mm aa"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy h:mm aa"
                      timeCaption="Time"
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Turn Time"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Turn Time"
                      name="turn_time"
                      defaultValue={laytime.turn_time}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className='d-p'>
        <FloatingLabel
          controlId="floatingInput"
          label="Commenced Load"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={etbdate}
        onChange={handleEtbChange}

        dateFormat='dd/MM/yyyy h:mm aa'
        showTimeSelect
        timeFormat="h:mm aa"
                      timeCaption="Time"

        placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>

       <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Laycan"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Laycan"
                      name="laycan"
                      disabled
                      defaultValue={laytime.laycan}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className='d-p'>
        <FloatingLabel
          controlId="floatingInput"
          label="Time to Count For"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={etadate}
        onChange={handleEtaChange}

        dateFormat='dd/MM/yyyy h:mm aa'
        showTimeSelect
        timeFormat="h:mm aa"
                      timeCaption="Time"

         placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Demurrage"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Demurrage"
                      name="demurrage"
                      defaultValue={laytime.demurrage}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6" className='d-p'>
        <FloatingLabel
          controlId="floatingInput"
          label="Completed Load"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}

        dateFormat='dd/MM/yyyy h:mm aa'
        showTimeSelect
        timeFormat="h:mm aa"
                      timeCaption="Time"

      placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Despatch"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                      placeholder="Despatch"
                      name="despatch"
                      disabled
                      defaultValue={laytime.despatch}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>



</Row>

<p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p></>
          ) : (
            <>
              <div className="edit-delete">
                <span id="edit" onClick={handleEditClick}>
                  Edit
                </span>
                <span>
                  <Link to={`/order/view-order-details/${laytime.order_id}`}>Cancel</Link>
                </span>

              </div>
              <h4 className='heading m-t'>Order Information</h4>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right"><span>Order Id</span> </td>
                    <td><Link to={`/order/view-order-details/${laytime.order_id}`}>{laytime.orderID}</Link></td>

                    <td id="td-right"><span></span></td>
                    <td>
                    </td>

                  </tr>
                  <tr>
                  <td id="td-right"><span>Vessel Name</span></td>
                    <td>{laytime.vessel_name}
                    </td>
                    <td id="td-right"><span>Loading At</span> </td>
                    <td>{laytime.loading_at}</td>


                  </tr>
                  <tr>
                  <td id="td-right"><span>Buyer Name</span></td>
                    <td>{laytime.buyer_name}
                    </td>
                    <td id="td-right"><span>Cargo</span> </td>
                    <td>{laytime.cargo}</td>


                  </tr>
                  <tr>
                  <td id="td-right"><span>Seller</span></td>
                    <td>{laytime.seller}
                    </td>
                    <td id="td-right"><span>B/L Quantity</span> </td>
                    <td>{laytime.bl_quantity}</td>


                  </tr>
                  <tr>
                  <td id="td-right"><span>Shipper</span></td>
                    <td>{laytime.shipper}
                    </td>
                    <td id="td-right"><span>Contract Number</span> </td>
                    <td><Link to={`/contract/contract-details/${laytime.order_id}`}>{laytime.contract_number}</Link></td>

                  </tr>
                  <tr>
                  <td id="td-right"><span>Day(s) Allowed: </span></td>
                    <td>{laytime.days_allowed}
                    </td>

                    <td id="td-right"><span>LP Agent</span> </td>
                    <td>{laytime.lp_agent}</td>


                  </tr>
                  <tr>
                  <td id="td-right"><span>Sailing Date</span></td>
                    <td>{laytime.sailing_date}
                    </td>
                    <td id="td-right"><span>Loading Rate</span> </td>
                    <td>{laytime.loading_rate}</td>


                  </tr>
                  <tr>
                  <td id="td-right"><span>NOR Tendered</span></td>
                    <td>{laytime.NOR_tendered}
                    </td>
                    <td id="td-right"><span>Notice Of Readiness : </span> </td>
                    <td>{laytime.notice_of_readiness}</td>

                  </tr>
                  <tr>
                  <td id="td-right"><span>NOR Accepted</span></td>
                    <td>{laytime.NOR_accepted}
                    </td>

                    <td id="td-right"><span>Turn Time</span> </td>
                    <td>{laytime.turn_time}</td>

                  </tr>
                  <tr>
                  <td id="td-right"><span>Commenced Load</span></td>
                    <td>{laytime.commenced_load}
                    </td>

                    <td id="td-right"><span>Laycan</span> </td>
                    <td>{laytime.laycan}</td>

                  </tr>
                  <tr>
                  <td id="td-right"><span>Time to count for</span></td>
                    <td>{laytime.time_to_count_for}
                    </td>

                    <td id="td-right"><span>Demurrage</span> </td>
                    <td>{laytime.demurrage}</td>


                  </tr>

                  <tr>
                  <td id="td-right"><span>Completed Load</span></td>
                    <td>{laytime.completed_load}
                    </td>
                    <td id="td-right"><span>Despatch</span> </td>
                    <td>{laytime.despatch}</td>


                  </tr>
                </tbody>
              </table>
            </>
          )}






          <h4 className='heading m-t'> Exist Laytime</h4>
          <table className='table add-laytime'>
            <thead>
              <tr>
                <th>Existing Date</th>
                <th>Time Start</th>
                <th>Time End</th>
                <th>Percent To Count</th>
                <th>Stoppage Remarks</th>
                <th>Time Used</th>
                <th>Total Day(s)</th>
                <th>Days Left(Days Exceed)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {exist.map((x, i) => (
                <tr key={x.id}>
                  <td>


                    <input type='text' defaultValue={x.existing_date} name='existing_date'
                    onChange={(e) =>
                      setExist((prevRows) =>
                        prevRows.map((r) =>
                          r.id === x.id ? { ...r, existing_date: e.target.value } : r
                        )
                      )
                    }
                    />
                  </td>
                  <td >
                    <input type='text' defaultValue={x.time_start} name='time_start'
                     onChange={(e) =>
                      setExist((prevRows) =>
                        prevRows.map((r) =>
                          r.id === x.id ? { ...r, time_start: e.target.value } : r
                        )
                      )
                    }
                     />
                  </td>
                  <td >
                    <input type='text' defaultValue={x.time_end} name='time_end'
                     onChange={(e) =>
                      setExist((prevRows) =>
                        prevRows.map((r) =>
                          r.id === x.id ? { ...r, time_end: e.target.value } : r
                        )
                      )
                    }/>
                  </td>
                  <td >
                    <input type='number' pattern="[\d]+" defaultValue={(x.percent_to_count)} name='percent_to_count'
                     onChange={(e) =>
                      setExist((prevRows) =>
                        prevRows.map((r) =>
                          r.id === x.id ? { ...r, percent_to_count: parseInt(e.target.value) } : r
                        )
                      )
                    }
                    />
                  </td>
                  <td >
                    <input type='text' defaultValue={x.stoppage_remarks} name='stoppage_remarks'
                     onChange={(e) =>
                      setExist((prevRows) =>
                        prevRows.map((r) =>
                          r.id === x.id ? { ...r, stoppage_remarks: e.target.value } : r
                        )
                      )
                    }
                     />
                  </td>
                  <td >
                    {x.time_used}
                  </td>
                  <td >
                    {x.total_days}
                  </td>
                  <td>{x.days_left}</td>
                  <td className='laytime-action'> <FaSave  onClick={tableSave}/> <FaRegTrashAlt onClick={() => removeRowtable(x.id)}/></td>
                </tr>
              ))}
            </tbody>
          </table>
         <div className='time-calulation'>
         {/* {exist.map((x, i) => (
          <p>{}</p>
         ))} */}

          {/* <p><span>Time Allowed</span>  {exist[0].time_allowed} </p> */}
          <p><span>Time Allowed</span> {timeAllowed}</p>
          <p><span>Time Used</span> {timeUsed}</p>
          <p><span>Time Saved / (exceed): {exceed}</span> <span>Despatch: {despatch}</span></p>
         </div>

          <h4 className='heading m-t'> Add New Laytime</h4>
          <table className='table add-laytime'>
            <thead>
            <tr>
              <th>Date</th>
              <th>Time Start</th>
              <th>Time End</th>
              <th>Percent to count</th>
              <th>Stoppages Remarks
</th>
<th>Actions</th>

            </tr>
            </thead>
         <tbody>
          {rows.length > 0 ?(
            <>
          {rows.map((data, index) => (
            // <div key={index} className='d-f'>
            <>
               <TimeInputRow
          key={index}
          data={data}
          onFieldChange={(field, value) => handleFieldChange(index, field, value)}
          onAddRow={handleAddRow}
        />
              <button className='remove-row' onClick={() => handleRemoveRow(index)}>Delete</button>
              </>
            // </div>
          ))}
          </>
          ):(<></>)}


          </tbody>
          </table>
          <button className='btn btn-primary btn-laytime' onClick={handleSubmit}>Save</button>
        </div>

      </div>
      <p></p><p></p>
      <Footer />
    </div>
  );
};

export default Suplierlaytime;