
//get/opp_ship_createview_byID/
//get/opportunity_ship_keys
//update/opportunity_ship_records     /opportunity-shipment/create-view-list

import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from '../Footer'
import axios from "axios";
import { API_URL } from "../config";
import { BsArrowDownSquare, BsArrowUpSquare, BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import 'react-toastify/dist/ReactToastify.css';



const Oppshipmenteditview = () => {


  const { id } = useParams();

  console.log(id, 'id')

  const [datas, setDatas] = useState({})
  const [inputDatas, setInputDatas] = useState([])

  const [isChecked, setIsChecked] = useState(Boolean(inputDatas.all_opp_ship));


  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8) : null;


  useEffect(() => {

    setIsChecked(Boolean(inputDatas.all_opp_ship));

  }, []);


  const [oppShipName, setoppShipName] = useState([])
  const [operators, setOperator] = useState([])
  const [checkboxes, setCheckboxes] = useState([]);


  const getOppShipCreateView = async () => {


    const URL = `${API_URL}get/opp_ship_createview_byID/${id}`

    console.log(decryptedAccessToken,'decryptedAccessToken')
    const oppViewResponse = await axios.get(URL, {

      headers: {

        Authorization: `Bearer ${decryptedAccessToken}`,
        "Content-Type": "application/json",

      },

    })

    if (oppViewResponse.status === 200)
    {

      const data = oppViewResponse?.data?.opp_ship_view

      setInputDatas(data)
      setoppShipName(data.fields_to_display)
      setSelectedFields(data.fields_to_display)
      setOperator(data.filter_fields)


      setDatas(data)

      setFormData({

        view_name: data.view_name || '',
        unique_name: data.unique_name || data.view_name || '',
        my_opp_ship: data.my_opp_ship ?? false,
        all_opp_ship: data.all_opp_ship ?? false,
        id: id

      });

    }

  }


  useEffect(() => {

    getOppShipCreateView();

  }, [decryptedAccessToken]);


  const [filterConditions, setFilterConditions] = useState([]);

  useEffect(() => {

    if (operators.length === 0) {

      setFilterConditions([

        { input: "", operator: "", values: [] },

      ]);

    } else {

      const initialFilterConditions = inputDatas.filter_fields.map((x) => ({

        input: x.input,
        operator: x.operator,
        values: x.values.map((y) => y),

      }));

      setFilterConditions(initialFilterConditions);

    }

  }, [operators, inputDatas]);


  const navigate = useNavigate();



  const [OppKeys, setOppKeys] = useState([]);

  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState(['opportunity_no']);




  const [formData, setFormData] = useState({

    view_name: '',
    unique_name: '',
    all_opp_ship: inputDatas.all_opp_ship,
    my_opp_ship: inputDatas.my_opp_ship,

  });



  // console.log(datas)
  const [selectedOptions, setSelectedOptions] = useState([]);


  // Move Selected Fields Up //
  const moveSelectedFieldsUp = () => {

    const updatedFields = [...selectedFields];
    const firstSelectedIndex = selectedFields.indexOf(selectedOptions[0]);


    if (firstSelectedIndex > 0) {

      const movedFields = updatedFields.splice(firstSelectedIndex, selectedOptions.length);
      updatedFields.splice(firstSelectedIndex - 1, 0, ...movedFields);
      setSelectedFields(updatedFields);

    }

  };

  // move selected fields position down //
  const moveSelectedFieldsDown = () => {

    const updatedFields = [...selectedFields];
    const lastSelectedIndex = selectedFields.indexOf(selectedOptions[selectedOptions.length - 1]);

    if (lastSelectedIndex < selectedFields.length - 1) {

      const movedFields = updatedFields.splice(lastSelectedIndex, selectedOptions.length);
      updatedFields.splice(lastSelectedIndex + 1, 0, ...movedFields);
      setSelectedFields(updatedFields);

    }

  };


  const handleFieldSelection = (e) => {

    const options = Array.from(e.target.options)

    .filter((option) => option.selected)
    .map((option) => option.value);

    setSelectedOptions(options);

  };

  const handleAddFields = () => {

    const newFields = selectedOptions.filter(

      (option) => !selectedFields.includes(option)

    );

    setAvailableFields((prevFields) =>

      prevFields.filter((field) => !newFields.includes(field))

    );

    setSelectedFields((prevFields) => [...prevFields, ...newFields]);
    setSelectedOptions([]);

  };




  const handleRemoveFields = () => {

    setAvailableFields((prevFields) => {

      const updatedFields = [...prevFields, ...selectedOptions].filter(

        (field, index, self) => index === self.findIndex((f) => f === field)

      );

      return updatedFields;

    });

    setSelectedFields((prevFields) =>

      prevFields.filter((field) => !selectedOptions.includes(field))

    );

    setSelectedOptions([]);

  };

  const handleFieldToggle = (field) => {

    if (selectedOptions.includes(field)) {

      setSelectedOptions((prevOptions) =>

        prevOptions.filter((option) => option !== field)

      );

    } else {

      setSelectedOptions((prevOptions) => [...prevOptions, field]);

    }

  };

  const handleInputChanges = (event) => {

    const { name, checked, value, type } = event.target;

    setFormData((prevState) => {

      const newState = {

        ...prevState,
        [name]: type === 'checkbox' ? checked : value

      };

      // Handle mutually exclusive checkboxes
      if (name === 'all_opp' && checked) {

        newState.my_opp = false;

      } else if (name === 'my_opp' && checked) {

        newState.all_opp = false;

      }

      return newState;

    });

  };





  const fetchOppKeys = async () => {


    const URL = `${API_URL}get/opportunity_ship_keys`

    const OppKeysResponse = await axios.get(URL, {

      headers: {

        "Content-Type": "application/json",

      }

    })

    if (OppKeysResponse.status === 200)
    {
      setOppKeys(OppKeysResponse?.data?.fields)
      setAvailableFields(OppKeysResponse?.data?.fields);
    }

  }

  useEffect(() => {

    fetchOppKeys()

  }, [])

  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const updatedFilterConditions = [...filterConditions];

    const updatedCondition = {

      ...updatedFilterConditions[index],
      [name]: name === "values" ? value.split(",") : value,

    };

    updatedFilterConditions[index] = updatedCondition;

    setFilterConditions(updatedFilterConditions);

    setFormData({

      ...formData,
      [e.target.name]: e.target.checked,

    });

  };


  const addFilterCondition = () => {

    setFilterConditions([

      ...filterConditions,
      { input: "", operator: "", values: [] },

    ]);

  };

  const removeFilterCondition = (index) => {

    const updatedFilterConditions = [...filterConditions];
    updatedFilterConditions.splice(index, 1);
    setFilterConditions(updatedFilterConditions);

  };

  // On Saving View //

  const handleSubmit = async (e) => {

    e.preventDefault();

    const isFilterConditionsEmpty =
      filterConditions.length === 1 &&
      filterConditions[0].input === "" &&
      filterConditions[0].operator === "" &&
      filterConditions[0].values.length === 0;

    const dataToSend = {

      ...formData,
      filterConditions: isFilterConditionsEmpty ? [] : filterConditions,
      selectedFields: isFilterConditionsEmpty ? [] : selectedFields,

    };

    const cleanedJsonString = JSON.stringify(dataToSend)
      .replace(/\\/g, '')
      .replace(/"\[/g, '[')
      .replace(/\]"/g, ']');

    const datatosending = JSON.parse(cleanedJsonString);

    console.log(JSON.stringify(datatosending))

    try {


      const URL = `${API_URL}update/opportunity_ship_records/${id}`

      const oppViewResponse = await axios.put(URL, datatosending, {


        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json"

        }

      })

      if (oppViewResponse.status === 200) {

        console.log("Record updated successfully:", oppViewResponse);
        sessionStorage.setItem("OppShipViewEdit", true)
        navigate(`/opportunity-shipment/create-view-list`)

      }

      if (oppViewResponse.status === 401) {

        throw new Error("Unauthorized: Please check your access token.");

      }

    } catch (error) {

      console.error("Failed to update record:", error);

    }

  }



  return (

    <div className="addAccount">
      <Header />
      <div className="page-header" id='add-account'>
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Edit View</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">Dashboard / Opportunity Shipment/ Edit View </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">



          </div>
        </div>
      </div>
      <div id='view-container'>
      <p className='edit-cancel'><input type="submit" className="account-save" value="Save" onClick={handleSubmit} /><Link to={`/opportunity-shipment/create-view-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
          <h4 className='heading'>Step 1: Enter View Name</h4>

          <Form.Group as={Col} md="12">
            <FloatingLabel
              controlId="floatingInput"
              label="View Name"
              className="mb-3"


            >
              <Form.Control
                type="text"
                name="view_name"
                placeholder="View Name"
                value={formData.view_name || ""}
                onChange={handleInputChanges}
              />

            </FloatingLabel>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <h4 className='heading'>Step 2: Specify Filter Criteria</h4>
          <p>Filter By Owner:</p>

          <Form.Group as={Col} md="12">
            <input
              className="form-check-input"
              type="checkbox"
              checked={formData.all_opp_ship}
              name="all_opp_ship"
              id="flexCheckChecked"
              onChange={handleInputChanges}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              All Opportunity Shipment
            </label>
          </Form.Group>

          <Form.Group as={Col} md="12">
            <input
              className="form-check-input"
              type="checkbox"
              checked={formData.my_opp_ship}
              name="my_opp_ship"
              id="flexCheckDefault"
              onChange={handleInputChanges}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              My Opportunity Shipment
            </label>
          </Form.Group>   <p id='filter-by'>Filter By Additional Fields (Optional):</p>
          <Form.Group as={Col} md="12" className="account-field" id='view-filters'>
            {filterConditions?.map((condition, index) => (
              <div key={index}>

                <div >
                  <select
                    name='input'
                    value={condition.input}
                    onChange={(e) => handleInputChange(e, index)}
                  >
                    <option>Select Key</option>
                    {OppKeys?.map((key, keyIndex) => (
                      <option key={keyIndex} value={key}>
                        {key.replace(/_/g, " ")}
                      </option>
                    ))}
                  </select>

                  <select
                    name="operator"
                    value={condition.operator}
                    onChange={(e) => handleInputChange(e, index)}
                  >
                    <option value="Operator">Operator</option>
                    <option value="equals">equals</option>
                      <option value="not_equals">not_equals</option>
                      <option value="greater">greater</option>
                      <option value="greater_equal">greater_equal</option>
                      <option value="less">less</option>
                      <option value="less_equal">less_equal</option>
                    {/* ... other options ... */}
                  </select>

                  <input
                    type="text"
                    name="values"
                    placeholder="Values"
                    value={condition.values.join(",")}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <FaTrashAlt id='trash' onClick={() => removeFilterCondition(index)} />
                </div>


              </div>
            ))}


            <button onClick={addFilterCondition}>Add Filter</button>


          </Form.Group>
        </Row>
        <Row className="mb-3 available">
          <h4 className='heading'>Step 3: Select Fields to Display</h4>
          <Form.Group as={Col} md="6">
            <p>Available Fields</p>
            <select
              multiple
              value={selectedOptions}
              className='form-control selectedField'
              onChange={handleFieldSelection}
            >
              {availableFields.map((field) => (
                <option key={field.id} value={field}>
                  {field.replace(/_/g, " ")}
                </option>
              ))}
            </select>
          </Form.Group>

          <Form.Group as={Col} md="6" >
            <Form.Group as={Col} id='createview-add-remove' >
              <div>
                <p>
                  <span>Add</span> <br />
                  <BsArrowRightSquare onClick={handleAddFields}
                    disabled={!selectedOptions.length} />
                </p>
                <p>

                  <BsArrowLeftSquare onClick={handleRemoveFields}
                    disabled={!selectedFields.length} />
                  <br />
                  <span>Remove</span>
                </p>




              </div>
            </Form.Group>


            <div id='right-select-section'>
              <p>Selected Fields</p>
              <div className="selectedFields" >
                {selectedFields.map((field, index) => (
                  <div
                    key={field}
                    style={{
                      justifyContent: "left",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "0px",
                      background: selectedOptions.includes(field)
                        ? "#e3e3e3"
                        : "#fff",
                    }}
                    onClick={() => handleFieldToggle(field)}
                  >
                    {field.replace(/_/g, " ")}
                  </div>
                ))}
              </div>
            </div>


            <Form.Group as={Col} id='move-up-down' >
              <p>
                <BsArrowUpSquare onClick={moveSelectedFieldsUp}
                  disabled={!selectedFields.length} />

                <BsArrowDownSquare onClick={moveSelectedFieldsDown}
                  disabled={!selectedFields.length} />
              </p>
            </Form.Group>
          </Form.Group>



        </Row>
        <p className='edit-cancel'><input type="submit" className="account-save" value="Save" onClick={handleSubmit} /><Link to={'/opportunity-shipment/create-view-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>


        <div className='successDiv'><p></p><p></p></div>
      </div>
      <Footer />
    </div>
  );
}

export default Oppshipmenteditview;

