import React, { useState, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Header from "../Header";
import Footer from "../Footer";
import { Col } from "react-bootstrap";
import Popup from "reactjs-popup";
import { useNavigate, useParams, Link } from "react-router-dom";
import { AES, enc } from "crypto-js";
import axios from "axios";
import { API_URL } from '../config';
import { IoSearch } from "react-icons/io5";

const SelectQuoteproduct = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [productMaster, setProductMaster] = useState([]);
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);  // Add loading state
  const [error, setError] = useState(null);  // Handle error state

  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);

          // Start loading
          setLoading(true);

          const response = await axios.get(
            `${API_URL}get/products_api`,
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );
          console.log(response);

          setProductMaster(response.data);
          setLoading(false); // Data loaded, stop loading
        }
      } catch (error) {
        console.log(error);
        setError(error.message);  // Set error
        setLoading(false);  // Stop loading in case of error
      }
    };
    fetchProductCodes();
  }, []);

  const handleButtonClick = () => {
    navigate("/quotes/quote-product", {
      state: { ids: id, productCodes: selectedProductCode },
    });
  };

  return (
    <>
      <div id="view-page">
        <Header />
        <div className="contactAccount">
          {/* Your existing JSX for page header */}
        </div>
        <div className="tasks edit-view">
          <p className="edit-cancel">
            <input
              type="submit"
              className="account-save"
              value="Save"
              onClick={handleButtonClick}
            />
            <Link to={`/opportunity/quote-view/${id}`}>
              <input
                type="submit"
                className="account-save"
                value="Cancel"
              />
            </Link>
          </p>
          <Form.Group as={Col} md="6" style={{ margin: '0 auto' }}>
            <div className="d-flex align-items-center">
              <FloatingLabel
                controlId="floatingInput"
                label="Select Product Master"
                className="mb-3"
                style={{ width: '100%' }}
              >
                <Form.Control
                  type="text"
                  placeholder="Product Master"
                  value={selectedProductCode}
                  readOnly
                  style={{ width: '100%' }}
                />
              </FloatingLabel>
              <button
                className="button select-search"
                onClick={() => setIsPopupOpen(true)}
              >
                <IoSearch />
              </button>
            </div>
          </Form.Group>

          {/* Display loading indicator */}
          {loading && <div>Loading products...</div>}

          {/* Display error if any */}
          {error && <div>Error: {error}</div>}

          {/* Display products in popup */}
          <Popup
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            modal
            className="select-quote-product"
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Product Description</th>
                </tr>
              </thead>
              <tbody>
                {productMaster.map((y, i) => (
                  <tr
                    key={i}
                    onClick={() => {
                      setSelectedProductCode(y.product_code);
                      setIsPopupOpen(false);
                    }}
                  >
                    <td>{y.product_code}</td>
                    <td>{y.product_name}</td>
                    <td>{y.product_description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Popup>

          <p className="edit-cancel">
            <input
              type="submit"
              className="account-save"
              value="Save"
              onClick={handleButtonClick}
            />
            <Link to={`/opportunity/quote-view/${id}`}>
              <input
                type="submit"
                className="account-save"
                value="Cancel"
              />
            </Link>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SelectQuoteproduct;
