import React, { Component } from 'react'
import Accounts from './accounts'
import Footer from './Footer'
import Header from './Header' 
import {  Link } from "react-router-dom";
function Dashboard() {
   
  
    return (
      <div>
    <Header/>
    <div className="dashboard">
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / All</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div> 
        <div id='dashboard' className='container-fluid'>
            <div className='row'>
            
                <div className='col-md-3'>
                <Link to="/accounts/account-list" >
                    <div className='bg-info'>
                        Accounts
                    </div>
                    </Link>
                </div>
                
                <div className='col-md-3'>
                <Link to="/contact/contact-list">
                    <div className='bg-info'>
                        Contacts
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                <Link to="/product/product-list">
                    <div className='bg-info'>
                        Products
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                <Link to="/productmaster/create-view-list">
                    <div className='bg-info'>
                    Product Master
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                <Link to="/product/product-list" >
                    <div className='bg-info'>
                        Product
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                <Link to="/opportunity/create-view-list" > <div className='bg-info'>
                        Opportunities
                    </div>
                    </Link>
                </div>
                
                <div className='col-md-3'>
                <Link to="/opportunity-shipment/create-view-list"><div className='bg-info'>
                Opp Shipments
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                <Link to="/contract/create-contract-list">
                    <div className='bg-info'>
                    Contracts
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                <Link to="/order/create-order-list">
                    <div className='bg-info'>
                        Orders
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                <Link to="/tcf/create-view-list">
                    <div className='bg-info'>
                        TCF
                    </div>
                    </Link>
                </div>
                <div className='col-md-3'>
                    <Link to='/contract-shipment/create-view-list'>
                    <div className='bg-info'>
                        Product Master
                    </div>
                    </Link>
                </div>
            </div>
         </div>
    <Footer/>
      </div>
    );
  
};

export default Dashboard;