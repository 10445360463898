import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import Header from '../Header'
import { AES, enc } from 'crypto-js';

import { useNavigate, Link } from "react-router-dom";
import Footer from '../Footer'
import { API_URL } from "../config";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { BsArrowDownSquare, BsArrowUpSquare, BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";


const BankCreateView = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const navigate = useNavigate();

  const [filterConditions, setFilterConditions] = useState([

    { input: "", operator: "", values: [] },

  ]);


  const [bankKeys, setbankKeys] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState(['bank_code']);

  const [formData, setFormData] = useState({

    view_name: "",
    unique_name: "",
    all_bank: "",
    my_bank: ""

  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const moveSelectedFieldsUp = () => {

    const updatedFields = [...selectedFields];

    const firstSelectedIndex = selectedFields.indexOf(selectedOptions[0]);

    if (firstSelectedIndex > 0) {

      const movedFields = updatedFields.splice(firstSelectedIndex, selectedOptions.length);
      updatedFields.splice(firstSelectedIndex - 1, 0, ...movedFields);
      setSelectedFields(updatedFields);
    }

  };

  // New function to move selected fields down
  const moveSelectedFieldsDown = () => {

    const updatedFields = [...selectedFields];
    const lastSelectedIndex = selectedFields.indexOf(selectedOptions[selectedOptions.length - 1]);

    if (lastSelectedIndex < selectedFields.length - 1) {

      const movedFields = updatedFields.splice(lastSelectedIndex, selectedOptions.length);
      updatedFields.splice(lastSelectedIndex + 1, 0, ...movedFields);
      setSelectedFields(updatedFields);

    }

  };

  // Function To Handle Field Selection //

  const handleFieldSelection = (e) => {

    const options = Array.from(e.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    setSelectedOptions(options);

  };

  // Function To Handle Add Fields //
  const handleAddFields = () => {

    const newFields = selectedOptions.filter(

      (option) => !selectedFields.includes(option)

    );

    setAvailableFields((prevFields) =>

      prevFields.filter((field) => !newFields.includes(field))

    );

    setSelectedFields((prevFields) => [...prevFields, ...newFields]);

    setSelectedOptions([]);

  };





  const handleRemoveFields = () => {

    setAvailableFields((prevFields) => [...prevFields, ...selectedOptions].filter(

      (field, index, self) => index === self.findIndex((f) => f === field)

    ));

    setSelectedFields((prevFields) =>

      prevFields.filter((field) => !selectedOptions.includes(field))

    );

    setSelectedOptions([]);

  };

  // Toggle Between Selected Fields  //

  const handleFieldToggle = (field) => {

    if (selectedOptions.includes(field)) {

      setSelectedOptions([]);

    } else {

      setSelectedOptions([field]);

    }

  };

  // Function To Handle Input Filter Changes //

  const handleInputChanges = (e) => {

    const { name, value, type, checked } = e.target;

    setFormData((prevState) => {

      const newState = {

        ...prevState,
        [name]: type === 'checkbox' ? checked : value,

      };

      if (name === 'all_bank' && checked) {

        newState.my_bank = false;

      } else if (name === 'my_bank' && checked) {

        newState.all_bank = false;

      }

      return newState;

    });

  };


  // Fetch Contract Keys //

  const fetchAccoutKeys = async () => {


    const URL = `${API_URL}get/rwoodbank_keys`

    const bankKeysResponse = await axios.get(URL, {

      headers: {

        "Content-Type": "application/json",

      }

    })

    if (bankKeysResponse.status === 200) {

      setbankKeys(bankKeysResponse?.data?.fields)
      setAvailableFields(bankKeysResponse?.data?.fields);

    }

  }

  // On Load Component Fetch Contract Keys //

  useEffect(() => {

    fetchAccoutKeys()

  }, [])

  const handleInputChange = (e, index) => {

    const { name, value } = e.target;

    const updatedFilterConditions = [...filterConditions];

    const updatedCondition = {

      ...updatedFilterConditions[index],
      [name]: name === "values" ? value.split(",") : value,

    };

    updatedFilterConditions[index] = updatedCondition;

    setFilterConditions(updatedFilterConditions);

  };

  const addFilterCondition = () => {

    if (filterConditions.length < 10) {

      setFilterConditions([

        ...filterConditions,
        { input: "", operator: "", values: [] },

      ]);

    } else {

      alert("You can't add more than 10 filters.");

    }

  };

  const removeFilterCondition = (index) => {

    const updatedFilterConditions = [...filterConditions];
    updatedFilterConditions.splice(index, 1);

    setFilterConditions(updatedFilterConditions);

  };

  // Handle Submit //
  const handleSubmit = async (e) => {

    console.log(selectedFields);

    e.preventDefault();

    const isFilterConditionsEmpty =
      filterConditions.length === 1 &&
      filterConditions[0].input === "" &&
      filterConditions[0].operator === "" &&
      filterConditions[0].values.length === 0;

    const dataToSend = {

      ...formData,
      filterConditions: isFilterConditionsEmpty ? [] : filterConditions,
      selectedFields: isFilterConditionsEmpty ? [] : selectedFields,

    };


    if (filterConditions.length === 0) {

      alert("You should add filters.");
      return;


    }

    if (!formData.view_name) {

      alert("You should enter a view name.");
      return;

    }

    try {



      const URL = `${API_URL}get/rwoodbank_records`

      const createNewViewResponse = await axios.post(URL,dataToSend, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json"

        }

      })


      if (createNewViewResponse.status === 200)
      {

        const rwoodBankViewData = createNewViewResponse
        sessionStorage.setItem("isrwoodBankView", true)
        sessionStorage.setItem("rwoodBankView", rwoodBankViewData?.data?.view_name)
        sessionStorage.setItem("rwoodBankViewId", rwoodBankViewData?.data?.bank_create_view_id)

        navigate("/bank/bank-list");

      }


    } catch (error) {

      console.error("Error:", error.message);
    }

  };

  return (
    <div className="addAccount">
      <Header />
      <div className="page-header" id='add-account'>
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Create New View</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard / Bank / Create New View
            </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">

          </div>
        </div>
      </div>
      <div id='view-container'>


        <p className="edit-btn">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
          <Link  to={`/bank/bank-list`}><button
            className="btn btn-primary"

          >
            Cancel
          </button></Link>
        </p>
        <Row className="mb-3">
          <h4 className='heading'>Step 1: Enter View Name</h4>
          <Form.Group as={Col} md="12">
            <FloatingLabel
              controlId="floatingInput"
              label="View Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="view_name"
                placeholder="View Name"
                value={formData.view_name}
                onChange={handleInputChanges}
              />
            </FloatingLabel>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <h4 className='heading'>Step 2: Specify Filter Criteria</h4>
          <p>Filter By Owner:</p>

          <Form.Group as={Col} md="12">
            <input
              className="form-check-input"
              type="checkbox"
              checked={formData.all_bank}
              name="all_bank"
              id="flexCheckChecked"
              onChange={handleInputChanges}
            />
            <label className="form-check-label">
              All Banks
            </label>
          </Form.Group>
          <Form.Group as={Col} md="12">
            <input
              className="form-check-input"
              type="checkbox"
              checked={formData.my_bank}
              name="my_bank"
              id="flexCheckDefault"
              onChange={handleInputChanges}
            />
            <label className="form-check-label">
              My Banks
            </label>
          </Form.Group>
          <p id='filter-by'>Filter By Additional Fields (Optional):</p>
          <Form.Group as={Col} md="12" id='view-filters'>
            {filterConditions.length > 0 ? (
              <>
                {filterConditions?.map((condition, index) => (
                  <div key={index} >
                    <select
                      name="input"
                      value={condition.input}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <option value="">Select an Bank key</option>
                      {bankKeys.map((key, keyIndex) => (
                        <option key={keyIndex} value={key}>
                          {key.replace(/_/g, " ")}
                        </option>
                      ))}
                    </select>
                    <select
                      name="operator"
                      value={condition.operator}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <option value="Operator" defaultValue>
                        Operator
                      </option>
                      <option value="equals">equals</option>
                      <option value="not_equals">not_equals</option>
                      <option value="greater">greater</option>
                      <option value="greater_equal">greater_equal</option>
                      <option value="less">less</option>
                      <option value="less_equal">less_equal</option>
                    </select>
                    <input
                      type="text"
                      name="values"
                      placeholder="Values"
                      value={condition.values.join(",")}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <FaTrashAlt id='trash' onClick={() => removeFilterCondition(index)} />
                  </div>
                ))}
                <button onClick={addFilterCondition}>Add Filter</button>
              </>


            ) : (<p className="no-data">Not found Contract Key</p>)}

          </Form.Group>
        </Row>
        <Row className="mb-3 available">
          <h4 className='heading'>Step 3: Select Fields to Display</h4>
          <Form.Group as={Col} md="6">
            <p>Available Fields</p>
            <select
              multiple
              value={selectedOptions}
              className='form-control selectedField'
              onChange={handleFieldSelection}
            >
              {availableFields.length > 0 ? (
                <>
                  {availableFields.map((field) => (
                    <option key={field.id} value={field}>
                      {field.replace(/_/g, " ")}
                    </option>
                  ))}
                </>
              ) : (
                <option className="create-no-data">No Data</option>
              )}
            </select>
          </Form.Group>

          <Form.Group as={Col} md="6" >
            <Form.Group as={Col} id='createview-add-remove' >
              <div>
                <p>
                  <span>Add</span> <br />
                  <BsArrowRightSquare onClick={handleAddFields}
                    disabled={!selectedOptions.length} />
                </p>
                <p>

                  <BsArrowLeftSquare onClick={handleRemoveFields}
                    disabled={!selectedFields.length} />
                  <br />
                  <span>Remove</span>
                </p>




              </div>
            </Form.Group>


            <div id='right-select-section'>
              <p>Selected Fields</p>
              <div className=" selectedFields" >
                {selectedFields.length > 0 ? (
                  <>
                    {selectedFields.map((field, index) => (
                      <div
                        key={index}
                        style={{
                          justifyContent: "left",
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "0px",
                          background: selectedOptions.includes(field)
                            ? "#e3e3e3"
                            : "#fff",
                        }}
                        onClick={() => handleFieldToggle(field)}
                      >
                        {field.replace(/_/g, " ")}
                      </div>
                    ))}
                  </>
                ) : (
                  <div

                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "0px",
                      background: '#e3e3e3'

                    }}

                  >
                    <p>No Data</p>
                  </div>
                )}
              </div>
            </div>


            <Form.Group as={Col} id='move-up-down' >
              <p>
                <BsArrowUpSquare onClick={moveSelectedFieldsUp}
                  disabled={!selectedFields.length} />

                <BsArrowDownSquare onClick={moveSelectedFieldsDown}
                  disabled={!selectedFields.length} />
              </p>
            </Form.Group>
          </Form.Group>



        </Row>

        <p className="edit-btn">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
          <Link to={`/bank/bank-list`}><button
            className="btn btn-primary"

          >
            Cancel
          </button></Link>
        </p>
        <div className='successDiv'><p></p><p></p></div>
      </div>
      <Footer />
    </div>
  );
}

export default BankCreateView;
