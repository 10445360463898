import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Apdemo = () => {
  const [blsentDate17, setBlsentDate17] = useState(null);

  const handlebldate17 = (date) => {
    setBlsentDate17(date);
  };

  return (
    <DatePicker
      selected={blsentDate17}
      onChange={handlebldate17}
      dateFormat='dd/MM/yyyy'
      placeholderText="Select"
    />
  );
};

export default Apdemo;
