import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import Login from "../components/Login";
import Navigation from "../navigation";
import { GoDashboard } from "react-icons/go";
import Dropdown from "react-bootstrap/Dropdown";
import UserName from "../components/UserName";
import Logout from "../components/Logout";
import "../css/style.css";
import $ from "jquery";
import axios from "axios";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
  BsPerson,
} from "react-icons/bs";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
export default function Header() {
  const [searchValue, setSearchValue] = useState("");
  const prev = () => {
    $(".menu-inner-box").animate({ scrollLeft: "-=100px" });
  };
  const next = () => {
    $(".menu-inner-box").animate({ scrollLeft: "+=100px" });
  };
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  function changeStatus(e) {
    setStatus(e.target.value);
  }
  if (status === "trading") {
    window.location = "/rwood-trading";
    alert(status);
  } else if (status == "finance") {
    window.location = "/rwood-finance";
    // alert(status)
  }
  const toggle = () => {
    $(".nav").toggleClass("justify-content-end");
    $(".toggle").toggleClass("text-light");
  };
  $(document).on("click", "#actives", function () {
    setTimeout(function () {
      $(".active-menu").removeClass("active-menu");

      $(this).addClass("active-menu");
    }, 5000);
  });
  const [selectedItem, setSelectedItem] = useState("change page ");
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    // Update the active link based on the current location
    setActiveLink(location.pathname);
  }, [location]);

  const handleLinkClick = (link) => {
    // Set the active link
    setActiveLink(link);

    // Add additional logic after adding the active class
    console.log("Link clicked:", link);
    // You can perform other actions here, like navigating to a different page or updating state
  };

  const handleSearch = () => {
    navigate("/search/search-result", {
      state: { searchText: searchValue },
    });
  };
  return (
    <>
      <div className="container-fluid" id="header">
        <div className="row">
          <div className="col-md-12">
            <div className="pull-left d-flex">
              <img
                src="../../images/rwood1.jpg"
                alt="Logo"
                style={{ height: "50px", display: "block", width: "auto" }}
                className="logo"
              />
              <div class="search">
                <i class="fa fa-search"></i>
                <input
                  type="text"
                  class="form-control"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search"
                />
                <button onClick={handleSearch}>GO</button>
              </div>
              <div></div>
            </div>
            <div className="pull-right">
              <div className="pull-right">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="rounded-0"
                    style={{
                      height: "50px",
                    }}
                  >
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      className="user-name"
                    >
                      <BsPerson />
                      <div style={{ marginLeft: "5px", marginBottom: "5px" }}>
                        <p id="admin-label">
                          <span>
                            <UserName />
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Setting</Dropdown.Item>
                    <Dropdown.Item className="logout-btn">
                      <Logout />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="navbar">
        

          <nav>
            <Link
              to="/dashboard"
              className={activeLink === "/dashboard" ? "active" : ""}
              onClick={() => handleLinkClick("/dashboard")}
            >
              Dashboard
            </Link>
            <Link
              to="/reports"
              className={activeLink === "/test/test1" ? "active" : ""}
              onClick={() => handleLinkClick("/test/test1")}
            >
              Report
            </Link>
            <Link
              to="/accounts/account-list"
              className={
                activeLink === "/accounts/account-list" ? "active" : ""
              }
              onClick={() => handleLinkClick("/accounts/account-list")}
            >
              Accounts
            </Link>
            <Link
              to="/contact/contact-list"
              className={activeLink === "/contact/contact-list" ? "active" : ""}
              onClick={() => handleLinkClick("/contact/contact-list")}
            >
              Contacts
            </Link>
            <Link
              to="/productmaster/create-view-list"
              className={
                activeLink === "/productmaster/create-view-list" ? "active" : ""
              }
              onClick={() => handleLinkClick("/productmaster/create-view-list")}
            >
              Product Master
            </Link>
            <Link
              to="/product/product-list"
              className={activeLink === "/product/product-list" ? "active" : ""}
              onClick={() => handleLinkClick("/product/product-list")}
            >
              Products
            </Link>
            <Link
              to="/opportunity/create-view-list"
              className={
                activeLink === "/opportunity/create-view-list" ? "active" : ""
              }
              onClick={() => handleLinkClick("/opportunity/create-view-list")}
            >
              Opportunities
            </Link>
            <Link
              to="/opportunity-shipment/create-view-list"
              className={
                activeLink === "/opportunity-shipment/create-view-list"
                  ? "active"
                  : ""
              }
              onClick={() =>
                handleLinkClick("/opportunity-shipment/create-view-list")
              }
            >
              Opp Shipments
            </Link>
            <Link
              to="/contract/create-contract-list"
              className={
                activeLink === "/contract/create-contract-list" ? "active" : ""
              }
              onClick={() => handleLinkClick("/contract/create-contract-list")}
            >
              Contracts
            </Link>
            <Link
              to="/contract-shipment/create-view-list"
              className={
                activeLink === "/contract-shipment/create-view-list"
                  ? "active"
                  : ""
              }
              onClick={() =>
                handleLinkClick("/contract-shipment/create-view-list")
              }
            >
              {" "}
              Contract Shipments
            </Link>

            <Link
              to="/order/create-order-list"
              className={
                activeLink === "/order/create-order-list" ? "active" : ""
              }
              onClick={() => handleLinkClick("/order/create-order-list")}
            >
              Orders
            </Link>
            <Link
              to="/tcf/create-view-list"
              className={activeLink === "/tcf/create-view-list" ? "active" : ""}
              onClick={() => handleLinkClick("/tcf/create-view-list")}
            >
              TCF
            </Link>

            <Link
              to="/quote/quote-list"
              className={activeLink === "/quote/quote-list" ? "active" : ""}
              onClick={() => handleLinkClick("/quote/quote-list")}
            >
              Quotes
            </Link>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                More
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link
                    to="/finance/finance-list"
                    className={
                      activeLink === "/finance/finance-list" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/finance/finance-list")}
                  >
                    {" "}
                    RW Finance Config
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/account-master/list"
                    className={
                      activeLink === "/account-master/list" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/account-master/list")}
                  >
                    {" "}
                    Chart of Accounts Master
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/buyer/buyer-list"
                    className={
                      activeLink === "/buyer/buyer-list" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/buyer/buyer-list")}
                  >
                    {" "}
                    Buyer Products
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/bank/bank-list"
                    className={activeLink === "/bank/bank-list" ? "active" : ""}
                    onClick={() => handleLinkClick("/bank/bank-list")}
                  >
                    {" "}
                    Rwood Banks Master
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/price/price-list"
                    className={
                      activeLink === "/price/price-list" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/price/price-list")}
                  >
                    {" "}
                    Price Types{" "}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/pricebook/create-view-list"
                    className={
                      activeLink === "/pricebook/create-view-list"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      handleLinkClick("/pricebook/create-view-list")
                    }
                  >
                    {" "}
                    Price Book
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/company/company-list"
                    className={
                      activeLink === "/company/company-list" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/company/company-list")}
                  >
                    {" "}
                    Companies
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/user/userlist"
                    className={activeLink === "/user/userlist" ? "active" : ""}
                    onClick={() => handleLinkClick("/user/userlist")}
                  >
                    Rwood Users
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/post-shipment/create-view-list"
                    className={
                      activeLink === "/post-shipment/create-view-list"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      handleLinkClick("/post-shipment/create-view-list")
                    }
                  >
                    {" "}
                    Post Shipment Documents
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/pre-shipment/create-view-list"
                    className={
                      activeLink === "/pre-shipment/create-view-list"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      handleLinkClick("/pre-shipment/create-view-list")
                    }
                  >
                    Pre Shipment Documents
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/all-quotes"
                    className={activeLink === "/all-quotes" ? "active" : ""}
                    onClick={() => handleLinkClick("/all-quotes")}
                  >
                    {" "}
                    Quote Product
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    to="/quote-shipment/list-view"
                    className={
                      activeLink === "/quote-shipment/list-view" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/quote-shipment/list-view")}
                  >
                    {" "}
                    Quote Shipment{" "}
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    to="/pay-receive-detail/list-view"
                    className={
                      activeLink === "/pay-receive-detail/list-view"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      handleLinkClick("/pay-receive-detail/list-view")
                    }
                  >
                    {" "}
                    RW Payable/Receivables{" "}
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    to="/pay-receive-lineitem/list-view"
                    className={
                      activeLink === "/pay-receive-lineitem/list-view"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      handleLinkClick("/pay-receive-lineitem/list-view")
                    }
                  >
                    {" "}
                    Rw Payable/Receivable LineItem{" "}
                  </Link>
                </Dropdown.Item>


                <Dropdown.Item>
                  <Link
                    to="/receipt-detail/list-view"
                    className={
                      activeLink === "/receipt-detail/list-view"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      handleLinkClick("/receipt-detail/list-view")
                    }
                  >
                    {" "}
                    RW Payments/Receipts{" "}
                  </Link>
                </Dropdown.Item>


                <Dropdown.Item>
                  <Link
                    to="/receipt-lineitem/list-view"
                    className={
                      activeLink === "/receipt-lineitem/list-view"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      handleLinkClick("/receipt-lineitem/list-view")
                    }
                  >
                    {" "}
                    RW Payments/Receipts LineItem{" "}
                  </Link>
                </Dropdown.Item>





              </Dropdown.Menu>
            </Dropdown>
          </nav>
        </div>
      </div>
    </>
  );
}
