import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { useForm, Controller, useWatch } from "react-hook-form";

import { parse, isValid, format } from "date-fns";

import { toast, ToastContainer } from "react-toastify";

import DatePicker from "react-datepicker";

// import { decryptedAccessToken } from "../../Token";

import { API_URL } from "../config";

import Header from "../Header";

import Footer from "../Footer";


import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import { AES, enc } from "crypto-js";

import "quill/dist/quill.snow.css";
import "datatables.net-responsive-dt";
import "react-toastify/dist/ReactToastify.css";
import "datatables.net-dt/js/dataTables.dataTables.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-data-table-component-extensions/dist/index.css";


const TcfEdit = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    const encryptedAccessToken = localStorage.getItem("access_token");
    const decryptedAccessToken = encryptedAccessToken
      ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
      : null;


  const { id } = useParams();

  const tcfId = id;

  const navigate = useNavigate()

  const [isLoading, setisLoading] = useState(false);

  const [priceItems, setPriceItems] = useState([]);

  const [rwoodBanks, setRwoodbank] = useState([]);

  const [buyers, setBuyers] = useState([]);

  const [suppliers, setSuppliers] = useState([]);

  const [rwoodUsers, setRwoodusers] = useState([]);

  const [rwoodCompanies, setRwoodCompanies] = useState([]);

  const [priceBooks, setPriceBooks] = useState([]);

  const [productMaster, setProductMaster] = useState([]);

  const [products, setProduct] = useState([]);

  const [tradeConfirmData, setTradeConfirmData] = useState({});

  const [currency, setCurrencies] = useState([{"USD": "USD", "INR": "INR"}])

  const [tcfStatus, setTcfStatus] = useState([{

    "--None--": null,
    "Draft": "Draft",
    "Proposed": "Proposed",
    "Proposed-Long Term Contract":"Proposed-Long Term Contract",
    "Projected":"Projected",
    "Actual Shipment Completed": "Shipment Done",
    "Completed": "Completed",
    "Cancelled":"Z Cancelled",
    "Draft - Long Term": "Draft - Long Term",
    "Shipments - Arutmin": "Shipments - Arutmin",
    "Cancelled with Penalty": "Cancelled with Penalty"

  }])

  const [purchaseContract, statusPurchaseContract] = useState([

    {

      "--None--": null,
      "Draft": "Draft",
      "Activated": "Activated",
      "In Approval Process": "In Approval Process",
      "Under Review": "Under Review",
      "Signed Contract yet to receive": "Signed Contract yet to receive",
      "Signed Contract Sent for Sign": "Signed Contract Sent for Sign",
      "Draft Sent for Signing": "Draft Sent for Signing",
      "Draft Received for Signing": "Draft Received for Signing",
      "Signed Original Contract Need to Courier": "Signed Original Contract Need to Courier",
      "Signed Original Contract yet to Receive": "Signed Original Contract yet to Receive",
      "Original Contract Signed by Both Parties": "Original Contract Signed by Both Parties",
      "Original Contract Signed and Received by Both Parties": "Original Contract Signed and Received by Both Parties"

    }


  ])

  const [priceBasis, setPriceBasis] = useState([

    {
      "--None--": null,
      "GCV (ARB)": "GCV (ARB)",
      "GCV (ADB)": "GCV (ADB)",
      "NCV (ARB)": "NCV (ARB)"
    }

  ])

  const [vesselType, setVesselType] = useState([

    {
      "--None--": null,
      "Geared (GnG)": "Geared (GnG)",
      "Gearless": "Gearless",
      "Geared": "Geared",
      "Barge": "Barge"
    }

  ])

  const [hbaYear, setHbaYear] = useState([

    {
      "--None--": null,
      "2027": "2027",
      "2026": "2026",
      "2025": "2025",
      "2024": "2024",
      "2023": "2023",
      "2022": "2022",
      "2021": "2021",

    }

  ])

  const [hbaMonth, setHbaMonth] = useState([

    {
      "--None--": null,
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",

    }

  ])


  // TradeConfirm Fields Config //

  const TradeConfirmFields = {

    "fields": {

      "Informations": {

        "fieldsConfig": [

          { "name": "tcf_no", "type": "text", "disabled": true, "label": "TCF no", "placeholder": "TCF no" },
          { "name": "locked", "type": "checkbox", "disabled": false, "label": "Locked" },
          { "name": "tcf_date", "type": "date", "disabled": false, "label": "TCF date" },
          { "name": "record_type", "type": "text", "disabled": true, "label": "Record Type" },
          { "name": "company", "api_field": "company_name", "type": "select", "disabled": false, "label": "Company", "options": rwoodCompanies },
          { "name": "opportunity",  "type": "text", "label": "Opportunity", "disabled": true, "placeholder": "Opportunity"},
          { "name": "currency", "api_field": "currency", "type": "select", "disabled": false, "label": "Currency", "options": currency },
          { "name": "quote", "type": "text", "disabled": false, "label": "Quote" },
          { "name": "exchange_rate", "type": "text", "disabled": false, "label": "Exchange Rate" },
          { "name": "trader_name", "api_field": "rwood_username", "type": "select","disabled": false, "label": "Trader Name", "options": rwoodUsers },
          { "name": "order","type": "text", "disabled": false, "label": "Order"},
          { "name": "owner","type": "text", "disabled": false, "label": "Owner"},
          { "name": "rwood_bank_master", "api_field": "rwood_bank_name", "type": "select", "disabled": false,"label": "Rwood Bank Master", "options": rwoodBanks },
          { "name": "auto_select_pricebook", "type": "checkbox", "disabled": false,"label": "Auto Select Pricebook" },
          { "name": "price_book", "api_field": "price_book_name", "disabled": false,"type": "select", "label": "Price Book", "options": priceBooks },
          { "name": "tcf_status", "api_field": null, "type": "select", "disabled": false, "label": "Tcf Status", "options": tcfStatus },
          { "name": "purchase_confirmation_date", "type": "date", "disabled": false, "label": "Purchase Confirmation Date" },
          { "name": "buyer_contract", "type": "text", "disabled": true, "label": "Buyer Contract" },
          { "name": "contract_shipment", "type": "text", "disabled": true, "label": "Contract Shipment" },
          { "name": "product_master", "api_field": "product_name", "disabled": false, "type": "select", "label": "Product Master", "options": productMaster },
          { "name": "product", "api_field": "product_name", "type": "select", "disabled": false, "label": "Product", "options": products },
          { "name": "product_name", "type": "text", "disabled": false, "label": "Product Name" },


        ]

      },

      "Shipment Summary": {

        "fieldsConfig": [

          { "name": "shipment_sales_price", "type": "text", "disabled": false,"label": "Shipment Sales Price" },

          { "name": "shipment_gross_margin_ton", "type": "text", "disabled": false, "label": "Shipment Gross Margin-Ton" },

        ]

      },

      "Trade Confirmation Details": {

        "fieldsConfig": [

          { "name": "buyer", "api_field": "account_name", "type": "select", "disabled": false,"label": "Buyer", "options": buyers },
          { "name": "status_of_purchase_contract", "api_field": null, "type": "select", "disabled": false,"label": "Status of Purchase Contract", "options": purchaseContract },
          { "name": "supplier", "api_field": "account_name", "type": "select", "disabled": false,"label": "Supplier", "options": suppliers },
          { "name": "proposed_laycan_from", "type": "date", "disabled": true,"label": "Proposed Laycan From" },
          { "name": "product_description", "type": "text", "disabled": false, "label": "Product Description" },
          { "name": "proposed_laycan_to", "type": "date", "disabled": true, "label": "Proposed Laycan To" },
          {"name": "product_specification", "type": "text", "disabled": false,"label": "Product Specification"},
          { "name": "quantity_MT", "type": "text", "disabled": false,"label": "Quantity (MT)"},
          { "name": "price_basis", "api_field": null, "type": "select", "disabled": false,"label": "Price Basis", "options": priceBasis },
          { "name": "vessel_type", "api_field": null, "type": "select", "disabled": false,"label": "Vessel Type", "options": vesselType },
          { "name": "payment_term", "type": "text", "disabled": false,"label": "Payment Term"},
          { "name": "buying_terms", "type": "text", "disabled": false, "label": "Buying Terms	"},
          { "name": "selling_terms", "type": "text", "disabled": false,"label": "Selling Terms"},
          {"name":"price_adjustment", "type": "text", "disabled": false,"label": "Price Adjustment"}

        ]

      },

      "Contractual": {

        "fieldsConfig": [

          { "name": "contractual_GAR_P", "type": "text", "disabled": false,"label": "Contractual GAR (P)"},
          { "name": "contractual_GAR_S", "type": "text", "disabled": false,"label": "Contractual GAR (S)"},
          { "name": "contractual_TM_AR_P", "type": "text", "disabled": false,"label": "Contractual TM (AR) (P)"},
          { "name": "contractual_TM_AR_S", "type": "text", "disabled": false,"label": "Contractual TM (AR) (S)"},
          { "name": "contractual_Ts_AR_p", "type": "text", "disabled": false,"label": "Contractual TS (AR) (P)"},
          { "name": "contractual_Ts_AR_s", "type": "text", "disabled": false,"label": "Contractual TS (AR) (S)"},
          { "name": "contractual_Ash_AR_P", "type": "text", "disabled": false,"label": "Contractual Ash (AR) (P)"},
          { "name": "contractual_Ash_AR_S", "type": "text", "disabled": false,"label": "Contractual Ash (AR) (S)"},
          { "name": "contractual_Ts_ADB_P", "type": "text","disabled": false,"label": "Contractual TS (ADB) (P)"},
          { "name": "contractual_Ts_ADB_S", "type": "text","disabled": false,"label": "Contractual TS (ADB) (S)"},
          { "name": "contractual_Ash_ADB_P", "type": "text","disabled": false,"label": "Contractual Ash (ADB) (P)" },
          { "name": "contractual_Ash_ADB_S", "type": "text","disabled": false,"label": "Contractual Ash (ADB) (S)" },
          { "name": "projected_price_basis_GAR_P", "type": "text", "disabled": false,"label": "Projected Price Basis GAR (P)"},
          { "name": "projected_price_basis_GAR_S", "type": "text", "disabled": false,"label": "Projected Price Basis GAR (S)"},
          {"name": "contractual_HBA_year", "api_field": null, "type": "select", "disabled": false,"label": "Contractual HBA Year", "options": hbaYear},
          { "name": "discounted_price", "type": "text", "disabled": false,"label": "Discounted Price"},
          {"name": "contractual_HBA_month", "api_field": null, "type": "select", "disabled": false,"label": "Contractual HBA Month", "options": hbaMonth},
          { "name": "projected_index_value", "type": "text", "disabled": false,"label": "Projected Index Value"},
          { "name": "projected_hba", "type": "text", "disabled": false,"label": "Projected HBA"},
          {"name": "projected_sale_price_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Projected Sale Price Type", "options": priceItems},
          {"name": "projected_purchase_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Projected Purchase Price Type", "options": priceItems},
          { "name": "projected_fixed_sales_price", "type": "text", "disabled": false,"label": "Projected Fixed Sales Price"},
          { "name": "projected_fixed_purchase_price", "type": "text", "disabled": false,"label": "Projected Fixed Purchase Price"},
          { "name": "projected_sales_price_margin", "type": "text", "disabled": false,"label": "Projected Sales Price Margin"},
          { "name": "projected_purchase_price_margin", "type": "text", "disabled": false,"label": "Projected Purchase Price Margin"},
          { "name": "projected_barge_discount", "type": "text", "disabled": false,"label": "Projected Barge Discount"}

        ]
      },

      "Cost Sheet Projection": {

        "fieldsConfig": [

          { "name": "projected_pur_amount", "type": "text", "disabled": false,"label": "Projected Pur Amount"},
          { "name": "projected_sales", "type": "text", "disabled": false,"label": "Projected Sales"},
          { "name": "projected_freight_rate", "type": "text", "disabled": false,"label": "Projected Freight Rate"},
          { "name": "projected_freight_amount", "type": "text", "disabled": false,"label": "Projected Freight Amount"},
          { "name": "projected_insurance", "type": "text", "disabled": false,"label": "Projected Insurance	"},
          { "name": "projected_commission", "type": "text", "disabled": false,"label": "Projected Commision"},
          { "name": "projected_fixed_apportioned_overheads", "type": "text", "disabled": false,"label": "Projected Fixed - Apportioned Overheads"},
          { "name": "projected_any_contract_specific_cost", "type": "text", "disabled": false,"label": "Projected Any Contract Specific Cost"},
          { "name": "projected_extra_cost", "type": "text", "disabled": false,"label": "Projected Extra Cost" },
          { "name": "projected_provision_for_corporate_tax", "type": "text", "disabled": false,"label": "Projected Provision for Corporate Tax"}

        ]

      },

      "Actual Shipment Details": {

        "fieldsConfig":[]

      },

      "Actual Cost Elements": {

        "fieldsConfig": [

          { "name": "purchase_price_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Purchase Price Type", "options": priceItems },
          { "name": "actual_data_rcvd", "type": "checkbox", "disabled": false,"label": "Actual Data Rcvd" },
          { "name": "fixed_purchase_price", "type": "text", "disabled": false,"label": "Fixed Purchase Price" },
          { "name": "sale_price_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Sale Price Type", "options": priceItems },
          { "name": "purchase_price_margin", "type": "text", "disabled": false,"label": "Purchase Price Margin" },
          { "name": "fixed_sale_price", "type": "text", "disabled": false,"label": "Fixed Sale Price" },
          { "name": "barge_discount", "type": "text", "disabled": false,"label": "Barge Discount" },
          { "name": "sale_price_margin", "type": "text", "disabled": false,"label": "Sales Price Margin"},
          { "name": "actual_gar_p", "type": "text", "disabled": false,"label": "Actual GAR (P)" },
          { "name": "actual_gar_s", "type": "text","disabled": false, "disabled": false,"label": "Actual GAR (S)"},
          { "name": "actual_tm_p", "type": "text", "disabled": false,"label": "Actual TM (AR) (P)" },
          { "name": "actual_tm_s", "type": "text", "disabled": false,"label": "Actual TM (AR) (S)" },
          { "name": "actual_ash_ar_p", "type": "text", "disabled": false,"label": "Actual Ash (AR) (P)" },
          { "name": "actual_ash_ar_s", "type": "text", "disabled": false,"label": "Actual Ash (AR) (S)" },
          {"name": "actual_ts_ar_p", "type": "text", "disabled": false,"label": "Actual TS (AR) (P)"},
          { "name": "actual_ts_ar_s", "type": "text", "disabled": false,"label": "Actual TS (AR) (S)"},
          { "name": "actual_ash_adb_p", "type": "text", "disabled": false,"label": "Actual Ash (ADB) (P)"},
          { "name": "actual_ash_adb_s", "type": "text", "disabled": false,"label": "Actual Ash (ADB) (S)"},
          { "name": "actual_ts_adb_p", "type": "text", "disabled": false,"label": "Actual TS (ADB) (P)"},
          { "name": "actual_ts_adb_s", "type": "text", "disabled": false,"label": "Actual TS (ADB) (S)"},
          { "name": "actual_aft_p", "type": "text", "disabled": false,"label": "Actual AFT (P)"},
          { "name": "actual_aft_s", "type": "text", "disabled": false,"label": "Actual AFT (S)"},
          { "name": "actual_vm_p", "type": "text", "disabled": false,"label": "Actual VM (P)"},
          { "name": "actual_vm_s", "type": "text", "disabled": false,"label": "Actual VM (S)"},
          { "name": "actual_hgi_p", "type": "text", "disabled": false,"label": "Actual HGI (P)"},
          { "name": "actual_hgi_s", "type": "text", "disabled": false,"label": "Actual HGI (S)"},
          { "name": "price_basis_gar_p", "type": "text", "disabled": false,"label": "Price Basis GAR (P)"},
          { "name": "price_basis_gar_s", "type": "text", "disabled": false,"label": "Price Basis GAR (S)"},
          { "name": "gar_p", "type": "text", "disabled": false,"label": "GAR (P)"},
          { "name": "gar_s", "type": "text", "disabled": false,"label":"GAR (S)"}

        ]
      },

      "Bonus / Penalty": {

        "fieldsConfig": [

          { "name": "sulfur_bonus_rate_MT_S", "type": "text", "disabled": false,"label": "Sulfur Bonus Rate (MT) (S)"},
          { "name": "ash_bonus_rate_MT_S", "type": "text", "disabled": false,"label": "Ash Bonus Rate (MT) (S)"},
          { "name": "sulfur_penalty_rate_MT_S", "type": "text", "disabled": false,"label": "Sulfur Penalty Rate (MT) (S)"},
          { "name": "ash_penalty_rate_MT_S", "type": "text", "disabled": false,"label": "Ash Penalty Rate (MT) (S)"}

        ]

      },

      "Cost Sheet Actual": {

        "fieldsConfig": [

          { "name": "actual_pur_amount", "type": "text", "disabled": false,"label": "Actual Pur Amount"},
          { "name": "actual_freight_rate", "type": "text", "disabled": false,"label": "Actual Freight Rate"},
          { "name": "actual_freight_amount", "type": "text", "disabled": false,"label": "Actual Freight Amount"},
          { "name": "actual_insurance", "type": "text", "disabled": false,"label": "Actual Insurance"},
          { "name": "actual_commission", "type": "text", "disabled": false,"label": "Actual Commission"},

        ]

      },

      "Shipment Cost": {

        "fieldsConfig": [

          { "name": "actual_fixed_apportioned_overheads", "type": "text", "disabled": false,"label": "Actual Fixed Apportioned Overheads"},
          { "name": "final_total_cost", "type": "text", "disabled": false,"label": "Final Total Cost"},
          { "name": "actual_contract_specific_cost", "type": "text", "disabled": false,"label": "Actual Contract Specific Cost"},
          { "name": "actual_prov_for_corporate_tax", "type": "text", "disabled": false,"label": "Actual Prov For Corporate Tax"},
          { "name": "actual_withholding_tax", "type": "text", "disabled": false,"label": "Actual Withholding Tax"}

        ]

      },

      "Rwood Interenal Information": {

        "fieldsConfig": [

          { "name": "actual_fixed_apportioned_overheads", "type": "text", "disabled": false, "label": "Any Deviations" },
          { "name": "prepared_by", "type": "text", "disabled": false, "label": "Prepared By"},
          { "name": "check_and_approved_by", "type": "text", "disabled": false, "label": "Approved By"},
          { "name": "validated_by", "type": "text", "disabled": false, "label": "Check and Validated By"},
          { "name": "remarks", "type": "text", "disabled": false, "label": "Remarks"}

        ]

      }

    },

    "buyer_spot": {

      "Informations": {

        "fieldsConfig": [

          { "name": "tcf_no", "type": "text", "disabled": true, "label": "TCF no", "placeholder": "TCF no" },
          { "name": "locked", "type": "checkbox", "disabled": false, "label": "Locked" },
          { "name": "tcf_date", "type": "date", "disabled": false, "label": "TCF date" },
          { "name": "record_type", "type": "text", "disabled": true, "label": "Record Type" },
          { "name": "company", "api_field": "company_name", "type": "select", "disabled": false, "label": "Company", "options": rwoodCompanies },
          { "name": "opportunity",  "type": "text", "label": "Opportunity", "disabled": true, "placeholder": "Opportunity"},
          { "name": "currency", "api_field": "currency", "type": "select", "disabled": false, "label": "Currency", "options": currency },
          { "name": "quote", "type": "text", "disabled": false, "label": "Quote" },
          { "name": "exchange_rate", "type": "text", "disabled": false, "label": "Exchange Rate" },
          { "name": "trader_name", "api_field": "rwood_username", "type": "select","disabled": false, "label": "Trader Name", "options": rwoodUsers },
          { "name": "order","type": "text", "disabled": false, "label": "Order"},
          { "name": "owner","type": "text", "disabled": false, "label": "Owner"},
          { "name": "rwood_bank_master", "api_field": "rwood_bank_name", "type": "select", "disabled": false,"label": "Rwood Bank Master", "options": rwoodBanks },
          { "name": "auto_select_pricebook", "type": "checkbox", "disabled": false,"label": "Auto Select Pricebook" },
          { "name": "price_book", "api_field": "price_book_name", "disabled": false,"type": "select", "label": "Price Book", "options": priceBooks },
          { "name": "tcf_status", "api_field": null, "type": "select", "disabled": false, "label": "Tcf Status", "options": tcfStatus },
          { "name": "purchase_confirmation_date", "type": "date", "disabled": false, "label": "Purchase Confirmation Date" },
          { "name": "buyer_contract", "type": "text", "disabled": true, "label": "Buyer Contract" },
          { "name": "contract_shipment", "type": "text", "disabled": true, "label": "Contract Shipment" },
          { "name": "product_master", "api_field": "product_name", "disabled": false, "type": "select", "label": "Product Master", "options": productMaster },
          { "name": "product", "api_field": "product_name", "type": "select", "disabled": false, "label": "Product", "options": products },
          { "name": "product_name", "type": "text", "disabled": false, "label": "Product Name" },


        ]

      },

      "Shipment Summary": {

        "fieldsConfig": [

          { "name": "shipment_sales_price", "type": "text", "disabled": false,"label": "Shipment Sales Price" },

          { "name": "shipment_gross_margin_ton", "type": "text", "disabled": false, "label": "Shipment Gross Margin-Ton" },

        ]

      },

      "Trade Confirmation Details": {

        "fieldsConfig": [

          { "name": "buyer", "api_field": "account_name", "type": "select", "disabled": false,"label": "Buyer", "options": buyers },
          { "name": "status_of_purchase_contract", "api_field": null, "type": "select", "disabled": false,"label": "Status of Purchase Contract", "options": purchaseContract },
          { "name": "supplier", "api_field": "account_name", "type": "select", "disabled": false,"label": "Supplier", "options": suppliers },
          { "name": "proposed_laycan_from", "type": "date", "disabled": true,"label": "Proposed Laycan From" },
          { "name": "product_description", "type": "text", "disabled": false, "label": "Product Description" },
          { "name": "proposed_laycan_to", "type": "date", "disabled": true, "label": "Proposed Laycan To" },
          {"name": "product_specification", "type": "text", "disabled": false,"label": "Product Specification"},
          { "name": "quantity_MT", "type": "text", "disabled": false,"label": "Quantity (MT)"},
          { "name": "price_basis", "api_field": null, "type": "select", "disabled": false,"label": "Price Basis", "options": priceBasis },
          { "name": "vessel_type", "api_field": null, "type": "select", "disabled": false,"label": "Vessel Type", "options": vesselType },
          { "name": "payment_term", "type": "text", "disabled": false,"label": "Payment Term"},
          { "name": "selling_terms", "type": "text", "disabled": false,"label": "Selling Terms"},
          { "name": "buying_terms", "type": "text", "disabled": false, "label": "Buying Terms	"},
          {"name":"price_adjustment", "type": "text", "disabled": false,"label": "Price Adjustment"}

        ]

      },

      "Contractual": {

        "fieldsConfig": [

          { "name": "contractual_GAR_P", "type": "text", "disabled": false,"label": "Contractual GAR (P)"},
          { "name": "contractual_GAR_S", "type": "text", "disabled": false,"label": "Contractual GAR (S)"},
          { "name": "contractual_TM_AR_P", "type": "text", "disabled": false,"label": "Contractual TM (AR) (P)"},
          { "name": "contractual_TM_AR_S", "type": "text", "disabled": false,"label": "Contractual TM (AR) (S)"},
          { "name": "contractual_Ts_AR_p", "type": "text", "disabled": false,"label": "Contractual TS (AR) (P)"},
          { "name": "contractual_Ts_AR_s", "type": "text", "disabled": false,"label": "Contractual TS (AR) (S)"},
          { "name": "contractual_Ash_AR_P", "type": "text", "disabled": false,"label": "Contractual Ash (AR) (P)"},
          { "name": "contractual_Ash_AR_S", "type": "text", "disabled": false,"label": "Contractual Ash (AR) (S)"},
          { "name": "contractual_Ts_ADB_P", "type": "text","disabled": false,"label": "Contractual TS (ADB) (P)"},
          { "name": "contractual_Ts_ADB_S", "type": "text","disabled": false,"label": "Contractual TS (ADB) (S)"},
          { "name": "contractual_Ash_ADB_P", "type": "text","disabled": false,"label": "Contractual Ash (ADB) (P)" },
          { "name": "contractual_Ash_ADB_S", "type": "text","disabled": false,"label": "Contractual Ash (ADB) (S)" },
          { "name": "projected_price_basis_GAR_P", "type": "text", "disabled": false,"label": "Projected Price Basis GAR (P)"},
          { "name": "projected_price_basis_GAR_S", "type": "text", "disabled": false,"label": "Projected Price Basis GAR (S)"},
          {"name": "contractual_HBA_year", "api_field": null, "type": "select", "disabled": false,"label": "Contractual HBA Year", "options": hbaYear},
          { "name": "discounted_price", "type": "text", "disabled": false,"label": "Discounted Price"},
          {"name": "contractual_HBA_month", "api_field": null, "type": "select", "disabled": false,"label": "Contractual HBA Month", "options": hbaMonth},
          { "name": "projected_index_value", "type": "text", "disabled": false,"label": "Projected Index Value"},
          { "name": "projected_hba", "type": "text", "disabled": false,"label": "Projected HBA"},
          {"name": "projected_sale_price_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Projected Sale Price Type", "options": priceItems},
          {"name": "projected_purchase_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Projected Purchase Price Type", "options": priceItems},
          { "name": "projected_fixed_sales_price", "type": "text", "disabled": false,"label": "Projected Fixed Sales Price"},
          { "name": "projected_fixed_purchase_price", "type": "text", "disabled": false,"label": "Projected Fixed Purchase Price"},
          { "name": "projected_sales_price_margin", "type": "text", "disabled": false,"label": "Projected Sales Price Margin"},
          { "name": "projected_purchase_price_margin", "type": "text", "disabled": false,"label": "Projected Purchase Price Margin"},
          { "name": "projected_barge_discount", "type": "text", "disabled": false,"label": "Projected Barge Discount"}

        ]
      },

      "Cost Sheet Projection": {

        "fieldsConfig": [

          { "name": "projected_pur_amount", "type": "text", "disabled": false,"label": "Projected Pur Amount"},
          { "name": "projected_sales", "type": "text", "disabled": false,"label": "Projected Sales"},
          { "name": "projected_freight_rate", "type": "text", "disabled": false,"label": "Projected Freight Rate"},
          { "name": "projected_freight_amount", "type": "text", "disabled": false,"label": "Projected Freight Amount"},
          { "name": "projected_insurance", "type": "text", "disabled": false,"label": "Projected Insurance	"},
          { "name": "projected_commission", "type": "text", "disabled": false,"label": "Projected Commision"},
          { "name": "projected_fixed_apportioned_overheads", "type": "text", "disabled": false,"label": "Projected Fixed - Apportioned Overheads"},
          { "name": "projected_any_contract_specific_cost", "type": "text", "disabled": false,"label": "Projected Any Contract Specific Cost"},
          { "name": "projected_extra_cost", "type": "text", "disabled": false,"label": "Projected Extra Cost" },
          { "name": "projected_provision_for_corporate_tax", "type": "text", "disabled": false,"label": "Projected Provision for Corporate Tax"}

        ]

      },

      "Actual Shipment Details": {

        "fieldsConfig":[]

      },

      "Actual Cost Elements": {

        "fieldsConfig": [

          { "name": "purchase_price_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Purchase Price Type", "options": priceItems },
          { "name": "actual_data_rcvd", "type": "checkbox", "disabled": false,"label": "Actual Data Rcvd" },
          { "name": "fixed_purchase_price", "type": "text", "disabled": false,"label": "Fixed Purchase Price" },
          { "name": "sale_price_type", "api_field": "price_type_name", "type": "select", "disabled": false,"label": "Sale Price Type", "options": priceItems },
          { "name": "purchase_price_margin", "type": "text", "disabled": false,"label": "Purchase Price Margin" },
          { "name": "fixed_sale_price", "type": "text", "disabled": false,"label": "Fixed Sale Price" },
          { "name": "barge_discount", "type": "text", "disabled": false,"label": "Barge Discount" },
          { "name": "sale_price_margin", "type": "text", "disabled": false,"label": "Sales Price Margin"},
          { "name": "actual_gar_p", "type": "text", "disabled": false,"label": "Actual GAR (P)" },
          { "name": "actual_gar_s", "type": "text","disabled": false, "disabled": false,"label": "Actual GAR (S)"},
          { "name": "actual_tm_p", "type": "text", "disabled": false,"label": "Actual TM (AR) (P)" },
          { "name": "actual_tm_s", "type": "text", "disabled": false,"label": "Actual TM (AR) (S)" },
          { "name": "actual_ash_ar_p", "type": "text", "disabled": false,"label": "Actual Ash (AR) (P)" },
          { "name": "actual_ash_ar_s", "type": "text", "disabled": false,"label": "Actual Ash (AR) (S)" },
          {"name": "actual_ts_ar_p", "type": "text", "disabled": false,"label": "Actual TS (AR) (P)"},
          { "name": "actual_ts_ar_s", "type": "text", "disabled": false,"label": "Actual TS (AR) (S)"},
          { "name": "actual_ash_adb_p", "type": "text", "disabled": false,"label": "Actual Ash (ADB) (P)"},
          { "name": "actual_ash_adb_s", "type": "text", "disabled": false,"label": "Actual Ash (ADB) (S)"},
          { "name": "actual_ts_adb_p", "type": "text", "disabled": false,"label": "Actual TS (ADB) (P)"},
          { "name": "actual_ts_adb_s", "type": "text", "disabled": false,"label": "Actual TS (ADB) (S)"},
          { "name": "actual_aft_p", "type": "text", "disabled": false,"label": "Actual AFT (P)"},
          { "name": "actual_aft_s", "type": "text", "disabled": false,"label": "Actual AFT (S)"},
          { "name": "actual_vm_p", "type": "text", "disabled": false,"label": "Actual VM (P)"},
          { "name": "actual_vm_s", "type": "text", "disabled": false,"label": "Actual VM (S)"},
          { "name": "actual_hgi_p", "type": "text", "disabled": false,"label": "Actual HGI (P)"},
          { "name": "actual_hgi_s", "type": "text", "disabled": false,"label": "Actual HGI (S)"},
          { "name": "price_basis_gar_p", "type": "text", "disabled": false,"label": "Price Basis GAR (P)"},
          { "name": "price_basis_gar_s", "type": "text", "disabled": false,"label": "Price Basis GAR (S)"},
          { "name": "gar_p", "type": "text", "disabled": false,"label": "GAR (P)"},
          { "name": "gar_s", "type": "text", "disabled": false,"label":"GAR (S)"}

        ]
      },

      "Bonus / Penalty": {

        "fieldsConfig": [

          { "name": "sulfur_bonus_rate_MT_S", "type": "text", "disabled": false,"label": "Sulfur Bonus Rate (MT) (S)"},
          { "name": "ash_bonus_rate_MT_S", "type": "text", "disabled": false,"label": "Ash Bonus Rate (MT) (S)"},
          { "name": "sulfur_penalty_rate_MT_S", "type": "text", "disabled": false,"label": "Sulfur Penalty Rate (MT) (S)"},
          { "name": "ash_penalty_rate_MT_S", "type": "text", "disabled": false,"label": "Ash Penalty Rate (MT) (S)"}

        ]

      },

      "Cost Sheet Actual": {

        "fieldsConfig": [

          { "name": "actual_pur_amount", "type": "text", "disabled": false,"label": "Actual Pur Amount"},
          { "name": "actual_freight_rate", "type": "text", "disabled": false,"label": "Actual Freight Rate"},
          { "name": "actual_freight_amount", "type": "text", "disabled": false,"label": "Actual Freight Amount"},
          { "name": "actual_insurance", "type": "text", "disabled": false,"label": "Actual Insurance"},
          { "name": "actual_commission", "type": "text", "disabled": false,"label": "Actual Commission"},

        ]

      },

      "Shipment Cost": {

        "fieldsConfig": [

          { "name": "actual_fixed_apportioned_overheads", "type": "text", "disabled": false,"label": "Actual Fixed Apportioned Overheads"},
          { "name": "final_total_cost", "type": "text", "disabled": false,"label": "Final Total Cost"},
          { "name": "actual_contract_specific_cost", "type": "text", "disabled": false,"label": "Actual Contract Specific Cost"},
          { "name": "actual_prov_for_corporate_tax", "type": "text", "disabled": false,"label": "Actual Prov For Corporate Tax"},
          { "name": "actual_withholding_tax", "type": "text", "disabled": false,"label": "Actual Withholding Tax"}

        ]

      },

      "Rwood Interenal Information": {

        "fieldsConfig": [

          { "name": "actual_fixed_apportioned_overheads", "type": "text", "disabled": false, "label": "Any Deviations" },
          { "name": "prepared_by", "type": "text", "disabled": false, "label": "Prepared By"},
          { "name": "check_and_approved_by", "type": "text", "disabled": false, "label": "Approved By"},
          { "name": "validated_by", "type": "text", "disabled": false, "label": "Check and Validated By"},
          { "name": "remarks", "type": "text", "disabled": false, "label": "Remarks"}

        ]

      }

    }

  }

  // React Hook Form //

  const { register, handleSubmit, control, setValue, watch } = useForm({

    defaultValues: tradeConfirmData,

  });


  // Check Form Data //

  const formData = useWatch({ control });


  useEffect(() => {

    console.log(formData, 'formdATA')

  })


  // convert record_type_ string //

  const convertRecordTypeString = (str) => {

    return str.toLowerCase().replace(/[\s-]+/g, '_');

  };


  // Format Date Handler //

  const dateFormatHandler = (date) => {

    const parsedDate = parse(date, 'dd/MM/yyyy', new Date());

    const initialDate = isValid(parsedDate) ? parsedDate : null;

    return initialDate

  }

  // Save TradeConfirm Form Data //

  const submitTradeConfirmData = async (data) => {

    console.log(data, 'data123')

    const URL = `${API_URL}update/tcf/${tcfId}`

    const tradePostResponse = await axios.put(URL, data, {

      headers: {

        Authorization: `Bearer ${decryptedAccessToken}`,
        "Content-Type": "application/json",

      },

    })

    if (tradePostResponse.status === 200) {

      setTimeout(() => {

        navigate(`/tcf/tcf-details/${tcfId}`)

      }, 3000);


      toast.success('TCF Updated Successfully!', {

        autoClose: 2000,
        position: toast.POSITION.BOTTOM_CENTER,

      });

    } else {

      toast.error('An Unexpected Error Occurred While Updating TCF !',{

        autoClose: 2000,
        position: toast.POSITION.BOTTOM_CENTER,

      });

    }

  }

  // Fetch Trade Confirm Data's //

  const fetchTradeConfirmData = async () => {

    try {

      setisLoading(true);

      const URL = `${API_URL}get/tcfbyID/${tcfId}`;

      const fetchTcfResponse = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (fetchTcfResponse.status === 200) {

        const data = fetchTcfResponse?.data;

        setTradeConfirmData(data?.trade_data);

        // Update form values with fetched data

        Object.keys(data.trade_data).forEach((key) => {

          setValue(key, data.trade_data[key]);

        });
      }

  } catch (err) {

    setTimeout(() => {

      navigate(`/tcf/tcf-details/${tcfId}`)

    }, 3000);

    toast.info('Error fetching TCF. Redirecting in 2s...', {

      autoClose: 2000,
      position: toast.POSITION.BOTTOM_CENTER,

    });

    console.log('An Error Occured While Retriving Tcf ...')

  } finally {

    setisLoading(false)
  }

  };

  // Fetch Price Items //

  const fetchPriceItems = async () => {

    try {

      const URL = `${API_URL}get/all/price_items`;

      const priceItemsResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (priceItemsResponseData.status === 200) {

        const data = priceItemsResponseData?.data?.priceitem;

        if (data.length > 0) {

          setPriceItems(data);

        } else {

          setPriceItems([]);

        }

      }

    } catch (err) {

      console.log("An Error Occurred While Fetching PriceItems", err);

    }

  };

  // Fetching Rwood Bank Data's //

  const fetchRwoodBankDatas = async () => {

    try {

      const URL = `${API_URL}get/all/rwoodbank`;

      const rwoodBankResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (rwoodBankResponseData.status === 200) {

        const data = rwoodBankResponseData?.data?.rwood_bank;

        if (data.length > 0) {

          setRwoodbank(data);

        } else {

          setRwoodbank([]);

        }

      }

    } catch (err) {

      console.log("An Error occurred While Fetching Rwood Bank's", err);

    }

  };


  // Fetch Buyer Data's //

  const fetchBuyerDatas = async () => {

    try {

      const URL = `${API_URL}get/buyer`;

      const buyerResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (buyerResponseData.status === 200) {

        const data = buyerResponseData?.data;

        if (data.length > 0) {

          setBuyers(data);

        } else {

          setBuyers([]);

        }

      }

    } catch (err) {

      console.log("An Error occurred While Retriving Buyer", err);

    }

  };

  // Fetch Supplier Data's //

  const fetchSuppliersData = async () => {

    try {

      const URL = `${API_URL}get/supplier`;

      const supplierResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (supplierResponseData.status === 200) {

        const data = supplierResponseData?.data;

        setSuppliers(data);

      } else {

        setSuppliers([]);

      }

    } catch (err) {

      console.log("An Error Occurred While Fetching Supplier", err);

    }

  };

  // Fetch Trader Data's //

  const fetchRwoodTraderDatas = async () => {

    try {

      const URL = `${API_URL}get/all/rwoodusers`;

      const rwoodUserResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (rwoodUserResponseData.status === 200) {

        const data = rwoodUserResponseData?.data?.rwoodusers;

        if (data.length > 0) {

          setRwoodusers(data);

        } else {

          setRwoodusers([]);

        }

      }

    } catch (err) {

      console.log("An Error Occurred While Fetching Rwood Users", err);

    }

  };

  // Fetching Rwood Company Data's //

  const fetchRwoodCompanyDatas = async () => {

    try {

      const URL = `${API_URL}get/all/rwoodcompany`;

      const rwoodCompanyResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (rwoodCompanyResponseData.status === 200) {

        const data = rwoodCompanyResponseData?.data?.rwoodcompany;

        if (data.length > 0) {

          setRwoodCompanies(data);

        } else {

          setRwoodCompanies([]);

        }

      }

    } catch (err) {

      console.log(err, "An Error ccurred While Fetching TCF Compnay");

    }

  };


  // Fetch Pricebook Data //

  const fetchPriceBooksDatas = async () => {

    try {

      const URL = `${API_URL}get/all/pricebook`;

      const priceBookResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (priceBookResponseData.status === 200) {

        const data = priceBookResponseData?.data?.pricebook;

        if (data.length > 0) {
          setPriceBooks(data);

        } else {

          setPriceBooks([]);

        }

      }

    } catch (err) {

      console.log("An Error Occurred While Retriving Price Book", err);

    }

  };

  // Fetch Products Master Data //

  const fetchProductsData = async () => {

    try {

      const URL = `${API_URL}get/allproducts`;

      const productResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (productResponseData.status === 200) {

        const data = productResponseData?.data;

        if (data.length > 0) {

          setProductMaster(data);

        } else {

          setProductMaster([]);

        }

      }

    } catch (err) {

      console.error("An Error Occurred While Fetching Products ...");

    }

  };

  // Fetch Product Data //

  const fetchProductData = async () => {

    try {

      const URL = `${API_URL}/get/products_api`;

      const productResponseData = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      });

      if (productResponseData.status === 200) {

        const data = productResponseData?.data;

        setProduct(data);

      } else {

        setProduct([]);

      }

    } catch (err) {

      console.log("An Error Occurred While Fetching Product", err);

    }

  };


  // Handle Form Cancel Btn //

  const handleCancelBtn = () => {

    navigate(`/tcf/tcf-details/${tcfId}`)

  };





  // Retrive Trade Confirm Data //

  useEffect(() => {

    fetchTradeConfirmData();

  }, []);



  const fetchApi = (option) => {

    switch (option) {

      case "Company":

        fetchRwoodCompanyDatas();
        break;

      case "Buyer":

        fetchBuyerDatas();
        break;

      case "Supplier":

        fetchSuppliersData();
        break;

      case "Trader Name":

        fetchRwoodTraderDatas();
        break;

      case "Rwood Bank Master":

        fetchRwoodBankDatas();
        break;

      case "Price Book":

        fetchPriceBooksDatas();
        return;

      case "Product Master":

        fetchProductsData();
        break;

      case "Product":

        fetchProductData();
        break;

      case "Projected Sale Price Type":
      case "Projected Purchase Price Type":
      case "Sale Price Type":
      case "Purchase Price Type":

        fetchPriceItems();
        break;

      default:

        break;

    }

  }



  // Render Fields Dynamically //

  const renderField = (dataField) => {


    switch (dataField.type) {

      case "text":

        return (

          <Form.Group key={dataField.name} as={Col} md="6">

            <FloatingLabel controlId={`floatingInput-${dataField.name}`} label={dataField.label} className="mb-3">

              <Controller

                control={control}

                name={dataField.name}

                render={({ field: { onChange, onBlur, value, name } }) => (

                  <Form.Control

                    type="text"
                    placeholder={dataField.placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || undefined}
                    name={name}
                    disabled={dataField.disabled}

                  />

                )}

              />

            </FloatingLabel>

          </Form.Group>

        );

      case "checkbox":

        return (

          <Form.Group key={dataField.name} as={Col} md="6">

            <label>{dataField.label}</label>

            <Controller

              control={control}

              name={dataField.name}

              render={({ field: { onChange, onBlur, value, name } }) => (

                <input

                  type="checkbox"
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value || false}
                  name={name}
                  disabled={dataField.disabled}

                />

              )}

            />

          </Form.Group>

        );

      case "date":

        return (

          <Form.Group key={dataField.name} as={Col} md="6">

            <FloatingLabel controlId={`floatingInput-${dataField.name}`} label={dataField.label} className="mb-3">

              <Controller

                control={control}

                name={dataField.name}

                render={({ field: { onChange, value } }) => (

                  <DatePicker

                    selected={value ? dateFormatHandler(value) : undefined}
                    onChange={(date) => onChange(date ? format(date, 'dd/MM/yyyy') : undefined)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select"
                    disabled={dataField.disabled}

                  />

                )}

              />

            </FloatingLabel>

          </Form.Group>

        );

      case "select":

        return (

          <Form.Group key={dataField.name} as={Col} md="6">

            <Controller

              control={control}

              name={dataField.name}

              render={({ field }) => (

                <FloatingLabel controlId={`floatingSelect-${dataField.name}`} className="dropDown" label={dataField.label}>

                  <Form.Select

                    aria-label="Floating label select example"
                    name={`${field.label}`}
                    placeholder={`Select ${field.label}`}
                    disabled={dataField.disabled}

                    {...field}

                    onFocus={() => fetchApi(dataField.label)}
                  >
                    <option value={""}>--None--</option>

                    {field?.value && (

                      <option value={field?.key}>

                        {field?.value}

                      </option>

                    )}



                    {dataField.options.map((retrivedData) => {

                      return (

                        <>

                          {dataField.api_field ? (

                            <>

                            <option value={retrivedData[dataField.api_field]}>

                              {retrivedData[dataField.api_field]}

                            </option>

                            </>
                          ) : (

                            <>

                              {Object.entries(retrivedData).map(([key, value]) => (

                                <option key={key} value={key}>

                                  {key}

                                </option>

                              ))}

                            </>

                          )}

                        </>

                      );

                    })}

                  </Form.Select>

                </FloatingLabel>

              )}
            />

          </Form.Group>
        )

      default:

        return null;

    }

  };





  return (

    <div id="view-page">

      <Header />


      <div className="page-header" id="add-account">

        <div>

          <h2 className="main-content-title tx-24 mg-b-5">Trade Confirm</h2>

          <ol className="breadcrumb">

            <li className="breadcrumb-item active" aria-current="page">

              Dashboard / TCF / Edit

            </li>

          </ol>

        </div>

      </div>



      <div className="tasks column-gap fulid-section order-section">

        <div id="messageContainer"></div>

        <ToastContainer />

          {isLoading ? (

            <div className="rwood_spinner">

              <img src="../../../images/loader.svg" alt="spinner" />

            </div>

          ) : (

            tradeConfirmData && tradeConfirmData.record_type ? (

              <form onSubmit={handleSubmit(submitTradeConfirmData)}>

                <p className="edit-btn">

                  <button type="submit" className="btn btn-primary">

                    Save

                  </button>

                  <button className="btn btn-primary" onClick={handleCancelBtn}>

                    Cancel

                  </button>

                </p>

                {Object.entries(TradeConfirmFields["fields"]).map(([heading, { fieldsConfig }]) => (

                  <div key={heading}>

                    <Row className="mb-3">

                      <h4 className="heading">{heading}</h4>

                      {fieldsConfig.map(field => renderField(field))}

                    </Row>

                  </div>

                ))}

                <p className="edit-btn">

                  <button type="submit" className="btn btn-primary">

                    Save

                  </button>

                  <button className="btn btn-primary" onClick={handleCancelBtn}>

                    Cancel

                  </button>

                </p>

              </form>


            ) : (

              <p className="contract_data_status">{`An error occurred while rendering edit form.`}</p>

            )

          )}

        </div>

      <Footer />

    </div>

  )

}

export default TcfEdit