
// import React, {
//   Component,
//   useState,
//   useEffect,
//   useContext,
//   useRef,
//   useMemo
// } from "react";
// import {
//   Card,
//   Col,
//   OverlayTrigger,
//   Row,
//   Tooltip,
//   Table,
// } from "react-bootstrap";
// import { AiFillDiff } from "react-icons/ai";
// import "datatables.net-dt/js/dataTables.dataTables.js";
// import { AES, enc } from "crypto-js";
// import DataTable from "react-data-table-component";
// import Popup from "reactjs-popup";
// import { API_URL } from '../config';
// import "reactjs-popup/dist/index.css";
// import "datatables.net-responsive-dt";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import "react-data-table-component-extensions/dist/index.css";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { TokenContext } from "../AuthProvider";
// import $ from "jquery";
// import axios from "axios";
// import { BsFillEnvelopeFill } from "react-icons/bs";
// import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// // import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
// import { AiOutlineCloseCircle } from "react-icons/ai";

// import Header from "../Header";
// import Footer from "../Footer";
// import { set } from "date-fns";
// import { DropDownMenu } from "material-ui";
// // import FilterComponent from '../filterComponent';
// const FilterComponent = ({ onFilter, onClear, filterText }) => {
//   return (
//     <div>
//       <input
//         type="text"
//         placeholder="Search"
//         value={filterText}
//         onChange={onFilter}
//         className="table-search"
//       />
//       {/* <button onClick={onClear} ><AiOutlineCloseCircle /></button> */}
//     </div>
//   );
// };

// const PriceList = () => {
//   const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
//   const encryptedAccessToken = localStorage.getItem("access_token");
//   const decryptedAccessToken = encryptedAccessToken
//     ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
//     : null;


//   const navigate = useNavigate();
//   // const { selectedView, setSelectedViews, allTableData, setAllTableData, otherTableData, setOtherTableData } = useContext(TokenContext);
//   const [selectedView, setSelectedViews] = useState('');
//   const [allTableData, setAllTableData] = useState([]);
//   const [otherTableData, setOtherTableData] = useState([]);

//   const [dropdownOptions, setDropdownOptions] = useState([]);
//   const [showAllTableView, setShowAllTableView] = useState(selectedView === "all");
//   const [accountId, setAccountId]=useState([])
//   const [dropId, setDropId]=useState('')
//   const [randomDropdownValue, setRandomDropdownValue] = useState('recently_created');
//   const [keysAlldata,setKeys]=useState([])
//   const[allKeys, setAllkeys]=useState([])
//   const[ids,setID]=useState([])




//   useEffect(() => {
//     fetchDropdownOptions();
//   }, [decryptedAccessToken]);
//     const fetchDropdownOptions = async () => {

//       try {
//         const response = await axios.get(API_URL+'get/all/priceitem_createview');
//         const options =  response.data.priceitem_createview;
//         console.log(options)

//         setDropdownOptions(options);
//       } catch (error) {
//         console.error('Error fetching dropdown options:', error);
//         setDropdownOptions([]);
//       }
//     };




//   const deleteCreateview = (event) => {

//     event.preventDefault();

//     const isConfirmed = window.confirm("Are you sure you want to delete?");

//     if (!isConfirmed) {
//       // User canceled the deletion
//       return;
//     }

//     const encryptedAccessToken = localStorage.getItem('access_token');
//     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

//     if (encryptedAccessToken) {
//       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

//       fetch(`${API_URL}delete/account_create_view/${dropId}`, {

//         method: "DELETE",
//         headers: {
//           Authorization: `Bearer ${decryptedAccessToken}`,
//           'Content-Type': 'application/json'
//         },
//       })
//       .then((response) => {
//         if (response.ok) {
//           console.log('Deleted')
//           return response.json();
//         }
//       })
//       .then((response) => {
//         console.log(response);
//         fetchDropdownOptions()
//       })
//       .catch((error) => {
//         console.error('Error deleting:', error);
//         // Handle errors here
//       });
//     }

//     };




// const [filterItems, setFilteredItems]=useState([])
// const [columnsData, setColumnsData]=useState([])
// const [filterText, setFilterText] = useState('');
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (selectedView === 'all') {
//           const response = await axios.get(API_URL+'get/all/price_items?random_dropdown_option=recently_created',{
//             headers: { Authorization: `Bearer ${decryptedAccessToken}`,
//             'Content-Type': 'application/json' },
//           });
//           const data =  response.data.priceitem;
//           console.log(data)
//           setAllTableData(data);

// const columns = [

//   {
//       name: 'Price Code',
//       selector: (row, index) =>row.price_code,
//   },
//   {
//     name: 'Price Type Name',
//     selector: (row, index) => row.price_type_name

// },

// {
//   name: 'Price SR No',
//   selector: row => row.price_sr_no,
// },
//   // ... other columns
// ];


// const filteredItems = data.filter(item =>
//   Object.values(item).some(val =>
//       val && val.toString().toLowerCase().includes(filterText.toLowerCase())
//   )
// ).map(item => ({
//   price_code:<Link to={`/price/price-type-details/${item.id}`}>{item.price_code}</Link>,
//   price_type_name: item.price_type_name,
//   price_sr_no: item.price_sr_no,
//   // Add other properties as needed
// }));

//   setColumnsData(columns)
//   setFilteredItems(filteredItems)
// } else if (selectedView) {

//           const response = await axios.get(`${API_URL}get/priceitem/view/${selectedView}`);
//           const data = response.data.price_item; // Assuming the response con:tains the data for the selected view
//           setOtherTableData(data); // Assuming "accounts" is the array of data for the specific view
//           console.log(data)
//           const account_keys = Object.keys(data[0])
//           setAllkeys(account_keys);
//           setTimeout(() => {
//             $('.table').dataTable();
//           }, 50);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setAllTableData([]);
//         setOtherTableData([]);
//       }
//     };

//     fetchData();
//   }, [selectedView, filterText, decryptedAccessToken, setAllTableData, setOtherTableData, setColumnsData, setFilteredItems, setAllkeys, setAccountId]);


// // console.log(columnsData);

//   const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

//   useEffect(() => {
//     const storedView = localStorage.getItem('selectedView');

//     if (storedView && dropdownOptions.find(opt => opt.view_name === storedView)) {
//       setSelectedViews(storedView);
//       setShowAllTableView(storedView === 'all');
//     } else {
//       // If there's no stored view or it's not valid, set the default view to 'all'
//       setSelectedViews('all');
//       setShowAllTableView(true);
//       localStorage.setItem('selectedView', 'all');
//     }
//   }, [dropdownOptions])


//   const [formData, setFromdata] = useState({

//     account_record: "",

//   });


//   const handleChange = (e) => {

//     setFromdata({ ...formData, [e.target.name]: e.target.value });

//   };

//   const handleSubmit = (event) => {

//     event.preventDefault();


//     const encryptedAccessToken = localStorage.getItem('access_token');
//     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//     if (encryptedAccessToken) {
//       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

//     fetch(API_URL+'add/record', {
//               method: "POST",
//               body: JSON.stringify(formData),
//               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
//               'Content-Type': 'application/json' },
//             })
//               .then((response) => response.json())

//               .then((response) => {

//                   console.log(response)
//                   console.log(response.status)
//                   const account_record_new = response.account_type;

//                    navigate("/shippment/add-contract-shippment", {

//                     state: { account_record: account_record_new },

//                   });
//                 if (response.message === "record addedd successfully") {


//                                 }
//               })
//             }

//     };

//   const editView=()=>{
//         navigate('/pricebook/editview',{
//           state: { accountIds: accountId, pageID:dropId },
//         })
//       }


// const handleRandomDropdownChange = (event) => {
//   const selectedValue = event.target.value;
//   const encryptedAccessToken = localStorage.getItem('access_token');
//   const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//   if (encryptedAccessToken) {
//     const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
//     fetch(`${API_URL}get/all/price_items?random_dropdown_option=${selectedValue}`,{
//       headers: { Authorization: `Bearer ${decryptedAccessToken}`},

//     })
//       .then(response => response.json())
//       .then(data => {
// // console.log(data)
//         setAllTableData(data);
//       })
//       .catch(error => {
//         console.error('Error fetching data from backend:', error);
//         setAllTableData([]);
//       });

//     setRandomDropdownValue(selectedValue);}



//   };
// const [selectedOption, setSelectedOption] = useState('');

// const handleDropdownChange = (event) => {
//   const index = event.target.selectedIndex;
//   const el = event.target.childNodes[index];
//   const dropdownId = el.getAttribute('id');
//   console.log(dropdownId);
//   setDropId(dropdownId);
//   const viewName = event.target.value;
//   console.log(viewName);
//   setSelectedViews(viewName);
//   setShowAllTableView(viewName === 'all');
//   localStorage.setItem('selectedView', viewName);
//   setSelectedOption(event.target.value);


// };




//   const subHeaderComponentMemo = React.useMemo(() => {
//     const handleClear = () => {
//       if (filterText) {
//         setResetPaginationToggle(!resetPaginationToggle);
//         setFilterText('');
//       }
//     };
//     return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
//   }, [filterText, resetPaginationToggle]);


// // other table

// const columnsNew = Object.keys(otherTableData[0] || {}).map((key) => {
//   if (key === 'price_code') {
//     return {
//       name: key.replace(/_/g, " "),
//       selector: row => (
//         <Link to={`/price/price-type-details/${row.id}`}>
//           {row.price_code}
//         </Link>
//       ),
//     };
//   }
//   if (key === 'price_sr_no') {
//     return {
//       name: key.replace(/_/g, " "),
//       selector: row => (
//         <Link to={`/price/price-type-details/${row.id}`}>
//           {row.price_sr_no}
//         </Link>
//       ),
//     };
//   }

//   return key === 'id'
//     ? null  // Exclude the 'id' column
//     : {
//         name: key.replace(/_/g, " "),
//         selector: row => row[key],
//       };
// }).filter(columns => columns !== null);

// const [filterOther, setFilterOther] = useState('');
//   const [resetPaginationToggleOther, setResetPaginationToggleOther] = React.useState(false);
//   // const filteredItemsOther = otherTableData.filter(item => item.account_name && item.account_name.toLowerCase().includes(filterOther.toLowerCase()));


//   const filteredItemsOther = otherTableData.filter(item => {
//     // Check if any property in the item contains the filter text
//     for (const key in item) {
//       if (item[key] && item[key].toString().toLowerCase().includes(filterOther.toLowerCase())) {
//         return true;
//       }
//     }
//     return false;


//   });

//   const subHeaderComponentMemoOther = React.useMemo(() => {
//     const handleClear = () => {
//       if (setResetPaginationToggleOther) {
//         setResetPaginationToggleOther(!resetPaginationToggleOther);
//         setFilterOther('');
//       }
//     };
//     return <FilterComponent onFilter={e => setFilterOther(e.target.value)} onClear={handleClear} filterOther={filterOther} />;
//   }, [filterOther, resetPaginationToggleOther]);

// //  Recent Items

// const [recentItems, setRecentItems]=useState([])
// const [loading, setLoading] = useState(true);

// const fetchRecent = async () => {
//   try {
//     const response = await axios.get(API_URL+'get/recent_items');
//     console.log(response)
//     if(response.status===200){
//       setRecentItems(response.data.recent_items)
//     }

//   } catch (error) {
//     console.error(error);
//   }
//   finally{
//     setLoading(false);
//   }
// };
// useEffect(() => {

//   fetchRecent();
// }, []);





//   return (
//     <>
//       <Header />
//       <div className="contactAccount full-container" >
//         <div className="row">
//         <div className="col-md-2 recent-item">
//           <h4>Recent Items</h4>
//           {loading ? (
//         <p className="loading-text">Loading...</p>
//       ) : (
//           <div>
//             {recentItems.map((x,i)=>(
//               <p><Link to={x.url}>{x.code}</Link></p>
//             ))}
//           </div>
//       )}
// </div>
//         <div className="col-md-10">
//         <div className="page-header" id="add-account">
//           <div>
//             <h2 className="main-content-title tx-24 mg-b-5">Price Type </h2>
//             <ol className="breadcrumb">
//               <li className="breadcrumb-item active" aria-current="page">
//                 Dashboard / Price Type
//               </li>
//             </ol>
//           </div>
//           <div className="d-flex">
//             <div className="justify-content-center">
//              <button type="button" className="btn btn-primary my-2 btn-icon-text">
//          <Link to='/price/add-price-type'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Price Type</Link>
//         </button>
// </div>
//           </div>
//         </div>
//         <div className="accounts-page">
//           <div className="row">
//             <div className="col-md-6">
//               <form  className="d-flex dropdown-form">

//                 <label for="pwd" className="mr-sm-2">
//                   View
//                 </label>
//                 <select
//   onChange={handleDropdownChange}
//   className='form-control view-select'
//   value={selectedView}
//   defaultValue="all">
//         <option value="">Select a View</option>
//         <option value="all" >All Views</option>
//         {dropdownOptions.length>0 ?(
//           <>
//         {dropdownOptions.map((option, index) => (
//           <option key={index} id={option.id} value={option.view_name}>
//             {option.view_name}
//           </option>
//         ))}
//         </>
//         ):(<option className="no-data">No data available</option>)}
//       </select>



//                 {/* <input
//                   type="submit"
//                   class="btn btn-primary mb-2"
//                   value="GO"
//                 /> */}
//               </form>
//             </div>
//             <div className="col-md-6 links">
//               <p>
//                <span onClick={editView}>Edit</span> |{selectedOption && (
//        <span onClick={deleteCreateview}>Delete | </span>
//       )} <Link to="/price-type/create-price-type">Create New View</Link>
//               </p>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-md-12">
//             <h6 className="d-flex justify-content-between align-items-center">
//             <span>Recent Accounts</span>

//             {selectedView === 'all' && allTableData && (
//           <div className="random- d-flex">
//             <select
//          dropdowns     className='form-control'
//               onChange={handleRandomDropdownChange}
//               value={randomDropdownValue}
//               defaultValue="recently_created"

//             >
//               <option value="recently_created">Recently Created</option>
//               <option value="recently_modified">Recently Modified</option>
//               <option value="recently_viewed">Recently Viewed</option>
//             </select>
//           </div>
//         )}
//           </h6>
//           <div className="create-view-table">

//               {showAllTableView  && (


//                 <DataTable  columns={columnsData} data={filterItems} pagination  // optionally, a hook to reset pagination to page 1
//   subHeader subHeaderComponent={subHeaderComponentMemo} fixedHeader selectableRows persistTableHead />
//               )}
//                  {!showAllTableView && selectedView && (
//         <div>
//           {otherTableData.length > 0 ?(

// <DataTable  columns={columnsNew.map((column) => ({
//   name: column.name.replace(/_/g, " "),
//   selector: column.selector,
//   // Add other properties as needed
// }))} data={filteredItemsOther} fixedHeader pagination  // optionally, a hook to reset pagination to page 1
// subHeader subHeaderComponent={subHeaderComponentMemoOther}
// fixedHeaderScrollHeight="500px"   selectableRows persistTableHead />



//               ):(
//                 <table class="table table-hover table-bordered create-table">

//                   <tr>
//                     <th><b>Account Code</b></th>
//                     <th><b>Account Name</b></th>
//                     <th><b>Account Alias</b></th>
//                     <th><b>Account Record Type</b></th>
//                   </tr>



//                    <tr>
//                     <td align='center' colSpan={4}> No Data Available</td>
//                     </tr>


//               </table>
//                   )}
//         </div>
//       )}</div>
//             </div>
//           </div>
//           {/* <div className="row">
//             <div className="col-md-6">
//             <div className="bg-info">
//               <h6>
//                 <span className="pull-left">Reports</span>
//               </h6>
//               <div className="account-reports">
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//               </div>
//             </div>
//             </div>
//            <div className="col-md-6">
//             <div className="bg-info">
//               <h6>
//                 <span className="pull-left">Tools</span>
//               </h6>
//               <div className="account-reports">
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//                 <p>A & A Commodities</p>
//               </div>
//             </div>
//             </div>

//           </div> */}
//         </div>
//         </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default PriceList;




import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";

import axios from "axios";

import { AES, enc } from "crypto-js";
import { API_URL } from "../config";
import "./price.css";

import { Link, useNavigate } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiFillDiff } from "react-icons/ai";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import RecentItems from "../recentItems/RecentItems";

const PriceList = () => {
  const [isLoading, setLoading] = useState(false);
  const [priceTypeviews, setpriceTypeviews] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [selectedView, setSelectedView] = useState("all");
  const [selectedViewId, setSelectedViewId] = useState("");

  const [requestDatatype, setRequestDataType] = useState("recentmodifier");

  const [allTableData, setAllTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [columns, setColumns] = useState([]);

  const [modifiedEntriesLabel, setModifiedEntriesLabel] =
    useState("recently_created");

  const navigate = useNavigate();

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  // Function To Handle Recent Modifiers //

  const handleRandomDropdownChange = (e) => {
    const modifiedEntry = e.target.value;

    setModifiedEntriesLabel(modifiedEntry);

    setRequestDataType("recentmodifier");
  };

  // Function To Handle Page Change //

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Function To Handle Rows Per Change //

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




  const [excludedFields, setExcludedFields] = useState([
    "id",



  ]);
  const [linkFields, setLinkFields] = useState({
    price_code: { id: "id", link: "/price/price-type-details/" },



  });

  const renderTableRowsData = (data, type) => {
    switch (type) {
      case "headers":
        return data.map((col, index) => {
          if (!excludedFields.includes(col.field)) {
            return (
              <TableCell sx={{ width: 200 }} key={index}>
                {col.headerName}
              </TableCell>
            );
          }
          return null;
        });

      case "rows":
        return data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {columns.map((col, colIndex) => {
              if (!excludedFields.includes(col.field)) {
                return (
                  <TableCell sx={{ width: 200 }} key={colIndex}>
                    {linkFields.hasOwnProperty(col.field) ? (
                      <Link
                        to={`${linkFields[col.field].link}${
                          row[linkFields[col.field].id]
                        }`}
                      >
                        {row[col.field]}
                      </Link>
                    ) : (
                      row[col.field]
                    )}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        ));

      default:
        return null;
    }
  };


  // Formatting Table Header Data //

  const formatHeaderName = (key) => {
    const replaced = key.replace(/_/g, " ").toLowerCase();
    return replaced.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getColumnsData = (data) => {
    //desired order

   const desiredOrder = [

    "price_code",
    "price_type_name",
    "price_description",
    "price_sr_no",
];


    // Get the keys from the data
    const dataKeys = Object.keys(data[0] || {});

    const columnsData = desiredOrder

      .filter((key) => dataKeys.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    const remainingColumns = dataKeys

      .filter((key) => !desiredOrder.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    return [...columnsData, ...remainingColumns];
  };

  // Function To Fetch Created Views //
  const fetchPriceViews = async () => {
    try {
      setLoading(true);

      const createViewResponse = await axios.get(
        `${API_URL}get/all/priceitem_createview`
      );

      if (createViewResponse.status === 200) {
        const viewData = createViewResponse.data.priceitem_createview;

        console.log(viewData, "viewData");
        setpriceTypeviews(viewData);

        setLoading(false);

        setIsOptionsLoaded(true);
      }
    } catch (err) {
      console.log("An Error Occurred", err);
    }
  };



  const handleViewChange = (e) => {
    const { value, selectedIndex } = e.target;

    const selectedOptionId = e.target.options[selectedIndex].id;

    setSelectedViewId(selectedOptionId);

    setSelectedView(value);

    setRequestDataType("viewmodifier");

    sessionStorage.setItem("priceTypesView", value);
    sessionStorage.setItem("priceTypesViewId", selectedOptionId);
  };




  useEffect(() => {

    fetchPriceViews()

    const priceTypesViewName = sessionStorage.getItem("priceTypesView");

    const priceTypesViewNameId = sessionStorage.getItem("priceTypesViewId");

    const priceTypesViewEdit = sessionStorage.getItem("priceTypesViewEdit");
    const ispriceTypesView = sessionStorage.getItem("ispriceTypesView")

    setSelectedView(priceTypesViewName)

    setSelectedViewId(priceTypesViewNameId)

    if (priceTypesViewEdit && priceTypesViewEdit === "true" && priceTypesViewName && priceTypesViewNameId) {

      setRequestDataType("viewmodifier")

    } else if (ispriceTypesView && ispriceTypesView === "true" && priceTypesViewName && priceTypesViewNameId) {

      setRequestDataType("viewmodifier")

    } else {

      setRequestDataType("recentmodifier")

      setModifiedEntriesLabel("recently_created")

    }

    sessionStorage.removeItem("priceTypesViewEdit");
    sessionStorage.removeItem("ispriceTypesView")

  }, [])
  // Fetching View Based Data //

  const fetchViewData = async () => {
    try {
      setLoading(true);

      const URL = `${API_URL}get/priceitem/view`;

      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          page: page + 1,
          page_size: rowsPerPage,
          view_name: selectedView,
        },
      });

      if (response.status === 200) {
        const data = response.data.priceitem || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total || 0);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Retriving View Data", err);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getModifiedEntries = async () => {
    setLoading(true);

    const URL = `${API_URL}get/all/price_items/new`;

    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          random_dropdown_option: modifiedEntriesLabel,
          page: page + 1,
          page_size: rowsPerPage,
        },
      });

      if (response.status === 200) {
        const data = response.data.priceitem || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Getting Modified Entries");
    } finally {
      setLoading(false);
    }
  };

  const navigateViewEdit = (e) => {
    e.preventDefault();

    sessionStorage.setItem("priceTypesViewEdit", true);

    // /price-type/edit-view/:id
    navigate(`/price-type/edit-view/${selectedViewId}`);
  };

  const navigateCreateView = (e) => {
    e.preventDefault();

    sessionStorage.setItem("priceTypesViewEdit", true);

    navigate(`/price-type/create-price-type`);
  };

  const navigateViewDelete = () => {};

  //  set page to 0 whenever request data type changes //
  useEffect(() => {
    setPage(0);
  }, [requestDatatype]);

  useEffect(() => {
    requestDatatype === "recentmodifier" && getModifiedEntries();
  }, [modifiedEntriesLabel, page, rowsPerPage]);

  // Fetch Selected View //

  useEffect(() => {
    requestDatatype === "viewmodifier" && fetchViewData();
  }, [selectedView, page, rowsPerPage]);

  //// accounts add

  ///// account end

  return (
    <>
      <Header />

      <div className="dashboard-container">
        <div className="recent-items">
          <h2>Recent Items</h2>

         <RecentItems/>
        </div>

        <div className="accounts">
          <div className="accounts-header">
            <h2>PriceTypes</h2>

            <div className="header-actions"></div>
          </div>

          <div className="accounts-content">
            <div className="view-controls">
              <div className="view_label">
                <a>View:</a>
              </div>

              <div className="select-container">
                <select value={selectedView} onChange={handleViewChange}>
                  <option>Select a view</option>

                  {priceTypeviews.length > 0 ? (
                    priceTypeviews.map((option) => (
                      <option
                        key={option.id}
                        id={option.id}
                        value={option.unique_name}
                      >
                        {option.view_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Data Available</option>
                  )}
                </select>
              </div>

              <div className="button-container">
                <button onClick={fetchViewData}>Go</button>
              </div>

              <div className="view_actions">
                <span className="rwood_view_edit" onClick={navigateViewEdit}>
                  Edit
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateViewDelete}>
                  Delete
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateCreateView}>
                  Create New View
                </span>
              </div>
            </div>

            <div className="recent-accounts">
              <div className="recemt_contract_content">
                <div className="account-search">
                  <p>Recent PriceTypes</p>

                  <div className="select-modifier-container">
                    <select onChange={handleRandomDropdownChange}>
                      <option value="recently_created">Recently Created</option>
                      <option value="recently_modified">
                        Recently Modified
                      </option>
                      <option value="recently_viewed">Recently Viewed</option>
                    </select>
                  </div>
                </div>

                <div className="data_table">

                {!isLoading ? (
                    allTableData.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ tableLayout: "fixed", width: "100%" }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead sx={{ background: "#f7f7f7" }}>
                            <TableRow>
                              {renderTableRowsData(columns, "headers")}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {renderTableRowsData(allTableData, "rows")}
                          </TableBody>
                        </Table>

                        <TablePagination
                          component="div"
                          count={totalRecords}
                          page={page}
                          onPageChange={handlePageChange}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleRowsPerPageChange}
                        />
                      </TableContainer>
                    ) : (
                      <div className="contract_data_status">
                        <h3>No Data Available</h3>
                      </div>
                    )
                  ) : (
                    <div className="rwood_spinner">
                      <img src="../../../images/loader.svg" alt="spinner" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PriceList;
