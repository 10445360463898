import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { AiFillDiff } from "react-icons/ai";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const ProductMaster = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  $(".addAccount").css({"min-height":(window_height)+"px"})
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);



  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/allproducts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        $('#tables').DataTable();

       })
    } catch (error) {
      console.error(error);
    }
  };


  return (
<>
<Header/>
<div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">All Product Master </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / All Product Master</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to='/product-master/add'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Product Master</Link>
        </button>
     </div>
    </div>
  </div>
  <div class='tasks'>
  <table id='tables'>
    <thead>
    <tr>
      <th>Product Code</th>
      <th>Product Account</th>
      <th>Product Description</th>
      <th>Product Family</th>
      <th>Product Name</th>
      <th>Product Price Type</th>
      <th>Product Supplier</th>
      <th>Quotes Name</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>

    <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
      <td><Link to={"/productmaster/product-detail/"+x.id }>{x.product_code}</Link></td>
      <td>{x.product_account}</td>
      <td>{x.product_description}</td>
      <td>{x.product_family}</td>
      <td>{x.product_name}</td>
      <td>{x.product_price_type}</td>
      <td>{x.product_supplier}</td>
      <td>{x.quotes_name}</td>
      <td><Link to={"/productmaster/product-detail/"+x.id }><AiFillDiff/></Link> <FaTrashAlt/></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>

    </tbody>
    </table>
    </div> </div>
    <Footer/></>
  );
};

export default ProductMaster;