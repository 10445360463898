//get/all/accountcreateview
//get/account/view
//all/accounts
///accounts/edit-view/
///accounts/create-new-view

import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { AES, enc } from "crypto-js";
import { API_URL } from "../config";
import "./accounts.css";

import { Link, useNavigate } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiFillDiff } from "react-icons/ai";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import RecentItems from "../recentItems/RecentItems";


const AccountsList = () => {
  const [isLoading, setLoading] = useState(false);
  const [accountViews, setAccountViews] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [selectedView, setSelectedView] = useState("all");
  const [selectedViewId, setSelectedViewId] = useState("");

  const [requestDatatype, setRequestDataType] = useState("recentmodifier");

  const [allTableData, setAllTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [columns, setColumns] = useState([]);

  const [modifiedEntriesLabel, setModifiedEntriesLabel] =
    useState("recently_created");

  const navigate = useNavigate();

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  // Function To Handle Recent Modifiers //

  const handleRandomDropdownChange = (e) => {
    const modifiedEntry = e.target.value;

    setModifiedEntriesLabel(modifiedEntry);

    setRequestDataType("recentmodifier");
  };

  // Function To Handle Page Change //

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Function To Handle Rows Per Change //

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Formatting Table Header Data //

  const formatHeaderName = (key) => {
    const replaced = key.replace(/_/g, " ").toLowerCase();
    return replaced.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getColumnsData = (data) => {
    //desired order

    const desiredOrder = [
      "account_record_type",
      "account_name",
      "account_alias",
      "billing_country",
      "website",
      "created_at",
      "KYC_doc_date",
      "KYC_docs",
    ];

    // Get the keys from the data
    const dataKeys = Object.keys(data[0] || {});

    const columnsData = desiredOrder

      .filter((key) => dataKeys.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    const remainingColumns = dataKeys

      .filter((key) => !desiredOrder.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    return [...columnsData, ...remainingColumns];
  };

  // Function To Fetch Created Views //
  const fetchContractViews = async () => {
    try {
      setLoading(true);

      const createViewResponse = await axios.get(
        `${API_URL}get/all/accountcreateview`
      );

      if (createViewResponse.status === 200) {
        const viewData = createViewResponse.data.account_create_view;

        console.log(viewData, "viewData");
        setAccountViews(viewData);

        setLoading(false);

        setIsOptionsLoaded(true);
      }
    } catch (err) {
      console.log("An Error Occurred", err);
    }
  };

  // const handleViewFocus = () => {

  //   !isOptionsLoaded && fetchContractViews();

  // };

  const handleViewChange = (e) => {

    const { value, selectedIndex } = e.target;

    const selectedOptionId = e.target.options[selectedIndex].id;

    setSelectedViewId(selectedOptionId);

    setSelectedView(value);

    setRequestDataType("viewmodifier");

    sessionStorage.setItem("accountView", value);
    sessionStorage.setItem("accountViewId", selectedOptionId);

  };

  // On Component Load Set View To Previous //

  // useEffect(() => {

  //   fetchContractViews();

  //   const AccountviewName = sessionStorage.getItem("accountView");

  //   const AccountviewNameId = sessionStorage.getItem("accountViewId");

  //   const AccountviewEdit = sessionStorage.getItem("AccountviewEdit");

  //   setSelectedView(AccountviewName);

  //   setSelectedViewId(AccountviewNameId);

  //   AccountviewEdit === "true" && setRequestDataType("viewmodifier");

  //   sessionStorage.removeItem("AccountviewEdit");


  // }, []);




  useEffect(() => {

    fetchContractViews()

    const AccountviewName = sessionStorage.getItem("accountView")

    const AccountviewNameId = sessionStorage.getItem("accountViewId")

    const AccountviewEdit = sessionStorage.getItem("AccountviewEdit")

    const isAccountCreateView = sessionStorage.getItem("isAccountCreateView")

    setSelectedView(AccountviewName)

    setSelectedViewId(AccountviewNameId)

    if (AccountviewEdit && AccountviewEdit === "true" && AccountviewName && AccountviewNameId) {

      setRequestDataType("viewmodifier")

    } else if (isAccountCreateView && isAccountCreateView === "true" && AccountviewName && AccountviewNameId) {

      setRequestDataType("viewmodifier")

    } else {

      setRequestDataType("recentmodifier")

      setModifiedEntriesLabel("recently_created")

    }

    sessionStorage.removeItem("AccountviewEdit");
    sessionStorage.removeItem("isAccountCreateView")

  }, [])










  // Fetching View Based Data //

  const fetchViewData = async () => {

    try {

      setLoading(true);

      const URL = `${API_URL}get/account/view`;

      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          page: page + 1,
          page_size: rowsPerPage,
          view_name: selectedView,
        },
      });

      if (response.status === 200) {
        const data = response.data.accounts || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total || 0);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Retriving View Data", err);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getModifiedEntries = async () => {

    setLoading(true);

    const URL = `${API_URL}all/accounts/new`;

    try {
      const response = await axios.get(URL, {

        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          random_dropdown_option: modifiedEntriesLabel,
          page: page + 1,
          page_size: rowsPerPage,
        },

      });

      if (response.status === 200) {
        const data = response.data.accounts || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Getting Modified Entries");
    } finally {
      setLoading(false);
    }
  };

  const navigateViewEdit = (e) => {

    e.preventDefault();

    sessionStorage.setItem("AccountviewEdit", true);

    navigate(`/accounts/edit-view/${selectedViewId}`);
  };

  const navigateCreateView = (e) => {
    e.preventDefault();

    sessionStorage.setItem("AccountviewEdit", true);

    navigate(`/accounts/create-new-view`);
  };

  const navigateViewDelete = () => {};

  //  set page to 0 whenever request data type changes //
  useEffect(() => {

    setPage(0);

  }, [requestDatatype]);

  useEffect(() => {

    requestDatatype === "recentmodifier" && getModifiedEntries();

  }, [modifiedEntriesLabel, page, rowsPerPage]);

  // Fetch Selected View //

  useEffect(() => {

    requestDatatype === "viewmodifier" && fetchViewData();

  }, [selectedView, page, rowsPerPage]);

  //// accounts add
  const [formData, setFormData] = useState({

    account_record: "",

  });

  const [error, setError] = useState({});

  const validate = () => {

    const newErrors = {};
    if (!formData.account_record) {
      newErrors.account_record = "Account Record Type is required";
    }
    return newErrors;

  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/record`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            console.log(response.status);
            const account_record_new = response.account_type;

            navigate("/accounts/new-accounts", {
              state: { account_record: account_record_new },
            });
            if (response.message === "record addedd successfully") {
            }
          });
      }
    }
  };

  ///// account end

  return (
    <>
      <Header />

      <div className="dashboard-container">
        <div className="recent-items">
          <h2>Recent Items</h2>

         <RecentItems/>
        </div>

        <div className="accounts">
          <div className="accounts-header">
            <h2>Accounts</h2>

            <div className="header-actions">
              <Popup
                trigger={
                  <button className="add-account-btn">+ Add Account</button>
                }
                modal
              >
                <form onSubmit={handleSubmit} className="add-accounts">
                  <div className="form-group">
                    <label>Account Record Type</label>
                    <br />
                    <select
                      name="account_record"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.account_record}
                    >
                      <option value="">--Select account record type--</option>
                      <option value="Agent">Agent</option>
                      <option value="Bank">Bank</option>
                      <option value="Buyer">Buyer</option>
                      <option value="Supplier">Supplier</option>
                      <option value="Competitor">Competitor</option>
                      <option value="Employee">Employee</option>
                      <option value="Surveyor">Surveyor</option>
                    </select>
                    {error.account_record && (
                      <div className="errors-msg">{error.account_record}</div>
                    )}
                  </div>
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Submit"
                  />
                </form>
              </Popup>
            </div>
          </div>

          <div className="accounts-content">
            <div className="view-controls">
              <div className="view_label">
                <a>View:</a>
              </div>

              <div className="select-container">
                <select value={selectedView} onChange={handleViewChange}>
                  <option>Select a view</option>

                  {accountViews.length > 0 ? (
                    accountViews.map((option) => (
                      <option
                        key={option.id}
                        id={option.id}
                        value={option.unique_name}
                      >
                        {option.view_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Data Available</option>
                  )}
                </select>
              </div>

              <div className="button-container">
                <button onClick={fetchViewData}>Go</button>
              </div>

              <div className="view_actions">
                <span className="rwood_view_edit" onClick={navigateViewEdit}>
                  Edit
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateViewDelete}>
                  Delete
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateCreateView}>
                  Create New View
                </span>
              </div>
            </div>

            <div className="recent-accounts">
              <div className="recemt_contract_content">
                <div className="account-search">
                  <p>Recent Accounts</p>

                  <div className="select-modifier-container">
                    <select onChange={handleRandomDropdownChange}>
                      <option value="recently_created">Recently Created</option>
                      <option value="recently_modified">
                        Recently Modified
                      </option>
                      <option value="recently_viewed">Recently Viewed</option>
                    </select>
                  </div>
                </div>

                <div className="data_table">

                  {!isLoading ? (
                    allTableData.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ tableLayout: "fixed", width: "100%" }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead sx={{ background: "#f7f7f7" }}>
                            <TableRow>
                              {columns.map(
                                (col, index) =>
                                  col.field !== "id" && (
                                    <TableCell sx={{ width: 200 }} key={index}>
                                      {col.headerName}
                                    </TableCell>
                                  )
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allTableData.map((row, rowIndex) => (
                              <TableRow key={rowIndex}>
                                {columns.map(
                                  (col, colIndex) =>
                                    col.field !== "id" && (
                                      <TableCell
                                        sx={{ width: 200 }}
                                        key={colIndex}
                                      >
                                        {col.field === "account_name" ? (
                                          <Link to={`/accounts/${row.id}`}>
                                            {row[col.field]}
                                          </Link>
                                        ) : (
                                          row[col.field]
                                        )}
                                      </TableCell>
                                    )
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                        <TablePagination
                          component="div"
                          count={totalRecords}
                          page={page}
                          onPageChange={handlePageChange}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleRowsPerPageChange}
                        />
                      </TableContainer>
                    ) : (
                      <div className="rwood_spinner">
                      <img src="../../../images/loader.svg" alt="spinner" />
                    </div>
                    )
                  ) : (
                    <div className="rwood_spinner">
                      <img src="../../../images/loader.svg" alt="spinner" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AccountsList;
