import React, { Component } from 'react'
import Accounts from './accounts'
import Footer from './Footer'
import Headerfinanace from './headerfinanace' 
function DashboardFinance() {
   
  
    return (
      <div>
    <Headerfinanace/>
    <div className="dashboard">
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / All</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div> 
        <div id='dashboard' className='container-fluid'>
            <div className='row'>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Reports
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Accounts
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Contacts
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Products
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Accounts
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Price Type
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Opportunities
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        TCF
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Contracts
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Opp Shippments
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Orders
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Compaigns
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='bg-info'>
                        Product Master
                    </div>
                </div>
            </div>
         </div>
    <Footer/>
      </div>
    );
  
};

export default DashboardFinance;