
import React, { useEffect, useState } from "react";
import axios from "axios";
import { AES, enc } from "crypto-js";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import $ from "jquery";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { useParams  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Header from '../Header'
import { API_URL } from '../config';
import Footer from '../Footer'
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const AddQuotes = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const params = useParams()
  const id=params.id
  const [productCode, setProductCode] = useState("");
  const [productValues, setProductValues] = useState([]);
  const [products, setproducts] = useState([]);
  const [shippment, setShippment]=useState([]);

  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/quoteshipment`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;

      setShippment(data)
      console.log(data)

    } catch (error) {
      console.error(error);
    }
  };
  const [formData, setFormData] = useState({
    product_code: "",
    product_name: "",
        product_account: "",
        product_supplier: "",
        product_description: "",
        product_family: "",
        product_shipping_term:"",
        product_owner:"",
        product_active: "",
        short_term_available: "",
        product_price_basis: "",
        product_price_type: "",
        product_port_of_loading: "",
    locked: true,
    gross_air_dried_typical: "",
        gross_air_dried_min:"",
        gross_air_dried_max: "",
        gross_as_recieved_min: "",
        gross_as_recieved_max: "",
        net_as_recieved_min: "",
        net_as_recieved_max:"",
        moisture_typical: "",
      moisture_minimum: "",
      ash_typical: "",
      ash_minimum: "",
      ash_maximum: "",
      quote_shipment: "",
      volatile_matter_typical: "",
      volatile_matter_minimum:"",
      volatile_matter_maximum: "",
      fixed_carbon_by_difference: "",
      fixed_carbon_typical: "",
      fixed_carbon_minimum: "",
      fixed_carbon_maximum: "",
      carbon_typical: "",
      carbon_min:"",
      carbon_max: "",
      hydrogen_typical: "",
      hydrogen_min: "",
      hydrogen_max: "",
      nitrogen_typical:"",
      nitrogen_min: "",
      nitrogen_max: "",
      sulphur_typical: "",
      sulphur_min: "",
      sulphur_max_ultimate_analysis: "",
      oxygen_typical: "",
      oxygen_min: "",
      oxygen_max: "",
      sulphur_typical_arb:"",
  });

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    console.log(event.target);
    const newValue = type === "checkbox" ? checked : value;
    console.log(newValue);
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "locked" ? Boolean(newValue) : newValue,
    }));
  };

  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);
          const response = await axios.get(
            `${API_URL}get/products_api`,
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );
          setproducts(response.data);

        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProductCodes();
    quotes_names();
  }, []);

  const getProductValues = async (productCode) => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}all/product/datas/product_code/${productCode}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );

        setProductValues(response.data);

        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [quoteShipment_name, setquoteShipment_name] = useState([]);

  const quotes_names = async () => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}get/all/quoteshipment`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );

        setquoteShipment_name(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
// const [tcfData, setTcfData] = useState({
//       company: "",
//     });
  const handleProductCodeChange = async (event) => {
    setProductCode(event.target.value);
    try {
      const productValues = await getProductValues(event.target.value);
      console.log(productValues);

      setFormData({
        ...formData,
        product_code: event.target.value,
        product_name: productValues.product_name,
        product_account: productValues.product_account,
        product_supplier: productValues.product_supplier,
        product_description: productValues.product_description,
        product_family: productValues.product_family,
        product_shipping_term: productValues.product_shipping_term,
        product_owner:productValues.product_owner,
        product_active: true,
        short_term_available: false,
        product_price_basis: productValues.product_price_basis,
        product_price_type: productValues.product_price_type,
        product_port_of_loading: productValues.product_port_of_loading,
        gross_air_dried_typical: productValues.gross_air_dried_typical,
        gross_air_dried_min: productValues.gross_air_dried_min,
        gross_air_dried_max: productValues.gross_air_dried_max,
        gross_as_recieved_min: productValues.gross_as_recieved_min,
        gross_as_recieved_max: productValues.gross_as_recieved_max,
        net_as_recieved_min: productValues.net_as_recieved_min,
        net_as_recieved_max:productValues.net_as_recieved_max,
        moisture_typical: productValues.moisture_typical,
      moisture_minimum: productValues.moisture_minimum,
      ash_typical: productValues.ash_typical,
      ash_minimum: productValues.ash_minimum,
      ash_maximum: productValues.ash_maximum,
      volatile_matter_typical: productValues.volatile_matter_typical,
      volatile_matter_minimum:productValues.volatile_matter_minimum,
      volatile_matter_maximum: productValues.volatile_matter_maximum,
      fixed_carbon_by_difference: productValues.fixed_carbon_by_difference,
      fixed_carbon_typical: productValues.fixed_carbon_typical,
      fixed_carbon_minimum: productValues.fixed_carbon_minimum,
      fixed_carbon_maximum: productValues.fixed_carbon_maximum,
      carbon_typical: productValues.carbon_typical,
      carbon_min:productValues.carbon_min,
      carbon_max: productValues.carbon_max,
      hydrogen_typical: productValues.hydrogen_typical,
      hydrogen_min: productValues.hydrogen_min,
      hydrogen_max: productValues.hydrogen_max,
      nitrogen_typical:productValues.nitrogen_typical,
      nitrogen_min: productValues.nitrogen_min,
      nitrogen_max: productValues.nitrogen_max,
      sulphur_typical: productValues.sulphur_typical,
      sulphur_min: productValues.sulphur_min,
      sulphur_max_ultimate_analysis: productValues.sulphur_max_ultimate_analysis,
      oxygen_typical: productValues.oxygen_typical,
      oxygen_min: productValues.oxygen_min,
      oxygen_max: productValues.oxygen_max,
      sulphur_typical_arb:productValues.sulphur_typical_arb,
      quote_shipment: productValues.productValues,
        ...productValues,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);
      const headers = { Authorization: `Bearer ${decryptedAccessToken}` };

      axios
        .post(`${API_URL}add/new/quote/`+id, formData, { headers})
        .then((response) => {
          console.log(response)
          if (response.status === 200) {

            axios
            .post(`${API_URL}add/tcf/${productCode}`, {
              headers,
            })
            .then((secondResponse) => {
              console.log('hi'+JSON.stringify(secondResponse));
              if(secondResponse.data.message =="added"){
              toast.success('TCF Created successfully', {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

              }
            })
            .catch((secondError) => {
              console.log(secondError);
            });
        }
          console.log(response);
          toast.success('Quotes added & TCF Created successfully', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="shipment">
    <Header/>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Quotes</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Quotes</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
  <div className='tasks quotes'>
  <form onSubmit={handleSubmit}>
  <Row className="mb-3">
         {/* <h4 className='heading'>Account Information</h4> */}



         <div className="form-grop">
       <label htmlFor="product-code">Product Code:</label>
       <select
         className="form-control"
         id="product-code"
         name="product-code"
         value={productCode}
         onChange={handleProductCodeChange}
       >
         <option value="">Select a product code</option>
         {products.map((product) => (
           <option key={product.id} value={product.product_code}>
             {product.product_code}
           </option>
         ))}
       </select>
       </div>
       <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Quote Shipment">
    <Form.Select aria-label="Floating label select example"

    name="quote_shipment"
    value={formData.quote_shipment}
    onChange={handleChange}
    >
      <option value='' >Select</option>
       {
          shippment.map((x)=>{
              return(
                <option key={x.id} value={x.quote_shipment_code}>
                {x.quote_shipment_code}
              </option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Name"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Product Account"
               name="product_account"

           value={formData.product_account}
           onChange={handleChange}
               />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Supplier"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Supplier"
             name="product_supplier"

           value={formData.product_supplier}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Description"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Description"
             name="product_description"

           value={formData.product_description}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Family"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Family"
             name="product_family"

           value={formData.product_family}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Shipping Term"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Shipping Term"
             name="product_shipping_term"

           value={formData.product_shipping_term}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Owner"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Owner"
             name="product_owner"

           value={formData.product_owner}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Active"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Active"
             name="product_active"

           value={formData.product_active}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Short term available"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Short term available"
             name="short_term_available"

           value={formData.short_term_available}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Price Basis"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Price Basis"
             name="product_price_basis"

           value={formData.product_price_basis}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product price type"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product price type"
             name="product_price_type"

           value={formData.product_price_type}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Port Of Loading"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Port Of Loading"
             name="product_port_of_loading"

           value={formData.product_port_of_loading}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Gross Air Dried Typical"
                     className="mb-3"


                   >
                     <Form.Control
                       type="text"
                       placeholder="Gross Air Dried Typical"
                       name="gross_air_dried_typical"

           value={formData.gross_air_dried_typical}
           onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>


                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Gross Air Dried Min"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Gross Air Dried Min"
                     name="gross_air_dried_min"

                    value={formData.gross_air_dried_min}
                    onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>



                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Gross Air Dried Max"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Gross Air Dried Max"
                     name="gross_air_dried_max"

                    value={formData.gross_air_dried_max}
                    onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Gross As Recieved Min"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Gross As Recieved Min"
                     name="gross_as_recieved_min"
                     value={formData.gross_as_recieved_min}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Gross As Recieved Max"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Gross As Recieved Max"
                     name="gross_as_recieved_max"
                     value={formData.gross_as_recieved_max}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Net As Recieved Min"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Net As Recieved Min"
                     name="net_as_recieved_min"

                     value={formData.net_as_recieved_min}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Moisture Typical"
                     className="mb-3"


                   >
                     <Form.Control
                       type="text"
                       placeholder="Moisture Typical"
                       name="moisture_typical"

                     value={formData.moisture_typical}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>


                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Moisture Minimum"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Moisture Minimum"
                     name="moisture_minimum"
                     value={formData.moisture_minimum}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>



                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Ash Typical"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Ash Typical"
                     name="ash_typical"
                     value={formData.ash_typical}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Ash Minimum"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Ash Minimum"
                     name="ash_minimum"
                     value={formData.ash_minimum}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Ash Maximum"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Ash Maximum"
                     name="ash_maximum"
                     value={formData.ash_maximum}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Volatile Matter Typical"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Volatile Matter Typical"
                     name="volatile_matter_typical"
                     value={formData.volatile_matter_typical}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>


                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Volatile Matter Minimum"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Volatile Matter Minimum"
                     name="volatile_matter_minimum"
                     value={formData.volatile_matter_minimum}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Volatile Matter Maximum"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Volatile Matter Maximum"
                     name="volatile_matter_maximum"
                     value={formData.volatile_matter_maximum}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fixed Carbon By Difference"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Fixed Carbon By Difference"
                     name="fixed_carbon_by_difference"
                     value={formData.fixed_carbon_by_difference}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fixed Carbon Typical"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Fixed Carbon Typical"
                     name="fixed_carbon_typical"
                     value={formData.fixed_carbon_typical}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fixed Carbon Minimum"
                     className="mb-3"
                   >

                     <Form.Control type="text"
                     placeholder="Fixed Carbon Minimum"
                     name="fixed_carbon_minimum"
                     value={formData.fixed_carbon_minimum}
                     onChange={handleChange}/>
                   </FloatingLabel>
                 </Form.Group>
       </Row>
       <Row>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Moisture Typical"
                      name="moisture_typical"
                      value={formData.moisture_typical}
                      onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Moisture Minimum"
                    name="moisture_minimum"
                    value={formData.moisture_minimum}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Typical"
                    name="ash_typical"

                    value={formData.ash_typical}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Minimum"
                    name="ash_minimum"
                    value={formData.ash_minimum}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Maximum"
                    name="ash_maximum"
                    value={formData.ash_maximum}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Volatile Matter Typical"
                    name="volatile_matter_typical"
                    value={formData.volatile_matter_typical}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Arb"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Sulphur Typical Arb"
                    name="sulphur_typical_arb"
                    value={formData.sulphur_typical_arb}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Volatile Matter Minimum"
                    name="volatile_matter_minimum"
                    value={formData.volatile_matter_minimum}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Volatile Matter Maximum"
                    name="volatile_matter_maximum"
                    value={formData.volatile_matter_maximum}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon By Difference"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Fixed Carbon By Difference"
                    name="fixed_carbon_by_difference"
                    value={formData.fixed_carbon_by_difference}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Fixed Carbon Typical"
                    name="fixed_carbon_typical"
                    value={formData.fixed_carbon_typical}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Fixed Carbon Minimum"
                    name="fixed_carbon_minimum"
                    value={formData.fixed_carbon_minimum}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon Typical"
                      name="carbon_typical"
                      value={formData.carbon_typical}
                      onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Carbon Min"
                    name="carbon_min"

                    value={formData.carbon_min}
                    onChange={handleChange}/>
                    </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Carbon Max"
                    name="carbon_max"
                    value={formData.carbon_max}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Hydrogen Typical"
                    name="hydrogen_typical"
                    value={formData.hydrogen_typical}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Hydrogen Min"
                    name="hydrogen_min"
                    value={formData.hydrogen_min}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Hydrogen Max"
                    name="hydrogen_max"
                    value={formData.hydrogen_max}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Nitrogen Typical"
                    name="nitrogen_typical"
                    value={formData.nitrogen_typical}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Nitrogen Min"
                    name="nitrogen_min"
                    value={formData.nitrogen_min}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Nitrogen Max"
                    name="nitrogen_max"
                    value={formData.nitrogen_max}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Sulphur Typical"
                    name="sulphur_typical"
                    value={formData.sulphur_typical}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Sulphur Min"
                    name="sulphur_min"
                    value={formData.sulphur_min}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur max ultimate analysis"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Sulphur max ultimate analysis"
                    name="sulphur_max_ultimate_analysis"
                    value={formData.sulphur_max_ultimate_analysis}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Oxygen Typical"
                    name="oxygen_typical"
                    value={formData.oxygen_typical}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Oxygen Min"
                    name="oxygen_min"
                    value={formData.oxygen_min}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Oxygen Max"
                    name="oxygen_max"
                    value={formData.oxygen_max}
                    onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                      value={formData.sulphur_typical_adb}
                      onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>



       <button type="submit" className='btn btn-primary add-quote'>Save</button>
       <p className='result'><p></p><p></p></p>
       </Row>


         </form>
           </div>
      <Footer/>
      </div>
  );
};

export default AddQuotes;

// {product_code: 'rwood_code_1', product_name: '', product_account: '', product_description: ''}
