import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import Footer from '../Footer'
import $ from 'jquery';
import { API_URL } from '../config';
import axios from "axios";
import { useParams  } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EditBank() {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
const [account, setAccount]=useState([]);
  const params = useParams()
  const idUpdate=params.id
  $(".contactAccount").css({"min-height":(window_height)+"px"})
  const [update,setUpdate]=useState([]);
      useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}get/rwoodbanksbyID/`+idUpdate, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      setAccount(data);
      setValue('remark', data.remark);
      setValue('rwood_bank_name', data.rwood_bank_name);
      setValue('lc_type', data.lc_type);
      setValue('bank_from_date', data.bank_from_date);
      setValue('bank_to_date', data.bank_to_date);
      setValue('owner', data.owner);
      setValue('lc_transfer_rate_or_advising_cost', data.lc_transfer_rate_or_advising_cost);
      setValue('lc_negotiation_or_commission_rate', data.lc_negotiation_or_commission_rate);
      setValue('tax_percent', data.tax_percent);
      setValue('adding_confirmation_charges', data.adding_confirmation_charges);
      setValue('swift_charges', data.swift_charges);
      setValue('fixed_other_cost', data.fixed_other_cost);
      setValue('fixed_appointed_overheads', data.fixed_appointed_overheads);
      setValue('bank_full_name', data.bank_full_name);
      setValue('bank_account_no', data.bank_account_no);
      setValue('bank_branch_name', data.bank_branch_name);
      setValue('bank_street', data.bank_street);
      setValue('bank_country', data.bank_country);
      setValue('corresponding_bank', data.corresponding_bank);
      setValue('corresponding_bank_swift', data.corresponding_bank_swift);
      setValue('iban', data.iban);
      setValue('swift_code', data.swift_code);

    } catch (error) {
      console.error(error);
    }
  };


    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = data =>{
        const responseData={
          remark:data.remark,
         rwood_bank_name:data.rwood_bank_name,
lc_type:data.lc_type,
bank_from_date:data.bank_from_date,
bank_to_date:data.bank_to_date,
owner:data.owner,
lc_transfer_rate_or_advising_cost:data.lc_transfer_rate_or_advising_cost,
lc_negotiation_or_commission_rate:data.lc_negotiation_or_commission_rate,
tax_percent:data.tax_percent,
adding_confirmation_charges:data.adding_confirmation_charges,
interest:data.interest,
swift_charges:data.swift_charges,
fixed_other_cost:data.fixed_other_cost,
fixed_appointed_overheads:data.fixed_appointed_overheads,
bank_full_name:data.bank_full_name,
bank_account_no:data.bank_account_no,
bank_branch_name:data.bank_branch_name,
bank_street:data.bank_street,
bank_country:data.bank_country,
corresponding_bank:data.corresponding_bank,
corresponding_bank_swift:data.corresponding_bank_swift,
iban:data.iban,
swift_code:data.swift_code,
      };
      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

        fetch(`${API_URL}update/rwoodbank/`+idUpdate, {
                  method: "PUT",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())

                  .then((response) => {
                    if(response.message !==''){
                        toast.success('Bank Updated Successfully', {
                  position: "bottom-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });

                    }
                  })

                }

        };
        const handleChange = (e) => {
          const { name, value } = e.target;
          setUpdate((prevData) => ({
            ...prevData,
            [name]: value
          }));
        };

  return (

    <div className="addAccount" id='bank'>
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Edit Bank</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Edit Bank </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
   <Form onSubmit={handleSubmit(onSubmit)} id='price'>
      <Row className="mb-3">
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood bank name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Rwood bank name"
              name="rwood_bank_name"
              defaultValue={account.rwood_bank_name || ""}
              onChange={handleChange}
              {...register("rwood_bank_name", { required: false })}
            />
            {errors.rwood_bank_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Remark"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Remark"
              name="remark"
              defaultValue={account.remark || ""}
              onChange={handleChange}
              {...register("remark", { required: false })}
            />
            {errors.remark && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Lc type"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="LC Type"
              name="lc_type"
              defaultValue={account.lc_type || ""}
              onChange={handleChange}
              {...register("lc_type", { required: false })}
            />
            {errors.lc_type && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank from date"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Bank from date"
              name="bank_from_date"
              defaultValue={account.bank_from_date || ""}
              onChange={handleChange}
              {...register("bank_from_date", { required: false })}
            />
            {errors.bank_from_date && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank to date"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Bank to date"
              name="bank_to_date"
              defaultValue={account.bank_to_date || ""}
              onChange={handleChange}
              {...register("bank_to_date", { required: false })}
            />
            {errors.bank_to_date && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Owner"
              name="owner"
              defaultValue={account.owner || ""}
              onChange={handleChange}
              {...register("owner", { required: false })}
            />
            {errors.owner && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Lc transfer rate or advising cost"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Lc transfer rate or advising cost"
              name="lc_transfer_rate_or_advising_cost"
              defaultValue={account.lc_transfer_rate_or_advising_cost || ""}
              onChange={handleChange}
              {...register("lc_transfer_rate_or_advising_cost", { valueAsNumber:true, required: false })}
            />
            {errors.lc_transfer_rate_or_advising_cost && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Lc negotiation or commission rate"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Lc negotiation or commission rate"
              name="lc_negotiation_or_commission_rate"
              defaultValue={account.lc_negotiation_or_commission_rate || ""}
              onChange={handleChange}
              {...register("lc_negotiation_or_commission_rate", {valueAsNumber:true, required: false })}
            />
            {errors.lc_negotiation_or_commission_rate && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Tax percent"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Tax percent"
              name="tax_percent"
              defaultValue={account.tax_percent || ""}
              onChange={handleChange}
              {...register("tax_percent", {valueAsNumber:true, required: false })}
            />
            {errors.tax_percent && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Adding confirmation charges"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Adding confirmation charges"
              name="adding_confirmation_charges"
              defaultValue={account.adding_confirmation_charges || ""}
              onChange={handleChange}
              {...register("adding_confirmation_charges", {valueAsNumber:true, required: false })}
            />
            {errors.adding_confirmation_charges && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Interest"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Interest"
              name="interest"
              defaultValue={account.interest || ""}
              onChange={handleChange}
              {...register("interest", {valueAsNumber:true, required: false })}
            />
            {errors.interest && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Swift charges"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Swift charges"
              name="swift_charges"
              defaultValue={account.swift_charges || ""}
              onChange={handleChange}
              {...register("swift_charges",  {valueAsNumber:true, required: false })}
            />
            {errors.swift_charges && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fixed other cost"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Fixed other cost"
              name="fixed_other_cost"
              defaultValue={account.fixed_other_cost || ""}
              onChange={handleChange}
              {...register("fixed_other_cost", {valueAsNumber:true, required: false })}
            />
            {errors.fixed_other_cost && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fixed appointed overheads"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Fixed appointed overheads"
              name="fixed_appointed_overheads"
              defaultValue={account.fixed_appointed_overheads || ""}
              onChange={handleChange}
              {...register("fixed_appointed_overheads", {valueAsNumber:true, required: false })}
            />
            {errors.fixed_appointed_overheads && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank full name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Bank full name"
              name="bank_full_name"
              defaultValue={account.bank_full_name || ""}
              onChange={handleChange}
              {...register("bank_full_name", { required: false })}
            />
            {errors.bank_full_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank account no"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Bank account no"
              name="bank_account_no"
              defaultValue={account.bank_account_no || ""}
              onChange={handleChange}
              {...register("bank_account_no", {valueAsNumber:true, required: false })}
            />
            {errors.bank_account_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank_branch name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Bank_branch name"
              name="bank_branch_name"
              defaultValue={account.bank_branch_name || ""}
              onChange={handleChange}
              {...register("bank_branch_name", { required: false })}
            />
            {errors.bank_branch_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank street"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Bank street"
              name="bank_street"
              {...register("bank_street", { required: false })}
            />
            {errors.bank_street && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank country"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Bank country"
              name="bank_country"

              defaultValue={account.bank_country || ""}
              onChange={handleChange}
              {...register("bank_country", { required: false })}
            />
            {errors.bank_country && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Corresponding bank"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Corresponding bank"
              name="corresponding_bank"

              defaultValue={account.corresponding_bank || ""}
              onChange={handleChange}
              {...register("corresponding_bank", { required: false })}
            />
            {errors.corresponding_bank && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Corresponding bank swift"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Corresponding bank swift"
              name="corresponding_bank_swift"

              defaultValue={account.corresponding_bank_swift || ""}
              onChange={handleChange}
              {...register("corresponding_bank_swift", { required: false })}
            />
            {errors.corresponding_bank_swift && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Iban"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Iban"
              name="iban"

              defaultValue={account.iban || ""}
              onChange={handleChange}
              {...register("iban", { required: false })}
            />
            {errors.iban && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Swift code"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Swift code"
              name="swift_code"

              defaultValue={account.swift_code || ""}
              onChange={handleChange}
              {...register("swift_code", { required: false })}
            />
            {errors.swift_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>
      <input type="submit" className='price-submit' />
      <div className='priceDiv'><p></p><p></p></div>
    </Form>  <Footer/>
    </div>
  );
}

export default EditBank;
