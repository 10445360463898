import React, { useEffect, useState, forwardRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, Link } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Select from 'react-select';
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from '../config';
import $ from "jquery";
import axios from "axios";
import RecentItem from "../recentitem/rececentItem";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import {
  Card,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";

import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { FaRegRegistered } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import { IoIosHelpCircle } from "react-icons/io";
const NewAccounts = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const navigate = useNavigate();
  const [company,setCompany]=useState([]);



  // date
  const [startDate, setStartDate] = useState(null);

  const reference_date = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };


   const [kycdoc, setKycdoc] = useState(null);
   const ky_date_date = kycdoc ? `${kycdoc.getDate().toString().padStart(2, '0')}/${(kycdoc.getMonth() + 1).toString().padStart(2, '0')}/${kycdoc.getFullYear()}` : null;


  const handleStartDateKyc = (date) => {
    setKycdoc(date);
  };

  //  const kyc_date = kycdoc ? `${kycdoc.getDate().toString().padStart(2, '0')}/${(kycdoc.getMonth() + 1).toString().padStart(2, '0')}/${kycdoc.getFullYear()}` : null;


   // edit page
   const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please Enter Full Company Name here
    </Tooltip>
  );
  const trader = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Trader
    </Tooltip>
  );
  const phone = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Phone numbers are a great way to call people on phones.  Phones are what we call our pocket computers even though we use them for games, selfies, and facebook.
    </Tooltip>
  );

const adminRole=localStorage.getItem('role')

  // dropdown
  const optionsCompany = company.map(item => ({
    value: item.company_name,
    label: item.company_name
  }));

  const [companydrop, setCompanydrop] = useState(null);

  const handleCompany = (companydrop) => {
    setCompanydrop(companydrop);
  };

  const [isFormsubmit, setFormsubmited] = useState(false);

  const [startDate1, setStartDate1] = useState(new Date());
  const birthDate=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
 const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     value={value}
     className="custom-input"
     onClick={onClick}
     name="birthdate"
     onChange={onChange}
     ref={ref}
   ></input>
 ));

 const [reference1, setReference1] = useState(new Date());
  const referenceDate=reference1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(reference1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(reference1.toLocaleDateString("en-US", { day: '2-digit' }))
 const ReferenceInput = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     value={value}
     className="custom-input"
     onClick={onClick}
     name=""
     onChange={onChange}
     ref={ref}
   ></input>
 ));




useEffect(() => {

  fetchDataAccount();
}, [decryptedAccessToken]);

const fetchDataAccount = async () => {
  try {
    const response = await axios.get(`${API_URL}get/all/rwoodcompany`, {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.rwoodcompany;
    setCompany(data)
     console.log(data)
  } catch (error) {
    console.error(error);
  }
};

  const [account_name, setaccount_name] = useState('');
  const [documents, setDocuments] = useState([{ document_name: '', validity: '' }]);

  const handleDocumentChange = (index, field, value) => {
    const updatedDocuments = [...documents];
    updatedDocuments[index][field] = value;

    setDocuments(updatedDocuments);
  };

  const handleAddDocument = () => {
    setDocuments([...documents, { document_name: '', validity: '' }]);
  };

  const handleRemoveDocument = (index) => {
    const updatedDocuments = [...documents];
    updatedDocuments.splice(index, 1);
    setDocuments(updatedDocuments);
  };

  const location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const {
    reset: reset2,
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset: reset3,
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset: reset4,
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset: reset5,
    register: register5,
    formState: { errors: errors5 },
    handleSubmit: handleSubmit5,
  } = useForm({
    mode: "onBlur",
  });

  const { account_record } = location.state;
//  Recent Items







  if (account_record === "Agent") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: parseInt(data.annual_revenue),
        no_of_employees: data.no_of_employees,
        port: data.port,
        origin: data.origin,
        remarks: data.remarks,
        product: data.product,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        billing_postal_code:data.billing_postal_code,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: ky_date_date?ky_date_date:null,
        refrence_check_date:reference_date?reference_date:null,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        description: data.description,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
        documents
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios.post(`${API_URL}add/account`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            console.log(response);

            if (response.data.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.data.message}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });


              navigate("/accounts/" + response.data.account_id);
          } else if (response.data.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.data.message}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }
          })
          .catch(error => {
            // Handle errors
            console.error(error);
          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount  full-container">
          <div className="row">
        <div className="col-md-2 recent-item">
          <RecentItem/>
        </div>
        <div className="col-md-10 right-section">
        <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Add Agent
                </li>
              </ol>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    className="error-validation"
                    name="account_name"
                    {...register("account_name", { required: true })}
                  />
                 { errors.account_name && <span>Error: You must enter a value </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    value={adminRole}
                    className="owner-role"
                    {...register("account_owner", { required: false })}
                  />
                  {errors.account_owner && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    {...register("account_alias", { required: false })}
                  />
                  {errors.account_alias && <span>Error: You must enter a value</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Account Number"
                    name="account_number"
                    {...register("account_number", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    {...register("website", { required: false })}
                  />
                  {errors.website && <span>Error: You must enter a value</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    value={account_record}
                    name="account_record_type" className='record-type'
                    {...register("account_record_type", { required: false })}
                  />
                  {errors.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Trader"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Trader"
                    name="trader"
                    {...register("trader", { required: false })}
                  />
                  {errors.trader && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={trader}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual Revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Annual Revenue"
                    name="annual_revenue"
                    {...register("annual_revenue", { required: false })}
                  />
                  {errors.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    {...register("port", { required: false })}
                  />
                  {errors.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


             <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="No of employees"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="No of employees"
                  name="no_of_employees"
                  {...register("no_of_employees", {
                    valueAsNumber: true,
                    required: false,
                  })}
                />
                {errors.no_of_employees && <span>Required</span>}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                    {...register("annual_revenue", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    {...register("product", { required: false })}
                  />
                  {errors.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    {...register("remarks", { required: false })}
                  />
                  {errors.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    {...register("region", { required: false })}
                  />
                  {errors.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6" className='tools'>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    {...register("phone", { required: false })}
                  />
                  {errors.phone && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={phone}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>

              <Form.Group as={Col} md="6" className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    {...register("fax", { required: false })}
                  />
                  {errors.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    {...register("billing_postal_code", { required: false })}
                  />
                  {errors.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    {...register("shipping_postal_code", { required: false })}
                  />
                  {errors.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">KYC Documents</h4>
               {documents.map((document, index) => (
        <>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document Name"
                    defaultValue={document.name}
                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="5">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Validity"
                    defaultValue={document.validity}
                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="1">
          {index > 0 && (
            <span id="links" onClick={() => handleRemoveDocument(index)}><FaTrashAlt/></span>
          )}
          </Form.Group>
        </>
      ))}
      <span id="links" onClick={handleAddDocument}>Add Document</span>
      {/* <button onClick={handleSubmit}>Create Account</button> */}
              </Row>
              <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    {...register("KYC_docs", { required: false })}
                  />
                  {errors.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Commitment"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="commitment"
                    placeholder="Commitment"
                    defaultValue=""
                    {...register("commitment", { required: false })}
                  >
                    <option value="">--None--</option><option value="Medium Term">Medium Term</option><option value="Short Term">Short Term</option><option value="Long Term">Long Term</option><option value="Promptly">Promptly</option>
                  </Form.Select>
                  {errors.commitment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>




   <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="KYC Doc date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={kycdoc}
        onChange={handleStartDateKyc}
        dateFormat='dd/MM/yyyy'
        startDate={kycdoc} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Reliability"
                    name="reliability"
                    {...register("reliability", { required: false })}
                  />
                  {errors.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    {...register("refrence_check", { required: false })}
                  />
                  {errors.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Refrence Check Date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
        startDate={startDate} placeholderText="Select"

      />
        </FloatingLabel>
      </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent account"
                    name="parent_account"
                    {...register("parent_account", { required: false })}
                  />
                  {errors.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    {...register("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    {...register("under_group", { required: false })}
                  />
                  {errors.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    {...register("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station name"
                    name="station_name"
                    {...register("station_name", { required: false })}
                  />
                  {errors.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    {...register("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue=""
                    {...register("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors.expansion_setup_or_buyer_status && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production /Capacity Utilisation (Unit %)"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production /Capacity Utilisation (Unit %)"
                    name="production_capacity"
                    {...register("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze Import Break up (TPO/ %)	"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze Import Break up (TPO/ %)	"
                    name="originiaze_import_breakup"
                    {...register("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
                            <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    className="m-b"
                    {...register("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors.industry && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze Import Break up (TPO/ %)	"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze Import Break up (TPO/ %)	"
                    name="originiaze_import_breakup"
                    {...register("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    className="m-b"
                    {...register("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors.sector && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    className="m-b"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    {...register("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            {/* product Information */}
            {/* <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    {...register("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    {...register("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    {...register("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    {...register("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    {...register("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    {...register("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    {...register("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    {...register("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row> */}
            <Row className="mb-3">
              <h4 className="heading">System Information</h4>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="account source"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_source"
                    placeholder="account source"
                    defaultValue=""
                    {...register("account_source", { required: false })}
                  >
                  <option value="">--None--</option><option value="Advertisement">Advertisement</option><option value="Customer Event">Customer Event</option><option value="Employee Referral">Employee Referral</option><option value="Google AdWords">Google AdWords</option><option value="Other">Other</option><option value="Partner">Partner</option><option value="Purchased List">Purchased List</option><option value="Trade Show">Trade Show</option><option value="Webinar">Webinar</option><option value="Website">Website</option><option value="Rajgopalan">Rajgopalan</option><option value="Olivia">Olivia</option>
                  {errors.account_source && <span>Required</span>}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>


        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rating"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rating"
                    placeholder="Rating"
                    defaultValue=""
                    {...register("rating", { required: false })}
                  >
                   <option value="">--None--</option><option value="Hot">Hot</option><option value="Warm">Warm</option><option value="Cold">Cold</option></Form.Select>
                  {errors.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <h4 className="heading">Account Summary</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="general_terms"
                    style={{ height: "200px!important" }}
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>

          <p></p><p></p>
          </div></div>
        </div>
        <Footer />
      </>
    );
  } else if (account_record === "Supplier") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        payment_terms:data.payment_terms,
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: parseInt(data.annual_revenue),
        no_of_employees: data.no_of_employees,
        port: data.port,
        origin: data.origin,
        remarks: data.remarks,
        product: data.product,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        billing_postal_code:data.billing_postal_code,
        fax: data.fax,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: ky_date_date?ky_date_date:null,
         refrence_check_date:reference_date?reference_date:null,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        description: data.description,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
        berth_details:data.berth_details,
        nomination_of_vessel:data.nomination_of_vessel,
        loading_demurge_and_dispatch_details:data.loading_demurge_and_dispatch_details,
        documents
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios.post(`${API_URL}add/account`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            console.log(response);

            if (response.data.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.data.message}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });


              navigate("/accounts/" + response.data.account_id);
          } else if (response.data.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.data.message}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }
          })
          .catch(error => {
            // Handle errors
            console.error(error);
          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount  full-container">

         <div className="row">
       <div className="col-md-2 recent-item">
       <RecentItem/>
</div>
       <div className="col-md-10 right-section">
       <div className="page-header" id="add-account">
           <div>
             <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
             <ol className="breadcrumb">
               <li className="breadcrumb-item active" aria-current="page">
                 Dashboard / Account/ Add Supplier
               </li>
             </ol>
           </div>

         </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    // defaultValue={account_name}
                    className="error-validation"
                    // onChange={(e) => setaccount_name(e.target.value)}
                    {...register("account_name", { required: true})}
                    />
                    {errors.account_name && <span>Error: You must enter a value </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    className="owner-role"
                    value={adminRole}
                    {...register("account_owner", { required: false })}
                  />
                  {errors.account_owner && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    {...register("account_alias", { required: false })}
                  />
                  {errors.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              {/* <Form.Group as={Col} md="6"></Form.Group> */}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    value={account_record}
                    name="account_record_type" className='record-type'
                    {...register("account_record_type", { required: false })}
                  />
                  {errors.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    {...register("website", { required: false })}
                  />
                  {errors.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Account Number"
                    name="account_number"
                    {...register("account_number", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.account_number && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Trader"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Trader"
                    name="trader"
                    {...register("trader", { required: false })}
                  />
                  {errors.trader && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={trader}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    {...register("product", { required: false })}
                  />
                  {errors.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

              </Form.Group>

              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    {...register("remarks", { required: false })}
                  />
                  {errors.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    {...register("region", { required: false })}
                  />
                  {errors.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    {...register("billing_postal_code", { required: false })}
                  />
                  {errors.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    {...register("shipping_postal_code", { required: false })}
                  />
                  {errors.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">KYC Documents</h4>
               {documents.map((document, index) => (
        <>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document Name"
                    defaultValue={document.name}
                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="5">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Validity"
                    defaultValue={document.validity}
                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="1">
          {index > 0 && (
            <span id="links" onClick={() => handleRemoveDocument(index)}><FaTrashAlt/></span>
          )}
          </Form.Group>
        </>
      ))}
      <span id="links" onClick={handleAddDocument}>Add Document</span>
      {/* <button onClick={handleSubmit}>Create Account</button> */}
              </Row>
            <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Refrence Check Date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
        startDate={startDate} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Commitment"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="commitment"
                    placeholder="Commitment"
                    defaultValue=""
                    {...register("commitment", { required: false })}
                  >
                    <option value="">--None--</option><option value="Medium Term">Medium Term</option><option value="Short Term">Short Term</option><option value="Long Term">Long Term</option><option value="Promptly">Promptly</option>
                  </Form.Select>
                  {errors.commitment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    {...register("refrence_check", { required: false })}
                  />
                  {errors.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Reliability"
                    name="reliability"
                    {...register("reliability", { required: false })}
                  />
                  {errors.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    {...register("KYC_docs", { required: false })}
                  />
                  {errors.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"></Form.Group>

   <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="KYC Doc date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={kycdoc}
        onChange={handleStartDateKyc}
        dateFormat='dd/MM/yyyy'
        startDate={kycdoc} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rating"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rating"
                    placeholder="Rating"
                    defaultValue=""
                    {...register("rating", { required: false })}
                  >
                   <option value="">--None--</option><option value="Hot">Hot</option><option value="Warm">Warm</option><option value="Cold">Cold</option></Form.Select>
                  {errors.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent account"
                    name="parent_account"
                    {...register("parent_account", { required: false })}
                  />
                  {errors.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    {...register("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    {...register("under_group", { required: false })}
                  />
                  {errors.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    {...register("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue=""
                    {...register("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors.expansion_setup_or_buyer_status && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    {...register("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue=""
                    {...register("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors.expansion_setup_or_buyer_status && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production /Capacity Utilisation (Unit %"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production /Capacity Utilisation (Unit %"
                    name="production_capacity"
                    {...register("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    defaultValue=""
                    {...register("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors.sector && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze Import Break up (TPO/ %)	"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze Import Break up (TPO/ %)	"
                    name="originiaze_import_breakup"
                    {...register("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    {...register("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    {...register("port", { required: false })}
                  />
                  {errors.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

            </Row>
            {/* product Information */}
            {/* <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    {...register("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    {...register("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    {...register("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    {...register("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    {...register("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    {...register("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    {...register("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    {...register("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row> */}
            <Row className="mb-3">
              <h4 className="heading">Terms and Conditions</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Nomination of Vessel
                  "
                >
                  <Form.Control
                    as="textarea"
                    placeholder="nomination_of_vessel"
                    style={{ height: "300px!important" }}
                    name="nomination_of_vessel"
                    {...register("nomination_of_vessel", { required: false })}
                  />
                  {errors.nomination_of_vessel && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Berth Details

                  "
                >
                  <Form.Control
                    as="textarea"
                    placeholder="berth_details"
                    style={{ height: "300px!important" }}
                    name="berth_details"
                    {...register("berth_details", { required: false })}
                  />
                  {errors.berth_details && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Loading Demurrage and Despatch Details
                  "
                >
                  <Form.Control
                    as="textarea"
                    placeholder="loading_demurge_and_dispatch_details"
                    style={{ height: "300px!important" }}
                    name="loading_demurge_and_dispatch_details"
                    {...register("loading_demurge_and_dispatch_details", { required: false })}
                  />
                  {errors.loading_demurge_and_dispatch_details && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="general_terms"
                    style={{ height: "300px!important" }}
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    style={{ height: "300px!important" }}
                    name="payment_terms"
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">System Information</h4>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="account source"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_source"
                    placeholder="account source"
                    defaultValue=""
                    {...register("account_source", { required: false })}
                  >
                  <option value="">--None--</option><option value="Advertisement">Advertisement</option><option value="Customer Event">Customer Event</option><option value="Employee Referral">Employee Referral</option><option value="Google AdWords">Google AdWords</option><option value="Other">Other</option><option value="Partner">Partner</option><option value="Purchased List">Purchased List</option><option value="Trade Show">Trade Show</option><option value="Webinar">Webinar</option><option value="Website">Website</option><option value="Rajgopalan">Rajgopalan</option><option value="Olivia">Olivia</option>
                  {errors.account_source && <span>Required</span>}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>


            </Row>


            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          <p></p><p></p>
        </div>
        </div>
        </div>
        <Footer />
      </>
    );

    //
  } else if (account_record === "Bank") {
    const bankPage = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        port: data.port,
        company:companydrop.value,
        origin: data.origin,
        product: data.product,
        remarks: data.remarks,
        account_record_type: data.account_record_type,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        fax: data.fax,
        phone: data.phone,
        employees: data.employees,
        shipping_street: data.shipping_street,
        billing_street: data.billing_street,
        shipping_postal_code: data.shipping_postal_code,
        billing_postal_code: data.billing_postal_code,
        billing_city: data.billing_city,
        billing_state_or_province:data.billing_state_or_province,
        shipping_state_or_province:data.shipping_state_or_province,
        shipping_city: data.shipping_city,
        shipping_country: data.shipping_country,
        billing_country: data.billing_country,
        documents
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios.post(`${API_URL}add/account`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            console.log(response);

            if (response.data.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.data.message}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });


              navigate("/accounts/" + response.data.account_id);
          } else if (response.data.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.data.message}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }
          })
          .catch(error => {
            // Handle errors
            console.error(error);
          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount  full-container">

          <div className="row">
        <div className="col-md-2 recent-item">
        <RecentItem/>
</div>
        <div className="col-md-10 right-section">

        <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Add Agent
                </li>
              </ol>
            </div>

          </div>
          <Form onSubmit={handleSubmit2(bankPage)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    // defaultValue={account_name}
                    className="error-validation"
                    // onChange={(e) => setaccount_name(e.target.value)}
                    {...register2("account_name", { required: true })}
                    />
                    {errors2.account_name && <span>Error: You must enter a value
 </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    value={account_record}
                    name="account_record_type" className='record-type'
                    {...register2("account_record_type", { required: false })}
                  />
                  {errors2.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">

              <Select
        options={optionsCompany}
        value={companydrop}
        onChange={handleCompany}
        placeholder="Company"
      />



            </Form.Group>

               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    className="owner-role"
                    value={adminRole}
                    {...register2("account_owner", { required: false })}
                  />
                  {errors2.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    {...register2("account_alias", { required: false })}
                  />
                  {errors2.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent Account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent Account"
                    name="parent_account"
                    {...register2("parent_account", { required: false })}
                  />
                  {errors2.parent_account && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    {...register2("port", { required: false })}
                  />
                  {errors2.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Fax"
                    name="fax"
                    {...register2("fax", { required: false })}
                  />
                  {errors2.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register2("origin", { required: false })}
                  />
                  {errors2.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Phone"
                    name="phone"
                    {...register2("phone", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors2.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    {...register2("product", { required: false })}
                  />
                  {errors2.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Employees"
                    name="employees"
                    {...register2("employees", { required: false })}
                  />
                  {errors2.employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    {...register2("remarks", { required: false })}
                  />
                  {errors2.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" className="tools"></Form.Group>
              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Trader"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Trader"
                    name="trader"
                    {...register2("trader", { required: false })}
                  />
                  {errors2.trader && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={trader}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>

            </Row>

            <Row className="mb-3">
              <h4 className="heading">KYC Documents</h4>
               {documents.map((document, index) => (
        <>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document Name"
                    defaultValue={document.name}
                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="5">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Validity"
                    defaultValue={document.validity}
                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="1">
          {index > 0 && (
            <span id="links" onClick={() => handleRemoveDocument(index)}><FaTrashAlt/></span>
          )}
          </Form.Group>
        </>
      ))}
      <span id="links" onClick={handleAddDocument}>Add Document</span>
      {/* <button onClick={handleSubmit}>Create Account</button> */}
              </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register2("billing_street", { required: false })}
                  />
                  {errors2.billing_street && (
                    <p id="text-area">Required Billing Address</p>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    {...register2("shipping_street", { required: false })}
                  />
                  {errors2.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    {...register2("billing_postal_code", { required: false })}
                  />
                  {errors2.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    {...register2("shipping_postal_code", { required: false })}
                  />
                  {errors2.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register2("billing_city", { required: false })}
                  />
                  {errors2.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register2("shipping_city", { required: false })}
                  />
                  {errors2.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    {...register2("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors2.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    {...register2("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors2.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register2("billing_country", { required: false })}
                  />
                  {errors2.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    {...register2("shipping_country", { required: false })}
                  />
                  {errors2.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          <p></p><p></p>
          </div></div></div>
        <Footer />
      </>
    );
  }

  else if (account_record === "Buyer") {
    const buyer = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        imported_volume_from_indonesia_PA_in_MT:parseInt(
          data.imported_volume_from_indonesia_PA_in_MT),
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: parseInt(data.account_number),
        account_record_type: data.account_record_type,
        annual_revenue: parseInt(data.annual_revenue),
        no_of_employees: data.no_of_employees,
        port: data.port,
        payment_terms:data.payment_terms,
        origin: data.origin,
        remarks: data.remarks,
        product: data.product,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        description: data.description,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: ky_date_date?ky_date_date:null,
        refrence_check_date:reference_date?reference_date:null,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,

        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
        documents
      };

      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios.post(`${API_URL}add/account`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            console.log(response);

            if (response.data.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.data.message}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });


              navigate("/accounts/" + response.data.account_id);
          } else if (response.data.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.data.message}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }
          })
          .catch(error => {
            // Handle errors
            console.error(error);
          });
      }
    };
    return (
      <>
        <Header />
       <div className="contactAccount  full-container">

          <div className="row">
        <div className="col-md-2 recent-item">
        <RecentItem/>
</div>
        <div className="col-md-10 right-section">
        <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Add Buyer
                </li>
              </ol>
            </div>

          </div>
          <Form onSubmit={handleSubmit4(buyer)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                   <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    // defaultValue={account_name}
                    className="error-validation"
                    // onChange={(e) => setaccount_name(e.target.value)}
                    {...register4("account_name", { required: true })}
                    />
                    {errors4.account_name && <span>Error: You must enter a value </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    value={adminRole}
                    className="owner-role"
                    {...register4("account_owner", { required: false })}
                  />
                  {errors4.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    className="error-validation"
                    {...register4("account_alias", { required: true })}
                  />
                  {errors4.account_alias && <span>Error: You must enter a value</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent Account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent Account"
                    name="parent_account"
                    {...register4("parent_account", { required: false })}
                  />
                  {errors4.parent_account && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    {...register4("website", { required: false })}
                  />
                  {errors4.website && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Number"
                    name="account_number"
                    {...register4("account_number", { required: false })}
                  />
                  {errors4.account_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Trader"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Trader"
                    name="trader"
                    {...register4("trader", { required: false })}
                  />
                  {errors4.trader && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={trader}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    value={account_record}
                    name="account_record_type" className='record-type'
                    {...register4("account_record_type", { required: false })}
                  />
                  {errors4.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
             <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual Revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Annual Revenue"
                    name="annual_revenue"
                    {...register4("annual_revenue", { required: false })}
                  />
                  {errors4.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register4("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    {...register4("region", { required: false })}
                  />
                  {errors4.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Street"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Street"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register4("billing_street", { required: false })}
                  />
                  {errors4.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Street"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Street"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    {...register4("shipping_street", { required: false })}
                  />
                  {errors4.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    {...register4("billing_postal_code", { required: false })}
                  />
                  {errors4.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    {...register4("shipping_postal_code", { required: false })}
                  />
                  {errors4.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register4("billing_city", { required: false })}
                  />
                  {errors4.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register4("shipping_city", { required: false })}
                  />
                  {errors4.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    {...register4("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors4.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    {...register4("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors4.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register4("billing_country", { required: false })}
                  />
                  {errors4.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    {...register4("shipping_country", { required: false })}
                  />
                  {errors4.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Phone"
                    name="phone"
                    {...register4("phone", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    {...register4("fax", { required: false })}
                  />
                  {errors4.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">KYC Documents</h4>
               {documents.map((document, index) => (
        <>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document Name"
                    defaultValue={document.name}
                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="5">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Validity"
                    defaultValue={document.validity}
                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="1">
          {index > 0 && (
            <span id="links" onClick={() => handleRemoveDocument(index)}><FaTrashAlt/></span>
          )}
          </Form.Group>
        </>
      ))}
      <span id="links" onClick={handleAddDocument}>Add Document</span>
      {/* <button onClick={handleSubmit}>Create Account</button> */}
              </Row>
            <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    {...register4("KYC_docs", { required: false })}
                  />
                  {errors4.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Commitment"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="commitment"
                    placeholder="Commitment"
                    defaultValue=""
                    {...register4("commitment", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Minimum Term">Minimum Term</option>
                    <option value="Short Term">Short Term</option>
                    <option value="Long Term">Long Term</option>
                  </Form.Select>
                  {errors4.commitment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="KYC Doc date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={kycdoc}
        onChange={handleStartDateKyc}
        dateFormat='dd/MM/yyyy'
        startDate={kycdoc} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Reliability"
                    name="reliability"
                    {...register4("reliability", { required: false })}
                  />
                  {errors4.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    {...register4("refrence_check", { required: false })}
                  />
                  {errors4.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

<Form.Group as={Col} md="6">
       <FloatingLabel
          controlId="floatingInput"
          label="Refrence Check Date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
        startDate={startDate} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    {...register4("under_group", { required: false })}
                  />
                  {errors4.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    {...register4("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station name"
                    name="station_name"
                    {...register4("station_name", { required: false })}
                  />
                  {errors4.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    {...register4("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue=""
                    {...register4("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors4.expansion_setup_or_buyer_status && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume from indonesia PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Imported volume from indonesia PA in MT"
                    name="imported_volume_from_indonesia_PA_in_MT"
                    {...register4("imported_volume_from_indonesia_PA_in_MT", {
                      required: false,
                    })}
                  />
                  {errors4.imported_volume_from_indonesia_PA_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    {...register4("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    defaultValue=""
                    {...register4("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors4.industry && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production /Capacity Utilisation (Unit %)"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production /Capacity Utilisation (Unit %)"
                    name="production_capacity"
                    {...register4("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    defaultValue=""
                    {...register4("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors4.sector && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze Import Break up (TPO/ %)	"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze Import Break up (TPO/ %)	"
                    name="originiaze_import_breakup"
                    {...register4("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors4.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    {...register4("port", { required: false })}
                  />
                  {errors4.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    {...register4("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors4.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register4("origin", { required: false })}
                  />
                  {errors4.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

            </Row>
            {/* <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    {...register4("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    {...register("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    {...register4("product", { required: false })}
                  />
                  {errors4.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    {...register4("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"
                    {...register4("description", { required: false })}
                  />
                  {errors4.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    {...register4("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    {...register4("remarks", { required: false })}
                  />
                  {errors4.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    {...register4("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    {...register4("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    {...register4("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    {...register4("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    {...register4("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row> */}
            <Row>
              <h4 className="heading">Terms & Conditions</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    style={{ height: "100px" }}
                    name="general_terms"
                    {...register4("general_terms", { required: false })}
                  />
                  {errors4.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    style={{ height: "100px" }}
                    name="payment_terms"
                    {...register4("payment_terms", { required: false })}
                  />
                  {errors4.payment_terms && <p id="text-area">Required </p>}
                </FloatingLabel>
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <h4 className="heading">System Information</h4>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="account source"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_source"
                    placeholder="Account Source"
                    defaultValue=""
                    {...register4("account_source", { required: false })}
                  >
                  <option value="">--None--</option><option value="Advertisement">Advertisement</option><option value="Customer Event">Customer Event</option><option value="Employee Referral">Employee Referral</option><option value="Google AdWords">Google AdWords</option><option value="Other">Other</option><option value="Partner">Partner</option><option value="Purchased List">Purchased List</option><option value="Trade Show">Trade Show</option><option value="Webinar">Webinar</option><option value="Website">Website</option><option value="Rajgopalan">Rajgopalan</option><option value="Olivia">Olivia</option>
                  {errors4.account_source && <span>Required</span>}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rating"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rating"
                    placeholder="Rating"
                    defaultValue=""
                    {...register4("rating", { required: false })}
                  >
                   <option value="">--None--</option><option value="Hot">Hot</option><option value="Warm">Warm</option><option value="Cold">Cold</option></Form.Select>
                  {errors4.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

            </Row>

            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          </div></div></div>
        <p></p><p></p>
        <Footer />
      </>
    );
  } else if (account_record === "Employee") {
    const employees = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_record_type: data.account_record_type,
        company: data.company,
        account_alias: data.account_alias,
        account_owner: data.account_owner,
        employees: data.employees,
        parent_account: data.parent_account,
        fax: data.fax,
        port: data.port,
        origin: data.origin,
        phone: data.phone,
        product: data.product,
        remarks: data.remarks,
        shipping_state_or_province: data.shipping_state_or_province,
        documents,
        billing_state_or_province: data.billing_state_or_province,
        shipping_street: data.shipping_street,
        billing_street: data.billing_street,
        shipping_postal_code: data.shipping_postal_code,
        billing_postal_code: data.billing_postal_code,
        billing_city: data.billing_city,
        shipping_city: data.shipping_city,
        shipping_country: data.shipping_country,
        billing_country: data.billing_country,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios.post(`${API_URL}add/account`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            console.log(response);

            if (response.data.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.data.message}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });


              navigate("/accounts/" + response.data.account_id);
          } else if (response.data.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.data.message}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }
          })
          .catch(error => {
            // Handle errors
            console.error(error);
          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount  full-container">

         <div className="row">
       <div className="col-md-2 recent-item">
       <RecentItem/>
</div>
       <div className="col-md-10 right-section">
       <div className="page-header" id="add-account">
           <div>
             <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
             <ol className="breadcrumb">
               <li className="breadcrumb-item active" aria-current="page">
                 Dashboard / Account/ Add Employee
               </li>
             </ol>
           </div>

         </div>
          <Form onSubmit={handleSubmit5(employees)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                   <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    // defaultValue={account_name}
                    className="error-validation"
                    // onChange={(e) => setaccount_name(e.target.value)}
                    {...register5("account_name", { required: true })}
                    />
                    {errors5.account_name && <span>Error: You must enter a value </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    value={account_record}
                    name="account_record_type" className='record-type'
                    {...register5("account_record_type", { required: false })}
                  />
                  {errors5.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Company">
  <Form.Select aria-label="Floating label select example"
  name="company"
  placeholder="Company"

  {...register5("company", { required: false })}
  >
    <option value='' >Select</option>
     {
        company.map((x)=>{
            return(
                <option value={x.company_name}>{x.company_name}</option>
            )
        })
     }
  </Form.Select>
  {errors5.company && <span>Required</span>}
</FloatingLabel>
            </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    value={adminRole}
                    className="owner-role"
                    {...register5("account_owner", { required: false })}
                  />
                  {errors5.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    {...register5("account_alias", { required: false })}
                  />
                  {errors5.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent account"
                    name="parent_account"
                    {...register5("parent_account", { required: false })}
                  />
                  {errors5.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    {...register5("port", { required: false })}
                  />
                  {errors5.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Fax"
                    name="fax"
                    {...register5("fax", { required: false })}
                  />
                  {errors5.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register5("origin", { required: false })}
                  />
                  {errors5.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    {...register5("phone", { required: false })}
                  />
                  {errors5.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    {...register5("product", { required: false })}
                  />
                  {errors5.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Employees"
                    name="employees"
                    {...register5("employees", { required: false })}
                  />
                  {errors5.employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    {...register5("remarks", { required: false })}
                  />
                  {errors5.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
      <h4 className="heading">KYC Documents</h4>
      {documents.map((document, index) => (
        <React.Fragment key={index}>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId={`floatingInputName${index}`}
              label="Document Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Document Name"
                defaultValue={document.document_name}
                onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="5">
            <FloatingLabel
              controlId={`floatingInputValidity${index}`}
              label="Validity"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Validity"
                defaultValue={document.validity}
                onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="1">
            {index > 0 && (
              <span id="links" onClick={() => handleRemoveDocument(index)}>
                <FaTrashAlt />
              </span>
            )}
          </Form.Group>
        </React.Fragment>
      ))}
      <span id="links" onClick={handleAddDocument}>Add Document</span>
      {/* <Button onClick={handleSubmit}>Create Account</Button> */}
    </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register5("billing_street", { required: false })}
                  />
                  {errors5.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    {...register5("shipping_street", { required: false })}
                  />
                  {errors5.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    {...register5("billing_postal_code", { required: false })}
                  />
                  {errors5.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    {...register5("shipping_postal_code", { required: false })}
                  />
                  {errors5.shipping_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register5("billing_city", { required: false })}
                  />
                  {errors5.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register5("shipping_city", { required: false })}
                  />
                  {errors5.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    {...register5("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors5.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    {...register5("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors5.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register5("billing_country", { required: false })}
                  />
                  {errors5.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    {...register5("shipping_country", { required: false })}
                  />
                  {errors5.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          </div> </div> </div>
          <Footer />

      </>
    );
  } else if (account_record === "Surveyor") {
    const serviyor = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,

        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: parseInt(data.annual_revenue),
        description: data.description,
        no_of_employees: data.no_of_employees,
        port: data.port,
        origin: data.origin,
        remarks: data.remarks,
        product: data.product,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: ky_date_date?ky_date_date:null,
        refrence_check_date:reference_date?reference_date:null,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
        documents
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios.post(`${API_URL}add/account`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            console.log(response);

            if (response.data.success === true) {
    document.getElementById('messageContainer').innerHTML = `
        <div class="success-message">
        ${response.data.message}
        </div>
    `;

      window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: animated scrolling
    });


    navigate("/accounts/" + response.data.account_id);
} else if (response.data.success === false) {

    document.getElementById('messageContainer').innerHTML = `
        <div class="error-message">
            ${response.data.message}
        </div>
    `;

    // Scroll to the top of the page
    window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: animated scrolling
    });
}
          })
          .catch(error => {
            // Handle errors
            console.error(error);
          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount  full-container">

          <div className="row">
        <div className="col-md-2 recent-item">
        <RecentItem/>
</div>
        <div className="col-md-10 right-section">
        <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Add Surveyor
                </li>
              </ol>
            </div>

          </div>
          <Form onSubmit={handleSubmit(serviyor)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
          <Row className="mb-3">
              <h4 className="heading">Account Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    className="error-validation"
                    name="account_name"
                    {...register("account_name", { required: true })}
                  />
                  {errors.account_name && <span>Error: You must enter a value </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    className="owner-role"
                     value={adminRole}
                    {...register("account_owner", { required: false })}
                  />
                  {errors.account_owner && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    {...register("account_alias", { required: false })}
                  />
                  {errors.account_alias && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Account Number"
                    name="account_number"
                    {...register("account_number", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    {...register("website", { required: false })}
                  />
                  {errors.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    value={account_record}
                    name="account_record_type" className='record-type'
                    {...register("account_record_type", { required: false })}
                  />
                  {errors.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Trader"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Trader"
                    name="trader"
                    {...register("trader", { required: false })}
                  />
                  {errors.trader && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={trader}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual Revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Annual Revenue"
                    name="annual_revenue"
                    {...register("annual_revenue", { valueAsNumber:true,required: false })}
                  />
                  {errors4.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    {...register("port", { required: false })}
                  />
                  {errors.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                    {...register("annual_revenue", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    {...register("product", { required: false })}
                  />
                  {errors.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    {...register("remarks", { required: false })}
                  />
                  {errors.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    {...register("region", { required: false })}
                  />
                  {errors.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    {...register("phone", { required: false })}
                  />
                  {errors.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <p id="text-area">Required </p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    {...register("fax", { required: false })}
                  />
                  {errors.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    {...register("billing_postal_code", { required: false })}
                  />
                  {errors.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    {...register("shipping_postal_code", { required: false })}
                  />
                  {errors.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">KYC Documents</h4>
               {documents.map((document, index) => (
        <>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document Name"
                    defaultValue={document.name}
                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="5">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Validity"
                    defaultValue={document.validity}
                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="1">
          {index > 0 && (
            <span id="links" onClick={() => handleRemoveDocument(index)}><FaTrashAlt/></span>
          )}
          </Form.Group>
        </>
      ))}
      <span id="links" onClick={handleAddDocument}>Add Document</span>
      {/* <button onClick={handleSubmit}>Create Account</button> */}
              </Row>
              <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    {...register("KYC_docs", { required: false })}
                  />
                  {errors.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Commitment"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="commitment"
                    placeholder="Commitment"
                    defaultValue=""
                    {...register("commitment", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Minimum Term">Minimum Term</option>
                    <option value="Short Term">Short Term</option>
                    <option value="Long Term">Long Term</option>
                  </Form.Select>
                  {errors.commitment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="KYC Doc date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={kycdoc}
        onChange={handleStartDateKyc}
        dateFormat='dd/MM/yyyy'
        startDate={kycdoc} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Reliability"
                    name="reliability"
                    {...register("reliability", { required: false })}
                  />
                  {errors.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    {...register("refrence_check", { required: false })}
                  />
                  {errors.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

<Form.Group as={Col} md="6">
       <FloatingLabel
          controlId="floatingInput"
          label="Refrence Check Date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
        startDate={startDate} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            </Row>
            {/* <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    {...register("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    {...register("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    {...register("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    {...register("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    {...register("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    {...register("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    {...register("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    {...register("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row> */}
                <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent account"
                    name="parent_account"
                    {...register("parent_account", { required: false })}
                  />
                  {errors.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    {...register("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    {...register("under_group", { required: false })}
                  />
                  {errors.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    {...register("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station name"
                    name="station_name"
                    {...register("station_name", { required: false })}
                  />
                  {errors.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    {...register("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue=""
                    {...register("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors.expansion_setup_or_buyer_status && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production capacity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production capacity"
                    name="production_capacity"
                    {...register("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze Import Break up (TPO/ %)	"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze Import Break up (TPO/ %)	"
                    name="originiaze_import_breakup"
                    {...register("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
                            <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    className="m-b"
                    {...register("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors.industry && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze Import Break up (TPO/ %)	"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze Import Break up (TPO/ %)	"
                    name="originiaze_import_breakup"
                    {...register("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    className="m-b"
                    {...register("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors.sector && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    className="m-b"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    {...register("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Account Summary</h4>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="account source"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_source"
                    placeholder="account source"
                    defaultValue=""
                    {...register("account_source", { required: false })}
                  >
                  <option value="">--None--</option><option value="Advertisement">Advertisement</option><option value="Customer Event">Customer Event</option><option value="Employee Referral">Employee Referral</option><option value="Google AdWords">Google AdWords</option><option value="Other">Other</option><option value="Partner">Partner</option><option value="Purchased List">Purchased List</option><option value="Trade Show">Trade Show</option><option value="Webinar">Webinar</option><option value="Website">Website</option><option value="Rajgopalan">Rajgopalan</option><option value="Olivia">Olivia</option>
                  {errors.account_source && <span>Required</span>}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

    <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rating"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rating"
                    placeholder="Rating"
                    defaultValue=""
                    {...register("rating", { required: false })}
                  >
                   <option value="">--None--</option><option value="Hot">Hot</option><option value="Warm">Warm</option><option value="Cold">Cold</option></Form.Select>
                  {errors.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">System Information</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    style={{ height: "100px" }}
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          </div>  </div>  </div>
        <Footer />
      </>
    );
  } else if (account_record === "Competitor") {
    const onSubmit = (data) => {
      setFormsubmited(true)
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: parseInt(data.annual_revenue),
        description: data.description,
        no_of_employees: data.no_of_employees,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: ky_date_date?ky_date_date:null,
        refrence_check_date:reference_date?reference_date:null,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        port: data.port,
        origin: data.origin,
        imported_volume_from_indonesia_PA_in_MT:parseInt(
          data.imported_volume_from_indonesia_PA_in_MT),
        payment_terms: data.payment_terms,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
        documents
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios.post(`${API_URL}add/account`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            console.log(response);

            if (response.data.success === true) {
    document.getElementById('messageContainer').innerHTML = `
        <div class="success-message">
        ${response.data.message}
        </div>
    `;

      window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: animated scrolling
    });


    navigate("/accounts/" + response.data.account_id);
} else if (response.data.success === false) {

    document.getElementById('messageContainer').innerHTML = `
        <div class="error-message">
            ${response.data.message}
        </div>
    `;

    // Scroll to the top of the page
    window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: animated scrolling
    });
}
          })
          .catch(error => {
            // Handle errors
            console.error(error);
          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount  full-container">

         <div className="row">
       <div className="col-md-2 recent-item">
       <RecentItem/>
</div>
       <div className="col-md-10 right-section">
       <div className="page-header" id="add-account">
           <div>
             <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
             <ol className="breadcrumb">
               <li className="breadcrumb-item active" aria-current="page">
                 Dashboard / Account/ Add Competitor
               </li>
             </ol>
           </div>

         </div>
               <Form onSubmit={handleSubmit(onSubmit)}>
               <div id="messageContainer"></div>
          <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                   <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    // defaultValue={account_name}
                    className="error-validation"
                    // onChange={(e) => setaccount_name(e.target.value)}
                    {...register("account_name", { required: true })}
                    />
                    {errors.account_name && <span>Error: You must enter a value </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    value={adminRole}
                    className="owner-role"
                    {...register("account_owner", { required: false })}
                  />
                  {errors.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    {...register("account_alias", { required: false })}
                  />
                  {errors.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent Account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent Account"
                    name="parent_account"
                    {...register("parent_account", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    {...register("website", { required: false })}
                  />
                  {errors.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Account Number"
                    name="account_number"
                    {...register("account_number", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.account_number && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6" className="tools">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Trader"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Trader"
                    name="trader"
                    {...register("trader", { required: false })}
                  />
                  {errors.trader && <span>Required</span>}
                </FloatingLabel>
                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={trader}
                              >
                                <Button variant="success" className="tooltips">
                                  <IoIosHelpCircle id="help" />
                                </Button>
                              </OverlayTrigger>{" "}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    value={account_record}
                    name="account_record_type" className='record-type'
                    {...register("account_record_type", { required: false })}
                  />
                  {errors.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
{/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                    {...register("annual_revenue", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}

              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}

              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual Revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Annual Revenue"
                    name="annual_revenue"
                    {...register("annual_revenue", { required: false })}
                  />
                  {errors.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    {...register("region", { required: false })}
                  />
                  {errors.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

<Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    {...register("billing_postal_code", { required: false })}
                  />
                  {errors.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    {...register("shipping_postal_code", { required: false })}
                  />
                  {errors.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    {...register("phone", { required: false })}
                  />
                  {errors.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    {...register("fax", { required: false })}
                  />
                  {errors.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">KYC Documents</h4>
               {documents.map((document, index) => (
        <>

          <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document Name"
                    defaultValue={document.name}
                    onChange={(e) => handleDocumentChange(index, 'document_name', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="5">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Validity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Validity"
                    defaultValue={document.validity}
                    onChange={(e) => handleDocumentChange(index, 'validity', e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="1">
          {index > 0 && (
            <span id="links" onClick={() => handleRemoveDocument(index)}><FaTrashAlt/></span>
          )}
          </Form.Group>
        </>
      ))}
      <span id="links" onClick={handleAddDocument}>Add Document</span>
      {/* <button onClick={handleSubmit}>Create Account</button> */}
              </Row>
              <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    {...register("KYC_docs", { required: false })}
                  />
                  {errors.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Commitment"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="commitment"
                    placeholder="Commitment"
                    defaultValue=""
                    {...register("commitment", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Minimum Term">Minimum Term</option>
                    <option value="Short Term">Short Term</option>
                    <option value="Long Term">Long Term</option>
                  </Form.Select>
                  {errors.commitment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="KYC Doc date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={kycdoc}
        onChange={handleStartDateKyc}
        dateFormat='dd/MM/yyyy'
        startDate={kycdoc} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Reliability"
                    name="reliability"
                    {...register("reliability", { required: false })}
                  />
                  {errors.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    {...register("refrence_check", { required: false })}
                  />
                  {errors.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

<Form.Group as={Col} md="6">
       <FloatingLabel
          controlId="floatingInput"
          label="Refrence Check Date"
          className="mb-3 datepick"


        >
           <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
        startDate={startDate} placeholderText="Select"
      />
        </FloatingLabel>
      </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    {...register("under_group", { required: false })}
                  />
                  {errors.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    {...register("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station name"
                    name="station_name"
                    {...register("station_name", { required: false })}
                  />
                  {errors.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    {...register("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue=""
                    {...register("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors.expansion_setup_or_buyer_status && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume from indonesia PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Imported volume from indonesia PA in MT"
                    name="imported_volume_from_indonesia_PA_in_MT"
                    {...register("imported_volume_from_indonesia_PA_in_MT", {
                      required: false,
                    })}
                  />
                  {errors.imported_volume_from_indonesia_PA_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    {...register("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    defaultValue=""
                    {...register("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors.industry && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production /Capacity Utilisation (Unit %)"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production /Capacity Utilisation (Unit %)"
                    name="production_capacity"
                    {...register("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    defaultValue=""
                    {...register("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors.sector && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>{" "}
             <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze Import Break up (TPO/ %)	"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze Import Break up (TPO/ %)	"
                    name="originiaze_import_breakup"
                    {...register("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    {...register("port", { required: false })}
                  />
                  {errors.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    {...register("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Terms and Conditions</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    style={{ height: "100px" }}
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    style={{ height: "100px" }}
                    name="payment_terms"
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">System Information</h4>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="account source"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_source"
                    placeholder="account source"
                    defaultValue=""
                    {...register("account_source", { required: false })}
                  >
                  <option value="">--None--</option><option value="Advertisement">Advertisement</option><option value="Customer Event">Customer Event</option><option value="Employee Referral">Employee Referral</option><option value="Google AdWords">Google AdWords</option><option value="Other">Other</option><option value="Partner">Partner</option><option value="Purchased List">Purchased List</option><option value="Trade Show">Trade Show</option><option value="Webinar">Webinar</option><option value="Website">Website</option><option value="Rajgopalan">Rajgopalan</option><option value="Olivia">Olivia</option>
                  {errors.account_source && <span>Required</span>}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rating"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rating"
                    placeholder="Rating"
                    defaultValue=""
                    {...register("rating", { required: false })}
                  >
                   <option value="">--None--</option><option value="Hot">Hot</option><option value="Warm">Warm</option><option value="Cold">Cold</option></Form.Select>
                  {errors.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/accounts/account-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          </div> </div> </div>
        <Footer />
      </>
    );

  }
};

export default NewAccounts;
