// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { AES, enc } from "crypto-js";
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import $ from "jquery";
// import Form from "react-bootstrap/Form";
// import { Col, Row } from "react-bootstrap";
// import { useParams  } from "react-router-dom";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
// import Header from '../Header'
// import Footer from '../Footer';import { API_URL } from '../config';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
// const AddQuotes = () => {

//   const params = useParams()
//   const id=params.id
//   const [productCode, setProductCode] = useState("");
//   const [productValues, setProductValues] = useState([]);
//   const [products, setproducts] = useState([]);
//   const [shippment, setShippment]=useState([]);
//   const [supplier,setSupplier]=useState([]);


// useEffect(() => {

//   fetchDataSupplier1();
// }, [decryptedAccessToken]);

// const fetchDataSupplier1 = async () => {
//   try {
//     const response = await axios.get(API_URL+'get/supplier', {
//       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
//     );
//     const data = response.data;
//     setSupplier(data)
//      console.log('Supplier'+ JSON.stringify(data))
//   } catch (error) {
//     console.error(error);
//   }
// };


//   useEffect(() => {
//     fetchData();
//   }, [decryptedAccessToken]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(API_URL+'get/all/quoteshipment', {
//         headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
//       );
//       const data = response.data;

//       setShippment(data)
//       console.log(data)

//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const [formData, setFormData] = useState({
//     product_code: "",
//     product_name: "",
//         product_account: "",
//         product_supplier: "",
//         product_description: "",
//         product_family: "",
//         product_shipping_term:"",
//         product_owner:"",
//         product_active: "",
//         product_code: "",
//         product_price_basis: "",
//         product_price_type: "",
//         product_port_of_loading: "",
//     locked: true,
//     gross_air_dried_typical: "",
//         gross_air_dried_min:"",
//         gross_air_dried_max: "",
//         gross_as_recieved_min: "",
//         gross_as_recieved_max: "",
//         net_as_recieved_min: "",
//         net_as_recieved_max:"",
//         moisture_typical: "",
//       moisture_minimum: "",
//       ash_typical: "",
//       ash_minimum: "",
//       ash_maximum: "",
//       quote_shipment: "",
//       volatile_matter_typical: "",
//       volatile_matter_minimum:"",
//       volatile_matter_maximum: "",
//       fixed_carbon_by_difference: "",
//       fixed_carbon_typical: "",
//       fixed_carbon_minimum: "",
//       fixed_carbon_maximum: "",
//       carbon_typical: "",
//       carbon_min:"",
//       carbon_max: "",
//       hydrogen_typical: "",
//       hydrogen_min: "",
//       hydrogen_max: "",
//       nitrogen_typical:"",
//       nitrogen_min: "",
//       nitrogen_max: "",
//       sulphur_typical: "",
//       sulphur_min: "",
//       sulphur_max_ultimate_analysis: "",
//       oxygen_typical: "",
//       oxygen_min: "",
//       oxygen_max: "",
//       sulphur_typical_arb:"",
//   });

//   const handleChange = (event) => {
//     const { name, value, checked, type } = event.target;
//     console.log(event.target);
//     const newValue = type === "checkbox" ? checked : value;
//     console.log(newValue);
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: name === "locked" ? Boolean(newValue) : newValue,
//     }));
//   };

//   useEffect(() => {
//     const fetchProductCodes = async () => {
//       try {
//         const encryptedAccessToken = localStorage.getItem("access_token");
//         const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
//         if (encryptedAccessToken) {
//           const decryptedAccessToken = AES.decrypt(
//             encryptedAccessToken,
//             secret_key
//           ).toString(enc.Utf8);
//           const response = await axios.get(
//             API_URL+"get/products_api",
//             {
//               headers: { Authorization: `Bearer ${decryptedAccessToken}` },
//             }
//           );
//           setproducts(response.data);

//         }
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchProductCodes();
//     quotes_names();
//   }, []);

//   const getProductValues = async (productCode) => {
//     try {
//       const encryptedAccessToken = localStorage.getItem("access_token");
//       const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
//       if (encryptedAccessToken) {
//         const decryptedAccessToken = AES.decrypt(
//           encryptedAccessToken,
//           secret_key
//         ).toString(enc.Utf8);
//         const response = await axios.get(
//           `${API_URL}all/product/datas/product_code/${productCode}`,
//           {
//             headers: { Authorization: `Bearer ${decryptedAccessToken}` },
//           }
//         );

//         setProductValues(response.data);

//         return response.data;
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const [quoteShipment_name, setquoteShipment_name] = useState([]);

//   const quotes_names = async () => {
//     try {
//       const encryptedAccessToken = localStorage.getItem("access_token");
//       const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
//       if (encryptedAccessToken) {
//         const decryptedAccessToken = AES.decrypt(
//           encryptedAccessToken,
//           secret_key
//         ).toString(enc.Utf8);
//         const response = await axios.get(
//           `${API_URL}get/all/quoteshipment`,
//           {
//             headers: { Authorization: `Bearer ${decryptedAccessToken}` },
//           }
//         );

//         setquoteShipment_name(response.data);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// const [tcfData, setTcfData] = useState({
//       company: "",
//     });
//   const handleProductCodeChange = async (event) => {
//     setProductCode(event.target.value);
//     try {
//       const productValues = await getProductValues(event.target.value);
//       console.log(productValues);

//       setFormData({
//         ...formData,
//         product_code: event.target.value,
//         product_name: productValues.product_name,
//         product_account: productValues.product_account,
//         product_supplier: productValues.product_supplier,
//         product_description: productValues.product_description,
//         product_family: productValues.product_family,
//         product_shipping_term: productValues.product_shipping_term,
//         product_owner:productValues.product_owner,
//         product_active: true,
//         product_code: false,
//         product_price_basis: productValues.product_price_basis,
//         product_price_type: productValues.product_price_type,
//         product_port_of_loading: productValues.product_port_of_loading,
//         gross_air_dried_typical: productValues.gross_air_dried_typical,
//         gross_air_dried_min: productValues.gross_air_dried_min,
//         gross_air_dried_max: productValues.gross_air_dried_max,
//         gross_as_recieved_min: productValues.gross_as_recieved_min,
//         gross_as_recieved_max: productValues.gross_as_recieved_max,
//         net_as_recieved_min: productValues.net_as_recieved_min,
//         net_as_recieved_max:productValues.net_as_recieved_max,
//         moisture_typical: productValues.moisture_typical,
//       moisture_minimum: productValues.moisture_minimum,
//       ash_typical: productValues.ash_typical,
//       ash_minimum: productValues.ash_minimum,
//       ash_maximum: productValues.ash_maximum,
//       volatile_matter_typical: productValues.volatile_matter_typical,
//       volatile_matter_minimum:productValues.volatile_matter_minimum,
//       volatile_matter_maximum: productValues.volatile_matter_maximum,
//       fixed_carbon_by_difference: productValues.fixed_carbon_by_difference,
//       fixed_carbon_typical: productValues.fixed_carbon_typical,
//       fixed_carbon_minimum: productValues.fixed_carbon_minimum,
//       fixed_carbon_maximum: productValues.fixed_carbon_maximum,
//       carbon_typical: productValues.carbon_typical,
//       carbon_min:productValues.carbon_min,
//       carbon_max: productValues.carbon_max,
//       hydrogen_typical: productValues.hydrogen_typical,
//       hydrogen_min: productValues.hydrogen_min,
//       hydrogen_max: productValues.hydrogen_max,
//       nitrogen_typical:productValues.nitrogen_typical,
//       nitrogen_min: productValues.nitrogen_min,
//       nitrogen_max: productValues.nitrogen_max,
//       sulphur_typical: productValues.sulphur_typical,
//       sulphur_min: productValues.sulphur_min,
//       sulphur_max_ultimate_analysis: productValues.sulphur_max_ultimate_analysis,
//       oxygen_typical: productValues.oxygen_typical,
//       oxygen_min: productValues.oxygen_min,
//       oxygen_max: productValues.oxygen_max,
//       sulphur_typical_arb:productValues.sulphur_typical_arb,
//       quote_shipment: productValues.productValues,
//         ...productValues,
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     const encryptedAccessToken = localStorage.getItem("access_token");
//     const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

//     if (encryptedAccessToken) {
//       const decryptedAccessToken = AES.decrypt(
//         encryptedAccessToken,
//         secret_key
//       ).toString(enc.Utf8);
//       const headers = { Authorization: `Bearer ${decryptedAccessToken}` };

//       axios
//         .post(API_URL+"add/new/quote/"+id, formData, { headers})
//         .then((response) => {
//           if (response.status === 200) {
//             alert('success')
//             axios
//             .post(`${API_URL}add/tcf/${productCode}`, tcfData, {
//               headers,
//             })
//             .then((secondResponse) => {
//               console.log('hi'+JSON.stringify(secondResponse));
//             })
//             .catch((secondError) => {
//               console.log(secondError);
//             });
//         }
//           console.log(response);
//           $('.result').html(response.data.message)
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   };

//   return (
//     <>
//     <div className="shipment">
//     <Header/>
//     <div className="page-header" id=''>
//     <div>
//       <h2 className="main-content-title tx-24 mg-b-5">Quotes</h2>
//       <ol className="breadcrumb">
//         <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Quotes</li>
//       </ol>
//     </div>
//     <div className="d-flex">
//       <div className="justify-content-center">

//         {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
//          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
//         </button> */}

//       </div>
//     </div>
//   </div>
//   <div className='tasks quotes'>
//   <form onSubmit={handleSubmit}>

//    <Accordion preExpanded={['a']}>
//              <AccordionItem uuid='a'>
//                  <AccordionItemHeading>
//                      <AccordionItemButton>
//                          Quote Section 1
//                      </AccordionItemButton>
//                  </AccordionItemHeading>
//                  <AccordionItemPanel>
//                  <Row className="mb-3">
//          {/* <h4 className='heading'>Account Information</h4> */}



//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Locked"
//              className="mb-3"
//            >
//              <Form.Control
//                type="text"
//                placeholder="Locked"
//                name="locked"

//            value={formData.locked}
//            onChange={handleChange}
//                />
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Supplier"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="Supplier"
//              name="supplier"

//            value={formData.supplier}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Quotes status"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="Quotes status"
//              name="quotes_status"

//            value={formData.quotes_status}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Quotes name"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="Quotes name"
//              name="quotes_name"

//            value={formData.quotes_name}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="No of product"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="No of product "
//              name="no_of_product"

//            value={formData.no_of_product}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="No of quote shipment"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="No of quote shipment"
//              name="no_of_quote_shipment"

//            value={formData.no_of_quote_shipment}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Quote shipment"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="Quote shipment"
//              name="quote_shipment"

//            value={formData.quote_shipment}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Product code"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="Product code"
//              name="product_code"

//            value={formData.product_code}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Product name"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="Product name"
//              name="product_name"

//            value={formData.product_name}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>
//          <Form.Group as={Col} md="6">
//            <FloatingLabel
//              controlId="floatingInput"
//              label="Product account"
//              className="mb-3"
//            >

//              <Form.Control type="text"
//              placeholder="Product account"
//              name="product_account"

//            value={formData.product_account}
//            onChange={handleChange}/>
//            </FloatingLabel>
//          </Form.Group>

//          <Form.Group as={Col} md="6">

//           <select name='product_supplier' className='form-control'>
//           <option value='' >Select</option>
//        {
//           supplier.map((x)=>{
//               return(
//                   <option value={x.account_name}>{x.account_name}</option>
//               )
//           })
//        }
//           </select>
//               </Form.Group>
//          <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="product supplier"
//                      className="mb-3"


//                    >
//                      <Form.Control
//                        type="text"
//                        placeholder="product supplier"
//                        name="product_supplier"

//            value={formData.product_supplier}
//            onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>


//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Product family"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Product family"
//                      name="product_family"

//                     value={formData.product_family}
//                     onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>



//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Product active"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Product active"
//                      name="product_active"

//                     value={formData.product_active}
//                     onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Short term available"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Short term available"
//                      name="short_term_available"
//                      value={formData.short_term_available}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Product price basis"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Product price basis"
//                      name="product_price_basis"
//                      value={formData.product_price_basis}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Product price type"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Product price type"
//                      name="product_price_type"

//                      value={formData.product_price_type}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Product port of loading"
//                      className="mb-3"


//                    >
//                      <Form.Control
//                        type="text"
//                        placeholder="Product port of loading"
//                        name="product_port_of_loading"

//                      value={formData.product_port_of_loading}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>


//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Total moisture typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Total moisture typical"
//                      name="total_moisture_typical"
//                      value={formData.total_moisture_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>



//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Total moisture minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Total moisture minimum"
//                      name="total_moisture_minimum"
//                      value={formData.total_moisture_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Total moisture maximum "
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Total moisture maximum "
//                      name="total_moisture_maximum"
//                      value={formData.total_moisture_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Ash typical arb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Ash typical arb"
//                      name="ash_typical_arb"
//                      value={formData.ash_typical_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Ash minimum arb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Ash minimum arb"
//                      name="ash_minimum_arb"
//                      value={formData.ash_minimum_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>


//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur typical arb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur typical arb"
//                      name="sulphur_typical_arb"
//                      value={formData.sulphur_typical_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur minimum arb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur minimum arb"
//                      name="sulphur_minimum_arb"
//                      value={formData.sulphur_minimum_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur maximum arb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur maximum arb"
//                      name="sulphur_maximum_arb"
//                      value={formData.sulphur_maximum_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Volatile matter typical arb "
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Volatile matter typical arb "
//                      name="volatile_matter_typical_arb"
//                      value={formData.volatile_matter_typical_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>

//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Volatile matter minimum arb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Volatile matter minimum arb"
//                      name="volatile_matter_minimum_arb"
//                      value={formData.volatile_matter_minimum_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>

//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Volatile matter maximum arb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Volatile matter maximum arb"
//                      name="volatile_matter_maximum_arb"
//                      value={formData.volatile_matter_maximum_arb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Moisture typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Moisture typical"
//                      name="moisture_typical"
//                      value={formData.moisture_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Moisture minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Moisture minimum"
//                      name="moisture_minimum"
//                      value={formData.moisture_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Ash typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Ash typical"
//                      name="ash_typical"
//                      value={formData.ash_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Ash minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Ash minimum"
//                      name="ash_minimum"
//                      value={formData.ash_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Ash maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Ash maximum"
//                      name="ash_maximum"
//                      value={formData.ash_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Volatile matter typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Volatile matter typical"
//                      name="volatile_matter_typical"
//                      value={formData.volatile_matter_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Volatile matter minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Volatile matter minimum"
//                      name="volatile_matter_minimum"
//                      value={formData.volatile_matter_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Volatile matter maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Volatile matter maximum"
//                      name="volatile_matter_maximum"
//                      value={formData.volatile_matter_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Fixed carbon by difference"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Fixed carbon by difference"
//                      name="fixed_carbon_by_difference"
//                      value={formData.fixed_carbon_by_difference}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Fixed carbon typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Fixed carbon typical"
//                      name="fixed_carbon_typical"
//                      value={formData.fixed_carbon_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Fixed carbon minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Fixed carbon minimum"
//                      name="fixed_carbon_minimum"
//                      value={formData.fixed_carbon_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Fixed carbon maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Fixed carbon maximum"
//                      name="fixed_carbon_maximum"
//                      value={formData.fixed_carbon_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Gross air dried typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Gross air dried typical"
//                      name="gross_air_dried_typical"
//                      value={formData.gross_air_dried_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Gross air dried min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Gross air dried min"
//                      name="gross_air_dried_min"
//                      value={formData.gross_air_dried_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Gross air dried max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Gross air dried max"
//                      name="gross_air_dried_max"
//                      value={formData.gross_air_dried_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Gross as recieved min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Gross as recieved min"
//                      name="gross_as_recieved_min"
//                      value={formData.gross_as_recieved_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Gross as recieved max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Gross as recieved max"
//                      name="gross_as_recieved_max"
//                      value={formData.gross_as_recieved_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Net as recieved min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Net as recieved min"
//                      name="net_as_recieved_min"
//                      value={formData.net_as_recieved_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Net as recieved max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Net as recieved max"
//                      name="net_as_recieved_max"
//                      value={formData.net_as_recieved_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hgi typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hgi typical"
//                      name="hgi_typical"
//                      value={formData.hgi_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hgi min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hgi min"
//                      name="hgi_min"
//                      value={formData.hgi_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="hgi max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="hgi max"
//                      name="hgi_max"
//                      value={formData.hgi_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Carbon typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Carbon typical"
//                      name="carbon_typical"
//                      value={formData.carbon_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>


//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Carbon min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Carbon min"
//                      name="carbon_min"
//                      value={formData.carbon_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Carbon max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Carbon max"
//                      name="carbon_max"
//                      value={formData.carbon_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hydrogen typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hydrogen typical"
//                      name="hydrogen_typical"
//                      value={formData.hydrogen_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hydrogen min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hydrogen min"
//                      name="hydrogen_min"
//                      value={formData.hydrogen_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hydrogen max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hydrogen max"
//                      name="hydrogen_max"
//                      value={formData.hydrogen_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Nitrogen typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Nitrogen typical"
//                      name="nitrogen_typical"
//                      value={formData.nitrogen_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Nitrogen min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Nitrogen min"
//                      name="nitrogen_min"
//                      value={formData.nitrogen_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Nitrogen max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Nitrogen max"
//                      name="nitrogen_max"
//                      value={formData.nitrogen_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur typical"
//                      name="sulphur_typical"
//                      value={formData.sulphur_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur typical"
//                      name="sulphur_typical"
//                      value={formData.sulphur_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur max ultimate analysis"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur max ultimate analysis"
//                      name="sulphur_max_ultimate_analysis"
//                      value={formData.sulphur_max_ultimate_analysis}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Oxygen typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Oxygen typical"
//                      name="oxygen_typical"
//                      value={formData.oxygen_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Oxygen min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Oxygen min"
//                      name="oxygen_min"
//                      value={formData.oxygen_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Oxygen max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Oxygen max"
//                      name="oxygen_max"
//                      value={formData.oxygen_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur typical adb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur typical adb"
//                      name="sulphur_typical_adb"
//                      value={formData.sulphur_typical_adb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>


//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Sulphur min adb"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Sulphur min adb"
//                      name="sulphur_min_adb"
//                      value={formData.sulphur_min_adb}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Product sulphur max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Product sulphur max"
//                      name="product_sulphur_max"
//                      value={formData.product_sulphur_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Initial typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Initial typical"
//                      name="initial_typical"
//                      value={formData.initial_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Initial minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Initial minimum"
//                      name="initial_minimum"
//                      value={formData.initial_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Initial maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Initial maximum"
//                      name="initial_maximum"
//                      value={formData.initial_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Spherical typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Spherical typical"
//                      name="spherical_typical"
//                      value={formData.spherical_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Spherical minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Spherical minimum"
//                      name="spherical_minimum"
//                      value={formData.spherical_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Spherical maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Spherical maximum"
//                      name="spherical_maximum"
//                      value={formData.spherical_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hemispherical typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hemispherical typical"
//                      name="hemispherical_typical"
//                      value={formData.hemispherical_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hemispherical minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hemispherical minimum"
//                      name="hemispherical_minimum"
//                      value={formData.hemispherical_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Hemispherical maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Hemispherical maximum"
//                      name="hemispherical_maximum"
//                      value={formData.hemispherical_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Flow typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Flow typical"
//                      name="flow_typical"
//                      value={formData.flow_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="flow minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="flow minimum"
//                      name="flow_minimum"
//                      value={formData.flow_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Flow maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Flow maximum"
//                      name="flow_maximum"
//                      value={formData.flow_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising initial typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising initial typical"
//                      name="oxidising_initial_typical"
//                      value={formData.oxidising_initial_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising initial minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising initial minimum"
//                      name="oxidising_initial_minimum"
//                      value={formData.oxidising_initial_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>

//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising initial maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising initial maximum"
//                      name="oxidising_initial_maximum"
//                      value={formData.oxidising_initial_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising spherical typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising spherical typical"
//                      name="oxidising_spherical_typical"
//                      value={formData.oxidising_spherical_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising spherical minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising spherical minimum"
//                      name="oxidising_spherical_minimum"
//                      value={formData.oxidising_spherical_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising spherical maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising spherical maximum"
//                      name="oxidising_spherical_maximum"
//                      value={formData.oxidising_spherical_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising hemispherical typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising hemispherical typical"
//                      name="oxidising_hemispherical_typical"
//                      value={formData.oxidising_hemispherical_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising hemispherical minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising hemispherical minimum"
//                      name="oxidising_hemispherical_minimum"
//                      value={formData.oxidising_hemispherical_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising hemispherical maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising hemispherical maximum"
//                      name="oxidising_hemispherical_maximum"
//                      value={formData.oxidising_hemispherical_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising flow typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising flow typical"
//                      name="oxidizing_flow_typical"
//                      value={formData.oxidizing_flow_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising flow minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising flow minimum"
//                      name="oxidising_flow_minimum"
//                      value={formData.oxidising_flow_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="oxidising flow maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="oxidising flow maximum"
//                      name="oxidising_flow_maximum"
//                      value={formData.oxidising_flow_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="SiO2 Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="SiO2 Minimum"
//                      name="SiO2_Minimum"
//                      value={formData.SiO2_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="SiO2 Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="SiO2 Maximum"
//                      name="SiO2_Maximum"
//                      value={formData.SiO2_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Al2O3 Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Al2O3 Typical"
//                      name="Al2O3_Typical"
//                      value={formData.Al2O3_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Al2O3 Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Al2O3 Minimum"
//                      name="Al2O3_Minimum"
//                      value={formData.Al2O3_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Al2O3 Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Al2O3 Maximum"
//                      name="Al2O3_Maximum"
//                      value={formData.Al2O3_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Fe2O3 Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Fe2O3 Typical"
//                      name="Fe2O3_Typical"
//                      value={formData.Fe2O3_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Fe2O3 Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Fe2O3 Minimum"
//                      name="Fe2O3_Minimum"
//                      value={formData.Fe2O3_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Fe2O3 Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Fe2O3 Maximum"
//                      name="Fe2O3_Maximum"
//                      value={formData.Fe2O3_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="CaO Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="CaO Typical"
//                      name="CaO_Typical"
//                      value={formData.CaO_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="CaO Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="CaO Minimum"
//                      name="CaO_Minimum"
//                      value={formData.CaO_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="CaO Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="CaO Maximum"
//                      name="CaO_Maximum"
//                      value={formData.CaO_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>

//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="MgO Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="MgO Typical"
//                      name="MgO_Typical"
//                      value={formData.MgO_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="MgO Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="MgO Minimum"
//                      name="MgO_Minimum"
//                      value={formData.MgO_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="MgO Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="MgO Maximum"
//                      name="MgO_Maximum"
//                      value={formData.MgO_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="MnO Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="MnO Typical"
//                      name="MnO_Typical"
//                      value={formData.MnO_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="MnO Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="MnO Minimum"
//                      name="MnO_Minimum"
//                      value={formData.MnO_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="MnO Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="MnO Maximum"
//                      name="MnO_Maximum"
//                      value={formData.MnO_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="TiO2 Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="TiO2 Typical"
//                      name="TiO2_Typical"
//                      value={formData.TiO2_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="TiO2 Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="TiO2 Minimum"
//                      name="TiO2_Minimum"
//                      value={formData.TiO2_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="TiO2 Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="TiO2 Maximum"
//                      name="TiO2_Maximum"
//                      value={formData.TiO2_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Na2O Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Na2O Typical"
//                      name="Na2O_Typical"
//                      value={formData.Na2O_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Na2O Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Na2O Minimum"
//                      name="Na2O_Minimum"
//                      value={formData.Na2O_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="Na2O Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="Na2O Maximum"
//                      name="Na2O_Maximum"
//                      value={formData.Na2O_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="K2O Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="K2O Typical"
//                      name="K2O_Typical"
//                      value={formData.K2O_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="K2O Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="K2O Minimum"
//                      name="K2O_Minimum"
//                      value={formData.K2O_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="K2O Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="K2O Maximum"
//                      name="K2O_Maximum"
//                      value={formData.K2O_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="P2O5 Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="P2O5 Typical"
//                      name="P2O5_Typical"
//                      value={formData.P2O5_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="P2O5 Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="P2O5 Minimum"
//                      name="P2O5_Minimum"
//                      value={formData.P2O5_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="P2O5 Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="P2O5 Maximum"
//                      name="P2O5_Maximum"
//                      value={formData.P2O5_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="SO3 Typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="SO3 Typical"
//                      name="SO3_Typical"
//                      value={formData.SO3_Typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="SO3 Minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="SO3 Minimum"
//                      name="SO3_Minimum"
//                      value={formData.SO3_Minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="SO3 Maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="SO3 Maximum"
//                      name="SO3_Maximum"
//                      value={formData.SO3_Maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te arsenic as typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te arsenic as typical in air dried coal"
//                      name="te_arsenic_as_typical_in_air_dried_coal"
//                      value={formData.te_arsenic_as_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te arsenic as minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te arsenic as minimum in air dried coal"
//                      name="te_arsenic_as_minimum_in_air_dried_coal"
//                      value={formData.te_arsenic_as_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te arsenic as maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te arsenic as maximum in air dried coal"
//                      name="te_arsenic_as_maximum_in_air_dried_coal"
//                      value={formData.te_arsenic_as_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te antimony sb typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te antimony sb typical in air dried coal"
//                      name="te_antimony_sb_typical_in_air_dried_coal"
//                      value={formData.te_antimony_sb_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te antimony sb minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te antimony sb minimum in air dried coal"
//                      name="te_antimony_sb_minimum_in_air_dried_coal"
//                      value={formData.te_antimony_sb_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te antimony sb maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te antimony sb maximum in air dried coal"
//                      name="te_antimony_sb_maximum_in_air_dried_coal"
//                      value={formData.te_antimony_sb_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te beryllium be typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te beryllium be typical in air dried coal"
//                      name="te_beryllium_be_typical_in_air_dried_coal"
//                      value={formData.te_beryllium_be_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te beryllium be minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te beryllium be minimum in air dried coal"
//                      name="te_beryllium_be_minimum_in_air_dried_coal"
//                      value={formData.te_beryllium_be_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te beryllium be maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te beryllium be maximum in air dried coal"
//                      name="te_beryllium_be_maximum_in_air_dried_coal"
//                      value={formData.te_beryllium_be_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te boron b typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te boron b typical in air dried coal"
//                      name="te_boron_b_typical_in_air_dried_coal"
//                      value={formData.te_boron_b_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te boron b minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te boron b minimum in air dried coal"
//                      name="te_boron_b_minimum_in_air_dried_coal"
//                      value={formData.te_boron_b_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te boron b maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te boron b maximum in air dried coal"
//                      name="te_boron_b_maximum_in_air_dried_coal"
//                      value={formData.te_boron_b_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te cadmium cd typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te cadmium cd typical in air dried coal"
//                      name="te_cadmium_cd_typical_in_air_dried_coal"
//                      value={formData.te_cadmium_cd_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te cadmium cd minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te cadmium cd minimum in air dried coal"
//                      name="te_cadmium_cd_minimum_in_air_dried_coal"
//                      value={formData.te_cadmium_cd_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te cadmium cd maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te cadmium cd maximum in air dried coal"
//                      name="te_cadmium_cd_maximum_in_air_dried_coal"
//                      value={formData.te_cadmium_cd_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chromium cr typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chromium cr typical in air dried coal"
//                      name="te_chromium_cr_typical_in_air_dried_coal"
//                      value={formData.te_chromium_cr_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chromium cr minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chromium cr minimum in air dried coal"
//                      name="te_chromium_cr_minimum_in_air_dried_coal"
//                      value={formData.te_chromium_cr_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chromium cr maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chromium cr maximum in air dried coal"
//                      name="te_chromium_cr_maximum_in_air_dried_coal"
//                      value={formData.te_chromium_cr_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te copper cu typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te copper cu typical in air dried coal"
//                      name="te_copper_cu_typical_in_air_dried_coal"
//                      value={formData.te_copper_cu_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te copper cu minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te copper cu minimum in air dried coal"
//                      name="te_copper_cu_minimum_in_air_dried_coal"
//                      value={formData.te_copper_cu_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te copper cu maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te copper cu maximum in air dried coal"
//                      name="te_copper_cu_maximum_in_air_dried_coal"
//                      value={formData.te_copper_cu_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chlorine cl typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chlorine cl typical in air dried coal"
//                      name="te_chlorine_cl_typical_in_air_dried_coal"
//                      value={formData.te_chlorine_cl_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chlorine cl minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chlorine cl minimum in air dried coal"
//                      name="te_chlorine_cl_minimum_in_air_dried_coal"
//                      value={formData.te_chlorine_cl_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chlorine cl maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chlorine cl maximum in air dried coal"
//                      name="te_chlorine_cl_maximum_in_air_dried_coal"
//                      value={formData.te_chlorine_cl_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te fluorine f typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te fluorine f typical in air dried coal"
//                      name="te_fluorine_f_typical_in_air_dried_coal"
//                      value={formData.te_fluorine_f_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te fluorine f minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te fluorine f minimum in air dried coal"
//                      name="te_fluorine_f_minimum_in_air_dried_coal"
//                      value={formData.te_fluorine_f_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te fluorine f maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te fluorine f maximum in air dried coal"
//                      name="te_fluorine_f_maximum_in_air_dried_coal"
//                      value={formData.te_fluorine_f_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te mercury hg typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te mercury hg typical in air dried coal"
//                      name="te_mercury_hg_typical_in_air_dried_coal"
//                      value={formData.te_mercury_hg_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te mercury hg minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te mercury hg minimum in air dried coal"
//                      name="te_mercury_hg_minimum_in_air_dried_coal"
//                      value={formData.te_mercury_hg_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te mercury hg maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te mercury hg maximum in air dried coal"
//                      name="te_mercury_hg_maximum_in_air_dried_coal"
//                      value={formData.te_mercury_hg_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te molybdenum mo typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te molybdenum mo typical in air dried coal"
//                      name="te_molybdenum_mo_typical_in_air_dried_coal"
//                      value={formData.te_molybdenum_mo_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te molybdenum mo minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te molybdenum mo minimum in air dried coal"
//                      name="te_molybdenum_mo_minimum_in_air_dried_coal"
//                      value={formData.te_molybdenum_mo_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te molybdenum mo maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te molybdenum mo maximum in air dried coal"
//                      name="te_molybdenum_mo_maximum_in_air_dried_coal"
//                      value={formData.te_molybdenum_mo_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te lead pb typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te lead pb typical in air dried coal"
//                      name="te_lead_pb_typical_in_air_dried_coal"
//                      value={formData.te_lead_pb_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te lead pb minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te lead pb minimum in air dried coal"
//                      name="te_lead_pb_minimum_in_air_dried_coal"
//                      value={formData.te_lead_pb_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te lead pb maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te lead pb maximum in air dried coal"
//                      name="te_lead_pb_maximum_in_air_dried_coal"
//                      value={formData.te_lead_pb_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te phosphorus p typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te phosphorus p typical in air dried coal"
//                      name="te_phosphorus_p_typical_in_air_dried_coal"
//                      value={formData.te_phosphorus_p_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te phosphorus p minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te phosphorus p minimum in air dried coal"
//                      name="te_phosphorus_p_minimum_in_air_dried_coal"
//                      value={formData.te_phosphorus_p_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te phosphorus p maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te phosphorus p maximum in air dried coal"
//                      name="te_phosphorus_p_maximum_in_air_dried_coal"
//                      value={formData.te_phosphorus_p_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te selenium se typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te selenium se typical in air dried coal"
//                      name="te_selenium_se_typical_in_air_dried_coal"
//                      value={formData.te_selenium_se_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te selenium se minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te selenium se minimum in air dried coal"
//                      name="te_selenium_se_minimum_in_air_dried_coal"
//                      value={formData.te_selenium_se_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te selenium se maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te selenium se maximum in air dried coal"
//                      name="te_selenium_se_maximum_in_air_dried_coal"
//                      value={formData.te_selenium_se_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te vanadium v typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te vanadium v typical in air dried coal"
//                      name="te_vanadium_v_typical_in_air_dried_coal"
//                      value={formData.te_vanadium_v_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te vanadium v minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te vanadium v minimum in air dried coal"
//                      name="te_vanadium_v_minimum_in_air_dried_coal"
//                      value={formData.te_vanadium_v_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te vanadium v maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te vanadium v maximum in air dried coal"
//                      name="te_vanadium_v_maximum_in_air_dried_coal"
//                      value={formData.te_vanadium_v_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te zinc zn typical in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te zinc zn typical in air dried coal"
//                      name="te_zinc_zn_typical_in_air_dried_coal"
//                      value={formData.te_zinc_zn_typical_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te zinc zn minimum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te zinc zn minimum in air dried coal"
//                      name="te_zinc_zn_minimum_in_air_dried_coal"
//                      value={formData.te_zinc_zn_minimum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te zinc zn maximum in air dried coal"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te zinc zn maximum in air dried coal"
//                      name="te_zinc_zn_maximum_in_air_dried_coal"
//                      value={formData.te_zinc_zn_maximum_in_air_dried_coal}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te arsenic as typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te arsenic as typical"
//                      name="te_arsenic_as_typical"
//                      value={formData.te_arsenic_as_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te arsenic as minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te arsenic as minimum"
//                      name="te_arsenic_as_minimum"
//                      value={formData.te_arsenic_as_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te arsenic as maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te arsenic as maximum"
//                      name="te_arsenic_as_maximum"
//                      value={formData.te_arsenic_as_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te antimony sb typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te antimony sb typical"
//                      name="te_antimony_sb_typical"
//                      value={formData.te_antimony_sb_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te antimony sb minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te antimony sb minimum"
//                      name="te_antimony_sb_minimum"
//                      value={formData.te_antimony_sb_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te antimony sb maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te antimony sb maximum"
//                      name="te_antimony_sb_maximum"
//                      value={formData.te_antimony_sb_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te boron b typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te boron b typical"
//                      name="te_boron_b_typical"
//                      value={formData.te_boron_b_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te boron b maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te boron b maximum"
//                      name="te_boron_b_maximum"
//                      value={formData.te_boron_b_maximum}
//                      onChange={handleChange}/>
//                      </FloatingLabel>
//                      </Form.Group>
//                      <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te cadmium cd typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te cadmium cd typical"
//                      name="te_cadmium_cd_typical"
//                      value={formData.te_cadmium_cd_typical}
//                      onChange={handleChange}/>
//                       </FloatingLabel>
//                       </Form.Group>
//                       <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te cadmium cd minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te cadmium cd minimum"
//                      name="te_cadmium_cd_minimum"
//                      value={formData.te_cadmium_cd_minimum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te cadmium cd maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te cadmium cd maximum"
//                      name="te_cadmium_cd_maximum"
//                      value={formData.te_cadmium_cd_maximum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chromium cr typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chromium cr typical"
//                      name="te_chromium_cr_typical"
//                      value={formData.te_chromium_cr_typical}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chromium cr minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chromium cr minimum"
//                      name="te_chromium_cr_minimum"
//                      value={formData.te_chromium_cr_minimum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chromium cr maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chromium cr maximum"
//                      name="te_chromium_cr_maximum"
//                      value={formData.te_chromium_cr_maximum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te copper cu typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te copper cu typical"
//                      name="te_copper_cu_typical"
//                      value={formData.te_copper_cu_typical}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te copper cu minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te copper cu minimum"
//                      name="te_copper_cu_minimum"
//                      value={formData.te_copper_cu_minimum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te copper cu maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te copper cu_maximum"
//                      name="te_copper_cu_maximum"
//                      value={formData.te_copper_cu_maximum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chlorine cl typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chlorine cl typical"
//                      name="te_chlorine_cl_typical"
//                      value={formData.te_chlorine_cl_typical}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chlorine cl minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chlorine cl minimum"
//                      name="te_chlorine_cl_minimum"
//                      value={formData.te_chlorine_cl_minimum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te chlorine cl maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te chlorine cl maximum"
//                      name="te_chlorine_cl_maximum"
//                      value={formData.te_chlorine_cl_maximum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te fluorine f typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te fluorine f typical"
//                      name="te_fluorine_f_typical"
//                      value={formData.te_fluorine_f_typical}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te fluorine f minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te fluorine f minimum"
//                      name="te_fluorine_f_minimum"
//                      value={formData.te_fluorine_f_minimum}
//                      onChange={handleChange}/></FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te fluorine f maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te fluorine f maximum"
//                      name="te_fluorine_f_maximum"
//                      value={formData.te_fluorine_f_maximum}
//                      onChange={handleChange}/>
//                      </FloatingLabel>
//                      </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te mercury hg typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te mercury hg typical"
//                      name="te_mercury_hg_typical"
//                      value={formData.te_mercury_hg_typical}
//                      onChange={handleChange}/></FloatingLabel></Form.Group>
//                      <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te mercury hg minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te mercury hg minimum"
//                      name="te_mercury_hg_minimum"
//                      value={formData.te_mercury_hg_minimum}
//                      onChange={handleChange}/>
//                      </FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te mercury hg maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te mercury hg maximum"
//                      name="te_mercury_hg_maximum"
//                      value={formData.te_mercury_hg_maximum}
//                      onChange={handleChange}/>
//                     </FloatingLabel> </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te molybdenum mo typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te molybdenum mo typical"
//                      name="te_molybdenum_mo_typical"
//                      value={formData.te_molybdenum_mo_typical}
//                      onChange={handleChange}/>
//                      </FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te molybdenum mo minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te molybdenum mo minimum"
//                      name="te_molybdenum_mo_minimum"
//                      value={formData.te_molybdenum_mo_minimum}
//                      onChange={handleChange}/>
//                     </FloatingLabel>
//                      </Form.Group>
//                      <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te molybdenum mo maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te molybdenum mo maximum"
//                      name="te_molybdenum_mo_maximum"
//                      value={formData.te_molybdenum_mo_maximum}
//                      onChange={handleChange}/>
//                      </FloatingLabel>
//                      </Form.Group>
//                      <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te lead pb typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te lead pb typical"
//                      name="te_lead_pb_typical"
//                      value={formData.te_lead_pb_typical}
//                      onChange={handleChange}/>
//                      </FloatingLabel></Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te lead pb maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te lead pb maximum"
//                      name="te_lead_pb_maximum"
//                      value={formData.te_lead_pb_maximum}
//                      onChange={handleChange}/>
//                      </FloatingLabel></Form.Group>
//                      <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te phosphorus p typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te phosphorus p typical"
//                      name="te_phosphorus_p_typical"
//                      value={formData.te_phosphorus_p_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te phosphorus p minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te phosphorus p minimum"
//                      name="te_phosphorus_p_minimum"
//                      value={formData.te_phosphorus_p_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//                  <Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te phosphorus p maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te phosphorus p maximum"
//                      name="te_phosphorus_p_maximum"
//                      value={formData.te_phosphorus_p_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te selenium se typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te selenium se typical"
//                      name="te_selenium_se_typical"
//                      value={formData.te_selenium_se_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te selenium se minimum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te selenium se minimum"
//                      name="te_selenium_se_minimum"
//                      value={formData.te_selenium_se_minimum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te selenium se maximum"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te selenium se maximum"
//                      name="te_selenium_se_maximum"
//                      value={formData.te_selenium_se_maximum}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te vanadium v typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te vanadium v typical"
//                      name="te_vanadium_v_typical"
//                      value={formData.te_vanadium_v_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te vanadium v min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te vanadium v min"
//                      name="te_vanadium_v_min"
//                      value={formData.te_vanadium_v_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te vanadium v max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te vanadium v max"
//                      name="te_vanadium_v_max"
//                      value={formData.te_vanadium_v_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te zinc zn typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te zinc zn typical"
//                      name="te_zinc_zn_typical"
//                      value={formData.te_zinc_zn_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te zinc zn min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te zinc zn min"
//                      name="te_zinc_zn_min"
//                      value={formData.te_zinc_zn_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="te zinc zn max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="te zinc zn max"
//                      name="te_zinc_zn_max"
//                      value={formData.te_zinc_zn_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="size 0 to 50mm typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="size 0 to 50mm typical"
//                      name="size_0_to_50mm_typical"
//                      value={formData.size_0_to_50mm_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="size 0 to 50mm min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="size 0 to 50mm min"
//                      name="size_0_to_50mm_min"
//                      value={formData.size_0_to_50mm_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="size 0 to 50mm max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="size 0 to 50mm max"
//                      name="size_0_to_50mm_max"
//                      value={formData.size_0_to_50mm_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="above 0 to 50mm typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="above 0 to 50mm typical"
//                      name="above_0_to_50mm_typical"
//                      value={formData.above_0_to_50mm_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="above 0 to 50mm min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="above 0 to 50mm min"
//                      name="above_0_to_50mm_min"
//                      value={formData.above_0_to_50mm_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="above 0 to 50mm max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="above 0 to 50mm max"
//                      name="above_0_to_50mm_max"
//                      value={formData.above_0_to_50mm_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="under 2mm typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="under 2mm typical"
//                      name="under_2mm_typical"
//                      value={formData.under_2mm_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="under 2mm min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="under 2mm min"
//                      name="under_2mm_min"
//                      value={formData.under_2mm_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="under 2mm max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="under 2mm max"
//                      name="under_2mm_max"
//                      value={formData.under_2mm_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="size above 2mm typical"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="size above 2mm typical"
//                      name="size_above_2mm_typical"
//                      value={formData.size_above_2mm_typical}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="size above 2mm min"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="size above 2mm min"
//                      name="size_above_2mm_min"
//                      value={formData.size_above_2mm_min}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="size_above_2mm_max"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="size_above_2mm_max"
//                      name="size_above_2mm_max"
//                      value={formData.size_above_2mm_max}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group><Form.Group as={Col} md="6">
//                    <FloatingLabel
//                      controlId="floatingInput"
//                      label="testing method"
//                      className="mb-3"
//                    >

//                      <Form.Control type="text"
//                      placeholder="testing method"
//                      name="testing_method"
//                      value={formData.testing_method}
//                      onChange={handleChange}/>
//                    </FloatingLabel>
//                  </Form.Group>
//        </Row>

//                  </AccordionItemPanel>
//              </AccordionItem>
//              <AccordionItem>
//                  <AccordionItemHeading>
//                      <AccordionItemButton>
//                          Quote Section 2
//                      </AccordionItemButton>
//                  </AccordionItemHeading>
//                  <AccordionItemPanel>
//                  <div className='row'>
//
//       </div>
//  <div>
//        <button type="submit" className='btn btn-primary add-quote'>Save</button>
//        <p className='result'></p>
//    </div>    </AccordionItemPanel>
//              </AccordionItem>
//          </Accordion>

//          </form>
//            </div>
//       <Footer/>
//       </div>
//       </>
//   );
// };
// quote_product_code =only in detail page

//     locked :data.locked,
//     supplier :data.supplier,
//     quotes_status :data.quotes_status,
//     quotes_name :data.quotes_name,

//     no_of_product :data.no_of_product,
//     no_of_quote_shipment :data.no_of_quote_shipment,

//     quote_shipment :data.quote_shipment,
//     product_code :data.product_code,
//     product_name :data.product_name,
//     product_account :data.product_account,
//     product_supplier :data.product_supplier,
//     product_description :data.product_description,
//     product_family :data.product_family,
//     product_shipping_term :data.product_shipping_term,
//     product_owner :data.product_owner,
//     product_active :data.product_active,
//     short_term_available :data.short_term_available,
//     product_price_basis :data.product_price_basis,
//     product_price_type :data.product_price_type,
//     product_port_of_loading :data.product_port_of_loading,
//     total_moisture_typical :data.total_moisture_typical,
//     total_moisture_minimum :data.total_moisture_minimum,
//     total_moisture_maximum :data.total_moisture_maximum,
//     ash_typical_arb :data.ash_typical_arb,
//     ash_minimum_arb :data.ash_minimum_arb,
//     ash_maximum_arb :data.ash_maximum_arb,
//     sulphur_typical_arb :data.sulphur_typical_arb,
//     sulphur_minimum_arb :data.sulphur_minimum_arb,
//     sulphur_maximum_arb :data.sulphur_maximum_arb,
//     volatile_matter_typical_arb :data.volatile_matter_typical_arb,
//     volatile_matter_minimum_arb :data.volatile_matter_minimum_arb,
//     volatile_matter_maximum_arb :data.volatile_matter_maximum_arb,
//     moisture_typical :data.moisture_typical,
//     moisture_minimum :data.moisture_minimum,
//     ash_typical :data.ash_typical,
//     ash_minimum :data.ash_minimum,
//     ash_maximum :data.ash_maximum,
//     volatile_matter_typical_ADB :data.volatile_matter_typical_ADB,
//     volatile_matter_minimum :data.volatile_matter_minimum,
//     volatile_matter_maximum :data.volatile_matter_maximum,
//     fixed_carbon_by_difference :data.fixed_carbon_by_difference,
//     fixed_carbon_typical_ADB :data.fixed_carbon_typical_ADB,
//     fixed_carbon_minimum :data.fixed_carbon_minimum,
//     fixed_carbon_maximum :data.fixed_carbon_maximum,
//     gross_air_dried_typical :data.gross_air_dried_typical,
//     gross_air_dried_min :data.gross_air_dried_min,
//     gross_air_dried_max :data.gross_air_dried_max,
//     gross_as_recieved_min :data.gross_as_recieved_min,
//     gross_as_recieved_max :data.gross_as_recieved_max,
//     gross_as_recieved_typical :data.gross_as_recieved_typical,
//     net_as_recieved_typical :data.net_as_recieved_typical,
//     net_as_recieved_min :data.net_as_recieved_min,
//     net_as_recieved_max :data.net_as_recieved_max,
//     hgi_typical :data.hgi_typical,
//     hgi_min :data.hgi_min,
//     hgi_max :data.hgi_max,
//     carbon_typical :data.carbon_typical,
//     carbon_min :data.carbon_min,
//     carbon_max :data.carbon_max,
//     hydrogen_typical :data.hydrogen_typical,
//     hydrogen_min :data.hydrogen_min,
//     hydrogen_max :data.hydrogen_max,

//     nitrogen_typical :data.nitrogen_typical,
//     nitrogen_min :data.nitrogen_min,
//     nitrogen_max :data.nitrogen_max,

//     sulphur_typical :data.sulphur_typical,
//     sulphur_min :data.sulphur_min,
//     sulphur_max_ultimate_analysis :data.sulphur_max_ultimate_analysis,
//     oxygen_typical :data.oxygen_typical,
//     oxygen_min :data.oxygen_min,
//     oxygen_max :data.oxygen_max,

//     sulphur_typical_adb :data.sulphur_typical_adb,
//     sulphur_min_adb :data.sulphur_min_adb,
//     product_sulphur_max :data.product_sulphur_max,

//     initial_typical :data.initial_typical,
//     initial_minimum :data.initial_minimum,
//     initial_maximum :data.initial_maximum,
//     spherical_typical :data.spherical_typical,
//     spherical_minimum :data.spherical_minimum,
//     spherical_maximum :data.spherical_maximum,
//     hemispherical_typical :data.hemispherical_typical,
//     hemispherical_minimum :data.hemispherical_minimum,
//     hemispherical_maximum :data.hemispherical_maximum,
//     flow_typical :data.flow_typical,
//     flow_minimum :data.flow_minimum,
//     flow_maximum :data.flow_maximum,

//     oxidising_initial_typical :data.oxidising_initial_typical,
//     oxidising_initial_minimum :data.oxidising_initial_minimum,
//     oxidising_initial_maximum :data.oxidising_initial_maximum,
//     oxidising_spherical_typical :data.oxidising_spherical_typical,
//     oxidising_spherical_minimum :data.oxidising_spherical_minimum,
//     oxidising_spherical_maximum :data.oxidising_spherical_maximum,
//     oxidising_hemispherical_typical :data.oxidising_hemispherical_typical,
//     oxidising_hemispherical_minimum :data.oxidising_hemispherical_minimum,
//     oxidising_hemispherical_maximum :data.oxidising_hemispherical_maximum,
//     oxidizing_flow_typical :data.oxidizing_flow_typical,
//     oxidising_flow_minimum :data.oxidising_flow_minimum,
//     oxidising_flow_maximum :data.oxidising_flow_maximum,

//     SiO2_Typical :data.SiO2_Typical,
//     SiO2_Minimum :data.SiO2_Minimum,
//     SiO2_Maximum :data.SiO2_Maximum,
//     Al2O3_Typical :data.Al2O3_Typical,
//     Al2O3_Minimum :data.Al2O3_Minimum,
//     Al2O3_Maximum :data.Al2O3_Maximum,
//     Fe2O3_Typical :data.Fe2O3_Typical,
//     Fe2O3_Minimum :data.Fe2O3_Minimum,
//     Fe2O3_Maximum :data.Fe2O3_Maximum,
//     CaO_Typical :data.CaO_Typical,
//     CaO_Minimum :data.CaO_Minimum,
//     CaO_Maximum :data.CaO_Maximum,
//     MgO_Typical :data.MgO_Typical,
//     MgO_Minimum :data.MgO_Minimum,
//     MgO_Maximum :data.MgO_Maximum,
//     MnO_Typical :data.MnO_Typical,
//     MnO_Minimum :data.MnO_Minimum,
//     MnO_Maximum :data.MnO_Maximum,
//     TiO2_Typical :data.TiO2_Typical,
//     TiO2_Minimum :data.TiO2_Minimum,
//     TiO2_Maximum :data.TiO2_Maximum,
//     Na2O_Typical :data.Na2O_Typical,
//     Na2O_Minimum :data.Na2O_Minimum,
//     Na2O_Maximum :data.Na2O_Maximum,
//     K2O_Typical :data.K2O_Typical,
//     K2O_Minimum :data.K2O_Minimum,
//     K2O_Maximum :data.K2O_Maximum,
//     P2O5_Typical :data.P2O5_Typical,
//     P2O5_Minimum :data.P2O5_Minimum,
//     P2O5_Maximum :data.P2O5_Maximum,
//     SO3_Typical :data.SO3_Typical,
//     SO3_Minimum :data.SO3_Minimum,
//     SO3_Maximum :data.SO3_Maximum,
//     te_arsenic_as_typical_in_air_dried_coal :data.te_arsenic_as_typical_in_air_dried_coal,
//     te_arsenic_as_minimum_in_air_dried_coal :data.te_arsenic_as_minimum_in_air_dried_coal,
//     te_arsenic_as_maximum_in_air_dried_coal :data.te_arsenic_as_maximum_in_air_dried_coal,
//     te_antimony_sb_typical_in_air_dried_coal :data.te_antimony_sb_typical_in_air_dried_coal,
//     te_antimony_sb_minimum_in_air_dried_coal :data.te_antimony_sb_minimum_in_air_dried_coal,
//     te_antimony_sb_maximum_in_air_dried_coal :data.te_antimony_sb_maximum_in_air_dried_coal,
//     te_beryllium_be_typical_in_air_dried_coal :data.te_beryllium_be_typical_in_air_dried_coal,
//     te_beryllium_be_minimum_in_air_dried_coal :data.te_beryllium_be_minimum_in_air_dried_coal,
//     te_beryllium_be_maximum_in_air_dried_coal :data.te_beryllium_be_maximum_in_air_dried_coal,
//     te_boron_b_typical_in_air_dried_coal :data.te_boron_b_typical_in_air_dried_coal,
//     te_boron_b_minimum_in_air_dried_coal :data.te_boron_b_minimum_in_air_dried_coal,
//     te_boron_b_maximum_in_air_dried_coal :data.te_boron_b_maximum_in_air_dried_coal,
//     te_cadmium_cd_typical_in_air_dried_coal :data.te_cadmium_cd_typical_in_air_dried_coal,
//     te_cadmium_cd_minimum_in_air_dried_coal :data.te_cadmium_cd_minimum_in_air_dried_coal,
//     te_cadmium_cd_maximum_in_air_dried_coal :data.te_cadmium_cd_maximum_in_air_dried_coal,
//     te_chromium_cr_typical_in_air_dried_coal :data.te_chromium_cr_typical_in_air_dried_coal,
//     te_chromium_cr_minimum_in_air_dried_coal :data.te_chromium_cr_minimum_in_air_dried_coal,
//     te_chromium_cr_maximum_in_air_dried_coal :data.te_chromium_cr_maximum_in_air_dried_coal,
//     te_copper_cu_typical_in_air_dried_coal :data.te_copper_cu_typical_in_air_dried_coal,
//     te_copper_cu_minimum_in_air_dried_coal :data.te_copper_cu_minimum_in_air_dried_coal,
//     te_copper_cu_maximum_in_air_dried_coal :data.te_copper_cu_maximum_in_air_dried_coal,
//     te_chlorine_cl_typical_in_air_dried_coal :data.te_chlorine_cl_typical_in_air_dried_coal,
//     te_chlorine_cl_minimum_in_air_dried_coal :data.te_chlorine_cl_minimum_in_air_dried_coal,
//     te_chlorine_cl_maximum_in_air_dried_coal :data.te_chlorine_cl_maximum_in_air_dried_coal,
//     te_fluorine_f_typical_in_air_dried_coal :data.te_fluorine_f_typical_in_air_dried_coal,
//     te_fluorine_f_minimum_in_air_dried_coal :data.te_fluorine_f_minimum_in_air_dried_coal,
//     te_fluorine_f_maximum_in_air_dried_coal :data.te_fluorine_f_maximum_in_air_dried_coal,
//     te_mercury_hg_typical_in_air_dried_coal :data.te_mercury_hg_typical_in_air_dried_coal,
//     te_mercury_hg_minimum_in_air_dried_coal :data.te_mercury_hg_minimum_in_air_dried_coal,
//     te_mercury_hg_maximum_in_air_dried_coal :data.te_mercury_hg_maximum_in_air_dried_coal,
//     te_molybdenum_mo_typical_in_air_dried_coal :data.te_molybdenum_mo_typical_in_air_dried_coal,
//     te_molybdenum_mo_minimum_in_air_dried_coal :data.te_molybdenum_mo_minimum_in_air_dried_coal,
//     te_molybdenum_mo_maximum_in_air_dried_coal :data.te_molybdenum_mo_maximum_in_air_dried_coal,
//     te_lead_pb_typical_in_air_dried_coal :data.te_lead_pb_typical_in_air_dried_coal,
//     te_lead_pb_minimum_in_air_dried_coal :data.te_lead_pb_minimum_in_air_dried_coal,
//     te_lead_pb_maximum_in_air_dried_coal :data.te_lead_pb_maximum_in_air_dried_coal,
//     te_phosphorus_p_typical_in_air_dried_coal :data.te_phosphorus_p_typical_in_air_dried_coal,
//     te_phosphorus_p_minimum_in_air_dried_coal :data.te_phosphorus_p_minimum_in_air_dried_coal,
//     te_phosphorus_p_maximum_in_air_dried_coal :data.te_phosphorus_p_maximum_in_air_dried_coal,
//     te_selenium_se_typical_in_air_dried_coal :data.te_selenium_se_typical_in_air_dried_coal,
//     te_selenium_se_minimum_in_air_dried_coal :data.te_selenium_se_minimum_in_air_dried_coal,
//     te_selenium_se_maximum_in_air_dried_coal :data.te_selenium_se_maximum_in_air_dried_coal,
//     te_vanadium_v_typical_in_air_dried_coal :data.te_vanadium_v_typical_in_air_dried_coal,
//     te_vanadium_v_minimum_in_air_dried_coal :data.te_vanadium_v_minimum_in_air_dried_coal,
//     te_vanadium_v_maximum_in_air_dried_coal :data.te_vanadium_v_maximum_in_air_dried_coal,
//     te_zinc_zn_typical_in_air_dried_coal :data.te_zinc_zn_typical_in_air_dried_coal,
//     te_zinc_zn_minimum_in_air_dried_coal :data.te_zinc_zn_minimum_in_air_dried_coal,
//     te_zinc_zn_maximum_in_air_dried_coal :data.te_zinc_zn_maximum_in_air_dried_coal,

//     te_arsenic_as_typical :data.te_arsenic_as_typical,
//     te_arsenic_as_minimum :data.te_arsenic_as_minimum,
//     te_arsenic_as_maximum :data.te_arsenic_as_maximum,
//     te_antimony_sb_typical :data.te_antimony_sb_typical,
//     te_antimony_sb_minimum :data.te_antimony_sb_minimum,
//     te_antimony_sb_maximum :data.te_antimony_sb_maximum,
//     te_boron_b_typical :data.te_boron_b_typical,
//     te_boron_b_minimum :data.te_boron_b_minimum,
//     te_boron_b_maximum :data.te_boron_b_maximum,

//     te_beryllium_b_typical :data.te_beryllium_b_typical,
//     te_beryllium_b_minimum :data.te_beryllium_b_minimum,
//     te_beryllium_b_maximum :data.te_beryllium_b_maximum,

//     te_cadmium_cd_typical :data.te_cadmium_cd_typical,
//     te_cadmium_cd_minimum :data.te_cadmium_cd_minimum,
//     te_cadmium_cd_maximum :data.te_cadmium_cd_maximum,
//     te_chromium_cr_typical :data.te_chromium_cr_typical,
//     te_chromium_cr_minimum :data.te_chromium_cr_minimum,
//     te_chromium_cr_maximum :data.te_chromium_cr_maximum,
//     te_copper_cu_typical :data.te_copper_cu_typical,
//     te_copper_cu_minimum :data.te_copper_cu_minimum,
//     te_copper_cu_maximum :data.te_copper_cu_maximum,
//     te_chlorine_cl_typical :data.te_chlorine_cl_typical,
//     te_chlorine_cl_minimum :data.te_chlorine_cl_minimum,
//     te_chlorine_cl_maximum :data.te_chlorine_cl_maximum,
//     te_fluorine_f_typical :data.te_fluorine_f_typical,
//     te_fluorine_f_minimum :data.te_fluorine_f_minimum,
//     te_fluorine_f_maximum :data.te_fluorine_f_maximum,
//     te_mercury_hg_typical :data.te_mercury_hg_typical,
//     te_mercury_hg_minimum :data.te_mercury_hg_minimum,
//     te_mercury_hg_maximum :data.te_mercury_hg_maximum,
//     te_molybdenum_mo_typical :data.te_molybdenum_mo_typical,
//     te_molybdenum_mo_minimum :data.te_molybdenum_mo_minimum,
//     te_molybdenum_mo_maximum :data.te_molybdenum_mo_maximum,
//     te_lead_pb_typical :data.te_lead_pb_typical,
//     te_lead_pb_minimum :data.te_lead_pb_minimum,
//     te_lead_pb_maximum :data.te_lead_pb_maximum,
//     te_phosphorus_p_typical :data.te_phosphorus_p_typical,
//     te_phosphorus_p_minimum :data.te_phosphorus_p_minimum,
//     te_phosphorus_p_maximum :data.te_phosphorus_p_maximum,
//     te_selenium_se_typical :data.te_selenium_se_typical,
//     te_selenium_se_minimum :data.te_selenium_se_minimum,
//     te_selenium_se_maximum :data.te_selenium_se_maximum,
//     te_vanadium_v_typical :data.te_vanadium_v_typical,
//     te_vanadium_v_min :data.te_vanadium_v_min,
//     te_vanadium_v_max :data.te_vanadium_v_max,
//     te_zinc_zn_typical :data.te_zinc_zn_typical,
//     te_zinc_zn_min :data.te_zinc_zn_min,
//     te_zinc_zn_max :data.te_zinc_zn_max,

//     ash_rejection_ARB :data.ash_rejection_ARB,
//     volatile_matter_rejection_ARB :data.volatile_matter_rejection_ARB,
//     total_sulphur_rejection_ARB :data.total_sulphur_rejection_ARB,
//     total_moisture_rejection :data.total_moisture_rejection,
//     inherent_moisture_rejection :data.inherent_moisture_rejection,
//     ash_content_rejection :data.ash_content_rejection,
//     volatile_matter_rejection_ADB :data.volatile_matter_rejection_ADB,
//     fixed_carbon_rejection_ADB  :data.fixed_carbon_rejection_ADB,
//     gross_as_received_rejection :data.gross_as_received_rejection,
//     gross_air_dried_rejection :data.gross_air_dried_rejection,
//     net_as_received_rejection :data.net_as_received_rejection,
//     hgi_rejection :data.hgi_rejection,
//     carbon_rejection :data.carbon_rejection,
//     hydrogen_rejection :data.hydrogen_rejection,
//     nitrogen_rejection :data.nitrogen_rejection,
//     oxygen_rejection :data.oxygen_rejection,
//     sulphur_rejection_ADB :data.sulphur_rejection_ADB,
//     initial_deformation_rejection :data.initial_deformation_rejection,
//     spherical_rejection :data.spherical_rejection,
//     hemispherical_rejection :data.spherical_rejection,
//     flow_rejection :data.flow_rejection,
//     oxidizing_initial_deformation_rejection :data.oxidizing_initial_deformation_rejection,
//     oxidizing_spherical_rejection :data.oxidizing_spherical_rejection,
//     oxidizing_hemisperical_rejection :data.oxidizing_hemisperical_rejection,
//     oxidizing_flow_rejection :data.oxidizing_flow_rejection,
//     SiO2_rejection :data.SiO2_rejection,
//     AI2O3_rejection :data.AI2O3_rejection,
//     Fe2O3_rejection :data.Fe2O3_rejection,
//     CaO_rejection :data.CaO_rejection,
//     P2O5_rejection  :data.P2O5_rejection,
//     SO3_rejection :data.SO3_rejection,
//     MgO_rejection :data.MgO_rejection,
//     TiO2_rejection :data.TiO2_rejection,
//     NA2O_rejection :data.NA2O_rejection,
//     K2O_rejection :data.K2O_rejection,
//     Mn3O4_rejection :data.Mn3O4_rejection,

//     te_antimony_sb_rejection_air_dried_in_Coal :data.te_antimony_sb_rejection_air_dried_in_Coal,
//     te_arsenic_as_rejection_air_dried_in_Coal :data.te_arsenic_as_rejection_air_dried_in_Coal,
//     te_beryllium_be_rejection_air_dried_in_Coal :data.te_beryllium_be_rejection_air_dried_in_Coal,
//     te_boron_b_rejection_air_dried_in_Coal :data.te_boron_b_rejection_air_dried_in_Coal,
//     te_cadmium_cd_rejection_air_dried_in_Coal :data.te_cadmium_cd_rejection_air_dried_in_Coal,
//     te_chromium_cr_rejection_air_dried_in_Coal :data.te_chromium_cr_rejection_air_dried_in_Coal,
//     te_chlorine_cl_rejection_air_dried_in_Coal :data.te_chlorine_cl_rejection_air_dried_in_Coal,
//     te_copper_cu_rejection_air_dried_in_Coal :data.te_copper_cu_rejection_air_dried_in_Coal,
//     te_fluorine_f_rejection_air_dried_in_Coal :data.te_fluorine_f_rejection_air_dried_in_Coal,
//     te_lead_pb_rejection_air_dried_in_Coal :data.te_lead_pb_rejection_air_dried_in_Coal,
//     te_mercury_hg_rejection_air_dried_in_Coal :data.te_mercury_hg_rejection_air_dried_in_Coal,
//     te_molybdenum_mo_rejection_air_dried_in_Coal :data.te_molybdenum_mo_rejection_air_dried_in_Coal,
//     te_phosporous_p_rejection_air_dried_in_Coal :data.te_phosporous_p_rejection_air_dried_in_Coal,
//     te_selenium_se_rejection_air_dried_in_Coal :data.te_selenium_se_rejection_air_dried_in_Coal,
//     te_vanadium_v_rejection_air_dried_in_Coal :data.te_vanadium_v_rejection_air_dried_in_Coal,
//     te_zinc_zn_rejection_air_dried_in_Coal :data.te_zinc_zn_rejection_air_dried_in_Coal,

//     te_antimony_sb_rejection_dried_basis :data.te_antimony_sb_rejection_dried_basis,
//     te_arsenic_as_rejection_dried_basis :data.te_arsenic_as_rejection_dried_basis,
//     te_beryllium_be_rejection_dried_basis :data.te_beryllium_be_rejection_dried_basis,
//     te_boron_b_rejection_dried_basis :data.te_boron_b_rejection_dried_basis,
//     te_cadmium_cd_rejection_dried_basis :data.te_cadmium_cd_rejection_dried_basis,
//     te_chromium_cr_rejection_dried_basis :data.te_chromium_cr_rejection_dried_basis,
//     te_chlorine_cl_rejection_dried_basis :data.te_chlorine_cl_rejection_dried_basis,
//     te_copper_cu_rejection_dried_basis :data.te_copper_cu_rejection_dried_basis,
//     te_fluorine_f_rejection_dried_basis :data.te_fluorine_f_rejection_dried_basis,
//     te_lead_pb_rejection_dried_basis :data.te_lead_pb_rejection_dried_basis,
//     te_mercury_hg_rejection_dried_basis :data.te_mercury_hg_rejection_dried_basis,
//     te_molybdenum_mo_rejection_dried_basis :data.te_molybdenum_mo_rejection_dried_basis,
//     te_phosporous_p_rejection_dried_basis :data.sasasasasa,
//     te_selenium_se_rejection_dried_basis :data.sasasasasa,
//     te_vanadium_v_rejection_dried_basis :data.sasasasasa,
//     te_zinc_zn_rejection_dried_basis :data.sasasasasa,
//     size_0_to_50_mm_rejection_dried_basis :data.sasasasasa,
//     above_50mm_rejection_dried_basis :data.sasasasasa,
//     under_2mm_rejection_dried_basis :data.sasasasasa,
//     size_above_2mm_rejection_dried_basis :data.sasasasasa,
//     size_0_to_50mm_typical :data.sasasasasa,
//     size_0_to_50mm_min :data.sasasasasa,
//     size_0_to_50mm_max :data.sasasasasa,
//     above_0_to_50mm_typical :data.sasasasasa,
//     above_0_to_50mm_min :data.sasasasasa,
//     above_0_to_50mm_max :data.sasasasasa,
//     under_2mm_typical :data.sasasasasa,
//     under_2mm_min :data.sasasasasa,
//     under_2mm_max :data.sasasasasa,
//     size_above_2mm_typical :data.sasasasasa,
//     size_above_2mm_min :data.sasasasasa,
//     size_above_2mm_max :data.sasasasasa,

//     testing_method =str

//     created_by :data.sasasasasa, (only in detail page)
//     last_modified_by :data.sasasasasa, str (only in detail page)


//     quotes_date = current date
//     expiration_date = Date

// export default AddQuotes;

// // {product_code: 'rwood_code_1', product_name: '', product_account: '', product_description: ''}
import React, { useEffect, useState } from "react";
import axios from "axios";
import { AES, enc } from "crypto-js";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import $ from "jquery";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useLocation, Link  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../config';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Header from '../Header'
import Footer from '../Footer';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const AddQuoteproduct = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate=useNavigate()
   const location =useLocation();
  const { productCodes, ids } = location.state;
  console.log(productCodes,ids)


  const [productCode, setProductCode] = useState("");
  const [productValues, setProductValues] = useState([]);
  const [products, setproducts] = useState([]);
  const [shippment, setShippment]=useState([]);

  useEffect(() => {

    quotes_names();
  }, [decryptedAccessToken]);


  const quotes_names = async () => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}get/quoteshipment_by_offer/${ids}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
// alert(JSON.stringify(response))
setShippment(response.data.offer_quote_shipment);
console.log(response)
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/quotesbyID/${ids}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data)
    } catch (error) {
      console.error(error);
    }
  };

  const [formData, setFormData] = useState({
    product_code: productCodes,
    quote_product_code :"",
    locked :"",
    supplier :"",
    quotes_status :"",
    quotes_name :"",

    no_of_product :"",
    no_of_quote_shipment :"",

    quote_shipment :"",
    product_code :"",
    product_name :"",
    product_account :"",
    product_supplier :"",
    product_description :"",
    product_family :"",
    product_shipping_term :"",
    product_owner :"",
    product_active :"",
    short_term_available :"",
    product_price_basis :"",
    product_price_type :"",
    product_port_of_loading :"",
    total_moisture_typical:"",
    total_moisture_minimum:"",
    total_moisture_maximum:"",
    ash_typical_arb:"",
    ash_minimum_arb:"",
    ash_maximum_arb:"",
    sulphur_typical_arb:"",
    sulphur_minimum_arb:"",
    sulphur_maximum_arb:"",
    volatile_matter_typical_arb:"",
    volatile_matter_minimum_arb:"",
    volatile_matter_maximum_arb:"",

    moisture_typical:"",
    moisture_minimum:"",
    ash_typical:"",
    ash_minimum:"",
    ash_maximum:"",

    volatile_matter_typical_ADB:"",
    volatile_matter_minimum:"",
    volatile_matter_maximum:"",
    fixed_carbon_by_difference:"",
    fixed_carbon_typical_ADB:"",
    fixed_carbon_minimum:"",
    fixed_carbon_maximum:"",

    gross_air_dried_typical:"",
    gross_air_dried_min:"",
    gross_air_dried_max:"",
    gross_as_recieved_min:"",
    gross_as_recieved_max:"",
    gross_as_recieved_typical:"",

    net_as_recieved_typical:"",
    net_as_recieved_min:"",
    net_as_recieved_max:"",

    hgi_typical:"",
    hgi_min:"",
    hgi_max:"",

    carbon_typical:"",
    carbon_min:"",
    carbon_max:"",

    hydrogen_typical:"",
    hydrogen_min:"",
    hydrogen_max:"",

    nitrogen_typical:"",
    nitrogen_min:"",
    nitrogen_max:"",

    sulphur_typical:"",
    sulphur_min:"",
    sulphur_max_ultimate_analysis:"",
    oxygen_typical:"",
    oxygen_min:"",
    oxygen_max:"",

    sulphur_typical_adb:"",
    sulphur_min_adb:"",
    product_sulphur_max:"",

    initial_typical:"",
    initial_minimum:"",
    initial_maximum:"",
    spherical_typical:"",
    spherical_minimum:"",
    spherical_maximum:"",
    hemispherical_typical:"",
    hemispherical_minimum:"",
    hemispherical_maximum:"",
    flow_typical:"",
    flow_minimum:"",
    flow_maximum:"",

    oxidising_initial_typical:"",
    oxidising_initial_minimum:"",
    oxidising_initial_maximum:"",
    oxidising_spherical_typical:"",
    oxidising_spherical_minimum:"",
    oxidising_spherical_maximum:"",
    oxidising_hemispherical_typical:"",
    oxidising_hemispherical_minimum:"",
    oxidising_hemispherical_maximum:"",
    oxidising_flow_typical:"",
    oxidising_flow_minimum:"",
    oxidising_flow_maximum:"",

    SiO2_Typical:"",
    SiO2_Minimum:"",
    SiO2_Maximum:"",
    Al2O3_Typical:"",
    Al2O3_Minimum:"",
    Al2O3_Maximum:"",
    Fe2O3_Typical:"",
    Fe2O3_Minimum:"",
    Fe2O3_Maximum:"",
    CaO_Typical:"",
    CaO_Minimum:"",
    CaO_Maximum:"",
    MgO_Typical:"",
    MgO_Minimum:"",
    MgO_Maximum:"",
    MnO_Typical:"",
    MnO_Minimum:"",
    MnO_Maximum:"",
    TiO2_Typical:"",
    oxidizing_hemisperical_typical:"",
    TiO2_Minimum:"",
    TiO2_Maximum:"",
    Na2O_Typical:"",
    Na2O_Minimum:"",
    Na2O_Maximum:"",
    K2O_Typical:"",
    K2O_Minimum:"",
    K2O_Maximum:"",
    P2O5_Typical:"",
    P2O5_Minimum:"",
    P2O5_Maximum:"",
    SO3_Typical:"",
    SO3_Minimum:"",
    SO3_Maximum:"",
    te_arsenic_as_typical_in_air_dried_coal:"",
    te_arsenic_as_minimum_in_air_dried_coal:"",
    te_arsenic_as_maximum_in_air_dried_coal:"",
    te_antimony_sb_typical_in_air_dried_coal:"",
    te_antimony_sb_minimum_in_air_dried_coal:"",
    te_antimony_sb_maximum_in_air_dried_coal:"",
    te_beryllium_be_typical_in_air_dried_coal:"",
    te_beryllium_be_minimum_in_air_dried_coal:"",
    te_beryllium_be_maximum_in_air_dried_coal:"",
    te_boron_b_typical_in_air_dried_coal:"",
    te_boron_b_minimum_in_air_dried_coal:"",
    te_boron_b_maximum_in_air_dried_coal:"",
    te_cadmium_cd_typical_in_air_dried_coal:"",
    te_cadmium_cd_minimum_in_air_dried_coal:"",
    te_cadmium_cd_maximum_in_air_dried_coal:"",
    te_chromium_cr_typical_in_air_dried_coal:"",
    te_chromium_cr_minimum_in_air_dried_coal:"",
    te_chromium_cr_maximum_in_air_dried_coal:"",
    te_copper_cu_typical_in_air_dried_coal:"",
    te_copper_cu_minimum_in_air_dried_coal:"",
    te_copper_cu_maximum_in_air_dried_coal:"",
    te_chlorine_cl_typical_in_air_dried_coal:"",
    te_chlorine_cl_minimum_in_air_dried_coal:"",
    te_chlorine_cl_maximum_in_air_dried_coal:"",
    te_fluorine_f_typical_in_air_dried_coal:"",
    te_fluorine_f_minimum_in_air_dried_coal:"",
    te_fluorine_f_maximum_in_air_dried_coal:"",
    te_mercury_hg_typical_in_air_dried_coal:"",
    te_mercury_hg_minimum_in_air_dried_coal:"",
    te_mercury_hg_maximum_in_air_dried_coal:"",
    te_molybdenum_mo_typical_in_air_dried_coal:"",
    te_molybdenum_mo_minimum_in_air_dried_coal:"",
    te_molybdenum_mo_maximum_in_air_dried_coal:"",
    te_lead_pb_typical_in_air_dried_coal:"",
    te_lead_pb_minimum_in_air_dried_coal:"",
    te_lead_pb_maximum_in_air_dried_coal:"",
    te_phosphorus_p_typical_in_air_dried_coal:"",
    te_phosphorus_p_minimum_in_air_dried_coal:"",
    te_phosphorus_p_maximum_in_air_dried_coal:"",
    te_selenium_se_typical_in_air_dried_coal:"",
    te_selenium_se_minimum_in_air_dried_coal:"",
    te_selenium_se_maximum_in_air_dried_coal:"",
    te_vanadium_v_typical_in_air_dried_coal:"",
    te_vanadium_v_minimum_in_air_dried_coal:"",
    te_vanadium_v_maximum_in_air_dried_coal:"",
    te_zinc_zn_typical_in_air_dried_coal:"",
    te_zinc_zn_minimum_in_air_dried_coal:"",
    te_zinc_zn_maximum_in_air_dried_coal:"",

    te_arsenic_as_typical:"",
    te_arsenic_as_minimum:"",
    te_arsenic_as_maximum:"",
    te_antimony_sb_typical:"",
    te_antimony_sb_minimum:"",
    te_antimony_sb_maximum:"",
    te_boron_b_typical:"",
    te_boron_b_minimum:"",
    te_boron_b_maximum:"",

    te_beryllium_b_typical:"",
    te_beryllium_b_minimum:"",
    te_beryllium_b_maximum:"",

    te_cadmium_cd_typical:"",
    te_cadmium_cd_minimum:"",
    te_cadmium_cd_maximum:"",
    te_chromium_cr_typical:"",
    te_chromium_cr_minimum:"",
    te_chromium_cr_maximum:"",
    te_copper_cu_typical:"",
    te_copper_cu_minimum:"",
    te_copper_cu_maximum:"",
    te_chlorine_cl_typical:"",
    te_chlorine_cl_minimum:"",
    te_chlorine_cl_maximum:"",
    te_fluorine_f_typical:"",
    te_fluorine_f_minimum:"",
    te_fluorine_f_maximum:"",
    te_mercury_hg_typical:"",
    te_mercury_hg_minimum:"",
    te_mercury_hg_maximum:"",
    te_molybdenum_mo_typical:"",
    te_molybdenum_mo_minimum:"",
    te_molybdenum_mo_maximum:"",
    te_lead_pb_typical:"",
    te_lead_pb_minimum:"",
    te_lead_pb_maximum:"",
    te_phosphorus_p_typical:"",
    te_phosphorus_p_minimum:"",
    te_phosphorus_p_maximum:"",
    te_selenium_se_typical:"",
    te_selenium_se_minimum:"",
    te_selenium_se_maximum:"",
    te_vanadium_v_typical:"",
    te_vanadium_v_min:"",
    te_vanadium_v_max:"",
    te_zinc_zn_typical:"",
    te_zinc_zn_min:"",
    te_zinc_zn_max:"",


    ash_rejection_ARB:"",
    volatile_matter_rejection_ARB:"",
    total_sulphur_rejection_ARB:"",
    total_moisture_rejection:"",
    inherent_moisture_rejection:"",
    ash_content_rejection:"",
    volatile_matter_rejection_ADB:"",
    fixed_carbon_rejection_ADB :"",
    gross_as_received_rejection:"",
    gross_air_dried_rejection:"",
    net_as_received_rejection:"",
    hgi_rejection:"",
    carbon_rejection:"",
    hydrogen_rejection:"",
    nitrogen_rejection:"",
    oxygen_rejection:"",
    sulphur_rejection_ADB:"",
    initial_deformation_rejection:"",
    spherical_rejection:"",
    hemispherical_rejection:"",
    flow_rejection:"",
    oxidizing_initial_deformation_rejection:"",
    oxidizing_spherical_rejection:"",
    oxidizing_hemisperical_rejection:"",
    oxidizing_flow_rejection:"",
    SiO2_rejection:"",
    AI2O3_rejection:"",
    Fe2O3_rejection:"",
    CaO_rejection:"",
    P2O5_rejection :"",
    SO3_rejection:"",
    MgO_rejection:"",
    TiO2_rejection:"",
    NA2O_rejection:"",
    K2O_rejection:"",
    Mn3O4_rejection:"",

    te_antimony_sb_rejection_air_dried_in_Coal:"",
    te_arsenic_as_rejection_air_dried_in_Coal:"",
    te_beryllium_be_rejection_air_dried_in_Coal:"",
    te_boron_b_rejection_air_dried_in_Coal:"",
    te_cadmium_cd_rejection_air_dried_in_Coal:"",
    te_chromium_cr_rejection_air_dried_in_Coal:"",
    te_chlorine_cl_rejection_air_dried_in_Coal:"",
    te_copper_cu_rejection_air_dried_in_Coal:"",
    te_fluorine_f_rejection_air_dried_in_Coal:"",
    te_lead_pb_rejection_air_dried_in_Coal:"",
    te_mercury_hg_rejection_air_dried_in_Coal:"",
    te_molybdenum_mo_rejection_air_dried_in_Coal:"",
    te_phosporous_p_rejection_air_dried_in_Coal:"",
    te_selenium_se_rejection_air_dried_in_Coal:"",
    te_vanadium_v_rejection_air_dried_in_Coal:"",
    te_zinc_zn_rejection_air_dried_in_Coal:"",

    te_antimony_sb_rejection_dried_basis:"",
    te_arsenic_as_rejection_dried_basis:"",
    te_beryllium_be_rejection_dried_basis:"",
    te_boron_b_rejection_dried_basis:"",
    te_cadmium_cd_rejection_dried_basis:"",
    te_chromium_cr_rejection_dried_basis:"",
    te_chlorine_cl_rejection_dried_basis:"",
    te_copper_cu_rejection_dried_basis:"",
    te_fluorine_f_rejection_dried_basis:"",
    te_lead_pb_rejection_dried_basis:"",
    te_mercury_hg_rejection_dried_basis:"",
    te_molybdenum_mo_rejection_dried_basis:"",
    te_phosporous_p_rejection_dried_basis:"",
    te_selenium_se_rejection_dried_basis:"",
    te_vanadium_v_rejection_dried_basis:"",
    te_zinc_zn_rejection_dried_basis:"",
    size_0_to_50_mm_rejection_dried_basis:"",
    above_50mm_rejection_dried_basis:"",
    under_2mm_rejection_dried_basis:"",
    size_above_2mm_rejection_dried_basis:"",



    AI2O3_typical:"",
    Fe2O3_typical:"",
    P2O5_Typical:"",
    SO3_Typical:"",
    MgO_Typical:"",
    TiO2_Typical:"",
    Na2O_Typical:"",
    K2O_Typical:"",
    Mn3O4_Typical:"",

















    size_0_to_50mm_typical:"",
    size_0_to_50mm_min:"",
    size_0_to_50mm_max:"",
    above_0_to_50mm_typical:"",
    above_0_to_50mm_min:"",
    above_0_to_50mm_max:"",
    under_2mm_typical:"",
    under_2mm_min:"",
    under_2mm_max:"",
    size_above_2mm_typical:"",
    size_above_2mm_min:"",
    size_above_2mm_max:"",

    testing_method: "",

    created_by: "",
    last_modified_by: "",


  });

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    console.log(event.target);
    const newValue = type === "checkbox" ? checked : value;
    console.log(newValue);
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "locked" ? Boolean(newValue) : newValue,
    }));
  };

  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);
          const response = await axios.get(
            API_URL+"get/products_api",
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );
          setproducts(response.data);
          // navigate("/opportunity/quote-view/"+response.data.quote_product_id)
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProductCodes();
    // quotes_names();
  }, []);

  const getProductValues = async (productCode) => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}all/product/datas/product_code/${productCodes}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
console.log(response.data)
        setProductValues(response.data);

        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [quoteShipment_name, setquoteShipment_name] = useState([]);
  useEffect(() => {

    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/quoteshipment', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;

      console.log(data)

    } catch (error) {
      console.error(error);
    }
  };

  // const quotes_names = async () => {
  //   try {
  //     const encryptedAccessToken = localStorage.getItem("access_token");
  //     const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  //     if (encryptedAccessToken) {
  //       const decryptedAccessToken = AES.decrypt(
  //         encryptedAccessToken,
  //         secret_key
  //       ).toString(enc.Utf8);
  //       const response = await axios.get(
  //         `${API_URL}get/quoteshipment_by_offer/${ids}`,
  //         {
  //           headers: { Authorization: `Bearer ${decryptedAccessToken}` },
  //         }
  //       );
  //       console.log(response)

  //       setquoteShipment_name(response.data.offer_quote_shipment);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
// const [tcfData, setTcfData] = useState({
//       company: "",
//     });
  // const handleProductCodeChange = async (event) => {

  // };


  useEffect(() => {
    setProductCode(productCodes);
    const fetchDataProduct = async () => {

    try {
      const productValues = await getProductValues(productCodes);
      console.log(productValues);

      setFormData({
        ...formData,

        quote_product_code :productValues.quote_product_code,

        locked :productValues.locked,
        supplier :productValues.supplier,
        quotes_status :productValues.quotes_status,
        quotes_name :productValues.quotes_name,

        no_of_product :productValues.no_of_product,
        no_of_quote_shipment :productValues.no_of_quote_shipment,

        quote_shipment :productValues.quote_shipment,
        product_code :productValues.product_code,
        product_name :productValues.product_name,
        product_account :productValues.product_account,
        product_supplier :productValues.product_supplier,
        product_description :productValues.product_description,
        product_family :productValues.product_family,
        product_shipping_term :productValues.product_shipping_term,
        product_owner :productValues.product_owner,
        product_active :productValues.product_active,
        short_term_available :productValues.short_term_available,
        product_price_basis :productValues.product_price_basis,
        product_price_type :productValues.product_price_type,
        product_port_of_loading :productValues.product_port_of_loading,
        total_moisture_typical :productValues.total_moisture_typical,
        total_moisture_minimum :productValues.total_moisture_minimum,
        total_moisture_maximum :productValues.total_moisture_maximum,
        ash_typical_arb :productValues.ash_typical_arb,
        ash_minimum_arb :productValues.ash_minimum_arb,
        ash_maximum_arb :productValues.ash_maximum_arb,
        sulphur_typical_arb :productValues.sulphur_typical_arb,
        sulphur_minimum_arb :productValues.sulphur_minimum_arb,
        sulphur_maximum_arb :productValues.sulphur_maximum_arb,
        volatile_matter_typical_arb :productValues.volatile_matter_typical_arb,
        volatile_matter_minimum_arb :productValues.volatile_matter_minimum_arb,
        volatile_matter_maximum_arb :productValues.volatile_matter_maximum_arb,
        moisture_typical :productValues.moisture_typical,
        moisture_minimum :productValues.moisture_minimum,
        ash_typical :productValues.ash_typical,
        ash_minimum :productValues.ash_minimum,
        ash_maximum :productValues.ash_maximum,
        volatile_matter_typical_ADB :productValues.volatile_matter_typical_ADB,
        volatile_matter_minimum :productValues.volatile_matter_minimum,
        volatile_matter_maximum :productValues.volatile_matter_maximum,
        fixed_carbon_by_difference :productValues.fixed_carbon_by_difference,
        fixed_carbon_typical_ADB :productValues.fixed_carbon_typical_ADB,
        fixed_carbon_minimum :productValues.fixed_carbon_minimum,
        fixed_carbon_maximum :productValues.fixed_carbon_maximum,
        gross_air_dried_typical :productValues.gross_air_dried_typical,
        gross_air_dried_min :productValues.gross_air_dried_min,
        gross_air_dried_max :productValues.gross_air_dried_max,
        gross_as_recieved_min :productValues.gross_as_recieved_min,
        gross_as_recieved_max :productValues.gross_as_recieved_max,
        gross_as_recieved_typical :productValues.gross_as_recieved_typical,
        net_as_recieved_typical :productValues.net_as_recieved_typical,
        net_as_recieved_min :productValues.net_as_recieved_min,
        net_as_recieved_max :productValues.net_as_recieved_max,
        hgi_typical :productValues.hgi_typical,
        hgi_min :productValues.hgi_min,
        hgi_max :productValues.hgi_max,
        carbon_typical :productValues.carbon_typical,
        carbon_min :productValues.carbon_min,
        carbon_max :productValues.carbon_max,
        hydrogen_typical :productValues.hydrogen_typical,
        hydrogen_min :productValues.hydrogen_min,
        hydrogen_max :productValues.hydrogen_max,

        nitrogen_typical :productValues.nitrogen_typical,
        nitrogen_min :productValues.nitrogen_min,
        nitrogen_max :productValues.nitrogen_max,

        sulphur_typical :productValues.sulphur_typical,
        sulphur_min :productValues.sulphur_min,
        sulphur_max_ultimate_analysis :productValues.sulphur_max_ultimate_analysis,
        oxygen_typical :productValues.oxygen_typical,
        oxygen_min :productValues.oxygen_min,
        oxygen_max :productValues.oxygen_max,

        sulphur_typical_adb :productValues.sulphur_typical_adb,
        sulphur_min_adb :productValues.sulphur_min_adb,
        product_sulphur_max :productValues.product_sulphur_max,

        initial_typical :productValues.initial_typical,
        initial_minimum :productValues.initial_minimum,
        initial_maximum :productValues.initial_maximum,
        spherical_typical :productValues.spherical_typical,
        spherical_minimum :productValues.spherical_minimum,
        spherical_maximum :productValues.spherical_maximum,
        hemispherical_typical :productValues.hemispherical_typical,
        hemispherical_minimum :productValues.hemispherical_minimum,
        hemispherical_maximum :productValues.hemispherical_maximum,
        flow_typical :productValues.flow_typical,
        flow_minimum :productValues.flow_minimum,
        flow_maximum :productValues.flow_maximum,
        oxidizing_hemisperical_typical:productValues.oxidizing_hemisperical_typical,
        oxidising_initial_typical :productValues.oxidising_initial_typical,
        oxidising_initial_minimum :productValues.oxidising_initial_minimum,
        oxidising_initial_maximum :productValues.oxidising_initial_maximum,
        oxidising_spherical_typical :productValues.oxidising_spherical_typical,
        oxidising_spherical_minimum :productValues.oxidising_spherical_minimum,
        oxidising_spherical_maximum :productValues.oxidising_spherical_maximum,
        oxidising_hemispherical_typical :productValues.oxidising_hemispherical_typical,
        oxidising_hemispherical_minimum :productValues.oxidising_hemispherical_minimum,
        oxidising_hemispherical_maximum :productValues.oxidising_hemispherical_maximum,
        oxidising_flow_typical :productValues.oxidising_flow_typical,
        oxidising_flow_minimum :productValues.oxidising_flow_minimum,
        oxidising_flow_maximum :productValues.oxidising_flow_maximum,

        SiO2_Typical :productValues.SiO2_Typical,
        SiO2_Minimum :productValues.SiO2_Minimum,
        SiO2_Maximum :productValues.SiO2_Maximum,
        Al2O3_Typical :productValues.Al2O3_Typical,
        Al2O3_Minimum :productValues.Al2O3_Minimum,
        Al2O3_Maximum :productValues.Al2O3_Maximum,
        Fe2O3_Typical :productValues.Fe2O3_Typical,
        Fe2O3_Minimum :productValues.Fe2O3_Minimum,
        Fe2O3_Maximum :productValues.Fe2O3_Maximum,
        CaO_Typical :productValues.CaO_Typical,
        CaO_Minimum :productValues.CaO_Minimum,
        CaO_Maximum :productValues.CaO_Maximum,
        MgO_Typical :productValues.MgO_Typical,
        MgO_Minimum :productValues.MgO_Minimum,
        MgO_Maximum :productValues.MgO_Maximum,
        MnO_Typical :productValues.MnO_Typical,
        MnO_Minimum :productValues.MnO_Minimum,
        MnO_Maximum :productValues.MnO_Maximum,
        TiO2_Typical :productValues.TiO2_Typical,
        TiO2_Minimum :productValues.TiO2_Minimum,
        TiO2_Maximum :productValues.TiO2_Maximum,
        Na2O_Typical :productValues.Na2O_Typical,
        Na2O_Minimum :productValues.Na2O_Minimum,
        Na2O_Maximum :productValues.Na2O_Maximum,
        K2O_Typical :productValues.K2O_Typical,
        K2O_Minimum :productValues.K2O_Minimum,
        K2O_Maximum :productValues.K2O_Maximum,
        P2O5_Typical :productValues.P2O5_Typical,
        P2O5_Minimum :productValues.P2O5_Minimum,
        P2O5_Maximum :productValues.P2O5_Maximum,
        SO3_Typical :productValues.SO3_Typical,
        SO3_Minimum :productValues.SO3_Minimum,
        SO3_Maximum :productValues.SO3_Maximum,
        te_arsenic_as_typical_in_air_dried_coal :productValues.te_arsenic_as_typical_in_air_dried_coal,
        te_arsenic_as_minimum_in_air_dried_coal :productValues.te_arsenic_as_minimum_in_air_dried_coal,
        te_arsenic_as_maximum_in_air_dried_coal :productValues.te_arsenic_as_maximum_in_air_dried_coal,
        te_antimony_sb_typical_in_air_dried_coal :productValues.te_antimony_sb_typical_in_air_dried_coal,
        te_antimony_sb_minimum_in_air_dried_coal :productValues.te_antimony_sb_minimum_in_air_dried_coal,
        te_antimony_sb_maximum_in_air_dried_coal :productValues.te_antimony_sb_maximum_in_air_dried_coal,
        te_beryllium_be_typical_in_air_dried_coal :productValues.te_beryllium_be_typical_in_air_dried_coal,
        te_beryllium_be_minimum_in_air_dried_coal :productValues.te_beryllium_be_minimum_in_air_dried_coal,
        te_beryllium_be_maximum_in_air_dried_coal :productValues.te_beryllium_be_maximum_in_air_dried_coal,
        te_boron_b_typical_in_air_dried_coal :productValues.te_boron_b_typical_in_air_dried_coal,
        te_boron_b_minimum_in_air_dried_coal :productValues.te_boron_b_minimum_in_air_dried_coal,
        te_boron_b_maximum_in_air_dried_coal :productValues.te_boron_b_maximum_in_air_dried_coal,
        te_cadmium_cd_typical_in_air_dried_coal :productValues.te_cadmium_cd_typical_in_air_dried_coal,
        te_cadmium_cd_minimum_in_air_dried_coal :productValues.te_cadmium_cd_minimum_in_air_dried_coal,
        te_cadmium_cd_maximum_in_air_dried_coal :productValues.te_cadmium_cd_maximum_in_air_dried_coal,
        te_chromium_cr_typical_in_air_dried_coal :productValues.te_chromium_cr_typical_in_air_dried_coal,
        te_chromium_cr_minimum_in_air_dried_coal :productValues.te_chromium_cr_minimum_in_air_dried_coal,
        te_chromium_cr_maximum_in_air_dried_coal :productValues.te_chromium_cr_maximum_in_air_dried_coal,
        te_copper_cu_typical_in_air_dried_coal :productValues.te_copper_cu_typical_in_air_dried_coal,
        te_copper_cu_minimum_in_air_dried_coal :productValues.te_copper_cu_minimum_in_air_dried_coal,
        te_copper_cu_maximum_in_air_dried_coal :productValues.te_copper_cu_maximum_in_air_dried_coal,
        te_chlorine_cl_typical_in_air_dried_coal :productValues.te_chlorine_cl_typical_in_air_dried_coal,
        te_chlorine_cl_minimum_in_air_dried_coal :productValues.te_chlorine_cl_minimum_in_air_dried_coal,
        te_chlorine_cl_maximum_in_air_dried_coal :productValues.te_chlorine_cl_maximum_in_air_dried_coal,
        te_fluorine_f_typical_in_air_dried_coal :productValues.te_fluorine_f_typical_in_air_dried_coal,
        te_fluorine_f_minimum_in_air_dried_coal :productValues.te_fluorine_f_minimum_in_air_dried_coal,
        te_fluorine_f_maximum_in_air_dried_coal :productValues.te_fluorine_f_maximum_in_air_dried_coal,
        te_mercury_hg_typical_in_air_dried_coal :productValues.te_mercury_hg_typical_in_air_dried_coal,
        te_mercury_hg_minimum_in_air_dried_coal :productValues.te_mercury_hg_minimum_in_air_dried_coal,
        te_mercury_hg_maximum_in_air_dried_coal :productValues.te_mercury_hg_maximum_in_air_dried_coal,
        te_molybdenum_mo_typical_in_air_dried_coal :productValues.te_molybdenum_mo_typical_in_air_dried_coal,
        te_molybdenum_mo_minimum_in_air_dried_coal :productValues.te_molybdenum_mo_minimum_in_air_dried_coal,
        te_molybdenum_mo_maximum_in_air_dried_coal :productValues.te_molybdenum_mo_maximum_in_air_dried_coal,
        te_lead_pb_typical_in_air_dried_coal :productValues.te_lead_pb_typical_in_air_dried_coal,
        te_lead_pb_minimum_in_air_dried_coal :productValues.te_lead_pb_minimum_in_air_dried_coal,
        te_lead_pb_maximum_in_air_dried_coal :productValues.te_lead_pb_maximum_in_air_dried_coal,
        te_phosphorus_p_typical_in_air_dried_coal :productValues.te_phosphorus_p_typical_in_air_dried_coal,
        te_phosphorus_p_minimum_in_air_dried_coal :productValues.te_phosphorus_p_minimum_in_air_dried_coal,
        te_phosphorus_p_maximum_in_air_dried_coal :productValues.te_phosphorus_p_maximum_in_air_dried_coal,
        te_selenium_se_typical_in_air_dried_coal :productValues.te_selenium_se_typical_in_air_dried_coal,
        te_selenium_se_minimum_in_air_dried_coal :productValues.te_selenium_se_minimum_in_air_dried_coal,
        te_selenium_se_maximum_in_air_dried_coal :productValues.te_selenium_se_maximum_in_air_dried_coal,
        te_vanadium_v_typical_in_air_dried_coal :productValues.te_vanadium_v_typical_in_air_dried_coal,
        te_vanadium_v_minimum_in_air_dried_coal :productValues.te_vanadium_v_minimum_in_air_dried_coal,
        te_vanadium_v_maximum_in_air_dried_coal :productValues.te_vanadium_v_maximum_in_air_dried_coal,
        te_zinc_zn_typical_in_air_dried_coal :productValues.te_zinc_zn_typical_in_air_dried_coal,
        te_zinc_zn_minimum_in_air_dried_coal :productValues.te_zinc_zn_minimum_in_air_dried_coal,
        te_zinc_zn_maximum_in_air_dried_coal :productValues.te_zinc_zn_maximum_in_air_dried_coal,

        te_arsenic_as_typical :productValues.te_arsenic_as_typical,
        te_arsenic_as_minimum :productValues.te_arsenic_as_minimum,
        te_arsenic_as_maximum :productValues.te_arsenic_as_maximum,
        te_antimony_sb_typical :productValues.te_antimony_sb_typical,
        te_antimony_sb_minimum :productValues.te_antimony_sb_minimum,
        te_antimony_sb_maximum :productValues.te_antimony_sb_maximum,
        te_boron_b_typical :productValues.te_boron_b_typical,
        te_boron_b_minimum :productValues.te_boron_b_minimum,
        te_boron_b_maximum :productValues.te_boron_b_maximum,

        te_beryllium_b_typical :productValues.te_beryllium_b_typical,
        te_beryllium_b_minimum :productValues.te_beryllium_b_minimum,
        te_beryllium_b_maximum :productValues.te_beryllium_b_maximum,

        te_cadmium_cd_typical :productValues.te_cadmium_cd_typical,
        te_cadmium_cd_minimum :productValues.te_cadmium_cd_minimum,
        te_cadmium_cd_maximum :productValues.te_cadmium_cd_maximum,
        te_chromium_cr_typical :productValues.te_chromium_cr_typical,
        te_chromium_cr_minimum :productValues.te_chromium_cr_minimum,
        te_chromium_cr_maximum :productValues.te_chromium_cr_maximum,
        te_copper_cu_typical :productValues.te_copper_cu_typical,
        te_copper_cu_minimum :productValues.te_copper_cu_minimum,
        te_copper_cu_maximum :productValues.te_copper_cu_maximum,
        te_chlorine_cl_typical :productValues.te_chlorine_cl_typical,
        te_chlorine_cl_minimum :productValues.te_chlorine_cl_minimum,
        te_chlorine_cl_maximum :productValues.te_chlorine_cl_maximum,
        te_fluorine_f_typical :productValues.te_fluorine_f_typical,
        te_fluorine_f_minimum :productValues.te_fluorine_f_minimum,
        te_fluorine_f_maximum :productValues.te_fluorine_f_maximum,
        te_mercury_hg_typical :productValues.te_mercury_hg_typical,
        te_mercury_hg_minimum :productValues.te_mercury_hg_minimum,
        te_mercury_hg_maximum :productValues.te_mercury_hg_maximum,
        te_molybdenum_mo_typical :productValues.te_molybdenum_mo_typical,
        te_molybdenum_mo_minimum :productValues.te_molybdenum_mo_minimum,
        te_molybdenum_mo_maximum :productValues.te_molybdenum_mo_maximum,
        te_lead_pb_typical :productValues.te_lead_pb_typical,
        te_lead_pb_minimum :productValues.te_lead_pb_minimum,
        te_lead_pb_maximum :productValues.te_lead_pb_maximum,
        te_phosphorus_p_typical :productValues.te_phosphorus_p_typical,
        te_phosphorus_p_minimum :productValues.te_phosphorus_p_minimum,
        te_phosphorus_p_maximum :productValues.te_phosphorus_p_maximum,
        te_selenium_se_typical :productValues.te_selenium_se_typical,
        te_selenium_se_minimum :productValues.te_selenium_se_minimum,
        te_selenium_se_maximum :productValues.te_selenium_se_maximum,
        te_vanadium_v_typical :productValues.te_vanadium_v_typical,
        te_vanadium_v_min :productValues.te_vanadium_v_min,
        te_vanadium_v_max :productValues.te_vanadium_v_max,
        te_zinc_zn_typical :productValues.te_zinc_zn_typical,
        te_zinc_zn_min :productValues.te_zinc_zn_min,
        te_zinc_zn_max :productValues.te_zinc_zn_max,

        ash_rejection_ARB :productValues.ash_rejection_ARB,
        volatile_matter_rejection_ARB :productValues.volatile_matter_rejection_ARB,
        total_sulphur_rejection_ARB :productValues.total_sulphur_rejection_ARB,
        total_moisture_rejection :productValues.total_moisture_rejection,
        inherent_moisture_rejection :productValues.inherent_moisture_rejection,
        ash_content_rejection :productValues.ash_content_rejection,
        volatile_matter_rejection_ADB :productValues.volatile_matter_rejection_ADB,
        fixed_carbon_rejection_ADB  :productValues.fixed_carbon_rejection_ADB,
        gross_as_received_rejection :productValues.gross_as_received_rejection,
        gross_air_dried_rejection :productValues.gross_air_dried_rejection,
        net_as_received_rejection :productValues.net_as_received_rejection,
        hgi_rejection :productValues.hgi_rejection,
        carbon_rejection :productValues.carbon_rejection,
        hydrogen_rejection :productValues.hydrogen_rejection,
        nitrogen_rejection :productValues.nitrogen_rejection,
        oxygen_rejection :productValues.oxygen_rejection,
        sulphur_rejection_ADB :productValues.sulphur_rejection_ADB,
        initial_deformation_rejection :productValues.initial_deformation_rejection,
        spherical_rejection :productValues.spherical_rejection,
        hemispherical_rejection :productValues.spherical_rejection,
        flow_rejection :productValues.flow_rejection,
        oxidizing_initial_deformation_rejection :productValues.oxidizing_initial_deformation_rejection,
        oxidizing_spherical_rejection :productValues.oxidizing_spherical_rejection,
        oxidizing_hemisperical_rejection :productValues.oxidizing_hemisperical_rejection,
        oxidizing_flow_rejection :productValues.oxidizing_flow_rejection,
        SiO2_rejection :productValues.SiO2_rejection,
        AI2O3_rejection :productValues.AI2O3_rejection,
        Fe2O3_rejection :productValues.Fe2O3_rejection,
        CaO_rejection :productValues.CaO_rejection,
        P2O5_rejection  :productValues.P2O5_rejection,
        SO3_rejection :productValues.SO3_rejection,
        MgO_rejection :productValues.MgO_rejection,
        TiO2_rejection :productValues.TiO2_rejection,
        NA2O_rejection :productValues.NA2O_rejection,
        K2O_rejection :productValues.K2O_rejection,
        Mn3O4_rejection :productValues.Mn3O4_rejection,

        te_antimony_sb_rejection_air_dried_in_Coal :productValues.te_antimony_sb_rejection_air_dried_in_Coal,
        te_arsenic_as_rejection_air_dried_in_Coal :productValues.te_arsenic_as_rejection_air_dried_in_Coal,
        te_beryllium_be_rejection_air_dried_in_Coal :productValues.te_beryllium_be_rejection_air_dried_in_Coal,
        te_boron_b_rejection_air_dried_in_Coal :productValues.te_boron_b_rejection_air_dried_in_Coal,
        te_cadmium_cd_rejection_air_dried_in_Coal :productValues.te_cadmium_cd_rejection_air_dried_in_Coal,
        te_chromium_cr_rejection_air_dried_in_Coal :productValues.te_chromium_cr_rejection_air_dried_in_Coal,
        te_chlorine_cl_rejection_air_dried_in_Coal :productValues.te_chlorine_cl_rejection_air_dried_in_Coal,
        te_copper_cu_rejection_air_dried_in_Coal :productValues.te_copper_cu_rejection_air_dried_in_Coal,
        te_fluorine_f_rejection_air_dried_in_Coal :productValues.te_fluorine_f_rejection_air_dried_in_Coal,
        te_lead_pb_rejection_air_dried_in_Coal :productValues.te_lead_pb_rejection_air_dried_in_Coal,
        te_mercury_hg_rejection_air_dried_in_Coal :productValues.te_mercury_hg_rejection_air_dried_in_Coal,
        te_molybdenum_mo_rejection_air_dried_in_Coal :productValues.te_molybdenum_mo_rejection_air_dried_in_Coal,
        te_phosporous_p_rejection_air_dried_in_Coal :productValues.te_phosporous_p_rejection_air_dried_in_Coal,
        te_selenium_se_rejection_air_dried_in_Coal :productValues.te_selenium_se_rejection_air_dried_in_Coal,
        te_vanadium_v_rejection_air_dried_in_Coal :productValues.te_vanadium_v_rejection_air_dried_in_Coal,
        te_zinc_zn_rejection_air_dried_in_Coal :productValues.te_zinc_zn_rejection_air_dried_in_Coal,

        te_antimony_sb_rejection_dried_basis :productValues.te_antimony_sb_rejection_dried_basis,
        te_arsenic_as_rejection_dried_basis :productValues.te_arsenic_as_rejection_dried_basis,
        te_beryllium_be_rejection_dried_basis :productValues.te_beryllium_be_rejection_dried_basis,
        te_boron_b_rejection_dried_basis :productValues.te_boron_b_rejection_dried_basis,
        te_cadmium_cd_rejection_dried_basis :productValues.te_cadmium_cd_rejection_dried_basis,
        te_chromium_cr_rejection_dried_basis :productValues.te_chromium_cr_rejection_dried_basis,
        te_chlorine_cl_rejection_dried_basis :productValues.te_chlorine_cl_rejection_dried_basis,
        te_copper_cu_rejection_dried_basis :productValues.te_copper_cu_rejection_dried_basis,
        te_fluorine_f_rejection_dried_basis :productValues.te_fluorine_f_rejection_dried_basis,
        te_lead_pb_rejection_dried_basis :productValues.te_lead_pb_rejection_dried_basis,
        te_mercury_hg_rejection_dried_basis :productValues.te_mercury_hg_rejection_dried_basis,
        te_molybdenum_mo_rejection_dried_basis :productValues.te_molybdenum_mo_rejection_dried_basis,
        te_phosporous_p_rejection_dried_basis :productValues.te_phosporous_p_rejection_dried_basis,
        te_selenium_se_rejection_dried_basis :productValues.te_selenium_se_rejection_dried_basis,
        te_vanadium_v_rejection_dried_basis :productValues.te_vanadium_v_rejection_dried_basis,
        te_zinc_zn_rejection_dried_basis :productValues.te_zinc_zn_rejection_dried_basis,
        size_0_to_50_mm_rejection_dried_basis :productValues.size_0_to_50_mm_rejection_dried_basis,
        above_50mm_rejection_dried_basis :productValues.above_50mm_rejection_dried_basis,
        under_2mm_rejection_dried_basis :productValues.under_2mm_rejection_dried_basis,
        size_above_2mm_rejection_dried_basis :productValues.size_above_2mm_rejection_dried_basis,
        size_0_to_50mm_typical :productValues.size_0_to_50mm_typical,
        size_0_to_50mm_min :productValues.size_0_to_50mm_min,
        size_0_to_50mm_max :productValues.size_0_to_50mm_max,
        above_0_to_50mm_typical :productValues.above_0_to_50mm_typical,
        above_0_to_50mm_min :productValues.above_0_to_50mm_min,
        above_0_to_50mm_max :productValues.above_0_to_50mm_max,
        under_2mm_typical :productValues.under_2mm_typical,
        under_2mm_min :productValues.under_2mm_min,
        under_2mm_max :productValues.under_2mm_max,
        size_above_2mm_typical :productValues.size_above_2mm_typical,
        size_above_2mm_min :productValues.size_above_2mm_min,
        size_above_2mm_max :productValues.size_above_2mm_max,
        oxidizing_initial_typical:productValues.oxidizing_initial_typical,
        testing_method :productValues.testing_method,

        created_by :productValues.created_by,
        last_modified_by :productValues.last_modified_by,
        AI2O3_typical:productValues.AI2O3_typical,
        Fe2O3_typical:productValues.Fe2O3_typical,
        CaO_Typical:productValues.CaO_Typical,
        P2O5_Typical:productValues.P2O5_Typical,
        SO3_Typical:productValues.SO3_Typical,
        MgO_Typical:productValues.MgO_Typical,
        TiO2_Typical:productValues.TiO2_Typical,
        Na2O_Typical:productValues.Na2O_Typical,
        K2O_Typical:productValues.K2O_Typical,
        Mn3O4_Typical:productValues.Mn3O4_Typical,

        ...productValues,
      });
    } catch (error) {
      console.log(error);
    }
  }
  fetchDataProduct();
}, [productCodes]);


  const [productAccount, setProductaccount] = useState([]);
   // product account
    useEffect(() => {
      fetchDataAccount1();
    }, [decryptedAccessToken]);

    const fetchDataAccount1 = async () => {
      try {
        const response = await axios.get(
          API_URL+"all/accounts",
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
        const data = response.data.accounts;
        // console.log(data)
        setProductaccount(data);
      } catch (error) {
        console.error(error);
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();


      console.log(JSON.stringify(formData))
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const headers = { Authorization: `Bearer ${decryptedAccessToken}` };
      const response=  axios
          .post(API_URL+"add/new/quote/" + ids, formData, { headers })
          .then((response) => {
            console.log(response);

            if (response.data.success === 'added') {
                toast.success('Quotes Product Added Successfully', {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                navigate(`/opportunity/quote-view/${ids}`);
              // }
            } else {
              // Handle other status codes if needed
              console.log("Unexpected status code:", response.status);
            }
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // Handle different HTTP status codes
              console.log("Error status:", error.response.status);
              console.log("Error data:", error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              console.log("No response received:", error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error setting up request:", error.message);
            }
          });
      }

    };

  return (
    <div className="shipment">
    <Header/>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Quote Product</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Quote Product</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
  <div className='tasks quotes'>

  <form onSubmit={handleSubmit}>

  <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/opportunity/quote-view/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
  <Row className="mb-3">

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Code"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Product Account"
               name="product_code"

           value={productCodes}
           onChange={handleChange}
               />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Supplier">
         <Form.Select aria-label="Floating label select example"
         name="product_supplier"
         placeholder="Supplier"
         value={formData.product_supplier}
         // defaultValue={}
         onChange={handleChange}
         >

     <option defaultValue='' >Select </option>
              {
                 productAccount.map((x)=>{
                     return(
                         <option defaultValue={x.account_name}>{x.account_name}</option>
                     )
                 })
              }


         </Form.Select>

       </FloatingLabel>

             </Form.Group>


       <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Quote Shipment">
    <Form.Select aria-label="Floating label select example"

    name="quote_shipment"
    value={formData.quote_shipment}
    onChange={handleChange}
    >
      <option value='' >Select</option>
       {
          shippment.map((x)=>{
              return(
                <option key={x.id} value={x.quote_shipment_code}>
                {x.quote_shipment_code}
              </option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>


  </Row>

  <Row className="mb-3">
             <h4 className='heading'>Product Information
</h4>
<Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Shipping Term"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Shipping Term"
             name="product_shipping_term"

           value={formData.product_shipping_term}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Name"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Name"
             name="product_name"

           value={formData.product_name}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Port Of Loading"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Port Of Loading"
             name="product_port_of_loading"

           value={formData.product_port_of_loading}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>


         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Description"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Description"
             name="product_description"

           value={formData.product_description}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Price GAR"
             className="mb-3"
           >

             <Form.Control type="text"
             placeholder="Product Price GAR"
             name="product_price_basis"

           value={formData.product_price_basis}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
        </Row>
          <Row className="mb-3">
             <h4 className='heading'>%As Received Basis</h4>

                     {/* <h4 className='heading'>Account Information</h4> */}
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Ash Content Typical ARB	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Ash Content Typical ARB	"
                           name="ash_typical_arb"
                           defaultValue={formData.ash_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Ash Content Rejection ARB	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Ash Content Rejection ARB	"
                           name="ash_rejection_ARB"
                           defaultValue={formData.ash_rejection_ARB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Volatile Matter Typical ARB	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Volatile Matter Typical ARB	"
                           name="volatile_matter_typical_arb"
                           defaultValue={formData.volatile_matter_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Volatile Matter Rejection ARB"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Volatile Matter Rejection ARB"
                           name="volatile_matter_rejection_ARB"
                           defaultValue={formData.volatile_matter_rejection_ARB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Sulphur Typical ARB	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Sulphur Typical ARB	"
                           name="sulphur_typical_arb"
                           defaultValue={formData.sulphur_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Sulphur Rejection ARB	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Sulphur Rejection ARB	"
                           name="total_sulphur_rejection_ARB"
                           defaultValue={formData.total_sulphur_rejection_ARB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Moisture Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Moisture Typical"
                           name="total_moisture_typical"
                           defaultValue={formData.total_moisture_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Moisture Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Moisture Rejection"
                           name="total_moisture_rejection"
                           defaultValue={formData.total_moisture_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
             </Row>

             <Row className="mb-3">
                     <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Inherent Moisture Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Inherent Moisture Typical"
                           name="moisture_typical"
                           defaultValue={formData.moisture_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Inherent Moisture Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Inherent Moisture Rejection	"
                           name="inherent_moisture_rejection"
                           defaultValue={formData.inherent_moisture_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Ash Content Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Ash Content Typical"
                           name="ash_typical"
                           defaultValue={formData.ash_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Ash Content Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Ash Content Rejection"
                           name="ash_content_rejection"
                           defaultValue={formData.ash_content_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Volatile Matter Typical ADB"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Volatile Matter Typical ADB"
                           name="volatile_matter_typical_ADB"
                           defaultValue={formData.volatile_matter_typical_ADB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Volatile Matter Rejection ADB"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Volatile Matter Rejection ADB"
                           name="volatile_matter_rejection_ARB"
                           defaultValue={formData.volatile_matter_rejection_ARB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Fixed Carbon Typical ADB"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Fixed Carbon Typical ADB"
                           name="fixed_carbon_typical_ADB"
                           defaultValue={formData.fixed_carbon_typical_ADB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Fixed Carbon Rejection ADB	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Fixed Carbon Rejection ADB	"
                           name="fixed_carbon_rejection_ADB"
                           defaultValue={formData.fixed_carbon_rejection_ADB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Fixed Carbon By Difference"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Fixed Carbon By Difference"
                           name="moisture_typical"
                           defaultValue={formData.moisture_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                   </Row>
                   <Row className="mb-3">
                     <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Gross As Received Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Gross As Received Typical"
                           name="gross_as_recieved_typical"
                           defaultValue={formData.gross_as_recieved_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Gross As Received Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Gross As Received Rejection"
                           name="gross_as_received_rejection"
                           defaultValue={formData.gross_as_received_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Gross Air Dried Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Gross Air Dried Typical	"
                           name="gross_air_dried_typical"
                           defaultValue={formData.gross_air_dried_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Gross Air Dried Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Gross Air Dried Rejection"
                           name="gross_air_dried_rejection"
                           defaultValue={formData.gross_air_dried_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Net As Received Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Net As Received Typical"
                           name="net_as_recieved_typical"
                           defaultValue={formData.net_as_recieved_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Net As Received Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Net As Received Rejection"
                           name="net_as_received_rejection"
                           defaultValue={formData.net_as_received_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
               </Row>
                   <Row className="mb-3">
                     <h4 className='heading'>HGI</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="HGI typical"
                         className="mb-3"
                       >

                         <Form.Control type="text"
                         placeholder="HGI typical"
                         name="hgi_typical"
                         defaultValue={formData.hgi_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="HGI rejection"
                         className="mb-3"
                       >

                         <Form.Control type="text"
                         placeholder="HGI rejection"
                         name="hgi_rejection"
                         defaultValue={formData.hgi_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                     </Row>
                     <Row className="mb-3">
                     <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Carbon typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Carbon typical"
                           name="carbon_typical"
                           defaultValue={formData.carbon_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Carbon Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Carbon Rejection"
                           name="carbon_rejection"
                           defaultValue={formData.carbon_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Hydrogen Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Hydrogen Typical"
                           name="hydrogen_typical"
                           defaultValue={formData.hydrogen_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Hydrogen Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Hydrogen Rejection"
                           name="hydrogen_rejection"
                           defaultValue={formData.hydrogen_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Nitrogen Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Nitrogen Typical"
                           name="nitrogen_typical"
                           defaultValue={formData.nitrogen_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Nitrogen Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Nitrogen Rejection"
                           name="nitrogen_rejection"
                           defaultValue={formData.nitrogen_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="hydrogen typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="hydrogen typical"
                           name="hydrogen_typical"
                           defaultValue={formData.hydrogen_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxygen Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Oxygen Rejection"
                           name="oxygen_rejection"
                           defaultValue={formData.oxygen_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                   </Row>
                   <Row className="mb-3">
                     <h4 className='heading'>SULPHUR %air dried basis</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Sulphur Typical Adb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Sulphur Typical Adb"
                           name="sulphur_typical_adb"
                           defaultValue={formData.sulphur_typical_adb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>



                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Sulphur Rejection ADB"
                         className="mb-3"
                       >

                         <Form.Control type="number"
                         placeholder="Sulphur Rejection ADB"
                         name="sulphur_rejection_ADB"
                         defaultValue={formData.sulphur_rejection_ADB}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                   </Row>
                   <Row className="mb-3">
                     <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>


                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Initial Deformation Typical	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Initial Deformation Typical	"
                           name="initial_typical"
                           defaultValue={formData.initial_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Initial Deformation Rejection	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Initial Deformation Rejection"
                           name="initial_rejection"
                           defaultValue={formData.initial_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Spherical Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Spherical Typical"
                           name="spherical_typical"
                           defaultValue={formData.spherical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Spherical Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Spherical Rejection"
                           name="spherical_rejection"
                           defaultValue={formData.spherical_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Hemispherical Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Hemispherical Typical"
                           name="hemispherical_typical"
                           defaultValue={formData.hemispherical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Hemispherical Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Hemispherical Rejection"
                           name="hemispherical_rejection"
                           defaultValue={formData.hemispherical_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Flow Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Flow Typical"
                           name="flow_typical"
                           defaultValue={formData.flow_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Flow Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Flow Rejection"
                           name="flow_rejection"
                           defaultValue={formData.flow_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>




                      </Row>
                      <Row className="mb-3">
                     <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Initial Deformtion Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Initial Deformtion Typical"
                           name="oxidising_initial_typical"
                           defaultValue={formData.oxidising_initial_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Initial Deformation Rejection	"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Initial Deformation Rejection"
                           name="oxidizing_initial_deformation_rejection"
                           defaultValue={formData.oxidizing_initial_deformation_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Spherical Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Spherical Typical"
                           name="oxidizing_spherical_typical"
                           defaultValue={formData.oxidizing_spherical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Spherical Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Spherical Rejection"
                           name="oxidizing_spherical_rejection"
                           defaultValue={formData.oxidizing_spherical_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Hemisperical Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Hemisperical Typical"
                           name="oxidising_hemisperical_typical"
                           defaultValue={formData.oxidising_hemisperical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Hemisperical Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Hemisperical Rejection"
                           name="oxidizing_hemisperical_rejection"
                           defaultValue={formData.oxidizing_hemisperical_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Flow Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Flow Typical"
                           name="oxidizing_flow_typical"
                           defaultValue={formData.oxidizing_flow_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Flow Rejection"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Flow Rejection"
                           name="oxidizing_flow_rejection"
                           defaultValue={formData.oxidizing_flow_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                     </Row>

               <Row className="mb-3">
                 <h4 className="heading">ASH ANALYSIS %Dry Basis in Ash</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SiO2 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.SiO2_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="SiO2 Typical"
                       name="SiO2_Typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TiO2 rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.TiO2_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="TiO2 rejection"
                       name="TiO2_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="AI2O3 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.AI2O3_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="AI2O3 Typical"
                       name="AI2O3_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="AI2O3 Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.AI2O3_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="AI2O3 Rejection"
                       name="AI2O3_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fe2O3 Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.Fe2O3_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Fe2O3 Typical"
                       name="Fe2O3_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fe2O3 Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.TiO2_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="Fe2O3 Rejection"
                       name="TiO2_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="CaO Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.CaO_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="CaO Typical"
                       name="CaO_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="CaO Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.CaO_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="CaO Rejection"
                       name="CaO_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="P2O5 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.P2O5_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="P2O5 Typical"
                       name="P2O5_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="P2O5 Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.P2O5_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="P2O5 Rejection"
                       name="P2O5_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SO3 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.SO3_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="SO3 Typical"
                       name="SO3_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SO3 Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.SO3_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="SO3 Rejection"
                       name="SO3_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="MgO Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.MgO_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="MgO Typical"
                       name="MgO_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="MgO Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.MgO_rejection	}
         onChange={handleChange}
                       type="number"
                       placeholder="MgO Rejection"
                       name="MgO_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TiO2 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.TiO2_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TiO2 Typical"
                       name="TiO2_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TiO2 Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.TiO2_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="TiO2 Rejection"
                       name="TiO2_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="NA2O Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.NA2O_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="NA2O Typical"
                       name="NA2O_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="NA2O Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.NA2O_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="NA2O Rejection"
                       name="NA2O_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="K2O Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.K2O_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="K2O typical"
                       name="K2O_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="K2O Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.K2O_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="K2O Rejection"
                       name="K2O_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Mn3O4 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.Mn3O4_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Mn3O4 Typical"
                       name="Mn3O4_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Mn3O4 Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.Mn3O4_rejection}
         onChange={handleChange}
                       type="number"
                       placeholder="Mn3O4 Rejection"
                       name="Mn3O4_rejection"
                       />
                   </FloatingLabel>
                 </Form.Group>


               </Row>
               <Row className="mb-3">
                 <h4 className="heading">Trace Elements, ppm Air Dried in Coal</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Antimony Sb Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_antimony_sb_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Antimony Sb Typical"
                       name="te_antimony_sb_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Antimony Sb Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_antimony_sb_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Antimony Sb Rejection"
                       name="te_antimony_sb_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Arsenic As Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_arsenic_as_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Arsenic As Typical"
                       name="te_arsenic_as_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Arsenic As Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_arsenic_as_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Arsenic As Rejection"
                       name="te_arsenic_as_rejection_dried_basis"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Beryllium Be Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_beryllium_be_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Beryllium Be Typical"
                       name="te_beryllium_be_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Beryllium Be Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_beryllium_be_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Beryllium Be Rejection"
                       name="te_beryllium_be_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Boron B Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_boron_b_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Boron B Typical"
                       name="te_boron_b_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Boron B Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_boron_b_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Boron B Rejection"
                       name="te_boron_b_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Chromium Cr Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_chromium_cr_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Chromium Cr Typical	"
                       name="te_chromium_cr_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Chromium Cr Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_chromium_cr_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Chromium Cr Rejection"
                       name="te_chromium_cr_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Cadmium Cd Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_cadmium_cd_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Cadmium Cd Typical"
                       name="te_cadmium_cd_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Cadmium Cd Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_cadmium_cd_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Cadmium Cd Rejection"
                       name="te_cadmium_cd_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Chlorine Cl Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_chlorine_cl_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Chlorine Cl Typical"
                       name="te_chlorine_cl_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Chlorine Cl Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_chlorine_cl_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Chlorine Cl Rejection"
                       name="te_chlorine_cl_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Copper Cu Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_copper_cu_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Copper Cu Typical"
                       name="te_copper_cu_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Copper Cu Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_copper_cu_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Copper Cu Rejection"
                       name="te_copper_cu_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Fluorine F Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_fluorine_f_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Fluorine F Typical	"
                       name="te_fluorine_f_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Fluorine F Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_fluorine_f_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Fluorine F Rejection	"
                       name="te_fluorine_f_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Lead Pb Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_lead_pb_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Lead Pb Typical"
                       name="te_lead_pb_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Lead Pb Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_lead_pb_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Lead Pb Rejection"
                       name="te_lead_pb_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Mercury Hg Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_mercury_hg_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Mercury Hg Typical"
                       name="te_mercury_hg_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Mercury Hg Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_mercury_hg_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Mercury Hg Rejection"
                       name="te_mercury_hg_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Molybdenum Mo Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_molybdenum_mo_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Molybdenum Mo Typical"
                       name="te_molybdenum_mo_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Molybdenum Mo Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_molybdenum_mo_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Molybdenum Mo Rejection"
                       name="te_molybdenum_mo_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Phosporous P Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_arsenic_as_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Phosporous P Typical"
                       name="te_arsenic_as_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Phosporous P Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_phosporous_p_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Phosporous P Rejection"
                       name="te_phosporous_p_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Selenium Se Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_selenium_se_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Selenium Se Typical"
                       name="te_selenium_se_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Selenium Se Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_selenium_se_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Selenium Se Rejection	"
                       name="te_selenium_se_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Vanadium V Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_vanadium_v_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Vanadium V Typical"
                       name="te_vanadium_v_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Vanadium V Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_vanadium_v_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Vanadium V Rejection"
                       name="te_vanadium_v_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Zinc Zn Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_zinc_zn_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Zinc Zn Typical"
                       name="te_zinc_zn_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                   <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Zinc Zn Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_zinc_zn_rejection_air_dried_in_Coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Zinc Zn Rejection"
                       name="te_zinc_zn_rejection_air_dried_in_Coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 </Row>

                 <Row className="mb-3">
                 <h4 className="heading">Trace Elements, ppm Dried Basis</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Antimony Sb Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_arsenic_as_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Antimony Sb Typical"
                       name="te_arsenic_as_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Antimony Sb Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_antimony_sb_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Antimony Sb Rejection"
                       name="te_antimony_sb_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Arsenic As Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_arsenic_as_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Arsenic As Typical"
                       name="te_arsenic_as_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Arsenic As Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_arsenic_as_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Arsenic As Rejection"
                       name="te_arsenic_as_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Beryllium Be Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_beryllium_b_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Beryllium Be Typical	"
                       name="te_beryllium_b_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Beryllium Be Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_beryllium_be_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Beryllium Be Rejection"
                       name="te_beryllium_be_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Boron B Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_boron_b_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Boron B Typical"
                       name="te_boron_b_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Boron B Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_boron_b_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Boron B Rejection"
                       name="te_boron_b_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Cadmium Cd Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_cadmium_cd_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Cadmium Cd Typical"
                       name="te_cadmium_cd_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Cadmium Cd Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_cadmium_cd_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Cadmium Cd Rejection"
                       name="te_cadmium_cd_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Chromium Cr Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_chromium_cr_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Chromium Cr Typical"
                       name="te_chromium_cr_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Chromium Cr Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_chromium_cr_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Chromium Cr Rejection"
                       name="te_chromium_cr_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Copper Cu Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_copper_cu_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Copper Cu Typical"
                       name="te_copper_cu_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Copper Cu Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_copper_cu_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Copper Cu Rejection"
                       name="te_copper_cu_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Fluorine F Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_fluorine_f_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Fluorine F Typical"
                       name="te_fluorine_f_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Fluorine F Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_fluorine_f_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Fluorine F Rejection"
                       name="te_fluorine_f_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Lead Pb Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_lead_pb_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Lead Pb Typical"
                       name="te_lead_pb_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Lead Pb Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_lead_pb_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Lead Pb Rejection"
                       name="te_lead_pb_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Mercury Hg Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_mercury_hg_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Mercury Hg Typical"
                       name="te_mercury_hg_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Mercury Hg Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_mercury_hg_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Mercury Hg Rejection"
                       name="te_mercury_hg_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Molybdenum Mo Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_molybdenum_mo_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Molybdenum Mo Typical	"
                       name="te_molybdenum_mo_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Molybdenum Mo Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_molybdenum_mo_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Molybdenum Mo Rejection	"
                       name="te_molybdenum_mo_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Phosporous P Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_molybdenum_mo_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Phosporous P Typical	"
                       name="te_molybdenum_mo_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Phosporous P Rejection		"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_phosporous_p_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Phosporous P Rejection	"
                       name="te_phosporous_p_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Selenium Se Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_selenium_se_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Selenium Se Typical	"
                       name="te_selenium_se_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>


                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Selenium Se Rejection	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_selenium_se_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Selenium Se Rejection	"
                       name="te_selenium_se_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Vanadium V Typical	"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_vanadium_v_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Vanadium V Typical	"
                       name="te_vanadium_v_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Vanadium V Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_vanadium_v_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Vanadium V Rejection"
                       name="te_vanadium_v_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>


                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Zinc Zn Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_zinc_zn_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Zinc Zn Typical"
                       name="te_zinc_zn_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE Zinc Zn Rejection"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.te_zinc_zn_rejection_dried_basis}
         onChange={handleChange}
                       type="number"
                       placeholder="TE Zinc Zn Rejection"
                       name="te_zinc_zn_rejection_dried_basis"
                      />
                   </FloatingLabel>
                 </Form.Group>




                 </Row>
               <Row className="mb-3">
                 <h4 className="heading">SIZING, %</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size 0 to 50mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.size_0_to_50mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Size 0 to 50mm typical"
                       name="size_0_to_50mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size 0 to 50mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.size_0_to_50mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Size 0 to 50mm min"
                       name="size_0_to_50mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size 0 to 50mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.size_0_to_50mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Size 0 to 50mm max"
                       name="size_0_to_50mm_max"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Above 0 to 50mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.above_0_to_50mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Above 0 to 50mm typical"
                       name="above_0_to_50mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Above 0 to 50mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.above_0_to_50mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Above 0 to 50mm min"
                       name="above_0_to_50mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Above 0 to 50mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.above_0_to_50mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Above 0 to 50mm max"
                       name="above_0_to_50mm_max"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Under 2mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.under_2mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Under 2mm typical"
                       name="under_2mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Under 2mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.under_2mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Under 2mm min"
                       name="under_2mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Under 2mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.under_2mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Under 2mm max"
                       name="under_2mm_max"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size above 2mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.size_above_2mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Size above 2mm typical"
                       name="size_above_2mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size above 2mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.size_above_2mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Size above 2mm min"
                       name="size_above_2mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size above 2mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={formData.size_above_2mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Size above 2mm max"
                       name="size_above_2mm_max" />
                   </FloatingLabel>
                 </Form.Group>
               </Row>
               <Row className="mb-3">
                 <h4 className="heading">Testing Method</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Testing Method"
                   >
                     <Form.Select
                       aria-label="Floating label select example"
                       name="testing_method"
                       placeholder="Testing method"
                       defaultValue={formData.size_above_2mm_max}
         onChange={handleChange}
                     >
                       <option defaultValue="">Select </option>
                       <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                         According to ASTM Standard Method Unless Otherwise Stated
                       </option>
                       <option defaultValue="According to ISO Methods">
                         According to ISO Methods
                       </option>
                       <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                         According to ISO Methods Except HGI, Trace Elements and Ash
                         Analysis to ASTM
                       </option>
                       <option defaultValue="According to ISO Methods Except HGI to ASTM">
                         According to ISO Methods Except HGI to ASTM
                       </option>
                       <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                         According to ISO Methods, Except HGI and Ash Analysis to
                         ASTM
                       </option>
                       <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                         According to ISO Methods, Except HGI as per ASTM Test Method
                       </option>
                     </Form.Select>
                   </FloatingLabel>
                 </Form.Group>
               </Row>
{/*           */}


<p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/opportunity/quote-view/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
       <p className='result'><p></p><p></p></p>



         </form>
           </div>
      <Footer/>
      </div>
  );
};

export default AddQuoteproduct;

// {product_code: 'rwood_code_1', product_name: '', product_account: '', product_description: ''}
