import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import { API_URL } from '../config';
import axios from "axios";
import Footer from '../Footer'
import Header from '../Header'
import { AiFillDiff } from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const PostShipmentList = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $(".contactAccount").css({"min-height":(window_height)+"px"})


  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/postshipments', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data.postshipments;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Post Shipment List </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Post Shipment List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">


      </div>
    </div>
  </div>
  <div class='tasks'>
  <table id='tables'>
    <thead>
    <tr>
    <th>postshiment No</th>
    <th>COO Status</th>
      <th>COW Status</th>
      <th>DSR Status</th>
      <th>Exchange Rate</th>
      <th>Currency</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
    <td><Link to={"/postshipment/postshipment-details/"+x.id }>{x.post_code_no}</Link></td>
      <td>{x.coo_status}</td>
      <td>{x.cow_status}</td>
      <td>{x.dsr_status}</td>
      <td>{x.exchange_rate}</td>
      <td>{x.currency}</td>
      <td><Link to={"/contact/edit-contact/"+x.id }><FaPencilAlt/></Link> <FaTrashAlt/><Link to={"/postshipment/postshipment-details/"+x.id }><AiFillDiff/></Link></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>
    </div> </div>
    <Footer/>
    </div>
  );
};

export default PostShipmentList;