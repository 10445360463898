import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer';import { API_URL } from '../config';
import DatePicker from "react-datepicker";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { AES, enc } from "crypto-js";
import $ from 'jquery';
import { useParams  } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OppShipmentLists from "../opportunity/oppShipmentList";
function Addcontracttcf() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [rwooduser, setRwooduser]=useState([]);
  // supplier
  useEffect(() => {

    fetchDatarusers();
  }, [decryptedAccessToken]);

  const fetchDatarusers = async () => {
    try {
      const response = await axios.get(API_URL+'get/contractbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data[0].contract_details.rwood_contract_number;
      console.log(data)
      setRwooduser(data);

    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();
    const location = useLocation();
      const { account_record } = location.state;
  const params = useParams()
  const id=params.id
  // const body=$(window).height()
  // const header=$("#header").height()
  // const nav=$(".navbar").height()
  // const footer=$(".footer").height()
  // const total_height=header+nav+footer;
  // const window_height=body-total_height;
  // $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas,setDatas]=useState([]);
  const [getPrice, setgetPrice]=useState([]);
  const [companies, setCompanies]=useState([]);
  const [rwoodbank, setRwoodbank]=useState([]);
  const [priceBook, setPriceBook]=useState([]);
  const [rwoodusers, setRwoodusers]=useState([]);
  const [buyers, setBuyer]=useState([]);
  const [suppliers, setSupplier]=useState([]);
  const [price, setPrice]=useState([]);

  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodcompany', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwoodcompany;
      setCompanies(data);

    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodbank', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwood_bank;
      // console.log(data)
      setRwoodbank(data);

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {

    fetchData2();
  }, [decryptedAccessToken]);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/pricebook', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
    //   console.log(data)
      setPriceBook(data);

    } catch (error) {
      console.error(error);
    }
  };
const [opp,setOpp]=useState([])
  useEffect(() => {

    fetchDataOpp();
  }, [decryptedAccessToken]);

  const fetchDataOpp = async () => {
    try {
      const response = await axios.get(API_URL+'all/opportunity', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.opportunity;
      console.log(data)
    setOpp(data);

    } catch (error) {
      console.error(error);
    }
  };


const [product,setProduct]=useState([])
useEffect(() => {

  fetchDataProduct();
}, [decryptedAccessToken]);

const fetchDataProduct = async () => {
  try {
    const response = await axios.get(API_URL+'get/allproducts', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    console.log(data)
    setProduct(data);

  } catch (error) {
    console.error(error);
  }
};

  useEffect(() => {

    fetchData3();
  }, [decryptedAccessToken]);

  const fetchData3 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodusers ', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
    //   console.log(data)
      setRwoodusers(data);

    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    fetchData4();
  }, [decryptedAccessToken]);

  const fetchData4 = async () => {
    try {
      const response = await axios.get(API_URL+'get/buyer', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data[0].account_name;
      setBuyer(data);

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {

    fetchData5();
  }, [decryptedAccessToken]);

  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/supplier', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
    //   console.log(data)
      setSupplier(data);

    } catch (error) {
      console.error(error);
    }
  };
  const [startDate1, setStartDate1] = useState(new Date());
   const birthDate=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate2, setStartDate2] = useState(new Date());
   const BankDate=startDate2.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate5, setStartDate5] = useState(new Date());
   const BankDate5=startDate2.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate6, setStartDate6] = useState(new Date());
   const BankDate6=startDate2.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate3, setStartDate3] = useState(new Date());
   const BankDate1=startDate2.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  useEffect(() => {

    fetchData6();
  }, [decryptedAccessToken]);

  const fetchData6 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/price_items', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.priceitem;
    //   console.log(data)
      setPrice(data);

    } catch (error) {
      console.error(error);
    }
  };
const [actual, setActual] = useState(false);
      const [locked, setLocked] = useState(false);

 const { register, handleSubmit, reset, formState: { errors } } = useForm();
 if (account_record === "Buyer - Spot") {
 const onSubmit = data =>{
     const responseData={
      locked:data.locked,
      tcf_date:birthDate,
company:data.company,
currency:data.currency,
exchange_rate:data.exchange_rate,
// rwood_bank_master:data.rwood_bank_master,
tcf_status:data.tcf_status,
buyer_contract:data.buyer_contract,
contract_shipment:data.contract_shipment,
product:data.product,
product_master:data.product_master,
product_name:data.product_name,
record_type:data.record_type,
opportunity:data.opportunity,
quote:data.quote,
owner:data.owner,
auto_select_pricebook:data.auto_select_pricebook,
price_book:data.price_book,
trader_name:data.trader_name,
purchase_confirmation_date:BankDate5,
shipment_gross_margin_ton:data.shipment_gross_margin_ton,
shipment_sales_price:data.shipment_sales_price,
buyer:data.buyer,
supplier:data.supplier,
product_description:data.product_description,
product_specification:data.product_specification,
quantity_MT:data.quantity_MT,
price_basis:data.price_basis,
vessel_type:data.vessel_type,
payment_term:data.payment_term,
selling_terms:data.selling_terms,
buying_terms:data.buying_terms,
price_adjustment:data.price_adjustment,
status_of_purchase_contract:data.status_of_purchase_contract,
proposed_laycan_from:BankDate,
proposed_laycan_to:BankDate1,
contractual_GAR_S:data.contractual_GAR_S,
contractual_GAR_S:data.contractual_GAR_S,
contractual_TM_AR_P:data.contractual_TM_AR_P,
contractual_TM_AR_S:data.contractual_TM_AR_S,
contractual_Ash_AR_P:data.contractual_Ash_AR_P,
contractual_Ash_AR_S:data.contractual_Ash_AR_S,
contractual_Ts_ADB_P:data.contractual_Ts_ADB_P,
contractual_Ts_ADB_S:data.contractual_Ts_ADB_S,
contractual_Ash_ADB_P:data.contractual_Ash_ADB_P,
contractual_Ash_ADB_S:data.contractual_Ash_ADB_S,
projected_price_basis_GAR_P:data.projected_price_basis_GAR_P,
projected_price_basis_GAR_S:data.projected_price_basis_GAR_S,
contractual_HBA_year:data.contractual_HBA_year,
contractual_HBA_month:data.contractual_HBA_month,
discounted_price:data.discounted_price,
projected_index_value:data.projected_index_value,
projected_purchase_type:data.projected_purchase_type,
projected_fixed_purchase_price:data.projected_fixed_purchase_price,
projected_purchase_price_margin:data.projected_purchase_price_margin,
projected_barge_discount:data.projected_barge_discount,
projected_sale_price_type:data.projected_sale_price_type,
projected_fixed_sales_price:data.projected_fixed_sales_price,
projected_sales_price_margin:data.projected_sales_price_margin,
projected_pur_amount:data.projected_sales_price_margin,
projected_freight_rate:data.projected_freight_rate,
projected_freight_amount:data.projected_freight_amount,
projected_insurance:data.projected_insurance,
projected_commission:data.projected_commission,
projected_fixed_apportioned_overheads:data.projected_fixed_apportioned_overheads,
projected_any_contract_specific_cost:data.projected_any_contract_specific_cost,
projected_extra_cost:data.projected_extra_cost,
projected_provision_for_corporate_tax:data.sss,
vessel_name:data.vessel_name,
stowage_qty:data.stowage_qty,
destination:data.destination,
actual_bl_date:BankDate6,
actual_bl_qty:data.actual_bl_qty,
actual_hba:data.actual_hba,
actual_hba_purchase:data.actual_hba_purchase,
expected_value_date:data.expected_value_date,
demmurage_rate_p:data.demmurage_rate_p,
demmurage_rate_s:data.demmurage_rate_s,
load_rate:data.load_rate,
gcnewc:data.gcnewc,
actual_gcnewc_sales:data.actual_gcnewc_sales,
purchase_price_type:data.purchase_price_type,
fixed_purchase_price:data.fixed_purchase_price,
purchase_price_margin:data.purchase_price_margin,
barge_discount:data.barge_discount,
actual_data_rcvd:data.actual_data_rcvd,
sale_price_type:data.sale_price_type,
fixed_sale_price:data.fixed_sale_price,
sale_price_margin:data.sale_price_margin,
actual_gar_p:data.actual_gar_p,
actual_tm_p:data.actual_tm_p,
actual_ash_ar_p:data.actual_ash_ar_p,
actual_ts_ar_p:data.actual_ts_ar_p,
actual_ash_adb_p:data.actual_ash_adb_p,
actual_ts_adb_p:data.actual_ts_adb_p,
actual_aft_p:data.actual_aft_p,
actual_vm_p:data.actual_vm_p,
actual_hgi_p:data.actual_hgi_p,
price_basis_gar_p:data.price_basis_gar_p,
gar_p:data.gar_p,
actual_gar_s:data.actual_gar_s,
actual_tm_s:data.actual_tm_s,
actual_ash_ar_s:data.actual_ash_ar_s,
actual_ts_ar_s:data.actual_ts_ar_s,
actual_ash_adb_s:data.actual_ash_adb_s,
actual_ts_adb_s:data.actual_ts_adb_s,
actual_aft_s:data.actual_aft_s,
actual_vm_s:data.actual_vm_s,
actual_hgi_s:data.actual_hgi_s,
price_basis_gar_s:data.price_basis_gar_s,
gar_s:data.gar_s,
sulfur_bonus_rate_MT_S:data.sulfur_bonus_rate_MT_S,
sulfur_penalty_rate_MT_S:data.sulfur_penalty_rate_MT_S,
ash_bonus_rate_MT_S:data.ash_bonus_rate_MT_S,
ash_penalty_rate_MT_S:data.ash_penalty_rate_MT_S,
actual_pur_amount:data.actual_pur_amount,
actual_freight_rate:data.actual_freight_rate,
actual_freight_amount:data.actual_freight_amount,
actual_insurance:data.actual_insurance,
actual_commission:data.actual_commission,
actual_fixed_apportioned_overheads:data.actual_fixed_apportioned_overheads,
actual_contract_specific_cost:data.actual_contract_specific_cost,
actual_prov_for_corporate_tax:data.actual_prov_for_corporate_tax,
actual_withholding_tax:data.actual_withholding_tax,
final_total_cost:data.final_total_cost,
any_deviation:data.any_deviation,
prepared_by:data.prepared_by,
check_and_approved_by:data.check_and_approved_by,
validated_by:data.validated_by,
remarks:data.remarks

   };
// console.log(JSON.stringify(responseData))
   const encryptedAccessToken = localStorage.getItem('access_token');
     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

     fetch(API_URL+'add/contract/tcf/'+id, {
               method: "POST",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())

               .then((response) => {
                 toast.success('TCF Added Successfully', {
                      position: "bottom-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
                      setTimeout(() => {
                        reset();
                        navigate("/contract/contract-detail/"+id);
                      }, 2000);
               // $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
              //  $('.priceDiv').hide(2000)


               })

             }

     };

  return (

    <div className="priceAccount" id='tcf-banner'>
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add TCF</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contract / Add Contract Tcf</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
      <Row className="mb-3">
              <h4 className="heading">Information</h4>
                    <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TCF date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='TCF date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Company">
    <Form.Select aria-label="Floating label select example"
    name="company"
    placeholder="Company"
    {...register("company", { required: true })}
    >
      <option value='' >Select</option>
       {
          companies.map((x)=>{
              return(
                  <option value={x.company_name}>{x.company_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.company && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Currency">
    <Form.Select aria-label="Floating label select example"
    name="currency"
    placeholder="Currency"
    {...register("currency", { required: false })}
    >
      <option value='' >Select</option>
      <option value='USD' >USD</option>
      <option value='IDR' >IDR</option>

    </Form.Select>
    {errors.currency && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Exchange rate"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Exchange rate"
              name="exchange_rate"
              {...register("exchange_rate", {valueAsNumber:true, required: false })}
            />
            {errors.exchange_rate && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="order"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="order"
              name="order"
              {...register("order", { required: false })}
            />
            {errors.order && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Rwood Bank Master">
    <Form.Select aria-label="Floating label select example"
    name="rwood_bank_master"
    placeholder="Rwood bank master"
    {...register("rwood_bank_master", { required: false })}
    >
      <option value='' >Select</option>
       {
          rwoodbank.map((x)=>{
              return(
                  <option value={x.bank_full_name}>{x.bank_full_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.rwood_bank_master && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>



      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Tcf status"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Tcf status"
              name="tcf_status"
              {...register("tcf_status", { required: false })}
            />
            {errors.tcf_status && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer contract"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Buyer contract"
              name="buyer_contract"
              defaultValue={rwooduser} className='record-type'

              {...register("buyer_contract", { required: false })}
            />
            {errors.buyer_contract && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract shipment"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contract shipment"
              name="contract_shipment"
              {...register("contract_shipment", { required: false })}
            />
            {errors.contract_shipment && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="product"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="product"
              name="product"
              {...register("product", { required: false })}
            />
            {errors.product && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Product Master">
    <Form.Select aria-label="Floating label select example"
    name="product_master"
    placeholder="Product Master"
    {...register("product_master", { required: true })}
    >
      <option value='' >Select</option>
       {
          product.map((x)=>{
              return(
                  <option value={x.product_code}>{x.product_code}</option>
              )
          })
       }
    </Form.Select>
    {errors.product_master && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Product name"
              name="product_name"
              {...register("product_name", { required: false })}
            />
            {errors.product_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
            <input type='checkbox' name='locked' onChange={(e) => setLocked(e.target.checked) } /><label>Locked</label>
          </Form.Group>

      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Record type"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Record type"
              name="record_type"
              defaultValue={account_record}
              {...register("record_type", { required: false })}
            />
            {errors.record_type && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Opportunity">
    <Form.Select aria-label="Floating label select example"
    name="opportunity"
    placeholder="Opportunity"
    {...register("opportunity", { required: true })}
    >
      <option value='' >Select</option>
       {
          opp.map((x)=>{
              return(
                  <option value={x.opportunity_name}>{x.opportunity_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.opportunity && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="quote"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="quote"
              name="quote"
              {...register("quote", { required: false })}
            />
            {errors.quote && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="owner"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="owner"
              name="owner"
              {...register("owner", { required: false })}
            />
            {errors.owner && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Auto select pricebook"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Auto select pricebook"
              name="auto_select_pricebook"
              {...register("auto_select_pricebook", { required: false })}
            />
            {errors.auto_select_pricebook && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"
    defaultValue=""
    {...register("price_book", { required: false })}
    >
      <option value='' >Select</option>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.price_book && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Trader Name">
    <Form.Select aria-label="Floating label select example"
    name="trader_name"
    placeholder="Trader Name"
    defaultValue=""
    {...register("trader_name", { required: false })}
    >
      <option value='' >Select</option>
       {
          rwoodusers.map((x)=>{
              return(
                  <option value={x.rwood_username}>{x.rwood_username}</option>
              )
          })
       }
    </Form.Select>
    {errors.trader_name && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>


          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase confirmation date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate5}
      onChange={(date) => setStartDate5(date)}
      customInput={<CustomInput5/>}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Purchase confirmation date'
    />
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Shipment Summary</h4>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipment gross margin ton"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Shipment gross margin ton"
              name="shipment_gross_margin_ton"
              {...register("shipment_gross_margin_ton", {valueAsNumber:true, required: false })}
            />
            {errors.shipment_gross_margin_ton && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipment sales price"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Shipment sales price"
              name="shipment_sales_price"
              {...register("shipment_sales_price", {valueAsNumber:true, required: false })}
            />
            {errors.shipment_sales_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Trade Confirmation Details</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Buyer"
              name="buyer"
              defaultValue={buyers}
              {...register("buyer", { required: false })}
            />
            {errors.buyer && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="supplier"
    placeholder="Supplier"
    defaultValue=""
    {...register("supplier", { required: true })}
    >
      <option value='' >Select</option>
       {
          suppliers.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.supplier && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
       <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Product Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Product Description"
                    style={{ height: "100px" }}
                    name="product_description"
                    {...register("product_description", { required: false })}
                  />
                  {errors.product_description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

       <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product specification"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Product specification"
              name="product_specification"
              {...register("product_specification", { required: false })}
            />
            {errors.product_specification && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quantity MT"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Quantity MT"
              name="quantity_MT"
              {...register("quantity_MT", {valueAsNumber:true, required: false })}
            />
            {errors.quantity_MT && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price basis"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Price basis"
              name="price_basis"
              {...register("price_basis", { required: false })}
            />
            {errors.price_basis && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"
                    defaultValue=""
                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payment term"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Payment term"
              name="payment_term"
              {...register("payment_term", { required: false })}
            />
            {errors.payment_term && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Selling terms"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Selling terms"
              name="selling_terms"
              {...register("selling_terms", { required: false })}
            />
            {errors.selling_terms && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buying terms"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Buying terms"
              name="buying_terms"
              {...register("buying_terms", { required: false })}
            />
            {errors.buying_terms && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price adjustment"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Price adjustment"
              name="price_adjustment"
              {...register("price_adjustment", { required: false })}
            />
            {errors.price_adjustment && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Status of purchase contract"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Status of purchase contract"
              name="status_of_purchase_contract"
              {...register("status_of_purchase_contract", { required: false })}
            />
            {errors.status_of_purchase_contract && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Proposed laycan from"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<CustomInput2/>}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Proposed laycan from'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Proposed laycan to"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate3}
      onChange={(date) => setStartDate3(date)}
      customInput={<CustomInput3 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Proposed laycan to'
    />
          </FloatingLabel>
        </Form.Group></Row>
        <Row>
        <h4 className='heading'>Contractual</h4>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual GAR S"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual GAR S"
              name="contractual_GAR_S"
              {...register("contractual_GAR_S", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_GAR_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual GAR P"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual GAR P"
              name="contractual_GAR_P"
              {...register("contractual_GAR_P", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_GAR_P && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual TM AR P"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual TM AR P"
              name="contractual_TM_AR_P"
              {...register("contractual_TM_AR_P", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_TM_AR_P && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual TM AR S"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual TM AR S"
              name="contractual_TM_AR_S"
              {...register("contractual_TM_AR_S", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_TM_AR_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual Ash AR P"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual Ash AR P"
              name="contractual_Ash_AR_P"
              {...register("contractual_Ash_AR_P", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_Ash_AR_P && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual Ash AR S"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual Ash AR S"
              name="contractual_Ash_AR_S"
              {...register("contractual_Ash_AR_S", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_Ash_AR_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual Ts ADB P"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual Ts ADB P"
              name="contractual_Ts_ADB_P"
              {...register("contractual_Ts_ADB_P", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_Ts_ADB_P && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual Ts ADB S"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual Ts ADB S"
              name="contractual_Ts_ADB_S"
              {...register("contractual_Ts_ADB_S", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_Ts_ADB_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual Ash ADB P"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual Ash ADB P"
              name="contractual_Ash_ADB_P"
              {...register("contractual_Ash_ADB_P", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_Ash_ADB_P && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual Ash ADB S"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual Ash ADB S"
              name="contractual_Ash_ADB_S"
              {...register("contractual_Ash_ADB_S", {valueAsNumber:true, required: false })}
            />
            {errors.contractual_Ash_ADB_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected price basis GAR P"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected price basis GAR P"
              name="projected_price_basis_GAR_P"
              {...register("projected_price_basis_GAR_P", {valueAsNumber:true, required: false })}
            />
            {errors.projected_price_basis_GAR_P && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected price basis GAR S"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected price basis GAR S"
              name="projected_price_basis_GAR_S"
              {...register("projected_price_basis_GAR_S", {valueAsNumber:true, required: false })}
            />
            {errors.projected_price_basis_GAR_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual HBA year"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual HBA year"
              name="contractual_HBA_year"
              {...register("contractual_HBA_year", { valueAsNumber:true,required: false })}
            />
            {errors.contractual_HBA_year && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contractual HBA month"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contractual HBA month"
              name="contractual_HBA_month"
              {...register("contractual_HBA_month", { required: false })}
            />
            {errors.contractual_HBA_month && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Discounted price"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Discounted price"
              name="discounted_price"
              {...register("discounted_price", { valueAsNumber:true,required: false })}
            />
            {errors.discounted_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected index value"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected index value"
              name="projected_index_value"
              {...register("projected_index_value", {valueAsNumber:true, required: false })}
            />
            {errors.projected_index_value && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Projected Purchase Type">
    <Form.Select aria-label="Floating label select example"
    name="projected_purchase_type"
    placeholder="Projected Purchase Type"
    defaultValue=""
    {...register("projected_purchase_type", { required: false })}
    >
      <option value='' >Select</option>
       {
          price.map((x)=>{
              return(
                  <option value={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.company && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected fixed purchase price"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected fixed purchase price"
              name="projected_fixed_purchase_price"
              {...register("projected_fixed_purchase_price", {valueAsNumber:true, required: false })}
            />
            {errors.projected_fixed_purchase_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected purchase price margin"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected purchase price margin"
              name="projected_purchase_price_margin"
              {...register("projected_purchase_price_margin", {valueAsNumber:true, required: false })}
            />
            {errors.projected_purchase_price_margin && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected barge discount"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected barge discount"
              name="projected_barge_discount"
              {...register("projected_barge_discount", {valueAsNumber:true, required: false })}
            />
            {errors.projected_barge_discount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Projected Sale Price Type">
    <Form.Select aria-label="Floating label select example"
    name="projected_sale_price_type"
    placeholder="Projected Sale Price Type"
    defaultValue=""
    {...register("projected_sale_price_type", { required: false })}
    >
      <option value='' >Select</option>
       {
          price.map((x)=>{
              return(
                  <option value={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.projected_sale_price_type && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected fixed sales price"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected fixed sales price"
              name="projected_fixed_sales_price"
              {...register("projected_fixed_sales_price", {valueAsNumber:true, required: false })}
            />
            {errors.projected_fixed_sales_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected sales price margin"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected sales price margin"
              name="projected_sales_price_margin"
              {...register("projected_sales_price_margin", { valueAsNumber:true,required: false })}
            />
            {errors.projected_sales_price_margin && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Cost Sheet Projection</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected freight rate"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected freight rate"
              name="projected_freight_rate"
              {...register("projected_freight_rate", {valueAsNumber:true, required: false })}
            />
            {errors.projected_freight_rate && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected freight amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected freight amount"
              name="projected_freight_amount"
              {...register("projected_freight_amount", {valueAsNumber:true, required: false })}
            />
            {errors.projected_freight_amount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected insurance"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected insurance"
              name="projected_insurance"
              {...register("projected_insurance", {valueAsNumber:true, required: false })}
            />
            {errors.projected_insurance && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected commission"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected commission"
              name="projected_commission"
              {...register("projected_commission", {valueAsNumber:true, required: false })}
            />
            {errors.projected_commission && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected fixed apportioned overheads"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected fixed apportioned overheads"
              name="projected_fixed_apportioned_overheads"
              {...register("projected_fixed_apportioned_overheads", {valueAsNumber:true, required: false })}
            />
            {errors.projected_fixed_apportioned_overheads && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected any contract specific cost"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected any contract specific cost"
              name="projected_any_contract_specific_cost"
              {...register("projected_any_contract_specific_cost", { valueAsNumber:true, required: false })}
            />
            {errors.projected_any_contract_specific_cost && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected extra cost"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected extra cost"
              name="projected_extra_cost"
              {...register("projected_extra_cost", { valueAsNumber:true, required: false })}
            />
            {errors.projected_extra_cost && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected provision for corporate tax"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected provision for corporate tax"
              name="projected_provision_for_corporate_tax"
              {...register("projected_provision_for_corporate_tax", {valueAsNumber:true, required: false })}
            />
            {errors.projected_provision_for_corporate_tax && <span>Required</span>}
          </FloatingLabel>

        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Vessel name"
              name="vessel_name"
              {...register("vessel_name", { required: false })}
            />
            {errors.vessel_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Stowage qty"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Stowage qty"
              name="stowage_qty"
              {...register("stowage_qty", {valueAsNumber:true, required: false })}
            />
            {errors.stowage_qty && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Destination"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Destination"
              name="destination"
              {...register("destination", { required: false })}
            />
            {errors.destination && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual BL Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate6}
      onChange={(date) => setStartDate6(date)}
      customInput={<CustomInput6 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Actual BL Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual bl qty"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual bl qty"
              name="actual_bl_qty"
              {...register("actual_bl_qty", { valueAsNumber:true,required: false })}
            />
            {errors.actual_bl_qty && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual hba"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual hba"
              name="actual_hba"
              {...register("actual_hba", {valueAsNumber:true, required: false })}
            />
            {errors.actual_hba && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual hba purchase"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual hba purchase"
              name="actual_hba_purchase"
              {...register("actual_hba_purchase", {valueAsNumber:true, required: false })}
            />
            {errors.actual_hba_purchase && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Expected value date"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Expected value date"
              name="expected_value_date"
              {...register("expected_value_date", { required: false })}
            />
            {errors.expected_value_date && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Demmurage rate p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Demmurage rate p"
              name="demmurage_rate_p"
              {...register("demmurage_rate_p", {valueAsNumber:true, required: false })}
            />
            {errors.demmurage_rate_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Demmurage rate s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Demmurage rate s"
              name="demmurage_rate_s"
              {...register("demmurage_rate_s", {valueAsNumber:true, required: false })}
            />
            {errors.demmurage_rate_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Load rate"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Load rate"
              name="load_rate"
              {...register("load_rate", {valueAsNumber:true, required: false })}
            />
            {errors.load_rate && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Gcnewc"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Gcnewc"
              name="gcnewc"
              {...register("gcnewc", {valueAsNumber:true, required: false })}
            />
            {errors.gcnewc && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual gcnewc sales"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual gcnewc sales"
              name="actual_gcnewc_sales"
              {...register("actual_gcnewc_sales", {valueAsNumber:true, required: false })}
            />
            {errors.actual_gcnewc_sales && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Actual Cost Elements</h4>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Purchase Price Type">
    <Form.Select aria-label="Floating label select example"
    name="purchase_price_type"
    placeholder="Purchase Price Type"
    defaultValue=""
    {...register("purchase_price_type", { required: false })}
    >
      <option value='' >Select</option>
       {
          price.map((x)=>{
              return(
                  <option value={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.purchase_price_type && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fixed purchase price"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Fixed purchase price"
              name="fixed_purchase_price"
              {...register("fixed_purchase_price", {valueAsNumber:true, required: false })}
            />
            {errors.fixed_purchase_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase price margin"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Purchase price margin"
              name="purchase_price_margin"
              {...register("purchase_price_margin", {valueAsNumber:true, required: false })}
            />
            {errors.purchase_price_margin && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Barge discount"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Barge discount"
              name="barge_discount"
              {...register("barge_discount", {valueAsNumber:true, required: false })}
            />
            {errors.barge_discount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected Pur Amount"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected Pur Amount"
              name="projected_pur_amount"
              {...register("projected_pur_amount", {valueAsNumber:true, required: false })}
            />
            {errors.projected_pur_amount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

 <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Sale Price Type">
    <Form.Select aria-label="Floating label select example"
    name="sale_price_type"
    placeholder="Sale Price Type"
    defaultValue=""
    {...register("sale_price_type", { required: false })}
    >
      <option value='' >Select</option>
       {
          price.map((x)=>{
              return(
                  <option value={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.sale_price_type && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fixed sale price"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Fixed sale price"
              name="fixed_sale_price"
              {...register("fixed_sale_price", {valueAsNumber:true, required: false })}
            />
            {errors.fixed_sale_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sale price margin"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Sale price margin"
              name="sale_price_margin"
              {...register("sale_price_margin", {valueAsNumber:true, required: false })}
            />
            {errors.sale_price_margin && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual gar p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual gar p"
              name="actual_gar_p"
              {...register("actual_gar_p", { valueAsNumber:true,required: false })}
            />
            {errors.actual_gar_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual tm p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual tm p"
              name="actual_tm_p"
              {...register("actual_tm_p", { valueAsNumber:true, required: false })}
            />
            {errors.actual_tm_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ash ar p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ash ar p"
              name="actual_ash_ar_p"
              {...register("actual_ash_ar_p", {valueAsNumber:true,  required: false })}
            />
            {errors.actual_ash_ar_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ts ar p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ts ar p"
              name="actual_ts_ar_p"
              {...register("actual_ts_ar_p", {valueAsNumber:true, required: false })}
            />
            {errors.actual_ts_ar_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ash adb p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ash adb p"
              name="actual_ash_adb_p"
              {...register("actual_ash_adb_p", {valueAsNumber:true, required: false })}
            />
            {errors.actual_ash_adb_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ts adb p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ts adb p"
              name="actual_ts_adb_p"
              {...register("actual_ts_adb_p", {valueAsNumber:true, required: false })}
            />
            {errors.actual_ts_adb_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual aft p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual aft p"
              name="actual_aft_p"
              {...register("actual_aft_p", { valueAsNumber:true,required: false })}
            />
            {errors.actual_aft_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual vm p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual vm p"
              name="actual_vm_p"
              {...register("actual_vm_p", {valueAsNumber:true, required: false })}
            />
            {errors.actual_vm_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual hgi p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual hgi p"
              name="actual_hgi_p"
              {...register("actual_hgi_p", {valueAsNumber:true, required: false })}
            />
            {errors.actual_hgi_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price basis gar p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Price basis gar p"
              name="price_basis_gar_p"
              {...register("price_basis_gar_p", { valueAsNumber:true, required: false })}
            />
            {errors.price_basis_gar_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Gar p"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Gar p"
              name="gar_p"
              {...register("gar_p", { valueAsNumber:true, required: false })}
            />
            {errors.gar_p && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual gar s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual gar s"
              name="actual_gar_s"
              {...register("actual_gar_s", { valueAsNumber:true, required: false })}
            />
            {errors.actual_gar_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual tm s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual tm s"
              name="actual_tm_s"
              {...register("actual_tm_s", { valueAsNumber:true, required: false })}
            />
            {errors.actual_tm_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ash ar s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ash ar s"
              name="actual_ash_ar_s"
              {...register("actual_ash_ar_s", {valueAsNumber:true, required: false })}
            />
            {errors.actual_ash_ar_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ts ar s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ts ar s"
              name="actual_ts_ar_s"
              {...register("actual_ts_ar_s", {valueAsNumber:true, required: false })}
            />
            {errors.actual_ts_ar_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ash adb s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ash adb s"
              name="actual_ash_adb_s"
              {...register("actual_ash_adb_s", {valueAsNumber:true, required: false })}
            />
            {errors.actual_ash_adb_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual ts adb s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual ts adb s"
              name="actual_ts_adb_s"
              {...register("actual_ts_adb_s", { valueAsNumber:true,required: false })}
            />
            {errors.actual_ts_adb_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual aft s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual aft s"
              name="actual_aft_s"
              {...register("actual_aft_s", { valueAsNumber:true,required: false })}
            />
            {errors.actual_aft_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual vm s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual vm s"
              name="actual_vm_s"
              {...register("actual_vm_s", { valueAsNumber:true,required: false })}
            />
            {errors.actual_vm_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual hgi s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual hgi s"
              name="actual_hgi_s"
              {...register("actual_hgi_s", {valueAsNumber:true, required: false })}
            />
            {errors.actual_hgi_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price basis gar s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Price basis gar s"
              name="price_basis_gar_s"
              {...register("price_basis_gar_s", {valueAsNumber:true, required: false })}
            />
            {errors.price_basis_gar_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Gar s"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Gar s"
              name="gar_s"
              {...register("gar_s", {valueAsNumber:true, required: false })}
            />
            {errors.gar_s && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Bonus / Penalty</h4>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulfur bonus rate MT S"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Sulfur bonus rate MT S"
              name="sulfur_bonus_rate_MT_S"
              {...register("sulfur_bonus_rate_MT_S", {valueAsNumber:true, required: false })}
            />
            {errors.sulfur_bonus_rate_MT_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulfur penalty rate MT S"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Sulfur penalty rate MT S"
              name="sulfur_penalty_rate_MT_S"
              {...register("sulfur_penalty_rate_MT_S", {valueAsNumber:true, required: false })}
            />
            {errors.sulfur_penalty_rate_MT_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ash bonus rate MT S"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Ash bonus rate MT S"
              name="ash_bonus_rate_MT_S"
              {...register("ash_bonus_rate_MT_S", {valueAsNumber:true, required: false })}
            />
            {errors.ash_bonus_rate_MT_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ash penalty rate MT S"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Ash penalty rate MT S"
              name="ash_penalty_rate_MT_S"
              {...register("ash_penalty_rate_MT_S", {valueAsNumber:true, required: false })}
            />
            {errors.ash_penalty_rate_MT_S && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Cost Sheet Actual</h4>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual pur amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual pur amount"
              name="actual_pur_amount"
              {...register("actual_pur_amount", {valueAsNumber:true, required: false })}
            />
            {errors.actual_pur_amount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual freight rate"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual freight rate"
              name="actual_freight_rate"
              {...register("actual_freight_rate", {valueAsNumber:true, required: false })}
            />
            {errors.actual_freight_rate && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual freight amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual freight amount"
              name="actual_freight_amount"
              {...register("actual_freight_amount", { valueAsNumber:true,required: false })}
            />
            {errors.actual_freight_amount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual insurance"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual insurance"
              name="actual_insurance"
              {...register("actual_insurance", {valueAsNumber:true, required: false })}
            />
            {errors.actual_insurance && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual commission"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual commission"
              name="actual_commission"
              {...register("actual_commission", {valueAsNumber:true, required: false })}
            />
            {errors.actual_commission && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Shipment Cost</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual fixed apportioned overheads"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual fixed apportioned overheads"
              name="actual_fixed_apportioned_overheads"
              {...register("actual_fixed_apportioned_overheads", {valueAsNumber:true, required: false })}
            />
            {errors.actual_fixed_apportioned_overheads && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual contract specific cost"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual contract specific cost"
              name="actual_contract_specific_cost"
              {...register("actual_contract_specific_cost", { valueAsNumber:true,required: false })}
            />
            {errors.actual_contract_specific_cost && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual prov for corporate tax"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual prov for_corporate tax"
              name="actual_prov_for_corporate_tax"
              {...register("actual_prov_for_corporate_tax", {valueAsNumber:true, required: false })}
            />
            {errors.actual_prov_for_corporate_tax && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual withholding tax"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual withholding tax"
              name="actual_withholding_tax"
              {...register("actual_withholding_tax", {valueAsNumber:true, required: false })}
            />
            {errors.actual_withholding_tax && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Final total cost"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Final total cost"
              name="final_total_cost"
              {...register("final_total_cost", {valueAsNumber:true, required: false })}
            />
            {errors.final_total_cost && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Rwood Interenal Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Any deviation"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Any deviation"
              name="any_deviation"
              {...register("any_deviation", { required: false })}
            />
            {errors.any_deviation && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Prepared by"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Prepared by"
              name="prepared_by"
              {...register("prepared_by", { required: false })}
            />
            {errors.prepared_by && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Check and approved by"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Check and approved by"
              name="check_and_approved_by"
              {...register("check_and_approved_by", { required: false })}
            />
            {errors.check_and_approved_by && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Validated by"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Validated by"
              name="validated_by"
              {...register("validated_by", { required: false })}
            />
            {errors.validated_by && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Remarks"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Remarks"
              name="remarks"
              {...register("remarks", { required: false })}
            />
            {errors.remarks && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>



      </Row>
      <input type="submit" className='price-submit' />
      <div className='priceDiv'><p></p><p></p></div>
    </Form>
    </div>
    <Footer/>
    </div>

  );
}
if (account_record === "Buyer - Long Term") {
  const onSubmit = data =>{
      const responseData={
       locked:data.locked,
       tcf_date:birthDate,
 company:data.company,
 currency:data.currency,
 exchange_rate:data.exchange_rate,
//  rwood_bank_master:data.rwood_bank_master,
 tcf_status:data.tcf_status,
 buyer_contract:data.buyer_contract,
 contract_shipment:data.contract_shipment,
 product:data.product,
 product_master:data.product_master,
 product_name:data.product_name,
 record_type:data.record_type,
 opportunity:data.opportunity,
 quote:data.quote,
 owner:data.owner,
 auto_select_pricebook:data.auto_select_pricebook,
 price_book:data.price_book,
 trader_name:data.trader_name,
 purchase_confirmation_date:BankDate5,
 shipment_gross_margin_ton:data.shipment_gross_margin_ton,
 shipment_sales_price:data.shipment_sales_price,
 buyer:data.buyer,
 supplier:data.supplier,
 product_description:data.product_description,
 product_specification:data.product_specification,
 quantity_MT:data.quantity_MT,
 price_basis:data.price_basis,
 vessel_type:data.vessel_type,
 payment_term:data.payment_term,
 selling_terms:data.selling_terms,
 buying_terms:data.buying_terms,
 price_adjustment:data.price_adjustment,
 status_of_purchase_contract:data.status_of_purchase_contract,
 proposed_laycan_from:BankDate,
 proposed_laycan_to:BankDate1,
 contractual_GAR_S:data.contractual_GAR_S,
 contractual_GAR_S:data.contractual_GAR_S,
 contractual_TM_AR_P:data.contractual_TM_AR_P,
 contractual_TM_AR_S:data.contractual_TM_AR_S,
 contractual_Ash_AR_P:data.contractual_Ash_AR_P,
 contractual_Ash_AR_S:data.contractual_Ash_AR_S,
 contractual_Ts_ADB_P:data.contractual_Ts_ADB_P,
 contractual_Ts_ADB_S:data.contractual_Ts_ADB_S,
 contractual_Ash_ADB_P:data.contractual_Ash_ADB_P,
 contractual_Ash_ADB_S:data.contractual_Ash_ADB_S,
 projected_price_basis_GAR_P:data.projected_price_basis_GAR_P,
 projected_price_basis_GAR_S:data.projected_price_basis_GAR_S,
 contractual_HBA_year:data.contractual_HBA_year,
 contractual_HBA_month:data.contractual_HBA_month,
 discounted_price:data.discounted_price,
 projected_index_value:data.projected_index_value,
 projected_purchase_type:data.projected_purchase_type,
 projected_fixed_purchase_price:data.projected_fixed_purchase_price,
 projected_purchase_price_margin:data.projected_purchase_price_margin,
 projected_barge_discount:data.projected_barge_discount,
 projected_sale_price_type:data.projected_sale_price_type,
 projected_fixed_sales_price:data.projected_fixed_sales_price,
 projected_sales_price_margin:data.projected_sales_price_margin,
 projected_pur_amount:data.projected_sales_price_margin,
 projected_freight_rate:data.projected_freight_rate,
 projected_freight_amount:data.projected_freight_amount,
 projected_insurance:data.projected_insurance,
 projected_commission:data.projected_commission,
 projected_fixed_apportioned_overheads:data.projected_fixed_apportioned_overheads,
 projected_any_contract_specific_cost:data.projected_any_contract_specific_cost,
 projected_extra_cost:data.projected_extra_cost,
 projected_provision_for_corporate_tax:data.sss,
 vessel_name:data.vessel_name,
 stowage_qty:data.stowage_qty,
 destination:data.destination,
 actual_bl_date:BankDate6,
 actual_bl_qty:data.actual_bl_qty,
 actual_hba:data.actual_hba,
 actual_hba_purchase:data.actual_hba_purchase,
 expected_value_date:data.expected_value_date,
 demmurage_rate_p:data.demmurage_rate_p,
 demmurage_rate_s:data.demmurage_rate_s,
 load_rate:data.load_rate,
 gcnewc:data.gcnewc,
 actual_gcnewc_sales:data.actual_gcnewc_sales,
 purchase_price_type:data.purchase_price_type,
 fixed_purchase_price:data.fixed_purchase_price,
 purchase_price_margin:data.purchase_price_margin,
 barge_discount:data.barge_discount,
 actual_data_rcvd:data.actual_data_rcvd,
 sale_price_type:data.sale_price_type,
 fixed_sale_price:data.fixed_sale_price,
 sale_price_margin:data.sale_price_margin,
 actual_gar_p:data.actual_gar_p,
 actual_tm_p:data.actual_tm_p,
 actual_ash_ar_p:data.actual_ash_ar_p,
 actual_ts_ar_p:data.actual_ts_ar_p,
 actual_ash_adb_p:data.actual_ash_adb_p,
 actual_ts_adb_p:data.actual_ts_adb_p,
 actual_aft_p:data.actual_aft_p,
 actual_vm_p:data.actual_vm_p,
 actual_hgi_p:data.actual_hgi_p,
 price_basis_gar_p:data.price_basis_gar_p,
 gar_p:data.gar_p,
 actual_gar_s:data.actual_gar_s,
 actual_tm_s:data.actual_tm_s,
 actual_ash_ar_s:data.actual_ash_ar_s,
 actual_ts_ar_s:data.actual_ts_ar_s,
 actual_ash_adb_s:data.actual_ash_adb_s,
 actual_ts_adb_s:data.actual_ts_adb_s,
 actual_aft_s:data.actual_aft_s,
 actual_vm_s:data.actual_vm_s,
 actual_hgi_s:data.actual_hgi_s,
 price_basis_gar_s:data.price_basis_gar_s,
 gar_s:data.gar_s,
 sulfur_bonus_rate_MT_S:data.sulfur_bonus_rate_MT_S,
 sulfur_penalty_rate_MT_S:data.sulfur_penalty_rate_MT_S,
 ash_bonus_rate_MT_S:data.ash_bonus_rate_MT_S,
 ash_penalty_rate_MT_S:data.ash_penalty_rate_MT_S,
 actual_pur_amount:data.actual_pur_amount,
 actual_freight_rate:data.actual_freight_rate,
 actual_freight_amount:data.actual_freight_amount,
 actual_insurance:data.actual_insurance,
 actual_commission:data.actual_commission,
 actual_fixed_apportioned_overheads:data.actual_fixed_apportioned_overheads,
 actual_contract_specific_cost:data.actual_contract_specific_cost,
 actual_prov_for_corporate_tax:data.actual_prov_for_corporate_tax,
 actual_withholding_tax:data.actual_withholding_tax,
 final_total_cost:data.final_total_cost,
 any_deviation:data.any_deviation,
 prepared_by:data.prepared_by,
 check_and_approved_by:data.check_and_approved_by,
 validated_by:data.validated_by,
 remarks:data.remarks

    };
//  console.log(JSON.stringify(responseData))
    const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+'add/contract/tcf/'+id, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())

                .then((response) => {
                  toast.success('TCF Added Successfully', {
                       position: "bottom-center",
                       autoClose: 1000,
                       hideProgressBar: false,
                       closeOnClick: true,
                       pauseOnHover: true,
                       draggable: true,
                       progress: undefined,
                       theme: "light",
                       });
                       setTimeout(() => {
                        reset();
                        navigate("/contract/contract-detail/"+id);
                      }, 2000);
                // $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
               //  $('.priceDiv').hide(2000)


                })

              }

      };

   return (

     <div className="priceAccount" id='tcf-banner'>
     <Header/>
     <div>
     <div className="page-header" id=''>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">Add TCF</h2>
       <ol className="breadcrumb">
         <li className="breadcrumb-item active" aria-current="page">Dashboard / TCF / Add Tcf</li>
       </ol>
     </div>
     <div className="d-flex">
       <div className="justify-content-center">

         {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}

       </div>
     </div>
   </div>
      <Form onSubmit={handleSubmit(onSubmit)} id='price'>
       <Row className="mb-3">
               <h4 className="heading">Information</h4>
                     <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="TCF date"
             className="mb-3 datepick"


           >
             <DatePicker
       selected={startDate1}
       onChange={(date) => setStartDate1(date)}
       customInput={<CustomInput />}
       dayClassName={() => "example-datepicker-day-class"}
       popperClassName="example-datepicker-class"
       todayButton="TODAY"
       dateFormat='yyyy-MM-dd'
       placeholderText='TCF date'
     />
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Company">
     <Form.Select aria-label="Floating label select example"
     name="company"
     placeholder="Company"
     {...register("company", { required: true })}
     >
       <option value='' >Select</option>
        {
           companies.map((x)=>{
               return(
                   <option value={x.company_name}>{x.company_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.company && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>

         <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Currency">
     <Form.Select aria-label="Floating label select example"
     name="currency"
     placeholder="Currency"
     {...register("currency", { required: false })}
     >
       <option value='' >Select</option>
       <option value='USD' >USD</option>
       <option value='IDR' >IDR</option>

     </Form.Select>
     {errors.currency && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Exchange rate"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Exchange rate"
               name="exchange_rate"
               {...register("exchange_rate", {valueAsNumber:true, required: false })}
             />
             {errors.exchange_rate && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="order"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="order"
               name="order"
               {...register("order", { required: false })}
             />
             {errors.order && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Rwood Bank Master">
     <Form.Select aria-label="Floating label select example"
     name="rwood_bank_master"
     placeholder="Rwood bank master"
     {...register("rwood_bank_master", { required: false })}
     >
       <option value='' >Select</option>
        {
           rwoodbank.map((x)=>{
               return(
                   <option value={x.bank_full_name}>{x.bank_full_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.rwood_bank_master && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>



       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Tcf status"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Tcf status"
               name="tcf_status"
               {...register("tcf_status", { required: false })}
             />
             {errors.tcf_status && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Buyer contract"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Buyer contract"
               name="buyer_contract"
               defaultValue={rwooduser} className='record-type'
               {...register("buyer_contract", { required: false })}
             />
             {errors.buyer_contract && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contract shipment"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contract shipment"
               name="contract_shipment"
               {...register("contract_shipment", { required: false })}
             />
             {errors.contract_shipment && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="product"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="product"
               name="product"
               {...register("product", { required: false })}
             />
             {errors.product && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Product Master">
    <Form.Select aria-label="Floating label select example"
    name="product_master"
    placeholder="Product Master"
    {...register("product_master", { required: true })}
    >
      <option value='' >Select</option>
       {
          product.map((x)=>{
              return(
                  <option value={x.product_code}>{x.product_code}</option>
              )
          })
       }
    </Form.Select>
    {errors.product_master && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product name"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Product name"
               name="product_name"
               {...register("product_name", { required: false })}
             />
             {errors.product_name && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
         <input type='checkbox' name='locked' onChange={(e) => setLocked(e.target.checked) } /><label>Locked</label>
           </Form.Group>

       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Record type"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Record type"
               name="record_type"
               defaultValue={account_record}
               {...register("record_type", { required: false })}
             />
             {errors.record_type && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Opportunity">
    <Form.Select aria-label="Floating label select example"
    name="opportunity"
    placeholder="Opportunity"
    {...register("opportunity", { required: true })}
    >
      <option value='' >Select</option>
       {
          opp.map((x)=>{
              return(
                  <option value={x.opportunity_name}>{x.opportunity_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.opp && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="quote"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="quote"
               name="quote"
               {...register("quote", { required: false })}
             />
             {errors.quote && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="owner"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="owner"
               name="owner"
               {...register("owner", { required: false })}
             />
             {errors.owner && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Auto select pricebook"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Auto select pricebook"
               name="auto_select_pricebook"
               {...register("auto_select_pricebook", { required: false })}
             />
             {errors.auto_select_pricebook && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Price book">
     <Form.Select aria-label="Floating label select example"
     name="price_book"
     placeholder="Price book"
     defaultValue=""
     {...register("price_book", { required: false })}
     >
       <option value='' >Select</option>
        {
           priceBook.map((x)=>{
               return(
                   <option value={x.price_book_name}>{x.price_book_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.price_book && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>

               <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Trader Name">
     <Form.Select aria-label="Floating label select example"
     name="trader_name"
     placeholder="Trader Name"
     defaultValue=""
     {...register("trader_name", { required: false })}
     >
       <option value='' >Select</option>
        {
           rwoodusers.map((x)=>{
               return(
                   <option value={x.rwood_username}>{x.rwood_username}</option>
               )
           })
        }
     </Form.Select>
     {errors.trader_name && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>


           <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Purchase confirmation date"
             className="mb-3 datepick"


           >
             <DatePicker
       selected={startDate5}
       onChange={(date) => setStartDate5(date)}
       customInput={<CustomInput5/>}
       dayClassName={() => "example-datepicker-day-class"}
       popperClassName="example-datepicker-class"
       todayButton="TODAY"
       dateFormat='yyyy-MM-dd'
       placeholderText='Purchase confirmation date'
     />
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Shipment Summary</h4>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Shipment gross margin ton"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Shipment gross margin ton"
               name="shipment_gross_margin_ton"
               {...register("shipment_gross_margin_ton", {valueAsNumber:true, required: false })}
             />
             {errors.shipment_gross_margin_ton && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Shipment sales price"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Shipment sales price"
               name="shipment_sales_price"
               {...register("shipment_sales_price", {valueAsNumber:true, required: false })}
             />
             {errors.shipment_sales_price && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Trade Confirmation Details</h4>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Buyer"
              name="buyer"
              defaultValue={buyers}
              {...register("buyer", { required: false })}
            />
            {errors.buyer && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Supplier">
     <Form.Select aria-label="Floating label select example"
     name="supplier"
     placeholder="Supplier"
     defaultValue=""
     {...register("supplier", { required: true })}
     >
       <option value='' >Select</option>
        {
           suppliers.map((x)=>{
               return(
                   <option value={x.account_name}>{x.account_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.supplier && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>
        <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingTextarea2"
                   label="Product Description"
                 >
                   <Form.Control
                     as="textarea"
                     placeholder="Product Description"
                     style={{ height: "100px" }}
                     name="product_description"
                     {...register("product_description", { required: false })}
                   />
                   {errors.product_description && <p id="text-area">Required</p>}
                 </FloatingLabel>
               </Form.Group>

        <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product specification"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Product specification"
               name="product_specification"
               {...register("product_specification", { required: false })}
             />
             {errors.product_specification && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Quantity MT"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Quantity MT"
               name="quantity_MT"
               {...register("quantity_MT", {valueAsNumber:true, required: false })}
             />
             {errors.quantity_MT && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Price basis"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Price basis"
               name="price_basis"
               {...register("price_basis", { required: false })}
             />
             {errors.price_basis && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingSelect"
                   className="dropDown"
                   label="Vessel Type"
                 >
                   <Form.Select
                     aria-label="Floating label select example"
                     name="vessel_type"
                     placeholder="Vessel Type"
                     defaultValue=""
                     {...register("vessel_type", { required: false })}
                   >
                     <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                   </Form.Select>
                   {errors.vessel_type && <span>Required</span>}
                 </FloatingLabel>
               </Form.Group>
               <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Payment term"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Payment term"
               name="payment_term"
               {...register("payment_term", { required: false })}
             />
             {errors.payment_term && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Selling terms"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Selling terms"
               name="selling_terms"
               {...register("selling_terms", { required: false })}
             />
             {errors.selling_terms && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Buying terms"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Buying terms"
               name="buying_terms"
               {...register("buying_terms", { required: false })}
             />
             {errors.buying_terms && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Price adjustment"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Price adjustment"
               name="price_adjustment"
               {...register("price_adjustment", { required: false })}
             />
             {errors.price_adjustment && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Status of purchase contract"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Status of purchase contract"
               name="status_of_purchase_contract"
               {...register("status_of_purchase_contract", { required: false })}
             />
             {errors.status_of_purchase_contract && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
             <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Proposed laycan from"
             className="mb-3 datepick"


           >
             <DatePicker
       selected={startDate2}
       onChange={(date) => setStartDate2(date)}
       customInput={<CustomInput2/>}
       dayClassName={() => "example-datepicker-day-class"}
       popperClassName="example-datepicker-class"
       todayButton="TODAY"
       dateFormat='yyyy-MM-dd'
       placeholderText='Proposed laycan from'
     />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Proposed laycan to"
             className="mb-3 datepick"


           >
             <DatePicker
       selected={startDate3}
       onChange={(date) => setStartDate3(date)}
       customInput={<CustomInput3 />}
       dayClassName={() => "example-datepicker-day-class"}
       popperClassName="example-datepicker-class"
       todayButton="TODAY"
       dateFormat='yyyy-MM-dd'
       placeholderText='Proposed laycan to'
     />
           </FloatingLabel>
         </Form.Group></Row>
         <Row>
         <h4 className='heading'>Contractual</h4>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual GAR S"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual GAR S"
               name="contractual_GAR_S"
               {...register("contractual_GAR_S", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_GAR_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual GAR P"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual GAR P"
               name="contractual_GAR_P"
               {...register("contractual_GAR_P", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_GAR_P && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual TM AR P"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual TM AR P"
               name="contractual_TM_AR_P"
               {...register("contractual_TM_AR_P", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_TM_AR_P && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual TM AR S"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual TM AR S"
               name="contractual_TM_AR_S"
               {...register("contractual_TM_AR_S", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_TM_AR_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual Ash AR P"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual Ash AR P"
               name="contractual_Ash_AR_P"
               {...register("contractual_Ash_AR_P", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_Ash_AR_P && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual Ash AR S"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual Ash AR S"
               name="contractual_Ash_AR_S"
               {...register("contractual_Ash_AR_S", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_Ash_AR_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual Ts ADB P"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual Ts ADB P"
               name="contractual_Ts_ADB_P"
               {...register("contractual_Ts_ADB_P", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_Ts_ADB_P && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual Ts ADB S"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual Ts ADB S"
               name="contractual_Ts_ADB_S"
               {...register("contractual_Ts_ADB_S", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_Ts_ADB_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual Ash ADB P"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual Ash ADB P"
               name="contractual_Ash_ADB_P"
               {...register("contractual_Ash_ADB_P", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_Ash_ADB_P && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual Ash ADB S"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual Ash ADB S"
               name="contractual_Ash_ADB_S"
               {...register("contractual_Ash_ADB_S", {valueAsNumber:true, required: false })}
             />
             {errors.contractual_Ash_ADB_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected price basis GAR P"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected price basis GAR P"
               name="projected_price_basis_GAR_P"
               {...register("projected_price_basis_GAR_P", {valueAsNumber:true, required: false })}
             />
             {errors.projected_price_basis_GAR_P && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected price basis GAR S"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected price basis GAR S"
               name="projected_price_basis_GAR_S"
               {...register("projected_price_basis_GAR_S", {valueAsNumber:true, required: false })}
             />
             {errors.projected_price_basis_GAR_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual HBA year"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual HBA year"
               name="contractual_HBA_year"
               {...register("contractual_HBA_year", { valueAsNumber:true,required: false })}
             />
             {errors.contractual_HBA_year && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contractual HBA month"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Contractual HBA month"
               name="contractual_HBA_month"
               {...register("contractual_HBA_month", { required: false })}
             />
             {errors.contractual_HBA_month && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Discounted price"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Discounted price"
               name="discounted_price"
               {...register("discounted_price", { valueAsNumber:true,required: false })}
             />
             {errors.discounted_price && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected index value"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected index value"
               name="projected_index_value"
               {...register("projected_index_value", {valueAsNumber:true, required: false })}
             />
             {errors.projected_index_value && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Projected Purchase Type">
     <Form.Select aria-label="Floating label select example"
     name="projected_purchase_type"
     placeholder="Projected Purchase Type"
     defaultValue=""
     {...register("projected_purchase_type", { required: false })}
     >
       <option value='' >Select</option>
        {
           price.map((x)=>{
               return(
                   <option value={x.price_type_name}>{x.price_type_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.company && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected fixed purchase price"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected fixed purchase price"
               name="projected_fixed_purchase_price"
               {...register("projected_fixed_purchase_price", {valueAsNumber:true, required: false })}
             />
             {errors.projected_fixed_purchase_price && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected purchase price margin"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected purchase price margin"
               name="projected_purchase_price_margin"
               {...register("projected_purchase_price_margin", {valueAsNumber:true, required: false })}
             />
             {errors.projected_purchase_price_margin && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected barge discount"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected barge discount"
               name="projected_barge_discount"
               {...register("projected_barge_discount", {valueAsNumber:true, required: false })}
             />
             {errors.projected_barge_discount && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
       <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Projected Sale Price Type">
     <Form.Select aria-label="Floating label select example"
     name="projected_sale_price_type"
     placeholder="Projected Sale Price Type"
     defaultValue=""
     {...register("projected_sale_price_type", { required: false })}
     >
       <option value='' >Select</option>
        {
           price.map((x)=>{
               return(
                   <option value={x.price_type_name}>{x.price_type_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.projected_sale_price_type && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected fixed sales price"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected fixed sales price"
               name="projected_fixed_sales_price"
               {...register("projected_fixed_sales_price", {valueAsNumber:true, required: false })}
             />
             {errors.projected_fixed_sales_price && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected sales price margin"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected sales price margin"
               name="projected_sales_price_margin"
               {...register("projected_sales_price_margin", { valueAsNumber:true,required: false })}
             />
             {errors.projected_sales_price_margin && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Cost Sheet Projection</h4>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected freight rate"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected freight rate"
               name="projected_freight_rate"
               {...register("projected_freight_rate", {valueAsNumber:true, required: false })}
             />
             {errors.projected_freight_rate && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected freight amount"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected freight amount"
               name="projected_freight_amount"
               {...register("projected_freight_amount", {valueAsNumber:true, required: false })}
             />
             {errors.projected_freight_amount && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected insurance"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected insurance"
               name="projected_insurance"
               {...register("projected_insurance", {valueAsNumber:true, required: false })}
             />
             {errors.projected_insurance && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected commission"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected commission"
               name="projected_commission"
               {...register("projected_commission", {valueAsNumber:true, required: false })}
             />
             {errors.projected_commission && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected fixed apportioned overheads"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected fixed apportioned overheads"
               name="projected_fixed_apportioned_overheads"
               {...register("projected_fixed_apportioned_overheads", {valueAsNumber:true, required: false })}
             />
             {errors.projected_fixed_apportioned_overheads && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected any contract specific cost"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected any contract specific cost"
               name="projected_any_contract_specific_cost"
               {...register("projected_any_contract_specific_cost", { valueAsNumber:true, required: false })}
             />
             {errors.projected_any_contract_specific_cost && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected extra cost"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected extra cost"
               name="projected_extra_cost"
               {...register("projected_extra_cost", { valueAsNumber:true, required: false })}
             />
             {errors.projected_extra_cost && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected provision for corporate tax"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Projected provision for corporate tax"
               name="projected_provision_for_corporate_tax"
               {...register("projected_provision_for_corporate_tax", {valueAsNumber:true, required: false })}
             />
             {errors.projected_provision_for_corporate_tax && <span>Required</span>}
           </FloatingLabel>

         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Vessel name"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Vessel name"
               name="vessel_name"
               {...register("vessel_name", { required: false })}
             />
             {errors.vessel_name && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Stowage qty"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Stowage qty"
               name="stowage_qty"
               {...register("stowage_qty", {valueAsNumber:true, required: false })}
             />
             {errors.stowage_qty && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Destination"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Destination"
               name="destination"
               {...register("destination", { required: false })}
             />
             {errors.destination && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>

             <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual BL Date"
             className="mb-3 datepick"


           >
             <DatePicker
       selected={startDate6}
       onChange={(date) => setStartDate6(date)}
       customInput={<CustomInput6 />}
       dayClassName={() => "example-datepicker-day-class"}
       popperClassName="example-datepicker-class"
       todayButton="TODAY"
       dateFormat='yyyy-MM-dd'
       placeholderText='Actual BL Date'
     />
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual bl qty"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual bl qty"
               name="actual_bl_qty"
               {...register("actual_bl_qty", { valueAsNumber:true,required: false })}
             />
             {errors.actual_bl_qty && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual hba"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual hba"
               name="actual_hba"
               {...register("actual_hba", {valueAsNumber:true, required: false })}
             />
             {errors.actual_hba && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual hba purchase"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual hba purchase"
               name="actual_hba_purchase"
               {...register("actual_hba_purchase", {valueAsNumber:true, required: false })}
             />
             {errors.actual_hba_purchase && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Expected value date"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Expected value date"
               name="expected_value_date"
               {...register("expected_value_date", { required: false })}
             />
             {errors.expected_value_date && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Demmurage rate p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Demmurage rate p"
               name="demmurage_rate_p"
               {...register("demmurage_rate_p", {valueAsNumber:true, required: false })}
             />
             {errors.demmurage_rate_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Demmurage rate s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Demmurage rate s"
               name="demmurage_rate_s"
               {...register("demmurage_rate_s", {valueAsNumber:true, required: false })}
             />
             {errors.demmurage_rate_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Load rate"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Load rate"
               name="load_rate"
               {...register("load_rate", {valueAsNumber:true, required: false })}
             />
             {errors.load_rate && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Gcnewc"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Gcnewc"
               name="gcnewc"
               {...register("gcnewc", {valueAsNumber:true, required: false })}
             />
             {errors.gcnewc && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual gcnewc sales"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual gcnewc sales"
               name="actual_gcnewc_sales"
               {...register("actual_gcnewc_sales", {valueAsNumber:true, required: false })}
             />
             {errors.actual_gcnewc_sales && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Actual Cost Elements</h4>

         <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Purchase Price Type">
     <Form.Select aria-label="Floating label select example"
     name="purchase_price_type"
     placeholder="Purchase Price Type"
     defaultValue=""
     {...register("purchase_price_type", { required: false })}
     >
       <option value='' >Select</option>
        {
           price.map((x)=>{
               return(
                   <option value={x.price_type_name}>{x.price_type_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.purchase_price_type && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Fixed purchase price"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Fixed purchase price"
               name="fixed_purchase_price"
               {...register("fixed_purchase_price", {valueAsNumber:true, required: false })}
             />
             {errors.fixed_purchase_price && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Purchase price margin"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Purchase price margin"
               name="purchase_price_margin"
               {...register("purchase_price_margin", {valueAsNumber:true, required: false })}
             />
             {errors.purchase_price_margin && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Barge discount"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Barge discount"
               name="barge_discount"
               {...register("barge_discount", {valueAsNumber:true, required: false })}
             />
             {errors.barge_discount && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Projected Pur Amount"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Projected Pur Amount"
               name="projected_pur_amount"
               {...register("projected_pur_amount", {valueAsNumber:true, required: false })}
             />
             {errors.projected_pur_amount && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>

  <Form.Group as={Col} md="6">

               <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Sale Price Type">
     <Form.Select aria-label="Floating label select example"
     name="sale_price_type"
     placeholder="Sale Price Type"
     defaultValue=""
     {...register("sale_price_type", { required: false })}
     >
       <option value='' >Select</option>
        {
           price.map((x)=>{
               return(
                   <option value={x.price_type_name}>{x.price_type_name}</option>
               )
           })
        }
     </Form.Select>
     {errors.sale_price_type && <span>Required</span>}
   </FloatingLabel>
               </Form.Group>

          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Fixed sale price"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Fixed sale price"
               name="fixed_sale_price"
               {...register("fixed_sale_price", {valueAsNumber:true, required: false })}
             />
             {errors.fixed_sale_price && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Sale price margin"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Sale price margin"
               name="sale_price_margin"
               {...register("sale_price_margin", {valueAsNumber:true, required: false })}
             />
             {errors.sale_price_margin && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual gar p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual gar p"
               name="actual_gar_p"
               {...register("actual_gar_p", { valueAsNumber:true,required: false })}
             />
             {errors.actual_gar_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual tm p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual tm p"
               name="actual_tm_p"
               {...register("actual_tm_p", { valueAsNumber:true, required: false })}
             />
             {errors.actual_tm_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ash ar p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ash ar p"
               name="actual_ash_ar_p"
               {...register("actual_ash_ar_p", {valueAsNumber:true,  required: false })}
             />
             {errors.actual_ash_ar_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ts ar p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ts ar p"
               name="actual_ts_ar_p"
               {...register("actual_ts_ar_p", {valueAsNumber:true, required: false })}
             />
             {errors.actual_ts_ar_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ash adb p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ash adb p"
               name="actual_ash_adb_p"
               {...register("actual_ash_adb_p", {valueAsNumber:true, required: false })}
             />
             {errors.actual_ash_adb_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ts adb p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ts adb p"
               name="actual_ts_adb_p"
               {...register("actual_ts_adb_p", {valueAsNumber:true, required: false })}
             />
             {errors.actual_ts_adb_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual aft p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual aft p"
               name="actual_aft_p"
               {...register("actual_aft_p", { valueAsNumber:true,required: false })}
             />
             {errors.actual_aft_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual vm p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual vm p"
               name="actual_vm_p"
               {...register("actual_vm_p", {valueAsNumber:true, required: false })}
             />
             {errors.actual_vm_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual hgi p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual hgi p"
               name="actual_hgi_p"
               {...register("actual_hgi_p", {valueAsNumber:true, required: false })}
             />
             {errors.actual_hgi_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Price basis gar p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Price basis gar p"
               name="price_basis_gar_p"
               {...register("price_basis_gar_p", { valueAsNumber:true, required: false })}
             />
             {errors.price_basis_gar_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Gar p"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Gar p"
               name="gar_p"
               {...register("gar_p", { valueAsNumber:true, required: false })}
             />
             {errors.gar_p && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual gar s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual gar s"
               name="actual_gar_s"
               {...register("actual_gar_s", { valueAsNumber:true, required: false })}
             />
             {errors.actual_gar_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual tm s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual tm s"
               name="actual_tm_s"
               {...register("actual_tm_s", { valueAsNumber:true, required: false })}
             />
             {errors.actual_tm_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ash ar s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ash ar s"
               name="actual_ash_ar_s"
               {...register("actual_ash_ar_s", {valueAsNumber:true, required: false })}
             />
             {errors.actual_ash_ar_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ts ar s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ts ar s"
               name="actual_ts_ar_s"
               {...register("actual_ts_ar_s", {valueAsNumber:true, required: false })}
             />
             {errors.actual_ts_ar_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ash adb s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ash adb s"
               name="actual_ash_adb_s"
               {...register("actual_ash_adb_s", {valueAsNumber:true, required: false })}
             />
             {errors.actual_ash_adb_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual ts adb s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual ts adb s"
               name="actual_ts_adb_s"
               {...register("actual_ts_adb_s", { valueAsNumber:true,required: false })}
             />
             {errors.actual_ts_adb_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual aft s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual aft s"
               name="actual_aft_s"
               {...register("actual_aft_s", { valueAsNumber:true,required: false })}
             />
             {errors.actual_aft_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual vm s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual vm s"
               name="actual_vm_s"
               {...register("actual_vm_s", { valueAsNumber:true,required: false })}
             />
             {errors.actual_vm_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual hgi s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual hgi s"
               name="actual_hgi_s"
               {...register("actual_hgi_s", {valueAsNumber:true, required: false })}
             />
             {errors.actual_hgi_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Price basis gar s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Price basis gar s"
               name="price_basis_gar_s"
               {...register("price_basis_gar_s", {valueAsNumber:true, required: false })}
             />
             {errors.price_basis_gar_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Gar s"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Gar s"
               name="gar_s"
               {...register("gar_s", {valueAsNumber:true, required: false })}
             />
             {errors.gar_s && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Bonus / Penalty</h4>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Sulfur bonus rate MT S"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Sulfur bonus rate MT S"
               name="sulfur_bonus_rate_MT_S"
               {...register("sulfur_bonus_rate_MT_S", {valueAsNumber:true, required: false })}
             />
             {errors.sulfur_bonus_rate_MT_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Sulfur penalty rate MT S"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Sulfur penalty rate MT S"
               name="sulfur_penalty_rate_MT_S"
               {...register("sulfur_penalty_rate_MT_S", {valueAsNumber:true, required: false })}
             />
             {errors.sulfur_penalty_rate_MT_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Ash bonus rate MT S"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Ash bonus rate MT S"
               name="ash_bonus_rate_MT_S"
               {...register("ash_bonus_rate_MT_S", {valueAsNumber:true, required: false })}
             />
             {errors.ash_bonus_rate_MT_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Ash penalty rate MT S"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Ash penalty rate MT S"
               name="ash_penalty_rate_MT_S"
               {...register("ash_penalty_rate_MT_S", {valueAsNumber:true, required: false })}
             />
             {errors.ash_penalty_rate_MT_S && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Cost Sheet Actual</h4>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual pur amount"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual pur amount"
               name="actual_pur_amount"
               {...register("actual_pur_amount", {valueAsNumber:true, required: false })}
             />
             {errors.actual_pur_amount && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual freight rate"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual freight rate"
               name="actual_freight_rate"
               {...register("actual_freight_rate", {valueAsNumber:true, required: false })}
             />
             {errors.actual_freight_rate && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual freight amount"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual freight amount"
               name="actual_freight_amount"
               {...register("actual_freight_amount", { valueAsNumber:true,required: false })}
             />
             {errors.actual_freight_amount && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual insurance"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual insurance"
               name="actual_insurance"
               {...register("actual_insurance", {valueAsNumber:true, required: false })}
             />
             {errors.actual_insurance && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>


          <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual commission"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual commission"
               name="actual_commission"
               {...register("actual_commission", {valueAsNumber:true, required: false })}
             />
             {errors.actual_commission && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Shipment Cost</h4>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual fixed apportioned overheads"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual fixed apportioned overheads"
               name="actual_fixed_apportioned_overheads"
               {...register("actual_fixed_apportioned_overheads", {valueAsNumber:true, required: false })}
             />
             {errors.actual_fixed_apportioned_overheads && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>  <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual contract specific cost"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual contract specific cost"
               name="actual_contract_specific_cost"
               {...register("actual_contract_specific_cost", { valueAsNumber:true,required: false })}
             />
             {errors.actual_contract_specific_cost && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>  <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual prov for corporate tax"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual prov for_corporate tax"
               name="actual_prov_for_corporate_tax"
               {...register("actual_prov_for_corporate_tax", {valueAsNumber:true, required: false })}
             />
             {errors.actual_prov_for_corporate_tax && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>  <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Actual withholding tax"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Actual withholding tax"
               name="actual_withholding_tax"
               {...register("actual_withholding_tax", {valueAsNumber:true, required: false })}
             />
             {errors.actual_withholding_tax && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>  <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Final total cost"
             className="mb-3"


           >
             <Form.Control
               type="number"
               placeholder="Final total cost"
               name="final_total_cost"
               {...register("final_total_cost", {valueAsNumber:true, required: false })}
             />
             {errors.final_total_cost && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         </Row>
         <Row>
         <h4 className='heading'>Rwood Interenal Information</h4>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Any deviation"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Any deviation"
               name="any_deviation"
               {...register("any_deviation", { required: false })}
             />
             {errors.any_deviation && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>  <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Prepared by"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Prepared by"
               name="prepared_by"
               {...register("prepared_by", { required: false })}
             />
             {errors.prepared_by && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>  <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Check and approved by"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Check and approved by"
               name="check_and_approved_by"
               {...register("check_and_approved_by", { required: false })}
             />
             {errors.check_and_approved_by && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>  <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Validated by"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Validated by"
               name="validated_by"
               {...register("validated_by", { required: false })}
             />
             {errors.validated_by && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Remarks"
             className="mb-3"


           >
             <Form.Control
               type="text"
               placeholder="Remarks"
               name="remarks"
               {...register("remarks", { required: false })}
             />
             {errors.remarks && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>



       </Row>
       <input type="submit" className='price-submit' />
       <div className='priceDiv'><p></p><p></p></div>
     </Form>
     </div>
     <Footer/>
     </div>

   );
 }}

export default Addcontracttcf;
