import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { API_URL } from '../config';
import Footer from '../Footer'
import Header from '../Header'
import { AiFillDiff } from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const PreShipmentList = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  $(".contactAccount").css({"min-height":(window_height)+"px"})


  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/preshipment', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data.preshipments;
      // console.log(data)
      setDatas(data);
      $(document).ready(function(){
          $('#tables').DataTable();

       })
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <Header/>
    <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Pre Shipment List </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Pre Shipment List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
{/*
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to='/preshipment/add-preshipment'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Preshipment</Link>
        </button> */}

      </div>
    </div>
  </div>
  <div class='tasks'>
  <table id='tables'>
    <thead>
    <tr>
    <th>Pre Code No</th>
    <th>Master LC No</th>
      <th>Rwood Bank</th>
      <th>Master LC Applicant</th>
      <th>Applicant Full Name</th>
      <th>Applicant Address</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
    <td><Link to={"/shipment/preshipment-details/"+x.id }>{x.pre_code_no}</Link></td>
      <td>{x.master_lc_no}</td>
      <td>{x.rwood_bank}</td>
      <td>{x.master_lc_applicant}</td>
      <td>{x.applicant_full_name}</td>
      <td>{x.applicant_address}</td>
      <td><Link to={"/contact/edit-contact/"+x.id }><FaPencilAlt/></Link> <FaTrashAlt/><Link to={"/preshipment/preshipment-detail/"+x.id }><AiFillDiff/></Link></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>
    </div> </div>
    <Footer/>
    </div>
  );
};

export default PreShipmentList;