import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useLocation , Link} from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Header from "../Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer";
import $ from "jquery";
import { API_URL } from '../config';
import axios from "axios";
import encUtf8 from 'crypto-js/enc-utf8';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
function AddCotract() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { account_record } = location.state;
  // date
  const [startDate, setStartDate] = useState(null);
  const startDates = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const [contractStartdate, setContractStartdate] = useState(new Date());
  const [check,setCheck1]=useState(false)
  const [priceBook, setPriceBook]=useState([]);
  useEffect(() => {

    fetchData2();
  }, [decryptedAccessToken]);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/pricebook`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.pricebook;
      console.log(data)
      setPriceBook(data);

    } catch (error) {
      console.error(error);
    }
  };

  // const renewalDate =
  //   startDate.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   startDate.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   startDate.toLocaleDateString("en-US", { day: "2-digit" });
  // const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name="renewal_date"
  //     ref={ref}
  //   ></input>
  // ));
  // const [effective, setEffective] = useState(new Date());
  // const contractEffective =
  // effective.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   effective.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   effective.toLocaleDateString("en-US", { day: "2-digit" });
  // const ExampleCustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name="contract_effective_date"
  //     ref={ref}
  //   ></input>
  // ));
  // const [start, setStart] = useState(new Date());
  // const startDates =
  // start.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   start.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   start.toLocaleDateString("en-US", { day: "2-digit" });
  // const ExampleCustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name="contract_start_date"
  //     ref={ref}
  //   ></input>
  // ));

  // const [signed, setSigned] = useState(new Date());
  // const startDates1 =
  // signed.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   signed.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   signed.toLocaleDateString("en-US", { day: "2-digit" });
  // const ExampleCustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name=""
  //     ref={ref}
  //   ></input>
  // ));

  // const [received, setReceived] = useState(new Date());
  // const startDates2 =
  // received.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   received.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   received.toLocaleDateString("en-US", { day: "2-digit" });
  // const ExampleCustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name=""
  //     ref={ref}
  //   ></input>
  // ));

  // const [shipmentDate, setshipmentDate] = useState(new Date());
  // const startShipment =
  // received.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   received.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   received.toLocaleDateString("en-US", { day: "2-digit" });
  // const ShipmentData = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name=""
  //     ref={ref}
  //   ></input>
  // ));




  // const [customer, setCustomer] = useState(new Date());
  // const startDates3 =
  // customer.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   customer.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   customer.toLocaleDateString("en-US", { day: "2-digit" });
  // const ExampleCustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name=""
  //     ref={ref}
  //   ></input>
  // ));

  // const [sent, setSent] = useState(new Date());
  // const startDates4 =
  // sent.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   sent.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   sent.toLocaleDateString("en-US", { day: "2-digit" });
  // const ExampleCustomInput7 = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input"
  //     onClick={onClick}
  //     onChange={onChange}
  //     name=""
  //     ref={ref}
  //   ></input>
  // ));
  const [startDate1, setStartDate1] = useState(null);
  const startDates1 = startDate1 ? `${startDate1.getDate().toString().padStart(2, '0')}/${(startDate1.getMonth() + 1).toString().padStart(2, '0')}/${startDate1.getFullYear()}` : null;
  const handleStartDateChange1 = (date) => {
    setStartDate1(date);
  };
  const [startDate2, setStartDate2] = useState(null);
  const startDates2 = startDate2 ? `${startDate2.getDate().toString().padStart(2, '0')}/${(startDate2.getMonth() + 1).toString().padStart(2, '0')}/${startDate2.getFullYear()}` : null;
  const handleStartDateChange2 = (date) => {
    setStartDate2(date);
  };

  const [startDate3, setStartDate3] = useState(null);
  const startDates3 = startDate3 ? `${startDate3.getDate().toString().padStart(2, '0')}/${(startDate3.getMonth() + 1).toString().padStart(2, '0')}/${startDate3.getFullYear()}` : null;
  const handleStartDateChange3 = (date) => {
    setStartDate3(date);
  };

  const [startDate4, setStartDate4] = useState(null);
  const startDates4 = startDate4 ? `${startDate4.getDate().toString().padStart(2, '0')}/${(startDate4.getMonth() + 1).toString().padStart(2, '0')}/${startDate4.getFullYear()}` : null;
  const handleStartDateChange4 = (date) => {
    setStartDate4(date);
  };

  const [startDate5, setStartDate5] = useState(null);
  const startDates5 = startDate5 ? `${startDate5.getDate().toString().padStart(2, '0')}/${(startDate5.getMonth() + 1).toString().padStart(2, '0')}/${startDate5.getFullYear()}` : null;
  const handleStartDateChange5 = (date) => {
    setStartDate5(date);
  };
const [startDate6, setStartDate6] = useState(null);
  const startDates6 = startDate6 ? `${startDate6.getDate().toString().padStart(2, '0')}/${(startDate6.getMonth() + 1).toString().padStart(2, '0')}/${startDate6.getFullYear()}` : null;
  const handleStartDateChange6 = (date) => {
    setStartDate6(date);
  };

  const [startDate7, setStartDate7] = useState(null);
  const startDates7 = startDate7 ? `${startDate7.getDate().toString().padStart(2, '0')}/${(startDate7.getMonth() + 1).toString().padStart(2, '0')}/${startDate7.getFullYear()}` : null;
  const handleStartDateChange7 = (date) => {
    setStartDate7(date);
  };
  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(total_height);
  console.log(body);
  const [getPrice, setgetPrice] = useState([]);
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/price_items`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data)
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataContacts();
  }, [decryptedAccessToken]);

  const fetchDataContacts = async () => {
    try {
      const response = await axios.get(
        `${API_URL}allcontacts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contact;
      // console.log(data)
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };

  $(".contactAccount fulid-section").css({ "min-height": window_height + "px" });
  const [accountData, setAccountData] = useState([]);
  const [companyData, setcompanyData] = useState([]);
  const [traderData, setTraderData] = useState([]);
  const [supplierData, setsupplierData] = useState([]);
  const [surveyors, setSurveyor] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchDatausers();
  }, [decryptedAccessToken]);

  const fetchDatausers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/users`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data);
      setUsers(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/supplier`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(data)
      setsupplierData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataTrader();
  }, [decryptedAccessToken]);

  const fetchDataTrader = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodusers`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodusers;
      console.log(data)
      setTraderData(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    fetchDataaccount();
  }, [decryptedAccessToken]);

  const fetchDataaccount = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      console.log(data);
      setDatas(data);
      $(document).ready(function () {
        setTimeout(() => {
          $("#tables").DataTable();
        }, 5);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataCompany();
  }, [decryptedAccessToken]);

  const fetchDataCompany = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodcompany`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      // console.log(data)
      setcompanyData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      console.log(data)
      setAccountData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData6();
  }, [decryptedAccessToken]);

  const fetchData6 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/surveyor`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(JSON.stringify(data))
      setSurveyor(data);
    } catch (error) {
      console.error(error);
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  if (account_record === "Buyer - Long Term") {

const onSubmit = async (data) => {
  const responseData = {
    locked: true,
    company: data.company,
    currency: data.currency,
    trader_name: data.trader_name,
    exchange_rate: data.exchange_rate,
    contract_record_type: data.contract_record_type,
    price_book: data.price_book,
    contract_owner: data.contract_owner,
    rwood_contract_no: data.rwood_contract_no,
    status: data.status,
    contract_effective_date: startDates1,
    account_name: data.account_name,
    contract_status: data.contract_status,
    pending_matters: data.pending_matters,
    contract_term_in_months: data.contract_term_in_months,
    remark: data.remark,
    billing_address: data.billing_address,
    shipping_address: data.shipping_address,
    supplier: data.supplier,
    master_conference_reference: data.master_conference_reference,
    contract_start_date: startDates,
    renewal_date: startDates2,
    renewal_contract_number: data.renewal_contract_number,
    origin: data.origin,
    commodity: data.commodity,
    interlab_tolerance: data.interlab_tolerance,
    contract_quantity_in_MT: data.contract_quantity_in_MT,
    destination: data.destination,
    pricing_terms: data.pricing_terms,
    price_adjust_clause: data.price_adjust_clause,
    bonus_penalty_clause: data.bonus_penalty_clause,
    payment_terms: data.payment_terms,
    general_terms: data.general_terms,
    hs_code: data.hs_code,
    rejections: data.rejections,
    latest_date_of_shipment: data.latest_date_of_shipment,
    loading_rate_per_day: data.loading_rate_per_day,
    lc_type: data.lc_type,
    master_lc_presentation_days: data.master_lc_presentation_days,
    payment_details: data.payment_details,
    master_lc_tolerance: data.master_lc_tolerance,
    lc_validity_no_of_days: data.lc_validity_no_of_days,
    lc_opening_days: data.lc_opening_days,
    lc_opening_days_in_words: data.lc_opening_days_in_words,
    list_of_documents_required: data.list_of_documents_required,
    kind_attention: data.kind_attention,
    customer_signed_by: data.customer_signed_by,
    customer_signed_title: data.customer_signed_title,
    company_signed_by: data.company_signed_by,
    company_signed_date: startDates3,
    original_contract_recieved_date: startDate5,
    original_contract_sent_date: startDates6,
    applicable_law: data.applicable_law,
    customer_signed_date: startDates7,
    arbitration: data.arbitration,
    rwood_surveyor_agent: data.rwood_surveyor_agent,
    surveyor_2: data.surveyor_2,
    surveyor_3: data.surveyor_3,
    description: data.description,
    lc_presentation_period: data.lc_presentation_period,
    product_description: data.product_description,
  };

  console.log(JSON.stringify(responseData));

  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

  if (encryptedAccessToken) {
    try {
      // Decrypt the access token
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(encUtf8);

      console.log('Decrypted Access Token:', decryptedAccessToken);

      // Make the Axios POST request
      const response = await axios.post(
        `${API_URL}add/contract`,
        responseData,
        {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Axios Response:', response);

      if (response.data.success) {
        setMessage({
          type: 'success',
          text: response.data.msg,
        });

        // Scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        // Navigate to the next page
        navigate(`/contract/contract-details/${response.data.id}`);
      } else {
        setMessage({
          type: 'error',
          text: response.data.msg,
        });

        // Scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios Error:', error.message);
      } else {
        console.error('Unexpected Error:', error);
      }

      setMessage({
        type: 'error',
        text: 'An error occurred while submitting the form.',
      });

      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  } else {
    console.error('No access token found.');
    setMessage({
      type: 'error',
      text: 'Access token not found. Please log in again.',
    });

    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};


    return (
      <>
        <Header />
        <div className="contactAccount fulid-section contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Buyer Long Term Contract{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Buyer Long Term
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
           <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    className="error-validation"
                    {...register("company", { required: true })}
                  >
                    <option value="">Select </option>
                    {companyData.length>0?(
        <>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                    </>
                    ):(

                      <option className='no-data'>No data  available</option>
                      )}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    className="error-validation"
                    {...register("currency", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"  className='error-validation'

                    {...register("trader_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {traderData.length>0?(
        <>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                     </>
        ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account_record}
                    name="contract_record_type" className='record-type'
                    {...register("contract_record_type", { required: true })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    {...register("contract_owner", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              {/* <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"

    {...register("price_book", { required: false })}
    >
      <option value='' >Select</option>
      {priceBook.length>0?(
        <>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.price_book && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>  */}

<Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="rwood_contract_no"
                    {...register("rwood_contract_no", { required: false })}
                  />
                  {errors.rwood_contract_no && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    className="error-validation"
                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft" selected>Draft</option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    className="error-validation"
                    {...register("account_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.length>0?(
        <>
       {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}


                  </Form.Select>
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Supplier"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="supplier"
                    placeholder="Supplier"
                    {...register("supplier", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.length>0?(
        <>
       {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.supplier && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="Contract Status"

                    {...register("contract_status", { required: false })}
                  >
                   <option value="In Approval Process">In Approval Process</option><option value="Activated">Activated</option><option value="Draft">Draft</option><option value="Under Review" selected>Under Review</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option><option value="Closed">Closed</option><option value="Partially completed">Partially completed</option><option value="Completed">Completed</option><option value="Cancelled">Cancelled</option><option value="Partially cancelled">Partially cancelled</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Partially Signed Contract">Partially Signed Contract</option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    {...register("contract_term_in_months", {
                      valueAsNumber:true,
                      required: false,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate}
        onChange={handleStartDateChange}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Effective Date"
                  className="mb-3 date-picker"
                >
                      <DatePicker
              selected={startDate1}
        onChange={handleStartDateChange1}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master conference reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master conference reference"
                    name="master_conference_reference"
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate2}
        onChange={handleStartDateChange2}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Commodity">
  <Form.Select aria-label="Floating label select example"
  name="commodity"
  placeholder="Commodity"

  {...register("commodity", { required: false })}
  >
    <option>---None---</option>
    <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
  </Form.Select>
  {errors.commodity && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Interlab tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Interlab tolerance"
                    name="interlab_tolerance"
                    className="textarea"
                    style={{ height: "100px!important" }}
                    {...register("interlab_tolerance", { required: false })}
                  />
                  {errors.interlab_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    className="error-validation"
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Market Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Destination"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Destination"
                    name="destination"
                    {...register("destination", { required: false })}
                  />
                  {errors.destination && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Pricing terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing terms"
                    name="pricing_terms"
                    style={{ height: "100px!important" }}
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price  Adjustment Clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Price  Adjustment Clause"
                    name="price_adjustment_clause"
                    style={{ height: "100px!important" }}
                    className="error-validation"
                    {...register("price_adjustment_clause", { required: true})}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    style={{ height: "100px!important" }}
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Payment terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    name="payment_terms"
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Hs code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Hs code"
                    name="hs_code"
                    {...register("hs_code", { required: false })}
                  />
                  {errors.hs_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Payment Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="LC Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="lc_type"
                    placeholder="LC Type"

                    {...register("lc_type", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
                  </Form.Select>
                  {errors.lc_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc presentation days"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master lc presentation days"
                    name="master_lc_presentation_days"
                    {...register("master_lc_presentation_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_presentation_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Payment Details"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="payment_details"
                    placeholder="Payment Details"

                    {...register("payment_details", { required: false })}
                  >
                    <option value="">--None--</option><option value="AT SIGHT">AT SIGHT</option><option value="Usance">Usance</option><option value="Telegraphic Transfer">Telegraphic Transfer</option>
                  </Form.Select>
                  {errors.payment_details && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    {...register("lc_opening_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Master lc tolerance"
                    name="master_lc_tolerance"
                    {...register("master_lc_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Lc opening days in words"
                    name="lc_opening_days_in_words"
                    {...register("lc_opening_days_in_words", {
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc validity no of days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc validity no of days"
                    name="lc_validity_no_of_days"
                    {...register("lc_validity_no_of_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_validity_no_of_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document presentation period"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document presentation period"
                    name="document_presentation_period"
                    {...register("document_presentation_period", {
                      required: false,
                    })}
                  />
                  {errors.document_presentation_period && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>

{/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="latest date of shipment"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate4}
        onChange={handleStartDateChange4}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="latest date of shipment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="latest date of shipment"
                    name="latest_date_of_shipment"
                    {...register("latest_date_of_shipment", {
                      valueAsNumber: false,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Important Terms Information</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="List of documents required"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    style={{ height: "200px!important" }}
                    placeholder="List of documents required"
                    name="list_of_documents_required"
                    {...register("list_of_documents_required", {
                      required: false,
                    })}
                  />
                  {errors.list_of_documents_required && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Signature Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Kind attention"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="kind_attention"
                    placeholder="Kind attention"

                    {...register("kind_attention", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.length>0?(
        <>
      {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.kind_attention && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"

                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.length>0?(
        <>
      {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company Signed Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate3}
        onChange={handleStartDateChange3}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"

                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {users.length>0?(
        <>
      {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original Contract Recieved Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate5}
        onChange={handleStartDateChange5}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >

    <DatePicker
              selected={startDate6}
        onChange={handleStartDateChange6}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Terms</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Applicable Law"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="applicable_law"
                    placeholder="Applicable Law"

                    {...register("applicable_law", { required: false })}
                  >
                   <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                  </Form.Select>
                  {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rwood surveryor agent"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rwood_surveryor_agent"
                    placeholder="Rwood surveryor agent"
                    {...register("rwood_surveryor_agent", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.length>0?(
        <>
       {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.rwood_surveryor_agent && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Arbitration"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Arbitration"
                    name="arbitration"
                    {...register("arbitration", { required: false })}
                  />
                  {errors.arbitration && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 1"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_1"
                    placeholder="Surveyor 1"

                    {...register("surveyor_1", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.length>0?(
        <>
       {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.surveyor_1 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 2"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_2"
                    placeholder="Surveyor 2"

                    {...register("surveyor_2", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.length>0?(
        <>
       {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.surveyor_2 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">List Of Fields-Not Required</h4>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="LC Presentation Period"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="LC Presentation Period"
                    style={{ height: "100px" }}
                    name="lc_presentation_period"
                    {...register("lc_presentation_period", { required: false })}
                  />
                  {errors.lc_presentation_period && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Product Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Product Description"
                    style={{ height: "100px" }}
                    name="product_description"
                    {...register("product_description", { required: false })}
                  />
                  {errors.product_description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  }
  else if (account_record === "Buyer - Spot") {
    const onSubmit = async (data) => {
      const responseData = {
        locked: true,
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        rwood_contract_no: data.rwood_contract_no,
        account_name: data.account_name,
        original_contract_recieved_date: startDate5,
        contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        remark: data.remark,
        billing_address: data.billing_address,
        shipping_address: data.shipping_address,
        master_conference_reference: data.master_conference_reference,
        renewal_date: startDates2,
        renewal_contract_number: data.renewal_contract_number,
        company: data.company,
        trader_name: data.trader_name,
        contract_owner: data.contract_owner,
        price_book: data.price_book,
        supplier: data.supplier,
        status: data.status,
        contract_term_in_months: data.contract_term_in_months,
        contract_start_date: startDates,
        owner_expiration_notice: data.owner_expiration_notice,
        origin: data.origin,
        interlab_tolerance: data.interlab_tolerance,
        commodity: data.commodity,
        latest_date_of_shipment: data.latest_date_of_shipment,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        port_of_loading: data.port_of_loading,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        price_adjust_clause: data.price_adjust_clause,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        shipping_city: data.shipping_city,
        hs_code: data.hs_code,
        applicable_law: data.applicable_law,
        arbitration: data.arbitration,
        contract_effective_date: startDates1,
        lc_type: data.lc_type,
        company_signed_date: startDates3,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        loading_rate_per_day: data.loading_rate_per_day,
        master_lc_presentation_days: data.master_lc_presentation_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          data.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: data.document_presentation_period,
        list_of_documents_required: data.list_of_documents_required,
        special_terms: data.special_terms,
        price_PMT: data.price_PMT,
        price_PMT_in_words: data.price_PMT_in_words,
        price_basis: data.price_basis,
        price_type: data.price_type,
        price_basis_GAR: data.price_basis_GAR,
        price_adjustment_clause: data.price_adjustment_clause,
        description: data.description,
        rejections: data.rejections,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        original_contract_sent_date: startDates6,
        company_signed_by: data.company_signed_by,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
        billing_street: data.billing_street,
        billing_zip_postal_code: data.billing_zip_postal_code,
        billing_city: data.billing_city,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
      };

      console.log(JSON.stringify(responseData));

      const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

      if (encryptedAccessToken) {
        try {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(encUtf8);

          console.log('Decrypted Access Token:', decryptedAccessToken);

          const response = await axios.post(`${API_URL}add/contract`, responseData, {
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.data.success) {
            setMessage({ type: 'success', text: response.data.msg });

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });

            // Navigate to the next page
            navigate(`/contract/contract-details/${response.data.id}`);
          } else {
            setMessage({ type: 'error', text: response.data.msg });

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error('Axios Error:', error.message);
          } else {
            console.error('Unexpected Error:', error);
          }

          setMessage({
            type: 'error',
            text: 'An error occurred while submitting the form.',
          });

          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      } else {
        console.error('No access token found.');
        setMessage({
          type: 'error',
          text: 'Access token not found. Please log in again.',
        });

        // Scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount fulid-section contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Contract Buyer Spot{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Contract Buyer Spot
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    {...register("contract_owner", { required: false })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    className="error-validation"
                    placeholder="Currency"

                    {...register("currency", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="IDR">IDR </option>
                    <option value="USD">USD </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"  className='error-validation'

                    {...register("trader_name", { required: false })}
                  >
                    <option value="">Select </option>
                    {traderData.length>0?(
        <>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                     </>
        ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Supplier"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="supplier"
                    placeholder="Supplier"

                    className="error-validation"
                    {...register("supplier", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.length>0?(
        <>
       {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.supplier && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    className="error-validation"
                    {...register("company", { required: true })}
                  >
                    <option value="">Select </option>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"

    {...register("price_book", { required: false })}
    >
      <option value='' >Select</option>
      {priceBook.length>0?(
        <>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.price_book && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account_record}
                    name="contract_record_type" className='record-type'
                    {...register("contract_record_type", { required: false })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>


              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    className="error-validation"
                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft" selected>Draft</option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
<Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="rwood_contract_no"
                    {...register("rwood_contract_no", { required: false })}
                  />
                  {errors.rwood_contract_no && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    className="error-validation"
                    name="contract_term_in_months"
                    {...register("contract_term_in_months", {
                      valueAsNumber:true,
                      required: true,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    className="error-validation"
                    {...register("account_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.length>0?(
        <>
       {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    className="error-validation"
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="contract status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="contract status"
                    className="error-validation"
                    {...register("contract_status", { required: true })}
                  >
                   <option value="In Approval Process">In Approval Process</option><option value="Activated">Activated</option><option value="Draft">Draft</option><option value="Under Review" selected>Under Review</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option><option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate}
        onChange={handleStartDateChange}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master conference reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master conference reference"
                    name="master_conference_reference"
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate2}
        onChange={handleStartDateChange2}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Commodity">
  <Form.Select aria-label="Floating label select example"
  name="commodity"
  placeholder="Commodity"

  {...register("commodity", { required: false })}
  >
    <option>---None---</option>
    <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
  </Form.Select>
  {errors.commodity && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Interlab tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Interlab tolerance"
                    name="interlab_tolerance"
                    style={{ height: "100px!important" }}
                    {...register("interlab_tolerance", { required: false })}
                  />
                  {errors.interlab_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Market Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port Of Loading"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port Of Loading"
                    name="port_of_loading"
                    {...register("port_of_loading", { required: false })}
                  />
                  {errors.port_of_loading && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Destination"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Destination"
                    name="destination"
                    {...register("destination", { required: false })}
                  />
                  {errors.destination && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="price adjustment clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="price adjustment clause"
                    name="price_adjustment_clause"
                    {...register("price_adjustment_clause", { required: false })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="bonus  penalty clause"
                    name="bonus_penalty_clause"
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Hs code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Hs code"
                    name="hs_code"
                    {...register("hs_code", { required: false })}
                  />
                  {errors.hs_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pricing Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing Terms"
                    style={{ height: "100px!important" }}
                    name="pricing_terms"
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Payment Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="LC Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="lc_type"
                    placeholder="LC Type"

                    {...register("lc_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
                  </Form.Select>
                  {errors.lc_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc presentation days"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master lc presentation days"
                    name="master_lc_presentation_days"
                    {...register("master_lc_presentation_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_presentation_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Payment Details"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="payment_details"
                    placeholder="Payment Details"

                    {...register("lc_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
                  </Form.Select>
                  {errors.payment_details && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    {...register("lc_opening_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Master lc tolerance"
                    name="master_lc_tolerance"
                    {...register("master_lc_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Lc opening days in words"
                    name="lc_opening_days_in_words"
                    {...register("lc_opening_days_in_words", {
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc validity no of days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc validity no of days"
                    name="lc_validity_no_of_days"
                    {...register("lc_validity_no_of_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_validity_no_of_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document presentation period"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document presentation period"
                    name="document_presentation_period"
                    {...register("document_presentation_period", {
                      required: false,
                    })}
                  />
                  {errors.document_presentation_period && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="work days for time to copy doc to buyer"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="work days for time to copy doc to buyer"
                    name="work_days_for_time_to_copy_doc_to_buyer"
                    {...register("work_days_for_time_to_copy_doc_to_buyer", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.work_days_for_time_to_copy_doc_to_buyer && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
             <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="latest date of shipment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="latest date of shipment"
                    name="latest_date_of_shipment"
                    {...register("latest_date_of_shipment", {
                      valueAsNumber: false,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

             </Row>
            <Row className="mb-3">
              <h4 className="heading">Important Terms Information</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="List of documents required"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    style={{ height: "200px!important" }}
                    placeholder="List of documents required"
                    name="list_of_documents_required"
                    {...register("list_of_documents_required", {
                      required: false,
                    })}
                  />
                  {errors.list_of_documents_required && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Signature Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Kind attention"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="kind_attention"
                    placeholder="Kind attention"

                    {...register("kind_attention", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.length>0?(
        <>
      {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.kind_attention && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"

                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.length>0?(
        <>
      {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer Signed Date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate7}
        onChange={handleStartDateChange7}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"

                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {users.length>0?(
        <>
      {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original Contract Recieved Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate5}
        onChange={handleStartDateChange5}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company Signed Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate3}
        onChange={handleStartDateChange3}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >

    <DatePicker
              selected={startDate6}
        onChange={handleStartDateChange6}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={sent}
                    onChange={(date) => setSent(date)}
                    customInput={<ExampleCustomInput7 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group> */}
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Terms</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Applicable Law"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="applicable_law"
                    placeholder="Applicable Law"

                    {...register("applicable_law", { required: false })}
                  >
                   <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                  </Form.Select>
                  {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rwood surveryor agent"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rwood_surveryor_agent"
                    placeholder="Rwood surveryor agent"
                    {...register("rwood_surveryor_agent", { required: false })}
                  >
                    <option value="">Select </option>


{surveyors.length>0?(
        <>
       {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.rwood_surveryor_agent && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Arbitration"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="arbitration"
                    placeholder="Arbitration"

                    {...register("arbitration", { required: false })}
                  >
                   <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                  </Form.Select>
                  {errors.arbitration && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 1"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_1"
                    placeholder="Surveyor 1"

                    {...register("surveyor_1", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.length>0?(
        <>
       {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.surveyor_1 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 2"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_2"
                    placeholder="Surveyor 2"

                    {...register("surveyor_2", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.length>0?(
        <>
       {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.surveyor_2 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">List Of Fields-Not Required</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Product description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Product description"
                    style={{ height: "100px" }}
                    name="product_description"
                    {...register("product_description", { required: false })}
                  />
                  {errors.product_description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  }
  else if (account_record === "Supplier - Long Term") {
    const onSubmit = async (data) => {
      const responseData = {
        locked: data.locked,
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        rwood_contract_no: data.rwood_contract_no,
        account_name: data.account_name,
        contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        remark: data.remark,
        billing_address: data.billing_address,
        shipping_address: data.shipping_address,
        master_conference_reference: data.master_conference_reference,
        renewal_date: startDates2,
        renewal_contract_number: data.renewal_contract_number,
        company: data.company,
        trader_name: data.trader_name,
        contract_owner: data.contract_owner,
        price_book: data.price_book,
        supplier: data.supplier,
        status: data.status,
        contract_term_in_months: data.contract_term_in_months,
        contract_start_date: startDates,
        owner_expiration_notice: data.owner_expiration_notice,
        origin: data.origin,
        interlab_tolerance: data.interlab_tolerance,
        commodity: data.commodity,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        port_of_loading: data.port_of_loading,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        price_adjust_clause: data.price_adjust_clause,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        shipping_city: data.shipping_city,
        hs_code: data.hs_code,
        applicable_law: data.applicable_law,
        arbitration: data.arbitration,
        lc_type: data.lc_type,
        company_signed_date: startDates3,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        loading_rate_per_day: data.loading_rate_per_day,
        master_lc_presentation_days: data.master_lc_presentation_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          data.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: data.document_presentation_period,
        list_of_documents_required: data.list_of_documents_required,
        special_terms: data.special_terms,
        price_PMT: data.price_PMT,
        price_PMT_in_words: data.price_PMT_in_words,
        price_basis: data.price_basis,
        price_type: data.price_type,
        price_basis_GAR: data.price_basis_GAR,
        price_adjustment_clause: data.price_adjustment_clause,
        description: data.description,
        rejections: data.rejections,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        company_signed_by: data.company_signed_by,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
        original_contract_recieved_date: startDate5,
        billing_street: data.billing_street,
        contract_effective_date: startDates1,
        billing_zip_postal_code: data.billing_zip_postal_code,
        billing_city: data.billing_city,
        original_contract_sent_date: startDates6,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        customer_signed_date: startDates7,
      };

      console.log(JSON.stringify(responseData)); // Log the response data for debugging

      const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

      if (encryptedAccessToken) {
        try {
          // Decrypt the access token using AES
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);

          console.log('Decrypted Access Token:', decryptedAccessToken); // Log the decrypted token

          // Make the POST request with Axios
          const response = await axios.post(`${API_URL}add/contract`, responseData, {
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json',
            },
          });

          // Handle success response
          if (response.data.success) {
            setMessage({ type: 'success', text: response.data.msg });

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });

            // Navigate to the contract details page
            navigate(`/contract/contract-details/${response.data.id}`);
          } else {
            // Handle error response
            setMessage({ type: 'error', text: response.data.msg });

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        } catch (error) {
          // Handle request error
          if (axios.isAxiosError(error)) {
            console.error('Axios Error:', error.message); // Log Axios errors
          } else {
            console.error('Unexpected Error:', error); // Log unexpected errors
          }

          // Set error message
          setMessage({
            type: 'error',
            text: 'An error occurred while submitting the form.',
          });

          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      } else {
        // Handle missing access token
        console.error('No access token found.');
        setMessage({
          type: 'error',
          text: 'Access token not found. Please log in again.',
        });

        // Scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount fulid-section contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Contract Supplier Long Term{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Contract Supplier Long Term
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    className="error-validation"
                    {...register("currency", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    className="error-validation"
                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft" selected>Draft</option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    className="error-validation"
                    {...register("account_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.length>0?(
        <>
       {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account_record}
                    name="contract_record_type" className='record-type'
                    {...register("contract_record_type", { required: false })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="rwood_contract_no"
                    {...register("rwood_contract_no", { required: false })}
                  />
                  {errors.rwood_contract_no && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    {...register("contract_owner", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate}
        onChange={handleStartDateChange}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Owner Expiration Notice"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="owner_expiration_notice"
                    placeholder="Owner Expiration Notice"

                    {...register("owner_expiration_notice", {
                      required: false,
                    })}
                  >
                    <option value="30">30 Days</option>
<option value="45">45 Days</option>
<option value="60">60 Days</option>
<option value="90">90 Days</option>
<option value="120">120 Days</option>
                  </Form.Select>
                  {errors.owner_expiration_notice && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Effective Date"
                  className="mb-3 date-picker"
                >
                      <DatePicker
              selected={startDate1}
        onChange={handleStartDateChange1}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    {...register("contract_term_in_months", {
                      valueAsNumber:true,
                      required: false,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="Contract Status"

                    {...register("contract_status", { required: false })}
                  >
                   <option value="">--None--</option><option value="In Approval Process">In Approval Process</option><option value="Activated">Activated</option><option value="Draft">Draft</option><option value="Under Review" selected>Under Review</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option><option value="Closed">Closed</option><option value="Partially completed">Partially completed</option><option value="Completed">Completed</option><option value="Cancelled">Cancelled</option><option value="Partially cancelled">Partially cancelled</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Partially Signed Contract">Partially Signed Contract</option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"  className='error-validation'

                    {...register("trader_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {traderData.length>0?(
        <>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                     </>
        ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master Contract Reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master Contract Reference"
                    name="master_conference_reference"
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    className="error-validation"
                    {...register("company", { required: true })}
                  >
                    <option value="">Select </option>
                    {companyData.length>0?(
        <>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                    </>
                    ):(

                      <option className='no-data'>No data  available</option>
                      )}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group><Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal date"
                  className="mb-3 date-picker"
                >
                      <DatePicker
              selected={startDate2}
        onChange={handleStartDateChange2}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port Of Loading"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port Of Loading"
                    name="port_of_loading"
                    {...register("port_of_loading", { required: false })}
                  />
                  {errors.port_of_loading && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    className="error-validation"
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Commodity">
  <Form.Select aria-label="Floating label select example"
  name="commodity"
  placeholder="Commodity"

  {...register("commodity", { required: false })}
  >
    <option>---None---</option>
    <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
  </Form.Select>
  {errors.commodity && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price PMT"
                    name="price_PMT"
                    {...register("price_PMT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_PMT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"

    {...register("price_book", { required: false })}
    >
      <option value='' >Select</option>
      {priceBook.length>0?(
        <>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.price_book && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price PMT in words"
                    name="price_PMT_in_words"
                    {...register("price_PMT_in_words", { required: false })}
                  />
                  {errors.price_PMT_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price Basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price Basis"

                    {...register("price_basis", { required: false })}
                  >
                  <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type" className='error-validation'

                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>

{getPrice.length>0?(
        <>
      {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price basis GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price basis GAR"
                    name="price_basis_GAR"
                    {...register("price_basis_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_basis_GAR && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price adjustment clause"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price adjustment clause"
                    name="price_adjustment_clause"
                    {...register("price_adjustment_clause", {
                      required: false,
                    })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pricing Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing Terms"
                    style={{ height: "100px!important" }}
                    name="pricing_terms"
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Term From Supplier</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment Terms"
                    style={{ height: "100px!important" }}
                    name="payment_terms"
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Applicable Law"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="applicable_law"
                    placeholder="Applicable Law"

                    {...register("applicable_law", { required: false })}
                  >
                   <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                  </Form.Select>
                  {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Arbitration"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="arbitration"
                    placeholder="Arbitration"

                    {...register("arbitration", { required: false })}
                  >
                   <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                  </Form.Select>
                  {errors.arbitration && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Description Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Special Terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Special Terms"
                    name="special_terms"
                    {...register("special_terms", { required: false })}
                  />
                  {errors.special_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="billing address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="billing address"
                    style={{ height: "100px!important" }}
                    name="billing_address"
                    {...register("billing_address", { required: false })}
                  />
                  {errors.billing_address && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="shipping address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="shipping address"
                    style={{ height: "100px!important" }}
                    name="shipping_address"
                    {...register("shipping_address", { required: false })}
                  />
                  {errors.shipping_address && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
{/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing street"
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping street"
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing zip / postal code"
                    name="billing_zip_postal_code"
                    {...register("billing_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.billing_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping zip / postal code"
                    name="shipping_zip_postal_code"
                    {...register("shipping_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.shipping_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state / province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state / province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group> */}
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Signature Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"

                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.length>0?(
        <>
      {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"

                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {users.length>0?(
        <>
      {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company Signed Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate3}
        onChange={handleStartDateChange3}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer Signed Date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate7}
        onChange={handleStartDateChange7}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original Contract Recieved Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate5}
        onChange={handleStartDateChange5}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >

    <DatePicker
              selected={startDate6}
        onChange={handleStartDateChange6}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
            </Row>

            <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  }
  else if (account_record === "Supplier") {
    const onSubmit = (data) => {
      const responseData = {
        locked: data.locked,
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        rwood_contract_no: data.rwood_contract_no,
        account_name: data.account_name,
        contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        remark: data.remark, billing_address:data.billing_address,shipping_address:data.shipping_address,
        master_conference_reference: data.master_conference_reference,
        renewal_date: startDates2,
        renewal_contract_number: data.renewal_contract_number,
        company: data.company,
        trader_name: data.trader_name,
        contract_owner: data.contract_owner,
        price_book: data.price_book,
        supplier: data.supplier,
        status: data.status,
        contract_term_in_months: data.contract_term_in_months,
        contract_start_date: startDates,
        owner_expiration_notice: data.owner_expiration_notice,
        origin: data.origin,
        interlab_tolerance: data.interlab_tolerance,
        commodity: data.commodity,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        port_of_loading: data.port_of_loading,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        price_adjust_clause: data.price_adjust_clause,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        shipping_city: data.shipping_city,
        hs_code: data.hs_code,
        company_signed_date: startDates3,
        applicable_law: data.applicable_law,
        arbitration: data.arbitration,
        lc_type: data.lc_type,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        loading_rate_per_day: data.loading_rate_per_day,
        master_lc_presentation_days: data.master_lc_presentation_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          data.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: data.document_presentation_period,
        list_of_documents_required: data.list_of_documents_required,
        special_terms: data.special_terms,
        price_PMT: data.price_PMT,
        price_PMT_in_words: data.price_PMT_in_words,
        price_basis: data.price_basis,
        price_type: data.price_type,
        price_basis_GAR: data.price_basis_GAR,
        price_adjustment_clause: data.price_adjustment_clause,
        description: data.description,
        rejections: data.rejections,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        company_signed_by: data.company_signed_by,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
        original_contract_recieved_date:startDate5,
        original_contract_sent_date: startDates6,
        billing_street: data.billing_street,
        billing_zip_postal_code: data.billing_zip_postal_code,
        billing_city: data.billing_city,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
         customer_signed_date:startDates7
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios
        .post(`${API_URL}add/contract`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => { console.log(response)
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              navigate(`/contract/contract-details/${response.id}`);
          } else if (response.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }
          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount fulid-section contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Contract Supplier{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Supplier
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    className="error-validation"
                    {...register("currency", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    className="error-validation"
                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft" selected>Draft</option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    className="error-validation"
                    {...register("account_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.length>0?(
        <>
       {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account_record}
                    name="contract_record_type" className='record-type'
                    {...register("contract_record_type", { required: false })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="rwood_contract_no"
                    {...register("rwood_contract_no", { required: false })}
                  />
                  {errors.rwood_contract_no && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    {...register("contract_owner", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group><Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                    <DatePicker
              selected={startDate}
        onChange={handleStartDateChange}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Owner Expiration Notice"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="owner_expiration_notice"
                    placeholder="Owner Expiration Notice"

                    {...register("owner_expiration_notice", {
                      required: false,
                    })}
                  >
                    <option>---None---</option>
                    <option value="30">30 Days</option>
<option value="45">45 Days</option>
<option value="60">60 Days</option>
<option value="90">90 Days</option>
<option value="120">120 Days</option>
                  </Form.Select>
                  {errors.owner_expiration_notice && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Effective Date"
                  className="mb-3 date-picker"
                >
                     <DatePicker
              selected={startDate1}
        onChange={handleStartDateChange1}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    className="error-validation"
                    {...register("contract_term_in_months", {
                      valueAsNumber:true,
                      required: true,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="Contract Status"

                    {...register("contract_status", { required: false })}
                  >
                   <option value="">--None--</option><option value="In Approval Process">In Approval Process</option><option value="Activated">Activated</option><option value="Draft">Draft</option><option value="Under Review" selected>Under Review</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option><option value="Closed">Closed</option><option value="Partially completed">Partially completed</option><option value="Completed">Completed</option><option value="Cancelled">Cancelled</option><option value="Partially cancelled">Partially cancelled</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Partially Signed Contract">Partially Signed Contract</option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"  className='error-validation'

                    {...register("trader_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {traderData.length>0?(
        <>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                     </>
        ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master Contract Reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master Contract Reference"
                    name="master_conference_reference"
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    className="error-validation"
                    {...register("company", { required: true })}
                  >
                    <option value="">Select </option>
                    {companyData.length>0?(
        <>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                    </>
                    ):(

                      <option className='no-data'>No data  available</option>
                      )}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group><Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate2}
        onChange={handleStartDateChange2}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port Of Loading"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port Of Loading"
                    name="port_of_loading"
                    {...register("port_of_loading", { required: false })}
                  />
                  {errors.port_of_loading && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    className="error-validation"
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Commodity">
  <Form.Select aria-label="Floating label select example"
  name="commodity"
  placeholder="Commodity"

  {...register("commodity", { required: false })}
  >
    <option>---None---</option>
    <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
  </Form.Select>
  {errors.commodity && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price PMT"
                    name="price_PMT"
                    {...register("price_PMT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_PMT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"

    {...register("price_book", { required: false })}
    >
      <option value='' >Select</option>
      {priceBook.length>0?(
        <>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.price_book && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price PMT in words"
                    name="price_PMT_in_words"
                    {...register("price_PMT_in_words", { required: false })}
                  />
                  {errors.price_PMT_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price Basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price Basis"

                    {...register("price_basis", { required: false })}
                  >
                   <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type" className='error-validation'

                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>

{getPrice.length>0?(
        <>
      {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price basis GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price basis GAR"
                    name="price_basis_GAR"
                    {...register("price_basis_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_basis_GAR && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="price adjustment clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="price adjustment clause"
                    name="price_adjustment_clause"
                    {...register("price_adjustment_clause", { required: false })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pricing Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing Terms"
                    style={{ height: "100px!important" }}
                    name="pricing_terms"
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Term From Supplier</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment Terms"
                    style={{ height: "100px!important" }}
                    name="payment_terms"
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Applicable Law"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="applicable_law"
                    placeholder="Applicable Law"

                    {...register("applicable_law", { required: false })}
                  >
                   <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                  </Form.Select>
                  {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Arbitration"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="arbitration"
                    placeholder="Arbitration"

                    {...register("arbitration", { required: false })}
                  >
                   <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                  </Form.Select>
                  {errors.arbitration && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Description Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Special Terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Special Terms"
                    name="special_terms"
                    {...register("special_terms", { required: false })}
                  />
                  {errors.special_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="billing address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="billing address"
                    style={{ height: "100px!important" }}
                    name="billing_address"
                    {...register("billing_address", { required: false })}
                  />
                  {errors.billing_address && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="shipping address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="shipping address"
                    style={{ height: "100px!important" }}
                    name="shipping_address"
                    {...register("shipping_address", { required: false })}
                  />
                  {errors.shipping_address && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
{/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing street"
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping street"
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing zip / postal code"
                    name="billing_zip_postal_code"
                    {...register("billing_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.billing_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping zip / postal code"
                    name="shipping_zip_postal_code"
                    {...register("shipping_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.shipping_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state / province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state / province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group> */}
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Signature Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"

                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.length>0?(
        <>
      {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"

                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>


{users.length>0?(
        <>
      {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company Signed Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate3}
        onChange={handleStartDateChange3}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer Signed Date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate7}
        onChange={handleStartDateChange7}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original Contract Recieved Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate5}
        onChange={handleStartDateChange5}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >

    <DatePicker
              selected={startDate6}
        onChange={handleStartDateChange6}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>


            </Row>

            <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  }
  else if (account_record === "Supplier - Spot") {
    const onSubmit = (data) => {
      const responseData = {
        locked: data.locked,
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        rwood_contract_no: data.rwood_contract_no,
        account_name: data.account_name,
        contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        remark: data.remark, billing_address:data.billing_address,shipping_address:data.shipping_address,
         customer_signed_date:startDates7,
        master_conference_reference: data.master_conference_reference,
        renewal_date: startDates2,
        renewal_contract_number: data.renewal_contract_number,
        company: data.company,
        trader_name: data.trader_name,
        contract_owner: data.contract_owner,
        price_book: data.price_book,
        supplier: data.supplier,
        status: data.status,
        contract_term_in_months: data.contract_term_in_months,
        contract_start_date: startDates,
        owner_expiration_notice: data.owner_expiration_notice,
        origin: data.origin,
        interlab_tolerance: data.interlab_tolerance,
        commodity: data.commodity,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        port_of_loading: data.port_of_loading,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        price_adjust_clause: data.price_adjust_clause,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        shipping_city: data.shipping_city,
        hs_code: data.hs_code,
        applicable_law: data.applicable_law,
        arbitration: data.arbitration,
        lc_type: data.lc_type,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        loading_rate_per_day: data.loading_rate_per_day,
        master_lc_presentation_days: data.master_lc_presentation_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          data.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: data.document_presentation_period,
        list_of_documents_required: data.list_of_documents_required,
        special_terms: data.special_terms,
        company_signed_date: startDates3,
        price_PMT: data.price_PMT,
        price_PMT_in_words: data.price_PMT_in_words,
        price_basis: data.price_basis,
        price_type: data.price_type,
        price_basis_GAR: data.price_basis_GAR,
        price_adjustment_clause: data.price_adjustment_clause,
        description: data.description,
        rejections: data.rejections,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        company_signed_by: data.company_signed_by,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
        billing_street: data.billing_street,
        billing_zip_postal_code: data.billing_zip_postal_code,
        billing_city: data.billing_city,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        axios
        .post(`${API_URL}add/contract`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => { console.log(response)
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;

                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              navigate(`/contract/contract-details/${response.id}`);
          } else if (response.success === false) {

              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;

              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }

          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount fulid-section contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Contract  Supplier Spot{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Supplier Spot
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="messageContainer"></div>
          <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    className="error-validation"
                    {...register("currency", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    className="error-validation"
                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft" selected>Draft</option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6"></Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    className="error-validation"
                    {...register("account_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.length>0?(
        <>
       {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account_record}
                    name="contract_record_type" className='record-type'
                    {...register("contract_record_type", { required: false })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="rwood_contract_no"
                    {...register("rwood_contract_no", { required: false })}
                  />
                  {errors.rwood_contract_no && <span>Required </span>}
                </FloatingLabel>
              </Form.Group> <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    {...register("contract_owner", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate}
        onChange={handleStartDateChange}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Owner Expiration Notice"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="owner_expiration_notice"
                    placeholder="Owner Expiration Notice"

                    {...register("owner_expiration_notice", {
                      required: false,
                    })}
                  >
                    <option>---None---</option>
                    <option value="30">30 Days</option>
<option value="45">45 Days</option>
<option value="60">60 Days</option>
<option value="90">90 Days</option>
<option value="120">120 Days</option>
                  </Form.Select>
                  {errors.owner_expiration_notice && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Effective Date"
                  className="mb-3 date-picker"
                >
                     <DatePicker
              selected={startDate1}
        onChange={handleStartDateChange1}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    {...register("contract_term_in_months", {
                      valueAsNumber:true,
                      required: false,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="Contract Status"

                    {...register("contract_status", { required: false })}
                  >
                   <option value="">--None--</option><option value="In Approval Process">In Approval Process</option><option value="Activated">Activated</option><option value="Draft">Draft</option><option value="Under Review" selected>Under Review</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option><option value="Closed">Closed</option><option value="Partially completed">Partially completed</option><option value="Completed">Completed</option><option value="Cancelled">Cancelled</option><option value="Partially cancelled">Partially cancelled</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Partially Signed Contract">Partially Signed Contract</option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"  className='error-validation'

                    {...register("trader_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {traderData.length>0?(
        <>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                     </>
        ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master Contract Reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master Contract Reference"
                    name="master_conference_reference"
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    className="error-validation"
                    {...register("company", { required: true })}
                  >
                    <option value="">Select </option>
                    {companyData.length>0?(
        <>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                    </>
                    ):(

                      <option className='no-data'>No data  available</option>
                      )}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group><Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate2}
        onChange={handleStartDateChange2}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port Of Loading"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port Of Loading"
                    name="port_of_loading"
                    {...register("port_of_loading", { required: false })}
                  />
                  {errors.port_of_loading && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    className="error-validation"
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Commodity">
  <Form.Select aria-label="Floating label select example"
  name="commodity"
  placeholder="Commodity"

  {...register("commodity", { required: false })}
  >
    <option>---None---</option>
    <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
  </Form.Select>
  {errors.commodity && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price PMT"
                    name="price_PMT"
                    {...register("price_PMT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_PMT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"

    {...register("price_book", { required: false })}
    >
      <option value='' >Select</option>
      {priceBook.length>0?(
        <>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.price_book && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price PMT in words"
                    name="price_PMT_in_words"
                    {...register("price_PMT_in_words", { required: false })}
                  />
                  {errors.price_PMT_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price Basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price Basis"

                    {...register("price_basis", { required: false })}
                  >
                  <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type" className='error-validation'

                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>


{getPrice.length>0?(
        <>
      {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price basis GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price basis GAR"
                    name="price_basis_GAR"
                    {...register("price_basis_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_basis_GAR && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price adjustment clause"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price adjustment clause"
                    name="price_adjustment_clause"
                    {...register("price_adjustment_clause", {
                      required: false,
                    })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pricing Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing Terms"
                    style={{ height: "100px!important" }}
                    name="pricing_terms"
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Term From Supplier</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment Terms"
                    style={{ height: "100px!important" }}
                    name="payment_terms"
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Applicable Law"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="applicable_law"
                    placeholder="Applicable Law"

                    {...register("applicable_law", { required: false })}
                  >
                   <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                  </Form.Select>
                  {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Arbitration"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="arbitration"
                    placeholder="Arbitration"

                    {...register("arbitration", { required: false })}
                  >
                   <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                  </Form.Select>
                  {errors.arbitration && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Description Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Special Terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Special Terms"
                    name="special_terms"
                    {...register("special_terms", { required: false })}
                  />
                  {errors.special_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="billing address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="billing address"
                    style={{ height: "100px!important" }}
                    name="billing_address"
                    {...register("billing_address", { required: false })}
                  />
                  {errors.billing_address && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="shipping address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="shipping address"
                    style={{ height: "100px!important" }}
                    name="shipping_address"
                    {...register("shipping_address", { required: false })}
                  />
                  {errors.shipping_address && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
{/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing street"
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping street"
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing zip / postal code"
                    name="billing_zip_postal_code"
                    {...register("billing_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.billing_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping zip / postal code"
                    name="shipping_zip_postal_code"
                    {...register("shipping_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.shipping_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state / province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state / province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group> */}
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Signature Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"

                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>

{contacts.length>0?(
        <>
      {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"

                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {users.length>0?(
        <>
      {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original Contract Recieved Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate5}
        onChange={handleStartDateChange5}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company Signed Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate3}
        onChange={handleStartDateChange3}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer Signed Date"
                  className="mb-3 date-picker"
                >
                 <DatePicker
              selected={startDate7}
        onChange={handleStartDateChange7}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >

    <DatePicker
              selected={startDate6}
        onChange={handleStartDateChange6}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
            </Row>

            <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/create-contract-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  }}

export default AddCotract;
